import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/Api/login.service';

@Component({
  selector: 'headerMovil',
  templateUrl: './hamburguerMenu.component.html',
  styleUrls: ['./hamburguerMenu.component.scss']
})
export class HamburguerMenuComponent implements OnInit {
  opened = false;
  constructor(private loginSE: LoginService) { }

  ngOnInit() {
  
  }
  openMenu(){
    if (!this.opened) {
      this.opened = true;
     }else{
      this.opened = false;
     }
      document.getElementById("nav-icon2")?.classList.toggle("open");
      if ( document.getElementById("nav-icon2")?.classList.contains("open")) {
        (document.querySelector(".test")as HTMLElement).classList.add("menuOpened");
        setTimeout(() => {
          (document.querySelector(".ocean")as HTMLElement).classList.remove("aparecido");
          (document.querySelector(".ocean")as HTMLElement).classList.add("desaparecido");
         
        }, 500);
      
      }else{
        
        setTimeout(() => {
          (document.querySelector(".ocean")as HTMLElement).classList.remove("desaparecido");
          (document.querySelector(".ocean")as HTMLElement).classList.add("aparecido")
        }, 500);
        setTimeout(() => {
          (document.querySelector(".test")as HTMLElement).classList.remove("menuOpened");
          (document.querySelector(".test")as HTMLElement).classList.add("menuClosed");
        }, 700);
      }
     
  }
  logOut(){
    this.loginSE.logOut();
  }
}
