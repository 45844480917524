<div class="generalContainer">
  <div class="subContainer">
    <div>
      <sidebar></sidebar>
    </div>
    <div class="master-container">
      <div class="changePass-page">
        <header class="nav-section">
          <nav class="navbar">
            <div class="breadcrumbs">
              <ul>
                <li>
                  <a routerLink="/publisher/home" aria-label="Inicio"><img src="/assets/icons/house-solid.svg" alt="" />
                  </a>
                </li>
                <li><a routerLink="/publisher/settings/">Configuración</a></li>
                <li><a routerLink="/publisher/settings/change-password">Cambiar contraseña</a></li>
              </ul>
            </div>
            <div class="nav-options">
              <div class="icons">

                <a routerLink="." aria-label="Ayuda" class="nav-icon"><img src="/assets/icons/questionMark.svg"
                    alt="" /></a>
                <a routerLink="." aria-label="Mi usuario" class="nav-icon">
                  <img src="/assets/icons/user.svg" alt="" /></a>
              </div>
              
            </div>
            
          </nav>
          <section class="options">
            <div class="left-group">
              <a class="btn-xs" routerLink="../">Atrás</a>
            </div>
            <div class="right-group"></div>
          </section>
        </header>
        <main>
          <div class="formGeneralContainer">
            <form>
              <div class="fotoPerfilContainer"  (click)="setClick('.fotoPerfilContainer button .fileInput')">
                <div class="imageOverlay"><mat-icon>edit</mat-icon></div>
                <button fileInput [accept]="['image/jpeg']" [(fichero)]="image"
                  (ficheroChange)="setProfileImage(image)"></button>
                <img *ngIf="fotoPerfil==null" src="/assets/images/defaultImagePerfil.png" alt="" />
                <img *ngIf="fotoPerfil!=null" [src]="fotoPerfil" alt="" />
              </div>
              <div class="dobleColumna">
                <div class="dataContainer">
                  <label [class.vacio]="nombreVacio">Nombre</label>
                  <input type="text"  name="name" (change)="updateUser('name', nombre)"[(ngModel)]="nombre" placeholder="Nombre" class="inputData">
                </div>
                <div class="dataContainer">
                  <label [class.vacio]="apellidosVacio">Apellidos</label>
                  <input type="text"  name="apellidos" (change)="updateUser('surname', apellidos)"[(ngModel)]="apellidos" placeholder="Apellidos" class="inputData">
                </div>
              </div>
              <div class="dobleColumna">
                <div class="dataContainer">
                  <label [class.vacio]="generoVacio">Género</label>
                  <input type="text"  name="genero" (change)="updateUser('gender', genero)" [(ngModel)]="genero" placeholder="Género" class="inputData">
                </div>
                <div class="dataContainer">
                  <label [class.vacio]="fechaNacimientoVacio">Fecha de nacimiento</label>
                  <div>
                    <input matInput [matDatepicker]="picker" readOnly="true" [value]="fechaNacimiento" (dateChange)="changeDate($event)"
                      placeholder="Fecha de nacimiento" class="inputData datePicker">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker class="miPerfil-movil" #picker></mat-datepicker>
                  </div>
                </div>
              </div>
              <div class="dobleColumna">
                <div class="dataContainer">
                  <label [class.vacio]="telefonoVacio">Teléfono</label>
                  <input type="number"  name="numero" (change)="updateUser('phone', telefono)" [(ngModel)]="telefono" placeholder="Número de teléfono" class="inputData">
                </div>
                <div class="dataContainer">
                  <label>Email de contacto</label>
                  <input type="email"  name="email" disabled [(ngModel)]="email" placeholder="Email de contacto" class="inputData">
                </div>
              </div>

              <div class="dataContainer enlace">
                <a (click)="openChangePassword()">¿Quieres cambiar la contraseña?</a>
              </div>
              <div class="dataContainer">
                <button [routerLink]="['/publisher/settings']"  type="button" class="btn-sm">Finalizar edición</button>
              </div>
            </form>
          </div>
        </main>
      </div>
    </div>
  </div>
</div>