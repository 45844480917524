<PageTemplate>
  <ng-container bodyContent>
    <div class="formHeader">
      <GeneralButton [buttonText]="'Cancelar'" (click)="cancel()"></GeneralButton>
      <div>
        <GeneralButton [buttonText]="'Atrás'" (click)="activo = activo - 1" [class.disabled]="activo == 0">
        </GeneralButton>
        <!-- <GeneralButton [buttonText]="'Siguiente'"  *ngIf="activo < 3 && activo == 0" (click)="validatePaso1()"></GeneralButton> -->
        <GeneralButton [buttonText]="'Siguiente'" *ngIf="activo < 3 && activo == 1" (click)="validatePaso2()">
        </GeneralButton>
        <GeneralButton [buttonText]="'Siguiente'" *ngIf="activo < 3 && activo == 2" (click)="validatePaso3()">
        </GeneralButton>
        <GeneralButton *ngIf="!editar && activo == 0" [buttonText]="'Crear recinto'" (click)="validatePaso1()">
        </GeneralButton>
        <GeneralButton *ngIf="editar && activo == 0" [buttonText]="'Finalizar edición'"
          [routerLink]="['/publisher/facilities/']"></GeneralButton>
      </div>
    </div>
    <!-- <steps [navegacionActiva]="navegacionActiva" [pasos]="pasos" [activo]="activo" (cambio)="cambiado($event)"></steps> -->
    <main>
      <ng-container *ngIf="activo == 0">
        <div class="paso0GeneralContainer">
          <div class="formLeft">
            <div class="dataContainer">
              <label [class.vacio]="nombreRecintoError" for="">Dale un nombre a este recinto</label>
              <mat-spinner *ngIf="!dataIsLoaded" class="medium"></mat-spinner>
                <input type="text" [(ngModel)]="nombreRecinto" (change)="editFacility('name', nombreRecinto)"
                  placeholder="Nombre del recinto" class="inputData" *ngIf="dataIsLoaded">
            </div>
            <div class="dobleColumna">
              <div class="dataContainer">
                <label [class.vacio]="direccionLocalError" for="">Dirección exacta del local</label>
                <mat-spinner *ngIf="!dataIsLoaded" class="medium" ></mat-spinner>

                <input type="text" (change)="obtenerCoordenadas()" [(ngModel)]="direccionLocal"
                  placeholder="Dirección del local" class="inputData" *ngIf="dataIsLoaded">
              </div>
              <div class="dataContainer">
                <label [class.vacio]="localidadError" for="">Localidad</label>
                <mat-spinner *ngIf="!dataIsLoaded " class="medium"></mat-spinner>

                <input type="text" (change)="obtenerCoordenadas()" [(ngModel)]="localidad" placeholder="Localidad"
                  class="inputData" *ngIf="dataIsLoaded ">
              </div>
            </div>
            <div class="dobleColumna">
              <div class="dataContainer">
                <label [class.vacio]="aforoMaximoError" for="">Aforo máximo del local</label>
                <mat-spinner *ngIf="!dataIsLoaded " class="medium"></mat-spinner>

                <input type="number" [(ngModel)]="aforoMaximo" (change)="editFacility('capacity', aforoMaximo)"
                  placeholder="Aforo máximo" class="inputData" *ngIf="dataIsLoaded ">
              </div>
              <div class="dataContainer">
                <label [class.vacio]="metrosCuadradosError" for="">Metros cuadrados</label>
                <mat-spinner *ngIf="!dataIsLoaded " class="medium"></mat-spinner>

                <input type="number" (change)="editFacility('squareMeters', metrosCuadrados)"
                  [(ngModel)]="metrosCuadrados" placeholder="Metros cuadrados" class="inputData" *ngIf="dataIsLoaded ">
              </div>
            </div>
          </div>
          <div class="formRight">
            <div class="dataContainer">
              <label for="" [class.vacio]="tematicaSeleccionadaError">Temática</label>
              <div class="tematicas">
                <mat-spinner class="medium center" *ngIf="!dataIsLoaded"></mat-spinner>
                <ng-container *ngIf="dataIsLoaded">
                  <span (click)="setTematicas(tematica); editFacility('categories', tematicaSeleccionada)"
                    class="tematica" [class.active]="tematicaSeleccionada.includes(tematica)"
                    *ngFor="let tematica of tematicas">
                    {{tematica}}
                  </span>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="activo == 1">
        <div class="paso1GeneralContainer">
          <div class="plantas">
            <p class="blockTitle" [class.vacio]="planoError">Asociar plano a planta o zona</p>
            <div class="plantasList">
              <p class="noData" *ngIf="zonas.length == 0">No hay plantas creadas</p>
              <div class="planta" *ngFor="let zona of zonas" [class.selected]="zonaSelected == zona.id"
                (click)="zonaSelected = zona.id; planoSelected = zona.image">
                <div>
                  <input [class.editEnabled]="editEnabled && zonaEditable == zona.id"
                    (change)="editFloorPlan('name', zona.name)" type="text" [(ngModel)]="zona.name">
                </div>
                <div class="iconos">
                  <a (click)="enableEdit(zona)" class="square-icon"><img src="/assets/icons/editPencil.svg"
                      alt="" /></a>
                  <a (click)="eliminarZona(zona)" class="square-icon"><img src="/assets/icons/trash.svg" alt="" /></a>
                </div>
              </div>

            </div>
            <div (click)="generadorDeZonas()" class="planta añadirPlanta">
              <mat-icon>add_circle_outline</mat-icon>
              Añadir planta
            </div>
          </div>
          <div class="planos">
            <p *ngIf="zonaSelected == -1">Para asociar un plano, selecciona una zona</p>
            <div *ngIf="zonaSelected != -1">
              <button (click)="setClick()" fileInput [accept]="['image/jpeg', 'image/png']" [(fichero)]="plano"
                (ficheroChange)="adjuntarPlano()">
                <div></div>
                <svg *ngIf="planoSelected == null" [class.activo]="plano != null" xmlns="http://www.w3.org/2000/svg"
                  width="33.215" height="31.919" viewBox="0 0 33.215 31.919">
                  <g id="Grupo_1726" data-name="Grupo 1726" transform="translate(-604 -583.25)">
                    <path id="Trazado_790" data-name="Trazado 790"
                      d="M.75,10.75v2.992A6.121,6.121,0,0,0,7,19.725H24.713a6.121,6.121,0,0,0,6.251-5.983V10.75"
                      transform="translate(604.75 593.944)" fill="none" stroke="#1017af" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="3" />
                    <line id="Línea_646" data-name="Línea 646" y1="18.448" transform="translate(620.5 585.249)"
                      fill="none" stroke="#1017af" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                    <path id="Trazado_791" data-name="Trazado 791" d="M4.75,8.185,11.654.75l6.9,7.435"
                      transform="translate(608.75 584)" fill="none" stroke="#1017af" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="3" />
                  </g>
                </svg>
              </button>
              <ng-container *ngIf="editar">
                <!-- <img *ngIf="planoSelected == null" [src]="planoSelected" alt=""> -->
                <img *ngIf="planoSelected != null && !planoSelected[0].includes('data')" [src]="planoSelected"
                  alt="">
                <img *ngIf="planoSelected != null && planoSelected[0].includes('data')" [src]="planoSelected" alt="">
              </ng-container>
              <ng-container *ngIf="!editar">
                <img [src]="planoSelected" alt="">
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="activo == 2">
        <div class="paso2GeneralContainer">
          <div class="leftContainer">
            <div class="titleContainer">
              <p class="blockTitle" [class.vacio]="reservasError">Listado de reservas agregadas</p>
            </div>
            <div class="mesasContainer">
              <p class="noData" *ngIf="zonas.length == 0">No hay reservas creadas</p>
              <ng-container *ngFor="let zona of zonas">
                <ng-container *ngFor="let mesa of zona.tables">
                  <div class="listaMesa" *ngIf="zona.id == zonaSelectedPaso2">
                    <div>
                      <div [class.cuadrado]="mesa.type == 'cuadrado'" [class.circulo]="mesa.type == 'circulo'"></div>
                      <span>Mesa {{mesa.number}}</span>
                    </div>
                    <div class="iconos">
                      <a (click)="eliminarMesa(mesa, zona)" class="square-icon"><img src="/assets/icons/trash.svg"
                          alt="" /></a>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
          <div class="rightContainer">
            <div class="titleContainer">
              <p class="blockTitle" [class.vacio]="reservasError">Escoge el plano de la planta:</p>
              <ng-select [items]="zonas" class="zonasSelector" bindValue="id" bindLabel="name"
                [(ngModel)]="zonaSelectedPaso2" [searchable]="false" [placeholder]="'- Seleccionada una zona/planta -'"
                (change)="changePlano()" [clearable]="false">
              </ng-select>
            </div>
            <div class="getionPlanosContainer">
              <div class="controlerContainer">
                <div class="mesas">
                  <div class="cuadrado" (click)="generarMesa('cuadrado')"></div>
                  <div class="circulo" (click)="generarMesa('circulo')"></div>
                </div>
                <div class="controles">
                  <div class="rotarContainer" *ngIf="false">
                    <div class="rotarDerecha" (click)="rotateRight()" *ngIf="false">
                      <mat-icon>rotate_right</mat-icon>
                    </div>
                    <div class="rotarIzquierda" (click)="rotateLeft()" *ngIf="false">
                      <mat-icon>rotate_left</mat-icon>
                    </div>
                  </div>
                  <div class="zoomContainer">
                    <div class="masZoom" (click)="addZoom()">
                      <mat-icon>add</mat-icon>
                    </div>
                    <div (click)="lessZoom()" class="menosZoom">
                      <mat-icon>remove</mat-icon>
                    </div>
                  </div>
                </div>
              </div>
              <div class="planosContainer">

                <ng-container *ngFor="let zona of zonas">
                  <pan-zoom [config]="panZoomConfig" [class.rotate-90]="rotacionDerecha == -90"
                    *ngIf="zonaSelectedPaso2 != null && zonaSelectedPaso2 == zona.id"
                    [class.rotate-135]="rotacionDerecha == -135" [class.rotate-180]="rotacionDerecha == -180"
                    [class.rotate-225]="rotacionDerecha == -225" [class.rotate-270]="rotacionDerecha == -270"
                    [class.rotate-315]="rotacionDerecha == -315" [class.rotate-360]="rotacionDerecha == -360"
                    [class.rotate0]="rotacionDerecha == 0" [class.rotate45]="rotacionDerecha == 45"
                    [class.rotate90]="rotacionDerecha == 90" [class.rotate135]="rotacionDerecha == 135"
                    [class.rotate180]="rotacionDerecha == 180" [class.rotate225]="rotacionDerecha == 225"
                    [class.rotate270]="rotacionDerecha == 270" [class.rotate315]="rotacionDerecha == 315"
                    [class.rotate360]="rotacionDerecha == 360">
                    <img id="plano"
                      *ngIf="zonaSelectedPaso2 != null && zonaSelectedPaso2 == zona.id && zona.image.includes('data')"
                      [src]="zona.image" alt="" [class.rotate-45]="rotacionDerecha == -45">
                    <img id="plano"
                      *ngIf="zonaSelectedPaso2 != null && zonaSelectedPaso2 == zona.id && !zona.image.includes('data')"
                      [src]="zona.image" alt="" [class.rotate-45]="rotacionDerecha == -45">
                    <ng-container *ngFor="let mesa of zona.tables">
                      <div [class.cuadrado]="mesa.type == 'cuadrado'"
                        [ngStyle]="{'transform': 'translate3d('+mesa.x_position+'px, '+mesa.y_position+'px, 0px)'}"
                        (mousedown)="arrastrando()" (mouseup)="soltado(mesa, $event)"
                        [class.circulo]="mesa.type=='circulo'" class="mesaFlotante" cdkDrag>{{mesa.number}}</div>
                    </ng-container>
                  </pan-zoom>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="activo == 3">
        <div class="paso3GeneralContainer">
          <div class="leftContainer">
            <div class="plantasContainer">
              <p class="noData" *ngIf="zonas.length == 0">No hay reservas creadas</p>
              <ng-container *ngFor="let zona of zonas">
                <div class="planta" (click)="zonaPaso3Selected = zona.id"
                  [class.selected]="zonaPaso3Selected == zona.id">
                  <div class="plantaLeft">
                    <span>{{zona.name}}</span>
                  </div>
                  <div class="plantaRight">
                    <span>{{zona.tables.length}} reservas disponibles</span>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="rightContainer">
            <div class="listadoReservas">
              <div class="tituloContainer">
                <span>Reservas disponibles</span>
              </div>
              <ng-container *ngIf="zonaPaso3Selected == null">
                <p>Para gestionar las mesas debe de seleccionar una zona
                  de reserva</p>
              </ng-container>
              <ng-container *ngIf="zonaPaso3Selected != null">
                <ng-container *ngFor="let zona of zonas">
                  <ng-container *ngFor="let mesa of zona.tables">
                    <div class="reservasContainer" *ngIf="zona.id == zonaPaso3Selected">
                      <div class="reservaNombre">
                        <div [class.cuadrado]="mesa.type == 'cuadrado'" [class.circulo]="mesa.type == 'circulo'">
                        </div>
                        <span>Mesa {{mesa.number}}</span>
                      </div>
                      <div class="reservaPersonas">
                        <span>Personas</span>
                        <input type="number" min="0" (change)="editTable('capacity', mesa.capacity,null, null, mesa.id)"
                          [(ngModel)]="mesa.capacity">
                      </div>
                      <div class="reservaPrecio">
                        <span>Precio</span>
                        <input type="number" min="0" (change)="editTable('fee', mesa.fee, null, null, mesa.id)"
                          [(ngModel)]="mesa.fee">
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </main>
  </ng-container>
</PageTemplate>