import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LoginService } from 'src/app/services/Api/login.service';

@Component({
  selector: 'app-supportChat',
  templateUrl: './supportChat.component.html',
  styleUrls: ['./supportChat.component.scss']
})
export class SupportChatComponent implements OnInit {
  userSelect:any = {}
  mensaje:string = ""
  usuarios=[
    {
      id:1,
      nombre: "Felix Caballero López De La Fuente",
      urlImg: '/assets/images/imagenUsuarioTest.png'

    },
    {
      id:2,
      nombre: "Andrés Campos Grueiro",
      urlImg: '/assets/images/imagenUsuarioTest.png'
    },
    {
      id:3,
      nombre: "Joan Damià Fernández",
      urlImg: '/assets/images/imagenUsuarioTest.png'
    },
    {
      id:4,
      nombre: "Marina Álvarez Corral",
      urlImg: '/assets/images/imagenUsuarioTest.png'
    },
    {
      id:5,
      nombre: "Gabriela Lopez Aviñón",
      urlImg: '/assets/images/imagenUsuarioTest.png'
    },
    {
      id:6,
      nombre: "Gabriela Lopez Aviñón",
      urlImg: '/assets/images/imagenUsuarioTest.png'
    },
    {
      id:7,
      nombre: "Gabriela Lopez Aviñón",
      urlImg: '/assets/images/imagenUsuarioTest.png'
    },
    {
      id:8,
      nombre: "Gabriela Lopez Aviñón",
      urlImg: '/assets/images/imagenUsuarioTest.png'
    },
    {
      id:9,
      nombre: "Gabriela Lopez Aviñón",
      urlImg: '/assets/images/imagenUsuarioTest.png'
    },
    {
      id:10,
      nombre: "Gabriela Lopez Aviñón",
      urlImg: '/assets/images/imagenUsuarioTest.png'
    }
  ]
  constructor(private loginSE: LoginService) { }

  ngOnInit() {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    }else{
      window.addEventListener('keypress',(e)=>{
        if(e.key == "Enter"){
          if (this.mensaje != "") {
          }
        }
      })
    }
     
  }

}
