<div class="limitWidth">
  <div class="dataContainer">
    <label [class.vacio]="razonSocialError">Nombre negocio</label>
    <input type="text" [(ngModel)]="razonSocial" class="inputData" placeholder="P.e: Indiana">
  </div>
  <div class="dataContainer">
    <label [class.vacio]="edadSeleccionadaError">Edad del público objetivo</label>
    <div class="flexContainer">
      <button class="edadBtn" *ngFor="let edad of edadesMinima" [class.activo]="edad.value == edadSeleccionada"
        (click)="edadSeleccionada=edad.value">{{edad.name}} años</button>
    </div>
  </div>
  <div class="dataContainer">
    <label [class.vacio]="interesesError">Escoge los intereses que más se adaptan a tu negocio...</label>
    <mat-spinner class="medium center" *ngIf="!dataIsLoaded"></mat-spinner>

    <div class="flexContainer">
      <ng-container *ngIf="dataIsLoaded">
        <button class="pill" *ngFor="let item of listIntereses" (click)="toggleSelectedInteres(item.name)"
          [class.active]="selectedIntereses.includes(item.name)">{{item.name}}</button>
      </ng-container>
    </div>
  </div>
</div>