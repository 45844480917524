<PageTemplate>
  <ng-container bodyContent>
    <div class="headerContainer">
      <header [searcherActive]="false"></header>
      <searcher [placeHolder]="'Busca aquí un negocio filial'" [toFilter]="companiesBackup" [type]="'company'"
        (filtered)="filteredCompanies($event)"></searcher>
    </div>
    <div class="spinnerContainer"  *ngIf="!dataIsLoaded">
      <mat-spinner class="big"></mat-spinner>
    </div>
    <ng-container *ngIf="dataIsLoaded">
      <div class="negociosVacios" *ngIf="companies.length == 0">
        <GeneralButton [class.noPermision]="!permiso" [buttonText]="'Añadir nuevo negocio'"
          [routerLink]="['/admin/bussines/new-bussines']">
        </GeneralButton>
        <p class="noData">Aún no has agregado ningún negocio</p>
      </div>
      <div class="negociosContainer" *ngIf="companies.length != 0">
        <ManagementInfoCard *ngFor="let company of companies" [title]="company.name"
          [description]="'Visualiza los datos agregado de este negocio'" [link]="'/publisher/home'"
          [idCompany]="company.id" [deleteButton]="true" (confirmDelete)="deleteCompany($event)">
        </ManagementInfoCard>
        <div class="addNuevoNegocio" [routerLink]="['/admin/bussines/new-bussines']" [class.noPermision]="!permiso">
          <svg xmlns="http://www.w3.org/2000/svg" width="41.861" height="41.861" viewBox="0 0 41.861 41.861">
            <g id="Grupo_11593" data-name="Grupo 11593" transform="translate(-1 -496)">
              <path id="Trazado_10160" data-name="Trazado 10160"
                d="M4.75,24.681A19.931,19.931,0,0,1,24.681,4.75h0A19.931,19.931,0,0,1,44.611,24.681h0A19.931,19.931,0,0,1,24.681,44.611h0A19.931,19.931,0,0,1,4.75,24.681Z"
                transform="translate(-2.75 492.25)" fill="none" stroke="#1117a8" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="2" />
              <path id="Trazado_10161" data-name="Trazado 10161" d="M12,8.75V26.715"
                transform="translate(9.931 499.198)" fill="none" stroke="#1117a8" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="2" />
              <path id="Trazado_10162" data-name="Trazado 10162" d="M26.715,12H8.75"
                transform="translate(4.198 504.931)" fill="none" stroke="#1117a8" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="2" />
            </g>
          </svg>
          <span>Añadir nuevo negocio</span>
        </div>
      </div>
    </ng-container>

  </ng-container>
</PageTemplate>