<PageTemplate>
  <ng-container bodyContent>
    <header [searcherActive]="false" [buttonActive]="false"></header>
    <div class="eventosContainer">
      <p>{{this.today.toLocaleString('default', {weekday: 'long'})}} | {{hoy.dia}} de {{hoy.mesNombre()}} de
        {{hoy.year}}</p>
      <p>Estos son los eventos disponibles durante hoy {{hoy.dia}} de {{hoy.mesNombre()}}</p>
      <div class="events">
        <mat-spinner class="center big" *ngIf="!dataLoaded"></mat-spinner>
        <ng-container *ngIf="dataLoaded">
          <p class="noData" *ngIf="eventosHoy.length == 0">No hay eventos disponibles</p>
          <card [redirect]="redirect" [data]="event" [type]="'eventSimpleCard'" *ngFor="let event of eventosHoy"
            [routerLink]="['/publisher/management/events/today-events/buy-validate/'+event.id]"></card>
        </ng-container>
      </div>
    </div>
  </ng-container>
</PageTemplate>