<PageTemplate>
  <ng-container bodyContent>
    <div class="headerContainer">
      <header [searcherActive]="false" [buttonActive]="false"></header>
      <div class="buttons">
        <GeneralButton *ngIf=" !dataIsLoaded" [buttonText]="'Cargando...'"></GeneralButton>
        <GeneralButton *ngIf=" dataIsLoaded && !pr.pause" [buttonText]="'Pausar actividad'" (click)="setPauseRRPP()"
          [permiso]="permiso">
        </GeneralButton>
        <GeneralButton *ngIf=" dataIsLoaded && pr.pause" [buttonText]="'Reanudar actividad'" (click)="setPauseRRPP()"
          [permiso]="permiso"></GeneralButton>
        <!-- <GeneralButton [buttonText]="'Eliminar RRPP'" (click)="eliminarRRPP()" [permiso]="permiso"></GeneralButton> -->
      </div>
    </div>
    <div class="prGeneralContainer">
      <div class="prInformacion">

          <img onerror="this.src='/assets/images/defaultImagePerfil.png'"  *ngIf="dataIsLoaded" [src]="pr.user.profile_image" alt="">
          <img src="/assets/images/defaultImagePerfil.png" alt="" *ngIf="!dataIsLoaded">

        <div class="prInformationTable">
          <p *ngIf="!dataIsLoaded">Cargando nombre...</p>
          <p *ngIf="dataIsLoaded">{{pr.user.name}} {{pr.user.surname}}</p>
          <div class="table">
            <div class="left">
              <div>
                <span>Mail de contacto:</span>
                <mat-spinner *ngIf="!dataIsLoaded" class="small"></mat-spinner>
                <span class="underline" *ngIf="dataIsLoaded">{{pr.user.email}}</span>
              </div>
              <div>
                <span>Telefono de contacto:</span>
                <mat-spinner *ngIf="!dataIsLoaded" class="small"></mat-spinner>
                <span class="underline" *ngIf="dataIsLoaded">{{pr.user.phone}}</span>
              </div>
              <div>
                <span>Negocio asociado:</span>
                <mat-spinner *ngIf="!dataIsLoaded" class="small"></mat-spinner>
                <span *ngIf="dataIsLoaded">{{pr.company.name}}</span>
              </div>
            </div>
            <div class="right">
              <div>
                <span>Nº Identificación Fiscal:</span>
                <mat-spinner *ngIf="!dataIsLoaded" class="small"></mat-spinner>
                <span *ngIf="dataIsLoaded">{{pr.user.dni}}</span>
              </div>
              <div>
                <span>Localidad:</span>
                <mat-spinner *ngIf="!dataIsLoaded" class="small"></mat-spinner>
                <span *ngIf="dataIsLoaded">{{pr.user.address.locality}}</span>
              </div>
              <div>
                <span>Equipo:</span>
                <mat-spinner *ngIf="!dataIsLoaded" class="small"></mat-spinner>
                <span class="underline" *ngIf="dataIsLoaded"
                  (click)="verEquipo(pr.subordinates)">{{pr.total_equipo}} personas (Ver
                  equipo)</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="prHeader">
        <div>
          <div>
            <img src="/assets/icons/graficaIcono.svg" alt="">
            <span>Ventas esta semana </span>
          </div>
          <mat-spinner *ngIf="!dataIsLoaded" class="medium adminSpinner"></mat-spinner>
          <span *ngIf="dataIsLoaded">{{estadisticas.ventas_semanales | currency:'EUR'}}</span>
        </div>
        <div>
          <div>
            <img src="/assets/icons/graficaIcono.svg" alt="">
            <span>Ventas totales</span>
          </div>
          <mat-spinner *ngIf="!dataIsLoaded" class="medium adminSpinner"></mat-spinner>
          <span *ngIf="dataIsLoaded">{{estadisticas.ventas_totales | currency:'EUR'}}</span>
        </div>
      </div>
      <div class="estadisticasContainer">
        <p class="noData" *ngIf="estadisticaTabla.length == 0">No hay datos para este RRPP</p>
        <ng-container *ngIf="estadisticaTabla.length != 0">
          <div class="estadisticasHeader">
            <span>Estadísticas según evento</span>
            <searcher [placeHolder]="'Busca un evento aquí'" [toFilter]="estadisticaTablaBackup" [type]="'eventPR'" (filtered)="filteredEvents($event)"></searcher>
          </div>
          <div class="estadisticasBody">
            <div class="evento">
              <span class="titulo">Título del evento</span>
              <span *ngFor="let evento of estadisticaTabla" class="element" (click)="openInfoVentasPR(evento.event_id)">
                {{evento.title}}
              </span>
            </div>
            <div class="entradas">
              <span class="titulo" title="Entradas vendidas (indiv.)">Entradas vendidas (indiv.)</span>
              <span *ngFor="let evento of estadisticaTabla" class="element">
                {{evento.entradas_vendidas}}
                <ng-container *ngIf="evento.entradas_vendidas == 1">
                  entrada
                </ng-container>
                <ng-container *ngIf="evento.entradas_vendidas == 0 || evento.entradas_vendidas > 1">
                  entradas
                </ng-container>
              </span>
            </div>
            <div class="facturacion">
              <span class="titulo" title="Total facturación (equipo)">Total facturación (equipo)</span>
              <span *ngFor="let evento of estadisticaTabla" class="element">
                {{evento.facturacion_total | currency:'EUR'}}
              </span>
            </div>
          </div>
        </ng-container>

      </div>
    </div>
  </ng-container>
</PageTemplate>
