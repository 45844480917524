<div class="navegationPasos">
  <div class="individualPasoContainer" tabindex="0" *ngFor="let paso of pasos; let i = index" [ngClass]="{ activo: i <= activo, activoSiguiente: (i+1) <= activo }" (click)="clickNavegation(i);">
    <i class="numberPasos">{{i + 1}}</i>
    <span>{{ paso }}</span>
    <div class="triangleBackground"></div>
    <div class="triangleNextPaso">
      <div class="triangleConnectActualPaso">
    </div>
  </div>
</div>
