<headerMovil></headerMovil>
<div class="bodyGeneralContainer">
    <backButtonTitle [titulo]="'Mis comunidades'"></backButtonTitle>
    <div class="misComunidadesGeneralContainer">
        <p class="sinDatos" *ngIf="misComunidaddes.length == 0">No perteneces a ninguna comunidad</p>
        <ng-container *ngFor="let comunidad of misComunidaddes">
            <div class="card-flat-det">
                <img *ngIf="comunidad.image != null"[src]="comunidad.image" alt="">
                <img *ngIf="comunidad.image != null"src="/assets/antoine-rault-IhWRrZx4-kk-unsplash.jpg" alt="">
                <div class="card-info">
                    <div class="card-title">
                        <h5>{{comunidad.name}}</h5>
                    </div>
                    <div class="card-details">
                        <small *ngIf="comunidad.num_members != 1">{{comunidad.num_members}} miembros</small>
                        <small *ngIf="comunidad.num_members == 1">{{comunidad.num_members}} miembro</small>
                        <small class="tp" *ngIf="comunidad.age_range=='NO_RANGE'">TP</small>
                        <small class="tp" *ngIf="comunidad.age_range=='RANGE_14_17'">14-17</small>
                        <small class="tp" *ngIf="comunidad.age_range=='RANGE_16'">+16</small>
                        <small class="adultos" *ngIf="comunidad.age_range=='RANGE_18'">+18</small>
                        <small class="adultos" *ngIf="comunidad.age_range=='RANGE_21'">+21</small>
                        <small class="adultos" *ngIf="comunidad.age_range=='RANGE_30'">+30</small>
                        <small class="adultos" *ngIf="comunidad.age_range=='RANGE_40'">+40</small>
                        <small class="adultos" *ngIf="comunidad.age_range=='RANGE_50'">+50</small>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<botonFlotante [texto]="'Nueva lista'" *ngIf="false"></botonFlotante>
<botonFlotante [texto]="'Copiar código'" [tipo]="4"></botonFlotante>
