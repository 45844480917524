import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { CRMService } from 'src/app/services/Api/CRM.service';
import { CampaignService } from 'src/app/services/Api/Campaign.service';
import { EventService } from 'src/app/services/Api/event.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'audiencia',
  templateUrl: './Audiencia.component.html',
  styleUrls: ['./Audiencia.component.scss'],
})
export class AudienciaComponent extends UnsubscribesDestroy implements OnInit {
  @Output() data = new EventEmitter<any>();
  @Output() validStep = new EventEmitter<Boolean>();
  @Output() dataToUpdate = new EventEmitter<any>();
  @Input() receiveData: any = null;

  audiencias: Array<any> = [];
  audienciaSeleccionada: any = null;
  audienciaTotal: number = 0;

  centros: Array<any> = [];
  centroSeleccionado: any = null;

  eventos: Array<any> = [];
  eventoSeleccionado: any = null;

  ciudadObjetivo: string = '';
  ciudadObjetivoError: boolean = false;
  edadInicio: number | null = null;
  edadInicioError: boolean = false;
  edadFin: number | null = null;
  edadFinError: boolean = false;
  generoActivo: string = 'Desconocido';
  nombreAudiencia: string = '';
  nombreAudienciaError: boolean = false;
  editar: boolean = false;
  constructor(
    private campaingsSE: CampaignService,
    private notificaciones: NotificacionesService,
    private route: ActivatedRoute,
    private crmSE: CRMService,
    private eventSE: EventService
  ) {
    super();
  }

  ngOnInit() {
    if (
      this.route.snapshot.params['id'] != null ||
      this.route.snapshot.params['id'] != undefined
    ) {
      this.editar = true;
    }
    this.setData();
    this.getAllAudiences();
    this.calculateAudiencia();
    this.getAllCenters();
    this.getAllActiveEvents();
  }

  /**
   * GETTERS & SETTERS
   */
  setData() {
    if (this.receiveData != null) {
      this.audienciaSeleccionada = this.receiveData;
      this.nombreAudiencia = this.audienciaSeleccionada.name;
      this.edadInicio = this.audienciaSeleccionada.from_age;
      this.edadFin = this.audienciaSeleccionada.until_age;
      this.ciudadObjetivo = this.audienciaSeleccionada.locality;
      this.audienciaTotal = this.audienciaSeleccionada.alcance;
      if (this.audienciaSeleccionada.centers) {
        this.audienciaSeleccionada.centers?.length > 1
          ? (this.centroSeleccionado = this.audienciaSeleccionada.centers)
          : (this.centroSeleccionado = this.audienciaSeleccionada.centers[0]);
      }
      if (this.audienciaSeleccionada.evento) {
        this.audienciaSeleccionada.evento?.length > 1
          ? (this.eventoSeleccionado = this.audienciaSeleccionada.events)
          : (this.eventoSeleccionado = this.audienciaSeleccionada.events[0]);
      }

      if (this.audienciaSeleccionada.gender == null) {
        this.generoActivo = 'Desconocido';
      } else {
        this.generoActivo = this.audienciaSeleccionada.gender;
      }
    }
  }
  getAudiencia() {
    if (this.audienciaSeleccionada) {
      this.nombreAudiencia = this.audienciaSeleccionada.name;
      this.edadInicio = this.audienciaSeleccionada.from_age;
      this.edadFin = this.audienciaSeleccionada.until_age;
      this.ciudadObjetivo = this.audienciaSeleccionada.locality;
      this.audienciaTotal = this.audienciaSeleccionada.alcance;
      if (this.audienciaSeleccionada.centers?.length > 1) {
        this.centroSeleccionado = this.audienciaSeleccionada?.centers?.map(
          (element) => element.id
        );
      } else {
        this.centroSeleccionado = this.audienciaSeleccionada?.centers?.map(
          (element) => element.id
        )[0];
      }
      if (this.audienciaSeleccionada.evento?.length > 1) {
        this.eventoSeleccionado = this.audienciaSeleccionada?.evento?.map(
          (element) => element.id
        );
      } else {
        this.eventoSeleccionado = this.audienciaSeleccionada?.evento?.map(
          (element) => element.id
        )[0];
      }
      if (this.audienciaSeleccionada.gender == null) {
        this.generoActivo = 'Desconocido';
      } else {
        this.generoActivo = this.audienciaSeleccionada.gender;
      }
      this.calculateAudiencia();
    } else {
      this.clearDataFromInputs();
    }
    if (this.editar) {
      this.dataToUpdate.emit({
        element: 'audience',
        value: this.audienciaSeleccionada.id,
      });
    }
  }

  getCiudad(event: string) {
    this.ciudadObjetivo = event;
  }
  getEdadInicio(event: any) {
    this.edadInicio = event;
  }
  getEdadFin(event: any) {
    this.edadFin = event;
  }
  getGenero(event: any) {
    this.generoActivo = event;
    this.updateGenero();
  }
  getNombre(event: string) {
    this.nombreAudiencia = event;
  }
  /**
   * UPDATERS
   */
  updateCiudad() {
    this.calculateAudiencia();
  }
  updateEdadInicio() {
    this.calculateAudiencia();
  }
  updateEdadFin() {
    this.calculateAudiencia();
  }
  updateGenero() {
    this.calculateAudiencia();
  }

  updateCenter(event) {
    this.calculateAudiencia();
  }

  updateEvent(event) {
    this.calculateAudiencia();
  }

  makeSureArray(event, type) {
    switch (type) {
      case 'event':
        if (!this.eventoSeleccionado) return;
        if (
          typeof this.eventoSeleccionado == 'string' ||
          typeof this.eventoSeleccionado == 'number'
        ) {
          this.eventoSeleccionado = [event];
        }
        break;
      case 'center':
        if (!this.centroSeleccionado) return;
        if (
          typeof this.centroSeleccionado == 'string' ||
          typeof this.centroSeleccionado == 'number'
        ) {
          this.centroSeleccionado = [event];
        }
        break;
    }
  }

  checkDeleteSelector(eventSelected) {
    this.getAudiencia();
  }

  clearDataFromInputs() {
    this.nombreAudiencia = '';
    this.edadInicio = 0;
    this.edadFin = 0;
    this.ciudadObjetivo = '';
    this.audienciaTotal = 0;
    this.generoActivo = 'Desconocido';
    this.audienciaSeleccionada = null;
    this.centroSeleccionado = null;
    this.eventoSeleccionado = null;
  }

  /**
   * VALIDATORS
   */
  validarNombre() {
    if (this.nombreAudiencia == '') {
      this.nombreAudienciaError = true;
    } else {
      this.nombreAudienciaError = false;
    }
  }
  validarPaso2() {
    if (this.audienciaSeleccionada != null) {
      var step2Obj = {
        id: this.audienciaSeleccionada.id,
        name: this.audienciaSeleccionada.name,
        from_age: this.audienciaSeleccionada.from_age,
        until_age: this.audienciaSeleccionada.until_age,
        locality: this.audienciaSeleccionada.locality,
        gender: this.audienciaSeleccionada.gender,
        alcance: this.audienciaSeleccionada.alcance,
        events: this.getFormatReturnedData(
          this.audienciaSeleccionada,
          'evento'
        ),
        centers: this.getFormatReturnedData(
          this.audienciaSeleccionada,
          'centers'
        ),
      };
      this.data.emit(step2Obj);
      this.validStep.emit(true);
    } else {
      this.validStep.emit(false);
      this.notificaciones.showError('Debes seleccionar una audiencia');
    }
  }

  getFormatReturnedData(audienciaSeleccionada, type) {
    // Si el objeto tiene un atributo events y hemos llamado a la función con el tipo evento, lo retonamos directamente porque ya tiene el formato que necesitamos
    if (audienciaSeleccionada.events && type === 'evento')
      return audienciaSeleccionada.events;
    // Si el audienciaSeleccionada[type] es un array y el primer elemento tiene un atributo id, lo mapeamos para obtener un array de ids
    if (Array.isArray(audienciaSeleccionada[type])) {
      if (audienciaSeleccionada[type][0]?.id) {
        if (audienciaSeleccionada[type].length > 1)
          return audienciaSeleccionada[type].map((element) => element.id);
        if (audienciaSeleccionada[type].length == 1)
          return [audienciaSeleccionada[type][0].id];
      } else {
        // Si el audienciaSeleccionada[type] es un array pero no tiene un atributo id, lo retornamos directamente porque ya tiene el formato que necesitamos
        return audienciaSeleccionada[type];
      }
    } else {
      // Si el audienciaSeleccionada[type] no es un array, lo retornamos en un array
      return audienciaSeleccionada[type]
        ? [audienciaSeleccionada[type].id]
        : [];
    }
  }

  /**
   * CALLS TO API
   */
  getAllAudiences() {
    this.campaingsSE.getAllAudiences();
    this.campaingsSE
      .getResult()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (res == null) {
          return;
        }
        this.audiencias = res;
        this._unsub.next('');
      });
  }
  updateAudience() {
    this.campaingsSE.updateAudience(this.audienciaSeleccionada.id, {
      name: this.nombreAudiencia,
      fromAge: this.edadInicio,
      untilAge: this.edadFin,
      gender: this.generoActivo,
    });
    this.campaingsSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (res == null) {
          return;
        }
        this.notificaciones.showFeedBack(res.message);
        this.getAllAudiences();
        this.audienciaSeleccionada = res.data;
        this._unsubInd.next('');
      });
    this.campaingsSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (res == null) {
          return;
        }
        this.notificaciones.showError(res.error.message);
        this._unsub.next('');
      });
  }
  createAudience() {
    let centers: any = [];
    let events: any = [];
    if (
      typeof this.centroSeleccionado == 'string' ||
      typeof this.centroSeleccionado == 'number'
    ) {
      centers = [this.centroSeleccionado];
    } else {
      centers = this.centroSeleccionado;
    }
    if (
      typeof this.eventoSeleccionado == 'string' ||
      typeof this.eventoSeleccionado == 'number'
    ) {
      events = [this.eventoSeleccionado];
    } else {
      events = this.eventoSeleccionado;
    }
    this.campaingsSE.createAudience({
      name: this.nombreAudiencia,
      fromAge: this.edadInicio,
      untilAge: this.edadFin,
      centers: centers,
      events: events,
      gender: this.generoActivo,
      company: sessionStorage.getItem('dcmpny'),
    });
    this.campaingsSE
      .getResultIndividual()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (res == null) {
          return;
        }
        this.notificaciones.showFeedBack(res.message);
        this.getAllAudiences();
        this.audienciaSeleccionada = res.data;
        this._unsubInd.next('');
      });
    this.campaingsSE
      .getResultIndividualError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (res == null) {
          return;
        }
        this.notificaciones.showError(res.error.message);
        this._unsub.next('');
      });
  }
  calculateAudiencia() {
    let url = '?';

    if (this.centroSeleccionado) {
      url = url + '&centers=' + this.centroSeleccionado;
    }
    if (this.eventoSeleccionado) {
      url = url + '&events=' + this.eventoSeleccionado;
    }
    if (this.edadInicio != null) {
      url = url + '&fromAge=' + this.edadInicio;
    }
    if (this.edadFin != null) {
      url = url + '&untilAge=' + this.edadFin;
    }
    // if (this.ciudadObjetivo != "") {
    //   url = url + "&locality=" + this.ciudadObjetivo;
    // }
    if (this.generoActivo != 'Desconocido') {
      url = url + '&gender=' + this.generoActivo;
    }
    this.campaingsSE.calculateAudience(url);
    this.campaingsSE
      .getResultIndividual()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (res == null) {
          return;
        }
        this.audienciaTotal = res;
        this._unsubInd.next('');
      });
  }

  getAllCenters() {
    this.crmSE.getCenters();
    this.crmSE
      .getResult()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.centros = res;
        this._unsubInd3.next('');
      });
    this.crmSE
      .getResultError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            this.notificaciones.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            this.notificaciones.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            this.notificaciones.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  getAllActiveEvents() {
    this.eventSE.getEventSelect();
    this.eventSE
      .getResultEvent()
      .pipe(takeUntil(this._unsubInd4))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.eventos = res;
        this._unsubInd4.next('');
      });
    this.eventSE
      .getResultError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            this.notificaciones.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            this.notificaciones.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            this.notificaciones.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  /**
   * FUNCTIONALITY
   */

  saveAudience() {
    this.validarNombre();
    if (!this.nombreAudienciaError) {
      if (this.audienciaSeleccionada == null) {
        this.createAudience();
      } else {
        this.updateAudience();
      }
    } else {
      this.notificaciones.showError('No pueden haber campos vacíos');
    }
  }
}
