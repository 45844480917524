import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take, takeUntil } from 'rxjs';
import { Fecha } from 'src/app/models/Fecha';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { CustomDatesPopupComponent } from 'src/app/popups/CustomDatesPopup/CustomDatesPopup.component';
import { EventService } from 'src/app/services/Api/event.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { StadisticsService } from 'src/app/services/Api/stadistics.service';
import { UserService } from 'src/app/services/Api/user.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PopupService } from 'src/app/services/utils/popup.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent extends UnsubscribesDestroy implements OnInit {

  //NEW VARIABLES
  dateSteps: Array<String> = ["7 días", "14 días", "Mensual", "Anual", "Personalizado"]
  toggleElements: Array<String> = ["Ventas euros", "Ventas unidades"]
  selectedStep: Number = 0
  selectedToggle: Number = 0
  events: Array<any> = [];
  eventSelected: Number | null = null
  fecha = new Date();
  eventosSeleccionados: any = [];
  estadisticas: any = { entradas_vendidas: 0, event_week: 0, new_pr: 0, travel_pack_sold: 0, ventas: 0 }
  isDataLoaded: Boolean = false;
  dataIsLoadedAuxiliar: Boolean = false;
  tipo: String = "ventas";
  datosGrafica: any = []
  permiso: Boolean = false;
  inicio: string = ""
  fin: string = ""
  constructor(private loginSE: LoginService, private userSE: UserService, private loader: LoaderService, private eventsSE: EventService, private notificaciones: NotificacionesService, private router: Router, private estadisticasSE: StadisticsService, private popupSE: PopupService) {
    super();
  }
  ngOnInit(): void {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      if (sessionStorage.getItem("nmsr") == null || sessionStorage.getItem("nmsr") == undefined) {
        this.getMyData();
      } else {
        this.checkPermisos();
      }

    }
  }
  getCustomDays(startDate, endDate, steps = 1) {
    const dateArray: any = [];
    let currentDate = new Date(startDate);

    while (currentDate <= new Date(endDate)) {
      dateArray.push(new Date(currentDate).getDate());
      // Use UTC date to prevent problems with time zones and DST
      currentDate.setUTCDate(currentDate.getUTCDate() + steps);
    }
    if (dateArray.length > 31) {
      return true;
    } else {
      return false;
    }
  }
  clickFecha(fecha: Date) {
    this.fecha = fecha;
    this.eventosSeleccionados = []
    this.events.map(element => {
      var d2Aux = new Date(element.startDate)
      var d2: Fecha = new Fecha();
      d2.dia = d2Aux.getDate();
      d2.mes = d2Aux.getMonth() + 1
      d2.year = d2Aux.getFullYear()

      if (d2.dia == fecha.getDate() && d2.mes == (fecha.getMonth() + 1) && d2.year == fecha.getFullYear()) {
        this.eventosSeleccionados.push(element)
      }

    })
  }
  changeStep(event: Number) {
    this.dataIsLoadedAuxiliar = false;
    this.selectedStep = event
    if (event == 4) {
      this.dataIsLoadedAuxiliar = false;
      this.popupSE.openPopup(CustomDatesPopupComponent);
      this.popupSE.returnData().pipe(takeUntil(this._unsubInd6)).subscribe(value => {
        if (value == null) { return; }
        this.inicio = new Date(value.returnValue.fechaInicio).getFullYear() + "-" + (new Date(value.returnValue.fechaInicio).getMonth() + 1) + "-" + new Date(value.returnValue.fechaInicio).getDate()
        this.fin = new Date(value.returnValue.fechaFin).getFullYear() + "-" + (new Date(value.returnValue.fechaFin).getMonth() + 1) + "-" + new Date(value.returnValue.fechaFin).getDate()
        this.getHomeStadistics(this.inicio, this.fin);
        this.getTableStadistics(this.inicio, this.fin)
        this._unsubInd6.next("")
      })
    } else {
      if (this.eventSelected == null) {
        this.getHomeStadistics();
        this.getTableStadistics()
      } else {
        this.getTableEventStadistics(this.eventSelected)
      }

    }
  }
  changeToggle(event: Number) {
    this.selectedToggle = event
    if (this.selectedToggle == 0) {
      this.tipo = "ventas"
      if (this.eventSelected == null) {
        this.getTableStadistics(this.inicio, this.fin)
      } else {
        this.getTableEventStadistics(this.eventSelected, this.fin, this.inicio)
      }
    } else {
      this.tipo = "unidades"
      if (this.eventSelected == null) {
        this.getTableStadistics(this.inicio, this.fin)
      } else {
        this.getTableEventStadistics(this.eventSelected, this.fin, this.inicio)
      }
    }
  }
  lateralScrollingWithWheel() {
    (document.querySelector(".bodyGeneralContainer .eventContainer .eventList .events ul") as HTMLElement).addEventListener("wheel", (evt) => {
      evt.preventDefault();
      (document.querySelector(".bodyGeneralContainer .eventContainer .eventList .events ul") as HTMLElement).scrollLeft += evt.deltaY;

    })
  }
  touchScroll() {

    var slider = document.querySelector(".bodyGeneralContainer .eventContainer .eventList .events ul") as HTMLElement;
    let isDown = false;
    let startX: any;
    let scrollLeft: any;
    slider.addEventListener('mousedown', (e) => {
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
      cancelMomentumTracking();
    });

    slider.addEventListener('mouseleave', () => {
      isDown = false;
      slider.classList.remove('active');
    });

    slider.addEventListener('mouseup', () => {
      isDown = false;
      slider.classList.remove('active');
      beginMomentumTracking();
    });

    slider.addEventListener('mousemove', (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      var prevScrollLeft = slider.scrollLeft;
      slider.scrollLeft = scrollLeft - walk;
      velX = slider.scrollLeft - prevScrollLeft;
    });

    slider.addEventListener('wheel', (e) => {
      cancelMomentumTracking();
    });

    var velX = 0;
    var momentumID: any;

    function beginMomentumTracking() {
      cancelMomentumTracking();
      momentumID = requestAnimationFrame(momentumLoop);
    }
    function cancelMomentumTracking() {
      cancelAnimationFrame(momentumID);
    }
    function momentumLoop() {
      slider.scrollLeft += velX;
      velX *= 0.95;
      if (Math.abs(velX) > 0.5) {
        momentumID = requestAnimationFrame(momentumLoop);
      }
    }
  }
  getMyData() {
    this.userSE.getMyUser();
    this.userSE.getResult().pipe(takeUntil(this._unsubInd3)).subscribe(value => {
      if (value == null) { return }
      sessionStorage.setItem("nmsr", value.id)
      sessionStorage.setItem("dcmpny", value.companyId)
      sessionStorage.setItem("uniqueName", value.uniqueName)
      localStorage.setItem("permisos", value.permisos)
      sessionStorage.setItem("profileImage", value.profileImage)
      sessionStorage.setItem("groupName", value.groupName)
      sessionStorage.setItem("groupId", value.groupId)
      sessionStorage.setItem("groupImage", value.groupImage)
      this._unsubInd3.next("");
      setTimeout(() => {
        this.checkPermisos();

      }, 500);
    })
  }

  fechaAnt:Date|null = null;
  eventsLoaded = false;
  getEvents(fecha?: Date) {
    if(fecha != undefined){
      var today = fecha;
    }else{
      var today = new Date();
    }
    if(this.fechaAnt != null){
      if(this.fechaAnt > today){
        this.fechaAnt = today;
      }else{
        return;
      }
    }else{
      this.fechaAnt = today;
    }
    this.eventsLoaded = false;
    this.events = [];
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    this.eventsSE.getEvents(50000, 1, date)
    this.eventsSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return; }
      this.events = value;
      this.eventsLoaded = true;
      this.loader.closeSlow();
      this._unsubInd.next("")
      this.isDataLoaded = true;

    })
    this.eventsSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return; }
      this.loader.closeSlow();
      this._unsub.next("")
    })
    setTimeout(() => {
      this.clickFecha(new Date(today.toISOString()))
      this.touchScroll();
      this.lateralScrollingWithWheel();
    }, 1500);

  }
  cambiarMes(fecha: Date){
    this.getEvents(fecha);
  }
  getHomeStadistics(fechaInicio?: any, fechaFin?: any) {
    switch (this.selectedStep) {
      case 0:
        this.estadisticasSE.getHomeStadistics(this.getWeeks().firstDay, this.getWeeks().lastDay);
        break;
      case 1:
        this.estadisticasSE.getHomeStadistics(this.getWeeks(14).firstDay, this.getWeeks(14).lastDay);
        break;
      case 2:
        this.estadisticasSE.getHomeStadistics(this.getDaysInMonth((new Date().getMonth() + 1), new Date().getFullYear()).lastDay, this.getDaysInMonth((new Date().getMonth() + 1), new Date().getFullYear()).firstDay);
        break;
      case 3:
        this.estadisticasSE.getHomeStadistics(this.getDaysInYear().lastDay, this.getDaysInYear().firstDay);
        break;
      case 4:
        this.estadisticasSE.getHomeStadistics(fechaFin, fechaInicio);
        break;
    }

    this.estadisticasSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe(value => {
      if (value == null) { return }
      this.estadisticas = value;
      this._unsubInd2.next("");
    })
    this.estadisticasSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    })
  }
  getWeeks(cantidadDias: number = 7) {
    const week = Array(cantidadDias).fill(new Date()).map((date, i) => {
      if (i !== 0) {
        date.setDate(date.getDate() - 1);
      }
      return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    });
    var dates = { firstDay: week[0], lastDay: week[week.length - 1] }

    return dates;
  }
  getDaysInMonth(month, year) {
    var date = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    var days: any = [];
    for (let i = 1; i <= new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate(); i++) {
      days.push(date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + i);
    }
    return { firstDay: days[0], lastDay: days[days.length - 1] }

  }
  getDaysInYear() {
    var firstDay = new Date().getFullYear() + "-01-" + new Date(new Date().getFullYear(), 0, 1).getDate()
    var lastDay = new Date().getFullYear() + "-12-" + new Date(new Date().getFullYear(), 11, 31).getDate()
    return { firstDay: firstDay, lastDay: lastDay }
  }

  getTableStadistics(fechaInicio?: any, fechaFin?: any) {
    this.dataIsLoadedAuxiliar = false;
    switch (this.selectedStep) {
      case 0:
        this.estadisticasSE.getTableHomeStadistics(this.getWeeks().firstDay, this.getWeeks().lastDay, this.tipo);
        break;
      case 1:
        this.estadisticasSE.getTableHomeStadistics(this.getWeeks(14).firstDay, this.getWeeks(14).lastDay, this.tipo);
        break;
      case 2:
        this.estadisticasSE.getTableHomeStadistics(this.getDaysInMonth((new Date().getMonth() + 1), new Date().getFullYear()).lastDay, this.getDaysInMonth((new Date().getMonth() + 1), new Date().getFullYear()).firstDay, this.tipo);
        break;
      case 3:
        this.estadisticasSE.getTableHomeStadistics(this.getDaysInYear().lastDay, this.getDaysInYear().firstDay, this.tipo, true);
        break;
      case 4:
        this.estadisticasSE.getTableHomeStadistics(fechaFin, fechaInicio, this.tipo, this.getCustomDays(fechaFin, fechaInicio));
        break;
    }
    this.estadisticasSE.getResultIndividual().pipe(takeUntil(this._unsubInd4)).subscribe(value => {
      if (value == null) { return }

      this.datosGrafica = []
      this.datosGrafica = value;
      setTimeout(() => {
        this.dataIsLoadedAuxiliar = true;

      }, 500);
      this._unsubInd4.next("");
    })
    this.estadisticasSE.getResultIndividual().pipe(takeUntil(this._unsubInd4)).subscribe(value => {
      if (value == null) { return }
      this.notificaciones.showError(value.error.message)
      this._unsubInd4.next("");
    })
  }
  getTableEventStadistics(idEvento, fechaInicio?: any, fechaFin?: any,) {
    this.dataIsLoadedAuxiliar = false;
    switch (this.selectedStep) {
      case 0:
        this.estadisticasSE.getTableEventStadistics(idEvento, this.getWeeks().firstDay, this.getWeeks().lastDay, this.tipo);
        break;
      case 1:
        this.estadisticasSE.getTableEventStadistics(idEvento, this.getWeeks(14).firstDay, this.getWeeks(14).lastDay, this.tipo);
        break;
      case 2:
        this.estadisticasSE.getTableEventStadistics(idEvento, this.getDaysInMonth((new Date().getMonth() + 1), new Date().getFullYear()).lastDay, this.getDaysInMonth((new Date().getMonth() + 1), new Date().getFullYear()).firstDay, this.tipo);
        break;
      case 3:
        this.estadisticasSE.getTableEventStadistics(idEvento, this.getDaysInYear().lastDay, this.getDaysInYear().firstDay, this.tipo, true);
        break;
      case 4:
        this.estadisticasSE.getTableEventStadistics(idEvento, fechaInicio, fechaFin, this.tipo);
        break;
    }
    this.estadisticasSE.getResultIndividual().pipe(takeUntil(this._unsubInd5)).subscribe(value => {
      if (value == null) { return; }

      this.datosGrafica = value;
      setTimeout(() => {
        this.dataIsLoadedAuxiliar = true;

      }, 500);

      this._unsubInd5.next("");
    })
    this.estadisticasSE.getResultIndividualError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return; }
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    })
  }
  changeEvent() {
    this.datosGrafica = []
    this.dataIsLoadedAuxiliar = false;
    if (this.eventSelected == null) {
      this.getTableStadistics(this.inicio, this.fin)
    } else {
      this.getTableEventStadistics(this.eventSelected, this.fin, this.inicio)
    }
  }
  checkPermisos() {
    // this.loader.open();
    if (localStorage.getItem("tipoUser") == null || localStorage.getItem("tipoUser") == undefined || localStorage.getItem("tipoUser") == '["2"]') {
      this.loginSE.logOut();
      this.notificaciones.showError("No tienes permisos para acceder")
    } else {
      if (localStorage.getItem("tipoUser") == '["0"]' || localStorage.getItem("tipoUser") == '["3"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY") && !localStorage.getItem("permisos")?.includes("CONSULT_COMPANY_GROUP")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else {
          this.getHomeStadistics();
          this.getTableStadistics();
          this.getEvents();
        }
      }
      if (localStorage.getItem("tipoUser") == '["1"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else {
          this.getHomeStadistics();
          this.getEvents();
          this.getTableStadistics();
        }
      }
    }


  }
}
