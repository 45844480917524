import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, catchError } from 'rxjs';
import { Facility } from 'src/app/models/Facility';
import { environment } from 'src/environments/environment';
import { BaseService } from './Base.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class FacilityService extends BaseService{

  private urlApi = environment.urlApi;


  constructor(private http: HttpClient) {
    super();
  }



  getFacilities(){
    this.http.get(this.urlApi + '/api/facility/company/'+sessionStorage.getItem("dcmpny")+'?num_devoluciones=100000&num_pagina=1', this.getHeader()).subscribe({
      next: (data) => {
        var facility:Facility = new Facility();
        facility = Facility.convertFrontObject(data);
        this.sendNextResult('result', facility)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getFacilities, args: [] })
        this.checkStatusError(error);
      },
    });
  }
  getFacilityById(id:number){
    this.http.get(this.urlApi + '/api/facility/'+id, this.getHeader()).subscribe({
      next: (data) => {
        var facility:Facility = new Facility();
        facility = Facility.convertFrontSingleObject(data);
        this.sendNextResult('result', facility)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getFacilityById, args: [id] })
        this.checkStatusError(error);
      },
    });
  }
  updateFacilityById(id:number, facility:any){
    this.http.put(this.urlApi + '/api/facility/'+id, facility,this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultUpdate', data)
      },
      error: (error) => {
        this.sendNextResult('resultUpdateError', error, { method: this.getFacilityById, args: [id, facility] })
        this.checkStatusError(error);
      },
    });
  }
  newFacility(facility){
    this.http.post(this.urlApi + '/api/facility',facility, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultIndividual', data)
      },
      error: (error) => {
        this.sendNextResult('resultIndividualError', error, { method: this.newFacility, args: [facility] })
        this.checkStatusError(error);
      },
    });
  }
  obtenerCoordenadas(calle){
    calle =  encodeURIComponent(calle)
    this.http.get('https://maps.googleapis.com/maps/api/geocode/json?key='+environment.key+'&address='+calle).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.obtenerCoordenadas, args: [calle] })
        this.checkStatusError(error);
      },
    });
  }
  getFacilitiesOfGroup(){
    this.http.get(this.urlApi + '/api/facility/groups/'+sessionStorage.getItem("groupId"), this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getFacilitiesOfGroup, args: [] })
        this.checkStatusError(error);
      },
    });
  }
  deleteFacility(id){
    var facilities_ids:any = []
    let send={facilities_ids:facilities_ids};
    if(!(id instanceof Array)){send.facilities_ids.push(id);}else{send.facilities_ids=id;}
    this.http.delete(this.urlApi + '/api/facility',this.sendBodyOptions(send)).subscribe({
      next: (data) => {
        this.sendNextResult('resultDelete', data)
      },
      error: (error) => {
        this.sendNextResult('resultDeleteError', error, { method: this.deleteFacility, args: [id] })
        this.checkStatusError(error);
      },
    });
  }
}
