import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, catchError } from 'rxjs';
import { Facility } from 'src/app/models/Facility';
import { environment } from 'src/environments/environment';
import { BaseService } from './Base.service';


@Injectable({
  providedIn: 'root'
})
export class CompanyService extends BaseService {

  private urlApi = environment.urlApi;

  constructor(private http: HttpClient) {
    super();
  }

  getCompanyById(id: number) {
    this.http.get(this.urlApi + '/api/company/' + id, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getCompanyById, args: [id] })
        this.checkStatusError(error);
      },
    });
  }
  updateCompany(id: number, company:any) {
    this.http.put(this.urlApi + '/api/company/' + id,company,this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultUpdate', data)
      },
      error: (error) => {
        this.sendNextResult('resultUpdateError', error, { method: this.updateCompany, args: [id, company] })
        this.checkStatusError(error);
      },
    });
  }
  addNewCompanyContact(contacto:any){
    this.http.post(this.urlApi + '/api/company/contact',contacto,this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.addNewCompanyContact, args: [contacto] })
        this.checkStatusError(error);
      },
    });
  }
  updateCompanyContact(id: number, company:any) {
    this.http.put(this.urlApi + '/api/company/contact/' + id,company,this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultUpdate', data)
      },
      error: (error) => {
        this.sendNextResult('resultUpdateError', error, { method: this.updateCompanyContact, args: [id, company] })
        this.checkStatusError(error);
      },
    });
  }
  deleteCompanyContact(id: any) {
    var companyContacts_ids:any = []
    let send={companyContacts_ids:companyContacts_ids};
    if(!(id instanceof Array)){send.companyContacts_ids.push(id);}else{send.companyContacts_ids=id;}
    this.http.delete(this.urlApi + '/api/company/contact',this.sendBodyOptions(send)).subscribe({
      next: (data) => {
        this.sendNextResult('resultDelete', data)
      },
      error: (error) => {
        this.sendNextResult('resultDeleteError', error, { method: this.deleteCompanyContact, args: [id] })
        this.checkStatusError(error);
      },
    });
  }
  getNextEvents(id:any){
    this.http.get(this.urlApi + '/api/company/'+id+'/events', this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultIndividual', data)
      },
      error: (error) => {
        this.sendNextResult('resultIndividualError', error, { method: this.getNextEvents, args: [id] })
        this.checkStatusError(error);
      },
    });
  }
  getManagerByCompany(id){
    this.http.get(this.urlApi + '/api/company/'+id+'/manager', this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getManagerByCompany, args: [id] })
        this.checkStatusError(error);
      },
    });
  }
  obtainURLStripe(companyId){
    this.http.get(this.urlApi + '/api/company/url/'+companyId, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.obtainURLStripe, args: [companyId] })
        this.checkStatusError(error);
      },
    });
  }
  getEmployeesOfCompany(companyId, comunityId){
    this.http.get(this.urlApi + '/api/community/authorize/'+comunityId+'/'+companyId, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getEmployeesOfCompany, args: [companyId, comunityId] })
        this.checkStatusError(error);
      },
    });
  }
  addNewCompanyGroup(company){
    this.http.post(this.urlApi + '/company/group', company).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.addNewCompanyGroup, args: [company] })
        this.checkStatusError(error);
      },
    });
  }
  addNewCompany(company){
    this.http.post(this.urlApi + '/api/company', company, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.addNewCompany, args: [company] })
        this.checkStatusError(error);
      },
    });
  }
  getAllCompaniesOfGroup(){
    this.http.get(this.urlApi + '/api/company/group/companies/'+sessionStorage.getItem("groupId"), this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getAllCompaniesOfGroup, args: [] })
        this.checkStatusError(error);
      },
    });
  }
  getCompanyGroupById(id){
    this.http.get(this.urlApi + '/api/company/group/' + id, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultIndividual', data)
      },
      error: (error) => {
        this.sendNextResult('resultIndividualError', error, { method: this.getCompanyGroupById, args: [id] })
        this.checkStatusError(error);
      },
    });
  }
  updateCompanyGroup(id: number, company:any) {
    this.http.put(this.urlApi + '/api/company/group/' + id,company,this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultUpdate', data)
      },
      error: (error) => {
        this.sendNextResult('resultUpdateError', error, { method: this.updateCompanyGroup, args: [id,company] })
        this.checkStatusError(error);
      },
    });
  }
  deleteCompany(id){
    var companies_ids:any = []
    let send={companies_ids:companies_ids};
    if(!(id instanceof Array)){send.companies_ids.push(id);}else{send.companies_ids=id;}
    this.http.delete(this.urlApi + '/api/company',this.sendBodyOptions(send)).subscribe({
      next: (data) => {
        this.sendNextResult('resultDelete', data)
      },
      error: (error) => {
        this.sendNextResult('resultDeleteError', error, { method: this.deleteCompany, args: [id] })
        this.checkStatusError(error);
      },
    });
  }
  searchEverythingOfCompany(param){
    this.http.get(this.urlApi + '/api/search/'+param , this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.searchEverythingOfCompany, args: [param] })
        this.checkStatusError(error);
      },
    });
  }
  getOrganizerCompaniesByGroup(){
    this.http.get(this.urlApi + '/api/organizer/group/'+sessionStorage.getItem("groupId")+"?num_devoluciones=10000000&num_pagina=1" , this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultIndividual', data)
      },
      error: (error) => {
        this.sendNextResult('resultIndividualError', error, { method: this.getOrganizerCompaniesByGroup, args: [] })
        this.checkStatusError(error);
      },
    });
  }
}
