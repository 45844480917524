// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  urlAPI: "https://memorable-json-server.herokuapp.com",
  urlApi: "https://dev.grupomemorable.com/back/public/index.php",
  urlImages: "https://dev.grupomemorable.com/back/public/",
  urlFirebase: "https://europe-west1-memorable-test.cloudfunctions.net/api",
  ticketera: "https://ticket.grupomemorable.com/ticket?event=",

  key: "AIzaSyCONFSklba8ydkM_-WjLjCpgE8KBTLgdNI",
  firebaseConfig: {
    apiKey: "AIzaSyBYVghFStNnqhJSmHv8nGq5zEeCeUzFucI",
    authDomain: "memorable-test.firebaseapp.com",
    databaseURL: "https://memorable-test-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "memorable-test",
    storageBucket: "memorable-test.appspot.com",
    messagingSenderId: "352765884797",
    appId: "1:352765884797:web:264e6ee12ff205b529f414",
    vapidKey: "BKaBLNwEQmXitTQpvupAfz5hOgM0ZmEs7VGHwNOqMPrF9ucWZfGIKAEh1yAkSHq87eJxv3HlciJuQOigrPMBxAM"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
