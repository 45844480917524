import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular/lib/fullcalendar.component';
import { CalendarOptions } from '@fullcalendar/core';
import esLocale from '@fullcalendar/core/locales/es';
@Component({
  selector: 'RangeCalendar',
  templateUrl: './RangeCalendar.component.html',
  styleUrls: ['./RangeCalendar.component.scss']
})
export class RangeCalendarComponent implements OnInit {

  months:Array<String> = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"]
  d = new Date();
  mesActual = this.d.getMonth()+1;

  @Input() rango: boolean = true;
  @Input() ini: any = null;
  @Input() year: string | number = ""
  @Input() fin: any = null;
  @Output() fechaSeleccionada = new EventEmitter<any>();
  @ViewChild('calendar') calendarComponent!: FullCalendarComponent;
   ngOnChanges(changes: SimpleChange){
    if (changes["year"] !== undefined) {
      let yearToPass = `${changes["year"].currentValue}-01-01`
      this.calendarComponent?.getApi().gotoDate(yearToPass)
    }
   }
  ngOnInit() {
    if (this.ini != null) {
      if (this.fin != null) {
        var date = new Date(this.fin)
        this.pintarRango(this.ini, date.setDate(date.getDate() + 1));
      } else {
        this.pintarFecha(this.ini);
      }
    }
  }
  constructor() { }
  calendarOptionsInicio: CalendarOptions = {
    initialView: 'dayGridMonth',
    headerToolbar: {
      left: 'prev',
      center: 'title',
      right: 'next',
    },
    locale: esLocale,
    timeZone: 'UTC',
    events: [],
    dateClick: this.handleDateClick.bind(this),
  };
  marcarInicio = true;
  calcularIntervalo() { }
  rellenarDias() { }
  handleDateClick(arg: any) {
    if (this.calendarOptionsInicio.events != undefined) {
      if (!this.rango) {
        this.pintarFecha(arg.date);

      } else {
        if (this.marcarInicio) {
          this.fin = null;
          this.ini = arg.date;
          this.pintarFecha(arg.date);
        } else {
          let res: any = new Date(arg.date.getTime() + 6000);
          this.fin = res;
          this.pintarRango(this.ini, this.fin);
        }

        this.marcarInicio = !this.marcarInicio;
      }
    }
    this.fechaSeleccionada.emit(arg.date);
  }
  pintarFecha(date: any) {
    this.calendarOptionsInicio.events = [
      {
        date: date,
        display: 'block',
      },
    ];
  }

  pintarRango(date1: any, date2: any) {
    this.calendarOptionsInicio.events = [
      {
        start: date1,
        end: date2,
        display: 'block',
      },
    ];
  }

  goToDate(){
    var auxMesActual;
    if (this.mesActual < 10) {
        auxMesActual = "0"+String(this.mesActual)
    }else{
      auxMesActual = String(this.mesActual)
    }
    this.calendarComponent.getApi().gotoDate(this.year+"-"+auxMesActual+"-01")
  }
}
