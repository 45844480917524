import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseService } from './Base.service';

@Injectable({
  providedIn: 'root'
})
export class ReportesService  extends BaseService{

  private urlApi: string = environment.urlApi;

  constructor(private http: HttpClient) {
    super();
  }

  getAll(num_dev: number, num_pagina: number, orderBy: string){
    this.http.get(this.urlApi + '/api/message/report?num_devoluciones='+num_dev+'&num_pagina='+num_pagina+'&orderBy='+orderBy, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getAll, args: [num_dev, num_pagina, orderBy] })
        this.checkStatusError(error);
      },
    });
  }
  getSingle(comunidadId){
    this.http.get(this.urlApi + '/api/message/report/community/'+comunidadId, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultIndividual', data)
      },
      error: (error) => {
        this.sendNextResult('resultIndividualError', error, { method: this.getSingle, args: [comunidadId] })
        this.checkStatusError(error);
      },
    });
  }
  create(mensaje){
    this.http.post(this.urlApi + '/api/message/report',mensaje,this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultUpdate', data)
      },
      error: (error) => {
        this.sendNextResult('resultUpdateError', error, { method: this.create, args: [mensaje] })
        this.checkStatusError(error);
      },
    });
  }
  deleteMessage(id){
    var ids:any = []
    let send={ids:ids};
    if(!(id instanceof Array)){send.ids.push(id);}else{send.ids=id;}
    this.http.delete(this.urlApi + '/api/message/report',this.sendBodyOptions(send)).subscribe({
      next: (data) => {
        this.sendNextResult('resultDelete', data)
      },
      error: (error) => {
        this.sendNextResult('resultDeleteError', error, { method: this.deleteMessage, args: [id] })
        this.checkStatusError(error);
      },
    });
  }
}
