<div class="addNewPrContainer">
  <div class="header">
    <svg (click)="forceClosePopup()" xmlns="http://www.w3.org/2000/svg" width="20.242" height="20.242"
      viewBox="0 0 20.242 20.242">
      <g id="Grupo_1201" data-name="Grupo 1201" transform="translate(-5674.379 -12806.379)">
        <line id="Línea_323" data-name="Línea 323" x2="16" y2="16" transform="translate(5676.5 12808.5)" fill="none"
          stroke="#0e17b6" stroke-linecap="round" stroke-width="3" />
        <line id="Línea_324" data-name="Línea 324" x1="16" y2="16" transform="translate(5676.5 12808.5)" fill="none"
          stroke="#0e17b6" stroke-linecap="round" stroke-width="3" />
      </g>
    </svg>
  </div>
  <div class="body">
    <p>Añadir nuevo rol al equipo</p>
    <ng-container *ngIf="usuarioRegistrado">
      <div class="buscadorContainer">
        <div>
          <button class="btn-xs botonBuscador" (change)="filterUser(filtroCorreo)">
            <mat-icon>search</mat-icon>
          </button>
          <input type="url" placeholder="Buscar un usuario por email" (change)="filterUser(filtroCorreo)"
            [(ngModel)]="filtroCorreo">
        </div>
        <div class="listaContainer" *ngIf="openList">
          <span>{{usuarioFiltrado.email}}</span>
          <div>
            <button (click)="invitado = true;sendInvitation()" *ngIf="!invitado">Invitar</button>
            <button class="invitado" *ngIf="invitado">Invitado</button>
          </div>
        </div>
      </div>
      <div class="dataContainer" >
        <label>¿Quieres añadirle como administrador de un negocio filial?</label>
        <mat-radio-group aria-label="Selecciona una opción" [(ngModel)]="administradorCompany" class="radioFlex">
          <mat-radio-button [value]="false">No</mat-radio-button>
          <mat-radio-button [value]="true" class="radioFullWidth">Sí, ¿cual?</mat-radio-button>
        </mat-radio-group>
        <div class="edadSelect" *ngIf="administradorCompany">
          <ng-select [items]="modalData.empresas" [(ngModel)]="empresa" [searchable]="false" bindLabel="name"
          bindValue="id" [placeholder]="'Selecciona una compañoa'" [clearable]="false"></ng-select>
        </div>
      </div>
      <button class="btn-sm usuarioNoRegistradoBoton" (click)="usuarioRegistrado=false" *ngIf="false">Enviar correo con
        invitación</button>
    </ng-container>
    <ng-container *ngIf="!usuarioRegistrado">
      <div class="dataContainer">
        <label for="" [class.vacio]="nombreError">Nombre</label>
        <input type="text" placeholder="Nombre" [(ngModel)]="nombre" class="inputData">
      </div>
      <div class="dataContainer">
        <label for=""  [class.vacio]="apellidosError">Apellidos</label>
        <input type="text" placeholder="Apellidos"[(ngModel)]="apellidos" class="inputData">
      </div>
      <div class="dataContainer">
        <label for=""  [class.vacio]="phoneError">Teléfono</label>
        <input type="number"[(ngModel)]="phone" placeholder="Número de teléfono" class="inputData">
      </div>
      <div class="dataContainer">
        <label for=""  [class.vacio]="emailError">Email</label>
        <input type="email" [(ngModel)]="email" placeholder="Email" class="inputData">
      </div>
     
      <div class="dataContainer">
        <button class="btn-sm" (click)="sendRegister()">Registrar usuario</button>
      </div>
    </ng-container>
  </div>
</div>