import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { LoginService } from 'src/app/services/Api/login.service';
import { UserService } from 'src/app/services/Api/user.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'app-admin-welcome',
  templateUrl: './admin-welcome.component.html',
  styleUrls: ['./admin-welcome.component.scss']
})
export class AdminWelcomeComponent extends UnsubscribesDestroy implements OnInit {

  isSA: boolean = false;

  constructor(private loginSE: LoginService, private userSE: UserService, private loader: LoaderService, private notificaciones: NotificacionesService, private router: Router) {
    super();
  }

  ngOnInit() {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      this.getMyData();
    }
  }

  //LLAMADAS A LA API
  getMyData() {
    this.userSE.getMyUser();
    this.loader.open();
    this.userSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return }
      this.loader.closeSlow();
      localStorage.setItem("permisos", value.permisos)
      sessionStorage.setItem("nmsr", value.id)
      sessionStorage.setItem("dcmpny", value.companyId)
      sessionStorage.setItem("uniqueName", value.uniqueName)
      sessionStorage.setItem("profileImage", value.profileImage)
      sessionStorage.setItem("groupName", value.groupName)
      sessionStorage.setItem("groupId", value.groupId)
      sessionStorage.setItem("groupImage", value.groupImage)
      sessionStorage.setItem("dcmpny", "")
      if (value.roles.includes("ROLE_SUPER_ADMIN")) {
        this.isSA = true;
      } else {
        this.isSA = false;
      }
      setTimeout(() => {
        this.checkPermisos();

      }, 500);

      this._unsubInd.next("");

    })
  }
  checkPermisos() {
    if (localStorage.getItem("tipoUser") == null || localStorage.getItem("tipoUser") == undefined || localStorage.getItem("tipoUser") == '["2"]') {
      this.loginSE.logOut();
      this.notificaciones.showError("No tienes permisos para acceder")
    } else {
      if (localStorage.getItem("tipoUser") == '["0"]' || localStorage.getItem("tipoUser") == '["3"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY") && !localStorage.getItem("permisos")?.includes("CONSULT_COMPANY_GROUP")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        }
      }
    }

  }
}
