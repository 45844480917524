import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, catchError } from 'rxjs';
import { Facility } from 'src/app/models/Facility';
import { environment } from 'src/environments/environment';
import { BaseService } from './Base.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class TablesService extends BaseService {

  private urlApi = environment.urlApi;

  constructor(private http: HttpClient) {
    super();
  }

  updateTableById(id:number,table:any){
    this.http.put(this.urlApi + '/api/table/' + id, table, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultUpdate', data)
      },
      error: (error) => {
        this.sendNextResult('resultUpdateError', error, { method: this.updateTableById, args: [id, table] })
        this.checkStatusError(error);
      },
    });
  }
  deleteTables(id: any) {
    var tables_ids:any = []
    let send={tables_ids:tables_ids};
    if(!(id instanceof Array)){send.tables_ids.push(id);}else{send.tables_ids=id;}
    this.http.delete(this.urlApi + '/api/table',this.sendBodyOptions(send)).subscribe({
      next: (data) => {
        this.sendNextResult('resultDelete', data)
      },
      error: (error) => {
        this.sendNextResult('resultDeleteError', error, { method: this.deleteTables, args: [id] })
        this.checkStatusError(error);
      },
    });
  }
  newTable(table: any) {
    this.http.post(this.urlApi + '/api/table', table, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.newTable, args: [table] })
        this.checkStatusError(error);
      },
    });
  }
}
