<PageTemplate>
  <ng-container bodyContent>
    <header [searcherActive]="false"></header>
    <GeneralButton [buttonText]="'Añadir nuevo rol en administración'" (click)="addNewAdmin()"
      [class.noPermision]="!editar"></GeneralButton>
    <div class="permisosGeneralContainer">
      <div class="pestañas">
        <div [class.active]="active == 0" (click)="active = 0">Administrador general</div>
        <div [class.active]="active == 1" (click)="active = 1">Administración de negocio</div>
      </div>
      <div class="administradorGeneral" *ngIf="active == 0">
        <div class="administradorGeneralHeader">
          <div>
            <span>Usuario</span>
          </div>
          <div>
            <span>Todos los permisos</span>
          </div>
          <div>
            <span>Consultar y editar</span>
          </div>
          <div>
            <span>Solo consultar</span>
          </div>
          <div>
            <span>Otorgar y denegar nuevos permisos</span>
          </div>
          <div>
            <span>Añadir negocios</span>
          </div>
        </div>
        <div class="administradorGeneralBody" [class.noPermision]="!editar">
          <mat-spinner class="big" *ngIf="!dataIsLoaded"></mat-spinner>
          <ng-container *ngIf="dataIsLoaded">
            <div class="administrador" *ngFor="let admin of groupAdmins" [class.desactivado]="myUser == admin.user_id"
              [class.noPermision]="!editar">
              <div>
                <img *ngIf="admin.profile_image != null" [src]="admin.profile_image" alt="">
                <img *ngIf="admin.profile_image == null" src="/assets/images/defaultImagePerfil.png" alt="">
                <div>
                  <span>{{admin.name}}</span>
                  <span>{{admin.email}}</span>
                </div>

              </div>
              <div>
                <ng-container *ngIf="admin.permisos != null">
                  <mat-checkbox [checked]="admin.permisos.split(',').length == 6"
                    (change)="modificarTodosPermisos(admin);"></mat-checkbox>
                  <label for="" *ngIf="admin.permisos.split(',').length == 6">Activado</label>
                  <label for="" *ngIf="admin.permisos.split(',').length != 6">Desactivado</label>
                </ng-container>
                <ng-container *ngIf="admin.permisos == null">
                  <mat-checkbox [checked]="false" (change)="modificarTodosPermisos(admin);"></mat-checkbox>
                  <label for="">Desactivado</label>
                </ng-container>
              </div>
              <div>
                <ng-container *ngIf="admin.permisos != null">
                  <mat-checkbox
                    [class.desactivado]="!admin.permisos.includes(permisos[1].type) && !admin.permisos.includes(permisos[3].type)"
                    [checked]="admin.permisos.includes(permisos[0].type) && admin.permisos.includes(permisos[2].type) "
                    (change)="comprobarPermiso(admin, permisos[0].type, permisos[2].type, 'admin');"></mat-checkbox>
                  <label for=""
                    [class.desactivado]="!admin.permisos.includes(permisos[1].type) && !admin.permisos.includes(permisos[3].type)"
                    *ngIf="admin.permisos.includes(permisos[0].type) && admin.permisos.includes(permisos[2].type)">Activado</label>
                  <label for=""
                    [class.desactivado]="!admin.permisos.includes(permisos[1].type) && !admin.permisos.includes(permisos[3].type)"
                    *ngIf="!admin.permisos.includes(permisos[0].type) && !admin.permisos.includes(permisos[2].type)">Desactivado</label>
                </ng-container>
                <ng-container *ngIf="admin.permisos == null">
                  <mat-checkbox [checked]="false" [class.desactivado]="true"></mat-checkbox>
                  <label for="" [class.desactivado]="true">Desactivado</label>
                </ng-container>
              </div>
              <div>
                <ng-container *ngIf="admin.permisos != null">
                  <mat-checkbox
                    [checked]="admin.permisos.includes(permisos[1].type) && admin.permisos.includes(permisos[3].type) "
                    (change)="comprobarPermiso(admin, permisos[1].type, permisos[3].type, 'admin');"></mat-checkbox>
                  <label for=""
                    *ngIf="admin.permisos.includes(permisos[1].type) && admin.permisos.includes(permisos[3].type)">Activado</label>
                  <label for=""
                    *ngIf="!admin.permisos.includes(permisos[1].type) || !admin.permisos.includes(permisos[3].type)">Desactivado</label>
                </ng-container>
                <ng-container *ngIf="admin.permisos == null">
                  <mat-checkbox [checked]="false"
                    (change)="comprobarPermiso(admin, permisos[1].type, permisos[3].type, 'admin');"></mat-checkbox>
                  <label for="">Desactivado</label>
                </ng-container>
              </div>
              <div>
                <ng-container *ngIf="admin.permisos != null">
                  <mat-checkbox [checked]="admin.permisos.includes(permisos[4].type)"
                    (change)="comprobarPermiso(admin, permisos[4].type, null, 'admin');"></mat-checkbox>
                  <label for="" *ngIf="admin.permisos.includes(permisos[4].type)">Activado</label>
                  <label for="" *ngIf="!admin.permisos.includes(permisos[4].type)">Desactivado</label>
                </ng-container>
                <ng-container *ngIf="admin.permisos == null">
                  <mat-checkbox [checked]="false" (change)="comprobarPermiso(admin, permisos[4].type, null, 'admin');">
                  </mat-checkbox>
                  <label for="">Desactivado</label>
                </ng-container>
              </div>
              <div>
                <ng-container *ngIf="admin.permisos != null">
                  <mat-checkbox [checked]="admin.permisos.includes(permisos[5].type)"
                    (change)="comprobarPermiso(admin, permisos[5].type, null, 'admin');"></mat-checkbox>
                  <label for="" *ngIf="admin.permisos.includes(permisos[5].type)">Activado</label>
                  <label for="" *ngIf="!admin.permisos.includes(permisos[5].type)">Desactivado</label>
                </ng-container>
                <ng-container *ngIf="admin.permisos == null">
                  <mat-checkbox [checked]="false" (change)="comprobarPermiso(admin, permisos[5].type, null, 'admin');">
                  </mat-checkbox>
                  <label for="">Desactivado</label>
                </ng-container>
              </div>
            </div>
          </ng-container>

        </div>
      </div>

      <div class="administradorNegocio" *ngIf="active == 1">
        <div class="administradorNegocioHeader">
          <div>
            <span>Negocios</span>
          </div>
          <div>
            <span>Usuario</span>
          </div>
          <div>
            <span>Consultar y editar</span>
          </div>
          <div>
            <span>Solo consultar</span>
          </div>
        </div>
        <div class="administradosNegocioBody">
          <mat-spinner class="big" *ngIf="!dataIsLoaded"></mat-spinner>

          <ng-container *ngIf="dataIsLoaded">
            <div class="negocios">
              <div class="negocio" *ngFor="let negocio of negocios"
                (click)="negocioSelect = negocio.id;getAllAdminsOfCompany();"
                [class.active]="negocioSelect == negocio.id">
                <span>{{negocio.name}}</span>
                <span>></span>
              </div>
            </div>
            <div class="empleados" [class.loaded]="!especialDataIsLoaded">
              <p class="noData" *ngIf="negocioSelect == -1">No se ha seleccionado ningun negocio</p>
              <ng-container *ngIf="negocioSelect != -1">
                <mat-spinner *ngIf="!especialDataIsLoaded" class="big"></mat-spinner>
                <ng-container *ngIf="especialDataIsLoaded">
                  <div class="empleado" *ngFor="let empleado of empleados.reverse()"
                  [class.desactivado]="groupAdmins.includes(empleado)" [class.noPermision]="!editar">
                  <div>
                    <img *ngIf="empleado.profile_image != null" [src]="empleado.profile_image" alt="">
                    <img *ngIf="empleado.profile_image == null" src="/assets/images/defaultImagePerfil.png" alt="">
                    <div>
                      <span>{{empleado.name}}</span>
                      <span>{{empleado.email}}</span>
                    </div>

                  </div>
                  <div>
                    <ng-container *ngIf="empleado.permisos != null">
                      <mat-checkbox [class.desactivado]="!empleado.permisos.includes(permisos[1].type)"
                        [checked]="empleado.permisos.includes(permisos[0].type) "
                        (change)="comprobarPermiso(empleado, permisos[0].type)"></mat-checkbox>
                      <label [class.desactivado]="!empleado.permisos.includes(permisos[1].type)" for=""
                        *ngIf="empleado.permisos.includes(permisos[0].type) ">Activado</label>
                      <label [class.desactivado]="!empleado.permisos.includes(permisos[1].type)" for=""
                        *ngIf=" !empleado.permisos.includes(permisos[0].type) ">Desactivado</label>
                    </ng-container>
                    <ng-container *ngIf="empleado.permisos == null">
                      <mat-checkbox [class.desactivado]="true" [checked]="false"
                        (change)="comprobarPermiso(empleado, permisos[0].type)">
                      </mat-checkbox>
                      <label for="" [class.desactivado]="true">Desactivado</label>
                    </ng-container>

                  </div>
                  <div>
                    <ng-container *ngIf="empleado.permisos != null">
                      <mat-checkbox [checked]="empleado.permisos.includes(permisos[1].type) "
                        (change)="comprobarPermiso(empleado, permisos[1].type);"></mat-checkbox>
                      <label for="" *ngIf="empleado.permisos.includes(permisos[1].type)  ">Activado</label>
                      <label for="" *ngIf="!empleado.permisos.includes(permisos[1].type) ">Desactivado</label>
                    </ng-container>
                    <ng-container *ngIf="empleado.permisos == null">
                      <mat-checkbox [checked]="false" (change)="comprobarPermiso(empleado, permisos[1].type)">
                      </mat-checkbox>
                      <label for="">Desactivado</label>

                    </ng-container>


                  </div>
                </div>
                </ng-container>
               
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>

    </div>
  </ng-container>
</PageTemplate>