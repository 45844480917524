<div class="homeGeneralContainer">
  <div class="navbarGeneralContainer">
    <sidebar></sidebar>
  </div>
  <div class="bodyGeneralContainer">
    <div class="navigationForm">
      <div class="allWidthFlex">
        <button [routerLink]="['/admin/bussines/']" class="normalizeBtn">Cancelar</button>
      </div>
      <button class="normalizeBtn" (click)="backPaso()" [attr.disabled]="(activoNav!=0)?null:true">Atrás</button>
      <button (click)="nextPaso()" class="normalizeBtn" *ngIf="activoNav<2" >Siguiente</button>
      <button (click)="nextPaso()" class="normalizeBtn" *ngIf="activoNav>=2" >Finalizar</button>
    </div>
    <main>
      <div class="titleContainer">
        <h3 class="titleWithColor">Añadiendo un nuevo negocio</h3>
      </div>
      <div class="containerFormNavegation">
        <steps [pasos]="pasos"  [(activo)]="activoNav"></steps>
        <div class="separatorPasos"></div>
      </div>
      <div class="containerForm">
        <form-business-basic-information *ngIf="activoNav==0" (valido)="paso1Valido($event)" (dataPaso1)="getDataPaso1($event)" [data]="dataPaso1"></form-business-basic-information>
        <form-business-enclosures *ngIf="activoNav==1" (dataPaso2)="getDataPaso2($event)" [data]="dataPaso2"></form-business-enclosures>
        <form-business-community *ngIf="activoNav==2"  (valido)="paso3Valido($event)" (dataPaso3)="getDataPaso3($event)" [data]="dataPaso3" [rangoEdad]="dataPaso1.ageRange"></form-business-community>
      </div>
    </main>
  </div>
</div>
