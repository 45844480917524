import { Component, OnInit } from '@angular/core';
import { BusinessService } from 'src/app/services/Api/business.service';
import { Business } from 'src/app/models/Business';
import { LoginService } from 'src/app/services/Api/login.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { CompanyService } from 'src/app/services/Api/company.service';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { environment } from 'src/environments/environment';
import { EventService } from 'src/app/services/Api/event.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-PromotionalPage',
  templateUrl: './PromotionalPage.component.html',
  styleUrls: ['./PromotionalPage.component.scss']
})
export class PromotionalPageComponent extends UnsubscribesDestroy implements OnInit {

  urlImages = environment.urlImages
  viewMore = false;
  company: any = {}
  proximosEventos: any = []
  companyProfileImage: string = ""
  companyImage1: string = "";
  companyImage2: string = "";
  companyImage3: string = ""
  companyImage4: string = ""
  companyImage5: string = ""
  companyImage6: string = ""
  companyImage7: string = ""
  companyImage8: string = ""
  companyImage9: string = ""
  bannerImage: string = ""
  normalImages: any = []
  editar: Boolean = false;

  dataLoaded: Boolean = false;
  constructor(private loaderSE: LoaderService, private notifications: NotificacionesService, private loginSE: LoginService, private companySE: CompanyService, private eventSE: EventService, private notificaciones: NotificacionesService, private router: Router) {
    super();
  }

  ngOnInit(): void {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      this.checkPermisos();
    }

  }
  getCompany() {
    this.companySE.getCompanyGroupById(Number(sessionStorage.getItem("groupId")));
    // this.loaderSE.open();
    this.companySE.getResultIndividual().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return }
      this.loaderSE.closeSlow();

      this.company = value;
      this.normalImages = []
      this.company.company_group_images.forEach(element => {
        if (element.is_banner) {
          this.bannerImage = element.image.url
        }
        if (element.is_principal) {
          this.companyProfileImage = element.image.url
        }
        if (!element.is_banner && !element.is_principal) {
          this.normalImages.push(element);
        }
      });
      if (this.normalImages.length != 0) {
        if (this.normalImages[0] != undefined || this.normalImages[0] != null) {
          this.companyImage1 = this.normalImages[0].image.url
          setTimeout(() => {
            (document.querySelector(".photo.one img") as HTMLImageElement).src = this.companyImage1
          }, 500);
        }
        if (this.normalImages[1] != undefined || this.normalImages[1] != null) {
          this.companyImage2 = this.normalImages[1].image.url
          setTimeout(() => {
            (document.querySelector(".photo-detail.two img") as HTMLImageElement).src = this.companyImage2
          }, 500);
        }
        if (this.normalImages[2] != undefined || this.normalImages[2] != null) {
          this.companyImage3 = this.normalImages[2].image.url
          setTimeout(() => {
            (document.querySelector(".photo-detail.three img") as HTMLImageElement).src = this.companyImage3
          }, 500);
        }
        if (this.normalImages[3] != undefined || this.normalImages[3] != null) {
          this.companyImage4 = this.normalImages[3].image.url
          setTimeout(() => {
            (document.querySelector(".photo-detail.four img") as HTMLImageElement).src = this.companyImage4
          }, 500);
        }
        if (this.normalImages[4] != undefined || this.normalImages[4] != null) {
          this.companyImage5 = this.normalImages[4].image.url
          setTimeout(() => {
            (document.querySelector(".photo-detail.five img") as HTMLImageElement).src = this.companyImage5
          }, 500);
        }
        if (this.normalImages[5] != undefined || this.normalImages[5] != null) {
          this.companyImage6 = this.normalImages[5].image.url
          setTimeout(() => {
            (document.querySelector(".photo-detail.six img") as HTMLImageElement).src = this.companyImage6
          }, 500);
        }
        if (this.normalImages[6] != undefined || this.normalImages[6] != null) {
          this.companyImage7 = this.normalImages[6].image.url
          setTimeout(() => {
            (document.querySelector(".photo-detail.seven img") as HTMLImageElement).src = this.companyImage7
          }, 500);
        }
        if (this.normalImages[7] != undefined || this.normalImages[7] != null) {
          this.companyImage8 = this.normalImages[7].image.url
          setTimeout(() => {
            (document.querySelector(".photo-detail.eight img") as HTMLImageElement).src = this.companyImage8
          }, 500);
        }
        if (this.normalImages[8] != undefined || this.normalImages[8] != null) {
          this.companyImage9 = this.normalImages[8].image.url
          setTimeout(() => {
            (document.querySelector(".photo-detail.nine img") as HTMLImageElement).src = this.companyImage9
          }, 500);
        }
      }
      this._unsubInd.next("");
    })

  }
  getNextCompanyByCompany() {
    this.eventSE.getEventByCompanyGroup(3, 1);
    this.eventSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe(value => {
      if (value == null) { return }
      this.proximosEventos = value
      this.dataLoaded = true;
      this._unsubInd2.next("");
    })
  }

  checkPermisos() {
    if (localStorage.getItem("tipoUser") == null || localStorage.getItem("tipoUser") == undefined || localStorage.getItem("tipoUser") == '["2"]') {
      this.loginSE.logOut();
      this.notificaciones.showError("No tienes permisos para acceder")
    } else {
      if (localStorage.getItem("tipoUser") == '["0"]' || localStorage.getItem("tipoUser") == '["3"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY") && !localStorage.getItem("permisos")?.includes("CONSULT_COMPANY_GROUP")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else {
          if (localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY") && localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY_GROUP")) {
            this.getCompany();
            setTimeout(() => {
              this.getNextCompanyByCompany();
            }, 1000);
            this.editar = true;
          } else {
            this.getCompany();
            setTimeout(() => {
              this.getNextCompanyByCompany();
            }, 1000);
          }
        }
      }
    }

  }
  setCompany(proximoEvento) {
    sessionStorage.setItem("dcmpny", proximoEvento.company);
  }
}
