import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'toggleMenu',
  templateUrl: './toggleMenu.component.html',
  styleUrls: ['./toggleMenu.component.scss']
})
export class ToggleMenuComponent implements OnInit {

  @Input() data:Array<String> = []
  @Output() toggleMenu = new EventEmitter<Number>();
  toggleSelected: Number = 0;
  constructor() { }

  ngOnInit() {
  }

  changeToggle(number:Number){
    this.toggleSelected = number;
    this.toggleMenu.emit(number)
  }
}
