<section class="events">
  <ul>
    <ng-container *ngIf="!limite">
      <app-event-item *ngFor="let event of events" [event]="event" [enlace]="enlace" (salida)="devolucion($event)"
        [seleccionado]="seleccoinado == event.id"></app-event-item>
    </ng-container>
    <ng-container *ngIf="limite">
      <app-event-item *ngFor="let event of events  | slice:0:3" [event]="event" [enlace]="enlace" (salida)="devolucion($event)"
        [seleccionado]="seleccoinado == event.id"></app-event-item>
    </ng-container>
  </ul>
</section>