<section class="pr-revenue-weekly-chart">
  <div style="display: block">
    <!-- <canvas
      baseChart
      [data]="lineChartData"
      [options]="lineChartOptions"
      [type]="lineChartType"
      height="440px"
      width="800px"
    >
    </canvas> -->
  </div>
  <div class="legend"></div>
</section>
