<div class="hamburguerMenuGeneralContainer">
  <img [routerLink]="['/rrpp/home']" *ngIf="!opened" src="/assets/icons/memorableLogo.png" alt="">
  <img [routerLink]="['/rrpp/home']" *ngIf="opened" src="/assets/icons/iconoMemorableBlanco.svg" alt="">
  <div id="nav-icon2" (click)="openMenu()">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
</div>
<div class="test">
  <div class="ocean">
    <div class="wave"></div>
  </div>
  <div class="menuGeneralContainer">
    <a [routerLink]="['/rrpp/home']" routerLinkActive="router-link-active" class="link1" #rlaHome="routerLinkActive">

      <svg *ngIf="rlaHome.isActive" xmlns="http://www.w3.org/2000/svg" width="17.41" height="17" viewBox="0 0 17.41 17">
        <g id="Grupo_1910" data-name="Grupo 1910" transform="translate(13.705 -126.5)">
          <path id="Trazado_481" data-name="Trazado 481" d="M3.5,7.5v6.823s11.55-.005,11.627,0,0-6.823,0-6.823" transform="translate(-14.315 128.677)" fill="none" stroke="#0e17b6" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
          <path id="Trazado_482" data-name="Trazado 482" d="M1.5,7.323,9.5.5l8,6.823" transform="translate(-14.5 126.5)" fill="none" stroke="#0e17b6" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
          <circle id="Elipse_909" data-name="Elipse 909" cx="1.344" cy="1.344" r="1.344" transform="translate(-6.344 134.104)" fill="#0e17b6" stroke="#0e17b6" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        </g>
      </svg>
      <svg *ngIf="!rlaHome.isActive" xmlns="http://www.w3.org/2000/svg" width="17.41" height="17" viewBox="0 0 17.41 17">
        <g id="Grupo_1691" data-name="Grupo 1691" transform="translate(13.705 -126.5)">
          <g id="Grupo_1910" data-name="Grupo 1910">
            <path id="Trazado_481" data-name="Trazado 481" d="M3.5,7.5v6.823s11.55-.005,11.627,0,0-6.823,0-6.823" transform="translate(-14.315 128.677)" fill="none" stroke="#fbfbfe" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
            <path id="Trazado_482" data-name="Trazado 482" d="M1.5,7.323,9.5.5l8,6.823" transform="translate(-14.5 126.5)" fill="none" stroke="#fbfbfe" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
            <circle id="Elipse_909" data-name="Elipse 909" cx="1.344" cy="1.344" r="1.344" transform="translate(-6.344 134.104)" fill="#fbfbfe" stroke="#fbfbfe" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
          </g>
        </g>
      </svg>
      Inicio
    </a>
    <a [routerLink]="['/rrpp/mis-comunidades']" routerLinkActive="router-link-active" class="link2" #rlaComunidades="routerLinkActive">
     <img *ngIf="!rlaComunidades.isActive"src="/assets/icons/misComunidades.svg" alt="">
     <img *ngIf="rlaComunidades.isActive"src="/assets/icons/misComunidadesActivo.svg" alt="">
      Mis comunidades</a>
    <a [routerLink]="['/rrpp/mis-eventos']" routerLinkActive="router-link-active" class="link3" #rlaEventos="routerLinkActive">
      <img *ngIf="!rlaEventos.isActive"src="/assets/icons/misEventos.svg" alt="">
     <img *ngIf="rlaEventos.isActive"src="/assets/icons/misEventosActivo.svg" alt="">

      Mis eventos
    </a>
    <a [routerLink]="['/rrpp/mi-equipo']"  routerLinkActive="router-link-active"  #rlaInvitacion="routerLinkActive">
      <mat-icon  *ngIf="rlaInvitacion.isActive" class="activo">add_circle_outline</mat-icon>
      <mat-icon  *ngIf="!rlaInvitacion.isActive" class="noActivo">add_circle_outline</mat-icon>

      Mi equipo
    </a>
    <a [routerLink]="['/rrpp/mi-perfil']" class="link4" routerLinkActive="router-link-active"   #rlaMiPerfil="routerLinkActive">
      <img *ngIf="!rlaMiPerfil.isActive"src="/assets/icons/miPerfil.svg" alt="">
      <img *ngIf="rlaMiPerfil.isActive"src="/assets/icons/miPerfilActivo.svg" alt="">
      Mi perfil
    </a>
    <a (click)="logOut()" class="link5" routerLinkActive="router-link-active">
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
        <g id="Grupo_1909" data-name="Grupo 1909" transform="translate(-12381.25 6659.75)">
          <path id="Trazado_750" data-name="Trazado 750" d="M11.75,4.75l2.916,2.707L11.75,10.165" transform="translate(12383.084 -6658.707)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
          <g id="Grupo_1908" data-name="Grupo 1908">
            <line id="Línea_639" data-name="Línea 639" x1="6.873" transform="translate(12390.669 -6651.25)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
            <path id="Trazado_751" data-name="Trazado 751" d="M13.418.75H3.163A2.316,2.316,0,0,0,.75,2.957V14.543A2.316,2.316,0,0,0,3.163,16.75H13.418" transform="translate(12381 -6660)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
          </g>
        </g>
      </svg>

      Log out MMB Business
    </a>
  </div>
</div>
