<ng-container *ngIf="type == 'carrouselCard'">
  <div class="carrouselCard" (click)="navigateToEvent()">
    <div class="imageContainer">
      <img [src]="data.url" onerror="this.src='/assets/images/placeholder.jpg'" alt="">
      <span
        class="horario">{{data.startTime.split("T")[1].split(":")[0]}}:{{data.startTime.split("T")[1].split(":")[1]}}
        - {{data.finishTime.split("T")[1].split(":")[0]}}:{{data.finishTime.split("T")[1].split(":")[1]}}</span>
    </div>
    <div class="dataContainer">
      <span class="title">{{data.title}}</span>
      <span class="recinto">{{data.name}}</span>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="type == 'eventCard'">
  <div class="eventCard" (click)="navigateToEvent()">
    <div class="imageContainer">
      <img [src]="data.url" onerror="this.src='/assets/images/placeholder.jpg'" alt="">
      <span class="fecha">{{data.startDate | date:'d'}} {{data.startDate | date:'MMM' | uppercase}}</span>
      <div class="facturacionEvento">
        <span>Facturación evento</span>
        <span *ngIf="data.facturacion != null">{{data.facturacion | currency:'EUR'}}</span>
        <span *ngIf="data.facturacion == null">0€</span>
      </div>
      <div class="entradasDisponibles">
        <img src="../../../../assets/icons/ticket.svg" alt="">
        <div>
          <span>Entradas disponibles</span>
          <span>{{data.ticket_vendidos}}/{{data.num_ticket}}</span>
        </div>
      </div>
    </div>
    <div class="dataContainer">
      <span class="title" [title]="data.title">{{data.title}}</span>
      <div class="recintoContainer">
        <span class="recinto" [title]="data.name">{{data.name}}</span>
        <div class="active" *ngIf="data.isActive">
          <span>Activo</span>
        </div>
        <div class="inactive" *ngIf="!data.isActive">
          <span>Inactivo</span>
        </div>
      </div>

    </div>
  </div>
</ng-container>
<ng-container *ngIf="type == 'eventSimpleCard'">
  <div class="eventCard" [class.simple]="data.facility == null">
    <div class="imageContainer" *ngIf="data.event_images != null">
      <img [src]="data.event_images[0].image.url" onerror="this.src='/assets/images/placeholder.jpg'" alt="">
      <span class="fecha">{{data.start_date | date:'d'}} {{data.end_date | date:'MMM' | uppercase}}</span>
    </div>
    <div class="imageContainer" *ngIf="data.event_images == null">
      <img [src]="data.url" onerror="this.src='/assets/images/placeholder.jpg'" alt="">
      <span class="fecha">{{data.start_date | date:'d'}} {{data.end_date | date:'MMM' | uppercase}}</span>
    </div>
    <div class="dataContainer" [class.simple]="data.facility == null">
      <span class="title" [title]="data.title">{{data.title}}</span>
      <div class="recintoContainer" *ngIf="data.facility != null">
        <span class="recinto" [title]="data.name">{{data.facility.name}}</span>
        <div class="active" *ngIf="data.is_active">
          <span>Activo</span>
        </div>
        <div class="inactive" *ngIf="!data.is_active">
          <span>Inactivo</span>
        </div>
      </div>

    </div>
  </div>
</ng-container>
<ng-container *ngIf="type=='facilityCard'">
  <div class="facilityCard">
    <div class="imageContainer" *ngIf="data.map_image != null">
      <img [src]="data.map_image" alt="">
    </div>
    <div class="imageContainer" *ngIf="data.map_image == null">
      <img [src]="'/assets/images/ubicacion.jpg'" alt="">
    </div>
    <div class="dataContainer">
      <span class="title">{{data.name}}</span>
      <div *ngIf="deleteActive">
        <img src="/assets/icons/trash.svg" alt="" style="cursor: pointer;"
          (click)="emitDelete();$event.stopPropagation()">
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="type=='communityCard'">
  <div class="communityCard">
    <div class="imageContainer" *ngIf="data.image != null">
      <img [src]="data.image" onerror="this.src='/assets/images/placeholder.jpg'" alt="">

    </div>
    <div class="imageContainer" *ngIf="data.image == null">
      <img [src]="'/assets/images/placeholder.jpg'" alt="">
    </div>
    <div class="dataContainer">
      <div>
        <span class="title">{{data.name}}</span>
        <div>
          <small>{{data.num_members}} miembros</small>
          <!-- <small class="tp" *ngIf="data.age_range=='NO_RANGE'">TP</small>
          <small class="tp" *ngIf="data.age_range=='RANGE_14_17'">14-17</small>
          <small class="tp" *ngIf="data.age_range=='RANGE_16'">+16</small>
          <small class="tp" *ngIf="data.age_range=='RANGE_18'">+18</small>
          <small class="adultos" *ngIf="data.age_range=='RANGE_21'">+21</small>
          <small class="adultos" *ngIf="data.age_range=='RANGE_30'">+30</small>
          <small class="adultos" *ngIf="data.age_range=='RANGE_40'">+40</small>
          <small class="adultos" *ngIf="data.age_range=='RANGE_50'">+50</small> -->
        </div>
      </div>
      <div>
        <img src="/assets/icons/trash.svg" alt="" style="cursor: pointer;"
          (click)="emitDelete();$event.stopPropagation()">
      </div>
    </div>
  </div>
</ng-container>