import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'backButtonTitle',
  templateUrl: './backButtonTitle.component.html',
  styleUrls: ['./backButtonTitle.component.scss']
})
export class BackButtonTitleComponent implements OnInit {
  @Input() titulo:string = ""
  constructor(private router: Router) {}

  ngOnInit() {
  }
  backForward(){
   
    if (sessionStorage.getItem("returnForm") != null || sessionStorage.getItem("returnForm") != undefined) {
      this.router.navigate([sessionStorage.getItem("returnForm")])
      sessionStorage.removeItem("returnForm");
    }else{
      if (sessionStorage.getItem("lastRoute") != null || sessionStorage.getItem("lastRoute") != undefined) {
        this.router.navigate([sessionStorage.getItem("lastRoute")])
        sessionStorage.removeItem("lastRoute");
      }else{
        this.router.navigate(["/rrpp/home"])
      }
    }
   
  }
}
