<div class="showInfoContainer">
  <div class="header">
    <svg (click)="forceClosePopup()" xmlns="http://www.w3.org/2000/svg" width="20.242" height="20.242"
      viewBox="0 0 20.242 20.242">
      <g id="Grupo_1201" data-name="Grupo 1201" transform="translate(-5674.379 -12806.379)">
        <line id="Línea_323" data-name="Línea 323" x2="16" y2="16" transform="translate(5676.5 12808.5)" fill="none"
          stroke="#0e17b6" stroke-linecap="round" stroke-width="3" />
        <line id="Línea_324" data-name="Línea 324" x1="16" y2="16" transform="translate(5676.5 12808.5)" fill="none"
          stroke="#0e17b6" stroke-linecap="round" stroke-width="3" />
      </g>
    </svg>
  </div>
  <div class="body">
    <ng-container *ngIf="tipo == 'RelacionesPublicas'">
      <p><b>Correo electrónico:</b><br><br>{{modalData.data.email}}</p>
      <br>
      <br>
      <p><b>Teléfono:</b><br><br>{{modalData.data.phone}}</p>
    </ng-container>
  </div>
</div>