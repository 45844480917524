import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { BusinessService } from 'src/app/services/Api/business.service';
import { Business } from 'src/app/models/Business';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { ConfirmPopupComponent } from 'src/app/popups/confirmPopup/confirmPopup.component';
import { take, takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { PopupService } from 'src/app/services/utils/popup.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { CompanyService } from 'src/app/services/Api/company.service';
import { LoaderService } from 'src/app/services/utils/loader.service';

@Component({
  selector: 'app-UpdateGroupData',
  templateUrl: './UpdateGroupData.component.html',
  styleUrls: ['./UpdateGroupData.component.scss']
})
export class UpdateGroupDataComponent extends UnsubscribesDestroy implements OnInit {

  razonSocial = "";
  denominacionComercial = "";
  cif = "";
  direccionFiscal = ""
  anyoFundacion = null;

  razonSocialVacio = false;
  denominacionComercialVacio = false
  cifVacio = false
  direccionFiscalVacio = false
  anyoFundacionVacio = false;

  nombreContacto = ""
  telefonoContacto: any | null = null;
  emailContacto = "";

  nombreContactoVacio = false;
  telefonoContactoVacio = false;
  emailContactoVacio = false;

  cp: Number | null = null;
  cpVacio: Boolean = false;

  activo: number = 0;
  pasos = ['Datos de la empresa', 'Personas de contacto'];
  taxId: number = 0
  personasContacto: any = []
  contactoSeleccionado = {
    id: -1,
    name: "",
    phone: "",
    email: ""
  }
  editar: Boolean = false;
  dataLoaded: Boolean = false;
  constructor(private router: Router, private route: ActivatedRoute, private notifications: NotificacionesService, private popupSE: PopupService, private notificaciones: NotificacionesService, private loginSE: LoginService, private companySE: CompanyService, private laoderSE: LoaderService) {
    super();
  }

  ngOnInit(): void {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      this.checkPermisos();

    }
  }

  getCompany() {
    this.companySE.getCompanyGroupById(Number(sessionStorage.getItem("groupId")));
    // this.laoderSE.open();
    this.companySE.getResultIndividual().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return }
      this.laoderSE.closeSlow();
      this.razonSocial = value.name;
      this.denominacionComercial = value.trade_name;
      this.cif = value.cif;
      this.anyoFundacion = value.year
      this.direccionFiscal = value.address.street
      this.cp = value.address.zip_code
      this.personasContacto = value.company_contacts
      this.taxId = value.address.id
      this._unsubInd.next("");
      this.dataLoaded = true;
    })

  }
  saveChanges(element: any, data: any) {
    if (this.razonSocial == "") {
      this.razonSocialVacio = true;
    } else {
      this.razonSocialVacio = false;
    }
    if (this.denominacionComercial == "") {
      this.denominacionComercialVacio = true;
    } else {
      this.denominacionComercialVacio = false;
    }
    if (this.cif == "") {
      this.cifVacio = true;
    } else {
      this.cifVacio = false;
    }
    if (this.direccionFiscal == "") {
      this.direccionFiscalVacio = true;
    } else {
      this.direccionFiscalVacio = false;
    }
    if (this.anyoFundacion == null) {
      this.anyoFundacionVacio = true;
    } else {
      this.anyoFundacionVacio = false;
    }
    if (this.cp == null) {
      this.cpVacio = true;
    } else {
      this.cpVacio = false;
    }
    if (!this.razonSocialVacio && !this.denominacionComercialVacio && !this.cifVacio && !this.direccionFiscalVacio && !this.cpVacio) {
      this.updateCompany(element, data)
    } else {
      this.notifications.showError("No pueden haber campos vacíos")
    }
  }
  cambiado(pulsado: number) {
    if (pulsado < this.activo) {
      this.activo = pulsado;
    } else {
      if (this.check(pulsado)) {
        this.activo = pulsado;
      }
    }
  }
  check(pagina: number) {
    switch (pagina) {
      case 0:
        return true;
        break;
      case 1:
        return true;
        break;
      default:
        return false;
    }
  }

  setDataContacto() {
    this.nombreContacto = this.contactoSeleccionado.name;
    this.telefonoContacto = this.contactoSeleccionado.phone;
    this.emailContacto = this.contactoSeleccionado.email
  }
  updateDataTransporte() {
    this.contactoSeleccionado.name = this.nombreContacto;
    this.contactoSeleccionado.phone = this.telefonoContacto;
    this.contactoSeleccionado.email = this.emailContacto;
  }
  addPersonaContacto() {
    this.contactoSeleccionado = {
      id: -1,
      name: "",
      phone: "",
      email: ""
    }
    this.companySE.addNewCompanyContact({ name: "", phone: "", email: "", company: Number(sessionStorage.getItem("dcmpny")) })
    this.companySE.getResult().pipe(takeUntil(this._unsubInd3)).subscribe(value => {
      if (value == null) { return };
      this.notificaciones.showFeedBack(value.message)
      this.getCompany();
      this._unsubInd3.next("");
    })
  }
  validEmail(email: string) {
    var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
    return String(email).search(filter) != -1;
  }
  updateCompany(element: any, data: any) {
    var company = {};

    if (element == "taxAddress") {
      company['address'] = { id: this.taxId, street: data }
    } else if (element == "cp") {
      company['address'] = { id: this.taxId, zipCode: data }
    } else {
      company[element] = data;
    }
    this.companySE.updateCompanyGroup(Number(sessionStorage.getItem("groupId")), company);
    this.companySE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return };
      this.notificaciones.showFeedBack(value.message)
      this._unsubInd.next("");
    })
  }

  validarDatos() {
    var vacio = false
    var contactosVacios: any = [];
    this.personasContacto.forEach(element => {
      if (element.name == "" || element.email == "" || element.phone == "") {
        vacio = true;
        contactosVacios.push(element.id);
      }
    });
    if (vacio) {
      this.popupSE.openPopup(ConfirmPopupComponent, { accion: "Descartar", tipo: "campos" })
      this.popupSE.returnData().pipe(takeUntil(this._unsubInd3)).subscribe(value => {
        if (value == null) { return }
        if (value.returnValue.accionHecha) {
          this.companySE.deleteCompanyContact(contactosVacios);
          this.companySE.getResultDelete().pipe(takeUntil(this._unsubInd2)).subscribe(value => {
            if (value == null) { return }
            this.router.navigate(["/publisher/places"])
            this._unsubInd2.next("");
          })
          this.companySE.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe(value => {
            if (value == null) { return }
            this.notificaciones.showFeedBack(value.message)
            this._unsub.next("");
          })
        }
        this._unsubInd3.next("");
      });
    }
  }
  obtainURL() {
    this.companySE.obtainURLStripe(sessionStorage.getItem("dcmpny"));
    this.companySE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return }
      window.open(
        value,
        '_blank' // <- This is what makes it open in a new window.
      );
      this._unsubInd.next("");
    })
    this.companySE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    })
  }
  checkPermisos() {
    if (localStorage.getItem("tipoUser") == null || localStorage.getItem("tipoUser") == undefined || localStorage.getItem("tipoUser") == '["2"]') {
      this.loginSE.logOut();
      this.notificaciones.showError("No tienes permisos para acceder")
    } else {
      if (localStorage.getItem("tipoUser") == '["0"]' || localStorage.getItem("tipoUser") == '["3"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY") && !localStorage.getItem("permisos")?.includes("CONSULT_COMPANY_GROUP")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else {
          if (localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY") && localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY_GROUP")) {
            this.getCompany();
            this.editar = true;
          } else {
            this.laoderSE.open()
            this.getCompany();
          }
        }
      }
    }

  }
}
