import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ManagementInfoCard',
  templateUrl: './ManagementInfoCard.component.html',
  styleUrls: ['./ManagementInfoCard.component.scss']
})
export class ManagementInfoCardComponent implements OnInit {

  @Input() label: String = ""
  @Input() title: String = ""
  @Input() description: String = ""
  @Input() link: String = ""
  @Input() icon: String = ""
  @Input() deleteButton: Boolean = false;
  @Input() idCompany: Number | null = null
  @Input() permiso: Boolean = true;
  @Output() confirmDelete = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    
  }
  setCompanyId(){
    if (this.idCompany != null) {
      sessionStorage.setItem("dcmpny", String(this.idCompany))
    }
  }
  emitDelete(){
    this.confirmDelete.emit(this.idCompany)
  }
}
