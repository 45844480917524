import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { EventService } from 'src/app/services/Api/event.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { StadisticsService } from 'src/app/services/Api/stadistics.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'app-HistoricEvent',
  templateUrl: './HistoricEvent.component.html',
  styleUrls: ['./HistoricEvent.component.scss'],
})
export class HistoricEventComponent
  extends UnsubscribesDestroy
  implements OnInit
{
  cabeceras = [
    'TÍTULO EVENTO',
    'RECINTO',
    'FECHA',
    'ASISTENCIA',
    'VENTAS TOTALES',
    'ESTADO',
  ];
  eventos = [];
  resultados = 5000;
  numPag: number = 1;
  isDemographyGenre: boolean = false;
  isDemographyAge: boolean = true;
  isDemographyInterests: boolean = false;
  dataIsLoaded = false;
  dataIsLoadedAuxiliar = false;
  dataTableLoaded = false;
  diagramaSexo: any = { men: 0, women: 0 };
  diagramaEdad: any = {
    catorce_a_dieciocho: 0,
    dieciocho_a_veintiuno: 0,
    veintiuno: 0,
    treinta: 0,
    cuarenta: 0,
    cincuenta: 0,
  };
  diagramaCategories: any = {};
  topEventos: any = [];
  year = new Date().getFullYear();
  years: any = [];
  ventas = true;
  asistencia = false;
  ventasTotales = [];
  asistenciasTotales = [];
  constructor(
    private loginSE: LoginService,
    private loader: LoaderService,
    private notificaciones: NotificacionesService,
    private eventSE: EventService,
    private estadisticasSE: StadisticsService
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      this.checkPermisos();
    }
  }
  getYears() {
    const now = new Date().getUTCFullYear();
    this.years = Array(now - (now - 43))
      .fill('')
      .map((v, idx) => now - idx) as Array<number>;
  }
  setYear(year) {
    this.topEventos = [];
    this.year = year;
    this.dataIsLoaded = false;
    if (this.ventas) {
      this.getHistoricalSales();
    }
    if (this.asistencia) {
      this.getHistoricalAssist();
    }
    this.getDiagramData();
    this.getDataForTable();
  }
  getHistoricalAssist() {
    this.asistenciasTotales = [];
    this.estadisticasSE.getHistoricalAssist(this.year, this.numPag);
    this.estadisticasSE
      .getResult()
      .pipe(takeUntil(this._unsubInd4))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.asistenciasTotales = value;
        this.dataIsLoaded = true;
        this.loader.closeSlow();
        this._unsubInd4.next('');
      });
    this.estadisticasSE
      .getResultError()
      .pipe(takeUntil(this._unsub))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.notificaciones.showError(value.error.message);
        this.loader.closeSlow();
        this._unsub.next('');
      });
  }
  getHistoricalSales() {
    this.ventasTotales = [];
    this.estadisticasSE.getHistoricalSales(this.year, this.numPag);
    this.estadisticasSE
      .getResult()
      .pipe(takeUntil(this._unsubInd4))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.ventasTotales = value;
        this.dataIsLoaded = true;
        this.loader.closeSlow();
        this._unsubInd4.next('');
      });
    this.estadisticasSE
      .getResultError()
      .pipe(takeUntil(this._unsub))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.notificaciones.showError(value.error.message);
        this.loader.closeSlow();
        this._unsub.next('');
      });
  }
  paginaAnterior() {
    if (this.numPag > 1) {
      this.numPag--;
      this.dataTableLoaded = false;
      this.getDataForTable();
    }
  }
  siguientePagina() {
    this.numPag++;
    this.dataTableLoaded = false;
    this.getDataForTable();
  }
  getDiagramData() {
    // this.diagramaSexo= { men: 0, women: 0 };
    // this.diagramaEdad= { catorce_a_dieciocho: 0, dieciocho_a_veintiuno: 0, veintiuno: 0, treinta: 0, cuarenta: 0, cincuenta: 0 };
    // this.diagramaCategories= {}
    this.dataIsLoadedAuxiliar = false;
    this.estadisticasSE.getEventStadisticsDiagramHistorical(
      sessionStorage.getItem('dcmpny'),
      this.year
    );
    this.estadisticasSE
      .getResultIndividual()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((value) => {
        if (value == null) {
          return;
        }

        this.diagramaSexo = value.sex;
        this.diagramaEdad = value.age;
        this.diagramaCategories = value.categories;
        this.dataIsLoadedAuxiliar = true;
        this._unsubInd2.next('');
      });
    this.estadisticasSE
      .getResultIndividualError()
      .pipe(takeUntil(this._unsub))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.notificaciones.showError(value.error.message);
        this._unsub.next('');
      });
  }
  getTopEvents() {
    this.estadisticasSE.getTopEvents(
      sessionStorage.getItem('dcmpny'),
      this.year
    );
    this.estadisticasSE
      .getResult()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.topEventos = value;
        this._unsubInd3.next('');
      });
    this.estadisticasSE
      .getResultError()
      .pipe(takeUntil(this._unsub))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.notificaciones.showError(value.error.message);
        this._unsub.next('');
      });
  }
  getDataForTable() {
    this.eventSE.getEventHistoryByCompany(
      sessionStorage.getItem('dcmpny'),
      this.year,
      this.numPag
    );

    this.eventSE
      .getResult()
      .pipe(takeUntil(this._unsubInd5))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.eventos = value;
        this.dataTableLoaded = true;

        this._unsubInd5.next('');
        setTimeout(() => {
          this.getTopEvents();
        }, 500);
      });
    this.eventSE
      .getResultError()
      .pipe(takeUntil(this._unsub))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.notificaciones.showError(value.error.message);
        this._unsub.next('');
      });
  }

  onSelectGenre() {
    this.isDemographyGenre = true;
    this.isDemographyAge = false;
    this.isDemographyInterests = false;
  }

  onSelectAge() {
    this.isDemographyGenre = false;
    this.isDemographyAge = true;
    this.isDemographyInterests = false;
  }

  onSelectInterests() {
    this.isDemographyGenre = false;
    this.isDemographyAge = false;
    this.isDemographyInterests = true;
  }
  nextPage() {
    this.numPag++;
    if (this.asistencia) {
      this.getHistoricalAssist();
    } else {
      this.getHistoricalSales();
    }
  }
  previousPage() {
    if (this.numPag > 1) {
      this.numPag--;
    }
    if (this.asistencia) {
      this.getHistoricalAssist();
    } else {
      this.getHistoricalSales();
    }
  }
  checkPermisos() {
    if (
      localStorage.getItem('tipoUser') == null ||
      localStorage.getItem('tipoUser') == undefined ||
      localStorage.getItem('tipoUser') == '["2"]'
    ) {
      this.loginSE.logOut();
      this.notificaciones.showError('No tienes permisos para acceder');
    } else {
      if (
        localStorage.getItem('tipoUser') == '["0"]' ||
        localStorage.getItem('tipoUser') == '["3"]'
      ) {
        if (localStorage.getItem('permisos') == 'null') {
          this.loginSE.logOut();
          this.notificaciones.showError('No tienes permisos para acceder');
        } else if (
          !localStorage.getItem('permisos')?.includes('CONSULT_COMPANY') &&
          !localStorage.getItem('permisos')?.includes('CONSULT_COMPANY_GROUP')
        ) {
          this.loginSE.logOut();
          this.notificaciones.showError('No tienes permisos para acceder');
        } else {
          this.getHistoricalSales();
          this.getDiagramData();
          this.getYears();
          this.getDataForTable();
        }
      }
      if (localStorage.getItem('tipoUser') == '["1"]') {
        if (localStorage.getItem('permisos') == 'null') {
          this.loginSE.logOut();
          this.notificaciones.showError('No tienes permisos para acceder');
        } else if (
          !localStorage.getItem('permisos')?.includes('CONSULT_COMPANY')
        ) {
          this.loginSE.logOut();
          this.notificaciones.showError('No tienes permisos para acceder');
        } else {
          this.getHistoricalSales();
          this.getDiagramData();
          this.getYears();
          this.getDataForTable();
        }
      }
    }
  }
}
