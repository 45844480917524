<ng-container>
  <GeneralButton [buttonText]="'Ver listado'" *ngIf="!listOpen" (click)="controlOpenList()"></GeneralButton>

  <div class="headerEvento" *ngIf="dataIsLoaded">
    <img src="../../../../assets/icons/memorableLogoText.png" alt="">
    <div class="banner">
      <div class="left-group">
        <div class="date">
          <p>
            {{ event.start_date | date: 'd MMM' | uppercase }}
          </p>
        </div>
        <div class="title">
          <p>
            {{ event.title }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <ngx-scanner-qrcode #action="scanner" (data)="setOutput($event);" (error)="onError($event)" class="movil">
  </ngx-scanner-qrcode>

  <a class="btn-sm" (click)="action.toggleCamera()" style="display: none;">
    Escanear QR
  </a>
</ng-container>
<ng-container>
  <div class=" listGeneralContainer" [class.active]="listOpen">
    <div class="listHeader">
      <mat-icon (click)="controlOpenList()">arrow_back</mat-icon>
      <div class="tickets">
        <img src="/assets/icons/ticket.svg" alt="">
        <p>Listado de entradas vendidas</p>
      </div>

    </div>
    <div class="searcher">
      <input type="text" [(ngModel)]="filter" placeholder="Buscar entradas por DNI" (change)="getFilter()">
      <mat-icon>search</mat-icon>
    </div>
    <div class="entradasList">
      <p class="noData" *ngIf="entradas.length == 0">
        No hay entradas disponibles
      </p>
      <div class="entrada" *ngFor="let entrada of entradas" (click)="validarEntrada(entrada)"
        [class.active]=" (!networkStatus && bufferValidados.includes(entrada.soldTicketId)) || (networkStatus && ticketsYaValidados.includes(entrada.soldTicketId))">
        <div class="icon">
          <img src="/assets/icons/ticket.svg" alt=""
            [class.used]="  (!networkStatus && bufferValidados.includes(entrada.soldTicketId)) || (networkStatus && ticketsYaValidados.includes(entrada.soldTicketId)) ">
        </div>
        <div class="data">
          <p>{{entrada.name}} - {{entrada.ticketName}}</p>
          <div class="moreData">
            <div>
              <p>{{entrada.dni}}</p>
              <p>{{entrada.birthDate | date:'dd MMM yyyy'}}</p>
            </div>
            <div>
              <p>{{entrada.phone}}</p>
              <p>{{entrada.email}}</p>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- <div class="paginacion">
      <span (click)="previous()" [class.disabled]="num_pag == 0">
        <mat-icon>chevron_left</mat-icon>
        Página anterior
      </span>
      <span (click)="next()" [class.disabled]="entradas.length == 0">
        Siguiente página
        <mat-icon>chevron_right</mat-icon>
      </span>
    </div> -->
  </div>
</ng-container>
<p class="validaciones" [class.opened]="listOpen">Aforo: {{count}}</p>