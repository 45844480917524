import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Gallery,
  GalleryComponent,
  GalleryItem,
  GalleryState,
  ImageItem,
  ThumbnailsPosition,
  ImageSize,
} from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import { takeUntil } from 'rxjs';
import { Fecha } from 'src/app/models/Fecha';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { EventService } from 'src/app/services/Api/event.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { PrService } from 'src/app/services/Api/pr.service';
import { StadisticsService } from 'src/app/services/Api/stadistics.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ver-evento',
  templateUrl: './ver-evento.component.html',
  styleUrls: ['./ver-evento.component.scss']
})
export class VerEventoComponent extends UnsubscribesDestroy implements OnInit {
  urlImages = environment.urlImages
  evento: any;
  fechaEvento = new Fecha();
  tabActiva = 0;
  showMore = false;
  limite = 5
  items!: GalleryItem[];
  tipo = 3
  activo = 0;
  pasos = ["Entradas", "Listas"]
  imgFlyer = ""
  transportesList: any = [];
  entradas:any = []
  listas:any = []
  transporteSelect: number | null = null;
  dataIsLoaded: Boolean = false;
  lat: number = 0
  lng: number = 0
  direccion: any = null;
  estadisticas: any = {
    entradas_vendidas: 0,
    ventas_totales: 0,
    listas_activas: 0
}
  isDataLoaded: boolean = false;
  relacionesPublicas: Array<any> = []
  constructor(public gallery: Gallery, public lightbox: Lightbox, private notificaciones: NotificacionesService,  private route: ActivatedRoute,private router: Router, private loginSE: LoginService, private eventSE: EventService, private loaderSE: LoaderService, private estadisticasSE: StadisticsService, private prSE: PrService) {
    super();
  }


  lightboxRef = this.gallery.ref('lightbox');

  ngOnInit() {
    if (!this.loginSE.isLogin()) {
      this.router.navigate(["/login"])
      this.loginSE.logOut(true);
    } else {

      this.getEvent();
      this.getStadistics();

    }


  }
  getEvent() {
    this.eventSE.getEventById(this.route.snapshot.params['id']);
    this.loaderSE.open();
    this.eventSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return }
      this.loaderSE.closeSlow();
      this.evento = value;
      this.lat= Number(this.evento.facility.address.latitude);
      this.lng = Number(this.evento.facility.address.longitude);
      this.direccion = this.evento.facility.address
      var date = new Date(this.evento.start_date)
      this.fechaEvento.dia = date.getDate()
      this.fechaEvento.mes = date.getMonth() + 1;
      this.fechaEvento.year = date.getFullYear();
      this.items = this.evento.event_images.map(
        (item) =>
          new ImageItem({
            src: item,
          })
      );
      this.lightboxRef.setConfig({
        imageSize: ImageSize.Cover,
        thumbPosition: ThumbnailsPosition.Top,

      });
      this.evento.event_images.forEach(element => {
        if (element.is_principal) {
          this.imgFlyer = element.image.url
        }
      });
      this.lightboxRef.load(this.items);
      this.transportesList = this.evento.transports;
      this.dataIsLoaded = true;
      this._unsubInd.next("");
    })
    this.eventSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.loaderSE.closeSlow();
      this.notificaciones.showError(value.error.message);
      this._unsub.next("");
    })
  }
  getTicketsListByEventId(){
    this.eventSE.getTicketsListByEventId(this.route.snapshot.params['id']);
    this.eventSE.getResultTicket().pipe(takeUntil(this._unsubInd2)).subscribe(value => {
      if (value == null) { return }
      this.entradas = value["Ticket"]
      this.listas = value["List"]
      this._unsubInd2.next("");
      this.getVentasByPr()
    })
    this.eventSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.loaderSE.closeSlow();
      this.notificaciones.showError(value.error.message);

      this._unsub.next("");
    })
  }
  getStadistics() {
    this.estadisticasSE.getRRPPStadistics(sessionStorage.getItem("prId"), Number(this.route.snapshot.params['id']));
    this.estadisticasSE.getResultIndividual().pipe(takeUntil(this._unsubInd4)).subscribe(value => {
      if (value == null) { return }
      this.estadisticas = value;
      this.isDataLoaded = true;
      this._unsubInd4.next("");
      setTimeout(() => {
        this.getTicketsListByEventId();

      }, 500);
    })
  }
  getVentasByPr(){
    this.prSE.getVentasByPr(Number(sessionStorage.getItem("prId")), Number(this.route.snapshot.params['id']));
    this.prSE.getResult().pipe(takeUntil(this._unsubInd5)).subscribe(value => {
      if (value == null) { return }
      this.relacionesPublicas = value;
      this._unsubInd5.next("");

    })
    this.prSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.notificaciones.showError(value.message)
      this._unsub.next("");
    })
  }
  cambiado(event) {
    this.activo = event;
  }
  mobileCheck() {
    let check = false;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      check = true
    }
    return check;
  };
}
