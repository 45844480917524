import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/Api/user.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { sha256 } from 'js-sha256';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { takeUntil } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-recoverPassword',
  templateUrl: './recoverPassword.component.html',
  styleUrls: ['./recoverPassword.component.scss']
})

export class RecoverPasswordComponent extends UnsubscribesDestroy implements OnInit {
  password:string = "";
  passwordError:boolean = false;
  queryString = window.location.search;
  urlParams = new URLSearchParams(this.queryString);
  visible: Boolean = false;
  constructor(private loader: LoaderService, private notificaciones: NotificacionesService, private userSE:UserService, private router: Router) {
    super();
  }

  ngOnInit() {
  }
  changePass(){
    if (this.password == "") {
      this.passwordError = true;
      this.notificaciones.showError("No pueden haber campos vacios")
    }else{
      this.passwordError = false;
      var obj = {
        recover_token: this.urlParams.get('token'),
        new_password: sha256(this.password)
      }
      this.userSE.changePass(obj);
      this.loader.open();
      this.userSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value=>{
        if(value==null){return};
        this.loader.closeSlow();
        this.notificaciones.showFeedBack(value.message)
        this.router.navigate(["/login"])
        this._unsubInd.next("");
      })
      this.userSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value=>{
        if(value==null){return};
        this.loader.closeSlow();
        this.notificaciones.showError(value.error.message)
        this._unsub.next("");
      })
    }
  }
}
