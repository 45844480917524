<div class="stepGeneralContainer">
  <div class="stepLeft">
    <p [class.vacio]="fechaInicioError || fechaFinError">Calendario</p>
    <ng-select [items]="years" [(ngModel)]="yearSelected" [searchable]="false" [clearable]="false" (change)="updateFechas()"></ng-select>
    <RangeCalendar *ngIf="calendarioCargado" [year]="yearSelected" [ini]="fechaInicio" [fin]="fechaFin" [rango]="rango" (fechaSeleccionada)="seleccionarFechaEvento($event)"></RangeCalendar>
  </div>
  <div class="stepRight">
    <p [class.vacio]="horaInicioError || horaFinError">Horarios</p>
    <div class="doubleColumn">
      <FormElement [type]="'time'" (updateData)="updateHoraInicio($event)" [receiveData]="horaInicio" [error]="horaInicioError" [label]="'Hora inicio'" [placeholder]="'Hora inicio'" (sendData)="getHoraInicio($event)"></FormElement>
      <FormElement [type]="'time'"  (updateData)="updateHoraFin($event)" [receiveData]="horaFin" [error]="horaFinError" [label]="'Hora fin'"  [placeholder]="'Hora fin'" (sendData)="getHoraFin($event)"></FormElement>
    </div>
    <p>¿Tu evento dura múltiples días?</p>
    <SwitchOptions [elementActive]="recurrencia" [elements]="['Sí, varios días', 'No, un día']" (optionSelected)="getRecurrencia($event)"></SwitchOptions>
  </div>
</div>
