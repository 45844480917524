import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { CRMService } from 'src/app/services/Api/CRM.service';
import { UserService } from 'src/app/services/Api/user.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'app-EditarUsuarioPopup',
  templateUrl: './EditarUsuarioPopup.component.html',
  styleUrls: ['./EditarUsuarioPopup.component.scss']
})
export class EditarUsuarioPopupComponent extends UnsubscribesDestroy implements OnInit {

  nombre = ""
  apellidos = ""
  telefono = ""
  email = ""
  fechaNacimiento = ""
  dni = ""
  date = new Date(this.fechaNacimiento);
  nombreVacio = false
  apellidosVacio = false
  telefonoVacio = false
  emailVacio = false
  fechaNacimientoVacio = false
  dniVacio = false;
  centros: Array<any> = [];
  centro: number | null = null;
  constructor(public dialogRef: MatDialogRef<EditarUsuarioPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private notificaciones: NotificacionesService, private userSE: UserService, private loader: LoaderService, private crmSE: CRMService) {
    super()
  }

  ngOnInit() {
    this.getData();
    this.getCenters();
  }

  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
  setData(data: any) {
    this.nombre = data.name;
    this.apellidos = data.surname;
    this.telefono = data.phone;
    this.email = data.email;
    this.fechaNacimiento = data.birth_date;
    this.dni = data.dni;
    if (data.user_centers.length != 0) {
      this.centro = data.user_centers[0].center.id;

    }
  }
  getData(loaderActive = true) {
    this.userSE.getUserById(this.modalData.id);
    if (loaderActive) {
      this.loader.open();
    }
    this.userSE.getResultIndividual().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (res == null) { return }
      this.setData(res)
      this.loader.closeSlow();
      this._unsubInd.next('')
    })
    this.userSE.getResultIndividualError().pipe(takeUntil(this._unsub)).subscribe((res: any) => {
      if (res == null) { return }
      this.notificaciones.showError(res.error.message)
      this.loader.closeSlow();
      this._unsub.next('')
    })
  }
  getCenters() {
    this.crmSE.getCenters();
    this.crmSE.getResult().pipe(takeUntil(this._unsubInd4)).subscribe(value => {
      if (value == null) { return }

      this.centros = value;
      this._unsubInd4.next("");
    })
    this.crmSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.loader.closeSlow();
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    })
  }
  updateUser(element: any, data: any) {
    var usuario = {};
    if (element == "centers") {
      usuario["centers"] = [data];
    } else {
      usuario[element] = data;
    }
    this.loader.open();
    this.userSE.updateUser(this.modalData.id, usuario);
    this.userSE.getResultUpdate().pipe(takeUntil(this._unsubInd2)).subscribe(value => {
      if (value == null) { return }
      this.loader.closeSlow();
      this.notificaciones.showFeedBack(value.message)
      this.getData(false);
      this._unsubInd2.next("");
    })
    this.userSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.loader.closeSlow();
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    })
  }
  setClick(element) {
    (document.querySelector(element) as HTMLElement).click();
  }

  checkData(element?: any, data?: any) {
    if (this.nombre == "") {
      this.nombreVacio = true;
    } else {
      this.nombreVacio = false;
    }
    if (this.apellidos == "") {
      this.apellidosVacio = true;
    } else {
      this.apellidosVacio = false;
    }
    if (this.dni == "") {
      this.dniVacio = true;
    } else {
      this.dniVacio = false;
    }
    if (this.telefono == null) {
      this.telefonoVacio = true;
    } else {
      this.telefonoVacio = false;
    }
    if (this.email == "") {
      this.emailVacio = true;
    } else {
      this.emailVacio = false;
    }
    if (this.fechaNacimiento == "") {
      this.fechaNacimientoVacio = true;
    } else {
      this.fechaNacimientoVacio = false;
    }

    if (!this.nombreVacio && !this.apellidosVacio && !this.telefonoVacio && !this.emailVacio && !this.fechaNacimientoVacio) {
      if (!this.validEmail(this.email)) {
        this.notificaciones.showError("El formato del mail no es correcto");
        this.emailVacio = true;
      } else {
        this.emailVacio = false;
        this.updateUser(element, data)
      }
    } else {
      this.notificaciones.showError("No pueden haber campos vacíos")
    }
  }
  changeDate(event: any) {
    this.fechaNacimiento = event.value
    this.checkData('birthDate', this.fechaNacimiento)
  }
  validEmail(email: string) {
    var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
    return String(email).search(filter) != -1;
  }

}
