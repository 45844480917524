<div class="stepGeneralContainer">
  <p [class.vacio]="imagenFyerError">Flyer evento</p>
  <div class="flyerContainer">
    <div [ngClass]="{'hasImage': imagenFlyer != null || imagenFlyer != ''}" [ngStyle]="{'background-image': 'url('+imagenFlyer+')'}">
      <button fileInput [masInfo]="masInfo" [accept]="['image/png', 'image/jpeg']" [(fichero)]="imagenFlyer"
      (ficheroChange)="getImagenFlyer(imagenFlyer)"></button>
      <div class="container">
        <img src="/assets/icons/uploadIcon.svg">
        <p >Subir imagen de flyer</p>
      </div>

      <p class="errorImage" *ngIf="errorImage">
        Se ha producido un error al cargar la imagen, porfavor considera cambiarla
      </p>
    </div>
  </div>
</div>
