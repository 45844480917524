import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'lib-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {
  size="big";
  classSize=" CargarIconoNormalSize";

  constructor(public dialogRef: MatDialogRef<LoadingComponent>) { }

  closePopup(){
    this.dialogRef.close();
  }

  ngOnInit() {
    if(this.checkSize!=null){
      this.checkSize();
    }
  }
  checkSize(){
    if(this.size=="small"){
      this.classSize=" CargarIconoSmallSize";
    }else if(this.size=="normal"){
      this.classSize=" CargarIconoNormalSize";
    }else if(this.size=="grande"||this.size=="big"){
      this.classSize=" CargarIconoBigSize";
    }else{
      new Error(this.size+" no es un tamaño válido. Los tamaños válidos son: small, normal, grande/big");
    }
  }

}
