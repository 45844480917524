import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from 'src/app/models/User';
import { environment } from 'src/environments/environment';
import { BaseService } from './Base.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {
  urlApi = environment.urlApi
  constructor(protected http: HttpClient) {
    super();
  }
  getMyUser() {

    this.http.get(this.urlApi + '/api/user/me', this.getHeader()).subscribe({
      next: (data) => {
        var me: User = new User();
        me = User.convertFrontObject(data);
        this.sendNextResult('result', me)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getMyUser, args: [] })
        this.checkStatusError(error);
      },
    });
  }
  getUserById(id: number) {
    this.http.get(this.urlApi + '/api/user/' + id, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultIndividual', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getUserById, args: [id] })
        this.checkStatusError(error);
      },
    });
  }
  updateUser(id, user) {
    this.http.put(this.urlApi + '/api/user/' + id, user, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultUpdate', data)
      },
      error: (error) => {
        this.sendNextResult('resultUpdateError', error, { method: this.updateUser, args: [id, user] })
        this.checkStatusError(error);
      },
    });
  }
  filterUserByEmail(email: string) {
    this.http.get(this.urlApi + '/api/user/search/' + email, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.filterUserByEmail, args: [email] })
        this.checkStatusError(error);
      },
    });
  }
  checkUserByPhone(phone: string) {
    this.http.get(this.urlApi + '/api/user/search/phone/' + phone, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.checkUserByPhone, args: [phone] })
        this.checkStatusError(error);
      },
    });
  }

  sendInvitationEmail(user) {
    this.http.post(this.urlApi + '/api/company/admin/email', user, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.sendInvitationEmail, args: [user] })
        this.checkStatusError(error);
      },
    });
  }
  sendInvitationEmailRRPP(user) {
    this.http.post(this.urlApi + '/api/public/relation/email', user, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.sendInvitationEmailRRPP, args: [user] })
        this.checkStatusError(error);
      },
    });
  }
  addNewUser(user: any) {
    this.http.post(this.urlApi + '/api/employee/adduser', user, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.addNewUser, args: [user] })
        this.checkStatusError(error);
      },
    });
  }
  getDataFromCRM(num_dev, pag) {
    this.http.get(this.urlApi + '/api/user/crm?num_devoluciones=' + num_dev + "&num_pagina=" + pag, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getDataFromCRM, args: [num_dev, pag] })
        this.checkStatusError(error);
      },
    });
  }
  getDataFromCRMAgeRange(num_dev, pag, filter) {
    this.http.get(this.urlApi + '/api/user/crm?num_devoluciones=' + num_dev + "&num_pagina=" + pag + "&age=" + filter, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getDataFromCRMAgeRange, args: [num_dev, pag, filter] })
        this.checkStatusError(error);
      },
    });
  }
  getDataFromCRMGender(num_dev, pag, filter) {
    this.http.get(this.urlApi + '/api/user/crm?num_devoluciones=' + num_dev + "&num_pagina=" + pag + "&gender=" + filter, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getDataFromCRMGender, args: [num_dev, pag, filter] })
        this.checkStatusError(error);
      },
    });
  }
  getDataByMarketing(num_dev, pag, filter) {
    this.http.get(this.urlApi + '/api/user/crm?num_devoluciones=' + num_dev + "&num_pagina=" + pag + "&marketing=" + filter, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getDataByMarketing, args: [num_dev, pag, filter] })
        this.checkStatusError(error);
      },
    });
  }
  filterCRM(filtro) {
    this.http.get(this.urlApi + '/api/user/crm/' + filtro, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.filterCRM, args: [filtro] })
        this.checkStatusError(error);
      },
    });
  }
  changePass(newPass) {
    this.http.post(this.urlApi + '/cambiarPassword', newPass).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error)
        this.checkStatusError(error);
      },
    });
  }
  obtainMyCompanies() {
    this.http.get(this.urlApi + '/api/user/companies', this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultIndividual', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.obtainMyCompanies, args: [] })
        this.checkStatusError(error);
      },
    });
  }
  getAllForSA(nunDevolucines, numPagina, genericFilter) {
    this.http.get(this.urlApi + '/api/user/list?num_devoluciones=' + nunDevolucines + '&num_pagina=' + numPagina + "&genericFilter=" + genericFilter, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getAllForSA, args: [nunDevolucines, numPagina] })
        this.checkStatusError(error);
      },
    });
  }
  getAllSelect() {
    this.http.get(this.urlApi + '/api/user/select', this.getHeader()).subscribe({
      next: (data) => {
        this.customSendNextResult('resultUser', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getAllSelect, args: [] })
        this.checkStatusError(error);
      },
    });
  }
  validateEmail(data) {
    this.http.post(this.urlApi + '/first?code=' + data.code, {}).subscribe({
      next: (data) => {
        this.sendNextResult('resultIndividual', data)
      },
      error: (error) => {
        this.sendNextResult('resultIndividualError', error)
        this.checkStatusError(error);
      },
    });
  }
  customSendNextResult(type: 'resultUser', data: any) {
    let clearFuntion: Function;
    if (type == 'resultUser') {
      this._resultUser.next(data);
      clearFuntion = this.clearResultUser;
    }

    setTimeout(() => {
      this[clearFuntion.name]();
    }, 150);
  }

  _resultUser = new BehaviorSubject<any>(null);
  getResultUser() { return this._resultUser; }
  clearResultUser() { this._resultUser.next(null); }
}
