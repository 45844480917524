<div class="limitWidth">
  <div class="buscadorContainer">
    <GeneralButton [buttonText]="'Añadir nuevo recinto'" (click)="openNewFacility()"></GeneralButton>
  </div>
</div>
<div class="cardsContainer">
  <card [data]="facility" [type]="'facilityCard'" *ngFor="let facility of facilities" (click)="selectFacility(facility)" [class.selected]="facilitiesSelected.includes(facility)"></card>
</div>

<ng-template #tarjeta let-image="image" let-title="title">
  <div class="card">
    <img src="/assets/images/aditya-chinchure-ZhQCZjr9fHo-unsplash.jpg">
    <div class="cardTitle">
      <span>Discoteca Superclub - Indiana</span>
    </div>
  </div>
</ng-template>