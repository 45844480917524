import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { ConfirmPopupComponent } from 'src/app/popups/confirmPopup/confirmPopup.component';
import { CommunitiesService } from 'src/app/services/Api/communities.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { ReportesService } from 'src/app/services/Api/Reportes.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PopupService } from 'src/app/services/utils/popup.service';

@Component({
  selector: 'app-ReportesDetails',
  templateUrl: './ReportesDetails.component.html',
  styleUrls: ['./ReportesDetails.component.scss']
})
export class ReportesDetailsComponent extends UnsubscribesDestroy implements OnInit {

  isDataLoaded: boolean = false;
  reportes: any = [];
  @Input() nombreComunidad: string = ""
  constructor(private loginSE: LoginService, private notificaciones: NotificacionesService, private popupSE: PopupService, private reportesSE: ReportesService, private router: Router, private activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      this.checkPermisos();
    }
  }
  /**
   * FUNCTIONALITY
   */

  confirmDelete(id){
    this.popupSE.openPopup(ConfirmPopupComponent, { accion: "Eliminar", tipo: "el mensaje" })
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd5)).subscribe(value => {
      if (value == null) { return }
      this.deleteMessage(id)
      this._unsubInd5.next("");
    })
  }

  /**
   * CALL TO API
   */
  getSingle() {
    this.reportesSE.getSingle(Number(this.activatedRoute.snapshot.params["id"]));
    this.reportesSE.getResultIndividual().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if (res == null) { return }
      this.reportes = res
      this.isDataLoaded = true;
      this._unsubInd.next('')
    })
  }

  deleteMessage(reporteId) {
    this.reportesSE.deleteMessage(reporteId);
    this.reportesSE.getResultDelete().pipe(takeUntil(this._unsubInd2)).subscribe(value => {
      if (value == null) { return }
      this.notificaciones.showFeedBack(value.message)
      this._unsubInd3.next("");
      this.getSingle();
    })
    this.reportesSE.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    })
  }

  /**
   * PERMISSIONS
   */
  checkPermisos() {
    if (localStorage.getItem("tipoUser") == null || localStorage.getItem("tipoUser") == undefined || localStorage.getItem("tipoUser") != '["3"]') {
      this.loginSE.logOut();
      this.notificaciones.showError("No tienes permisos para acceder")
    } else {
      if (localStorage.getItem("tipoUser") == '["3"]') {
        this.getSingle();
      }
    }

  }
}
