import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private router: Router, private activeRoute: ActivatedRoute, private notificaciones: NotificacionesService) { }
  breadCrums: Array<any> = []
  years:any = []
  year: any = new Date().getUTCFullYear();
  @Input() searcherActive: Boolean = true;
  @Input() buttonActive: Boolean = false;
  @Input() sharedActive: Boolean = false;
  @Input() selectActive: Boolean = false;
  @Input() buttonAction: String = "";
  @Input() idEvent: Number | null = null
  @Input() idComunity: Number | null = null
  @Input() idFacility: Number | null = null
  @Output() yearSelected = new EventEmitter<any>();
  @Output() especialData = new EventEmitter<any>()
  @Input() permiso: Boolean = false;
  ngOnInit() {
    this.breadCrums = this.activeRoute.snapshot.data["breadcrumb"];
    this.getYears()
  }
  redirectTo(){
    if (this.buttonAction == "Crear nuevo evento") {
      this.router.navigate(["/publisher/management/events/my-events/add-event"])
    }else if(this.buttonAction == "Editar evento"){
      this.router.navigate(["/publisher/management/events/my-events/edit-event/"+this.idEvent])
    }else if(this.buttonAction == "Crear nueva comunidad"){
      this.router.navigate(["/publisher/communities-promotions/my-communities/add-community"])
    }else if(this.buttonAction == "Editar comunidad"){
      this.router.navigate(["/publisher/communities-promotions/my-communities/edit-community/"+this.idComunity])
    }else if(this.buttonAction == "Crear nuevo recinto"){
      this.router.navigate(["/publisher/facilities/add-facility"])
    }else if(this.buttonAction == "Editar recinto"){
      this.router.navigate(["/publisher/facilities/edit-facility/"+this.idFacility])
    }else if(this.buttonAction == "Añadir nuevo R.R.P.P"){
      this.especialData.emit(true)
    }else if(this.buttonAction == "Crear campaña"){
      this.router.navigate(["/publisher/communities-promotions/promotions/add-campaign"])
    }
  }
  getYears() {
    const now = new Date().getUTCFullYear();
    this.years = Array(now - (now - 43)).fill('').map((v, idx) => now - idx) as Array<number>;
  }
  setYear(year){
    this.yearSelected.emit(year);
  }
  
}
