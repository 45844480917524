import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { CategoriesService } from 'src/app/services/Api/categories.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'form-business-community',
  templateUrl: './form-business-community.component.html',
  styleUrls: ['./form-business-community.component.scss','../../admin-form-business.component.scss']
})
export class FormBusinessCommunityComponent extends UnsubscribesDestroy implements OnInit {

  //Initial values
  listIntereses:any[]=[];


  communityForm:{
    create:Boolean,
    visibility: Boolean,
    edadMinima: any,
    intereses: any[]
  }={
    create:false,
    visibility: false,
    edadMinima: null,
    intereses: []
  }
  edadesMinima = [{ name: "Todos los públicos", value: "NO_RANGE" }, { name: "De 14 a 17", value: "RANGE_14_17" }, { name: "Más de 16", value: "RANGE_16" }, { name: "Más de 18", value: "RANGE_18" }, { name: "Más de 21", value: "RANGE_21" }, { name: "Más de 30", value: "RANGE_30" }, { name: "Más de 40", value: "RANGE_40" }, { name: "Más de 50", value: "RANGE_50" }];

  nombreComunidad: String = ""
  nombreComunidadError: Boolean = false;

  publica: Boolean = true;
  restriccionEdad: Boolean = false;
  
  edadMinima: String | null= null
  edadMinimaError: Boolean = false;
  
  selectedInteresesError: Boolean = false;

  @Output() dataPaso3 = new EventEmitter<any>();
  @Output() valido = new EventEmitter<boolean>();
  @Input() data:any = null 
  @Input() rangoEdad:any = null 
  dataIsLoaded: Boolean = false;
  constructor(private notificaciones:NotificacionesService, private loader: LoaderService, private categoriesSE: CategoriesService) {
    super();
  }

  ngOnInit() {
    this.getCategoriesFromCommunities();
    if (this.data != null) {
      this.setData();
    }
    this.edadMinima = this.rangoEdad
  }
  toggleSelectedInteres(interes:any){
    if(this.checkSelectedInteres(interes)){
      //forced update
      this.communityForm.intereses=[...this.communityForm.intereses.filter(e=>e!=interes)];
    }else{
      this.communityForm.intereses.push(interes);
    }
  }
  checkSelectedInteres(interes:any){
    //TO DO filter by id when connect api
    return this.communityForm.intereses.filter(e=>e==interes).length!=0;
  }

  validarNombreComunidad(){
    if (this.nombreComunidad == "") {
      this.nombreComunidadError = true;
    }else{
      this.nombreComunidadError = false;
    }
  }
  validarEdadMinima(){
    if (this.edadMinima == null) {
      this.edadMinimaError = true;
    }else{
      this.edadMinimaError = false;
    }
  }
  validarIntereses(){
    if (this.communityForm.intereses.length == 0) {
      this.selectedInteresesError = true;
    }else{
      this.selectedInteresesError = false;
    }
  }
  validarPaso3(){
    this.validarNombreComunidad();
    this.validarIntereses();
    if (this.restriccionEdad) {
      this.validarEdadMinima();
    }
    var dataPaso3:any = null
    if (this.communityForm.create == true) {
      if (!this.nombreComunidadError && !this.selectedInteresesError && !this.edadMinimaError) {
        if (!this.restriccionEdad) {
          this.edadMinima = "NO_RANGE"
        }
        dataPaso3 = {
          name: this.nombreComunidad,
          isPrivate: this.publica,
          isActive: true,
          description: "",
          ageRange: this.edadMinima,
          categories: this.communityForm.intereses
        }
        this.valido.emit(true)
        this.dataPaso3.emit(dataPaso3);
      }else{
        this.notificaciones.showError("Hay errores en los campos, porfavor revisalos")
        this.valido.emit(false)
      }
    }else{
      this.valido.emit(true)
      this.dataPaso3.emit(dataPaso3);
    }
   
  }
  getCategoriesFromCommunities(){
    this.categoriesSE.getCategoriesFromCommunities();
    this.categoriesSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value=>{
      if(value==null){return};
      this.loader.closeSlow();
     
      value.forEach(element => {
        this.listIntereses.push(element.name)  
      });
      this.dataIsLoaded = true;
      this._unsubInd.next("");
    })
    this.categoriesSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value=>{
      if(value==null){return};
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    })
  }
  setData(){
    this.nombreComunidad = this.data.name;
    this.publica = this.data.isPrivate;
    if (this.data.ageRange == "NO_RANGE") {
      this.restriccionEdad = false;
    }else{
      this.restriccionEdad = true;
    }
    this.communityForm.intereses = this.data.categories
  }
}
