import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'botonFlotante',
  templateUrl: './botonFlotante.component.html',
  styleUrls: ['./botonFlotante.component.scss']
})
export class BotonFlotanteComponent implements OnInit {
  @Input() texto: string = "";
  @Input() tipo: number = 1;
  @Input() posicionEspecial: boolean = false;
  constructor(private router: Router, private notificaciones: NotificacionesService, private route: ActivatedRoute) { }

  ngOnInit() {
  }
  newList() {
    this.router.navigate(["/rrpp/nueva-lista"]);
    sessionStorage.setItem("lastRoute", this.router.url)
  }
  returnHome() {
    this.router.navigate(["/rrpp/home"]);
    sessionStorage.removeItem("lastRoute");
  }
  sendLink() {
    this.notificaciones.showFeedBack("Enlace de evento copiado correctamente")
    if (location.host.includes("localhost")) {
      return navigator.clipboard.writeText(environment.ticketera + this.route.snapshot.params['id']+"&rp="+sessionStorage.getItem("prId"));
    }else{
      return navigator.clipboard.writeText(environment.ticketera + this.route.snapshot.params['id']+"&rp="+sessionStorage.getItem("prId"));
    }
  }
  code() {
    this.notificaciones.showFeedBack("Código de RRPP copiado correctamente")
    if (location.host.includes("localhost")) {
      return navigator.clipboard.writeText(sessionStorage.getItem("prCode")!);
    }else{
      return navigator.clipboard.writeText(sessionStorage.getItem("prCode")!);    }
  }
}
