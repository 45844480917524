import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';

@Component({
  selector: 'total-event-asist-chart',
  templateUrl: './total-event-asist-chart.component.html',
  styleUrls: ['./total-event-asist-chart.component.scss']
})
export class TotalEventAsistChartComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  @Input() data:any = [];
  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: true,

    // We use these empty structures as placeholders for dynamic theming.
    scales: {

      x: {
        grid: { color: "rgba(0, 0, 0, 0)" },
        ticks: { // and this
          font: {
            size: 10,
            weight: "bold",
            family: "Rubik",
          },
          color: "#000066"
        },
      },
      y: {
        grid: { borderDash: [2, 8], color: "#CCCCFB", drawBorder: false, drawTicks: false },
        ticks: { // and this
          callback: function (value, index, values) {
            return value + " asistentes";
          },
        },
      }
    },

    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
      },

    },
  };
  public barChartType: ChartType = 'bar';
  public barChartPlugins = [
    DataLabelsPlugin
  ];

  public barChartData: ChartData<'bar'> = {
    labels: [],
    datasets: [
      { data: [], label: 'Series A', backgroundColor: ["#FF9933"], borderRadius: 8, hoverBackgroundColor: "#CC7621" },
    ]
  };

  // events
  public chartClicked({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
  }

  public chartHovered({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
  }
  constructor() { }
  ngOnInit() {
    this.data.forEach(element=>{
      this.barChartData.labels!.push(this.formatLabel(element.title, 5))
      this.barChartData.datasets[0].data.push(element.assist)
    })
    this.chart?.update();
  }

  formatLabel(str, maxwidth){
    var sections:any = [];
    var words = str.split(" ");
    var temp = "";
  
    words.forEach(function(item, index){
      if(temp.length > 0)
      {
        var concat = temp + ' ' + item;
  
        if(concat.length > maxwidth){
          sections.push(temp);
          temp = "";
        }
        else{
          if(index == (words.length-1)) {
            sections.push(concat);
            return;
          }
          else {
            temp = concat;
            return;
          }
        }
      }
  
      if(index == (words.length-1)) {
        sections.push(item);
        return;
      }
  
      if(item.length < maxwidth) {
        temp = item;
      }
      else {
        sections.push(item);
      }
  
    });
  
    return sections;
  }
  
}
