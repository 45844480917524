import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, catchError } from 'rxjs';
import { Facility } from 'src/app/models/Facility';
import { environment } from 'src/environments/environment';
import { BaseService } from './Base.service';


@Injectable({
  providedIn: 'root',
})
export class TicketsService extends BaseService {

  private urlApi = environment.urlApi;

  constructor(private http: HttpClient) {
    super();
  }
  getAllTicketsSA(filters: any) {
    this.http.get(this.urlApi + '/api/ticket/list' + this.optionsGet(filters), this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getAllTicketsSA, args: [filters] })
        this.checkStatusError(error);
      },
    });
  }
  newTicket(ticket: any) {
    this.http.post(this.urlApi + '/api/ticket', ticket, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.newTicket, args: [ticket] })
        this.checkStatusError(error);
      },
    });
  }
  updateTicketById(id: number, ticket: any) {
    this.http.put(this.urlApi + '/api/ticket/' + id, ticket, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultUpdate', data)
      },
      error: (error) => {
        this.sendNextResult('resultUpdateError', error, { method: this.updateTicketById, args: [id, ticket] })
        this.checkStatusError(error);
      },
    });
  }
  updateVisibilityTicketById(id: number, ticket: any) {
    this.http.put(this.urlApi + '/api/product/' + id, ticket, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultUpdate', data)
      },
      error: (error) => {
        this.sendNextResult('resultUpdateError', error, { method: this.updateVisibilityTicketById, args: [id, ticket] })
        this.checkStatusError(error);
      },
    });
  }
  updateTicket(id, ticket: any) {
    this.http.put(this.urlApi + '/api/product/' + id, ticket, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultUpdate', data)
      },
      error: (error) => {
        this.sendNextResult('resultUpdateError', error, { method: this.updateTicket, args: [id, ticket] })
        this.checkStatusError(error);
      },
    });
  }
  validateTicket(ticketId, eventId) {
    this.http.post(this.urlApi + '/validate/' + eventId, ticketId).subscribe({
      next: (data) => {
        this.sendNextResult('resultUpdate', data)
      },
      error: (error) => {
        this.sendNextResult('resultUpdateError', error, { method: this.validateTicket, args: [ticketId, eventId] })
        this.checkStatusError(error);
      },
    });
  }
  invalidarTicket(ticketId, eventId) {
    this.http.post(this.urlApi + '/invalidate/' + eventId, ticketId).subscribe({
      next: (data) => {
        this.sendNextResult('resultUpdate', data)
      },
      error: (error) => {
        this.sendNextResult('resultUpdateError', error, { method: this.validateTicket, args: [ticketId, eventId] })
        this.checkStatusError(error);
      },
    });
  }
  resendEmailTicketsBoughtInWeb(body: {name:string,mail:string,id:number}) {
    this.http.post(this.urlApi + `/api/cart/${body.id}/mail`  , body).subscribe({
      next: (data) => {
        this.sendNextResult('resultUpdate', data)
      },
      error: (error) => {
        this.sendNextResult('resultUpdateError', error, { method: this.resendEmailTicketsBoughtInWeb, args: [body] })
        this.checkStatusError(error);
      },
    });
  }
  buyTickets(ticket) {
    this.http.post(this.urlApi + '/api/soldticket/', ticket, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.buyTickets, args: [ticket] })
        this.checkStatusError(error);
      },
    });
  }
  createInvitation(ticket) {
    this.http.post(this.urlApi + '/api/soldticket/invitation', ticket, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.createInvitation, args: [ticket] })
        this.checkStatusError(error);
      },
    });
  }
  getAllEntradasEvento(eventId: number, num_dev: number, num_pag: number, filter: string) {
    this.http.get(this.urlApi + '/event/participants/' + eventId + "?num_devoluciones=" + num_dev + "&num_pagina=" + num_pag + "&filter=" + filter).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.validateTicket, args: [eventId, num_dev, num_pag, filter] })
        this.checkStatusError(error);
      },
    });
  }
  liberarEntrada(idEntrada) {
    this.http.put(this.urlApi + '/api/ticket/release/preorder/' + idEntrada, {}, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultIndividual', data)
      },
      error: (error) => {
        this.sendNextResult('resultIndividualError', error, { method: this.liberarEntrada, args: [idEntrada] })
        this.checkStatusError(error);
      },
    });
  }
  cambioTitularidad(id: number, user: number) {
    this.http.put(this.urlApi + '/api/sold/ticket/change/name/' + id, { userToAssign: user }, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultUpdate', data)
      },
      error: (error) => {
        this.sendNextResult('resultUpdateError', error, { method: this.liberarEntrada, args: [id, user] })
        this.checkStatusError(error);
      },
    });
  }
  refoundTicket(id: number, preorder: boolean) {

    this.http.post(this.urlApi + '/api/sold/ticket/' + id + '/refound', {'preorder':preorder}, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultUpdate', data)
      },
      error: (error) => {
        this.sendNextResult('resultUpdateError', error, { method: this.refoundTicket, args: [id,preorder] })
        this.checkStatusError(error);
      },
    });
  }
}
