<PageTemplate>
  <ng-container bodyContent>
    <header [searcherActive]="false" [buttonActive]="false"></header>
    <div class="buyValidateGeneralContainer">
      <div class="headerEvento">
        <div class="banner">
          <div class="left-group">
            <div class="date">
              <mat-spinner class="medium adminSpinner" *ngIf="!dataIsLoaded"></mat-spinner>
              <p *ngIf="dataIsLoaded">
                {{ event.start_date | date: 'd MMM' | uppercase }}
              </p>
            </div>
            <div class="title">
              <mat-spinner class="medium adminSpinner" *ngIf="!dataIsLoaded"></mat-spinner>
              <p *ngIf="dataIsLoaded">
                {{ event.title }}
              </p>
            </div>
            <div class="separador"></div>
            <div class="time">
              <mat-spinner class="medium adminSpinner" *ngIf="!dataIsLoaded"></mat-spinner>
              <ng-container *ngIf="dataIsLoaded">
                {{ event.start_time.split("T")[1].split("+")[0].split(":")[0]}}:{{
                event.start_time.split("T")[1].split("+")[0].split(":")[1]}}
                -
                {{ event.finish_time.split("T")[1].split("+")[0].split(":")[0]}}:{{
                event.finish_time.split("T")[1].split("+")[0].split(":")[1]}}
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="buttonContainer">
        <div>
          <span [class.selected]="optionSelected == 0" (click)="optionSelected = 0">Vender entradas</span>
          <span [class.selected]="optionSelected == 1" (click)="optionSelected = 1">Escanear QR</span>
        </div>
        <GeneralButton [buttonText]="'Compartir escáner'" (click)="compartirEscaner()"></GeneralButton>
      </div>
      <BuyTickets *ngIf="optionSelected == 0"></BuyTickets>
      <ValidateTickets *ngIf="optionSelected == 1"></ValidateTickets>
    </div>

  </ng-container>
</PageTemplate>