import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'app-confirmPopup',
  templateUrl: './confirmPopup.component.html',
  styleUrls: ['./confirmPopup.component.scss']
})
export class ConfirmPopupComponent implements OnInit {
  accion = ""
  tipo = ""
  genero = ""
  constructor(public dialogRef: MatDialogRef<ConfirmPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private notificaciones: NotificacionesService) { }

  ngOnInit() {
    this.accion = this.modalData.accion;
    this.tipo = this.modalData.tipo
    if (this.modalData.genero == "masculino") {
      this.genero = "el"
    }else if (this.modalData.genero == "femenino") {
      this.genero = "la"
    }

  
  }
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
}
