<div class="stepContainer">
  <div class="shareButton">
    <button class="" type="button" (click)="sendLink()">
      <mat-icon>share</mat-icon>
      Compartir
    </button>
  </div>

  <div class="imagesAndDescription">
    <div class="mainImageAndGallery">
      <div class="informacionEvento">
        <div class="float-group">
          <div class="float-date">
            <span>{{ event.start_date | date: 'd MMM' | uppercase }}</span>
          </div>
          <div class="float-time">
            <span
              >{{ event.start_time | date: 'hh:mm' | uppercase }}
              -
              {{ event.finish_time | date: 'hh:mm' | uppercase }}</span
            >
          </div>
        </div>

        <ng-container *ngFor="let imagen of event.event_images">
          <img *ngIf="imagen.is_principal" [src]="imagen.image.url" alt="" />
        </ng-container>

        <div class="details">
          <div class="details-group">
            <h4>Ubicación y dirección</h4>
            <p>
              {{ event.facility.address.street }}
              {{ event.facility.address.number }},
              {{ event.facility.address.locality }},
              {{ event.facility.address.province }}
            </p>
          </div>
          <div class="details-group">
            <h4>Recinto</h4>
            <p>{{ event.facility.name }}</p>
          </div>
          <div class="details-group">
            <h4>Redes Sociales</h4>
            <p>
              <ng-container
                *ngFor="let social_medias of event.company.social_medias"
                >{{ social_medias }},
              </ng-container>
            </p>
          </div>
          <div class="details-group">
            <h4>Página web</h4>
            <p>{{ event.company.webpage }}</p>
          </div>
          <div class="details-group">
            <h4>Temática</h4>
            <ul>
              <li *ngFor="let categoria of event.categories">
                <span class="micro-tag">{{ categoria.name }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="gallery">
        <div class="pictures">
          <ng-container *ngFor="let imagen of event.event_images">
            <div class="picture" *ngIf="!imagen.is_principal">
              <img
                [src]="imagen.image.url"
                alt=""
                onerror="this.src='/assets/images/placeholder.jpg'"
              />
            </div>
          </ng-container>
        </div>
        <a
          class="imagenContainer"
          fileInput
          [accept]="['image/jpeg', 'image/png']"
          [(fichero)]="fileImagen"
          (ficheroChange)="checkIsToSend(fileImagen)"
          ><img src="/assets/icons/plus-solid.svg" alt="" /> Añadir más fotos</a
        >
      </div>
    </div>
    <div class="imagenesEvento">
      <div class="description">
        <h4>Descripción del evento</h4>
        <p>
          {{ event.description | slice: 0:longitud }}
          <ng-container *ngIf="event.description.length > longitud">
            <ng-container *ngIf="255 == longitud">
              ... <span class="expandir" (click)="longitud = -1">Leer más</span>
            </ng-container>
            <ng-container *ngIf="255 != longitud">
              <span class="expandir" (click)="longitud = 255">Leer menos</span>
            </ng-container>
          </ng-container>
        </p>
      </div>
      <div class="headerImage">
        <div class="mainHeaderImage">
          <img
            [src]="headerImage"
            alt=""
            onerror="this.src='/assets/images/placeholder.jpg'"
            *ngIf="headerImage"
          />
        </div>
        <a
          class="imagenContainer"
          fileInput
          [accept]="['image/jpeg', 'image/png']"
          [(fichero)]="headerImageModel"
          (ficheroChange)="sendHeaderImage(headerImageModel)"
          ><img src="/assets/icons/plus-solid.svg" alt="" *ngIf="!headerImage" /> <mat-icon *ngIf="headerImage">edit</mat-icon> {{(headerImage)?'Editar foto de cabecera':'Añadir foto de cabecera'}}</a
        >
      </div>
    </div>
  </div>
</div>
