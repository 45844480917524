import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { take, takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { NewFacilityPopupComponent } from 'src/app/popups/NewFacilityPopup/NewFacilityPopup.component';
import { FacilityService } from 'src/app/services/Api/facility.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PopupService } from 'src/app/services/utils/popup.service';

@Component({
  selector: 'form-business-enclosures',
  templateUrl: './form-business-enclosures.component.html',
  styleUrls: ['./form-business-enclosures.component.scss','../../admin-form-business.component.scss']
})
export class FormBusinessEnclosuresComponent extends UnsubscribesDestroy implements OnInit {
  facilities: Array<any> = []
  facilitiesSelected: Array<any>=[]
  @Output() dataPaso2 = new EventEmitter<any>();
  @Input() data:any = null 
  constructor(private popupSE: PopupService, private facilitiesSE: FacilityService, private loader: LoaderService, private notificaciones: NotificacionesService) {
    super();
  }

  ngOnInit() {
    // this.getFacilitiesOfGroup();
   
  }
  openNewFacility(){
    this.popupSE.openPopup(NewFacilityPopupComponent);
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(value=>{
      if(value==null){return}
     
      this.facilities.push(value.returnValue)
      this._unsubInd2.next("");
    })
  }
  selectFacility(facility){
    if (this.facilitiesSelected.length == 0) {
      this.facilitiesSelected.push(facility)
    }else{
      if (this.facilitiesSelected.includes(facility)) {
        this.facilitiesSelected.splice(this.facilitiesSelected.indexOf(facility),1)
      }else{
        this.facilitiesSelected.push(facility)
      }
    }
  }
  getFacilitiesOfGroup(){
    this.facilitiesSE.getFacilitiesOfGroup();
    this.loader.open();
    this.facilitiesSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value=>{
      if(value==null){return};
      this.facilities = value;
      this.loader.closeSlow();
      this._unsubInd.next("");
      if (this.data != null) {
        this.setData();
      }
    })
    this.facilitiesSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value=>{
      if(value==null){return};
      this.notificaciones.showError(value.error.message)
      this.loader.closeSlow();
      this._unsub.next("");
    })
  }
  validarPaso2(){
    var dataPaso2 = {
      facilities: this.facilities
    }
    this.dataPaso2.emit(dataPaso2)
  }
  setData(){
    this.facilitiesSelected = this.data.facilities
  }
}
