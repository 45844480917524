<headerMovil></headerMovil>
<div class="bodyGeneralContainer" *ngIf="dataIsLoaded">
  <backButtonTitle></backButtonTitle>
  <div class="eventoHeaderContainer">
    <div>
      <span>{{this.fechaEvento.dia}} {{this.fechaEvento.mesAbreviacion().toUpperCase() }}</span>
      <span>{{ evento.start_time.split("T")[1].split("+")[0].split(":")[0]}}:{{
        evento.start_time.split("T")[1].split("+")[0].split(":")[1]}}
        -
        {{ evento.finish_time.split("T")[1].split("+")[0].split(":")[0]}}:{{
        evento.finish_time.split("T")[1].split("+")[0].split(":")[1]}}</span>

    </div>
    <div>
      <span>{{evento.title}}</span>
    </div>
  </div>
  <div class="tabsContainer">
    <div [class.tabActiva]="tabActiva == 0" (click)="tabActiva = 0">Información</div>
    <!-- <div [class.tabActiva]="tabActiva == 1" [class.mediaTabActiva]="tabActiva == 1" (click)="tabActiva = 1">Entradas
    </div> -->
    <div [class.tabActiva]="tabActiva == 2" [class.ultimaTabActiva]="tabActiva == 2" (click)="tabActiva = 2">Equipo
    </div>

  </div>
  <ng-container *ngIf="tabActiva == 0">
    <div class="infoContainer">
      <Info [type]="'Ventas totales'" [currency]="true" [loaded]="isDataLoaded" [value]="estadisticas.ventas_totales"></Info>
      <Info [type]="'Entradas vendidas'" [loaded]="isDataLoaded" [value]="estadisticas.entradas_vendidas"></Info>
      <Info [type]="'Listas activas'" [loaded]="isDataLoaded" [value]="estadisticas.entradas_vendidas"></Info>

    </div>
    <div class="flyerContainer" [ngStyle]="{'background-image': 'url('+imgFlyer+')'}">
      <div class="tagsContainer">
        <span *ngFor="let categoria of evento.categories">{{categoria.name}}</span>
      </div>
      <div class="infoFlyerContainer">
        <span>{{fechaEvento.dia}}/{{fechaEvento.mes}}/{{fechaEvento.year}}</span>
        <span>{{direccion.street}}, {{direccion.locality}}</span>
        <span>{{evento.title}}</span>
      </div>
    </div>
    <div class="recintoContainer">
      <span>Recinto</span>
      <span>{{evento.facility.name}}</span>
    </div>
    <div class="textContainer">
      <p [class.showMoreText]="showMore">{{evento.description}}</p>
      <span *ngIf="!showMore" (click)="showMore = true;">+ Ver mas</span>
      <span *ngIf="showMore" (click)="showMore = false;">- Ver menos</span>
    </div>
    <div class="galeriaContainer" [class.inicial]="limite == 5">
      <ng-container *ngFor="let imagen of evento.event_images | slice:0:limite; let i = index">
        <img (click)="lightbox.open(i)" [src]="$any(imagen).image.url" alt="">
      </ng-container>
      <button class="cargarMas" (click)="limite = limite+6">+ Ver más</button>
    </div>
    <button class="cargarMenos" *ngIf="limite > 5" (click)="limite = 5" type="button">
      <svg xmlns="http://www.w3.org/2000/svg" width="22.828" height="12.413" viewBox="0 0 22.828 12.413">
        <path id="Trazado_762" data-name="Trazado 762" d="M14828.729-6347.3l10-10,10,10"
          transform="translate(14850.143 -6345.882) rotate(180)" fill="none" stroke="#080c57" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="2" />
      </svg>
    </button>
  </ng-container>
  <ng-container *ngIf="tabActiva == 1">
    <app-steps class="misEventosMovilPasos" [pasos]="pasos" [activo]="activo" [navegacionActiva]="true"
      (cambio)="cambiado($event)" *ngIf="false"></app-steps>
    <ng-container *ngIf="activo == 0">
      <div class="entradasGeneralContainer">
        <div class="entrada" [ngStyle]="{'background-image': 'url('+imgFlyer+')'}"
          *ngFor="let entrada of entradas">
          <div class="datos">
            <span>{{entrada.name}}</span>
            <span>{{entrada.description}}</span>
            <span>{{(entrada.fee + entrada.administrativeExpenses) | currency:'EUR'}}</span>
          </div>
          <div class="progreso">
            <span>{{entrada.sold}}/{{entrada.stock}}</span>
            <progress [max]="entrada.stock" [value]="entrada.sold"></progress>
          </div>

        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="activo == 1">
      <div class="entradasGeneralContainer">
        <div class="entrada" [ngStyle]="{'background-image': 'url('+imgFlyer+')'}"
          *ngFor="let lista of listas">
          <div class="datos">
            <span>{{lista.name}}</span>
            <span>{{lista.description}}</span>
            <span>{{lista.fee | currency:'EUR'}}</span>
          </div>
          <div class="progreso">
            <span>{{lista.sold}}/{{lista.stock}}</span>
            <progress [max]="lista.stock" [value]="lista.sold"></progress>
          </div>

        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="tabActiva == 2">
    <div class="equipoContainer">
      <div class="organizador">
        <span>Organizador</span>
        <div>
          <span *ngFor="let empresaOrganizadora of evento.organizer_company">{{empresaOrganizadora.trade_name}}</span>
        </div>

      </div>
      <div class="equipo">
        <span>Resumen de ventas</span>
        <div class="tabla">
          <div class="header">
            <span>NOMBRE</span>
            <span>VENTAS</span>
            <span>INGRESOS</span>
            <span>COMISIÓN</span>
          </div>
          <div class="tableBody">
            <div class="rp" *ngFor="let rp of relacionesPublicas">
              <span>{{rp.name}}</span>
              <span>{{rp.entradas_vendidas}}</span>
              <span>{{rp.facturacion_total.toFixed(2) | currency:'EUR'}}</span>
              <span>{{rp.comision_sin_iva | currency:'EUR'}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<botonFlotante [texto]="'Compartir evento'" [tipo]="tipo"></botonFlotante>
<botonFlotante [texto]="'Copiar código'" [tipo]="4" [posicionEspecial]="true"></botonFlotante>
