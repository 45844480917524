import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { CRMService } from 'src/app/services/Api/CRM.service';
import { EventService } from 'src/app/services/Api/event.service';
import { StadisticsService } from 'src/app/services/Api/stadistics.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'EventDetails-Step1',
  templateUrl: './EventDetails-Step1.component.html',
  styleUrls: ['./EventDetails-Step1.component.scss']
})
export class EventDetailsStep1Component extends UnsubscribesDestroy implements OnInit {

  @Output() ticketStadistics: EventEmitter<any> = new EventEmitter();

  //VARIABLES
  id!: number;
  estadisticas: any = {}
  diagramaSexo: any = null;
  diagramaPlatform: any = null;
  diagramaEdad: any = null;
  diagramaCategories: any = null
  semanales: any = []
  biSemanales: any = []
  mensuales: any = []
  chartDataLoaded = false;

  //VARIABLES DE CONTROL
  isDemographyGenre: boolean = false;
  isPlatform: boolean = false;
  isDemographyAge: boolean = true;
  isDemographyInterests: boolean = false;
  isEventSalesWeekly: boolean = true;
  isEventSalesBiweekly: boolean = false;
  isEventSalesMonthly: boolean = false;
  dataIsLoaded = false
  toggleElements: Array<String> = ["Ventas euros", "Ventas unidades"]
  selectedToggle: Number = 0

centros: Array<any> = []
  centrosBackUp: any = []
  centroSeleccionado: any = -1;
  centroSeleccionadoAuxiliar: any = null
  grados: any = []

  constructor(private estadisticasSE: StadisticsService, private notificaciones: NotificacionesService, private eventsSE: EventService, private loaderSE: LoaderService, private route: ActivatedRoute, private router: Router, private crmSE: CRMService) {
    super();
  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.getEstadisticas();
    this.getTableEventStadistics(this.id, this.getWeeks(7).firstDay, this.getWeeks(7).lastDay, "ventas")
    this.getAllCenters();

  }

  //FUNCIONALIDADES
  getWeeks(cantidadDias: number = 7) {
    const week = Array(cantidadDias).fill(new Date()).map((date, i) => {
      if (i !== 0) {
        date.setDate(date.getDate() - 1);
      }
      return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    });
    var dates = { firstDay: week[0], lastDay: week[week.length - 1] }

    return dates;
  }
  getDaysInMonth(month, year) {
    var date = new Date(year, month, 1);
    var days: any = [];
    for (let i = 1; i <= new Date(year, month, 0).getDate(); i++) {
      days.push(date.getFullYear() + "-" + date.getMonth() + "-" + i);

    }


    return { firstDay: days[0], lastDay: days[days.length - 1] }

  }
  filteredCentros(event) {
    this.centros = event;
  }
  //CONTROL
  onSelectEventSalesWeekly() {
    this.isEventSalesWeekly = true;
    this.isEventSalesBiweekly = false;
    this.isEventSalesMonthly = false;
    if (this.selectedToggle == 0) {
      this.getTableEventStadistics(this.id, this.getWeeks(7).firstDay, this.getWeeks(7).lastDay, "ventas")
    } else {
      this.getTableEventStadistics(this.id, this.getWeeks(7).firstDay, this.getWeeks(7).lastDay, "unidades")
    }

  }

  onSelectEventSalesBiweekly() {
    this.isEventSalesWeekly = false;
    this.isEventSalesBiweekly = true;
    this.isEventSalesMonthly = false;
    if (this.selectedToggle == 0) {
      this.getTableEventStadistics(this.id, this.getWeeks(14).firstDay, this.getWeeks(14).lastDay, "ventas")
    } else {
      this.getTableEventStadistics(this.id, this.getWeeks(14).firstDay, this.getWeeks(14).lastDay, "unidades")
    }
  }

  onSelectEventSalesMonthly() {
    this.isEventSalesWeekly = false;
    this.isEventSalesBiweekly = false;
    this.isEventSalesMonthly = true;
    if (this.selectedToggle == 0) {
      this.getTableEventStadistics(this.id, this.getDaysInMonth(new Date().getMonth() + 1, new Date().getFullYear()).lastDay, this.getDaysInMonth(new Date().getMonth() + 1, new Date().getFullYear()).firstDay, "ventas")
    } else {
      this.getTableEventStadistics(this.id, this.getDaysInMonth(new Date().getMonth() + 1, new Date().getFullYear()).lastDay, this.getDaysInMonth(new Date().getMonth() + 1, new Date().getFullYear()).firstDay, "unidades")
    }

  }

  // Demography charts

  onSelectGenre() {
    this.isPlatform = false;
    this.isDemographyGenre = true;
    this.isDemographyAge = false;
    this.isDemographyInterests = false;
  }
  onSelectPlatform() {
    this.isPlatform = true;
    this.isDemographyGenre = false;
    this.isDemographyAge = false;
    this.isDemographyInterests = false;
  }

  onSelectAge() {
    this.isPlatform = false;
    this.isDemographyGenre = false;
    this.isDemographyAge = true;
    this.isDemographyInterests = false;
  }

  onSelectInterests() {
    this.isPlatform = false;
    this.isDemographyGenre = false;
    this.isDemographyAge = false;
    this.isDemographyInterests = true;
  }
  changeToggle(event: Number) {
    this.selectedToggle = event
    this.onSelectEventSalesWeekly()
  }
  //LAMADAS A LA API
  getDiagramStadistics() {
    this.estadisticasSE.getEventStadisticsDiagram(this.id)
    this.estadisticasSE.getResult().pipe(takeUntil(this._unsubInd4)).subscribe(value => {
      if (value == null) { return }


      this.diagramaPlatform = value.platform;
      this.diagramaSexo = value.sex
      this.diagramaEdad = value.age
      this.diagramaCategories = value.categories
      this.chartDataLoaded = true;

      this._unsubInd4.next("");
    })
    this.eventsSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.loaderSE.closeSlow();
      this.notificaciones.showError(value.error.message);

      this._unsub.next("");
    })
  }
  getEstadisticas() {
    this.estadisticasSE.getEventStadisticsById(this.id)
    this.estadisticasSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return }
      this.loaderSE.closeSlow();

      this.estadisticas = value;
      this._unsubInd.next("");

      setTimeout(() => {
        this.getDiagramStadistics();

      }, 200)
    })
    this.eventsSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.loaderSE.closeSlow();
      this.notificaciones.showError(value.error.message);
      this._unsub.next("");
    })
  }
  getTableEventStadistics(idEvento, firstDate, lastDate, type) {
    this.dataIsLoaded = false;
    this.estadisticasSE.getTableEventStadistics(idEvento, firstDate, lastDate, type)
    this.estadisticasSE.getResultIndividual().pipe(takeUntil(this._unsubInd5)).subscribe(value => {
      if (value == null) { this.loaderSE.closeSlow(); return; }
      this.ticketStadistics.emit(value[0]);
      this.loaderSE.closeSlow();
      if (this.isEventSalesWeekly) {
        this.semanales = value
      }
      if (this.isEventSalesBiweekly) {
        this.biSemanales = value;
      }
      if (this.isEventSalesMonthly) {
        this.mensuales = value;
      }
      setTimeout(() => {
        this.dataIsLoaded = true;

      }, 500);

      this._unsubInd5.next("");
    })
    this.estadisticasSE.getResultIndividualError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { this.loaderSE.closeSlow(); return; }
      this.loaderSE.closeSlow();
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    })
  }
  getAllCenters() {
    this.crmSE.getCentersBuyEvents(this.id);

    this.crmSE.getResultCenters().pipe(takeUntil(this._unsubInd2)).subscribe((res:any)=>{
      if(res==null){return}
      this.centros = res;
      this.centrosBackUp = res;
      this._unsubInd2.next('')
    })
    this.crmSE.getResultError().pipe(takeUntil(this._unsubInd)).subscribe((res:any)=>{
      if(res==null){return}
      res.status == 500 ? this.notificaciones.showError('Se ha producido un error, intentalo más tarde.') : this.notificaciones.showError(res.message);
      this._unsub.next('')
    })
  }
  getCentersGrados() {
    this.crmSE.getGradosBuyEvents(this.id,this.centroSeleccionado);
    this.crmSE.getResultGrados().pipe(takeUntil(this._unsubInd5)).subscribe(value => {
      if (value == null) { return }
      this.grados = value;

      this._unsubInd5.next("");
    })
    this.crmSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    })
  }
}
