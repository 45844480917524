import { BaseObject } from "./Base"

export interface User {
    birthDate: string,
    companyId: number,
    creationDate: string
    dni: string
    email: string
    gender: string
    id: number
    lastConnection: string
    marketingMails: boolean
    name: string
    phone: string
    profileImage: string | null
    roles: []
    surname: string
    uniqueName: string,
    permisos: string,
    groupName: string,
    groupImage: string,
    groupId: number,
    prId: number,
    prCode: string,
}
export class User extends BaseObject implements User{
    static convertFrontObject(ob):User{
        let user=new User();
        user.birthDate = ob["birth_date"]
        user.companyId = ob["companyId"]
        user.creationDate = ob["creation_date"]
        user.dni = ob["dni"]
        user.email = ob["email"]
        user.gender = ob["gender"]
        user.id = ob["id"]
        user.lastConnection = ob["last_connection"]
        user.marketingMails = ob["marketing_mails"]
        user.name = ob["name"]
        user.phone = ob["phone"]
        user.profileImage = ob["profile_image"]
        user.roles = ob["roles"]
        user.surname = ob["surname"]
        user.uniqueName = ob["unique_name"]
        user.permisos = ob["permisos"]
        user.groupName = ob["groupName"]
        user.groupImage = ob["url"]
        user.groupId = ob["groupId"]
        user.prId = ob["prId"]
        user.prCode = ob["prCode"]
        return user;

    }
}
