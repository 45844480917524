import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Event } from 'src/app/models/Event';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-event-item',
  templateUrl: './event-item.component.html',
  styleUrls: ['./event-item.component.scss'],
})
export class EventItemComponent implements OnInit {
  isFocus: boolean = false;
  @Input() enlace = true;
  @Input() seleccionado = false;
  @Output() salida = new EventEmitter<number>();
  @Input() event
  urlImages = environment.urlImages
  constructor(private router: Router) {}

  ngOnInit(): void {
    // Convert JSON Date to UTC Date
    this.event.startDate = new Date(
      this.event.startDate
    ).toUTCString();
  }

  // Added for accessibility purposes
  onFocus(): void {
    this.isFocus = true;
  }

  onBlur(): void {
    this.isFocus = false;
  }
  pulsado() {
    if (this.enlace) {
      this.router.navigate(['/publisher/management/events/' + this.event.id]);
    } else {
      this.salida.emit(this.event.id);
      this.router.navigate(['/publisher/management/ticket-store/' + this.event.id]);
    }
  }
}
