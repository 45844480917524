import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, catchError } from 'rxjs';
import { Facility } from 'src/app/models/Facility';
import { environment } from 'src/environments/environment';
import { BaseService } from './Base.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class FloorPlansService extends BaseService {

  private urlApi = environment.urlApi;

  constructor(private http: HttpClient) {
    super();
  }

  updateFloorPlanById(id: number, floorPlan: any) {
    this.http.put(this.urlApi + '/api/floor/plan/' + id, floorPlan, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultUpdate', data)
      },
      error: (error) => {
        this.sendNextResult('resultUpdateError', error, { method: this.updateFloorPlanById, args: [id, floorPlan] })
        this.checkStatusError(error);
      },
    });
  }
  newFloorPlan(floorPlan: any) {
    this.http.post(this.urlApi + '/api/floor/plan', floorPlan, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.newFloorPlan, args: [floorPlan] })
        this.checkStatusError(error);
      },
    });
  }
  deleteFloorPlan(id: any) {
    var floorPlans_ids:any = []
    let send={floorPlans_ids:floorPlans_ids};
    if(!(id instanceof Array)){send.floorPlans_ids.push(id);}else{send.floorPlans_ids=id;}
    this.http.delete(this.urlApi + '/api/floor/plan',this.sendBodyOptions(send)).subscribe({
      next: (data) => {
        this.sendNextResult('resultDelete', data)
      },
      error: (error) => {
        this.sendNextResult('resultDeleteError', error, { method: this.deleteFloorPlan, args: [id] })
        this.checkStatusError(error);
      },
    });
  }
}
