import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { CompanyService } from 'src/app/services/Api/company.service';
import { PrService } from 'src/app/services/Api/pr.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'searcher',
  templateUrl: './searcher.component.html',
  styleUrls: ['./searcher.component.scss']
})
export class SearcherComponent extends UnsubscribesDestroy implements OnInit {

  @Input() type = "general";
  @Input() toFilter:Array<any> = []
  @Input() size: String = ""
  @Input() placeHolder:String = "Busca aquí lo que quieras"
  @Output() filtered = new EventEmitter<any>();
  @Output() emitChange = new EventEmitter<string>()
  filter:string = ""
  rrpp: Array<any> = []
  busquedaGeneral: Array<any> = []
  loading: Boolean = false;
  constructor(private notificaciones: NotificacionesService, private prSE: PrService, private router:Router, private companySE: CompanyService) {
    super();
  }

  ngOnInit() {
    if (this.type == "rp") {
      this.searchRP();
    }
  }
  filterContent(){
    if (this.toFilter.length != 0) {
      if (this.type == "eventPR") {
        this.filtered.emit(this.toFilter.filter(event => event.title.toLowerCase().includes(this.filter.toLowerCase())))
       }
      if (this.type == "event") {
       this.filtered.emit(this.toFilter.filter(event => event.title.toLowerCase().includes(this.filter.toLowerCase()) || event.name.toLowerCase().includes(this.filter.toLowerCase())))
      }
      if (this.type == "user") {
        this.filtered.emit(this.toFilter.filter(user => user.name.toLowerCase().includes(this.filter.toLowerCase())))
      }
      if (this.type == "facility") {
        this.filtered.emit(this.toFilter.filter(facility => facility.name.toLowerCase().includes(this.filter.toLowerCase())))
      }
      if(this.type == "centro"){
        this.filtered.emit(this.toFilter.filter(center => center.name.toLowerCase().includes(this.filter.toLowerCase()) || center.locality.toLowerCase().includes(this.filter.toLowerCase())))
      }
      if(this.type == "company"){
        this.filtered.emit(this.toFilter.filter(company => company.name.toLowerCase().includes(this.filter.toLowerCase())))
      }
      if(this.type == "community"){
        this.filtered.emit(this.toFilter.filter(community => community.name.toLowerCase().includes(this.filter.toLowerCase())))
      }
    }

  }
  clearData(){
    if(this.filter == "" && this.type == 'rp'){
      this.rrpp = []
    }
    if(this.filter == "" && this.type == 'all'){
      this.busquedaGeneral = []
    }
  }
  verDetalles(id){
    this.router.navigate(["/publisher/management/public-relation/public-relation-details/"+id])
  }
  changeEmitter(){
    this.emitChange.emit(this.filter);
  }
  //LLAMADAS A LA API
  searchRP(){
    if (this.filter != "" && this.type == 'rp') {
      this.prSE.searchRP(this.filter);
      this.loading= true;
      this.prSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
        if (value == null) { return }

        if (value.message != null) {
          this.notificaciones.showWarning(value.message)
        }
        this.rrpp = value;
        this.loading= false;
        this._unsubInd.next("");
      })
      this.prSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
        if (value == null) { return }
        this.loading= false;
        this.notificaciones.showError(value.error.message)
        this._unsub.next("");
      })
    }

  }
  searchEverything(){
    if (this.filter != "" && this.type == 'all') {
      this.companySE.searchEverythingOfCompany(this.filter);
      this.loading = true;
      this.companySE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
        if (value == null) { return }

        if (value.message != null) {
          this.notificaciones.showWarning(value.message)
        }
        // this.rrpp = value;
        this.busquedaGeneral = value;
        this.loading = false;
        this._unsubInd.next("");
      })
      this.companySE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
        if (value == null) { return }
        this.loading= false;
        this.notificaciones.showError(value.error.message)
        this._unsub.next("");
      })
    }
  }
}
