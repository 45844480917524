import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType, Tooltip } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';

@Component({
  selector: 'SalesChart',
  templateUrl: './SalesChart.component.html',
  styleUrls: ['./SalesChart.component.scss']
})
export class SalesChartComponent implements OnInit, OnChanges {

  actualMonth: number = new Date().getMonth()
  actualYear: number = new Date().getFullYear();
  legends: Array<any> = [{ label: "RR.PP.", color: "#3726A8" }, { label: "Taquilla", color: "#54408C" }, { label: "On-line", color: "#605770" }, { label: "Invitaciones", color: "#626262" }]
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  @Input() type = "weekly"
  @Input() data: any = []
  @Input() customDates: any = null
  @Input() dates: any = null
  years: any = []
  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {

    this.data = changes["data"].currentValue
  }
  ngOnInit() {
    this.getDaysArray(7)
    if (this.type == "weekly") {
      this.getDaysArray(7)
      this.setData();
    }
    if (this.type == "biWeekly") {
      this.getDaysArray(14)
      this.setData();

    }
    if (this.type == "monthly") {
      this.getDaysInMonth(this.actualMonth, this.actualYear)
      this.setData();
    }
    if (this.type == "anual") {
      this.barChartData.labels = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]
      this.setAnualData()
    }
    if (this.type == "custom") {
      if (this.data.length > 0) {
        this.getCustomDays(this.dates.inicio, this.dates.fin)
      }
    }

  }

  getDaysArray(days: number) {
    var dateArray: any = [];
    var currentDate = new Date();
    for (var i = 0; i < days; i++) {
      dateArray.push(new Date(currentDate).getDate());
      currentDate.setDate(currentDate.getDate() - 1);
    }
    this.barChartData.labels = dateArray.reverse();
  }

  getDaysInMonth(month, year) {
    var date = new Date(year, month, 1);
    var days: any = [];
    while (date.getMonth() === month) {
      days.push(date.getDate());
      date.setDate(date.getDate() + 1);
    }
    this.barChartData.labels = days;

  }


  getCustomDays(startDate, endDate, steps = 1) {
    const dateArray: any = [];
    let currentDate = new Date(startDate);

    while (currentDate <= new Date(endDate)) {
      dateArray.push(new Date(currentDate).getDate());
      // Use UTC date to prevent problems with time zones and DST
      currentDate.setUTCDate(currentDate.getUTCDate() + steps);
    }
    if (dateArray.length > 365) {
      var diferenceYears = Number(this.dates.fin.split("-")[0] - Number(this.dates.inicio.split("-")[0]));
      var years: any = []
      var initialYear = Number(this.dates.inicio.split("-")[0]);
      for (let index = 0; index <= diferenceYears; index++) {
        years.push(initialYear)
        initialYear ++;
      }
      var test:any = []
      years.forEach(element => {
        for (let index = 1; index <= 12; index++) {
          if (index < 10) {
            test.push("0"+index+"-"+element)
          }else{
            test.push(index+"-"+element)
          }
        }
      });
      this.barChartData.labels = test;
      this.addMoreThanOneYear()
    } else if (dateArray.length > 31) {
      this.barChartData.labels = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
      this.setAnualData();
    } else {
      this.barChartData.labels = dateArray;
      this.setData();
    }
  }
  //BARRAS
  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    font: { family: 'Rubik' },

    scales: {
      x: {
        grid: {
          lineWidth: 0,
        }, ticks: {
          color: "#000000",
          font: {
            weight: '700',
          },
        },
      },
      y: {
        min: 0,
        ticks: { // and this
          callback: function (value, index, values) {
            return value + " €";
          },
        },
        grid: {
          color: '#dfe0f5',
          drawBorder: false,
        },
      }
    },

    plugins: {
      legend: {
        display: false,
      },

      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat('es-ES', {
                style: 'currency',
                currency: 'EUR'
              }).format(context.parsed.y);
            }
            return label;
          }
        }
      }
    }

  };
  public barChartType: ChartType = 'bar';
  public barChartPlugins = [
    DataLabelsPlugin
  ];

  public barChartData: ChartData<'bar'> = {
    labels: [],
    datasets: [
      {
        data: [],
        barThickness: 40,
        backgroundColor: '#3726A8',
        hoverBackgroundColor: '#3726A8',
        borderWidth: 2,
        borderColor: '#3726A8',
        hoverBorderColor: '#3726A8',
        borderSkipped: false,
        stack: "A",
        label: "Ingresos",
        datalabels: {
          formatter: function (value, context) {
            return value + "€"
          }
        }
      }
    ]
  };
  setData() {
      for (let i = 0; i < this.barChartData!.labels!.length; i++) {
        this.data.forEach(element => {
          if (Number(element.purchase_date.split("-")[2]) < 10) {
            if (element.purchase_date.split("-")[2] == this.barChartData.labels![i]) {
              this.barChartData.datasets[0].data[i] = element.ventas
            }
          } else {
            if (element.purchase_date.split("-")[2] == this.barChartData.labels![i]) {
              this.barChartData.datasets[0].data[i] = element.ventas
            }
          }

        })
      }
      this.chart?.update();


  }
  setAnualData() {
    setTimeout(() => {
      for (let i = 0; i < this.barChartData!.labels!.length; i++) {

        this.data.forEach(element => {
          if (element.purchase_date.split("-")[1] == this.barChartData.labels![i]) {
            this.barChartData.datasets[0].data[i] = element.ventas
          }
        })
      }
      this.chart?.update();
    }, 1000);

  }
  addMoreThanOneYear(){
    setTimeout(() => {
      for (let i = 0; i < this.barChartData!.labels!.length; i++) {

        this.data.forEach(element => {
          if ((element.purchase_date.split("-")[1]+"-"+element.purchase_date.split("-")[0]) == this.barChartData.labels![i]) {
            this.barChartData.datasets[0].data[i] = element.ventas
          }
        })
      }
      this.chart?.update();
    }, 1000);
  }
}
