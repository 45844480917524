<headerMovil></headerMovil>
<div class="bodyGeneralContainer">
  <div class="datosGeneralContainer">
    <div class="tarjetaInformativa">
      <span *ngIf="estadisticas.ventas_totales != null">{{estadisticas.ventas_totales | currency:'EUR'}}</span>
      <span *ngIf="estadisticas.ventas_totales == null">0 €</span>
      <span>Ventas totales</span>
    </div>
    <div class="tarjetaInformativa">
      <span *ngIf="estadisticas.entradas_vendidas != null">{{estadisticas.entradas_vendidas}}</span>
      <span *ngIf="estadisticas.entradas_vendidas == null">0</span>
      <span>Entradas vendidas</span>
    </div>
    <div class="tarjetaInformativa">
      <span>{{eventosHoy.length}}</span>
      <span>Eventos disponibles</span>
    </div>
    <div class="tarjetaInformativa">
      <span *ngIf="estadisticas.listas_activas != null">{{estadisticas.listas_activas}}</span>
      <span *ngIf="estadisticas.listas_activas == null">0</span>
      <span>Listas activas</span>
    </div>
  </div>
  <h2>Eventos hoy</h2>
  <div class="eventosGeneralContainer">
    <div class="efect"></div>
    <div class="previeousEfect"></div>
    <div class="carrouselContainer">
      <p class="sinDatos" *ngIf="eventosHoy.length == 0">No hay eventos hoy</p>
      <ng-container *ngFor="let evento of eventosHoy">
        <div class="card-vert" (click)="saveEvent(evento)">
          <ng-container *ngFor="let imagen of evento.event_images">
            <img *ngIf="imagen.is_principal" [src]="imagen.image.url" alt="" class="card-img">
          </ng-container>

          <div class="float-time">
            <span>{{evento.start_time.split("T")[1].split("+")[0].split(":")[0]}}:{{evento.start_time.split("T")[1].split("+")[0].split(":")[1]}}
              -
              {{evento.finish_time.split("T")[1].split("+")[0].split(":")[0]}}:{{evento.finish_time.split("T")[1].split("+")[0].split(":")[1]}}</span>
          </div>
          <div class="card-info">
            <div class="card-title">
              <h5 [title]="evento.nombre">{{evento.title}}</h5>
            </div>
            <div class="card-details">
              <small>{{evento.facility.name}}</small>
            </div>
          </div>

        </div>
      </ng-container>
    </div>
  </div>
  <h2>Eventos futuros</h2>
  <div class="eventosGeneralContainer">
    <div class="efect"></div>
    <div class="previeousEfect"></div>
    <div class="carrouselContainer">
      <p class="sinDatos" *ngIf="eventosFuturos.length == 0">No hay eventos futuros</p>
      <ng-container *ngFor="let evento of eventosFuturos">
        <div class="card-vert" (click)="saveEvent(evento)">
          <img [src]="evento.url" alt="" class="card-img" onerror="this.src='/assets/images/placeholder.jpg'">
          <div class="float-time">
            <span>{{evento.startTime.split("T")[1].split("+")[0].split(":")[0]}}:{{evento.startTime.split("T")[1].split("+")[0].split(":")[1]}}
              -
              {{evento.finishTime.split("T")[1].split("+")[0].split(":")[0]}}:{{evento.finishTime.split("T")[1].split("+")[0].split(":")[1]}}</span>
          </div>
          <div class="card-info">
            <div class="card-title">
              <h5 [title]="evento.nombre">{{evento.title}}</h5>
            </div>
            <div class="card-details">
              <small>{{evento.name}}</small>
            </div>
          </div>

        </div>
      </ng-container>
    </div>
  </div>
  <h2>Mis comunidades</h2>
  <div class="misComunidadesGeneralContainer">
    <p class="sinDatos" *ngIf="misComunidaddes.length == 0">No perteneces a ninguna comunidad</p>

    <ng-container *ngFor="let comunidad of misComunidaddes">
      <div class="card-flat-det">
        <img *ngIf="comunidad.image != null" [src]="comunidad.image" alt="">
        <img *ngIf="comunidad.image == null" src="/assets/antoine-rault-IhWRrZx4-kk-unsplash.jpg" alt="">
        <div class="card-info">
          <div class="card-title">
            <h5>{{comunidad.name}}</h5>
          </div>
          <div class="card-details">
            <small *ngIf="comunidad.num_members != 1">{{comunidad.num_members}} miembros</small>
            <small *ngIf="comunidad.num_members == 1">{{comunidad.num_members}} miembro</small>
            <small class="tp" *ngIf="comunidad.age_range=='NO_RANGE'">TP</small>
            <small class="tp" *ngIf="comunidad.age_range=='RANGE_14_17'">14-17</small>
            <small class="tp" *ngIf="comunidad.age_range=='RANGE_16'">+16</small>
            <small class="adultos" *ngIf="comunidad.age_range=='RANGE_18'">+18</small>
            <small class="adultos" *ngIf="comunidad.age_range=='RANGE_21'">+21</small>
            <small class="adultos" *ngIf="comunidad.age_range=='RANGE_30'">+30</small>
            <small class="adultos" *ngIf="comunidad.age_range=='RANGE_40'">+40</small>
            <small class="adultos" *ngIf="comunidad.age_range=='RANGE_50'">+50</small>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<botonFlotante [texto]="'Nueva lista'" *ngIf="false"></botonFlotante>
<botonFlotante [texto]="'Copiar código'" [tipo]="4"></botonFlotante>
