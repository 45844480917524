import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { Fecha } from 'src/app/models/Fecha';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { EventService } from 'src/app/services/Api/event.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mis-eventos',
  templateUrl: './mis-eventos.component.html',
  styleUrls: ['./mis-eventos.component.scss']
})
export class MisEventosComponent extends UnsubscribesDestroy implements OnInit {
  activo: number = 0;
  pasos = ['Hoy', 'Calendario'];
  fechaActual = new Date().toLocaleDateString();
  fecha = new Date();
  eventosSeleccionados: any = [];
  eventosCalendario: any = []

  eventosHoy: any = []
  urlImages = environment.urlImages
  constructor(private router: Router, private notificaciones: NotificacionesService, private loginSE: LoginService, private eventSE:EventService, private loader:LoaderService) { 
    super();
  }

  ngOnInit() {
    if (!this.loginSE.isLogin()) {
      this.router.navigate(["/login"])
      this.loginSE.logOut(true);
    }else{
      this.loader.open();
      this.getEventsByDate();
      setTimeout(() => {
        this.obtainEvents();
      }, 1000);
    }
  }
  
  cambiarMes(fecha: Date){
    this.obtainEvents(fecha);
  }
  getEventsByDate() {
    var today = new Date();
    var day = String(today.getDate()).padStart(2, '0');
    var month = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var year = today.getFullYear();
    this.eventSE.getEventByDate(year + "-" + month + "-" + day);
    this.eventSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe(value => {
      if (value == null) { return };
      this.eventosHoy = value;
      this._unsubInd2.next("");
    })
    this.eventSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return };
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    })
  }
  cambiado(event:any){
    this.activo = event;
  }
  clickFecha(fecha: Date) {
    this.eventosSeleccionados = []
    this.eventosCalendario.map(element => {
      var d2Aux = new Date(element.startDate)
      var d2: Fecha = new Fecha();
      d2.dia = d2Aux.getDate();
      d2.mes = d2Aux.getMonth() + 1
      d2.year = d2Aux.getFullYear()

      if (d2.dia == fecha.getDate() && d2.mes == (fecha.getMonth() + 1) && d2.year == fecha.getFullYear()) {
        this.eventosSeleccionados.push(element)
      }

    })
  }
  fechaToText(date: Date) {
    let res = '';
    let dia, mes;
    if (date.getDate() < 10) {
      dia = '0' + date.getDate();
    } else {
      dia = date.getDate();
    }

    if (date.getMonth() + 1 < 10) {
      mes = '0' + (date.getMonth() + 1);
    } else {
      mes = date.getMonth() + 1;
    }
    return date.getFullYear() + '-' + mes + '-' + dia;
  }
  saveEvent(evento:any){
    sessionStorage.setItem("evento", JSON.stringify(evento));
    sessionStorage.setItem("lastRoute", this.router.url);
    this.router.navigate(["/rrpp/ver-evento/"+evento.id])
  }
  fechaAnt:Date|null = null;
  eventsLoaded = false;
  obtainEvents(fecha?: Date){
    if(fecha != undefined){
      var today = fecha;
    }else{
      var today = new Date();
    }
    if(this.fechaAnt != null){
      if(this.fechaAnt > today){
        this.fechaAnt = today;
      }else{
        return;
      }
    }else{
      this.fechaAnt = today;
    }
    this.eventsLoaded = false;
    this.eventosCalendario = [];
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    this.eventSE.getEvents(5000,1,date);
    this.eventSE.getResult().pipe(takeUntil(this._unsubInd3)).subscribe(value => {
      if (value == null) { return; }
      this.loader.closeSlow();
      this.eventosCalendario = value;
      this.eventsLoaded = true;
      this._unsubInd3.next("")
    })
    this.eventSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return; }
      this._unsub.next("")
      this.loader.closeSlow();
    })
    setTimeout(() => {
      this.clickFecha(new Date(today.toISOString()))
    }, 1500);
  }
}
