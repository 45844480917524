import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { EventService } from 'src/app/services/Api/event.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { TicketsService } from 'src/app/services/Api/tickets.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'BuyTickets',
  templateUrl: './BuyTickets.component.html',
  styleUrls: ['./BuyTickets.component.scss']
})
export class BuyTicketsComponent extends UnsubscribesDestroy implements OnInit {
  isDataAvailable: boolean = false;
  id!: number;
  ticketSeleccionado = -1;
  cantidadEntradas = 1;
  event: any = {};
  ticketAux:any = ""
  mayorEdad=null;

  constructor(
    private eventService: EventService,
    private route: ActivatedRoute,
    private loginSE: LoginService,
    private loaderSE:LoaderService,
    private notificaciones: NotificacionesService,
    private ticketSE: TicketsService,
    private router:Router
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    }else{
      this.id = this.route.snapshot.params['id'];
      this.eventService.getEventById(this.id)
      // this.loaderSE.open();
      this.eventService.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value=>{
        if(value==null){return};
        this.event = value
        this.isDataAvailable = true;
        this.loaderSE.closeSlow();
        this._unsubInd.next("")
      })
      this.eventService.getResultError().pipe(takeUntil(this._unsub)).subscribe(value=>{
        if(value==null){return};
        this.loaderSE.closeSlow();
        this.notificaciones.showError(value.error.message)
        this._unsub.next("")
      })
    }
  }
  comprarEntradas(ticket){
    if (this.event.age_range != "NO_RANGE" && this.event.age_range != "RANGE_14_17" && this.mayorEdad == null) {
      this.notificaciones.showError("Debes marcar si es mayor de edad")
    }
    if (this.event.age_range != "NO_RANGE" && this.event.age_range != "RANGE_14_17" && this.mayorEdad == false) {
      this.notificaciones.showError("Debes asegurarte de que el comprador es mayor de edad")
    }
    if (this.event.age_range != "NO_RANGE" && this.event.age_range != "RANGE_14_17" && this.mayorEdad == true) {
      if (this.ticketAux == "") {
        this.notificaciones.showError("Debes seleccionar una entrada")
      }else{
        this.ticketSE.buyTickets({isUsed:false, ticket: ticket.id, quantity: this.cantidadEntradas, company: Number(sessionStorage.getItem("dcmpny"))});
        this.loaderSE.open();
      }

    }
    if(this.event.age_range == "NO_RANGE" || this.event.age_range == "RANGE_14_17"){
      if (this.ticketAux == "") {
        this.notificaciones.showError("Debes seleccionar una entrada")
      }else{
        this.ticketSE.buyTickets({isUsed:false, ticket: ticket.id, quantity: this.cantidadEntradas, company: Number(sessionStorage.getItem("dcmpny"))});
        this.loaderSE.open();
      }

    }
    this.ticketSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value=>{
      if(value==null){return};
      this.loaderSE.closeSlow();
      this.notificaciones.showFeedBack(value.message);
      this._unsubInd.next("");
    })
    this.ticketSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value=>{
      if(value==null){return};
      this.loaderSE.closeSlow();
      this.notificaciones.showError(value.error.message);
      this._unsub.next("");
    })
  }

}
