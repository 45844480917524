<PageTemplate>
  <ng-container bodyContent>
    <div class="headerContainer">
      <header [searcherActive]="false" [buttonActive]="false"></header>
      <div class="buttonContainer">
        <GeneralButton [buttonText]="'Atrás'" (click)="changeToggle('atras');selectedToggle = selectedToggle - 1;"
          [class.disabled]="selectedToggle == 0"></GeneralButton>
        <GeneralButton [buttonText]="'Siguiente'" (click)="changeToggle()" *ngIf="selectedToggle != 2"></GeneralButton>
        <GeneralButton [buttonText]="'Guardar campaña'" (click)="changeToggle()" *ngIf="selectedToggle == 2 && !editar">
        </GeneralButton>
        <GeneralButton [buttonText]="'Finalizar edición'"
          [routerLink]="['/publisher/communities-promotions/promotions']" routerLinkActive="router-link-active"
          *ngIf="selectedToggle == 2 && editar"></GeneralButton>
      </div>
    </div>
    <steps [pasos]="toggleElements" [activo]="selectedToggle"></steps>
    <ng-container *ngIf="!dataIsLoaded">
      <div class="noData">
        <mat-spinner class="medium center"></mat-spinner>
      </div>
    </ng-container>
    <ng-container *ngIf="dataIsLoaded">
      <duracion *ngIf="selectedToggle == 0" (dataToUpdate)="editarCampanya($event)" (data)="getDataFromStep1($event)"
        (validStep)="isValidStep1($event)" [receiveData]="dataPaso1"></duracion>
      <audiencia *ngIf="selectedToggle == 1" (dataToUpdate)="editarCampanya($event)" (data)="getDataFromStep2($event)"
        (validStep)="isValidStep2($event)" [receiveData]="dataPaso2"></audiencia>
      <edicionMensaje *ngIf="selectedToggle == 2" (dataToUpdate)="editarCampanya($event)"
        (data)="getDataFromStep3($event)" (validStep)="isValidStep3($event)" [receiveData]="dataPaso3"></edicionMensaje>
    </ng-container>

  </ng-container>
</PageTemplate>