<section class="tickets">
  <h3>Entradas anticipadas</h3>
  <mat-spinner class="big center" *ngIf="!dataLoaded"></mat-spinner>
  <ng-container *ngIf="dataLoaded">
    <p class="sinElementos" *ngIf="entradas.length == 0">No hay entradas para este evento</p>
    <ul>
      <li *ngFor="let ticket of entradas"  [class.opened]="ticketsOpened.includes(ticket)">
        <div class="pill-lg-alt" [style]="'background:url(' + imagenEntrada + ')'">
          <div class="pill-title">
            <h5>{{ ticket.name }}</h5>
            <div class="priceContainer" *ngIf="ticket.salesByPrice.length > 0 && ticket.salesByPrice[0].entradas_vendidas != null" (click)="controlOpenTicket(ticket)" [class.opened]="ticketsOpened.includes(ticket)">
              <mat-icon>
                trending_up
                </mat-icon>
              <mat-icon>
                expand_more
                </mat-icon>
            </div>
          </div>
          <div class="pill-desc">
            <small>{{ ticket.description}}</small>
          </div>
          <div class="pill-details">
            <small>{{ (ticket.fee+ticket.administrativeExpenses).toFixed(2) | currency: 'EUR' }} </small>
          </div>
          <div class="pill-sold">
            <span>
              {{ ticket.sold ?? 0 }} / {{ ticket.stock}}</span>
              <div *ngIf="ticket.reservas.ventas > 0" class="ticketsSoldLine">
                <span class="sold reservas">
                  <span class="infoHover reservas">
                    {{'Prereserva: ' + ticket.reservas.entradas_vendidas }}
                  </span>
                </span>
                <span class="sold ventas">
                  <span class="infoHover ventas">
                    {{'Confirmadas: ' + ticket.ventas.entradas_vendidas }}
                  </span>
                </span>
              </div>              
              <div *ngIf="ticket.reservas.ventas == 0" class="ticketsSoldLine">
                <span class="sold app">
                  <span class="infoHover app">
                    {{'Entradas vendidas en la App: ' + ticket.entradasApp }}
                  </span>
                </span>
                <span class="sold web">
                  <span class="infoHover web">
                    {{'Entradas vendidas en la Web: ' + ticket.entradasWeb }}
                  </span>
                </span>
              </div>
          </div>
        </div>
        
        
        <div class="listadoDesplegado" *ngIf="ticket.salesByPrice.length > 0 && ticket.salesByPrice[0].entradas_vendidas != null " [class.opened]="ticketsOpened.includes(ticket)">

          <ng-container *ngIf="ticket.reservas.ventas == 0" >
            <div *ngFor="let precio of ticket.salesByPrice">
              <span>
                {{precio.entradas_vendidas}} entradas - {{ precio.purchase_price | currency: 'EUR' }}
              </span>
              <span>
                {{precio.ventas | currency: 'EUR'}}
              </span>
            </div>
          </ng-container>
          <ng-container *ngIf="ticket.reservas.ventas > 0" >
            <span>
              {{'Prereserva: ' + ticket.reservas.entradas_vendidas }} <br> {{  'Bruto: ' + (ticket.reservas.bruto?? 0).toFixed(2) + ' €' + '. Neto: ' + (ticket.reservas.ventas ?? 0).toFixed(2) + ' €'}}
            </span>
            <span>
              {{'Confirmadas: ' + ticket.ventas.entradas_vendidas }} <br> 
              {{'Bruto: ' + (ticket.ventas.bruto?? 0).toFixed(2) + ' €' + '. Neto: ' + (ticket.ventas.ventas?? 0).toFixed(2) + ' €'}}
            </span>
          </ng-container>

        </div>

      </li>
    </ul>
  </ng-container>
</section>
