<div class="addNewPrContainer">
  <div class="header">
    <p>Cambio de titularidad</p>
    <svg (click)="forceClosePopup()" xmlns="http://www.w3.org/2000/svg" width="20.242" height="20.242"
      viewBox="0 0 20.242 20.242">
      <g id="Grupo_1201" data-name="Grupo 1201" transform="translate(-5674.379 -12806.379)">
        <line id="Línea_323" data-name="Línea 323" x2="16" y2="16" transform="translate(5676.5 12808.5)" fill="none"
          stroke="#0e17b6" stroke-linecap="round" stroke-width="3" />
        <line id="Línea_324" data-name="Línea 324" x1="16" y2="16" transform="translate(5676.5 12808.5)" fill="none"
          stroke="#0e17b6" stroke-linecap="round" stroke-width="3" />
      </g>
    </svg>
  </div>
  <div class="body">
    <!--  Antiguo método con el selector de usuarios. Deprecated por bajo rendimiento. Cambiado por el método de busqueda de usuarios por numero de teléfono
    <ng-select [items]="modalData.usuarios" [(ngModel)]="usuario" (change)="getNewUser()" [searchable]="true"
      [clearable]="false" bindLabel="name" bindValue="id" [placeholder]="'Selecciona un usuario'">
      <ng-template ng-label-tmp let-item="item">
        <span><b>{{ item.name }}</b></span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
        <div class="columnSelector">
          <span><b>{{ item.name }}</b></span>
          <span>{{ item.email }}</span>
          <span>{{ item.phone }}</span>
        </div>
      </ng-template>
    </ng-select>
    <div class="userSelected">
      <div>
        <span><b>{{ oldUsuario.name }}</b></span>
        <span>{{ oldUsuario.email }}</span>
        <span>{{ oldUsuario.phone }}</span>
      </div>
      <div>
        <mat-icon>east</mat-icon>
      </div>
      <div [class.empty]="!newUser">
        <ng-container *ngIf="!newUser">
          -
        </ng-container>
        <ng-container *ngIf="newUser">
          <span><b>{{ newUser.name }}</b></span>
          <span>{{ newUser.email }}</span>
          <span>{{ newUser.phone }}</span>

        </ng-container>
      </div>

    </div> -->

    <div class="selectorModule">
      <label for="name">Introduce el número de teléfono del usuario</label>
      <input type="text" placeholder="Introduce el número de teléfono del usuario" (change)="debounceCheckPhone()"
        [(ngModel)]="phone" class="inputData">
    </div>
    <mat-spinner class="small" *ngIf="isLoading"></mat-spinner>
    <div class="usuarioSeleccionado" *ngIf="isUserChecked">
      <p><b>Usuario seleccionado:</b></p>
      <span><b>{{ newUser.name }}</b></span>
      <span>{{ newUser.email }}</span>

    </div>



  </div>
  <div class="footer">
    <button class="btn-sm" [class.disabled]="!userSelected"
      (click)="closePopup({id: modalData.entrada.ticketId, user: this.userSelected})">Finalizar</button>
  </div>
</div>