<div class="stepGeneralContainer">
  <div class="stepLeft">
    <p class="noData" *ngIf="transportes.length == 0">No hay transportes para este evento</p>
    <div class="listaTransporte" *ngIf="transportes.length != 0">
      <div class="transporte" *ngFor="let transporte of transportes" [class.seleccionado]="transporte == transporteSeleccionado" (click)="transporteSeleccionado = transporte; setTransporteDataOnClick(transporte);verTransporteAbierto = true;">
        <p [title]="transporte.name">{{transporte.name}}</p>
        <p>{{transporte.fee | currency:'EUR'}}</p>
        <span class="deleteTransporte" >
          <img src="/assets/icons/trash.svg" alt="">
        </span>
      </div>
    </div>
    <button class="añadirNuevoTransporte"
      (click)="nuevoTransporteAbierto = true;verTransporteAbierto=false; addNuevoTransporte()"
      [class.disabled]="nuevoTransporteAbierto">
      <img src="/assets/icons/plus.svg" alt="">
      <span>Añadir un nuevo transporte</span>
    </button>
  </div>
  <div class="stepRight">
    <p class="noData" *ngIf="!nuevoTransporteAbierto && !verTransporteAbierto">No hay ningún transporte seleccionado</p>

    <form action="" *ngIf="nuevoTransporteAbierto || verTransporteAbierto">
      <FormElement [type]="'input'" [receiveData]="nombreTicketTransporte" [error]="nombreTicketTransporteError"
        [label]="'Nombre ticket de transporte'" [placeholder]="'Nombre ticket de transporte'"
        (sendData)="getNombreTicketTransporte($event)" (updateData)="updateNombreTransporte($event)"></FormElement>
      <div class="doubleColumn">
        <FormElement [type]="'number'" [receiveData]="precioTransporte" [error]="precioTransporteError"
          [label]="'Precio Transporte'" [placeholder]="'Precio Transporte'" (sendData)="getPrecioTransporte($event)"
          (updateData)="updatePrecioTransporte($event)">
        </FormElement>
        <div class="doubleColumn interno">
          <FormElement [type]="'time'" [receiveData]="horaRecogida" [error]="horaRecogidaError"
            [label]="'Hora recogida'" [placeholder]="'Hora recogida'" (sendData)="getHoraRecogida($event)"
            (updateData)="updateHoraRecogidaTransporte($event)">
          </FormElement>
          <FormElement [type]="'time'" [receiveData]="horaLlegada" [error]="horaLlegadaError" [label]="'Hora llegada'"
            [placeholder]="'Hora llegada'" (sendData)="getHoraLLegada($event)"(updateData)="updateHoraLlegadaTransporte($event)"></FormElement>
        </div>
      </div>
      <FormElement [label]="'Direccion de recogida'"></FormElement>
      <div class="multipleColumns">
        <FormElement [type]="'input'" [receiveData]="calleDireccionRecogida" [error]="calleDireccionRecogidaError"
          [label]="'Calle, avenida, etc'" [placeholder]="'Calle, avenida, etc'" (updateData)="updateDireccionTransporte($event)" (sendData)="getCalleRecogida($event)">
        </FormElement>
        <FormElement [type]="'number'" [receiveData]="numeroRecogida" [error]="numeroRecogidaError" (updateData)="updateNumeroTransporte($event)" [label]="'Nº'"
          [placeholder]="'Nº'" (sendData)="getNumeroRecogida($event)"></FormElement>
        <FormElement [type]="'input'" [receiveData]="localidadRecogida" (updateData)="updateLocalidadTransporte($event)" [error]="localidadRecogidaError"
          [label]="'Localidad'" [placeholder]="'Localidad'" (sendData)="getLocalidadRecogida($event)"></FormElement>
        <FormElement [type]="'input'" [receiveData]="provinciaRecogida" (updateData)="updateProvinciaTransporte($event)" [error]="provinciaRecogidaError"
          [label]="'Provincia'" [placeholder]="'Provincia'" (sendData)="getProvinciaRecogida($event)"></FormElement>
      </div>
      <div class="doubleColumn">
        <FormElement [type]="'input'" [receiveData]="empresaContratacion"(updateData)="updateEmpresaTransporte($event)" [error]="empresaContratacionError"
          [label]="'Empresa de contratación'" [placeholder]="'Empresa de contratación'"
          (sendData)="getEmpresaContratacion($event)"></FormElement>
        <div class="tripleColumn">
          <FormElement [type]="'input'" (updateData)="updateVehiculoTransporte($event)" [receiveData]="tipoVehiculo" [error]="tipoVehiculoError"
            [label]="'Tipo vehiculo'" [placeholder]="'Tipo vehiculo'" (sendData)="getTipoVehiculo($event)">
          </FormElement>
          <FormElement [type]="'number'" (updateData)="updateCantidadVehiculosTransporte($event)" [receiveData]="vehiculos" [error]="vehiculosError" [label]="'Vehículos'"
            [placeholder]="'Vehículos'" (sendData)="getVehiculos($event)"></FormElement>
          <FormElement [type]="'number'" [receiveData]="plazas" [error]="plazasError" [label]="'Plazas'"
            [placeholder]="'Plazas'" (updateData)="updatePlazasTransporte($event)" (sendData)="getPlazas($event)"></FormElement>
        </div>
      </div>
      <GeneralButton [buttonText]="'Guardar transporte'" (click)="validarFormularioTransporte()"></GeneralButton>
    </form>
  </div>
</div>