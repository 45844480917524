<PageTemplate>
  <ng-container bodyContent>
    <header [searcherActive]="false"></header>
    <div class="pageHeader">
      <h1>Avisos de reportes</h1>
      <div class="communityInfo">
        <h2>Título de la comunidad: </h2>
        <h3 *ngIf="isDataLoaded && reportes.length > 0">{{reportes[0].communityName}}</h3>
        <h3 *ngIf="!isDataLoaded">Cargando nombre de comunidad...</h3>
      </div>
    </div>
    <ng-container *ngIf="!isDataLoaded">
      <mat-spinner class="big center"></mat-spinner>
    </ng-container>
    <ng-container *ngIf="isDataLoaded">
      <p class="noData" *ngIf="reportes.length == 0">No hay reportes disponibles</p>
      <ng-container *ngIf="reportes.length > 0">
        <div class="reporteBody" *ngFor="let reporte of reportes">
          <p class="title">Caso:</p>
          <p class="info">Mensaje {{reporte.reportId}} - Reporte de usuarios</p>
          <p class="title">Mensaje: </p>
          <div class="mensaje">
            <div class="image">

            </div>
            <div class="texto">
              <span>{{reporte.message}}</span>
            </div>
          </div>

          <div class="acciones">
            <div class="users">
              <p class="title">Usuario(s) que han reportado:</p>
              <div class="list">
                <span *ngFor="let usuarios of reporte.reportersName.split(',') | slice:0:3">
                  {{usuarios}}
                </span>
                <span *ngIf="reporte.reportersName.split(',').length > 3">
                  +{{reporte.reportersName.split(',').length - 3}}
                </span>
              </div>
            </div>
            <div class="botones">
              <p class="title">Acciones disponibles:</p>
              <div>
                <GeneralButton [buttonText]="'Eliminar mensaje'" (click)="confirmDelete(reporte.reportId)"></GeneralButton>
                <GeneralButton [buttonText]="'Banear al usuario'"></GeneralButton>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</PageTemplate>
