import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseService } from './Base.service';


@Injectable({
  providedIn: 'root'
})
export class PermissionsService extends BaseService {

  private urlApi = environment.urlApi;

  constructor(private http: HttpClient) {
    super();
  }
  getPermissions(){
    this.http.get(this.urlApi + '/api/permission?num_devoluciones=100&num_pagina=1', this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getPermissions, args: [] })
        this.checkStatusError(error);
      },
    });
  }
  updatePermissions(idUser, idPermission, allowed, group, company){
    this.http.post(this.urlApi + '/api/user/permission/',{companyAdminId: idUser, permission: idPermission, allowed: allowed, group: group, company},this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultUpdate', data)
      },
      error: (error) => {
        this.sendNextResult('resultUpdateError', error, { method: this.updatePermissions, args: [idUser, idPermission, allowed, group] })
        this.checkStatusError(error);
      },
    });
  }
  getAllAdminsOfGroup(){
    this.http.get(this.urlApi + '/api/company/group/admin/'+sessionStorage.getItem("groupId"), this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getAllAdminsOfGroup, args: [] })
        this.checkStatusError(error);
      },
    });
  }
  getAllAdminsOfCompany(id){
    this.http.get(this.urlApi + '/company/admin/'+id, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getAllAdminsOfCompany, args: [id] })
        this.checkStatusError(error);
      },
    });
  }
}
