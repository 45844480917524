import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, catchError } from 'rxjs';
import { Facility } from 'src/app/models/Facility';
import { environment } from 'src/environments/environment';
import { BaseService } from './Base.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class ListsService extends BaseService {

  private urlApi = environment.urlApi;

  constructor(private http: HttpClient) {
    super();
  }
  newList(ticket: any) {
    this.http.post(this.urlApi + '/api/event/list', ticket, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.newList, args: [ticket] })
        this.checkStatusError(error);
      },
    });
  }
  updateList(id, list: any) {
    this.http.put(this.urlApi + '/api/product/' + id, list, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultUpdate', data)
      },
      error: (error) => {
        this.sendNextResult('resultUpdateError', error, { method: this.updateList, args: [id, list] })
        this.checkStatusError(error);
      },
    });
  }
  buyList(id, companion: any) {
    this.http.post(this.urlApi + '/api/event/list/buy/' + id + '/' + companion, {}, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultIndividual', data)
      },
      error: (error) => {
        this.sendNextResult('resultIndividualError', error, { method: this.buyList, args: [id, companion] })
        this.checkStatusError(error);
      },
    });
  }
}
