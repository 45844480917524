import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take, takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { InvoicesService } from 'src/app/services/Api/invoices.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { CompanyService } from 'src/app/services/Api/company.service';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';

@Component({
  selector: 'app-Invoices',
  templateUrl: './Invoices.component.html',
  styleUrls: ['./Invoices.component.scss']
})
export class InvoicesComponent extends UnsubscribesDestroy implements OnInit {

  date = new Date();
  actualYear = this.date.getFullYear();
  total = 0;
  totalImponible = 0
  totalIva = 0
  cabeceras = ["Título evento", "Fecha liquidación", "Base imponible", "IVA", "Total", ""]
  facturas: any = []
  iva = 0.21;
  company: any = null;
  facturaSeleccionada: any = null
  dataLoaded = false;
  constructor(private loginSE: LoginService, private router: Router, private notificaciones: NotificacionesService, private invoicesSE: InvoicesService, private loader: LoaderService, private companySE: CompanyService) {
    super();
  }

  ngOnInit(): void {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      this.checkPermisos();
    }

  }

  addYear() {
    this.clearTotal();
    this.actualYear++;
    this.getTotal()
    this.getInvoices();
  }
  subctractYear() {
    this.clearTotal();
    this.actualYear--;
    this.getTotal()
    this.getInvoices();
  }
  openDocument(factura) {
    this.companySE.getCompanyGroupById(Number(sessionStorage.getItem("groupId")));
    this.companySE.getResultIndividual().pipe(takeUntil(this._unsubInd2)).subscribe(value => {
      if (value == null) { return }
      this.company = value;
      this._unsubInd2.next("");
    })
    this.loader.open();
    setTimeout(() => {
      let data: any = document.getElementById("factura");
      html2canvas(data).then(canvas => {
        const contentDataURL = canvas.toDataURL('image/png', 1.0)
        let pdf = new jspdf('p', 'em', 'a4');
        pdf.addImage(contentDataURL, 'PNG', 0, 0, 0, 0);
        pdf.save(factura.event);
        this.loader.closeSlow();
        this.facturaSeleccionada = null
      });
    }, 1000);



  }
  getTotal() {
    this.total = 0
    this.totalImponible = 0
    this.totalIva = 0
    this.facturas.map(element => {
      this.total += element.amount
      this.totalImponible += element.amount - (element.amount * this.iva)
      this.totalIva += (element.amount * this.iva)
    })
  }
  clearTotal() {
    this.total = 0;
    this.totalImponible = 0;
    this.totalIva = 0;
  }
  getInvoices() {
    this.invoicesSE.getInvoices(5000, 1, sessionStorage.getItem("groupId"), this.actualYear)
    this.invoicesSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return; }
      this.loader.closeSlow()
      this.facturas = value;
      this.getTotal()
      this._unsubInd.next("")
      this.dataLoaded = true;

    })
    this.invoicesSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return; }
      this.loader.closeSlow()
      this.notificaciones.showError(value.error.message)
      this._unsub.next("")
    })
  }
  checkPermisos() {
    if (localStorage.getItem("tipoUser") == null || localStorage.getItem("tipoUser") == undefined || localStorage.getItem("tipoUser") == '["2"]') {
      this.loginSE.logOut();
      this.notificaciones.showError("No tienes permisos para acceder")
    } else {
      if (localStorage.getItem("tipoUser") == '["0"]' || localStorage.getItem("tipoUser") == '["3"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY") && !localStorage.getItem("permisos")?.includes("CONSULT_COMPANY_GROUP")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else {
          this.getInvoices();
        }
      }
    }

  }
}
