import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { sha256 } from 'js-sha256';
import { Login } from 'src/app/models/Login';
import { LoginService } from 'src/app/services/Api/login.service';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { ActivatedRoute, Router } from '@angular/router';
import { SendMailRecoverPassword } from 'src/app/models/SendMailRecoverPassword';
@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent extends UnsubscribesDestroy implements OnInit {
  email: string = "";
  password: string = "";
  emailVacio = false;
  emailValido = true;
  passwordVacio = false;
  check = false;
  validForm = true;
  changePassOpen = false;
  visible: Boolean = false;
  constructor(private laoderSE: LoaderService, private notificaciones: NotificacionesService, private loginSE: LoginService, private router: Router, private activatedRoute: ActivatedRoute) {
    super();
  }


  ngOnInit(): void {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.check = true
    }

    this.activatedRoute.queryParamMap.subscribe(params => {
      if (params["params"].manager != undefined) {
       localStorage.setItem("manager", params["params"].manager)
      }
    })
  }

  sendLogin() {
    if (!this.validateData() && this.emailVacio || !this.validateData() && !this.emailValido) {
      this.notificaciones.showError("Debe introducir un correo electrónico válido")
    }
    if (!this.validateData() && this.passwordVacio) {
      this.notificaciones.showError("Debe introducir una contraseña válida")
    }
    if (this.validateData() && this.validForm) {
      var credentials:Login = {
        username: this.email,
        password: sha256(this.password)
      }
      this.loginSE.checkLogin(credentials);
      this.laoderSE.open();
      this.loginSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value=>{
        if (value==null) {return}
        this.laoderSE.closeSlow();
        this._unsubInd.next("")
      })
      this.loginSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value=>{
        if (value==null) {return}
        this.laoderSE.closeSlow();
        this.notificaciones.showError(value.error.message);
        this._unsub.next("")
      })

    }

  }
  validateData() {
    if (this.email == "") {
      this.validForm = false;
      this.emailVacio = true;
    } else {
      this.emailVacio = false;
    }
    if (this.password == "") {
      this.validForm = false;
      this.passwordVacio = true;
    } else {
      this.passwordVacio = false;
    }
    if (!this.validEmail(this.email)) {
      this.validForm = false;
      this.emailValido = false;
    } else {
      this.emailValido = true;
    }

    if (!this.emailVacio && !this.passwordVacio && this.emailValido) {
      this.validForm = true;
      return true;
    }

    return false;

  }
  validEmail(email: string) {
    var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
    return String(email).search(filter) != -1;
  }
  changePass(){
    if (this.changePassOpen) {
      this.changePassOpen = false;
    }else{
      this.changePassOpen = true;
    }

  }
  sendMail(){
    if (this.email == "") {
      this.validForm = false;
      this.emailVacio = true;
    } else {
      this.emailVacio = false;
    }
    if (!this.validEmail(this.email)) {
      this.validForm = false;
      this.emailValido = false;
    } else {
      this.emailValido = true;
    }
    if (!this.emailVacio && this.emailValido) {
      this.validForm = true;
    }
    if (this.validForm) {
      var mail:SendMailRecoverPassword = {
        email: this.email
      }
      this.loginSE.sendMailRecoverPassword(mail)
      this.laoderSE.open();
      this.loginSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value=>{
        if(value==null){return;}
        this.laoderSE.closeSlow();
        this.notificaciones.showFeedBack("Email enviado con éxito");
        this._unsubInd.next("")
      })
      this.loginSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value=>{
        if (value==null) {return}
        this.laoderSE.closeSlow();
        this.notificaciones.showError(value.error.message);
        this._unsub.next("")
      })
    }
  }
}
