import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PopupService } from 'src/app/services/utils/popup.service';

@Component({
  selector: 'app-NewFacilityPopup',
  templateUrl: './NewFacilityPopup.component.html',
  styleUrls: ['./NewFacilityPopup.component.scss']
})
export class NewFacilityPopupComponent extends UnsubscribesDestroy implements OnInit {

  //VARIABLES FORMULARIO
  nombreRecinto: String = ""
  nombreRecintoError: Boolean = false;

  aforoLocal: Number | null = null;
  aforoLocalError: Boolean = false;

  direccion:String = ""
  direccionError: Boolean = false;

  localidadRecinto:String = ""
  localidadRecintoError: Boolean = false;

  numero:Number | null = null;
  numeroError: Boolean = false;

  constructor(public dialogRef: MatDialogRef<NewFacilityPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private notificaciones: NotificacionesService, private popupSE: PopupService, private loaderSE: LoaderService) {
    super();
  }

  ngOnInit() {
    document.querySelector(".mat-dialog-container")?.classList.add("rightPopup");

  }
  //FUNCIONALIDAD
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  //GETTERS Y SETTERS
  getTituloRecinto(event){
    this.nombreRecinto = event;
  }
  getAforoLocal(event){
    this.aforoLocal = event;
  }
  getDireccion(event){
    this.direccion = event;
  }
  getLocalidad(event){
    this.localidadRecinto = event;
  }


  //VALIDACIONES
  validarTituloRecinto(){
    if (this.nombreRecinto == "") {
      this.nombreRecintoError = true;
    }else{
      this.nombreRecintoError = false;
    }
  }
  validarAforoLocal(){
    if (this.aforoLocal == null) {
      this.aforoLocalError = true;
    }else{
      this.aforoLocalError = false;
    }
  }
  validarDireccion(){
    if(this.direccion == ""){
      this.direccionError = true;
    }else{
      this.direccionError = false;
    }
  }
  validarLocalidad(){
    if (this.localidadRecinto == "") {
      this.localidadRecintoError = true;
    }else{
      this.localidadRecintoError = false;
    }
  }


  validarRecinto(){
    this.validarTituloRecinto();
    this.validarAforoLocal();
    this.validarDireccion();
    this.validarLocalidad();

    if(!this.nombreRecintoError && !this.aforoLocalError && !this.direccionError && !this.localidadRecintoError){
      var recinto = {
        name: this.nombreRecinto,
        capacity: this.aforoLocal,
        company: Number(sessionStorage.getItem("dcmpny")),
        address: {
          street: this.direccion,
          locality: this.localidadRecinto
        }
      }
      this.closePopup(recinto)
    }else{
      this.notificaciones.showError("Hay algunos errores en los campos. Porfavor revísalos")
    }
  }
}
