<button *ngIf="tipo == 1" type="button" (click)="newList()">
  <svg xmlns="http://www.w3.org/2000/svg" width="12.5" height="16.5" viewBox="0 0 12.5 16.5">
    <path id="bolt_1_" data-name="bolt (1)" d="M10.75,13.25h-4l6.5-8.5v6h4l-6.5,8.5Z" transform="translate(-5.75 -3.75)"
      fill="none" stroke="#1017af" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
  </svg>
  <span>{{texto}}</span>
</button>

<button *ngIf="tipo == 2" type="button" class="tipo2" (click)="returnHome()">
  <svg xmlns="http://www.w3.org/2000/svg" width="15.414" height="15.414" viewBox="0 0 15.414 15.414">
    <g id="Grupo_11506" data-name="Grupo 11506" transform="translate(-204.793 -747.793)">
      <line id="Línea_726" data-name="Línea 726" x2="14" y2="14" transform="translate(205.5 748.5)" fill="none"
        stroke="#0e17b6" stroke-width="2" />
      <line id="Línea_727" data-name="Línea 727" x1="14" y2="14" transform="translate(205.5 748.5)" fill="none"
        stroke="#0e17b6" stroke-width="2" />
    </g>
  </svg>
  <span>{{texto}}</span>
</button>
<button *ngIf="tipo == 3" type="button" (click)="sendLink()">
  <svg xmlns="http://www.w3.org/2000/svg" width="16.5" height="16.5" viewBox="0 0 16.5 16.5">
    <g id="Grupo_1704" data-name="Grupo 1704" transform="translate(-14924.75 6052.25)">
      <ellipse id="Elipse_1009" data-name="Elipse 1009" cx="2.75" cy="5.25" rx="2.75" ry="5.25" transform="translate(14934.75 -6051.25)" fill="none" stroke="#1017af" stroke-width="2"/>
      <path id="Trazado_763" data-name="Trazado 763" d="M12.5,11.25S4,9.5,3,9.25a3.472,3.472,0,0,1,0-6.5c1-.25,9.5-2,9.5-2" transform="translate(14925 -6052)" fill="none" stroke="#1017af" stroke-width="2"/>
      <path id="Trazado_764" data-name="Trazado 764" d="M2.75,9.5v3.75a2,2,0,0,0,2,2h.5a2,2,0,0,0,2-2V10.5" transform="translate(14925 -6052)" fill="none" stroke="#1017af" stroke-width="2"/>
    </g>
  </svg>
  <span>{{texto}}</span>
</button>
<button *ngIf="tipo == 4" class="tipo2 copiarCodigo"  [class.posicionEspecial]="posicionEspecial" type="button" (click)="code()">
  <mat-icon>content_copy</mat-icon>
  <span>{{texto}}</span>
</button>
