<div class="confirmGenralContainer">
  <div class="header">
    <p>Reenviar email</p>
    <svg (click)="forceClosePopup()" xmlns="http://www.w3.org/2000/svg" width="20.242" height="20.242"
      viewBox="0 0 20.242 20.242">
      <g id="Grupo_1201" data-name="Grupo 1201" transform="translate(-5674.379 -12806.379)">
        <line id="Línea_323" data-name="Línea 323" x2="16" y2="16" transform="translate(5676.5 12808.5)" fill="none"
          stroke="#0e17b6" stroke-linecap="round" stroke-width="3" />
        <line id="Línea_324" data-name="Línea 324" x1="16" y2="16" transform="translate(5676.5 12808.5)" fill="none"
          stroke="#0e17b6" stroke-linecap="round" stroke-width="3" />
      </g>
    </svg>

  </div>
  <div class="body">
    <div class="dataContainer">
      <label for="">Nombre</label>
      <input type="text" [(ngModel)]="dataToPass.name" (change)="checkData('name', dataToPass.name)" class="inputData"
        placeholder="Nombre">
    </div>
    <div class="dataContainer">
      <label for="">Email</label>
      <input type="text" [(ngModel)]="dataToPass.mail" (change)="checkData('mail', dataToPass.mail)" class="inputData"
        placeholder="Email">
    </div>

  </div>
  <div class="footer">
    <button type="button" (click)="forceClosePopup()">Cancelar</button>
    <ng-container>
      <button type="button" [ngClass]="{disabled: !isComplete}"  (click)="closePopup()">Enviar</button>
    </ng-container>

  </div>
</div>
