import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { AddNewRRPPopupComponent } from 'src/app/popups/addNewRRPPopup/addNewRRPPopup.component';
import { EventService } from 'src/app/services/Api/event.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { PrService } from 'src/app/services/Api/pr.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PopupService } from 'src/app/services/utils/popup.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-PublicRelation',
  templateUrl: './PublicRelation.component.html',
  styleUrls: ['./PublicRelation.component.scss']
})
export class PublicRelationComponent extends UnsubscribesDestroy implements OnInit {

  //VARIABLES
  relacionesPublicas: Array<any> = []
  estadisticas: any = null
  selectedToggle: Number = 0
  eventos: Array<any> = []
  evento: any = null;
  rp: Array<any> = []
  rpAux: Array<any> = []
  //VARIABLES DE CONTROL
  orderBy: String = "Alfabéticamente"
  dataIsLoaded: Boolean = false;
  relacionesAbiertas: Array<Number> = []
  constructor(private notificaciones: NotificacionesService, private loader: LoaderService, private popupSE: PopupService, private prSE: PrService, private loginSE: LoginService, private eventSE: EventService) {
    super();
  }

  ngOnInit() {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      this.checkPermisos();
    }
  }
  newRRPP(event) {
    if (event) {
      this.popupSE.openPopup(AddNewRRPPopupComponent);
      this.popupSE.returnData().pipe(takeUntil(this._unsubInd4)).subscribe(value => {
        if (value == null) { return }
        setTimeout(() => {
          this.obtenerRRPP();
        }, 500);
        this._unsubInd4.next("");
      })
    }
  }
  getFilter(evento) {
    if (evento === "") {
      this.rp = this.rpAux;
    } else {
      this.rp = this.rpAux.filter((valor) => {

        return valor.name.toLowerCase().includes(evento.toLowerCase())
      });
    }
  }
  //FUNCIONALIDADES
  abrirGrupo(id) {
    if (!this.relacionesAbiertas.includes(id)) {
      this.relacionesAbiertas.push(id)
    } else {
      this.relacionesAbiertas.splice(this.relacionesAbiertas.indexOf(id), 1)
    }
  }
  contraerTodos() {
    this.relacionesAbiertas = []
  }
  getEvento() {
    this.loader.open();
    this.obtenerRRPP();
  }
  public exportAsExcelFile(): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.rp);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, "Facturación_RRPP");
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
  //LLAMADAS A LA API
  obtenerRRPP() {
    if (this.orderBy == "Alfabéticamente") {
      this.prSE.getAllPRByCompany(false, this.evento);
    } else {
      this.prSE.getAllPRByCompany(true, this.evento);
    }
    this.prSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return; }

      this.relacionesPublicas = value;
      this.dataIsLoaded = true;
      this.loader.closeSlow();
      this._unsubInd.next("")
    })
    this.prSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return; }
      this.loader.closeSlow();
      this._unsub.next("")
    })
  }
  obtenerEstadisticasHeaderPR() {
    this.prSE.getHeaderStadisticsPR();
    this.prSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return; }

      this.estadisticas = value;
      this._unsubInd.next("")
      setTimeout(() => {
        this.obtenerRRPP();
      }, 500);
    })
    this.prSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return; }
      this._unsub.next("")
    })
  }

  getVentasByEvent(id) {
    this.dataIsLoaded = false;
    this.prSE.getVentasByEvent(id);
    this.prSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return; }
      this.rp = [...value];
      this.rpAux = [...value];
      this._unsubInd.next("")
      this.dataIsLoaded = true;
    })
    this.prSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return; }
      this.notificaciones.showError(value.error.message)
      this._unsub.next("")
      this.dataIsLoaded = true;

    })
  }
  getAllEvent() {
    this.eventSE.getEventSelect()
    this.eventSE.getResultEvent().pipe(takeUntil(this._unsubInd2)).subscribe((res: any) => {
      if (res == null) { return }

      res.forEach(element => {
        element.title = element.title + " - " + element.startDate.split("T")[0].split("-")[2] + "/" + element.startDate.split("T")[0].split("-")[1] + "/" + element.startDate.split("T")[0].split("-")[0];
      });
      this.eventos = [...res];
      this._unsubInd2.next('')
    })
    this.eventSE.getResultError().pipe(takeUntil(this._unsub)).subscribe((res: any) => {
      if (res == null) { return }
      this.notificaciones.showError(res.error.message)
      this._unsub.next('')
    })
  }
  permiso: Boolean = false;
  checkPermisos() {
    if (localStorage.getItem("tipoUser") == null || localStorage.getItem("tipoUser") == undefined || localStorage.getItem("tipoUser") == '["2"]') {
      this.loginSE.logOut();
      this.notificaciones.showError("No tienes permisos para acceder")
    } else {
      if (localStorage.getItem("tipoUser") == '["0"]' || localStorage.getItem("tipoUser") == '["3"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY") && !localStorage.getItem("permisos")?.includes("CONSULT_COMPANY_GROUP")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else {
          if (localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY") && localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY_GROUP")) {
            this.permiso = true;
          }
          this.obtenerEstadisticasHeaderPR();
          this.getAllEvent();
        }
      }
      if (localStorage.getItem("tipoUser") == '["1"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else {
          if (localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY")) {
            this.permiso = true;
          }
          this.obtenerEstadisticasHeaderPR();
          this.getAllEvent();

        }
      }
    }


  }
}
