import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { ConfirmPopupComponent } from 'src/app/popups/confirmPopup/confirmPopup.component';
import { CommunitiesService } from 'src/app/services/Api/communities.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PopupService } from 'src/app/services/utils/popup.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-MyCommunities',
  templateUrl: './MyCommunities.component.html',
  styleUrls: ['./MyCommunities.component.scss']
})
export class MyCommunitiesComponent extends UnsubscribesDestroy implements OnInit {
  comunidadesAdministradas: any = []
  urlImages = environment.urlImages
  permiso: Boolean = false;
  dataIsLoaded: Boolean = false;

  num_pagina: number = 1;
  num_dev: number = 9;
  lastLength: number = 0;
  constructor(private loginSE: LoginService, private router: Router, private loaderSE: LoaderService, private notificaciones: NotificacionesService, private communitiesSE: CommunitiesService, private popupSE: PopupService) {
    super();
  }

  ngOnInit(): void {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {

      this.checkPermisos();
    }
  }
  activatePagination() {
    let that = this;

    window.onscroll = function (ev) {
      if (that.comunidadesAdministradas.length > 0) {
        if (((window.innerHeight + window.scrollY)) >= document.body.scrollHeight) {
          that.num_pagina += 1;
          // that.paginationLoaded = false
          if (that.lastLength != 0) {
            that.getCommunities();

          }
        }
      }
    };
  }
  openEdit(id) {
    this.router.navigate(["/publisher/promotions/communities/" + id])
  }
  getCommunities() {
    this.communitiesSE.getMymanagedCommunities(this.num_dev, this.num_pagina);

    this.communitiesSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return };
      this.lastLength = value.length
      value.forEach(element => {
        this.comunidadesAdministradas.push(element)
      });
      this.dataIsLoaded = true;
      this._unsubInd.next("");
    })
    this.communitiesSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return };
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    })
  }
  checkPermisos() {
    if (localStorage.getItem("tipoUser") == null || localStorage.getItem("tipoUser") == undefined || localStorage.getItem("tipoUser") == '["2"]') {
      this.loginSE.logOut();
      this.notificaciones.showError("No tienes permisos para acceder")
    } else {
      if (localStorage.getItem("tipoUser") == '["0"]' || localStorage.getItem("tipoUser") == '["3"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY") && !localStorage.getItem("permisos")?.includes("CONSULT_COMPANY_GROUP")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else {
          if (localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY") && localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY_GROUP")) {
            this.permiso = true;
          }
          this.getCommunities();
          this.activatePagination();
        }
      }
      if (localStorage.getItem("tipoUser") == '["1"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else {
          if (localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY")) {
            this.permiso = true;
          }
          this.getCommunities();
          this.activatePagination();

        }
      }
    }


  }
  deleteCommunity(event, id) {
    this.popupSE.openPopup(ConfirmPopupComponent, { accion: "Eliminar", tipo: "comunidad" })
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd5)).subscribe(value => {
      if (value == null) { return }
      this.communitiesSE.delteCommunity(id);
      this.communitiesSE.getResultDelete().pipe(takeUntil(this._unsubInd4)).subscribe(res => {
        if (res == null) { return; }
        this.notificaciones.showFeedBack(res.message)
        this._unsubInd4.next("")
        this.getCommunities();
      })
      this.communitiesSE.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe(res => {
        if (res == null) { return; }
        this.notificaciones.showError(res.error.message)
        this._unsub.next("")
      })
      this._unsubInd5.next("");
    })
  }
}
