<li>
  <div class="card-flat" [ngStyle]="{
      backgroundColor: this.isFocus ? '#bec1eb' : ''
    }">
    <a attr.aria-label="Recinto {{ facility.name }}" routerLink="/publisher/places/facilities/{{ facility.id }}"
      class="stretched-link" tabindex="0" (focus)="onFocus()" (blur)="onBlur()">
      <img [src]="facility.map_image" alt="" class="card-img" />
      <div class="card-info">
        <div class="card-title">
          <h5>{{ facility.name }}</h5>
        </div>
      </div>
    </a>
  </div>
</li>