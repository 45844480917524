import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take, takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { CambiarPrecioEntradaPopupComponent } from 'src/app/popups/CambiarPrecioEntradaPopup/CambiarPrecioEntradaPopup.component';
import { LiberarEntradaPopupComponent } from 'src/app/popups/LiberarEntradaPopup/LiberarEntradaPopup.component';
import { NewTicketPopupComponent } from 'src/app/popups/NewTicketPopup/NewTicketPopup.component';
import { TicketsService } from 'src/app/services/Api/tickets.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PopupService } from 'src/app/services/utils/popup.service';

@Component({
  selector: 'CreateEditEvent-Step3',
  templateUrl: './CreateEditEvent-Step3.component.html',
  styleUrls: ['./CreateEditEvent-Step3.component.scss']
})
export class CreateEditEventStep3Component extends UnsubscribesDestroy implements OnInit {

  //VARIABLES DE CONTROL
  entradasDesplegadas: Boolean = false;
  listasDesplegadas: Boolean = false;
  entradas: Array<any> = []
  entradasError: Boolean = false;
  editar: Boolean = false;
  iva: number = 10;
  ivaError: boolean = false;
  precio: number | null = null;
  precioError: boolean = false;
  active: boolean = false;
  discount_rrpp: number | null = null;
  discount_rrppActive: boolean = false;
  discount_rrppError: boolean = false;
  //INPUTS Y OUTPUTS
  @Input() limitacionFecha: any = null;
  @Input() receiveData: any = null;
  @Output() dataToUpdate = new EventEmitter<any>()
  @Output() data = new EventEmitter<any>()
  @Output() validStep = new EventEmitter<Boolean>()
  @Output() update = new EventEmitter<boolean>();


  constructor(private popupSE: PopupService, private loader: LoaderService, private notificaciones: NotificacionesService, private route: ActivatedRoute, private ticketSE: TicketsService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.receiveData != null) {
      this.receiveData = changes['receiveData'].currentValue;
      this.setData();
    }
  }
  ngOnInit() {
    if (this.route.snapshot.params['id'] != null || this.route.snapshot.params['id'] != undefined) {
      this.editar = true;
    }
  }

  //FUNCIONALIDAD
  desplegarEntradas() {
    if (this.entradasDesplegadas) {
      this.entradasDesplegadas = false;
    } else {
      this.entradasDesplegadas = true;
    }
  }
  desplegarListas() {
    if (this.listasDesplegadas) {
      this.listasDesplegadas = false;
    } else {
      this.listasDesplegadas = true;
    }
  }
  changeValue() {
    if (this.active) {
      this.active = false;
      this.precio = null;
      if (this.editar) {
        this.updatePrecio(null)
      }
    } else {
      this.active = true;
    }
  }
  changeValueDiscountRRPP() {
    if (this.discount_rrppActive) {
      this.discount_rrppActive = false;
      this.discount_rrpp = null;
      if (this.editar) {
        this.updateDiscountRRPP(null)
      }
    } else {
      this.discount_rrppActive = true;
    }
  }
  liberarEntrada(entrada) {
    this.popupSE.openPopup(LiberarEntradaPopupComponent, { entrada: entrada });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd4)).subscribe(value => {
      if (value == null) { return }
      setTimeout(() => {
        this.popupSE.openPopup(CambiarPrecioEntradaPopupComponent, { entrada: entrada });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd5)).subscribe(res => {
          if (res == null) { return }
          this.update.emit(true)
          this._unsubInd5.next("")
        })
      }, 1000);

      this.update.emit(true)
      this._unsubInd4.next("");
    })
  }
  //SETTERS AND GETTERS
  getIva(event) {
    this.iva = event;
  }
  getPrecio(event) {
    this.precio = event;
  }
  getDiscountRRPP(event) {
    this.discount_rrpp = event;
  }
  setData() {
    this.entradas = this.receiveData.tickets
    if (this.receiveData.iva != null) {
      this.iva = this.receiveData.iva
    }
    if (this.receiveData.ticketChangeName != null) {

      this.precio = this.receiveData.ticketChangeName
      this.active = true;
    }
    if (this.receiveData.discount_rrpp != null) {

      this.discount_rrpp = this.receiveData.discount_rrpp
      this.discount_rrppActive = true;
    }
  }

  updateIva(event) {
    if (this.editar) { this.dataToUpdate.emit({ element: "iva", value: event }) }
  }
  updatePrecio(event) {
    if (this.editar) { this.dataToUpdate.emit({ element: "ticketChangeName", value: event }) }
  }
  updateDiscountRRPP(event) {
    if (this.editar) { this.dataToUpdate.emit({ element: "discountRrpp", value: event }) }

  }
  addNewTicket() {
    this.popupSE.openPopup(NewTicketPopupComponent,
      {
        accion: "Nueva entrada",
        startDate: this.limitacionFecha.startDate,
        endDate: this.limitacionFecha.endDate,
        iva: this.iva,
        sales: 0,
      });

    this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return }
      if (this.editar) {
        this.entradas.push(value.returnValue);
        this.dataToUpdate.emit({ element: "tickets", value: [value.returnValue] })
      } else {
        this.entradas.push(value.returnValue);
      }
      this._unsubInd.next("")
      this.popupSE.clearData
    })
  }
  editTicket(entrada) {
    this.popupSE.openPopup(NewTicketPopupComponent,
      {
        accion: "Editar entrada",
        entrada
      });

    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(value => {
      if (value == null) { return }
      this.entradas[this.entradas.indexOf(value.returnValue.entradaVieja)] = value.returnValue.entradaNueva
      this._unsubInd2.next("")
      this.popupSE.clearData()
    })
  }
  cambiarActivoTicker(entrada) {
    this.ticketSE.updateTicket(entrada.idProducto, { isActive: entrada.isActive })
    this.ticketSE.getResultUpdate().pipe(takeUntil(this._unsubInd3)).subscribe(value => {
      if (value == null) { return }
      this.notificaciones.showFeedBack(value.message)
      this._unsubInd3.next("");
    })
    this.ticketSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.notificaciones.showError(value.message)
      this._unsub.next("");
    })
  }
  changeActiveTicket(ticket) {
    if (ticket.isActive) {
      ticket.isActive = false;
    } else {
      ticket.isActive = true;

    }
    if (this.editar) {
      this.cambiarActivoTicker(ticket)

    }
  }

  //VALIDACIONES
  validarPaso3() {
    if (this.entradas.length == 0) {
      this.validStep.emit(false)
      this.notificaciones.showError("Debes crear al menos una entrada")
      this.entradasError = true;
    } else {
      this.entradasError = false;
      if (this.active && this.precio == null) {
        this.validStep.emit(false)
        this.precioError = true;
        this.notificaciones.showError("Debes introducir un precio de cambio de nombre")

      } else {
        this.precioError = false;
        var step3Obj = {
          tickets: this.entradas,
          iva: this.iva,
          ticketChangeName: this.precio,
          discountRrpp: this.discount_rrpp
        }
        this.validStep.emit(true)
        this.data.emit(step3Obj)
      }

    }
  }

}
