<PageTemplate>
  <ng-container bodyContent>
    <header [searcherActive]="false"></header>
    <main>
      <div class="formGeneralContainer">
        <form [class.noPermision]="!editar">
          <div class="fotoPerfilContainer" (click)="setClick('.fotoPerfilContainer button .fileInput')">
            <mat-spinner class="medium" *ngIf="!dataLoaded"></mat-spinner>
            <ng-container *ngIf="dataLoaded">
              <div class="imageOverlay">
                <mat-icon>edit</mat-icon>
              </div>
              <button fileInput [accept]="['image/jpeg']" [(fichero)]="image"
                (ficheroChange)="setProfileImage(image)"></button>
              <img *ngIf="fotoPerfil==null" src="/assets/images/defaultImagePerfil.png" alt="" />
              <img *ngIf="fotoPerfil!=null" onerror="this.src='/assets/images/defaultImagePerfil.png'"
                [src]="fotoPerfil" alt="" />
            </ng-container>
          </div>
          <div class="dobleColumna">
            <div class="dataContainer">
              <label [class.vacio]="nombreVacio">Nombre</label>
              <mat-spinner class="medium" *ngIf="!dataLoaded"></mat-spinner>
              <input type="text" *ngIf="dataLoaded" name="name" (change)="updateUser('name', nombre)"
                [(ngModel)]="nombre" placeholder="Nombre" class="inputData">
            </div>
            <div class="dataContainer">
              <label [class.vacio]="apellidosVacio">Apellidos</label>
              <mat-spinner class="medium" *ngIf="!dataLoaded"></mat-spinner>
              <input type="text" *ngIf="dataLoaded" name="apellidos" (change)="updateUser('surname', apellidos)"
                [(ngModel)]="apellidos" placeholder="Apellidos" class="inputData">
            </div>
          </div>
          <div class="dobleColumna">
            <div class="dataContainer">
              <label [class.vacio]="generoVacio">Género</label>
              <mat-spinner class="medium" *ngIf="!dataLoaded"></mat-spinner>
              <input type="text" *ngIf="dataLoaded" name="genero" (change)="updateUser('gender', genero)"
                [(ngModel)]="genero" placeholder="Género" class="inputData">
            </div>
            <div class="dataContainer">
              <label [class.vacio]="fechaNacimientoVacio">Fecha de nacimiento</label>
              <mat-spinner class="medium" *ngIf="!dataLoaded"></mat-spinner>
              <div *ngIf="dataLoaded">
                <input matInput [matDatepicker]="picker" readOnly="true" [value]="fechaNacimiento"
                  (dateChange)="changeDate($event)" placeholder="Fecha de nacimiento" class="inputData datePicker">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker class="miPerfil-movil" #picker></mat-datepicker>
              </div>
            </div>
          </div>
          <div class="dobleColumna">
            <div class="dataContainer">
              <label [class.vacio]="telefonoVacio">Teléfono</label>
              <mat-spinner class="medium" *ngIf="!dataLoaded"></mat-spinner>
              <input *ngIf="dataLoaded" type="number" name="numero" (change)="updateUser('phone', telefono)"
                [(ngModel)]="telefono" placeholder="Número de teléfono" class="inputData">
            </div>
            <div class="dataContainer">
              <label>Email de contacto</label>
              <mat-spinner class="medium" *ngIf="!dataLoaded"></mat-spinner>
              <input *ngIf="dataLoaded" type="email" name="email" disabled [(ngModel)]="email"
                placeholder="Email de contacto" class="inputData">
            </div>
          </div>

          <div class="dataContainer enlace">
            <a (click)="openChangePassword()">¿Quieres cambiar la contraseña?</a>
          </div>
        </form>
      </div>
    </main>
  </ng-container>
</PageTemplate>