<section class="demography-genre-chart">
  <div>
    <div style="display: block">
      <canvas
        baseChart
        [data]="doughnutChartData"
        [type]="doughnutChartType"
        [options]="doughnutChartOptions"
        height="400px"
        width="400px"
      >
      </canvas>
    </div>
  </div>
  <div class="legend">
    <div class="legend-item">
      <span class="legend-item-icon icon-1"></span>
      <span class="legend-item-title">Hombres</span>
    </div>
    <div class="legend-item">
      <span class="legend-item-icon icon-2"></span>
      <span class="legend-item-title">Mujeres</span>
    </div>
  </div>
</section>
