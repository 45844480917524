<div class="searcherGeneralContainer" [class.fullWidth]="size == 'fullWidth'" [class.open]="type=='rp' && rrpp.length != 0">
  <span class="material-icons" *ngIf="!loading">
    search
  </span>
  <mat-spinner class="small" *ngIf="loading"></mat-spinner>
  <input type="text" [(ngModel)]="filter" (input)="filterContent();clearData()" (change)="searchRP();searchEverything();changeEmitter();" [placeholder]="placeHolder">
</div>
<div class="rrppContainer" *ngIf="type=='rp' && rrpp.length != 0">
  <div class="rrpp" *ngFor="let rp of rrpp">
    <span>{{rp.name}}</span>
    <GeneralButton [buttonText]="'Ver detalles'" (click)="verDetalles(rp.id)"></GeneralButton>
  </div>
</div>
<div class="generalContainer" *ngIf="type=='all' && busquedaGeneral.length != 0">
  <div class="general" *ngFor="let general of busquedaGeneral">
    <ng-container *ngIf="general.title != undefined || general.title != null">
      <span>{{general.title}}</span>
      <button type="button" [routerLink]="['/publisher/management/events/my-events/event-details/'+general.id]">Ver detalles</button>
    </ng-container>
    <ng-container *ngIf="general.name != undefined || general.name != null">
      <span>{{general.name}}</span>
      <button type="button" [routerLink]="['/publisher/communities-promotions/my-communities/community-details/'+general.id]">Ver detalles</button>
    </ng-container>
  </div>
</div>
