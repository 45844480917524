<div class="addNewPrContainer">
  <div class="header">
    <p>Detalle del pago</p>
    <svg (click)="forceClosePopup()" xmlns="http://www.w3.org/2000/svg" width="20.242" height="20.242"
      viewBox="0 0 20.242 20.242">
      <g id="Grupo_1201" data-name="Grupo 1201" transform="translate(-5674.379 -12806.379)">
        <line id="Línea_323" data-name="Línea 323" x2="16" y2="16" transform="translate(5676.5 12808.5)" fill="none"
          stroke="#0e17b6" stroke-linecap="round" stroke-width="3" />
        <line id="Línea_324" data-name="Línea 324" x1="16" y2="16" transform="translate(5676.5 12808.5)" fill="none"
          stroke="#0e17b6" stroke-linecap="round" stroke-width="3" />
      </g>
    </svg>
  </div>
  <div class="body" *ngFor="let producto of dataFromView.details">

    <div class="columnGrid">
      <div class="selectorModule">
        <label for="name">Nombre del evento:</label>
          <div class="fakeInput">{{producto.event}}</div>
      </div>

      <div class="selectorModule">
        <label for="name">Fecha de compra:</label>
        <div class="fakeInput">{{dataFromView.date | date:'dd/MM/yyyy'}}</div>
      </div>
    
      <div class="selectorModule">
        <label for="name">Usuario:</label>
        <div class="fakeInput">{{dataFromView.name}}</div>
      </div>
    
      <div class="selectorModule">
        <label for="name">Número de pedido de Redsys:</label>
        <div class="fakeInput">{{dataFromView.redsysOrder}}</div>
      </div>
    
      <div class="selectorModule">
        <label for="name">Cantidad:</label>
        <div class="fakeInput">{{producto.quantity}}</div>
      </div>
    
      <div class="selectorModule">
        <label for="name">Precio:</label>
        <div class="fakeInput">{{producto.product.fee | currency:'EUR' }}</div>
      </div>

      <div class="selectorModule double">
        <label for="name">Descripción:</label>
        <div class="fakeInput ">{{ producto.product.description }}</div>
      </div>
    
    
    
    
    
    </div>

  </div>
  <div class="footer">
    <!-- <button class="btn-sm" (click)="closePopup({id: modalData.entrada.ticketId, user: this.userSelected})">Finalizar</button> -->
  </div>
</div>