import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { StadisticsService } from 'src/app/services/Api/stadistics.service';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { pipe, takeUntil } from 'rxjs';

@Component({
  selector: 'event-units-sales-global',
  templateUrl: './event-units-sales-global.component.html',
  styleUrls: ['./event-units-sales-global.component.scss']
})
export class EventUnitsSalesGlobalComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  @Input() data: any = {}
  @Input() tipo: any = ""
  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    // Generic options
    font: { family: 'Rubik' },

    // Specific options
    layout: {
      padding: 20,
    },
    scales: {
      x: {
        ticks: {
          color: '#0e17b6',
          font: {
            weight: '700',
          },
        },
        stacked: true,
        grid: {
          lineWidth: 0,
        },
      },
      y: {
        ticks: {
          callback: (value) => `${value}`,
          color: '#0e17b6',
          font: {},
        },
        stacked: true,
        grid: {
          color: '#dfe0f5',
          drawBorder: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        align: 'end',
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        yAlign: 'bottom',
        enabled: true,
        backgroundColor: '#0e17b6',
        padding: 14,
        displayColors: false,
        callbacks: {
          title: () => ``,
          label: function (context) {
            let label = context.dataset.label +": "+ context.formattedValue;
            return label;
          },
        },
      },
    },
  };
  public barChartType: ChartType = 'bar';
  public barChartPlugins = [DataLabelsPlugin];

  public barChartDataSemanal: ChartData<'bar'> = {
    labels: ['1', '2', '3', '4', '5', '6', '7'],
    datasets: [
      {
        data: [],
        backgroundColor: '#9FA2E2',
        label: "Online",
        hoverBackgroundColor: '#9FA2E2',
        borderWidth: 2,
        borderColor: '#9FA2E2',
        hoverBorderColor: '#000',
        borderSkipped: false,
      },
      {
        data: [],
        backgroundColor: '#DEDFFB',
        label: "RRPP",
        hoverBackgroundColor: '#DEDFFB',
        borderWidth: 2,
        borderColor: ' #DEDFFB',
        hoverBorderColor: '#000',
        borderSkipped: false,
      }
    ],
  };
  public barChartDataBiSemanal: ChartData<'bar'> = {
    labels: [],
    datasets: [
      {
        data: [],
        label: "Online",
        backgroundColor: '#9FA2E2',
        hoverBackgroundColor: '#9FA2E2',
        borderWidth: 2,
        borderColor: '#9FA2E2',
        hoverBorderColor: '#000',
        borderSkipped: false,
      },
      {
        data: [],
        backgroundColor: '#DEDFFB',
        label: "RRPP",
        hoverBackgroundColor: '#DEDFFB',
        borderWidth: 2,
        borderColor: ' #DEDFFB',
        hoverBorderColor: '#000',
        borderSkipped: false,
      }
    ],
  };
  public barChartDataMensual: ChartData<'bar'> = {
    labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
    datasets: [
      {
        data: [],
        label: "Online",
        backgroundColor: '#9FA2E2',
        hoverBackgroundColor: '#9FA2E2',
        borderWidth: 2,
        borderColor: '#9FA2E2',
        hoverBorderColor: '#000',
        borderSkipped: false,
      },
      {
        data: [],
        backgroundColor: '#DEDFFB',
        label: "RRPP",
        hoverBackgroundColor: '#DEDFFB',
        borderWidth: 2,
        borderColor: ' #DEDFFB',
        hoverBorderColor: '#000',
        borderSkipped: false,
      }
    ],
  };

  constructor() {
  }

  ngOnInit(): void {
    if (this.tipo == "semanal") {
      const date = (() => {
        const now = new Date();
        return new Date(now.setDate(now.getDate() - now.getDay() - -1));
      })();
      const week = Array(7).fill(date).map((date, i) => {
        if (i !== 0) {
          date.setDate(date.getDate() + 1);
        }
        return String(date.getDate());
      });
      this.barChartDataSemanal.labels = week;
      for (let i = 0; i <  this.barChartDataSemanal.labels.length; i++) {
        this.data.forEach(element => {
          if(element.purchase_date.split("-")[2] ==  this.barChartDataSemanal.labels![i]){
            this.barChartDataSemanal.datasets[0].data[i] = element.ventas_unidades_online
            this.barChartDataSemanal.datasets[1].data[i] = element.ventas_unidades_publicRelation
          }
        })
        
      }
    }
    if (this.tipo == "biSemanal") {
      const date = (() => {
        const now = new Date();
        return new Date(now.setDate(now.getDate() - now.getDay() - -1));
      })();
      const week = Array(14).fill(date).map((date, i) => {
        if (i !== 0) {
          date.setDate(date.getDate() + 1);
        }
        return String(date.getDate());
      });
      this.barChartDataBiSemanal.labels = week;
      for (let i = 0; i <  this.barChartDataBiSemanal.labels.length; i++) {
        this.data.forEach(element => {
          if(element.purchase_date.split("-")[2] ==  this.barChartDataBiSemanal.labels![i]){
            this.barChartDataBiSemanal.datasets[0].data[i] = element.ventas_unidades_online
            this.barChartDataBiSemanal.datasets[1].data[i] = element.ventas_unidades_publicRelation
          }
        })
        
      }
    }
    if (this.tipo == "mensual") {
      this.data.forEach(element => {
        switch (element.purchase_date.split("-")[2]) {
          case "01":
            this.barChartDataMensual.datasets[0].data[0] = element.ventas_unidades_online
            this.barChartDataMensual.datasets[1].data[0] = element.ventas_unidades_publicRelation
            break;
          case "02":
            this.barChartDataMensual.datasets[0].data[1] = element.ventas_unidades_online
            this.barChartDataMensual.datasets[1].data[1] = element.ventas_unidades_publicRelation
            break;
          case "03":
            this.barChartDataMensual.datasets[0].data[2] = element.ventas_unidades_online
            this.barChartDataMensual.datasets[1].data[2] = element.ventas_unidades_publicRelation
            break;
          case "04":
            this.barChartDataMensual.datasets[0].data[3] = element.ventas_unidades_online
            this.barChartDataMensual.datasets[1].data[3] = element.ventas_unidades_publicRelation
            break;
          case "05":
            this.barChartDataMensual.datasets[0].data[4] = element.ventas_unidades_online
            this.barChartDataMensual.datasets[1].data[4] = element.ventas_unidades_publicRelation
            break;
          case "06":
            this.barChartDataMensual.datasets[0].data[5] = element.ventas_unidades_online
            this.barChartDataMensual.datasets[1].data[5] = element.ventas_unidades_publicRelation
            break;
          case "07":
            this.barChartDataMensual.datasets[0].data[6] = element.ventas_unidades_online
            this.barChartDataMensual.datasets[1].data[6] = element.ventas_unidades_publicRelation
            break;
          case "08":
            this.barChartDataMensual.datasets[0].data[7] = element.ventas_unidades_online
            this.barChartDataMensual.datasets[1].data[7] = element.ventas_unidades_publicRelation
            break;
          case "09":
            this.barChartDataMensual.datasets[0].data[8] = element.ventas_unidades_online
            this.barChartDataMensual.datasets[1].data[8] = element.ventas_unidades_publicRelation
            break;
          case "10":
            this.barChartDataMensual.datasets[0].data[9] = element.ventas_unidades_online
            this.barChartDataMensual.datasets[1].data[9] = element.ventas_unidades_publicRelation
            break;
          case "11":
            this.barChartDataMensual.datasets[0].data[10] = element.ventas_unidades_online
            this.barChartDataMensual.datasets[1].data[10] = element.ventas_unidades_publicRelation
            break;
          case "12":
            this.barChartDataMensual.datasets[0].data[11] = element.ventas_unidades_online
            this.barChartDataMensual.datasets[1].data[11] = element.ventas_unidades_publicRelation
            break;
          case "13":
            this.barChartDataMensual.datasets[0].data[12] = element.ventas_unidades_online
            this.barChartDataMensual.datasets[1].data[12] = element.ventas_unidades_publicRelation
            break;
          case "14":
            this.barChartDataMensual.datasets[0].data[13] = element.ventas_unidades_online
            this.barChartDataMensual.datasets[1].data[13] = element.ventas_unidades_publicRelation
            break;
          case "15":
            this.barChartDataMensual.datasets[0].data[14] = element.ventas_unidades_online
            this.barChartDataMensual.datasets[1].data[14] = element.ventas_unidades_publicRelation
            break;
          case "16":
            this.barChartDataMensual.datasets[0].data[15] = element.ventas_unidades_online
            this.barChartDataMensual.datasets[1].data[15] = element.ventas_unidades_publicRelation
            break;
          case "17":
            this.barChartDataMensual.datasets[0].data[16] = element.ventas_unidades_online
            this.barChartDataMensual.datasets[1].data[16] = element.ventas_unidades_publicRelation
            break;
          case "18":
            this.barChartDataMensual.datasets[0].data[17] = element.ventas_unidades_online
            this.barChartDataMensual.datasets[1].data[17] = element.ventas_unidades_publicRelation
            break;
          case "19":
            this.barChartDataMensual.datasets[0].data[18] = element.ventas_unidades_online
            this.barChartDataMensual.datasets[1].data[18] = element.ventas_unidades_publicRelation
            break;
          case "20":
            this.barChartDataMensual.datasets[0].data[19] = element.ventas_unidades_online
            this.barChartDataMensual.datasets[1].data[19] = element.ventas_unidades_publicRelation
            break;
          case "21":
            this.barChartDataMensual.datasets[0].data[20] = element.ventas_unidades_online
            this.barChartDataMensual.datasets[1].data[20] = element.ventas_unidades_publicRelation 
            break;
          case "22":
            this.barChartDataMensual.datasets[0].data[21] = element.ventas_unidades_online
            this.barChartDataMensual.datasets[1].data[21] = element.ventas_unidades_publicRelation
            break;
          case "23":
            this.barChartDataMensual.datasets[0].data[22] = element.ventas_unidades_online
            this.barChartDataMensual.datasets[1].data[22] = element.ventas_unidades_publicRelation
            break;
          case "24":
            this.barChartDataMensual.datasets[0].data[23] = element.ventas_unidades_online
            this.barChartDataMensual.datasets[1].data[23] = element.ventas_unidades_publicRelation
            break;
          case "25":
            this.barChartDataMensual.datasets[0].data[24] = element.ventas_unidades_online
            this.barChartDataMensual.datasets[1].data[24] = element.ventas_unidades_publicRelation
            break;
          case "26":
            this.barChartDataMensual.datasets[0].data[25] = element.ventas_unidades_online
            this.barChartDataMensual.datasets[1].data[25] = element.ventas_unidades_publicRelation            
            break;
          case "27":
            this.barChartDataMensual.datasets[0].data[26] = element.ventas_unidades_online
            this.barChartDataMensual.datasets[1].data[26] = element.ventas_unidades_publicRelation
            break;
          case "28":
            this.barChartDataMensual.datasets[0].data[27] = element.ventas_unidades_online
            this.barChartDataMensual.datasets[1].data[27] = element.ventas_unidades_publicRelation
            break;
          case "29":
            this.barChartDataMensual.datasets[0].data[28] = element.ventas_unidades_online
            this.barChartDataMensual.datasets[1].data[28] = element.ventas_unidades_publicRelation
            break;
          case "30":
            this.barChartDataMensual.datasets[0].data[29] = element.ventas_unidades_online
            this.barChartDataMensual.datasets[1].data[29] = element.ventas_unidades_publicRelation
            break;
          case "31":
            this.barChartDataMensual.datasets[0].data[30] = element.ventas_unidades_online
            this.barChartDataMensual.datasets[1].data[30] = element.ventas_unidades_publicRelation
            break;
        }
      });
    }
  }

}
