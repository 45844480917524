import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BaseService } from './Base.service';
import { NotificacionesService } from '../utils/notificaciones.service';
import { Login } from 'src/app/models/Login';
import { SendMailRecoverPassword } from 'src/app/models/SendMailRecoverPassword';
import { TokenManagmentService } from '../utils/TokenManagment.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService extends BaseService {
  urlApi = environment.urlApi;
  constructor(protected http: HttpClient, private notificaciones: NotificacionesService, private router: Router, private activatedRoute: ActivatedRoute) {
    super();
  }
  checkLogin(credentials: Login) {
    this.http.post(this.urlApi + '/login/user', credentials).subscribe({
      next: (data) => {
        this.logIn(data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error)
        this.checkStatusError(error);
      },
    });
  }
  refreshToken() {
    this.http.post(this.urlApi + '/api/refreshToken', { refresh_token: localStorage.getItem("refreshToken") }).subscribe({
      next: (data) => {
        this.setToken(data["token"]);
        this.setRefreshToken(data["refresh_token"]);
        TokenManagmentService.completeRefreshToken();
      },
      error: (error) => {
        TokenManagmentService.tokenLost();
      },
    });
  }
  sendMailRecoverPassword(email: SendMailRecoverPassword) {
    this.http.post(this.urlApi + '/recuperarCuenta', email).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error)
        this.checkStatusError(error);
      },
    });
  }
  saveTokenPush(tokenPush) {
    this.http.post(this.urlApi + "/api/user/token_push", { tokenPush: tokenPush }, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultUpdate', data)
      },
      error: (error) => {
        this.sendNextResult('resultUpdateError', error)
        this.checkStatusError(error);
      },
    });
  }
  private logIn(data) {

    let isManagerIdPresent = localStorage.getItem("manager") != null && localStorage.getItem("manager") != undefined;

    let tipo: any = this.getUserTypeToken(data["token"]);
    if (tipo.rolePrincipal == '["1"]') {
      if (this.mobileCheck()) {
        if (tipo.roles.includes("ROLE_RRPP")) {
          this.notificaciones.showFeedBack("Se ha iniciado sesión correctamente")
          this.setToken(data["token"])
          this.setRefreshToken(data["refresh_token"])
          localStorage.setItem("tipoUser", '["2"]');
          localStorage.setItem("login", "loged")
          if (isManagerIdPresent) {
            setTimeout(() => {
              this.router.navigate(["rrpp/compartir-perfil/" + localStorage.getItem("manager")])
              localStorage.removeItem("manager")
            }, 500);

          } else {
            if (sessionStorage.getItem("savedRoute") == null) {
              setTimeout(() => {
                this.router.navigate(["/rrpp/home"])
              }, 500);
            } else {
              setTimeout(() => {
                this.router.navigate([sessionStorage.getItem("savedRoute")])
              }, 500);
            }
          }
        } else {
          this.notificaciones.showWarning("Esta página solo es accesible desde dispositivos de escritorio")
        }
      } else {
        this.notificaciones.showFeedBack("Se ha iniciado sesión correctamente")
        this.setToken(data["token"])
        this.setRefreshToken(data["refresh_token"])
        localStorage.setItem("tipoUser", tipo.rolePrincipal);
        localStorage.setItem("login", "loged")
        this.saveTokenPush(localStorage.getItem("tokenPush"));
        setTimeout(() => {
          this.router.navigate(["/publisher/home"])

        }, 500);
      }
    }
    if (tipo.rolePrincipal == '["0"]') {
      if (this.mobileCheck()) {
        if (tipo.roles.includes("ROLE_RRPP")) {
          this.notificaciones.showFeedBack("Se ha iniciado sesión correctamente")
          this.setToken(data["token"])
          this.setRefreshToken(data["refresh_token"])
          localStorage.setItem("tipoUser", '["2"]');
          localStorage.setItem("login", "loged")
          if (isManagerIdPresent) {
            setTimeout(() => {
              this.router.navigate(["rrpp/compartir-perfil/" + localStorage.getItem("manager")])
              localStorage.removeItem("manager")
            }, 500);

          } else {
            if (sessionStorage.getItem("savedRoute") == null) {
              setTimeout(() => {
                this.router.navigate(["/rrpp/home"])
              }, 500);
            } else {
              setTimeout(() => {
                this.router.navigate([sessionStorage.getItem("savedRoute")])
              }, 500);
            }
          }
        } else {
          this.notificaciones.showWarning("Esta página solo es accesible desde dispositivos de escritorio")
        }
      } else {
        this.notificaciones.showFeedBack("Se ha iniciado sesión correctamente")
        this.setToken(data["token"])
        this.setRefreshToken(data["refresh_token"])
        localStorage.setItem("tipoUser", tipo.rolePrincipal);
        localStorage.setItem("login", "loged")
        this.saveTokenPush(localStorage.getItem("tokenPush"));
        setTimeout(() => {
          this.router.navigate(["/admin/newDirection"])
        }, 500);
      }
    }

    if (tipo.rolePrincipal == '["2"]' || (tipo.rolePrincipal == '["4"]' && isManagerIdPresent)) {
      if (this.mobileCheck()) {
        this.notificaciones.showFeedBack("Se ha iniciado sesión correctamente")
        this.setToken(data["token"])
        this.setRefreshToken(data["refresh_token"])
        localStorage.setItem("tipoUser", tipo.rolePrincipal);
        localStorage.setItem("login", "loged")
        if (isManagerIdPresent) {
          setTimeout(() => {
            this.router.navigate(["rrpp/compartir-perfil/" + localStorage.getItem("manager")])
            localStorage.removeItem("manager")
          }, 500);

        } else {
          if (sessionStorage.getItem("savedRoute") == null) {
            setTimeout(() => {
              this.router.navigate(["/rrpp/home"])
            }, 500);
          } else {
            setTimeout(() => {
              this.router.navigate([sessionStorage.getItem("savedRoute")])
            }, 500);
          }
        }



      } else {
        this.notificaciones.showWarning("Esta página solo es accesible desde dispositivos móviles")
      }
    }
    if (tipo.rolePrincipal == '["3"]') {
      if (this.mobileCheck()) {
        this.notificaciones.showWarning("Esta página solo es accesible desde dispositivos de escritorio")
      } else {
        this.notificaciones.showFeedBack("Se ha iniciado sesión correctamente")
        this.setToken(data["token"])
        this.setRefreshToken(data["refresh_token"])
        localStorage.setItem("tipoUser", tipo.rolePrincipal);
        localStorage.setItem("login", "loged")
        this.saveTokenPush(localStorage.getItem("tokenPush"));
        if (tipo.roles.length == 2 && tipo.rolePrincipal == '["3"]') {
          localStorage.setItem("onlySA", "true");
          setTimeout(() => {
            this.router.navigate(["/superadmin/home"])

          }, 500);
        }
        if (tipo.roles.length > 2 && tipo.rolePrincipal == '["3"]') {
          setTimeout(() => {
            this.router.navigate(["/admin/newDirection"])

          }, 500);

        }


      }
    }
    if (tipo.rolePrincipal == '["4"]' && !isManagerIdPresent) {
      this.notificaciones.showWarning("Este usuario solo tiene acceso a la aplicación")
    }
    this.sendNextResult('result', data)

  }

  mobileCheck() {
    let check = false;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      check = true
    }
    return check;
  };
  logOut(permisionError: boolean = false) {
    if (permisionError) {
      this.notificaciones.showError("Para acceder a esta página, debes iniciar sesión")
    }
    sessionStorage.removeItem("nmsr");
    sessionStorage.removeItem("dcmpny")
    localStorage.setItem("login", "needLogin")
    localStorage.removeItem("token");
    localStorage.removeItem("tipoUser");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("tokenPush")
    sessionStorage.removeItem("uniqueName")
    sessionStorage.removeItem("profileImage")
    localStorage.removeItem("permisos")
    sessionStorage.removeItem("groupName")
    sessionStorage.removeItem("groupImage")
    sessionStorage.removeItem("groupId")
    sessionStorage.removeItem("prId")
    localStorage.removeItem("keepSessionStorage")
    localStorage.removeItem("myUser")
    localStorage.removeItem("userType")
    localStorage.removeItem("nameUser")
    sessionStorage.removeItem("prCode")
    localStorage.removeItem("onlySA");
    this.router.navigate(["/login"]);
  }
  isLogin() {
    if (localStorage.getItem("token") != null && localStorage.getItem("login") != "needLogin" || localStorage.getItem("token") != undefined && localStorage.getItem("login") != "needLogin") {
      return true;
    }
    return false;
  }

}
