export interface OrganizerCompany {
  id?: Number,
  companyId: Number,
  tradeName: String,
  cif: String
  address: String
}

export class OrganizerCompany implements OrganizerCompany {
  static convertFrontObject(ob): OrganizerCompany {
    let organizerCompanyArray: any = [];
    ob.forEach(element => {
      let organizerCompanyOBJ = new OrganizerCompany();
      organizerCompanyOBJ.id = element["id"];
      organizerCompanyOBJ.companyId = element["company_id"];
      organizerCompanyOBJ.tradeName = element["trade_name"];
      organizerCompanyOBJ.address = element["address"];
      organizerCompanyOBJ.cif = element["cif"]
      organizerCompanyArray.push(organizerCompanyOBJ);
    });

    return organizerCompanyArray;
  }
  static convertFrontSingleObject(ob): OrganizerCompany {
    let organizerCompanyOBJ = new OrganizerCompany();
    organizerCompanyOBJ.id = ob["id"];
    organizerCompanyOBJ.companyId = ob["company_id"];
    organizerCompanyOBJ.tradeName = ob["trade_name"];
    organizerCompanyOBJ.cif = ob["cif"]
    organizerCompanyOBJ.address = ob["address"];

    return organizerCompanyOBJ;
  }
}
