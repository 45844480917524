import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { ConfirmPopupComponent } from 'src/app/popups/confirmPopup/confirmPopup.component';
import { CompanyService } from 'src/app/services/Api/company.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PopupService } from 'src/app/services/utils/popup.service';

@Component({
  selector: 'app-Bussines',
  templateUrl: './Bussines.component.html',
  styleUrls: ['./Bussines.component.scss']
})
export class BussinesComponent extends UnsubscribesDestroy implements OnInit {

  //VARIABLES
  companies: Array<any> = []
  companiesBackup: Array<any> = []
  permiso: Boolean = false;
  dataIsLoaded = false;
  constructor(private loader: LoaderService, private notifications: NotificacionesService, private companySE: CompanyService, private loginSE: LoginService, private notificaciones: NotificacionesService, private popupSE: PopupService) {
    super()
  }

  ngOnInit() {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      this.checkPermisos();
      this.getAllCompaniesOfGroup();

    }
  }
  filteredCompanies(event) {
    this.companies = event;
  }

  //LLAMADAS A LA API
  getAllCompaniesOfGroup() {
    this.companySE.getAllCompaniesOfGroup();
    this.companySE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return }
      this.loader.closeSlow();

      this.companies = value
      this.companiesBackup = value;
      this._unsubInd.next("");
      this.dataIsLoaded = true;
    })
    this.companySE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }

      this.loader.closeSlow();
      this.notifications.showError(value.error.message)
      this._unsub.next("");
    })

  }
  checkPermisos() {
    if (localStorage.getItem("tipoUser") == null || localStorage.getItem("tipoUser") == undefined || localStorage.getItem("tipoUser") == '["2"]') {
      this.loginSE.logOut();
      this.notificaciones.showError("No tienes permisos para acceder")
    } else {
      if (localStorage.getItem("tipoUser") == '["0"]' || localStorage.getItem("tipoUser") == '["3"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY") && !localStorage.getItem("permisos")?.includes("CONSULT_COMPANY_GROUP")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (localStorage.getItem("permisos")?.includes("CREATE_COMPANY")) {
          this.permiso = true;
        }
      }
    }

  }
  deleteCompany(event) {

    this.popupSE.openPopup(ConfirmPopupComponent, { accion: "Eliminar", tipo: "negocio filial" })
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd5)).subscribe(value => {
      if (value == null) { return }
      this.companySE.updateCompany(event, { isActive: false });
      this.companySE.getResultUpdate().pipe(takeUntil(this._unsubInd4)).subscribe(res => {
        if (res == null) { return; }
        this.notificaciones.showFeedBack("Se ha eliminado la compañia filial correctamente")
        this._unsubInd4.next("")
        this.getAllCompaniesOfGroup();
      })
      this.companySE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(res => {
        if (res == null) { return; }
        this.notificaciones.showError(res.error.message)
        this._unsub.next("")
      })
      this._unsubInd5.next("");
    })
  }
}
