import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseService } from './Base.service';

@Injectable({
  providedIn: 'root',
})
export class CampaignService extends BaseService {
  private urlApi = environment.urlApi;

  constructor(private http: HttpClient) {
    super();
  }

  getAll(num_devoluciones: number, num_pag: number, filter: string = '') {
    let url =
      '/api/campaign/company/' +
      sessionStorage.getItem('dcmpny') +
      '?num_devoluciones=' +
      num_devoluciones +
      '&num_pagina=' +
      num_pag;
    if (filter != '') {
      url = url + '&name=' + filter;
    }
    this.http.get(this.urlApi + url, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data);
      },
      error: (error) => {
        this.sendNextResult('resultError', error, {
          method: this.getAll,
          args: [num_devoluciones, num_pag],
        });
        this.checkStatusError(error);
      },
    });
  }
  getSingle(id: number) {
    this.http
      .get(this.urlApi + '/api/campaign/' + id, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult('resultIndividual', data);
        },
        error: (error) => {
          this.sendNextResult('resultIndividualError', error, {
            method: this.getSingle,
            args: [id],
          });
          this.checkStatusError(error);
        },
      });
  }
  getAllAudiences() {
    this.http
      .get(
        this.urlApi +
          '/api/audience/company/' +
          sessionStorage.getItem('dcmpny') +
          '?num_devoluciones=5000&num_pagina=1',
        this.getHeader()
      )
      .subscribe({
        next: (data) => {
          this.sendNextResult('result', data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, {
            method: this.getAllAudiences,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }
  create(campaign: any) {
    this.http
      .post(this.urlApi + '/api/campaign', campaign, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult('resultIndividual', data);
        },
        error: (error) => {
          this.sendNextResult('resultIndividualError', error, {
            method: this.create,
            args: [campaign],
          });
          this.checkStatusError(error);
        },
      });
  }
  lanzar() {
    this.http
      .get(this.urlApi + '/api/campaign/release', this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult('result', data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, {
            method: this.lanzar,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }
  update(id, campaign) {
    this.http
      .put(this.urlApi + '/api/campaign/' + id, campaign, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult('resultUpdate', data);
        },
        error: (error) => {
          this.sendNextResult('resultUpdateError', error, {
            method: this.update,
            args: [id, campaign],
          });
          this.checkStatusError(error);
        },
      });
  }
  updateAudience(id, audience) {
    this.http
      .put(this.urlApi + '/api/audience/' + id, audience, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult('resultUpdate', data);
        },
        error: (error) => {
          this.sendNextResult('resultUpdateError', error, {
            method: this.updateAudience,
            args: [id, audience],
          });
          this.checkStatusError(error);
        },
      });
  }
  calculateAudience(url) {
    this.http
      .get(this.urlApi + '/api/audience/calculator' + url, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult('resultIndividual', data);
        },
        error: (error) => {
          this.sendNextResult('resultIndividualError', error, {
            method: this.calculateAudience,
            args: [url],
          });
          this.checkStatusError(error);
        },
      });
  }
  createAudience(audiencia) {
    this.http
      .post(this.urlApi + '/api/audience', audiencia, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult('resultIndividual', data);
        },
        error: (error) => {
          this.sendNextResult('resultIndividualError', error, {
            method: this.createAudience,
            args: [audiencia],
          });
          this.checkStatusError(error);
        },
      });
  }
  createAnnounce(data: any) {
    this.http
      .post(this.urlApi + '/api/campaign/announce', data, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult('resultUpdate', data);
        },
        error: (error) => {
          this.sendNextResult('resultUpdateError', error, {
            method: this.createAnnounce,
            args: [data],
          });
          this.checkStatusError(error);
        },
      });
  }
}
