import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { UserService } from 'src/app/services/Api/user.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'app-addNewAdminPopup',
  templateUrl: './addNewAdminPopup.component.html',
  styleUrls: ['./addNewAdminPopup.component.scss']
})
export class AddNewAdminPopupComponent extends UnsubscribesDestroy implements OnInit {
  usuarioRegistrado = true;
  filtroCorreo = ""
  usuarioFiltrado:any = {}
  openList = false;
  invitado = false;

  nombre:String = ""
  apellidos:String = ""
  email:String = ""
  phone:number | null = null
  nombreError:boolean = false
  apellidosError:boolean = false
  emailError:boolean = false
  phoneError:boolean = false


  administradorCompany: Boolean = false;
  empresa: any = null
  constructor(public dialogRef: MatDialogRef<AddNewAdminPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private notificaciones: NotificacionesService, private userSE:UserService, private loader:LoaderService) {super()}
  ngOnInit() {
    document.querySelector(".mat-dialog-container")?.classList.add("rightPopup");

  }
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
  filterUser(email){
    if (email != "") {
      this.userSE.filterUserByEmail(email);
      this.loader.open();
      this.userSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value=>{
        if(value==null){return}
        this.loader.closeSlow();
        if (value.message == "El Rol del usuario es superior al que se le quiere asignar.") {
          this.notificaciones.showError(value.message)
          this.openList = false;
        }else{
          this.openList = true;
          this.usuarioFiltrado = value
        }
      
        this._unsubInd.next("");
      })
      this.userSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value=>{
        if(value==null){return}
        this.loader.closeSlow();
        this.notificaciones.showWarning(value.error.message)
        this._unsub.next("");
      })
    }
   
  }
  sendInvitation(){
    if (this.empresa == null) {
      this.userSE.sendInvitationEmail({companyId:Number(sessionStorage.getItem("groupId")),userId: this.usuarioFiltrado.id, rol:"ROLE_COMPANY_GROUP_ADMIN"});

    }else{
      this.userSE.sendInvitationEmail({companyId:this.empresa,userId: this.usuarioFiltrado.id, rol:"ROLE_COMPANY_ADMIN"});

    }
    // this.loader.open();
    this.userSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe(value=>{
      if(value==null){return}
      this.loader.closeSlow();
      this.notificaciones.showFeedBack(value)
      this.closePopup();
      this._unsubInd2.next("");
    })
    this.userSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value=>{
      if(value==null){return}
      this.loader.closeSlow();
      this.notificaciones.showError(value.error.message)
      this.closePopup();
      this._unsub.next("");
    })
  }
  sendRegister(){
    this.validateEmail()
    this.validateName();
    this.validatePhone();
    this.validateSurname();
    if (!this.nombreError && !this.apellidosError && !this.emailError && !this.phoneError) {
      var userAdmin = {
        name: this.nombre,
        surname: this.apellidos,
        phone: this.phone,
        email: this.email,
        companyId: Number(sessionStorage.getItem("dcmpny")),
        birthDate: '2000-01-01'
      }
      this.userSE.addNewUser(userAdmin);
      this.loader.open();
      this.userSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe(value=>{
        if(value==null){return}
        this.loader.closeSlow();
        this.notificaciones.showFeedBack(value.message)
        this.closePopup();
        this._unsubInd2.next("");
      })
      this.userSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value=>{
        if(value==null){return}
        this.loader.closeSlow();
        this.notificaciones.showError(value.error.message)
        this.closePopup();
        this._unsub.next("");
      })
    }else{
      this.notificaciones.showError("No pueden haber campos vacios")
    }
  }
  validateName(){
    if (this.nombre == "") {
      this.nombreError = true;
    }else{
      this.nombreError = false;
    }
  }
  validateSurname(){
    if (this.apellidos == "") {
      this.apellidosError = true;
    }else{
      this.apellidosError = false;
    }
  }
  validateEmail(){
    if (this.email == "") {
      this.emailError = true;
    }else{
      if (!this.validEmail(this.email)) {
        this.emailError = true;
        this.notificaciones.showError("El formato del email no es correcto")
      }else{
        this.emailError = false;
      }
    }
  }
  validatePhone(){
    if (this.phone == null) {
      this.phoneError = true;
    }else{
      this.phoneError = false;
    }
  }
  validEmail(email) {
    var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
    return String(email).search(filter) != -1;
  }
}
