import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { CompanyService } from 'src/app/services/Api/company.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { UserService } from 'src/app/services/Api/user.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent extends UnsubscribesDestroy implements OnInit {
  urlImages = environment.urlImages
  showQuickActions: boolean = false;
  showDirections: boolean = false;
  desplegarEmpresas = false;
  groupName: string = sessionStorage.getItem("groupName")!
  userType = localStorage.getItem("tipoUser")![2];
  groupImage = sessionStorage.getItem("groupImage")!
  companyId = sessionStorage.getItem("dcmpny")
  companies: Array<any> = []
  companyName: String = ""
  openedSocial: Boolean = false;
  openedAdministracion: Boolean = false;
  route: String = ""
  onlySuperAdmin: Boolean = false;
  permisoPermisos: Boolean = false;
  constructor(private loginSE: LoginService, private userSE: UserService, private router: Router, private companiesSE: CompanyService, private notificaciones: NotificacionesService) {
    super();
  }

  ngOnInit(): void {
    if (this.router.url.includes("admin")) {
      if (this.router.url.includes("superadmin")) {
        this.route = "superadmin"
      } else if (this.router.url.includes("admin")) {
        this.route = "admin"
        if (this.router.url.includes("social")) {
          this.openSocial();
        }
        if (this.router.url.includes("administration")) {
          this.openAdministracion();
        }
      }
    }
    setTimeout(() => {

      setTimeout(() => {
        this.groupName = sessionStorage.getItem("groupName")!
        this.groupImage = sessionStorage.getItem("groupImage")!
      }, 1000);

      this.userType = localStorage.getItem("tipoUser")![2];

      this.obtainCompanies();

    }, 1000);

  }
  updateGo(id) {
    let keepSessionStorage = {
      "nmsr": sessionStorage.getItem("nmsr"),
      "uniqueName": sessionStorage.getItem("uniqueName"),
      "profileImage": sessionStorage.getItem("profileImage"),
      "groupName": sessionStorage.getItem("groupName"),
      "groupId": sessionStorage.getItem("groupId"),
      "groupImage": sessionStorage.getItem("groupImage"),
      "dcmpny": id
    };
    localStorage.setItem("keepSessionStorage", JSON.stringify(keepSessionStorage));
  }
  removeGo() {
    localStorage.removeItem("keepSessionStorage");
  }
  obtainCompanies() {
    if (this.userType == '0' || this.userType == '3') {
      this.companiesSE.getAllCompaniesOfGroup();
      this.companiesSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe(value => {
        if (value == null) { return }
        this.companies = value;
        this.companies.forEach(element => {
          if (element.id == this.companyId) {
            this.companyName = element.name
          }
        });
        this._unsubInd2.next("");
      })
    } else {
      this.userSE.obtainMyCompanies();
      this.userSE.getResultIndividual().pipe(takeUntil(this._unsubInd2)).subscribe(value => {
        if (value == null) { return }
        this.companies = value;
        this.companies.forEach(element => {
          if (element.id == this.companyId) {
            this.companyName = element.name
          }
        });
        this._unsubInd2.next("");
      })
    }
  }
  onDisplayQuickActions(): void {
    this.showQuickActions = true;
    this.onCloseDirections();
  }

  onCloseQuickActions(): void {
    this.showQuickActions = false;
  }

  onDisplayDirections(): void {
    this.showDirections = true;
    this.onCloseQuickActions();
  }

  onCloseDirections(): void {
    this.showDirections = false;
  }
  logOut() {
    this.loginSE.logOut();
  }
  controlarDespliegueEmpresas() {
    if (localStorage.getItem("onlySA") != 'true') {
      if (this.desplegarEmpresas) {
        this.desplegarEmpresas = false;
      } else {
        this.desplegarEmpresas = true;
      }
    } else {
      this.onlySuperAdmin = true;
    }


  }
  reloadCompany(id) {
    sessionStorage.setItem("dcmpny", id)
    if (this.route != 'admin' && this.route != 'superadmin') {
      window.location.reload()
    } else {
      this.router.navigate(["/publisher/home"])
    }
  }
  openSocial() {
    if (this.openedSocial) {
      this.openedSocial = false;
    } else {
      this.openedSocial = true;
    }
    this.openedAdministracion = false;
  }
  openAdministracion() {
    if (this.openedAdministracion) {
      this.openedAdministracion = false;
    } else {
      this.openedAdministracion = true;
    }
    this.openedSocial = false;
  }
  goToAdministration() {
    if (localStorage.getItem("tipoUser") == '["0"]' || localStorage.getItem("tipoUser") == '["3"]') {
      this.router.navigate(["/admin/home"])
      sessionStorage.setItem("dcmpny", "")
    }
  }
  goToWelcomePage() {
    if (localStorage.getItem("tipoUser") == '["0"]' || localStorage.getItem("tipoUser") == '["3"]') {
      this.router.navigate(["/admin/newDirection"])
      sessionStorage.setItem("dcmpny", "")
    }
  }
}
