export interface Facility_old {
  id?: number;
  name: string;
  maxCapacity: number;
  etiquetas: [];
  address: {
    street: string;
    city: string;
    postalCode: string;
    country: string;
    coordinates: {
      lat: number;
      lng: number;
    };
  };
  totalAreas: number;
  totalSqm: number;
  availableBookings: number;
}

export interface Facility {
  id?: number,
  name: string,
  title: string,
  description: string,
  capacity: number,
  square_meters: number,
  start_time: string,
  finish_time: string,
  floor_plans :string[];
  categories:string[];
  map_image: string,
  company: any,
  address:any;
  creation_date?: string
  is_active: boolean 
}

export class Facility  implements Facility {
  static convertFrontObject(ob): Facility {
    let facilityArray:any = [];
    ob.forEach(element => {
      let facilityOBJ = new Facility();

      facilityOBJ.id = element["id"]
      facilityOBJ.name = element["name"]
      facilityOBJ.title = element["title"]
      facilityOBJ.description = element["description"]
      facilityOBJ.capacity = element["capacity"]
      facilityOBJ.square_meters = element["square_meters"]
      facilityOBJ.start_time = element["start_time"]
      facilityOBJ.map_image = element["map_image"]
      facilityOBJ.floor_plans = element["floor_plans"]
      facilityOBJ.finish_time = element["finish_time"]
      facilityOBJ.categories = element["categories"]
      facilityOBJ.company = element["company"]
      facilityOBJ.address = element["address"]
      facilityOBJ.creation_date = element["creation_date"]
      facilityOBJ.is_active = element["is_active"]

      facilityArray.push(facilityOBJ);
    });
    
    return facilityArray;
  }
  static convertFrontSingleObject(ob): Facility {
      let facilityOBJ = new Facility();
      facilityOBJ.id = ob["id"]
      facilityOBJ.name = ob["name"]
      facilityOBJ.title = ob["title"]
      facilityOBJ.description = ob["description"]
      facilityOBJ.capacity = ob["capacity"]
      facilityOBJ.square_meters = ob["square_meters"]
      facilityOBJ.start_time = ob["start_time"]
      facilityOBJ.floor_plans = ob["floor_plans"]
      facilityOBJ.finish_time = ob["finish_time"]
      facilityOBJ.categories = ob["categories"]
      facilityOBJ.company = ob["company"]
      facilityOBJ.address = ob["address"]
      facilityOBJ.creation_date = ob["creation_date"]
    
    return facilityOBJ;
  }
}