
<PageTemplate>
  <ng-container bodyContent>
    <header [searcherActive]="false" [buttonActive]="false"></header>
    <div class="master-container">
      <div class="add-campaign-page">

        <main>
          <section class="screen-1">
            <div class="form-container">
              <form class="form-control">
                <div class="form-group">
                  <label for="name">Selecciona el tipo de campaña que quieres realizar</label>
                  <FormElement *ngIf="false" [receiveData]="nombreCampanya" [error]="nombreCampanyaError" [type]="'input'" [placeholder]="'Nombre de la campaña'" [label]="''" (sendData)="getNombreCampnya($event)"></FormElement>
                </div>
              </form>
            </div>
            <div class="pill-group">
              <ul>
                <li>
                  <div class="pill-lg-soft">
                    <div class="pill-title">
                      <h5>Campaña de email</h5>
                    </div>
                    <div class="pill-details">
                      <small
                        >Campaña de mailing. Crea a través de esta herramienta
                        un modelo de mail para enviar a tus contactos.</small
                      >
                    </div>
                    <div class="pill-button">
                      <button   [routerLink]="'/publisher/communities-promotions/promotions/add-campaign/mailing'">
                        Crear
                      </button>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="pill-lg-soft">
                    <div class="pill-title">
                      <h5>Notificaciones PUSH</h5>
                    </div>
                    <div class="pill-details">
                      <small
                        >Contacta con tus bases datos vía notificación PUSH. Una
                        estrategia idónea para promocionar tus eventos a última
                        de hora.</small
                      >
                    </div>
                    <div class="pill-button">
                      <button
                        [routerLink]="'/publisher/communities-promotions/promotions/add-campaign/push'"
                      >
                        Crear
                      </button>
                    </div>
                  </div>
                </li>
                <li *ngIf="false">
                  <div class="pill-lg-soft">
                    <div class="pill-title">
                      <h5>Promoción eventos en destacados</h5>
                    </div>
                    <div class="pill-details">
                      <small
                        >Destaca tus eventos con publicidad dentro de nuestra
                        plataforma.</small
                      >
                    </div>
                    <div class="pill-button">
                      <button
                        [routerLink]="'./form'"
                        [queryParams]="{ type: 'destacado' }"
                      >
                        Crear
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </section>
        </main>
      </div>
    </div>
  </ng-container>
</PageTemplate>
