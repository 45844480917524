import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { initializeApp } from "firebase/app";
import { get, getDatabase, off, onChildAdded, onChildChanged, ref } from 'firebase/database';
import { ChatService } from 'src/app/services/Api/chat.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { PreviewDocumentsPopupComponent } from 'src/app/popups/previewDocumentsPopup/previewDocumentsPopup.component';
import { PopupService } from 'src/app/services/utils/popup.service';
import { ConfirmPopupComponent } from 'src/app/popups/confirmPopup/confirmPopup.component';
import { StaticUtilities } from 'src/app/models/StaticUtilities';
import { ReportesService } from 'src/app/services/Api/Reportes.service';
const firebaseConfig = environment.firebaseConfig
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

@Component({
  selector: 'Chat',
  templateUrl: './CommunityDetails-Chat.component.html',
  styleUrls: ['./CommunityDetails-Chat.component.scss']
})
export class CommunityDetailsChatComponent extends UnsubscribesDestroy implements OnInit {

  urlImages = environment.urlImages
  @Input() community:any = null
  mensajes: any = []
  imagen: any = null;
  video: any = null;
  documento: any = null
  mensaje = ""
  idSelected = -1
  mensajeFijado: any = null;
  isOpen = false
  mensajeFijadoIsOpen = false;
  idUsuario = Number(sessionStorage.getItem("nmsr"))
  tokensPush = [];
  constructor(private notificaciones: NotificacionesService, private activatedRoute: ActivatedRoute, private loader: LoaderService, private chatSE: ChatService, private popupSE: PopupService, private reportSE: ReportesService) {
    super();
  }

  ngOnInit() {
    this.getMessages();
  }
  getMessages() {
    let starCountRef = ref(database, 'community/' + this.activatedRoute.snapshot.params["id"] + '/messages');
    onChildAdded(starCountRef, (snapshot: any) => {
      if (snapshot.exists()) {
        if (snapshot.val().userId == Number(sessionStorage.getItem("nmsr"))) {
          this.mensajes.push(snapshot.val())
        } else {
          this.mensajes.push(snapshot.val())
        }
      }
    });
    setTimeout(() => {
      var elem = (document.getElementById('chat') as HTMLElement)!;
      elem.scrollTop = elem.scrollHeight;
    }, 500);
  }
  sendMessage() {
    // if (this.documento != null) {
    //   obj.documento = this.documento;
    //   obj.urlDocumento = this.convertToURLDownload(this.documento)
    // }
    if (this.mensaje != "" || this.imagen != null || this.video != null || this.documento != null) {
      this.chatSE.sendMessage(this.activatedRoute.snapshot.params["id"], { userId: sessionStorage.getItem("nmsr"), uniqueName: sessionStorage.getItem("uniqueName"), profileImage: sessionStorage.getItem("profileImage"), message: this.mensaje });
    }
    this.chatSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return }
      this.mensaje = ""
      setTimeout(() => {
        var elem = (document.getElementById('chat') as HTMLElement)!;
        elem.scrollTop = elem.scrollHeight;
      }, 500);
      this._unsubInd.next("");
    })
    this.chatSE.getResultError().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return }
      this._unsubInd.next("");
    })
    this.mensaje = ""
    this.imagen = null
    this.video = null
    this.documento = null

  }
  viewImage() {
    this.popupSE.openPopup(PreviewDocumentsPopupComponent, { document: this.imagen[0], type: 'image' })
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return; }
      if (value.removeDocument != null || value.removeDocument != undefined) {
        this.imagen = null;
        this._unsubInd.next("");
        this.popupSE.clearData();
      } else {
        this.mensaje = value.returnValue.mensaje;
        this.sendMessage();
        this._unsubInd.next("");
        this.popupSE.clearData();
      }
    })
  }
  setClickImagen() {
    (document.querySelector(".imagenes .fileInput") as HTMLElement).click();
    this.openMenu();

  }


  setClickVideo() {
    (document.querySelector(".videos .fileInput") as HTMLElement).click();
    this.openMenu();
  }
  viewVideo() {
    this.popupSE.openPopup(PreviewDocumentsPopupComponent, { document: this.video[0], type: 'video' })
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return; }
      if (value.removeDocument != null || value.removeDocument != undefined) {
        this.video = null;
        this._unsubInd.next("");
        this.popupSE.clearData();
      } else {
        this.mensaje = value.returnValue.mensaje;
        this.sendMessage();
        this._unsubInd.next("");
        this.popupSE.clearData();
      }
    })
  }

  setClickDocument() {
    (document.querySelector(".documentos .fileInput") as HTMLElement).click();
    this.openMenu();
  }
  viewDocument() {
    this.popupSE.openPopup(PreviewDocumentsPopupComponent, { document: this.documento[0], type: 'document' })
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return; }
      if (value.removeDocument != null || value.removeDocument != undefined) {
        this.documento = null;
        this._unsubInd.next("");
        this.popupSE.clearData();
      } else {
        this.mensaje = value.returnValue.mensaje;
        this.sendMessage();
        this._unsubInd.next("");
        this.popupSE.clearData();
      }
    })
  }
  convertToURLDownload(documento) {
    return window.URL.createObjectURL(StaticUtilities.base64ToBlob(documento[0]));
  }
  openMenu() {
    if (!this.isOpen) {
      this.isOpen = true;
    } else {
      this.isOpen = false;
      this.idSelected = -1
    }

  }
  copyMessageText(mensaje) {
    if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard api method'
      this.notificaciones.showFeedBack("Mensaje copiado correctamente");
      return navigator.clipboard.writeText(mensaje.message);

    } else {
      // text area method
      let textArea = document.createElement("textarea");
      textArea.value = mensaje.message;
      // make the textarea out of viewport
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      this.openMenu();
      return new Promise<void>((res, rej) => {
        // here the magic happens
        document.execCommand('copy') ? res() : rej();
        textArea.remove();
        this.notificaciones.showFeedBack("Mensaje copiado correctamente");
      });
    }

  }
  resendMessage(mensaje) {
    this.mensaje = mensaje.message;
    this.sendMessage();
    setTimeout(() => {
      var elem = (document.getElementById('chat') as HTMLElement)!;
      elem.scrollTop = elem.scrollHeight;
    }, 100);

  }
  removeMessage(message, tipo) {
    this.openMenu();
    this.popupSE.openPopup(ConfirmPopupComponent, { accion: "Eliminar", tipo: "mensaje", genero: "masculino", elementos: message })
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return }
      if (value.returnValue.accionHecha == true) {
        if (value.returnValue.elemento == this.mensajeFijado) {
          this.unfixMessage();
        }
        this.chatSE.removeMessages(message.messageId, this.activatedRoute.snapshot.params["id"])
        this.chatSE.getResultDelete().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (res == null) { return }
          this.notificaciones.showFeedBack("Se ha eliminado el mensaje correctamente")
          this.mensajes.splice(this.mensajes.indexOf(value.returnValue.elemento), 1)
          this._unsubInd2.next("");
        })
        this.chatSE.getResultDeleteError().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (res == null) { return }
          this.notificaciones.showError(res.error.message)
          this._unsubInd2.next("");
        })
        // this.mensajesExternos.splice(this.mensajesExternos.indexOf(value.returnValue.elemento), 1)

      }
      this._unsubInd.next("");
      this.popupSE.clearData();
    })
  }
  fixMessage(mensaje) {
    this.mensajeFijado = mensaje;
    this.openMenu();
    var container = document.getElementById('chat') as HTMLElement;
    container.addEventListener('scroll', () => {
      (document.getElementById("fijado") as HTMLElement)!.style.top = container.scrollTop + "px";
    })

  }
  openMensajeFijado() {
    if (!this.mensajeFijadoIsOpen) {
      this.mensajeFijadoIsOpen = true;
    } else {
      this.mensajeFijadoIsOpen = false;
    }
  }
  unfixMessage() {
    this.mensajeFijado = null;
    this.mensajeFijadoIsOpen = false;
  }
  goToMessage(id) {
    var scrollTo = document.getElementById(id) as HTMLElement;
    scrollTo.classList.add("scrolled")
    scrollTo.scrollIntoView();
    setTimeout(() => {
      scrollTo.classList.remove("scrolled")
    }, 1000);
  }
  confirmarReportar(mensaje){
    this.popupSE.openPopup(ConfirmPopupComponent, { accion: "Reportar", tipo: "el mensaje" })
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd6)).subscribe(value => {
      if (value == null) { return }
      this.reportarMensaje(mensaje)
      this._unsubInd6.next("");
    })
  }

  reportarMensaje(mensaje){
    let report = {
      community: Number(this.activatedRoute.snapshot.params["id"]),
      message: mensaje.message,
      firebaseRef: mensaje.messageId
    }
    this.reportSE.create(report)
    this.reportSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res)=>{
      if(res==null){return}
      this.notificaciones.showFeedBack(res.message)
      this._unsubInd.next('')
    })
    this.reportSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res)=>{
      if(res==null){return}
      this.notificaciones.showError(res.error.message)
      this._unsub.next('')
    })
  }
}
