import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FacilityService } from 'src/app/services/Api/facility.service';
import { Facility } from 'src/app/models/Facility';
import { LoginService } from 'src/app/services/Api/login.service';
import { takeUntil } from 'rxjs';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { EventService } from 'src/app/services/Api/event.service';
import { environment } from 'src/environments/environment';
import { Fecha } from 'src/app/models/Fecha';

@Component({
  selector: 'app-facility-details-page',
  templateUrl: './facility-details-page.component.html',
  styleUrls: ['./facility-details-page.component.scss'],
})
export class FacilityDetailsPageComponent extends UnsubscribesDestroy implements OnInit {
  showModal: boolean = false;
  id!: number;
  facility: any = { id: -1, name: "", description: "", capacity: 0, square_meters: 0, floor_plans: [], categories: [], company: { id: -1 }, address: {}, creation_date: "", map_image: "" }
  lat = 51.678418;
  lng = 7.809007;
  zoom = 18
  eventos: any = []
  urlImages = environment.urlImages
  date: Fecha = new Fecha();
  isDataLoaded = false;

  constructor(

    private route: ActivatedRoute,
    private loginSE: LoginService,
    private facilitiesSE: FacilityService,
    private loaderSE: LoaderService,
    private notificacionesSE: NotificacionesService,
    private eventSE: EventService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      this.checkPermisos()
    }
  }
  onDelete(): void {
    this.showModal = true;
  }

  toggleModal(e: boolean) {
    this.showModal = e;
  }
  permiso: Boolean = false;
  checkPermisos() {
    if (localStorage.getItem("tipoUser") == null || localStorage.getItem("tipoUser") == undefined || localStorage.getItem("tipoUser") == '["2"]') {
      this.loginSE.logOut();
      this.notificacionesSE.showError("No tienes permisos para acceder")
    } else {
      if (localStorage.getItem("tipoUser") == '["0"]' || localStorage.getItem("tipoUser") == '["3"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificacionesSE.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY") && !localStorage.getItem("permisos")?.includes("CONSULT_COMPANY_GROUP")) {
          this.loginSE.logOut();
          this.notificacionesSE.showError("No tienes permisos para acceder")
        } else {
          if (localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY") && localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY_GROUP")) {
            this.permiso = true;

          }

          this.id = this.route.snapshot.params['id'];
          this.facilitiesSE.getFacilityById(this.id);
          this.facilitiesSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
            if (value == null) { return; }

            this.facility = value;
            this.lat = Number(this.facility.address.latitude)
            this.lng = Number(this.facility.address.longitude)
            this._unsubInd.next("");
          })
          this.facilitiesSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
            if (value == null) { return; }
            this.notificacionesSE.showError(value.error.message)
            this._unsub.next("");
          })

          this.eventSE.getEventByFacility(this.id);
          this.eventSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe(value => {
            if (value == null) { return }
            this.eventos = value;
            this.isDataLoaded = true;
            this._unsubInd2.next("")
          })
          this.eventSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe(value => {
            if (value == null) { return }
            this.notificacionesSE.showError(value.error.message)
            this._unsubInd2.next("")
          })
        }
      }
    }
    if (localStorage.getItem("tipoUser") == '["1"]') {
      if (localStorage.getItem("permisos") == "null") {
        this.loginSE.logOut();
        this.notificacionesSE.showError("No tienes permisos para acceder")
      } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY")) {
        this.loginSE.logOut();
        this.notificacionesSE.showError("No tienes permisos para acceder")
      } else {
        if (localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY")) {
          this.permiso = true;
        }

        this.id = this.route.snapshot.params['id'];
        this.facilitiesSE.getFacilityById(this.id);
        this.facilitiesSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
          if (value == null) { return; }
          this.isDataLoaded = true;
          this.facility = value;
          this.lat = Number(this.facility.address.latitude)
          this.lng = Number(this.facility.address.longitude)
          this._unsubInd.next("");
        })
        this.facilitiesSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
          if (value == null) { return; }
          this.notificacionesSE.showError(value.error.message)
          this._unsub.next("");
        })

        this.eventSE.getEventByFacility(this.id);
        this.eventSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe(value => {
          if (value == null) { return }
          this.eventos = value;
          this._unsubInd2.next("")
        })
        this.eventSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe(value => {
          if (value == null) { return }
          this.notificacionesSE.showError(value.error.message)
          this._unsubInd2.next("")
        })
      }
    }
  }
}
