<main>
  <div class="not-found-page">
    <div class="content">
      <h1>404 - Not Found</h1>
      <p>Parece que la página que estabas buscando no existe</p>
      <a *ngIf="!loged" routerLink="/login" class="btn-lg-alt">Volver al login</a>
      <a *ngIf="loged && admin" routerLink="/admin/home" class="btn-lg-alt">Volver al home</a>
      <a *ngIf="loged && !admin" routerLink="/publisher/home" class="btn-lg-alt">Volver al home</a>
    </div>
  </div>
</main>
