import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { ValidationTicketPopupComponent } from 'src/app/popups/validationTicketPopup/validationTicketPopup.component';
import { LoginService } from 'src/app/services/Api/login.service';
import { TicketsService } from 'src/app/services/Api/tickets.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PopupService } from 'src/app/services/utils/popup.service';

@Component({
  selector: 'ValidateTickets',
  templateUrl: './ValidateTickets.component.html',
  styleUrls: ['./ValidateTickets.component.scss']
})
export class ValidateTicketsComponent extends UnsubscribesDestroy implements OnInit {

  output: string = "";
  stopFlag: boolean = false;
  constructor(private loginSE: LoginService, private notificaciones: NotificacionesService, private loaderSE: LoaderService, private ticketSE: TicketsService, private popupSE: PopupService, private route: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    }
  }
  onError(e: any): void {
    alert(e);
  }
  setOutput(event) {
    this.output = event;
    var qrInfo = {
      soldTicketId: Number(event.split(",")[0].split(":")[1].replace(/\s/g, '')),
      producto: event.split(",")[3].split(":")[1].replace(/\s/g, ''),
      imagen: event.split(",")[6].split(" ")[1].replace(/\s/g, ''),
      dni: event.split(",")[2].split(" ")[1].replace(/\s/g, ''),
      start_date: event.split(",")[4].split(" ")[1].replace(/\s/g, ''),
      finish_date: event.split(",")[5].split(" ")[1].replace(/\s/g, ''),
      name: event.split(",")[1].split(" ")[1].replace(/\s/g, ''),
    }
    if (!this.stopFlag) {
      this.stopFlag = true;
      this.popupSE.openPopup(ValidationTicketPopupComponent, { qrInfo: qrInfo, eventId:  this.route.snapshot.params['id']});
      this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
        if (res == null) { return };
        this.stopFlag = res.stopFlag
        this._unsubInd2.next("")

      })
    }


  }

}
