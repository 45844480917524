import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { EditarUsuarioPopupComponent } from 'src/app/popups/EditarUsuarioPopup/EditarUsuarioPopup.component';
import { LoginService } from 'src/app/services/Api/login.service';
import { UserService } from 'src/app/services/Api/user.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PopupService } from 'src/app/services/utils/popup.service';

@Component({
  selector: 'app-GestionCRM',
  templateUrl: './GestionCRM.component.html',
  styleUrls: ['./GestionCRM.component.scss']
})
export class GestionCRMComponent extends UnsubscribesDestroy implements OnInit {

  numDevoluciones: number = 6;
  numPagina: number = 1;
  usuarios: Array<any> = []
  genericFilter: string = "";
  dataIsLoaded: boolean = false;
  constructor(private notificaciones: NotificacionesService, private loginSE: LoginService, private userSE: UserService, private popupSE: PopupService) {
    super();
  }

  ngOnInit() {
    document.querySelector(".bodyGeneralContainer")!.classList.add("superAdmin");
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      this.checkPermisos();
    }
  }

  /**
   * GETTERS & SETTERS
   */
  getFilter(event) {
    this.genericFilter = event;
    this.numDevoluciones = 6;
    this.numPagina = 1;
    this.dataIsLoaded = false;
    this.getAll();
  }

  /**
   * FUNCTIONALITIES
   */
  paginaAnterior() {
    if (this.numPagina > 1) {
      this.numPagina--;
      this.dataIsLoaded = false;
      this.getAll();
    }
  }
  siguientePagina() {
    this.numPagina++;
    this.dataIsLoaded = false;
    this.getAll();
  }
  openEditUser(id: number) {
    this.popupSE.openPopup(EditarUsuarioPopupComponent, { id: id })
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((res: any) => {
      if (res == null) { return }
      this.getAll();
      this._unsubInd2.next('')
    })
  }
  /**
   * CALLS TO API
   */
  getAll() {
    this.userSE.getAllForSA(this.numDevoluciones, this.numPagina, this.genericFilter);
    this.userSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (res == null) { return }
      this.usuarios = [...res];
      this.dataIsLoaded = true;
      this._unsubInd.next('')
    })
    this.userSE.getResultError().pipe(takeUntil(this._unsub)).subscribe((res: any) => {
      if (res == null) { return }
      this.notificaciones.showError(res.error.message)
      this._unsub.next('')
    })
  }

  /**
  * PERMISSIONS
  */
  checkPermisos() {
    if (localStorage.getItem("tipoUser") == null || localStorage.getItem("tipoUser") == undefined || localStorage.getItem("tipoUser") != '["3"]') {
      this.loginSE.logOut();
      this.notificaciones.showError("No tienes permisos para acceder")
    } else {
      if (localStorage.getItem("tipoUser") == '["3"]') {
        this.getAll();
      }
    }

  }
}
