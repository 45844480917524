import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import esLocale from '@fullcalendar/core/locales/es';

@Component({
  selector: 'app-events-calendar',
  templateUrl: './events-calendar.component.html',
  styleUrls: ['./events-calendar.component.scss'],
})
export class EventsCalendarComponent implements OnInit, OnChanges {
  constructor() {}
  @ViewChild('calendar') calendarComponent!: FullCalendarComponent; 
  private calendarApi:any;

  @Output() fechaSeleccionada = new EventEmitter<Date>();
  @Output() cambioDeMes = new EventEmitter<Date>();
  @Input() eventos: any = {};
  ngAfterViewInit(){
    this.calendarApi = this.calendarComponent.getApi();
    let currentDate = this.calendarApi.currentData.dateProfileGenerator.nowDate;
  
    this.fechaSeleccionada.emit(currentDate);
  }
  ngOnInit(): void {

  }

  convertirArrayFechasEventos(event: any) {
    let res: any = [];
    event.forEach((element: any) => {
        let evento = { title: element.title, date: element.startDate };
        res.push(evento);
      });
    return res;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.eventos != null) {
      this.calendarOptions.events = this.convertirArrayFechasEventos(this.eventos);
    }
  }

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    headerToolbar: {
      left: 'prev',
      center: 'title',
      right: 'next',
    },
    locale: esLocale,
    events: [],
    dateClick: this.handleDateClick.bind(this),
    //cambio de mes
    datesSet: (arg) => {
      this.cambioDeMes.emit(arg.view.currentStart);
    },
  };

  handleDateClick(arg: any) {
    this.fechaSeleccionada.emit(arg.date);
  }
}
