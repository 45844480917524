import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { PrService } from 'src/app/services/Api/pr.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mi-equipo',
  templateUrl: './mi-equipo.component.html',
  styleUrls: ['./mi-equipo.component.scss']
})
export class MiEquipoComponent extends UnsubscribesDestroy implements OnInit {
  urlImages = environment.urlImages
  niveles: number = 0
  levels:any = []
  pr: any = null
  isDataLoaded: boolean = false
  constructor(private loader: LoaderService, private notificaciones: NotificacionesService, private prSE: PrService, private router: Router) {
    super();
  }

  ngOnInit() {
    this.getPRById();
  }
  navigateTo(){
    this.router.navigate(["/rrpp/compartir-perfil"])
  }
  getLevels(subordinados) {
    subordinados.forEach(element => {
      if (element.subordinates.length != 0) {
        this.niveles ++;
        this.levels.push({nivel: this.niveles, equipo: element.subordinates})
        this.getLevels(element.subordinates)
      }
    });
  }
  getPRById() {
    this.loader.open();
    this.prSE.getPRById(Number(sessionStorage.getItem("prId")));
    this.prSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return }
    this.pr = value;
  if (value.subordinates.length > 0) {
      this.niveles = 1
      this.levels.push({nivel: this.niveles, equipo: value.subordinates})
    }
    this.getLevels(value.subordinates)
    this.isDataLoaded = true;

    this.loader.closeSlow()
      this._unsubInd.next("");
    })
    this.prSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.notificaciones.showFeedBack(value.message)
      this.loader.closeSlow()

      this._unsub.next("");
    })
  }
}
