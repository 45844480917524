<PageTemplate>
  <ng-container bodyContent>
    <header [searcherActive]="false" [buttonActive]="true" [buttonAction]="'Crear campaña'" [permiso]="permiso">
    </header>
    <searcher size="fullWidth" placeHolder="Busca una campaña" (emitChange)="getFilter($event)"></searcher>
    <ng-container *ngIf="!isDataLoaded">
      <div class="noData">
        <mat-spinner class="medium"></mat-spinner>
      </div>
    </ng-container>
    <ng-container *ngIf="isDataLoaded">
      <p class="noData" *ngIf="audiencias.length == 0">No hay audiencias creadas todavía</p>
      <div class="tableGeneralContainer" *ngIf="audiencias.length != 0">
        <div class="header">
          <span class="active"></span>
          <span class="nombre">Nombre</span>
          <span class="tipo">Tipo</span>
          <span class="alcance">Alcance</span>
          <span class="creacion">Creación</span>
          <span class="inicio">Inicio</span>
          <span class="fin">Finalización</span>
          <span class="enviada">Enviada</span>
          <span class="extraButton"></span>
        </div>
        <div class="body">
          <div class="campaign" *ngFor="let campaign of audiencias"
            [class.loading]="!isLocalDataLoaded && updatingCampaign == campaign.id">
            <div class="elements" [class.active]="openeds.includes(campaign.id)"
              [class.loading]="!isLocalDataLoaded && updatingCampaign == campaign.id">
              <div class="data active" (click)="$event.stopPropagation();">
                <label class="switch" [class.noPermision]="!permiso">
                  <input name="active" type="checkbox" [checked]="campaign.is_active == true"
                    (click)="updateCampaign(campaign.id, campaign.is_active, campaign ); $event.preventDefault()" />
                  <span class="slider round"></span>
                </label>
              </div>
              <div class="data nombre"><span class="name">{{campaign.name}}</span></div>
              <div class="data tipo"><span class="type">{{campaign.type}}</span></div>
              <div class="data alcance"><span class="general">{{campaign?.audiences[0]?.alcance}}</span></div>
              <div class="data creacion"><span class="general">{{campaign.start_date | date: 'dd'}}
                  {{campaign.start_date | date: 'MMMM'}} {{campaign.start_date | date: 'yyyy'}}</span></div>
              <div class="data inicio"><span class="general">{{campaign.start_date | date: 'dd'}} {{campaign.start_date
                  | date: 'MMMM'}} {{campaign.start_date | date: 'yyyy'}}</span></div>
              <div class="data fin">
                <ng-container *ngIf="campaign.finish_date">
                  <span class="general">{{campaign.finish_date | date: 'dd'}} {{campaign.finish_date |
                    date: 'MMMM'}} {{campaign.finish_date | date: 'yyyy'}}</span>
                </ng-container>
                <ng-container *ngIf="!campaign.finish_date">
                  <span class="general">-</span>
                </ng-container>
              </div>
              <div class="data enviada"><span class="general">{{campaign.is_released ? "Sí" : "No"}}</span></div>
              <div class="data extraButton">
                <mat-icon (click)="$event.stopPropagation();copyCampaign(campaign)">file_copy</mat-icon>
                <button (click)="controlOpened(campaign.id)">
                  <mat-icon>more_horiz</mat-icon>
                </button>
              </div>
            </div>
            <mat-spinner class="medium" *ngIf="!isLocalDataLoaded && updatingCampaign == campaign.id"></mat-spinner>

            <div class="moreInfo" [class.opened]="openeds.includes(campaign.id)">
              <div class="audiencia" *ngFor="let audiencia of campaign.audiences">
                <div class="audienciaContainer">
                  <p>Audiencia</p>
                  <p class="valor">{{audiencia.name}}</p>
                </div>
                <div class="masInfoAudiencia">
                  <div class="edad">
                    <p>Edad</p>
                    <p class="valor">{{audiencia.from_age}} - {{audiencia.until_age}} años</p>
                  </div>
                  <div class="ubicacion">
                    <p>Ubicación</p>
                    <p class="valor" *ngIf="audiencia.locality != ''">{{audiencia.locality}}</p>
                    <p class="valor" *ngIf="audiencia.locality == ''">No existe ubicación</p>
                  </div>
                  <div class="intereses">
                    <p>Intereses</p>
                    <div>
                      <ng-container *ngIf="audiencia.categories.length > 0">
                        <span *ngFor="let categoria of audiencia.categories">{{categoria.name}}</span>
                      </ng-container>
                      <ng-container *ngIf="audiencia.categories.length == 0">
                        <span>No hay intereses</span>
                      </ng-container>

                    </div>
                  </div>
                </div>
              </div>
              <!-- <GeneralButton [buttonText]="'Editar campaña'" *ngIf="campaign.type == 'email' || campaign.type=='Email'" [routerLink]="['/publisher/communities-promotions/promotions/add-campaign/mailing/'+campaign.id]"   [class.noPermision]="!permiso"></GeneralButton>
              <GeneralButton [buttonText]="'Editar campaña'" *ngIf="campaign.type == 'push' || campaign.type =='Push'"  [class.noPermision]="!permiso"></GeneralButton> -->
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</PageTemplate>