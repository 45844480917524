
<div class="loginGeneralContainer">
  <img src="/assets/icons/logoLogin.png" alt="Logo login memorable">
  <div class="loginContainer">
    <form action="" >
      
      <div class="dataContainer">
        <div class="especial" *ngIf="!visible">
          <mat-icon (click)="visible = true">visibility</mat-icon>
          <input [class.error]="passwordError" class="inputData"[(ngModel)]="password" name="password" type="password" placeholder="Nueva contraseña">
        </div>
        <div class="especial" *ngIf="visible">
          <mat-icon (click)="visible = false">visibility_off</mat-icon>
          <input [class.error]="passwordError" class="inputData"[(ngModel)]="password" name="password" type="text" placeholder="Nueva contraseña">
        </div>
      </div>
        <button (click)="changePass()"  type="submit">Cambiar contraseña</button>
    </form>
  </div>
</div>