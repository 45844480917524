<headerMovil></headerMovil>
<div class="bodyGeneralContainer">
  <ng-container *ngIf="!invitacion">
    <p>Comparte el enlace para añadir un nuevo miembro a tu equipo</p>
    <GeneralButton [buttonText]="'Copiar enlace'" (click)="shareLink()"></GeneralButton>
  </ng-container>
  <ng-container *ngIf="invitacion">
    <p><span *ngIf="dataLoaded">{{pr.user.name}} {{pr.user.surname}}</span> te ha invitado a formar parte de su equipo.</p>
    <div class="buttonContainer">
      <GeneralButton [buttonText]="'Aceptar'" (click)="aceptar()"></GeneralButton>
      <GeneralButton [buttonText]="'Rechazar'" [type]="'secondary'" (click)="rechazar()"></GeneralButton>
    </div>
  </ng-container>
</div>