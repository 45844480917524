import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';

@Component({
  selector: 'demographicChart',
  templateUrl: './demographicChart.component.html',
  styleUrls: ['./demographicChart.component.scss']
})
export class DemographicChartComponent implements OnInit {

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  @Input() type = 'edad';
  @Input() dataEdad:any = { catorce_a_dieciocho: 0, dieciocho_a_veintiuno: 0, veintiuno: 0, treinta: 0, cuarenta: 0, cincuenta: 0 };
  @Input() dataSexo:any = { men: 0, women: 0 };
  @Input() dataInterest:any = {};
  keys: any = []
  // Include 'any' type in options to avoid library error
  public doughnutChartOptions: any['options'] = {
    responsive: true,
    cutout: '85%',
    // Generic options
    font: { family: 'Rubik' },

    // Specific options
    layout: {
      padding: 20,
    },
    scales: {
      x: {
        ticks: {
          display: false,
        },
        grid: {
          lineWidth: 0,
          drawBorder: false,
        },
      },
      y: {
        ticks: {
          display: false,
        },
        grid: {
          lineWidth: 0,
          drawBorder: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        caretSize: 0,
        enabled: true,
        backgroundColor: '#0e17b6',
        padding: 14,
        displayColors: false,
        callbacks: {},
      },
    },
  };
  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartPlugins = [DataLabelsPlugin];

  public doughnutChartDataEdad: ChartData<'doughnut'> = {
    labels: [
      'Menores de 18 años',
      'Entre 18 y 21 años',
      'Entre 21 y 30',
      'Entre 30 y 40 años',
      'Entre 40 y 50 años',
      'Más de 50',
    ],
    datasets: [
      {
        data: [],
        backgroundColor: [
          '#CCCDDF',
          '#7A7FE6',
          '#5960E1',
          '#1017AF',
          '#686BB0',
          '#74A6D8'
        ],
        hoverBackgroundColor: [
          '#CCCDDF',
          '#7A7FE6',
          '#5960E1',
          '#1017AF',
          '#686BB0',
          '#74A6D8'
        ],
        borderWidth: 2,
        borderColor: ['#f5f6ff', '#f5f6ff', '#f5f6ff', '#f5f6ff', '#f5f6ff'],
        hoverBorderColor: ['#000', '#000', '#000', '#000', '#000'],
        hoverOffset: 4,
        borderRadius: 50,
        spacing: 2,
      },
    ],
  };
  public doughnutChartDataSexo: ChartData<'doughnut'> = {
    labels: ['Hombres', 'Mujeres'],
    datasets: [
      {
        data: [],
        backgroundColor: ['#CCCDDF', '#7A7FE6'],
        borderWidth: 2,
        borderColor: ['#f5f6ff', '#f5f6ff', '#f5f6ff', '#f5f6ff', '#f5f6ff'],
        hoverBackgroundColor: ['#CCCDDF', '#7A7FE6'],
        hoverBorderColor: ['#000', '#000'],
        hoverOffset: 4,
        borderRadius: 50,
        spacing: 2,
      },
    ],
  };
  public doughnutChartDataPlatform: ChartData<'doughnut'> = {
    labels: ['App', 'Web'],
    datasets: [
      {
        data: [],
        backgroundColor: ['#CCCDDF', '#7A7FE6'],
        borderWidth: 2,
        borderColor: ['#f5f6ff', '#f5f6ff', '#f5f6ff', '#f5f6ff', '#f5f6ff'],
        hoverBackgroundColor: ['#CCCDDF', '#7A7FE6'],
        hoverBorderColor: ['#000', '#000'],
        hoverOffset: 4,
        borderRadius: 50,
        spacing: 2,
      },
    ],
  };
  public doughnutChartDataInterest: ChartData<'doughnut'> = {
    labels: this.keys,
    datasets: [
      {
        data: [],
        backgroundColor: [
          '#CCCDDF',
          '#7A7FE6',
          '#5960E1',
          '#1017AF',
          '#686BB0',
          '#000669',
        ],
        hoverBackgroundColor: [
          '#CCCDDF',
          '#7A7FE6',
          '#5960E1',
          '#1017AF',
          '#686BB0',
          '#000669',
        ],
        borderWidth: 2,
        borderColor: ['#f5f6ff', '#f5f6ff', '#f5f6ff', '#f5f6ff', '#f5f6ff'],
        hoverBorderColor: ['#000', '#000', '#000', '#000', '#000', '#000'],
        hoverOffset: 4,
        borderRadius: 50,
        spacing: 2,
      },
    ],
  };
  constructor() { }

  ngOnInit(): void {
  
    if (this.type == "categorias") {
      this.keys = Object.keys(this.dataInterest)
      this.doughnutChartDataInterest.labels = this.keys
      this.keys.forEach(element => {
        this.doughnutChartDataInterest.datasets[0].data.push(this.dataInterest[element])
      });
    }
    if (this.type == "edad") {
      this.doughnutChartDataEdad.datasets[0].data = [this.dataEdad.catorce_a_dieciocho, this.dataEdad.dieciocho_a_veintiuno, this.dataEdad.veintiuno, this.dataEdad.treinta, this.dataEdad.cuarenta, this.dataEdad.cincuenta]
    }
    if (this.type == "sexo") {
     
        this.doughnutChartDataSexo.datasets[0].data[0] = this.dataSexo.men
        this.doughnutChartDataSexo.datasets[0].data[1] = this.dataSexo.women
     
    }
    if(this.type == "platform"){
      this.doughnutChartDataPlatform.datasets[0].data[0] = this.dataSexo.app
      this.doughnutChartDataPlatform.datasets[0].data[1] = this.dataSexo.web
    }

  }

}
