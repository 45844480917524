<div class="welcome_container">
  <img src="/assets/icons/logoLoginBlue.png" class="imageWelcome">
  <div class="title_welcome">
    <p><b>¡Bienvenido a Memorable!</b><br>¿A dónde deseas dirigirte?</p>
  </div>
  <div class="link_to_navigation">
    <div>
      <ManagementInfoCard [title]="'Super administrador'" [description]="'Accede al panel de gestión de memorable'"
        [link]="'/superadmin/home'">
      </ManagementInfoCard>
    </div>
    <div>
      <ManagementInfoCard [title]="'Administración general'" [description]="'Accede al panel de gestión de tu grupo'"
        [link]="'/admin/home'">
      </ManagementInfoCard>
    </div>
    <div>
      <ManagementInfoCard [title]="'Negocios filiales'" [description]="'Accede a tus negocios filiales'"
        [link]="'/admin/my-companies'">
      </ManagementInfoCard>
    </div>
  </div>
</div>