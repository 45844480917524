import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { UserService } from 'src/app/services/Api/user.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'app-SearchUser-Popup',
  templateUrl: './SearchUser-Popup.component.html',
  styleUrls: ['./SearchUser-Popup.component.scss'],
})
export class SearchUserPopupComponent
  extends UnsubscribesDestroy
  implements OnInit
{
  // Old Data
  usuario: any = null;
  oldUsuario: any = null;
  newUser: any = null;

  users: any[] = [];
  userSelected: any = null;

  tickets: any[] = [];
  ticketSelected: any = null;

  phone: string = '';

  isActive: boolean = false;
  isUserChecked: boolean = false;

  // Debouncer
  debouncingTimer: any;

  isLoading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<SearchUserPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private userSE: UserService,
    private notificationSE: NotificacionesService
  ) {
    super();
  }

  ngOnInit() {
    this.oldUsuario = this.modalData.usuarios.find(
      (u) => u.name == this.modalData.entrada.user
    );
    this.usuario = this.modalData.usuarios.find(
      (u) => u.name == this.modalData.entrada.user
    ).id;
  }

  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
  getNewUser() {
    this.newUser = this.modalData.usuarios.find((u) => u.id == this.usuario);
  }
  isComplete() {
    if (
      this.userSelected &&
      this.phone &&
      this.ticketSelected &&
      this.isUserChecked
    ) {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
  }
  debounceCheckPhone() {
    clearTimeout(this.debouncingTimer);
    if (!this.phone) {
      this.clearSelectedUser();
      return;
    }
    this.isLoading = true;
    this.debouncingTimer = setTimeout(() => {
      this.ckeckPhoneNumber();
    }, 500);
  }

  clearSelectedUser() {
    this.userSelected = null;
    this.newUser = null;
    this.isUserChecked = false;
    this.isLoading = false;
    this.isComplete();
  }

  /**
   * API CALLS
   */

  ckeckPhoneNumber() {
    this.userSE.checkUserByPhone(this.phone);
    this.userSE
      .getResult()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.userSelected = res.id;
        this.newUser = res;
        this.isUserChecked = true;
        this.notificationSE.showFeedBack(
          'Usuario con ese numero de teléfono encontrado'
        );
        this.isComplete();
        this.isLoading = false;
        this._unsubInd2.next('');
      });
  }
}
