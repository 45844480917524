<div class="stepGeneralContainer">
  <div class="stepLeft">
    <FormElement [receiveData]="nombreEvento" [error]="nombreEventoError" [type]="'input'"
      [placeholder]="'Nombre del evento'" [label]="'¿Cómo se va a llamar tu evento?'"
      (sendData)="getNombreEvento($event)" (updateData)="updateNombreEvento($event)"></FormElement>
    <FormElement [receiveData]="descripcionEvento" [error]="descripcionEventoError" [type]="'textArea'"
      [textAreaRows]="'10'" [placeholder]="'Descripción'" [label]="'Descripción'"
      (sendData)="getDescripcionEvento($event)" (updateData)="updateDescripcionEvento($event)"></FormElement>
    <FormElement [loaded]="dataLoaded" [receiveData]="categoriesSelected" [error]="categoriesSelectedError"
      [type]="'pills'" [label]="'Temática del evento'" [pills]="categories"
      (sendData)="getCategoriasSeleccionadas($event)" (updateData)="updateCategoriasSeleccionadas($event)">
    </FormElement>
    <FormElement [error]="comapanyError" [label]="'Empresa organizadora'"></FormElement>
    <mat-spinner class="medium center" *ngIf="!dataLoaded"></mat-spinner>
    <ng-container *ngIf="dataLoaded">
      <div class="selectorEspecial" (click)="openSpecialSelector()" [class.opened]="specialSelectorOpened">
        <input type="text" [(ngModel)]="specialSelectorValue" readonly="true" placeholder="Empresa organizadora">
        <span></span>
        <div class="buttonsContainer" *ngIf="companyFormOpened">
          <button type="button" (click)="addCompanyForm(); $event.stopPropagation();">Cancelar</button>
          <button type="button" (click)="addCustomCompany(); $event.stopPropagation();">Guardar empresa</button>
        </div>
        <div class="companyListContainer" (click)="$event.stopPropagation();"
          [class.companyFormOpened]="companyFormOpened">
          <ng-container *ngIf="!companyFormOpened">
            <div class="companyList">
              <span class="list" *ngFor="let option of companies" [class.companySelected]="company.id == option.id"
                (click)="addCompany(option.id,option);$event.stopPropagation(); updateEmpresaOrganizadora();openSpecialSelector()">{{option.tradeName}}</span>
            </div>
            <span (click)="addCompanyForm()">Añadir empresa</span>
          </ng-container>
          <ng-container *ngIf="companyFormOpened">
            <p>Rellena todos los campos para añadir un nueva empresa</p>
            <div>
              <div>
                <label [class.vacio]="razonSocialError">Razón social</label>
                <input type="text" placeholder="Razón social" [(ngModel)]="razonSocial">
              </div>
              <div>
                <label [class.vacio]="cifError">CIF</label>
                <input type="text" placeholder="CIF" [(ngModel)]="cif">
              </div>
            </div>
            <div>
              <div>
                <label [class.vacio]="direccionError">Dirección</label>
                <input type="text" placeholder="Dirección" [(ngModel)]="direccion">
              </div>
              <div>
                <label [class.vacio]="codigoPostalError">Código postal</label>
                <input type="number" placeholder="Código postal" [(ngModel)]="codigoPostal">
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <FormElement [label]="'Tipo de evento'"></FormElement>
    <SwitchOptions [elementActive]="tipoEvento" [elements]="['Público', 'Privado']"
      (optionSelected)="getTipoEvento($event)"></SwitchOptions>
  </div>
  <div class="stepRight">
    <div class="doubleColumn">
      <FormElement [receiveData]="vestimenta" (updateData)="updateVestimenta($event)" [error]="vestimentaError"
        [type]="'selector'" [label]="'Vestimenta del evento'" [options]="vestimentas"
        [placeholder]="'Selecciona el código de vestimenta'" [selectorType]="'vestimenta'"
        (sendData)="getVestimenta($event)"></FormElement>
      <FormElement [receiveData]="edadMinima" (updateData)="updateEdadMinima($event)" [error]="edadMinimaError"
        [type]="'selector'" [label]="'Edad mínima'" [options]="edadesMinima"
        [placeholder]="'Selecciona una edad mínima'" [selectorType]="'edadesMinimas'"
        (sendData)="getEdadMinima($event)"></FormElement>
    </div>
    <FormElement [receiveData]="condicionesCompra" (updateData)="updateCondiciones($event)"
      [error]="condicionesCompraError" [type]="'textArea'" [textAreaRows]="'25'"
      [placeholder]="'Condiciones de compra de las entradas'" [label]="'Condiciones de compra de las entradas'"
      (sendData)="getCondicionesCompra($event)"></FormElement>
    <FormElement [error]="permisoParentalError" [label]="'¿Se necesita permiso parental para acceder al evento?'">
    </FormElement>
    <SwitchOptions [elementActive]="permiso"
      [elements]="['Sí, se necesita presentar permiso firmado', 'No, el evento no lo requiere']"
      (optionSelected)="getPermiso($event)"></SwitchOptions>
    <div class="uploadFileContainer" *ngIf="permiso == 0">
      <div>
        <button fileInput [masInfo]="masInfo" [accept]="['application/pdf']" [(fichero)]="permisoParental"
          (ficheroChange)="getPermisoParental(permisoParental)"></button>
        <img src="/assets/icons/uploadIcon.svg">
        <p *ngIf="permisoParental == null">Subir plantilla de permiso parental en PDF</p>
        <p *ngIf="permisoParental != null && permisoParental.name != null"
          [class.invalid]="permisoParental .type!='application/pdf'">{{permisoParental.name}}</p>
        <p *ngIf="permisoParental != null && permisoParental.name == null">permisoParental.pdf</p>
      </div>
    </div>
  </div>
</div>