import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { UserService } from 'src/app/services/Api/user.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'app-cambiarTitularidadPopup',
  templateUrl: './cambiarTitularidadPopup.component.html',
  styleUrls: ['./cambiarTitularidadPopup.component.scss']
})
export class CambiarTitularidadPopupComponent extends UnsubscribesDestroy implements OnInit {

  // Old Data
  usuario: any = null;
  oldUsuario: any = null;
  newUser: any = null;

  users: any[] = [];
  userSelected: any = null;

  tickets: any[] = [];
  ticketSelected: any = null;

  phone: string = '';

  isActive: boolean = false;
  isUserChecked: boolean = false;

  // Debouncer
  debouncingTimer: any;

  isLoading: boolean = false;



  constructor(public dialogRef: MatDialogRef<CambiarTitularidadPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private userSE: UserService, private notificationSE: NotificacionesService) {
    super()
  }

  ngOnInit() {
    this.oldUsuario = this.modalData?.usuarios.find(u => u?.name == this.modalData?.entrada?.user);
    this.usuario = this.modalData?.usuarios.find(u => u?.name == this.modalData?.entrada?.user)?.id;
  }

  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
  getNewUser() {
    this.newUser = this.modalData.usuarios.find(u => u.id == this.usuario);
  }
  isComplete() {
    if (this.userSelected && this.phone && this.ticketSelected && this.isUserChecked) {
      this.isActive = true
    } else {
      this.isActive = false
    }
  }
  debounceCheckPhone() {
    clearTimeout(this.debouncingTimer);
    if (!this.phone) { this.clearSelectedUser() ; return }
    this.isLoading = true;
    this.debouncingTimer = setTimeout(() => {
      this.ckeckPhoneNumber();
    }, 500);
  }

  clearSelectedUser() {
    this.userSelected = null;
    this.newUser = null;
    this.isUserChecked = false;
    this.isLoading = false;
    this.isComplete();
  }

  /**
   * API CALLS
   */

  ckeckPhoneNumber() {
    this.userSE.checkUserByPhone(this.phone);
    this.userSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe((res: any) => {
      if (!res) { return }
      this.userSelected = res.id;
      this.newUser = res;
      this.isUserChecked = true;
      this.notificationSE.showFeedBack('Usuario con ese numero de teléfono encontrado');
      this.isComplete();
      this.isLoading = false;
      this._unsubInd2.next('')
    })
    this.userSE.getResultError().pipe(takeUntil(this._unsub)).subscribe((res: any) => {
      if (!res) { return }
      this.clearSelectedUser()
      this.isLoading = false;
      if (res.status != 401) {
        if (res.status == 404) {
          this.notificationSE.showError('No se han encontrado resultados');
          this.isUserChecked = false;
          this.isComplete();
        } else if (res.status == 500) {
          this.notificationSE.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          this.notificationSE.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }

}
