import { Injectable } from '@angular/core';
import { BaseService } from './Base.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RedsysLogService extends BaseService {
  private urlApi: string = environment.urlApi;

  constructor(private http: HttpClient) {
    super();
  }


  getAll(filters: any) {
    this.http.get(this.urlApi + '/api/redsys/log/table' + this.optionsGet(filters), this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getAll, args: [filters] })
        this.checkStatusError(error);
      },
    });
  }
}
