import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { CategoriesService } from 'src/app/services/Api/categories.service';
import { CommunitiesService } from 'src/app/services/Api/communities.service';
import { UserService } from 'src/app/services/Api/user.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'CreateEditCommunity-Step1',
  templateUrl: './CreateEditCommunity-Step1.component.html',
  styleUrls: ['./CreateEditCommunity-Step1.component.scss']
})
export class CreateEditCommunityStep1Component extends UnsubscribesDestroy implements OnInit {

  //VARIABLES FORMULARIO
  nombreComunidad: String = ""
  nombreComunidadError: Boolean = false;

  isPublic: Boolean = true;
  limiteEdadActivo: Boolean = false;

  edadMinima: String = "NO_RANGE"

  categoriesSelected: Array<string> = []
  categoriesSelectedError: Boolean = false;
  //VARIABLES DE CONTROL
  editar: Boolean = false;
  intereses: Array<String> = []
  dataLoaded: Boolean = false;
  edadesMinimas = [{ name: "Todos los públicos", value: "NO_RANGE" }, { name: "De 14 a 17", value: "RANGE_14_17" }, { name: "Más de 16", value: "RANGE_16" }, { name: "Más de 18", value: "RANGE_18" }, { name: "Más de 21", value: "RANGE_21" }, { name: "Más de 30", value: "RANGE_30" }, { name: "Más de 40", value: "RANGE_40" }, { name: "Más de 50", value: "RANGE_50" }];

  //ENVIO Y RECEPCION DE DATOS
  @Output() data = new EventEmitter<any>()
  @Output() validStep = new EventEmitter<Boolean>()
  @Output() dataToUpdate = new EventEmitter<any>()
  @Input() receiveData: any = null

  constructor(private notificaciones: NotificacionesService, private router: Router, public activatedRoute: ActivatedRoute, private loader: LoaderService, private userSE: UserService, private communititesSE: CommunitiesService, private categoriesSE: CategoriesService) {
    super();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.receiveData != null) {
      this.receiveData = changes['receiveData'].currentValue;
      this.setData();
    }
  }
  ngOnInit() {
    this.getCommunitiesCategories();
    if (this.activatedRoute.snapshot.params["id"] != null || this.activatedRoute.snapshot.params["id"] != undefined) {
      this.editar = true;
    }
  }

  //GETTERS & SETTERS
  getNombreComunidad(event) {
    this.nombreComunidad = event;
  }
  getVisibilidad(event) {
    this.isPublic = event;
  }
  getLimiteActivoEdad(event) {
    if (!event) {
      this.edadMinima = "NO_RANGE"
    }
    this.limiteEdadActivo = event;
  }
  getEdadMinima(event) {
    this.edadMinima = event;
  }
  getCategoriasSeleccionadas(event) {
    this.categoriesSelected = event;

  }

  setData() {
    this.nombreComunidad = this.receiveData.name;
    this.isPublic = this.receiveData.isPrivate;

    this.categoriesSelected = this.receiveData.categories;
    if (this.receiveData.ageRange == "NO_RANGE") {
      this.limiteEdadActivo = false;
    }else{
      this.limiteEdadActivo = true;
      this.edadMinima = this.receiveData.ageRange
    }

  }

  //VALIDACIONES
  validarNombreComunidad() {
    if (this.nombreComunidad == "") {
      this.nombreComunidadError = true;
    } else {
      this.nombreComunidadError = false;
    }
  }
  validarCategorias() {
    if (this.categoriesSelected.length == 0) {
      this.categoriesSelectedError = true;
    } else {
      this.categoriesSelectedError = false;
    }
  }

  validarPaso1() {
    this.validarNombreComunidad();
    this.validarCategorias();
    if (!this.nombreComunidadError && !this.categoriesSelectedError) {
      var step1Obj = {
        name: this.nombreComunidad,
        isPrivate: this.isPublic,
        ageRange: this.edadMinima,
        categories: this.categoriesSelected
      }
      this.data.emit(step1Obj)
      this.validStep.emit(true);
    } else {
      this.validStep.emit(false)
      this.notificaciones.showError("No pueden haber campos vacios")
    }
  }

  //LLAMADAS A LA API
  getCommunitiesCategories() {
    this.categoriesSE.getCategoriesFromCommunities();
    this.categoriesSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return };
      this.intereses = value
      this.dataLoaded = true;
      this._unsubInd.next("");
    })
    this.categoriesSE.getResultError().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return };
      this.notificaciones.showError(value.error.message)
      this._unsubInd.next("");
    })
  }

  updateNombreComunidad(event) {
    if (this.editar) { this.dataToUpdate.emit({ element: "name", value: event }) }
  }
  updateVisibilidad(event) {
    if (this.editar) { this.dataToUpdate.emit({ element: "isPrivate", value: event }) }
  }
  updateEdadMinima(event) {
    if (this.editar) { this.dataToUpdate.emit({ element: "ageRange", value: event }) }
  }
  updateCategoriasSeleccionadas(event) {
    if (this.editar) { this.dataToUpdate.emit({ element: "categories", value: event }) }
  }
}
