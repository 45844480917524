<PageTemplate>
  <ng-container bodyContent>
    <header [searcherActive]="false"></header>
    <div class="pageHeader">
      <h1>Base de datos de las entradas vendidas</h1>
      <searcher [placeHolder]="'Busca aquí un usuario'" (emitChange)="getFilter($event)"></searcher>
    </div>
    <div class="filtersContainer">
      <div class="containerFilters">
        <label for="">Evento</label>
        <ng-select [items]="eventos" [(ngModel)]="evento" [searchable]="true" [clearable]="true" bindLabel="title"
          bindValue="id" [placeholder]="'Selecciona un evento'" (change)="getEvento()"></ng-select>
      </div>
      <div class="containerFilters">
        <label for="">Usuarios</label>
        <ng-select [items]="usuarios" (change)="getUsuario()" [(ngModel)]="usuario" [searchable]="true"
          [clearable]="true" bindLabel="name" bindValue="id" [placeholder]="'Selecciona un usuario'">
          <ng-template ng-label-tmp let-item="item">
            <div class="columnSelector">
              <span><b>{{ item.name }}</b></span>
            </div>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
            <div class="columnSelector">
              <span><b>{{ item.name }}</b></span>
              <span>{{ item.email }}</span>
              <span>{{ item.phone }}</span>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="containerFilters">
        <label for="">Compra anticipada</label>
        <ng-select [items]="compraAnticipadas" [(ngModel)]="compraAnticipada" [searchable]="true" [clearable]="true"
          bindLabel="name" bindValue="value" [placeholder]="'Selecciona un valor'" (change)="getCompra()"></ng-select>
      </div>
      <div class="containerFilters">
        <label for="">Fecha de compra</label>
        <div>
          <input matInput [matDatepicker]="picker" readOnly="true" [(ngModel)]="fechaCompra"
            (dateChange)="changeDate($event)" placeholder="Fecha de compra" class="inputData datePicker">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker class="miPerfil-movil" #picker></mat-datepicker>
        </div>
      </div>
      <div class="containerFilters" [ngStyle]="{'align-items': 'flex-end'}">
        <label for="" [ngStyle]="{'visibility':'hidden'}">Fecha de compra</label>

        <button class="btn-sm" (click)="clearFilters()">Limpiar filtros</button>
      </div>
    </div>
    <mat-spinner mat-spinner *ngIf="!dataIsLoaded"></mat-spinner>
    <div class="pageBody" *ngIf="dataIsLoaded">
      <p class="noData" *ngIf="entradas.length == 0">
        No hay entradas que mostrar
      </p>
      <div class="tableContainer" *ngIf="entradas.length > 0">
        <table>
          <thead>
            <tr>
              <th>Entrada</th>
              <th class="center">Evento</th>
              <th class="center">Comprador</th>
              <th class="center">Titular</th>
              <th class="center">Fecha de compra</th>
              <th class="center">Compra anticipada</th>
              <th class="center">Estado</th>
              <th class="center">Fecha de devolución</th>
              <th class="center"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let entrada of entradas" (click)="$event.stopPropagation();">
              <td>{{entrada.productName}}</td>
              <td class="center">{{entrada.eventTitle}}</td>
              <td class="center">{{entrada.comprador ?? entrada.user}}</td>
              <td class="center">{{entrada.user}}</td>
              <td class="center">{{entrada.purchase_date | date: 'dd MMMM YYYY'}}</td>
              <td class="center">{{!entrada.is_preorder ? 'No' : 'Sí'}}</td>
              <td class="center">{{entrada.refound ? "Devuelta" : "Pagada"}}</td>
              <td class="center">{{!entrada.refoundDate ? "-" : entrada.refoundDate | date: 'dd MMMM YYYY'}}</td>
              <td class="center">
                <!-- <img (click)="$event.stopPropagation();devolverEntrada(entrada)" title="Devolver el dinero"
                  src="/assets/icons/refound.png" alt=""> -->
                <img (click)="$event.stopPropagation();openCambioEntrada(entrada)"
                  src="/assets/icons/cambioTitularidad.svg" alt="" title="Cambio de titularidad" *ngIf="entrada.app">
                <mat-icon (click)="$event.stopPropagation();openReenviarEmail(entrada)" *ngIf="!entrada.app">mail</mat-icon>                  
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="paginacion" *ngIf="dataIsLoaded && entradas.length > 0">
      <span (click)="paginaAnterior()" [class.disabled]="filters.num_pagina == 1">
        << Página anterior</span>
          <span [ngStyle]="{'text-decoration': 'none'}">{{filters.num_pagina}}</span>
          <span (click)="siguientePagina()" [class.disabled]="entradas.length < 5">Siguiente página >></span>
    </div>
  </ng-container>
</PageTemplate>