import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, catchError } from 'rxjs';
import { Facility } from 'src/app/models/Facility';
import { environment } from 'src/environments/environment';
import { BaseService } from './Base.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root'
})
export class SocialMediaService extends BaseService {

  private urlApi = environment.urlApi;

  constructor(private http: HttpClient) {
    super();
  }

  updateSocialMediaById(id:any, socialMedia: any){
    this.http.put(this.urlApi + '/api/social/media/' + id,socialMedia,this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultUpdate', data)
      },
      error: (error) => {
        this.sendNextResult('resultUpdateError', error, { method: this.updateSocialMediaById, args: [id, socialMedia] })
        this.checkStatusError(error);
      },
    });
  }

}
