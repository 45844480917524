import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { ReenviarMailPopupComponent } from 'src/app/popups/ReenviarMail-Popup/ReenviarMail-Popup.component';
import { CambiarTitularidadPopupComponent } from 'src/app/popups/cambiarTitularidadPopup/cambiarTitularidadPopup.component';
import { ConfirmPopupComponent } from 'src/app/popups/confirmPopup/confirmPopup.component';
import { EventService } from 'src/app/services/Api/event.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { TicketsService } from 'src/app/services/Api/tickets.service';
import { UserService } from 'src/app/services/Api/user.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PopupService } from 'src/app/services/utils/popup.service';

@Component({
  selector: 'app-GestionEntradas',
  templateUrl: './GestionEntradas.component.html',
  styleUrls: ['./GestionEntradas.component.scss']
})
export class GestionEntradasComponent extends UnsubscribesDestroy implements OnInit {

  numDevoluciones: number = 6;
  numPagina: number = 1;
  usuarios: Array<any> = []
  genericFilter: string = "";
  dataIsLoaded: boolean = false;
  filters: any = {
    num_devoluciones: 5,
    num_pagina: 1,
  }
  entradas: Array<any> = [];
  eventos: Array<any> = [];
  evento: any = null;
  usuario: any = null;
  compraAnticipadas: Array<any> = [
    {
      value: true,
      name: "Si"
    },
    {
      value: false,
      name: "No"
    }
  ];
  compraAnticipada: any = null;
  fechaCompra: any = null;
  constructor(private notificaciones: NotificacionesService, private loginSE: LoginService, private userSE: UserService, private popupSE: PopupService, private ticketSE: TicketsService, private eventSE: EventService) {
    super();
  }

  ngOnInit() {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      this.checkPermisos();
    }
  }

  /**
   * GETTERS & SETTERS
   */
  getFilter(event) {
    this.filters.genericFilter = event;
    this.filters.num_devoluciones = 5;
    this.filters.num_pagina = 1;
    this.dataIsLoaded = false;
    this.getAll();
  }
  getEvento() {
    this.filters.num_devoluciones = 5;
    this.filters.num_pagina = 1;
    if (this.evento == null) {
      delete this.filters.event;
    } else {
      this.filters.event = this.evento;
    }
    this.dataIsLoaded = false;
    this.getAll();
  }
  getUsuario() {
    this.filters.num_devoluciones = 5;
    this.filters.num_pagina = 1;
    if (this.usuario == null) {
      delete this.filters.user;
    } else {
      this.filters.user = this.usuario;
    }
    this.dataIsLoaded = false;
    this.getAll();
  }
  getCompra() {
    this.filters.num_devoluciones = 5;
    this.filters.num_pagina = 1;
    if (this.compraAnticipada == null) {
      delete this.filters.preorder;
    } else {
      this.filters.preorder = this.compraAnticipada;
    }
    this.dataIsLoaded = false;
    this.getAll();
  }

  
  // Reenviar mail de las entradas compradas por ticket
  openReenviarEmail(entrada) {
    this.popupSE.openPopup(ReenviarMailPopupComponent, { entrada: entrada });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd5)).subscribe((res: any) => {
      if((!res))return
      let bodyToPass = res['returnValue'];
      this.sendEmail(bodyToPass);
      this._unsubInd5.next('');
    });
  }


  changeDate(event) {
    this.filters.num_devoluciones = 5;
    this.filters.num_pagina = 1;
    if (event == null) {
      delete this.filters.purchaseDate;
    }
    else {

      this.filters.purchaseDate = new Date(event.value).getFullYear() + "-" + (new Date(event.value).getMonth() + 1) + "-" + new Date(event.value).getDate();
    }
    this.dataIsLoaded = false;
    this.getAll();
  }
  clearFilters() {
    delete this.filters.event;
    delete this.filters.user;
    delete this.filters.preorder;
    delete this.filters.purchaseDate;
    this.evento = null;
    this.usuario = null;
    this.compraAnticipada = null;
    this.fechaCompra = null
    this.dataIsLoaded = false;
    this.getAll();

  }
  /**
   * FUNCTIONALITIES
   */
  paginaAnterior() {
    if (this.filters.num_pagina > 1) {
      this.filters.num_pagina--;
      this.dataIsLoaded = false;
      this.getAll();
    }
  }
  siguientePagina() {
    this.filters.num_pagina++;
    this.dataIsLoaded = false;
    this.getAll();
  }
  // devolverEntrada(entrada) {
  //   this.popupSE.openPopup(ConfirmPopupComponent, { accion: "Devolver", tipo: "el dinero de la entrada" })
  //   this.popupSE.returnData().pipe(takeUntil(this._unsubInd6)).subscribe(value => {
  //     if (value == null) { return }
  //     this.refoundTicket(entrada.soldId, entrada.is_preorder);
  //     this._unsubInd6.next("");
  //   })
  // }
  /**
     * CALLS TO API
     */
  getAll() {
    this.ticketSE.getAllTicketsSA(this.filters);
    this.ticketSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (res == null) { return }
      this.entradas = [...res];
      this.dataIsLoaded = true;
      this._unsubInd.next('')
    })
    this.userSE.getResultError().pipe(takeUntil(this._unsub)).subscribe((res: any) => {
      if (res == null) { return }
      this.notificaciones.showError(res.error.message)
      this._unsub.next('')
    })
  }
  getAllEvent() {
    this.eventSE.getEventSelect()
    this.eventSE.getResultEvent().pipe(takeUntil(this._unsubInd2)).subscribe((res: any) => {
      if (res == null) { return }
      this.eventos = [...res];
      this._unsubInd2.next('')
    })
    this.eventSE.getResultError().pipe(takeUntil(this._unsub)).subscribe((res: any) => {
      if (res == null) { return }
      this.notificaciones.showError(res.error.message)
      this._unsub.next('')
    })
  }
  getAllUser() {
    this.userSE.getAllSelect()
    this.userSE.getResultUser().pipe(takeUntil(this._unsubInd3)).subscribe((res: any) => {
      if (res == null) { return }
      this.usuarios = [...res];
      this._unsubInd3.next('')
    })
    this.userSE.getResultError().pipe(takeUntil(this._unsub)).subscribe((res: any) => {
      if (res == null) { return }
      this.notificaciones.showError(res.error.message)
      this._unsub.next('')
    })
  }
  cambiarTitularidad(id, user) {
    this.ticketSE.cambioTitularidad(id, user);
    this.ticketSE.getResultUpdate().pipe(takeUntil(this._unsubInd5)).subscribe((res: any) => {
      if (res == null) { return }
      this.notificaciones.showFeedBack('Se ha cambiado la titularidad correctamente')
      this.getAll();
      this._unsubInd5.next('')
    })
    this.ticketSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res: any) => {
      if (res == null) { return }
      this.notificaciones.showError(res.error.message)
      this._unsub.next('')
    })
  }
  openCambioEntrada(entrada) {
    this.popupSE.openPopup(CambiarTitularidadPopupComponent, { entrada: entrada, usuarios: this.usuarios })
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd4)).subscribe((res: any) => {
      if (res == null) { return }
      this.cambiarTitularidad(entrada.soldId, res.returnValue.user);
      this._unsubInd4.next('')
    })
  }

  // refoundTicket(id, preorder) {
  //   let preorderRes = false;
  //   if(preorder == 1){
  //     preorderRes = true;
  //   }else{
  //     preorderRes = false;
  //   }
  //   this.ticketSE.refoundTicket(id, preorderRes);
  //   this.ticketSE.getResultUpdate().pipe(takeUntil(this._unsubInd5)).subscribe((res: any) => {
  //     if (res == null) { return }
  //     this.notificaciones.showFeedBack('Se ha devuelto la entrada correctamente')
  //     this.getAll();
  //     this._unsubInd5.next('')
  //   })
  //   this.ticketSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res: any) => {
  //     if (res == null) { return }
  //     this.notificaciones.showError(res.error.message)
  //     this._unsub.next('')
  //   })
  // }

  sendEmail(body:{name:string,mail:string,id:number}){
    this.ticketSE.resendEmailTicketsBoughtInWeb(body);
    this.ticketSE.getResultUpdate().pipe(takeUntil(this._unsubInd4)).subscribe((res)=>{
      if(!res){return}
      this.notificaciones.showFeedBack('Se ha enviado el email correctamente');
      this._unsubInd4.next('')
    })
    this.ticketSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res)=>{
      if(!res){return}
      if(res.status != 401){
        if (res.status == 404) {
          this.notificaciones.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          this.notificaciones.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          this.notificaciones.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }
  /**
  * PERMISSIONS
  */
  checkPermisos() {
    if (localStorage.getItem("tipoUser") == null || localStorage.getItem("tipoUser") == undefined || localStorage.getItem("tipoUser") != '["3"]') {
      this.loginSE.logOut();
      this.notificaciones.showError("No tienes permisos para acceder")
    } else {
      if (localStorage.getItem("tipoUser") == '["3"]') {
        this.getAll();
        this.getAllEvent();
        this.getAllUser();
      }
    }

  }
}
