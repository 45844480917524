<div class="stepGeneralContainer">
  <form action="">
    <FormElement [receiveData]="nombreComunidad" [error]="nombreComunidadError" [type]="'input'" [placeholder]="'Nombre de la comunidad'" [label]="'¿Cómo se va a llamar tu comunidad?'" (sendData)="getNombreComunidad($event)" (updateData)="updateNombreComunidad($event)"></FormElement>
    <FormElement [receiveData]="isPublic"[type]="'radio'" [radioOptions]="{trueOption: 'Privada', falseOption: 'Pública'}" [label]="'¿Deseas que sea privada o pública?'" (sendData)="getVisibilidad($event)" (updateData)="updateVisibilidad($event)"></FormElement>
    <FormElement [receiveData]="limiteEdadActivo"  [type]="'radio'" [radioOptions]="{trueOption: 'Sí, ¿cuál?', falseOption: 'No'}" [label]="'¿Deseas restringir el acceso a la comunidad a un rango de edad?'" (sendData)="getLimiteActivoEdad($event)"></FormElement>
    <FormElement *ngIf="limiteEdadActivo" [receiveData]="edadMinima"  (updateData)="updateEdadMinima($event)" [type]="'selector'"  [label]="'Edad mínima'" [options]="edadesMinimas" [placeholder]="'Selecciona una edad mínima'" [selectorType]="'edadesMinimas'" (sendData)="getEdadMinima($event)"></FormElement>
    <FormElement [loaded]="dataLoaded" [receiveData]="categoriesSelected" [error]="categoriesSelectedError" [type]="'pills'"  [label]="'¿Cuáles son los intereses de la comunidad?'" [pills]="intereses" (sendData)="getCategoriasSeleccionadas($event)" (updateData)="updateCategoriasSeleccionadas($event)"></FormElement>

  </form>
</div>
