import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, catchError, BehaviorSubject } from 'rxjs';
import { Facility } from 'src/app/models/Facility';
import { environment } from 'src/environments/environment';
import { BaseService } from './Base.service';


@Injectable({
  providedIn: 'root'
})
export class CRMService extends BaseService{

  private urlApi = environment.urlApi;

  constructor(private http: HttpClient) {
    super();
  }

  getHeaderCRMCompany(company){
    this.http.get(this.urlApi + '/api/company/crm/'+company, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getHeaderCRMCompany, args: [] })
        this.checkStatusError(error);
      },
    });
  }
  getHeaderCRMGroup(){
    this.http.get(this.urlApi + '/api/group/crm/'+Number(sessionStorage.getItem("groupId")), this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getHeaderCRMGroup, args: [] })
        this.checkStatusError(error);
      },
    });
  }
  getCompanyGroupDiagram(){
    this.http.get(this.urlApi + '/api/event/statistics/company/diagram/group/' + sessionStorage.getItem("groupId") , this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getCompanyGroupDiagram, args: [] })
        this.checkStatusError(error);
      },
    });
  }
  getCompanyDiagram(id:any =  null, year: any = new Date().getFullYear()){
    if (id == null) {
      id = sessionStorage.getItem("dcmpny")
    }
    this.http.get(this.urlApi + '/api/event/statistics/company/diagram/' + id +"/"+year, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getCompanyDiagram, args: [id, year] })
        this.checkStatusError(error);
      },
    });
  }
  getCenters(){
    this.http.get(this.urlApi + '/api/center/crm?num_devoluciones=5000&num_pagina=1', this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getCenters, args: [] })
        this.checkStatusError(error);
      },
    });
  }
  getCentersGrados(centerId){
    this.http.get(this.urlApi + '/api/center/crm/'+centerId+'?num_devoluciones=5000&num_pagina=1', this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getCentersGrados, args: [centerId] })
        this.checkStatusError(error);
      },
    });
  }
  getCentersBuyEvents(eventId){
    this.http.get(this.urlApi + '/api/center/crm/event/'+eventId, this.getHeader()).subscribe({
      next: (data) => {
        this.customSendNextResult('resultCenters', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getCentersBuyEvents, args: [eventId] })
        this.checkStatusError(error);
      },
    });
  }
  getGradosBuyEvents(eventId, centerId){
    this.http.get(this.urlApi + '/api/center/crm/'+centerId+'/event/'+eventId, this.getHeader()).subscribe({
      next: (data) => {
        this.customSendNextResult('resultGrados', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getCentersBuyEvents, args: [eventId] })
        this.checkStatusError(error);
      },
    });
  }

  customSendNextResult(type: 'resultCenters' | 'resultGrados', data: any) {
    let clearFuntion: Function;
    if (type == 'resultCenters') {
      this._resultCenters.next(data);
      clearFuntion = this.clearResultCenters;
    }
    if (type == 'resultGrados') {
      this._resultGrados.next(data);
      clearFuntion = this.clearResultGrados;
    }

    setTimeout(() => {
      this[clearFuntion.name]();
    }, 150);
  }
  _resultCenters= new BehaviorSubject<any>(null);
  getResultCenters() { return this._resultCenters; }
  clearResultCenters() { this._resultCenters.next(null); }

  _resultGrados= new BehaviorSubject<any>(null);
  getResultGrados() { return this._resultGrados; }
  clearResultGrados() { this._resultGrados.next(null); }
}
