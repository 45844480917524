<div class="showInfoContainer">
  <div class="header">
    <svg (click)="forceClosePopup({ stopFlag: false, id:  this.modalData.qrInfo.soldTicketId  })" xmlns="http://www.w3.org/2000/svg" width="20.242" height="20.242"
      viewBox="0 0 20.242 20.242">
      <g id="Grupo_1201" data-name="Grupo 1201" transform="translate(-5674.379 -12806.379)">
        <line id="Línea_323" data-name="Línea 323" x2="16" y2="16" transform="translate(5676.5 12808.5)" fill="none"
          stroke="#0e17b6" stroke-linecap="round" stroke-width="3" />
        <line id="Línea_324" data-name="Línea 324" x1="16" y2="16" transform="translate(5676.5 12808.5)" fill="none"
          stroke="#0e17b6" stroke-linecap="round" stroke-width="3" />
      </g>
    </svg>
  </div>
  <div class="body" *ngIf="dataIsLoaded">
    <div class="iconContainer">
      <img *ngIf="data" src="/assets/icons/correct.svg" alt="">
      <p *ngIf="data">Entrada verificada</p>
      <img *ngIf="!data" src="/assets/icons/incorrect.svg" alt="">
      <p *ngIf="!data">Entrada no válida</p>
    </div>
    <ng-container *ngIf="!eventoValido">
      <div class="textContainer">
        <p class="noValido">Este ticket no pertenece a este evento</p>
      </div>
    </ng-container>
    <ng-container *ngIf="eventoValido">
      <div class="textContainer">
        <p>{{modalData.qrInfo.name}}</p>
        <p>{{modalData.qrInfo.dni}}</p>
      </div>
      <div class="ticketContainer">
        <div class="imagen">
          <img [src]="modalData.qrInfo.imagen" alt="">
        </div>
        <div class="datos">
          <p>{{modalData.qrInfo.producto}}</p>
          <p>{{modalData.qrInfo.start_date | date: 'd' }} {{ modalData.qrInfo.start_date | date: 'MMM' | uppercase }} - {{modalData.qrInfo.finish_date | date: 'd' }} {{ modalData.qrInfo.finish_date | date: 'MMM' | uppercase }} </p>
        </div>
      </div>
    </ng-container>
    <div class="footer">
      <button *ngIf="data" type="button" (click)="forceClosePopup({ stopFlag: false, id:  this.modalData.qrInfo.soldTicketId  })">De acuerdo</button>
      <button *ngIf="!data" type="button" (click)="forceClosePopup({ stopFlag: false, id:  this.modalData.qrInfo.soldTicketId })">Volver</button>
    </div>
  </div>
</div>
