import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { CommunitiesService } from 'src/app/services/Api/communities.service';
import { EventService } from 'src/app/services/Api/event.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { PrService } from 'src/app/services/Api/pr.service';
import { StadisticsService } from 'src/app/services/Api/stadistics.service';
import { UserService } from 'src/app/services/Api/user.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent extends UnsubscribesDestroy implements OnInit {
  urlImages = environment.urlImages
  fechaActual = new Date().toLocaleDateString();
  misDatos = {}
  eventosHoy: any = []
  eventosFuturos: any = []
  misComunidaddes: any = []
  estadisticas: any = {}
  constructor(private router: Router, private notificaciones: NotificacionesService, private loginSE: LoginService, private userSE: UserService, private loader: LoaderService, private eventSE: EventService, private communitiesSE: CommunitiesService, private prSE: PrService, private estadisticasSE: StadisticsService) {
    super();
  }

  ngOnInit() {
    if (!this.loginSE.isLogin()) {
      this.router.navigate(["/login"])
      this.loginSE.logOut(true);
    } else {
      this.getMyData();
      setTimeout(() => {
        this.loader.open()
        this.getEventsByDate();
        this.getMyComunnitites();

      }, 500);
    setTimeout(() => {
      this.getStadistics();
      this.getFutureEvents();

    }, 2000);
    }

  }
  getStadistics() {
    this.estadisticasSE.getRRPPStadistics(sessionStorage.getItem("prId"));
    this.estadisticasSE.getResultIndividual().pipe(takeUntil(this._unsubInd4)).subscribe(value => {
      if (value == null) { return }
      this.estadisticas = value;
      this.loader.closeSlow();
      this._unsubInd4.next("");
    })
  }
  getMyData() {
    this.userSE.getMyUser();

    this.userSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return }
      sessionStorage.setItem("prCode", value.prCode)
      sessionStorage.setItem("nmsr", value.id)
      sessionStorage.setItem("dcmpny", value.companyId)
      sessionStorage.setItem("prId", value.prId)
      this._unsubInd.next("");
    })
  }
  getEventsByDate() {
    var today = new Date();
    var day = String(today.getDate()).padStart(2, '0');
    var month = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var year = today.getFullYear();
    this.eventSE.getEventByDate(year + "-" + month + "-" + day);
    this.eventSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe(value => {
      if (value == null) { return };
      this.eventosHoy = value;
      this._unsubInd2.next("");
    })
    this.eventSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return };
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    })
  }
  getFutureEvents() {
    var today = new Date();
    var day = String(today.getDate()).padStart(2, '0');
    var month = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var year = today.getFullYear();
    this.eventSE.getEvents(5000,1,(year + "-" + month + "-" + day));
    this.eventSE.getResult().pipe(takeUntil(this._unsubInd5)).subscribe(value => {
      if (value == null) { return };
      this.eventosFuturos = value;
      this._unsubInd5.next("");
    })
  }
  getMyComunnitites() {
    this.communitiesSE.getCommunitiesByUser();
    this.communitiesSE.getResult().pipe(takeUntil(this._unsubInd3)).subscribe(value => {
      if (value == null) { return };
      this.misComunidaddes = value;
      this._unsubInd3.next("");
    })
  }
  saveEvent(evento: any) {
    sessionStorage.setItem("lastRoute", this.router.url);
    this.router.navigate(["/rrpp/ver-evento/" + evento.id])
  }
}
