
<div class="loginGeneralContainer">
  <img src="/assets/icons/logoLogin.png" alt="Logo login memorable">
  <div class="loginContainer">
    <form action="" >
        <input [class.error]="emailVacio || !emailValido" [(ngModel)]="email" type="email" name="email" placeholder="Correo electrónico">
        <div class="especial" *ngIf="!visible && !changePassOpen">
          <mat-icon  (click)="visible = true">visibility</mat-icon>
          <input [class.error]="passwordVacio" [(ngModel)]="password" name="password" type="password" placeholder="Contraseña">
        </div>
        <div class="especial" *ngIf="visible && !changePassOpen">
          <mat-icon  (click)="visible = false">visibility_off</mat-icon>
          <input  [class.error]="passwordVacio" [(ngModel)]="password" name="password" type="text" placeholder="Contraseña">
        </div>
        <button *ngIf="!changePassOpen"(click)="sendLogin()" type="submit">Iniciar sesión</button>
        <button *ngIf="changePassOpen"(click)="sendMail()" type="submit">Envíar correo</button>
        <a *ngIf="!changePassOpen" (click)="changePass()">¿Has olvidado tu contraseña?</a>
        <a *ngIf="false"[routerLink]="['/nuevo-negocio']" >Registra tu negocio en Memorable</a>
        <a *ngIf="changePassOpen"(click)="changePass()">¿Ya la recuerdas?</a>
    </form>
  </div>
</div>
