import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { CommunitiesService } from 'src/app/services/Api/communities.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-mis-comunidades',
  templateUrl: './mis-comunidades.component.html',
  styleUrls: ['./mis-comunidades.component.scss']
})
export class MisComunidadesComponent extends UnsubscribesDestroy implements OnInit {
  urlImages = environment.urlImages
  previousUrl: string | null = sessionStorage.getItem("previousURL");
  misComunidaddes:any = []
  constructor(private router: Router, private notificaciones: NotificacionesService, private loginSE: LoginService, private loaderSE: LoaderService,  private communitiesSE:CommunitiesService) {
    super()
  }

  ngOnInit() {
    if (!this.loginSE.isLogin()) {
      this.router.navigate(["/login"])
      this.loginSE.logOut(true);
    }else{
      this.getMyComunnitites();
    }
  }
  getMyComunnitites(){
    this.communitiesSE.getCommunitiesByUser();
    this.loaderSE.open();
    this.communitiesSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return };
      this.loaderSE.closeSlow();
      this.misComunidaddes = value;
      this._unsubInd.next("");
    })
    this.communitiesSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return };
      this.loaderSE.closeSlow();
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    })
  }
}
