import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/Api/login.service';

@Component({
  selector: 'app-promotions-page',
  templateUrl: './promotions-page.component.html',
  styleUrls: ['./promotions-page.component.scss']
})
export class PromotionsPageComponent implements OnInit {

  constructor(private loginSE: LoginService) { }
  permiso = true;
  ngOnInit(): void {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    }else{
      if (!localStorage.getItem("permisos")?.split(",").includes("7")|| localStorage.getItem("permisos") == null) {
      this.permiso = false
      }
    }
  }

}
