import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { ConfirmPopupComponent } from 'src/app/popups/confirmPopup/confirmPopup.component';
import { CommunitiesService } from 'src/app/services/Api/communities.service';
import { CompanyService } from 'src/app/services/Api/company.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PopupService } from 'src/app/services/utils/popup.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'Authorization',
  templateUrl: './CommunityDetails-Authorization.component.html',
  styleUrls: ['./CommunityDetails-Authorization.component.scss']
})
export class CommunityDetailsAuthorizationComponent extends UnsubscribesDestroy implements OnInit {

  empleados: any = [];
  empleados_backUp: any = [];
  cambio = false;
  urlImages = environment.urlImages
  isDataLoaded: Boolean = false;
  constructor(private popupSE: PopupService, private router: Router, public activatedRoute: ActivatedRoute, private notificaciones: NotificacionesService, private loginSE: LoginService, private communititesSE: CommunitiesService, private loader: LoaderService, private companySE: CompanyService) {
    super();
  }

  ngOnInit() {
    this.checkPermisos();
  }

  //FUNCIONALIDADES
  filteredUsers(event) {
    this.empleados = event;
  }
  //LLAMADAS A LA API
  getEmployees() {
    this.companySE.getEmployeesOfCompany(sessionStorage.getItem("dcmpny"), this.activatedRoute.snapshot.params["id"]);
    this.companySE.getResult().pipe(takeUntil(this._unsubInd6)).subscribe(value => {
      if (value == null) { return };
      this.empleados = value
      this.empleados_backUp = value;
      // this.getValuesOnEdit(value)
      this.isDataLoaded = true;
      this._unsubInd6.next("");
    })
    this.companySE.getResultError().pipe(takeUntil(this._unsubInd6)).subscribe(value => {
      if (value == null) { return };
      this.loader.closeSlow();
      this.notificaciones.showError(value.error.message)
      this._unsubInd6.next("");
    })
  }
  authorizeAdmin(isAdmin, empleado) {
    this.getEmployees()
    if (isAdmin == 1) {
      this.popupSE.openPopup(ConfirmPopupComponent, { accion: "Desautorizar", tipo: "administrador", genero: "masculino" })
      this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe(value => {
        if (value == null) { return }
        if (value.returnValue.accionHecha == true) {
          this.communititesSE.updateAdmin({ user: empleado.id, community: Number(this.activatedRoute.snapshot.params["id"]), admin: false })
          this.communititesSE.getResultDelete().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
            if (res == null) { return }
            this.notificaciones.showFeedBack(res.message)
            this.getEmployees();
            this.cambio = true;
            this._unsubInd2.next("")
          })
          this.communititesSE.getResultDeleteError().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
            if (res == null) { return }
            this.notificaciones.showError(res.error.message)
            this._unsubInd2.next("")
          })

        }
        this._unsubInd.next("");
        this.popupSE.clearData();
      })
    }
    if (isAdmin == 0) {
      this.popupSE.openPopup(ConfirmPopupComponent, { accion: "Autorizar", tipo: "administrador", genero: "masculino" })
      this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe(value => {
        if (value == null) { return }
        if (value.returnValue.accionHecha == true) {
          this.communititesSE.updateAdmin({ user: empleado.id, community: Number(this.activatedRoute.snapshot.params["id"]), admin: true })
          this.communititesSE.getResultDelete().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
            if (res == null) { return }
            this.notificaciones.showFeedBack(res.message)
            this.getEmployees();
            this.cambio = true;
            this._unsubInd2.next("")
          })
          this.communititesSE.getResultDeleteError().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
            if (res == null) { return }
            this.notificaciones.showError(res.error.message)
            this._unsubInd2.next("")
          })
        }
        this._unsubInd.next("");
        this.popupSE.clearData();
      })
    }
  }
  permiso: Boolean = false;
  checkPermisos() {
    if (localStorage.getItem("tipoUser") == null || localStorage.getItem("tipoUser") == undefined) {
      this.loginSE.logOut();
      this.notificaciones.showError("No tienes permisos para acceder")
    } else {
      if (localStorage.getItem("tipoUser") == '["0"]' || localStorage.getItem("tipoUser") == '["3"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY") && !localStorage.getItem("permisos")?.includes("CONSULT_COMPANY_GROUP")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else {
          if (localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY") && localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY_GROUP")) {
            this.permiso = true;
          }
          this.getEmployees();

        }
      }
      if (localStorage.getItem("tipoUser") == '["1"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else {
          if (localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY")) {
            this.permiso = true;
          }
          this.getEmployees();

        }
      }
    }


  }
}
