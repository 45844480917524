import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-pr-revenue-weekly-chart',
  templateUrl: './pr-revenue-weekly-chart.component.html',
  styleUrls: ['./pr-revenue-weekly-chart.component.scss'],
})
export class PrRevenueWeeklyChartComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  @Input() data: any = {}
  public lineChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [650, 590, 800, 810, 560, 550, 400],
        label: 'Tickets',
        backgroundColor: 'rgba(110, 116, 211, 0.2)',
        borderColor: 'rgba(110, 116, 211, 1)',
        borderWidth: 2,
        pointBackgroundColor: 'rgba(110, 116, 211 ,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        fill: 'origin',
      },
    ],
    labels: ['1', '2', '3', '4', '5', '6', '7'],
  };

  public lineChartOptions: ChartConfiguration['options'] = {
    responsive: true,

    // Generic options
    font: { family: 'Rubik' },

    // Specific options
    layout: {
      padding: 40,
    },
    elements: {
      line: {
        tension: 0.5,
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#0e17b6',
          font: {
            weight: '700',
          },
        },
        grid: {
          lineWidth: 0,
        },
      },
      y: {
        grid: {
          color: '#dfe0f5',
          drawBorder: false,
        },
        ticks: {
          callback: (value) => `${value} €`,
          color: '#0e17b6',
          font: {},
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
        align: 'end',
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        yAlign: 'bottom',
        enabled: true,
        backgroundColor: '#0e17b6',
        padding: 14,
        displayColors: false,
        callbacks: {
          title: () => ``,
          label: function (context) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat('es-ES', {
                style: 'currency',
                currency: 'EUR',
              }).format(context.parsed.y);
            }
            return label;
          },
        },
      },
    },
  };

  public lineChartType: ChartType = 'line';

  constructor() {}

  ngOnInit(): void {
    this.data.forEach(element => {
      switch (element.purchase_date.split("T")[0].split("-")[2]) {
        case "01":
          this.lineChartData.datasets[0].data[0] = element.recaudacion_ticket
          break;
        case "02":
          this.lineChartData.datasets[0].data[1] = element.recaudacion_ticket
          break;
        case "03":
          this.lineChartData.datasets[0].data[2] = element.recaudacion_ticket
          break;
        case "04":
          this.lineChartData.datasets[0].data[3] = element.recaudacion_ticket
          break;
        case "05":
          this.lineChartData.datasets[0].data[4] = element.recaudacion_ticket
          break;
        case "06":
          this.lineChartData.datasets[0].data[5] = element.recaudacion_ticket
          break;
        case "07":
          this.lineChartData.datasets[0].data[6] = element.recaudacion_ticket
          break;
      }
    });
  }
}
