<div class="managementCardInforGeneralCotnainer" [class.noPermision]="!permiso">
  <label for="">{{label}}</label>
  <div class="managementCardInfo">
    <div>
      <span class="title">{{title}}</span>
      <span class="description">{{description}}</span>
    </div>
    <div class="linkContainer" *ngIf="idCompany == null">
      <a [routerLink]="[link]">Acceder ></a>
      <img [src]="icon" alt="">
    </div>
    <div class="linkContainer" *ngIf="idCompany != null">
      <a (click)="setCompanyId()" [routerLink]="[link]">Acceder ></a>
      <img [src]="icon" alt="" *ngIf="!deleteButton">
      <img *ngIf="deleteButton" src="/assets/icons/trash.svg" alt="" style="cursor: pointer;" (click)="emitDelete()">
    </div>
  </div>
</div>