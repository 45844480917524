import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, catchError } from 'rxjs';
import { Facility } from 'src/app/models/Facility';
import { environment } from 'src/environments/environment';
import { BaseService } from './Base.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService extends BaseService {

  private urlApi = environment.urlApi;

  constructor(private http: HttpClient) {
    super();
  }
  getCategoriesFromEvents(){
    this.http.get(this.urlApi + '/api/event/category', this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getCategoriesFromEvents, args: [] })
        this.checkStatusError(error);
      },
    });
  }
  getCategoriesFromFacilitys(){
    this.http.get(this.urlApi + '/api/facility/categories/', this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getCategoriesFromFacilitys, args: [] })
        this.checkStatusError(error);
      },
    });
  }
  getCategoriesFromCommunities(){
    this.http.get(this.urlApi + '/api/community/categories/', this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getCategoriesFromCommunities, args: [] })
        this.checkStatusError(error);
      },
    });
  }
  getCategoriesFromCompany(){
    this.http.get(this.urlApi + '/company/categories').subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getCategoriesFromCompany, args: [] })
        this.checkStatusError(error);
      },
    });
  }
}
