import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { sha256 } from 'js-sha256';
import { take, takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { ChangePasswordPopupComponent } from 'src/app/popups/changePasswordPopup/changePasswordPopup.component';
import { LoginService } from 'src/app/services/Api/login.service';
import { UserService } from 'src/app/services/Api/user.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PopupService } from 'src/app/services/utils/popup.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mi-perfil',
  templateUrl: './mi-perfil.component.html',
  styleUrls: ['./mi-perfil.component.scss']
})
export class MiPerfilComponent extends UnsubscribesDestroy implements OnInit {
  urlImages = environment.urlImages
  nombre = ""
  apellidos = ""
  telefono = ""
  mailContacto = ""
  fechaNacimiento = ""
  date = new Date(this.fechaNacimiento);
  fotoPerfil = ""
  image = ""
  nombreVacio = false
  apellidosVacio = false
  telefonoVacio = false
  mailContactoVacio = false
  fechaNacimientoVacio = false



  constructor(private notificaciones: NotificacionesService, private router: Router, private popupSE: PopupService, private loginSE: LoginService,private userSE: UserService, private loader: LoaderService) {
    super();
  }

  ngOnInit() {
    if (!this.loginSE.isLogin()) {
      this.router.navigate(["/login"])
      this.loginSE.logOut(true);
    }else{
      this.getMyData();
    }
  }
  getMyData(loaderActivo:boolean = true) {
    this.userSE.getMyUser();
    if (loaderActivo) {
      this.loader.open()
    }
    this.userSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return }
      this.nombre = value.name
      this.apellidos = value.surname
      this.telefono = value.phone
      this.fechaNacimiento = value.birthDate
      this.mailContacto = value.email
      this.fotoPerfil = value.profileImage
      if (loaderActivo) {
        this.loader.closeSlow();
      }
      this._unsubInd.next("");
    })
  }
  setProfileImage(imagenPerfil){
    this.updateUser("profileImage", [{image: imagenPerfil[0], isPrincipal: true}])
  }
  updateUser(element:any, data:any){
    var usuario = {};
    usuario[element] = data;
    this.userSE.updateUser(sessionStorage.getItem("nmsr"), usuario);
    this.userSE.getResultUpdate().pipe(takeUntil(this._unsubInd2)).subscribe(value=>{
      if(value==null){return}
      this.notificaciones.showFeedBack(value.message)
      this.getMyData(false);
      this._unsubInd2.next("");
    })
    this.userSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(value=>{
      if(value==null){return}
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    })
  }
  setClick(element) {
    (document.querySelector(element) as HTMLElement).click();
  }

  checkData(element?:any, data?:any) {
    if (this.nombre == "") {
      this.nombreVacio = true;
    } else {
      this.nombreVacio = false;
    }
    if (this.apellidos == "") {
      this.apellidosVacio = true;
    } else {
      this.apellidosVacio = false;
    }
    if (this.telefono == null) {
      this.telefonoVacio = true;
    } else {
      this.telefonoVacio = false;
    }
    if (this.mailContacto == "") {
      this.mailContactoVacio = true;
    } else {
      this.mailContactoVacio = false;
    }
    if (this.fechaNacimiento == "") {
      this.fechaNacimientoVacio = true;
    } else {
      this.fechaNacimientoVacio = false;
    }

    if (!this.nombreVacio && !this.apellidosVacio && !this.telefonoVacio && !this.mailContactoVacio && !this.fechaNacimientoVacio) {
      if (!this.validEmail(this.mailContacto)) {
        this.notificaciones.showError("El formato del mail no es correcto");
        this.mailContactoVacio = true;
      } else {
        this.mailContactoVacio = false;
        this.updateUser(element, data)
      }
    } else {
      this.notificaciones.showError("No pueden haber campos vacíos")
    }
  }
  changeDate(event: any) {
    this.fechaNacimiento = event.value
    this.checkData('birthDate',  this.fechaNacimiento)
  }
  validEmail(email: string) {
    var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
    return String(email).search(filter) != -1;
  }
  openChangePassword() {
    this.popupSE.openPopup(ChangePasswordPopupComponent)
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd3)).subscribe(value => {
      if (value == null) { return }
      this.updateUser("password", sha256(value.returnValue.newPass))
      this._unsubInd3.next("")
      this.popupSE.clearData();
    })
  }
}
