import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/Api/login.service';

@Component({
  selector: 'app-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss']
})
export class SettingsPageComponent implements OnInit {
  isOpen = false;
  constructor(private loginSE: LoginService) { }

  ngOnInit(): void {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    }
  }
  showSearchBar(){
    if (!this.isOpen) {
      this.isOpen = true;
    }else{
      this.isOpen = false
    }
  }
}
