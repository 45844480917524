import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { Fecha } from 'src/app/models/Fecha';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { EventService } from 'src/app/services/Api/event.service';
import { ListsService } from 'src/app/services/Api/lists.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nueva-lista',
  templateUrl: './nueva-lista.component.html',
  styleUrls: ['./nueva-lista.component.scss']
})
export class NuevaListaComponent extends UnsubscribesDestroy implements OnInit {
  urlImages = environment.urlImages
  tipoBoton = 2;
  pasos = ['Esta semana', 'Todos los eventos'];
  activo: number = 0;
  fechaActual = new Date().toLocaleDateString();
  idEvento: number = -1;
  d = new Date();
  date = this.d.getDate();
  day = this.d.getDay();
  weekOfMonth = Math.ceil((this.date + 6 - this.day) / 7);
  fecha = new Date();
  filtro = ""
  reservados = [{ id: 1, nombre: "Reservado VIP" }, { id: 2, nombre: "Reservado" }, { id: 3, nombre: "Lista normal" }]

  reservadoSelect: any;
  nombreLista = ""
  numeroPersonas = 0;

  reservadoSelectVacio = false;
  nombreListaVacio = false;
  numeroPersonasVacio = false;

  eventosActivosPorSemana: any = []
  eventosActivosPorSemanaBackup: any = []
  eventosSeleccionados: any = []
  eventosSeleccionadosBackup: any = []
  eventoSeleccionado: any
  listaSeleccionada: any = -1;
  isDataLoaded = false;
  eventos: any = [

  ]
  eventosCalendario: any = []
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private notificaciones: NotificacionesService, private loginSE: LoginService, private eventSE: EventService, private loaderSE: LoaderService, private listSE: ListsService) {
    super();
  }

  ngOnInit() {
    if (!this.loginSE.isLogin()) {
      this.router.navigate(["/login"])
      this.loginSE.logOut(true);
    } else {
      if (this.activatedRoute.snapshot.params['id'] != undefined) {
        this.idEvento = this.activatedRoute.snapshot.params['id']
        this.obtainEvents()
      } else {
        this.getEventsByWeek();
        setTimeout(() => {
          this.obtainEvents()
        }, 2000);
      }
    }

  }
  cambiado(event: any) {
    this.activo = event;
  }
  getEventsByWeek() {
    var today = new Date();
    var day = String(today.getDate()).padStart(2, '0');
    var month = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var year = today.getFullYear();
    this.eventSE.getEventsByWeek();
    this.loaderSE.open();
    this.eventSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return };
     
      this.eventosActivosPorSemanaBackup = value
      this.eventosActivosPorSemana = value;
      this._unsubInd.next("");
    })
  }
  clickFecha(fecha: Date) {
    this.eventosSeleccionados = []
    this.eventosCalendario.map(element => {
      var d2Aux = new Date(element.startDate)
      var d2: Fecha = new Fecha();
      d2.dia = d2Aux.getDate();
      d2.mes = d2Aux.getMonth() + 1
      d2.year = d2Aux.getFullYear()

      if (d2.dia == fecha.getDate() && d2.mes == (fecha.getMonth() + 1) && d2.year == fecha.getFullYear()) {
        this.eventosSeleccionados.push(element)
        this.eventosSeleccionadosBackup.push(element)
      }

    })
  }
  fechaToText(date: Date) {
    let res = '';
    let dia, mes;
    if (date.getDate() < 10) {
      dia = '0' + date.getDate();
    } else {
      dia = date.getDate();
    }

    if (date.getMonth() + 1 < 10) {
      mes = '0' + (date.getMonth() + 1);
    } else {
      mes = date.getMonth() + 1;
    }
    return date.getFullYear() + '-' + mes + '-' + dia;
  }
  goToSelectedEvent(evento: any) {
    sessionStorage.setItem("returnForm", this.router.url)
    this.router.navigate([this.router.url + "/" + evento.id])
  }
  less() {
    if (this.numeroPersonas != 0) {
      this.numeroPersonas--;
    }
  }
  more() {
    this.numeroPersonas++;
  }
  checkData() {
    if (this.nombreLista == "") {
      this.nombreListaVacio = true;
    } else {
      this.nombreListaVacio = false;
    }
    if (this.numeroPersonas == 0 || this.numeroPersonas == null) {
      this.numeroPersonasVacio = true;
    } else {
      this.numeroPersonasVacio = false;
    }
    // if (this.reservadoSelect == undefined) {
    //   this.reservadoSelectVacio = true;
    // } else {
    //   this.reservadoSelectVacio = false;
    // }
    if (!this.nombreListaVacio && !this.numeroPersonasVacio) {
      var obj = {
        name: "Lista de" + this.nombreLista,
        description: "",
        precio: 0,
        stock: this.numeroPersonas + 1,
        fee: 0,
        iva: 21,
        isPhysical: false,
        startDate: this.eventoSeleccionado.startDate,
        finishDate: this.eventoSeleccionado.endDate,
        startTime: this.eventoSeleccionado.startTime,
        finishTime: this.eventoSeleccionado.finishTime,
        administrativeExpenses: 0.0,
        gender: "UNKNOWN",
        type: "PRODUCT_EVENT_LIST_TYPE",
        ageRange: "RANGE_21", //evento
        company: sessionStorage.getItem("dcmpny"),
        event: this.idEvento,
        taxType: "FIXED_TAX_TYPE"
      }
      this.listSE.newList(obj);
      this.listSE.getResult().pipe(takeUntil(this._unsubInd5)).subscribe(value => {
        if (value == null) { return }
        this.buyList(value);
        
        this._unsubInd5.next("");
      })
      this.listSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
        if (value == null) { return }
        this.notificaciones.showError(value.error.message)
        this._unsub.next("");
      })

    } else {
      this.notificaciones.showError("No pueden haber campos vacíos");
    }
  }
  buyList(value){
    this.listSE.buyList(value.data.id, (this.numeroPersonas + 1))
    this.loaderSE.open();
    this.listSE.getResultIndividual().pipe(takeUntil(this._unsubInd6)).subscribe(res => {
      if (res == null) { return }
      this.loaderSE.closeSlow()
      this.notificaciones.showFeedBack("Lista creada correctamente")
      this.router.navigate([sessionStorage.getItem("lastRoute")]);
      sessionStorage.removeItem("lastRoute");
      sessionStorage.removeItem("returnForm");
      this._unsubInd6.next("")
    })
    this.listSE.getResultIndividualError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (res == null) { return }
      this.loaderSE.closeSlow()
      this.notificaciones.showError(value.error)
      this._unsub.next("")
    })
  }
  cambiarMes(fecha: Date){
    this.obtainEvents(fecha);
  }
  fechaAnt:Date|null = null;
  eventsLoaded = false;
  obtainEvents(fecha?: Date){
    if(fecha != undefined){
      var today = fecha;
    }else{
      var today = new Date();
    }
    if(this.fechaAnt != null){
      if(this.fechaAnt > today){
        this.fechaAnt = today;
      }else{
        return;
      }
    }else{
      this.fechaAnt = today;
    }
    this.eventsLoaded = false;
    this.eventosCalendario = [];
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    this.eventSE.getEvents(5000, 1, date);
    if (this.activatedRoute.snapshot.params['id'] != undefined) {
      this.loaderSE.open();
    }
    this.eventSE.getResult().pipe(takeUntil(this._unsubInd3)).subscribe(value => {
      if (value == null) { return; }
      this.eventosCalendario = value;
      this.loaderSE.closeSlow();
      this.eventsLoaded = true;
      if (this.activatedRoute.snapshot.params['id'] != undefined) {
        this.loaderSE.closeSlow();
        this.eventosCalendario.map(element => {
          if (element.id == this.idEvento) {
            this.eventoSeleccionado = element;
            this.isDataLoaded = true;
          }
        })
      }

      this._unsubInd3.next("")
    })
    this.eventSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return; }
      this._unsub.next("")
    })
    setTimeout(() => {
      this.clickFecha(new Date(today.toISOString()))
    }, 1500);
  }
  filtrarEventos(filtro?) {
    if (this.activo == 0) {
      this.eventosActivosPorSemana = this.eventosActivosPorSemanaBackup.filter(evento => evento.title.toLowerCase().includes(filtro.toLowerCase()) || evento.title.toLowerCase().includes(filtro.toLowerCase()))
    } else {
      this.eventosSeleccionados = this.eventosSeleccionadosBackup.filter(evento => evento.title.toLowerCase().includes(filtro.toLowerCase()) || evento.title.toLowerCase().includes(filtro.toLowerCase()))

    }
  }
}
