import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'duracion',
  templateUrl: './Duracion.component.html',
  styleUrls: ['./Duracion.component.scss']
})
export class DuracionComponent implements OnInit {
  //ENVIO Y RECEPCION DE DATOS
  @Output() data = new EventEmitter<any>()
  @Output() validStep = new EventEmitter<Boolean>()
  @Output() dataToUpdate = new EventEmitter<any>()
  @Input() receiveData: any = null

  editar: boolean = false;
  today = new Date();

  nombreCampanya: string = ""
  nombreCampanyaError: boolean = false;
  fechaInicio: any = null;
  fechaInicioError: boolean = false;
  horaLanzamiento: string = ""
  horaLanzamientoError: boolean = false;
  lanzarCampanyaGuardar: boolean = false;
  constructor(private route: ActivatedRoute, private notificaciones: NotificacionesService) { }

  ngOnInit() {
    if (this.route.snapshot.params['id'] != null || this.route.snapshot.params['id'] != undefined) {
      this.editar = true;
    }
    this.setData();
  }

  /**
  * GETTERS & SETTERS
  */
   setData(){
    if (this.receiveData != null) {
      this.nombreCampanya = this.receiveData.name;
      this.horaLanzamiento = this.receiveData.startTime;
      this.fechaInicio = this.receiveData.startDate;
      this.lanzarCampanyaGuardar = this.receiveData.lanzarCampanyaGuardar
    }
  }
  getNombreEvento(event: string) {
    this.nombreCampanya = event;
  }
  getFechaInicio(event: any) {
    this.fechaInicio = event;
    this.validarFechas();
    if (!this.fechaInicioError) {
      this.updateFechas();
    }
  }
  getHoraLanzamiento(event: any) {
    this.horaLanzamiento = event;
  }
  getLanzarCampanyaGuardar(event: boolean){
    this.lanzarCampanyaGuardar = event;
    this.fechaInicio = null;
    this.horaLanzamiento  =""
    this.fechaInicioError = false;
    this.horaLanzamientoError = false;
  }
  //VALIDACIONES
  validarFechas() {
    var d2 = new Date(String(this.fechaInicio));
    var auxToday = this.today.getFullYear()+"-"+(this.today.getMonth()+1)+"-"+this.today.getDate()
    if (d2.getTime() < new Date(auxToday).getTime()) {
      this.notificaciones.showError("La fecha de inicio no puede ser inferior a la fecha actual")
      this.fechaInicioError = true;
    } else {
      this.fechaInicioError = false;

    }
  }
  validarNombreCampanya(){
    if (this.nombreCampanya == "") {
      this.nombreCampanyaError = true;
    }else{
      this.nombreCampanyaError = false;
    }
  }
  validarFecha(){
    if (this.fechaInicio == null  ) {
      this.fechaInicioError = true;
    }else{
      this.fechaInicioError = false;
    }
  }
  validarHoraLanzamiento(){
    if (this.horaLanzamiento == "") {
      this.horaLanzamientoError = true;
    }else{
      this.horaLanzamientoError = false;
    }
  }
  validarPaso1(){
    this.validarNombreCampanya();
    if (!this.lanzarCampanyaGuardar) {
      this.validarFecha();
      this.validarHoraLanzamiento();
    }

    if (!this.fechaInicioError && !this.nombreCampanyaError && !this.horaLanzamientoError) {
      var step1Obj = {
        startDate: this.fechaInicio,
        name: this.nombreCampanya,
        startTime: this.horaLanzamiento,
        lanzarCampanyaGuardar: this.lanzarCampanyaGuardar
      }
      this.data.emit(step1Obj)
      this.validStep.emit(true);
    }else{
      this.validStep.emit(false)
      this.notificaciones.showError("No pueden haber campos vacios")
    }
  }
  /**
  * EMITTERS
  */
  updateNombreEvento(event: string) {
    if (this.editar) { this.dataToUpdate.emit({ element: "name", value: event }) }
  }
  updateHoraLanzamiento(event: string) {
    if (this.editar) { this.dataToUpdate.emit({ element: "startTime", value: event }) }
  }
  updateFechas(){
    if (this.editar) { this.dataToUpdate.emit({ element: "startDate", value: this.fechaInicio }) }
  }
}
