import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { DatosDePagoGestionPagosPopupComponent } from 'src/app/popups/DatosDePagoGestionPagos-Popup/DatosDePagoGestionPagos-Popup.component';
import { RedsysLogService } from 'src/app/services/Api/RedsysLog.service';
import { EventService } from 'src/app/services/Api/event.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { TicketsService } from 'src/app/services/Api/tickets.service';
import { UserService } from 'src/app/services/Api/user.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PopupService } from 'src/app/services/utils/popup.service';

@Component({
  selector: 'app-GestionPagos',
  templateUrl: './GestionPagos.component.html',
  styleUrls: ['./GestionPagos.component.scss']
})
export class GestionPagosComponent extends UnsubscribesDestroy implements OnInit {
  fechaCompra: any = null;
  dataIsLoaded: boolean = false;
  filters: any = {
    num_devoluciones: 5,
    num_pagina: 1,
  }
  pagos: Array<any> = [];
  constructor(private notificaciones: NotificacionesService, private loginSE: LoginService, private redsyslogSE: RedsysLogService, private popupSE:PopupService) {
    super();
  }

  ngOnInit() {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      this.checkPermisos();
    }
  }

  /**
   * FUNCTIONALITIES
   */
  clearFilters() {
    this.fechaCompra = null
    this.dataIsLoaded = false;
    delete this.filters.purchaseDate;
    this.getRedsysLog();
  }
  openPagoPopup(pago) {
    this.popupSE.openPopup(DatosDePagoGestionPagosPopupComponent, {data:pago});
    
  }

  /**
   * GETTERS & SETTERS
   */
  getFilter(event) {
    this.filters.genericFilter = event;
    this.filters.num_devoluciones = 5;
    this.filters.num_pagina = 1;
    this.dataIsLoaded = false;
    this.getRedsysLog();
  }
  changeDate(event) {
    this.filters.num_devoluciones = 5;
    this.filters.num_pagina = 1;
    if (event == null) {

      delete this.filters.purchaseDate;
    }
    else {

      this.filters.purchaseDate = new Date(event.value).getFullYear() + "-" + (new Date(event.value).getMonth() + 1) + "-" + new Date(event.value).getDate();
    }
    this.dataIsLoaded = false;
    this.getRedsysLog();
  }
  paginaAnterior() {
    if (this.filters.num_pagina > 1) {
      this.filters.num_pagina--;
      this.dataIsLoaded = false;
      this.getRedsysLog();
    }
  }
  siguientePagina() {
    this.filters.num_pagina++;
    this.dataIsLoaded = false;
    this.getRedsysLog();
  }
  /**
   * CALLS TO API
   */

  getRedsysLog() {
    this.dataIsLoaded = false;
    this.redsyslogSE.getAll(this.filters);
    this.redsyslogSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (res == null) { return; }
      this.dataIsLoaded = true;
      this.pagos = res;
      this._unsubInd.next("");
    })
    this.redsyslogSE.getResultError().pipe(takeUntil(this._unsub)).subscribe((res: any) => {
      if (res == null) { return; }
      this.dataIsLoaded = true;
      this.notificaciones.showError(res.error.message);
      this._unsubInd.next("");
    })
  }

  /**
   * PERMISSIONS
   */
  checkPermisos() {
    if (localStorage.getItem("tipoUser") == null || localStorage.getItem("tipoUser") == undefined || localStorage.getItem("tipoUser") != '["3"]') {
      this.loginSE.logOut();
      this.notificaciones.showError("No tienes permisos para acceder")
    } else {
      if (localStorage.getItem("tipoUser") == '["3"]') {
        this.getRedsysLog();
      }
    }

  }
}
