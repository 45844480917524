<div class="duracionGeneralContainer">
  <FormElement [receiveData]="nombreCampanya" [error]="nombreCampanyaError" [type]="'input'"
    [placeholder]="'Nombre de la campaña'" [label]="'Nombre de la campaña'" (sendData)="getNombreEvento($event)"
    (updateData)="updateNombreEvento($event)"></FormElement>

  <div class="container">
    <div class="stepLeft">
      <p [class.vacio]="fechaInicioError" [class.disabled]="lanzarCampanyaGuardar" >Fecha de inicio</p>
      <RangeCalendar [class.disabled]="lanzarCampanyaGuardar" [ini]="fechaInicio" [rango]="false" (fechaSeleccionada)="getFechaInicio($event)"></RangeCalendar>
    </div>
    <div class="stepRight">
      <FormElement [class.disabled]="lanzarCampanyaGuardar"[receiveData]="horaLanzamiento" [error]="horaLanzamientoError" [type]="'time'"
        [placeholder]="'Hora de lanzamiento de la campaña'" [label]="'Hora de lanzamiento de la campaña'" (sendData)="getHoraLanzamiento($event)"
        (updateData)="updateHoraLanzamiento($event)"></FormElement>
        <mat-checkbox (change)="getLanzarCampanyaGuardar($event.checked)" [checked]="lanzarCampanyaGuardar">
          Lanzar la campaña al guardar
        </mat-checkbox>
    </div>
  </div>
</div>
