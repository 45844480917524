import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AvisoIOSPopupComponent } from 'src/app/popups/AvisoIOSPopup/AvisoIOSPopup.component';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { PopupService } from 'src/app/services/utils/popup.service';


@Component({
  selector: 'app-redirect-to-store',
  templateUrl: './redirect-to-store.component.html',
  styleUrls: ['./redirect-to-store.component.scss']
})
export class RedirectToStoreComponent implements OnInit {
  notMobile: boolean = false;
  deepRoute: any = localStorage.getItem("deepRoute")?.replace("https", "http");
  tienda: any = ""
  constructor(private loader: LoaderService, private router: Router, private popupSE: PopupService) { }

  ngOnInit() {
    // location.reload()

    this.getMobileOperatingSystem();
  }
  getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      this.tienda = "https://play.google.com/store/apps/details?id=com.memorable.app_memorable";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) ) {
      this.tienda = "https://apps.apple.com/lb/app/com-memorable-appmemorable/id1641700880";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && navigator.userAgent.includes("Instagram")) {
      this.tienda = "https://apps.apple.com/lb/app/com-memorable-appmemorable/id1641700880";
      this.popupSE.openPopup(AvisoIOSPopupComponent)
    }
  }

}
