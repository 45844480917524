<li>
  <div class="card-md-alt-det" [ngStyle]="{
      backgroundColor: this.isFocus ? '#d3d5ef' : ''
    }">
    <a attr.aria-label="Evento {{ event.name }}" routerLink="/publisher/management/ticket-store/{{ event.id }}"
      class="stretched-link" tabindex="0" (focus)="onFocus()" (blur)="onBlur()">
      <img src="../assets/pedro-lastra-Nyvq2juw4_o-unsplash.jpg" alt="" class="card-img" />
      <div class="float-date">
        <span>{{ event.schedule.startDate | date: 'd' }}</span><span>{{ event.schedule.startDate | date: 'MMM' |
          uppercase }}</span>
      </div>
      <div class="float-info">
        <div class="float-item">
          <div class="float-icon">
            <img src="/assets/icons/ticket-solid.svg" alt="" />
          </div>
          <div class="float-details">
            <span class="float-title">Entradas</span>
            <span class="float-description">202 / 215</span>
          </div>
        </div>
        <div class="float-item">
          <div class="float-icon">
            <img src="/assets/icons/person-solid.svg" alt="" />
          </div>
          <div class="float-details">
            <span class="float-title">Listas</span>
            <span class="float-description">188 / 422</span>
          </div>
        </div>
        <div class="float-item">
          <div class="float-icon">
            <img src="/assets/icons/star-solid.svg" alt="" />
          </div>
          <div class="float-details">
            <span class="float-title">Reservas</span>
            <span class="float-description">150/ 310</span>
          </div>
        </div>
      </div>

      <div class="card-info">
        <div class="card-title">
          <h5>{{ event.name }}</h5>
        </div>
        <div class="card-details">
          <small class="facility">Recinto Indiana</small>
          <small class="active" *ngIf="event.isActive">Activo</small>
          <small class="inactive" *ngIf="!event.isActive">No activo</small>
        </div>
      </div>
    </a>
  </div>
</li>