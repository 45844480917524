<PageTemplate>
  <ng-container bodyContent>
    <div class="headerContainer">
      <header [searcherActive]="false"></header>
      <searcher [placeHolder]="'Busca aquí una comunidad'" [toFilter]="communitiesBackup" [type]="'community'"
        (filtered)="filteredCommunities($event)"></searcher>
    </div>
    <div class="infoContainer">
      <Info [typeCard]="'admin'" [loaded]="isDataLoaded" [icon]="'/assets/icons/graficaIcono.svg'"
        [type]="'Número total de comunidades'" [value]="estadisticas.num_communities"></Info>
      <Info [typeCard]="'admin'" [loaded]="isDataLoaded" [icon]="'/assets/icons/graficaIcono.svg'"
        [type]="'Alcance total de usuarios'" [value]="estadisticas.alcance"></Info>
      <Info [typeCard]="'admin'" [loaded]="isDataLoaded" [icon]="'/assets/icons/graficaIcono.svg'"
        [type]="'Número medio de mensajes por día'" [value]="estadisticas.media_mensajes.toFixed(2)"></Info>
      <Info [typeCard]="'admin'" [loaded]="isDataLoaded" [icon]="'/assets/icons/graficaIcono.svg'"
        [type]="'Edad Media de usuarios'" [value]="estadisticas.edad_media.toFixed(2)"></Info>
    </div>

    <div class="spinnerContainer" *ngIf="!isDataLoaded">
      <mat-spinner class="big"></mat-spinner>
    </div>
    <ng-container *ngIf="isDataLoaded">
      <p class="noData" *ngIf="communities.length == 0">No hay comunidades disponibles</p>
      <div class="communitiesContainer">
        <card [data]="community" [type]="'communityCard'" (click)="setCompany(community)"
          *ngFor="let community of communities "></card>
      </div>
    </ng-container>
  </ng-container>
</PageTemplate>