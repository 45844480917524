import { Component, OnInit } from '@angular/core';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PermissionsService } from 'src/app/services/Api/permissions.service';
import { CompanyService } from 'src/app/services/Api/company.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PopupService } from 'src/app/services/utils/popup.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { take, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfirmPopupComponent } from 'src/app/popups/confirmPopup/confirmPopup.component';
import { AddNewAdminPopupComponent } from 'src/app/popups/addNewAdminPopup/addNewAdminPopup.component';

@Component({
  selector: 'app-UsersPermissions',
  templateUrl: './UsersPermissions.component.html',
  styleUrls: ['./UsersPermissions.component.scss'],
})
export class UsersPermissionsComponent
  extends UnsubscribesDestroy
  implements OnInit
{
  urlImages = environment.urlImages;
  active: number = 0;
  groupAdmins: Array<any> = [];
  permisos: any = [];
  empleados: any = [];
  negocios: any = [];
  dataIsLoaded: Boolean = false;
  especialDataIsLoaded: Boolean = false;

  negocioSelect: Number = -1;

  myUser: any = sessionStorage.getItem('nmsr');
  editar: Boolean = false;
  constructor(
    private loginSE: LoginService,
    private popup: PopupService,
    private router: Router,
    private permissionsSE: PermissionsService,
    private notificaciones: NotificacionesService,
    private companySE: CompanyService,
    private route: ActivatedRoute,
    private loaderSE: LoaderService,
    private popupSE: PopupService
  ) {
    super();
  }

  ngOnInit() {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      this.checkPermisos();
    }
  }
  modificarTodosPermisos(admin) {
    if (admin.permisos == null) {
      this.updatePermission(
        [
          'CONSULT_EDIT_COMPANY',
          'CONSULT_EDIT_COMPANY_GROUP',
          'CONSULT_COMPANY',
          'CONSULT_COMPANY_GROUP',
          'GRANT_PERMISSIONS_COMPANY_GROUP',
          'CREATE_COMPANY',
        ],
        admin.user_id,
        true,
        'admin'
      );
    } else {
      if (admin.permisos.split(',').length == 6) {
        this.updatePermission(
          [
            'CONSULT_EDIT_COMPANY',
            'CONSULT_EDIT_COMPANY_GROUP',
            'CONSULT_COMPANY',
            'CONSULT_COMPANY_GROUP',
            'GRANT_PERMISSIONS_COMPANY_GROUP',
            'CREATE_COMPANY',
          ],
          admin.user_id,
          false,
          'admin'
        );
      } else {
        this.updatePermission(
          [
            'CONSULT_EDIT_COMPANY',
            'CONSULT_EDIT_COMPANY_GROUP',
            'CONSULT_COMPANY',
            'CONSULT_COMPANY_GROUP',
            'GRANT_PERMISSIONS_COMPANY_GROUP',
            'CREATE_COMPANY',
          ],
          admin.user_id,
          true,
          'admin'
        );
      }
    }
  }
  //FUNCIONALIDADES
  comprobarPermiso(empleado, permiso, segundoPermiso = null, tipo = 'empresa') {
    this.popup.openPopup(ConfirmPopupComponent, {
      accion: 'Modificar',
      tipo: 'permisos',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd5))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        if (tipo == 'empresa') {
          if (empleado.permisos != null) {
            if (empleado.permisos.includes(permiso)) {
              if (permiso == 'CONSULT_COMPANY') {
                this.updatePermission(
                  ['CONSULT_EDIT_COMPANY', 'CONSULT_COMPANY'],
                  empleado.user_id,
                  false,
                  'empresa'
                );
              } else {
                this.updatePermission(
                  ['CONSULT_EDIT_COMPANY'],
                  empleado.user_id,
                  false,
                  'empresa'
                );
              }
            } else {
              this.updatePermission(
                [permiso],
                empleado.user_id,
                true,
                'empresa'
              );
            }
          } else {
            this.updatePermission([permiso], empleado.user_id, true, 'empresa');
          }
        } else if ('admin') {
          if (empleado.permisos != null) {
            if (segundoPermiso == null) {
              if (empleado.permisos.includes(permiso)) {
                this.updatePermission(
                  [permiso],
                  empleado.user_id,
                  false,
                  'admin'
                );
              } else {
                this.updatePermission(
                  [permiso],
                  empleado.user_id,
                  true,
                  'admin'
                );
              }
            } else {
              if (
                empleado.permisos.includes(permiso) &&
                empleado.permisos.includes(segundoPermiso)
              ) {
                if (
                  permiso == 'CONSULT_COMPANY' &&
                  segundoPermiso == 'CONSULT_COMPANY_GROUP'
                ) {
                  this.updatePermission(
                    [
                      'CONSULT_EDIT_COMPANY',
                      'CONSULT_EDIT_COMPANY_GROUP',
                      'CONSULT_COMPANY',
                      'CONSULT_COMPANY_GROUP',
                    ],
                    empleado.user_id,
                    false,
                    'admin'
                  );
                } else {
                  this.updatePermission(
                    ['CONSULT_EDIT_COMPANY', 'CONSULT_EDIT_COMPANY_GROUP'],
                    empleado.user_id,
                    false,
                    'admin'
                  );
                }
              } else {
                if (
                  permiso == 'CONSULT_COMPANY' &&
                  segundoPermiso == 'CONSULT_COMPANY_GROUP'
                ) {
                  this.updatePermission(
                    [
                      'CONSULT_EDIT_COMPANY',
                      'CONSULT_EDIT_COMPANY_GROUP',
                      'CONSULT_COMPANY',
                      'CONSULT_COMPANY_GROUP',
                    ],
                    empleado.user_id,
                    true,
                    'admin'
                  );
                } else {
                  this.updatePermission(
                    ['CONSULT_EDIT_COMPANY', 'CONSULT_EDIT_COMPANY_GROUP'],
                    empleado.user_id,
                    true,
                    'admin'
                  );
                }
              }
            }
          } else {
            if (segundoPermiso == null) {
              this.updatePermission([permiso], empleado.user_id, true, 'admin');
            } else {
              this.updatePermission(
                [permiso, segundoPermiso],
                empleado.user_id,
                true,
                'admin'
              );
            }
          }
        }

        this._unsubInd5.next('');
      });
  }

  //LLAMADAS A LA API
  getPermissions() {
    this.permissionsSE.getPermissions();
    this.permissionsSE
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((value) => {
        if (value == null) {
          return;
        }

        this.permisos = value;
        this._unsubInd.next('');
        setTimeout(() => {
          this.getGroupAdmins();
        }, 500);
      });
    this.permissionsSE
      .getResultError()
      .pipe(takeUntil(this._unsub))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.notificaciones.showError(value.error.message);

        this._unsub.next('');
      });
  }

  getGroupAdmins() {
    this.permissionsSE.getAllAdminsOfGroup();
    this.permissionsSE.getResult().pipe(takeUntil(this._unsubInd3)).subscribe(value => {
      if (value == null) { return }
      this.groupAdmins = value;
      this._unsubInd3.next("");
      setTimeout(() => {
        this.getAllCompaniesOfGroup();
      }, 500);
    })
    this.permissionsSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.notificaciones.showError(value.error.message)
      this.loaderSE.closeSlow();
      this._unsub.next("")
    })
  }
  getAllCompaniesOfGroup() {
    this.companySE.getAllCompaniesOfGroup();
    this.companySE
      .getResult()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((value) => {
        if (value == null) {
          return;
        }

        this.negocios = value;
        this.dataIsLoaded = true;
        this._unsubInd2.next('');
      });
    this.companySE
      .getResultError()
      .pipe(takeUntil(this._unsub))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.notificaciones.showError(value.error.message);

        this._unsub.next('');
      });
  }
  getAllAdminsOfCompany() {
    this.permissionsSE.getAllAdminsOfCompany(this.negocioSelect);

    this.permissionsSE
      .getResult()
      .pipe(takeUntil(this._unsubInd4))
      .subscribe((value) => {
        if (value == null) {
          return;
        }

        this.empleados = value;
        this.groupAdmins.forEach((element) => {
          this.empleados.push(element);
        });
        this.dataIsLoaded = true;

        this._unsubInd4.next('');
        this.especialDataIsLoaded = true;
      });
    this.permissionsSE
      .getResultError()
      .pipe(takeUntil(this._unsub))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.notificaciones.showError(value.error.message);

        this._unsub.next('');
      });
  }
  updatePermission(permiso, userId, allowed, origen) {
    if (origen == "empresa") {

      this.permissionsSE.updatePermissions(userId, permiso, allowed, false, this.negocioSelect);
    } else if (origen == "admin") {
      let company = sessionStorage.getItem("groupId");
      this.permissionsSE.updatePermissions(userId, permiso, allowed, true, company);

    }
    this.dataIsLoaded = false;
    // this.loaderSE.open();
    this.permissionsSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd4))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.notificaciones.showFeedBack(value.message);

        setTimeout(() => {
          if (origen == 'empresa') {
            this.getAllAdminsOfCompany();
          } else if (origen == 'admin') {
            this.getGroupAdmins();
          }
        }, 500);
        this._unsubInd4.next('');
      });
    this.permissionsSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd4))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.notificaciones.showError(value.error.message);

        this._unsubInd4.next('');
      });
  }
  checkPermisos() {
    if (
      localStorage.getItem('tipoUser') == null ||
      localStorage.getItem('tipoUser') == undefined ||
      localStorage.getItem('tipoUser') == '["2"]'
    ) {
      this.loginSE.logOut();
      this.notificaciones.showError('No tienes permisos para acceder');
    } else {
      if (
        localStorage.getItem('tipoUser') == '["0"]' ||
        localStorage.getItem('tipoUser') == '["3"]'
      ) {
        if (localStorage.getItem('permisos') == 'null') {
          this.loginSE.logOut();
          this.notificaciones.showError('No tienes permisos para acceder');
        } else if (
          !localStorage.getItem('permisos')?.includes('CONSULT_COMPANY') &&
          !localStorage.getItem('permisos')?.includes('CONSULT_COMPANY_GROUP')
        ) {
          this.loginSE.logOut();
          this.notificaciones.showError('No tienes permisos para acceder');
        } else if (
          localStorage
            .getItem('permisos')
            ?.includes('GRANT_PERMISSIONS_COMPANY_GROUP')
        ) {
          this.editar = true;
          this.getPermissions();
        } else {
          this.getPermissions();
        }
      }
    }
  }
  addNewAdmin() {
    this.popup.openPopup(AddNewAdminPopupComponent, {
      active: this.active,
      empresas: this.negocios,
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd6))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.dataIsLoaded = false;
        this.groupAdmins = [];
        this.permisos = [];
        this.empleados = [];
        this.negocios = [];
        setTimeout(() => {
          this.ngOnInit();
        }, 1000);
        this._unsubInd.next('');
      });
  }
}
