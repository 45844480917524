import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent implements OnInit {
  @Input('titulo') titulo: any = '';
  @Input('descripcion') descripcion: any = '';
  @Input('enlace') enlace: any = '';
  @Input('icono') icono: any = '';

  constructor() {}

  ngOnInit() {}
}
