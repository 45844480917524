<div class="general">
  <div>
    <a class="abrirApp" [href]="deepRoute">
      Continuar en la app
    </a>

    <div class="descarga">
      <label for="">Puedes descargar la app aquí: </label>
      <a class="descargaApp" [href]="'https://play.google.com/store/apps/details?id=com.memorable.app_memorable'">
      <img src="/assets/images/android.png">
      </a>
      <a class="descargaApp" [href]="'https://apps.apple.com/lb/app/com-memorable-appmemorable/id1641700880'">
      <img src="/assets/images/appstore.png">
      </a>
    </div>
  </div>
  <!-- <img src="/assets/icons/logoLogin.png" alt="">
  <div class="android" >
    <p>Disponible en: </p>
    <div>
      <a [href]="deepRoute">Abrir en la app</a>
    </div>
  </div> -->
</div>
