import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'FormElement',
  templateUrl: './FormElement.component.html',
  styleUrls: ['./FormElement.component.scss']
})
export class FormElementComponent implements OnInit {
  @Input() type:string = ""
  @Input() placeholder:string = ""
  @Input() label:string = ""
  @Input() pills:Array<any> = []
  @Input() options:Array<any> = []
  @Input() iconActive:boolean = false;
  @Input() selectorType:string = ""
  @Input() textAreaRows:string = ""
  @Input() receiveData:any = null
  @Input() error:Boolean = false;
  @Input() radioOptions:any = {trueOption: "", falseOption: ""};
  @Input() soloLectura: Boolean = false;
  @Input() labelActive: Boolean = true;
  @Input() loaded: Boolean = false;
  @Output() sendData = new EventEmitter<any>();
  @Output() updateData = new EventEmitter<any>();
  data:any = null
  pillsSelected:Array<any> = []
  optionSelected:any = null;
  updatePills = false;
  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.type != "pills" && this.type != "selector") {
      if (this.receiveData !== null && this.receiveData !== undefined) {
        this.data =this.receiveData;
      }else{
        this.data = null;
      }
    }
    if (this.type == "pills") {
      if (this.receiveData != null) {
        this.receiveData.map(element=>{
          if (typeof element == "string") {
            this.pillsSelected.push(element)
          }else{
            if (element.name != undefined) {
              this.pillsSelected.push(element.name)
            }
          }

        })
      }
    }
    if (this.type == "selector") {
      if (this.receiveData != null) {
        this.optionSelected = this.receiveData
      }
    }
  }

  ngOnInit() {
    if (this.receiveData != null) {
      if (this.type == "selector") {
        this.optionSelected = this.receiveData
      }else{
        this.data = this.receiveData;
      }
    }
  }
  emitData(){
    this.sendData.emit(this.data);
  }
  emitUpdateData(){
    this.updateData.emit(this.data);
    
  }
  addToSelected(pill:String){
    if (this.pillsSelected.length == 0) {
      this.pillsSelected.push(pill);
      this.sendData.emit(this.pillsSelected);
      this.updateData.emit(this.pillsSelected);
    }else{
      if (this.pillsSelected.includes(pill)) {
        this.pillsSelected.splice(this.pillsSelected.indexOf(pill), 1);
        this.sendData.emit(this.pillsSelected);
        this.updateData.emit(this.pillsSelected);
      }else{
        this.pillsSelected.push(pill);
        this.sendData.emit(this.pillsSelected);
        this.updateData.emit(this.pillsSelected);
      }
    }
  }
  emitSelector(){
    this.updateData.emit(this.optionSelected);
    this.sendData.emit(this.optionSelected)
  }

}
