<PageTemplate>
  <ng-container bodyContent>
    <header [searcherActive]="false" [buttonActive]="true" [buttonAction]="'Crear nuevo recinto'" [permiso]="permiso">
    </header>
    <searcher [size]="'fullWidth'" [placeHolder]="'Busca aquí un recinto'" [toFilter]="facilitites_backup"
      [type]="'facility'" (filtered)="filteredFacilities($event)"></searcher>
    <section class="places">
      <mat-spinner class="big center" *ngIf="!dataIsLoaded"></mat-spinner>
      <ng-container *ngIf="dataIsLoaded">
        <p class="noData" *ngIf="facilities.length == 0">No hay recintos disponibles</p>
        <card [data]="facility" [type]="'facilityCard'" [deleteActive]="true" *ngFor="let facility of facilities"
          (confirmDelete)="deleteFacility($event, facility.id);"
          [routerLink]="['/publisher/facilities/facility-details/'+facility.id]"></card>
      </ng-container>
    </section>
  </ng-container>
</PageTemplate>