<div class="addNewPrContainer">
  <div class="header">
    <svg (click)="closePopup()" xmlns="http://www.w3.org/2000/svg" width="20.242" height="20.242"
      viewBox="0 0 20.242 20.242">
      <g id="Grupo_1201" data-name="Grupo 1201" transform="translate(-5674.379 -12806.379)">
        <line id="Línea_323" data-name="Línea 323" x2="16" y2="16" transform="translate(5676.5 12808.5)" fill="none"
          stroke="#0e17b6" stroke-linecap="round" stroke-width="3" />
        <line id="Línea_324" data-name="Línea 324" x1="16" y2="16" transform="translate(5676.5 12808.5)" fill="none"
          stroke="#0e17b6" stroke-linecap="round" stroke-width="3" />
      </g>
    </svg>
  </div>
  <div class="body">
    <div class="dataContainer selector">
      <label for="">Centro</label>
      <ng-select [items]="centros" [(ngModel)]="centro" [searchable]="false" bindLabel="name" bindValue="id"
        [placeholder]="'- A rellenar -'" (change)="checkData('centers', centro)" [clearable]="false"></ng-select>
    </div>
    <div class="doubleColumn">
      <div class="dataContainer">
        <label for="" [class.vacio]="nombreVacio">Nombre</label>
        <input type="text" [(ngModel)]="nombre" (change)="checkData('name', nombre)" class="inputData"
          placeholder="Nombre">
      </div>
      <div class="dataContainer">
        <label for="" [class.vacio]="apellidosVacio">Apellidos</label>
        <input type="text" [(ngModel)]="apellidos" (change)="checkData('surname', apellidos)" class="inputData"
          placeholder="Apellidos">
      </div>
    </div>
    <div class="doubleColumn">
      <div class="dataContainer">
        <label for="" [class.vacio]="telefonoVacio">Teléfono</label>
        <input type="number" [(ngModel)]="telefono" (change)="checkData('phone', telefono)" class="inputData"
          placeholder="Teléfono">
      </div>
      <div class="dataContainer">
        <label for="" [class.vacio]="fechaNacimientoVacio">Fecha de nacimiento</label>
        <div>
          <input matInput [matDatepicker]="picker" readOnly="true" [value]="fechaNacimiento"
            (dateChange)="changeDate($event)" placeholder="Fecha de nacimiento" class="inputData datePicker">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker class="miPerfil-movil" #picker></mat-datepicker>
        </div>
      </div>
    </div>
    <div class="doubleColumn">
      <div class="dataContainer">
        <label for="" [class.vacio]="emailVacio">Email</label>
        <input type="email" [(ngModel)]="email" class="inputData" (change)="checkData('email', email)" placeholder="Email">
      </div>
      <div class="dataContainer">
        <label for="" [class.vacio]="dniVacio">DNI</label>
        <input type="email" [(ngModel)]="dni" class="inputData" (change)="checkData('dni', dni)" placeholder="DNI">
      </div>
    </div>

  </div>
  <div class="footer">
    <button class="btn-sm" (click)="closePopup()">Finalizar</button>
  </div>
</div>