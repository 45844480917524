<div class="loginGeneralContainer">
  <img src="/assets/icons/logoLogin.png" alt="Logo login memorable">
  <div class="loginContainer">
    <form action="">

      <div class="dataContainer">
        <p>{{mensaje}}</p>
      </div>
    </form>
  </div>
</div>