import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'app-CustomDatesPopup',
  templateUrl: './CustomDatesPopup.component.html',
  styleUrls: ['./CustomDatesPopup.component.scss']
})
export class CustomDatesPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CustomDatesPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private notificaciones: NotificacionesService) { }
  fechaInicio: String = ""
  fechaFin: String = ""
  ngOnInit() {
  }
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
  getFechaInicio(event){
    this.fechaInicio = event;
  }
  getFechaFin(event){
    this.fechaFin = event;
  }
  validarFechas(){
    if (this.fechaInicio == "" || this.fechaFin == "") {
      this.notificaciones.showError("Rango de fechas no valido")
    }else{
      this.closePopup({accionHecha: true, fechaInicio: this.fechaInicio, fechaFin: this.fechaFin})
    }
  }
}
