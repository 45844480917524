<PageTemplate>
  <ng-container bodyContent>
    <header [searcherActive]="false" [buttonActive]="true" [buttonAction]="'Crear nuevo evento'" [permiso]="permiso"></header>
    <div class="managementEventGeneralContainer">
      <ManagementInfoCard 
        [label]="'Gestión'" 
        [title]="'Listado eventos'"
        [description]="'Accede a la colección de tus últimos eventos'" 
        [link]="'/publisher/management/events/my-events'"
        [icon]="'/assets/icons/eventos_sec.svg'">
      </ManagementInfoCard>
      <ManagementInfoCard [permiso]="permiso"
        [label]="'Recepción y ventas'" 
        [title]="'Taquilla y escáner'"
        [description]="'Ventas de entradas desde la plataforma MMB.'" 
        [link]="'/publisher/management/events/today-events'"
        [icon]="'/assets/icons/taquilla.svg'">
      </ManagementInfoCard>
      <ManagementInfoCard 
        [label]="'Historial y archivo'" 
        [title]="'Historial de eventos'"
        [description]="'Archivo de eventos.'" 
        [link]="'/publisher/management/events/event-history'"
        [icon]="'/assets/icons/clock_sec.svg'">
      </ManagementInfoCard>
    </div>
  </ng-container>
</PageTemplate>