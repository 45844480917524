import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'SwitchOptions',
  templateUrl: './SwitchOptions.component.html',
  styleUrls: ['./SwitchOptions.component.scss']
})
export class SwitchOptionsComponent implements OnInit {

  @Input() elements: Array<any> = []
  @Input() elementActive: Number = 0
  @Output() optionSelected = new EventEmitter<Number>();

  constructor() { }

  ngOnInit() {
  }
  emitValue(){
    this.optionSelected.emit(this.elementActive)
  }
}
