<div class="prLinkGeneralContainer">
  <div class="prLinkHeader">
    <img src="/assets/icons/logoLinkPr.png" alt="">
  </div>
  <div class="prMensajeBienvenida">
    <p>Bienvenido a Memorable,</p>
    <p>Completa este proceso para registrarte como relaciones públicas</p>
    <a class="iniciado"  (click)="goToLogin()" routerLinkActive="router-link-active" >¿Ya tienes cuenta? Pulsa aquí</a>
  </div>
  <div class="prFormGeneralContainer" [class.paso1]="paso == 1" [class.paso2]="paso == 2">
    <div *ngIf="paso == 0 ||paso == 1">
      <ng-container *ngIf="paso == 0">
        <div class="dataContainer">
          <label for="" [class.vacio]="nombreVacio">Nombre</label>
          <input type="text" [(ngModel)]="nombre" class="inputData" placeholder="Nombre">
        </div>
        <div class="dataContainer">
          <label for="" [class.vacio]="apellidosVacio">Apellidos</label>
          <input type="text" [(ngModel)]="apellidos" class="inputData" placeholder="Apellidos">
        </div>
        <div class="dataContainer">
          <label for="" [class.vacio]="fechaNacimientoVacio">Fecha de nacimiento</label>
          <div>
            <input matInput [matDatepicker]="picker" (dateChange)="changeDate($event)"  readOnly="true" placeholder="Fecha de nacimiento" class="inputData datePicker">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker  class="miPerfil-movil" #picker></mat-datepicker>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="paso == 1">
        <div class="passContainer">
          <div class="dataContainer">
            <label for="" [class.vacio]="passwdVacio">Establece una contraseña para tu nuevo perfil</label>
            <input type="password" [(ngModel)]="passwd" name="password" class="inputData"
              placeholder="Contraseña">
          </div>
          <div class="dataContainer">
            <label for="" [class.vacio]="repeatPasswdVacio">Repite la contraseña</label>
            <input type="password" [(ngModel)]="repeatPasswd" class="inputData" placeholder="Repite la contraseña">
          </div>
        </div>
        <div class="stregthPath">
          <div class="dataContainer">
            <label for="" *ngIf="passwordStrength <= 20">Seguridad baja</label>
            <label for="" *ngIf="passwordStrength >= 40 && passwordStrength <= 80">Seguridad media</label>
            <label for="" *ngIf="passwordStrength == 100">Seguridad alta</label>
            <mat-password-strength class="passwordStrength" [password]="passwd" (onStrengthChanged)="onStrengthChanged($event)">
            </mat-password-strength>
          </div>

        </div>

      </ng-container>
    </div>
    <div *ngIf="paso == 0 ||paso == 1">
      <ng-container *ngIf="paso == 0">
        <div class="dataContainer">
          <label for="" [class.vacio]="numeroTelefonoVacio">Número de teléfono</label>
          <input type="number"[(ngModel)]="numeroTelefono" class="inputData" placeholder="Número de teléfono">
        </div>
        <div class="dataContainer">
          <label for=""[class.vacio]="emailVacio">Correo electrónico</label>
          <input type="email" [(ngModel)]="email" class="inputData" placeholder="Correo electrónico">
        </div>
        <div class="dataContainer">
          <label for="" [class.vacio]="dniVacio">DNI</label>
          <input type="text" [(ngModel)]="dni" class="inputData" placeholder="DNI">
        </div>
        <div class="dataContainer">
          <label for="" [class.vacio]="localidadVacio">Localidad</label>
          <input type="text" [(ngModel)]="localidad" class="inputData" placeholder="Localidad">
        </div>

      </ng-container>

    </div>

    <ng-container *ngIf="paso == 2">
      <p>¡Revisa tu correo electrónico para verificar tu cuenta!</p>
    </ng-container>
  </div>
  <div class="prFooter">
    <button  *ngIf="paso < 2" (click)="restarPaso()" [class.disabled]="paso == 0">Atrás</button>
    <button *ngIf="paso == 0"(click)="validateData()">Siguiente</button>
    <button *ngIf="paso == 1"(click)="validateData()">Finalizar</button>
    <button (click)="close()" *ngIf="paso == 2">Volver al login</button>
  </div>
</div>
