<div class="edicionMailGeneralContainer">
  <GeneralButton [buttonText]="'Lanzar campaña de prueba'" (click)="sendTestCampaign()"></GeneralButton>
  <FormElement [receiveData]="asuntoMail" [error]="asuntoMailError" [type]="'input'"
    [placeholder]="'Nombre de la campaña'" [label]="'Título del mensaje'" (sendData)="getAsunto($event)"
    (updateData)="updateAsunto($event)"></FormElement>
  <FormElement [receiveData]="cuerpo" class="pushMensaje" [error]="asuntoMailError" [type]="'textArea'"
    [placeholder]="'Mensaje de la campaña'" [label]="'Mensaje de la campaña'" (sendData)="getCuerpo($event)"
    (updateData)="updateCuerpo($event)"></FormElement>
  <FormElement [receiveData]="url" [error]="asuntoMailError" [type]="'input'" [placeholder]="'URL de la campaña'"
    [label]="'URL de la campaña'" (sendData)="getUrl($event)" (updateData)="updateUrl($event)"></FormElement>

</div>