import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { OrganizerCompany } from 'src/app/models/OrganizerCompany';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { ConfirmPopupComponent } from 'src/app/popups/confirmPopup/confirmPopup.component';
import { EventService } from 'src/app/services/Api/event.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PopupService } from 'src/app/services/utils/popup.service';
import { CreateEditEventStep1Component } from './Holders/CreateEditEvent-Step1/CreateEditEvent-Step1.component';
import { CreateEditEventStep2Component } from './Holders/CreateEditEvent-Step2/CreateEditEvent-Step2.component';
import { CreateEditEventStep3Component } from './Holders/CreateEditEvent-Step3/CreateEditEvent-Step3.component';
import { CreateEditEventStep4Component } from './Holders/CreateEditEvent-Step4/CreateEditEvent-Step4.component';
import { CreateEditEventStep5Component } from './Holders/CreateEditEvent-Step5/CreateEditEvent-Step5.component';
import { CreateEditEventStep6Component } from './Holders/CreateEditEvent-Step6/CreateEditEvent-Step6.component';

@Component({
  selector: 'app-CreateEditEvents',
  templateUrl: './CreateEditEvents.component.html',
  styleUrls: ['./CreateEditEvents.component.scss']
})
export class CreateEditEventsComponent extends UnsubscribesDestroy implements OnInit {

  @ViewChild(CreateEditEventStep1Component) step1!: CreateEditEventStep1Component;
  @ViewChild(CreateEditEventStep2Component) step2!: CreateEditEventStep2Component;
  @ViewChild(CreateEditEventStep3Component) step3!: CreateEditEventStep3Component;
  @ViewChild(CreateEditEventStep4Component) step4!: CreateEditEventStep4Component;
  @ViewChild(CreateEditEventStep5Component) step5!: CreateEditEventStep5Component;
  @ViewChild(CreateEditEventStep6Component) step6!: CreateEditEventStep6Component;

  editar: Boolean = false;
  event: any = null;
  pasos: Array<String> = ["Información básica", "Fecha y hora", "Entradas y listas", "Recinto", "Información promocional"]
  paso: number = 0
  dataPaso1: any = null;
  validStep1: Boolean = false;
  dataPaso2: any = null;
  validStep2: Boolean = false;
  dataPaso3: any = null;
  validStep3: Boolean = false;
  dataPaso4: any = null;
  validStep4: Boolean = false;
  dataPaso5: any = null;
  validStep5: Boolean = true;
  dataPaso6: any = null;
  validStep6: Boolean = true;
  constructor(private loginSE: LoginService, private popupSE: PopupService, private router: Router, private notificaciones: NotificacionesService, private eventSE: EventService, private loader: LoaderService, private route: ActivatedRoute,
  ) {
    super()
  }

  ngOnInit() {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      this.checkPermisos();

    }
  }
  //FUNCIONALIDADES
  cambiarPaso(event) {
  }
  cancel() {
    if (this.dataPaso1 != null || this.dataPaso2 != null || this.dataPaso3 != null || this.dataPaso4 != null || this.dataPaso5 != null || this.dataPaso6 != null) {
      this.popupSE.openPopup(ConfirmPopupComponent, { accion: "Descartar", tipo: "cambios" })
      this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe(value => {
        if (value == null) { return }
        this.router.navigate(["/publisher/management/events/my-events"])
        this._unsubInd.next("");
      })
    } else {
      this.router.navigate(["/publisher/management/events/my-events"])
    }
  }
  test(event) {
    this.getEvent();
  }
  //LLAMADAS A LA API
  crearEvento() {
    var evento = {
      title: this.dataPaso1.title,
      description: this.dataPaso1.description,
      categories: this.dataPaso1.categories,
      company: Number(this.dataPaso1.company),
      isPublic: this.dataPaso1.isPublic,
      dressCode: this.dataPaso1.dressCode,
      ageRange: this.dataPaso1.ageRange,
      conditions: this.dataPaso1.conditions,
      permission: { pdf: null },
      startDate: this.dataPaso2.startDate,
      discountRrpp: this.dataPaso3.discountRrpp,
      endDate: this.dataPaso2.endDate,
      startTime: this.dataPaso2.startTime,
      finishTime: this.dataPaso2.finishTime,
      isActive: true,
      isMultipleDays: this.dataPaso2.isMultipleDays,
      facility: this.dataPaso4,
      tickets: this.dataPaso3.tickets,
      iva: this.dataPaso3.iva,
      ticketChangeName: this.dataPaso3.ticketChangeName,
      list: [],
      transport: this.dataPaso5,
      images: [
        {
          image: this.dataPaso6[0],
          isPrincipal: true
        }
      ]
    }
    if (this.dataPaso1.organizerCompany != null) {
      if (this.dataPaso1.organizerCompany.id != null) {
        var auxCompany: any = {
          company: this.dataPaso1.organizerCompany.companyId,
          cif: this.dataPaso1.organizerCompany.cif,
          tradeName: this.dataPaso1.organizerCompany.tradeName,
          addressId: this.dataPaso1.organizerCompany.addressId,
          id: this.dataPaso1.organizerCompany.id
        }
        evento["organizerCompany"] = auxCompany
      } else {
        var auxCompany: any = {
          company: this.dataPaso1.organizerCompany.companyId,
          cif: this.dataPaso1.organizerCompany.cif,
          tradeName: this.dataPaso1.organizerCompany.tradeName,
          zipCode: this.dataPaso1.organizerCompany.zipCode,
          address: this.dataPaso1.organizerCompany.address
        }
        evento["organizerCompany"] = auxCompany
      }
    }

    if (evento.transport == null) {
      evento.transport = []
    } else {
      evento.transport.forEach(element => {
        element["pickUpDate"] = new Date(evento.startDate).getFullYear() + "-" + (new Date(evento.startDate).getMonth() + 1) + "-" + new Date(evento.startDate).getDate();
        element["arrivalDate"] = new Date(evento.endDate).getFullYear() + "-" + (new Date(evento.endDate).getMonth() + 1) + "-" + new Date(evento.endDate).getDate();
      });
    }

    if (this.dataPaso1.permission.pdf != null) {
      evento.permission = { pdf: this.dataPaso1.permission.pdf.base64 }
    }
    this.eventSE.addNewEvent(evento);
    this.loader.open();
    this.eventSE.getResult().pipe(takeUntil(this._unsubInd3)).subscribe(value => {
      if (value == null) { return }
      this.loader.closeSlow();
      this.notificaciones.showFeedBack(value.message)
      setTimeout(() => {
        this.router.navigate(["/publisher/management/events/my-events/"])
      }, 500);
      this._unsubInd3.next("");
    });
    this.eventSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.loader.closeSlow();
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    });
  }
  getEvent() {
    this.eventSE.getEventById(this.route.snapshot.params['id']);
    this.eventSE.getResult().pipe(takeUntil(this._unsubInd6)).subscribe(value => {
      if (value == null) { return }
      this.event = value;
      this.setDataToAllSteps();
      this._unsubInd6.next("");
    })
    this.eventSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.notificaciones.showError(value.error.message);
      this._unsub.next("");
    })
  }
  editarEvento(event) {
    var evento = {}
    evento[event.element] = event.value
    if (event.element == "tickets") {
      evento["company"] = Number(sessionStorage.getItem("dcmpny"))
    }
    if (event instanceof Array) {
      event.map(element => {
        if (element.element.includes("Date")) {
          evento[element.element] = element.value
        }
      })
    }
    this.eventSE.updateEventById(this.route.snapshot.params['id'], evento);
    this.eventSE.getResultUpdate().pipe(takeUntil(this._unsubInd4)).subscribe(value => {
      if (value == null) { return; }
      this.notificaciones.showFeedBack(value.message);
      this.getEvent();
      this._unsubInd4.next("")
    })
    this.eventSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return; }
      this.notificaciones.showError(value.error.message);
      this._unsub.next("")
    })
  }
  //VALIDACIONES
  validateStep() {
    switch (this.paso) {
      case 0:
        this.step1.validarPaso1();
        if (this.validStep1) {
          this.paso = this.paso + 1;
        }
        if (this.editar) {
          this.getEvent();
        }
        break;
      case 1:
        this.step2.validarPaso2();
        if (this.validStep2) {
          this.paso = this.paso + 1;
        }
        break;
      case 2:
        this.step3.validarPaso3();
        if (this.validStep3) {
          this.paso = this.paso + 1;
        }
        break;
      case 3:
        this.step4.validarPaso4();
        if (this.validStep4) {
          this.paso = this.paso + 1;
        }
        break;
      case 4:
        if (this.validStep5) {
          this.paso = this.paso + 1;
        } else {
          this.notificaciones.showError("Debes completar el formulario antes de continuar")
        }
        break;
    }
  }

  isValidStep1(valid: Boolean) {
    this.validStep1 = valid
  }
  isValidStep2(valid: Boolean) {
    this.validStep2 = valid
  }
  isValidStep3(valid: Boolean) {
    this.validStep3 = valid
  }
  isValidStep4(valid: Boolean) {
    this.validStep4 = valid
  }
  isValidStep5(valid: Boolean) {
    this.validStep5 = valid
  }
  isValidStep6(valid: Boolean) {
    this.validStep6 = valid
  }

  //GETTERS & SETTERS
  getDataFromStep1(event) {
    this.dataPaso1 = event;
  }
  getDataFromStep2(event) {
    this.dataPaso2 = event;
  }
  getDataFromStep3(event) {
    this.dataPaso3 = event;
  }
  getDataFromStep4(event) {
    this.dataPaso4 = event;
  }
  getDataFromStep5(event) {
    this.dataPaso5 = event;
  }
  getDataFromStep6(event) {
    this.dataPaso6 = event;
  }
  setDataToStep1() {
    this.dataPaso1 = {
      title: this.event.title,
      description: this.event.description,
      categories: this.event.categories,
      dressCode: this.event.dress_code,
      ageRange: this.event.age_range,
      conditions: this.event.conditions,
      organizerCompany: null,
      permission: {
        pdf: this.event.parental_permission
      },
      isPublic: this.event.is_public
    }
    if (this.event.organizer_company.length != 0) {
      var company: OrganizerCompany = {
        cif: this.event.organizer_company[0].cif,
        companyId: Number(sessionStorage.getItem("dcmpny")),
        tradeName: this.event.organizer_company[0].trade_name,
        id: this.event.organizer_company[0].id,
        address: this.event.organizer_company[0].address,
      };
      this.dataPaso1.organizerCompany = company
    }
  }
  setDataToStep2() {
    this.dataPaso2 = {
      startDate: this.event.start_date.split("T")[0],
      endDate: this.event.end_date.split("T")[0],
      startTime: this.event.start_time.split("T")[1].split("+")[0],
      finishTime: this.event.finish_time.split("T")[1].split("+")[0],
      isMultipleDays: this.event.is_multiple_days
    }
  }
  setDataToStep3() {
    this.dataPaso3 = {
      tickets: [],
      iva: this.event.iva,
      ticketChangeName: null,
      discount_rrpp: this.event.discount_rrpp,
    }
    this.event.ticket_change_name != null ? this.dataPaso3.ticketChangeName = this.event.ticket_change_name.product.fee : null;
    this.event.tickets.forEach(element => {
      var entrada = {
        idEntrada: element.id,
        idProducto: element.product.id,
        name: element.product.name,
        description: element.product.description,
        stock: element.product.stock,
        fee: element.product.fee,
        iva: element.product.iva,
        administrativeExpenses: element.product.administrative_expenses,
        company: sessionStorage.getItem("dcmpny"),
        taxType: element.product.comission_type,
        startDate: element.start_date,
        finishDate: element.finish_date,
        startTime: element.start_time,
        finishTime: element.finish_time,
        isActive: element.product.is_active,
        comission: element.product.comission,
        sales: element.product.sales,
        showStock: element.show_stock
      }
      if (element.is_preorder != null && element.is_preorder) {
        entrada["isPreorder"] = true;
      } else {
        entrada["isPreorder"] = false;

      }
      this.dataPaso3.tickets.push(entrada)
    });
  }
  setDataToStep4() {
    this.dataPaso4 = this.event.facility
  }
  setDataToStep5() {
    this.dataPaso5 = []
    this.event.transports.forEach(element => {
      var transporte = {
        id: element.id,
        name: element.name,
        fee: element.fee,
        capacity: element.capacity,
        pickUpTime: element.pick_up_date.split("T")[1].split("+")[0],
        arrivalTime: element.arrival_date.split("T")[1].split("+")[0],
        pickUpDate: element.pick_up_date,
        arrivalDate: element.arrival_date,
        address: {
          street: element.address.street,
          number: Number(element.address.number),
          locality: element.address.locality,
          province: element.address.province
        },
        company: element.company,
        vehicleType: element.vehicle_type,
        vehiclesQuantity: element.vehicles_quantity
      }
      this.dataPaso5.push(transporte);
    });
  }
  setDataToStep6() {
    this.event.event_images.forEach(element => {
      if (element.is_principal) {
        this.dataPaso6 = element.image.url

      }
    });
  }
  setDataToAllSteps() {
    this.setDataToStep1();
    this.setDataToStep2();
    this.setDataToStep3();
    this.setDataToStep4();
    this.setDataToStep5();
    this.setDataToStep6();
  }
  checkPermisos() {
    if (localStorage.getItem("tipoUser") == null || localStorage.getItem("tipoUser") == undefined || localStorage.getItem("tipoUser") == '["2"]') {
      this.loginSE.logOut();
      this.notificaciones.showError("No tienes permisos para acceder")
    } else {
      if (localStorage.getItem("tipoUser") == '["0"]' || localStorage.getItem("tipoUser") == '["3"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY") && !localStorage.getItem("permisos")?.includes("CONSULT_COMPANY_GROUP")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY") && localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY_GROUP")) {
          this.router.navigate(["/publisher/management/events/my-events"])
          this.notificaciones.showError("No tienes permisos para acceder")
        } else {
          if (this.route.snapshot.params['id'] != null || this.route.snapshot.params['id'] != undefined) {
            this.editar = true;

            this.getEvent();
          }
        }
      }
      if (localStorage.getItem("tipoUser") == '["1"]') {

        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY")) {
          this.notificaciones.showError("No tienes permisos para acceder")
          this.router.navigate(["/publisher/management/events/my-events"])
        } else {
          if (this.route.snapshot.params['id'] != null || this.route.snapshot.params['id'] != undefined) {
            this.editar = true;
            this.getEvent();
          }
        }
      }
    }


  }
}
