import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss'],
})
export class StepsComponent implements OnInit {
  yaActivo:any = []
  @Input() pasos: any = [];
  @Input () tipo:string = "";
  @Input() activo: number = 0;
  @Input() navegacionActiva: boolean = false;
  _activo = 0;
  @Output() activoChange = new EventEmitter<number>();
  @Output() cambio = new EventEmitter<number>();
  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['activo'] !== undefined) {
      if (changes['activo'].currentValue < this.pasos.length) {
        this._activo = changes['activo'].currentValue;
          this.yaActivo.push(this._activo)
      }
    }
    // You can also use categoryId.previousValue and
    // categoryId.firstChange for comparing old and new values
  }

  ngOnInit() {}

  pulsado(i: number) {
    
    if (this.navegacionActiva) {
      this._activo = i;
      this.cambio.emit(this._activo);
      this.activoChange.emit(this._activo);
    }else{
      if (i < this._activo) {
        this._activo = i;
        this.cambio.emit(this._activo);
        this.activoChange.emit(this._activo);
      }
    }
   
  }
  setYaClickado(i:number){
    this.yaActivo.push(i)
  }
}
