<div class="generalContainer">
  <div class="subContainer">
    <div>
      <sidebar></sidebar>
    </div>
    <div class="master-container">
      <div class="promotions-page">
        <header class="nav-section">
          <nav class="navbar">
            <div class="breadcrumbs">
              <ul>
                <li>
                  <a routerLink="../home" aria-label="Inicio"
                    ><img src="/assets/icons/house-solid.svg" alt="" />
                  </a>
                </li>
                <li><a routerLink=".">Comunidades y promoción</a></li>
              </ul>
            </div>
            <div class="nav-options">
              <div class="icons">
                <a routerLink="." aria-label="Ayuda" class="nav-icon"
                  ><img src="/assets/icons/questionMark.svg" alt=""
                /></a>
                <a routerLink="." aria-label="Mi usuario" class="nav-icon">
                  <img src="/assets/icons/user.svg" alt=""
                /></a>
              </div>
            </div>
          </nav>
          <section class="options">
            <div class="left-group">
              <a class="btn-xs" routerLink="../home">Atrás</a>
            </div>
            <div class="right-group" *ngIf="false">
              <!--<a class="btn-sm" href="#">Crear nueva comunidad</a>-->
              <a class="btn-sm" routerLink="./campaigns/add-campaign"
                >Crear nueva campaña</a
              >
            </div>
          </section>
        </header>

        <main>
          <section class="pill-group">
            <h3>Comunidades</h3>
            <ul>
              <li [class.noPermision]="!permiso">
                <app-menu-item
                  enlace="./communities"
                  titulo="Mi comunidad MMB"
                  icono="/assets/icons/comunidad.svg"
                ></app-menu-item>
              </li>
            </ul>
          </section>

          <section class="pill-group">
            <h3>Marketing</h3>
            <ul>
              <li *ngIf="false">
                <app-menu-item
                  enlace="./campaigns"
                  titulo="Campañas"
                  icono="/assets/icons/campañas.svg"
                ></app-menu-item>
              </li>
              <li>
                <app-menu-item
                  enlace="./customer-database"
                  titulo="CRM"
                  icono="/assets/icons/database.svg"
                ></app-menu-item>
              </li>
            </ul>
          </section>
        </main>
      </div>
    </div>
  </div>
</div>
