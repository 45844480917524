<div class="homeGeneralContainer">
  <div class="navbarGeneralContainer">
    <sidebar></sidebar>
  </div>
  <div class="bodyGeneralContainer">
    <div class="headerContainer">
      <header [searcherActive]="false"></header>
      <searcher [type]="'all'"></searcher>
    </div>
    <dateSteps [steps]="dateSteps" (stepSelected)="changeStep($event)"></dateSteps>
    <div class="infoContainer">
      <Info [type]="'Ventas'" [loaded]="isDataLoaded" [currency]="true" [value]="estadisticas.ventas"></Info>
      <Info [type]="'Eventos entradas vendidas'" [loaded]="isDataLoaded" [value]="estadisticas.entradas_vendidas"
        *ngIf="estadisticas.entradas_vendidas != null"></Info>
      <Info [type]="'Eventos entradas vendidas'" [loaded]="isDataLoaded" [value]="0"
        *ngIf="estadisticas.entradas_vendidas == null"></Info>
      <Info [type]="'Eventos totales esta semana'" [loaded]="isDataLoaded" [value]="estadisticas.event_week"></Info>
      <Info [type]="'Relaciones públicas esta semana'" [loaded]="isDataLoaded" [value]="estadisticas.new_pr"></Info>
    </div>
    <div class="eventContainer">
      <div class="calendar">
        <app-events-calendar class="misEventosCalendar" [eventos]="events" (cambioDeMes)="cambiarMes($event)" (fechaSeleccionada)="clickFecha($event)">
        </app-events-calendar>
        <mat-spinner *ngIf="!eventsLoaded" class="big"></mat-spinner>
      </div>
      <div class="eventList">
        <h3>{{ fecha | date: 'longDate' }}</h3>



        <div class="events" [class.loaded]="!isDataLoaded">
          <p class="noData" *ngIf="eventosSeleccionados.length == 0 && isDataLoaded">
            No hay eventos en esta fecha
          </p>
          <mat-spinner class="big" *ngIf="!isDataLoaded"></mat-spinner>
          <ul [class.hidden]="eventosSeleccionados.length == 0 && !isDataLoaded">
            <li *ngFor="let evento of eventosSeleccionados">
              <card [type]="'carrouselCard'" [data]="evento"></card>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="graficaContainer">
      <div class="graficaHeader">
        <toggleMenu [data]="toggleElements" (toggleMenu)="changeToggle($event)"></toggleMenu>
        <ng-select [items]="events" [(ngModel)]="eventSelected" (change)="changeEvent()" bindLabel="title"
          bindValue="id" [searchable]="false" [clearable]="true" [placeholder]="'Selecciona un evento'"></ng-select>
      </div>
      <div class="graficaBody" [class.loaded]="!dataIsLoadedAuxiliar">
        <mat-spinner class="big" *ngIf="!dataIsLoadedAuxiliar"></mat-spinner>
        <SalesChart *ngIf="selectedStep == 0 && dataIsLoadedAuxiliar && tipo=='ventas'" [type]="'weekly'"
          [data]="datosGrafica"></SalesChart>
        <SalesChart *ngIf="selectedStep == 1 && dataIsLoadedAuxiliar  && tipo=='ventas'" [type]="'biWeekly'"
          [data]="datosGrafica"></SalesChart>
        <SalesChart *ngIf="selectedStep == 2 && dataIsLoadedAuxiliar  && tipo=='ventas'" [type]="'monthly'"
          [data]="datosGrafica"></SalesChart>
        <SalesChart *ngIf="selectedStep == 3 && dataIsLoadedAuxiliar  && tipo=='ventas'" [type]="'anual'"
          [data]="datosGrafica"></SalesChart>
        <SalesChart *ngIf="selectedStep == 4 && dataIsLoadedAuxiliar  && tipo=='ventas'"
          [dates]="{inicio:inicio, fin: fin}" [type]="'custom'" [data]="datosGrafica"></SalesChart>

        <UnitsChart *ngIf="selectedStep == 0 && dataIsLoadedAuxiliar  && tipo=='unidades'" [type]="'weekly'"
          [data]="datosGrafica"></UnitsChart>
        <UnitsChart *ngIf="selectedStep == 1 && dataIsLoadedAuxiliar  && tipo=='unidades'" [type]="'biWeekly'"
          [data]="datosGrafica"></UnitsChart>
        <UnitsChart *ngIf="selectedStep == 2 && dataIsLoadedAuxiliar  && tipo=='unidades'" [type]="'monthly'"
          [data]="datosGrafica"></UnitsChart>
        <UnitsChart *ngIf="selectedStep == 3 && dataIsLoadedAuxiliar  && tipo=='unidades'" [type]="'anual'"
          [data]="datosGrafica"></UnitsChart>
        <UnitsChart *ngIf="selectedStep == 4 && dataIsLoadedAuxiliar  && tipo=='unidades'"
          [dates]="{inicio:inicio, fin: fin}" [type]="'custom'" [data]="datosGrafica"></UnitsChart>
      </div>
    </div>
    <div class="eventsContainer">
      <p class="noData" *ngIf="events.length == 0">No hay eventos disponibles</p>
      <card [data]="event" [type]="'eventCard'" *ngFor="let event of events |slice:0:3"></card>
    </div>
  </div>
</div>