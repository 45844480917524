import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { Fecha } from 'src/app/models/Fecha';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { EventService } from 'src/app/services/Api/event.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'app-ValidateBuyTickets',
  templateUrl: './ValidateBuyTickets-SelectEvent.component.html',
  styleUrls: ['./ValidateBuyTickets-SelectEvent.component.scss']
})
export class ValidateBuyTicketsSelectEventComponent extends UnsubscribesDestroy implements OnInit {
  today:Date = new Date();
  hoy:Fecha = new Fecha();
  eventosHoy:Array<any> = []
  redirect: Boolean = false;
  eventoSeleccionado:any = null;
  dataLoaded: Boolean = false;
  constructor(private loader:LoaderService, private notificaciones: NotificacionesService, private eventSE: EventService) {
    super();
  }

  ngOnInit() {
    this.hoy.dia = this.today.getDate();
    this.hoy.mes = this.today.getMonth()+1;
    this.hoy.year = this.today.getFullYear();
    this.getEventosHoy();

  }
  getEventosHoy(){
    var fecha = this.hoy.year+"-"+this.hoy.mes+"-"+this.hoy.dia;
    this.eventSE.getEventByDate(fecha);
    this.eventSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return };
      this.eventosHoy = value;
      this.dataLoaded = true;
      this._unsubInd.next("");
    })
    this.eventSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return };
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    })
  }
  
}
