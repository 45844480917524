import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { sha256 } from 'js-sha256';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { LoginService } from 'src/app/services/Api/login.service';
import { PrService } from 'src/app/services/Api/pr.service';
import { UserService } from 'src/app/services/Api/user.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'app-pr-link',
  templateUrl: './pr-link.component.html',
  styleUrls: ['./pr-link.component.scss']
})
export class PrLinkComponent extends UnsubscribesDestroy implements OnInit {
  fechaNacimiento = ""
  passwd = ""
  repeatPasswd = ""
  nombre = ""
  apellidos = ""
  localidad = ""
  numeroTelefono = ""
  email = ""
  dni = ""

  fechaNacimientoVacio: boolean = false;
  passwdVacio: boolean = false
  repeatPasswdVacio: boolean = false
  nombreVacio: boolean = false
  apellidosVacio: boolean = false
  localidadVacio: boolean = false
  numeroTelefonoVacio: boolean = false
  emailVacio: boolean = false
  dniVacio: boolean  = false;

  paso = 0;
  passwordStrength = 0;

  invitacion: boolean = false;
  manager: number = 0;
  company:  number = 0
  id: number = 0;
  constructor(private router: Router,
    private notificaciones: NotificacionesService,
    private loginSE: LoginService,
    private prSE: PrService,
    private loader: LoaderService, private userSE: UserService, private activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe(params => {
      this.invitacion = true;
      this.manager = Number(params["params"].manager)
      this.company = Number(params["params"].dcmpny)
    })
  }

  aumentarPaso() {
    this.paso++;
  }
  restarPaso() {
    if (this.paso >= 0) {
      this.paso--;
    }
  }
  onStrengthChanged(event: any) {
    this.passwordStrength = event;
  }
  validDni(dni) {
    var filter = /^[0-9]{8,8}[A-Za-z]$/g;
    return String(dni).search(filter) != -1;
  }
  validateData() {
    switch (this.paso) {
      case 0:
        if (this.nombre == "") {
          this.nombreVacio = true;
        } else {
          this.nombreVacio = false;
        }

        if (this.numeroTelefono == "") {
          this.numeroTelefonoVacio = true;
        } else {
          this.numeroTelefonoVacio = false;
        }
        if (this.apellidos == "") {
          this.apellidosVacio = true;
        } else {
          this.apellidosVacio = false;
        }
        if (this.localidad == "") {
          this.localidadVacio = true;
        } else {
          this.localidadVacio = false;
        }
        if (this.email == "") {
          this.emailVacio = true;
        } else {
          this.emailVacio = false;
        }
        if (this.dni == "") {
          this.dniVacio = true;
        } else {
          this.dniVacio = false;
        }
        if (this.fechaNacimiento == "") {
          this.fechaNacimientoVacio = true;
        } else {
          this.fechaNacimientoVacio = false;
        }

        if (!this.nombreVacio && !this.fechaNacimientoVacio && !this.localidadVacio && !this.apellidosVacio && !this.numeroTelefonoVacio && !this.emailVacio  && !this.dniVacio) {
          if (!this.validEmail(this.email)) {
            this.notificaciones.showError("El correo electrónico no tiene un formato válido")
            this.emailVacio = true;
          } else {
            this.emailVacio = false;
            this.aumentarPaso();
          }

        } else {
          this.notificaciones.showError("Hay errores en los campos, porfavor revisalos")
        }
        break;
      case 1:
        if (this.passwd == "") {
          this.passwdVacio = true;
        } else {
          this.passwdVacio = false;
        }
        if (this.repeatPasswd == "") {
          this.repeatPasswdVacio = true;
        } else {
          this.repeatPasswdVacio = false;
        }
        if (!this.passwdVacio && !this.repeatPasswdVacio) {
          if (this.passwd != this.repeatPasswd) {
            this.notificaciones.showError("Las contraseñas introducidas no coinciden")
            this.passwdVacio = true;
            this.repeatPasswdVacio = true;
          } else {

            this.passwdVacio = false;
            this.repeatPasswdVacio = false;
            if (this.manager == 0 || isNaN(this.manager)) {
              let queryString = window.location.search;
              let urlParams = new URLSearchParams(queryString);

              var pr = {
                idCompany: Number(urlParams.get('dcmpny')),
                timetable: "",
                name: this.nombre,
                surname: this.apellidos,
                email: this.email,
                phone: this.numeroTelefono,
                password: sha256(this.passwd),
                address: {
                  locality: this.localidad
                },
                birthDate: this.fechaNacimiento,
                profileImage: [{
                  image: "",
                  isPrincipal: true
                }],
                link: "",
                dni: this.dni,
                bankAccount: "",
              }
              this.prSE.addNewPR(pr);
            }else{
              var pr2 = {
                idManager: this.manager,
                timetable: "",
                name: this.nombre,
                surname: this.apellidos,
                email: this.email,
                phone: this.numeroTelefono,
                password: sha256(this.passwd),
                address: {
                  locality: this.localidad
                },
                birthDate: this.fechaNacimiento,
                profileImage: [{
                  image: "",
                  isPrincipal: true
                }],
                link: "",
                dni: this.dni,
                bankAccount: "",
              }
              this.prSE.addNewPR(pr2);
            }

            this.loader.open();
            this.prSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
              if (value == null) { return }
              this.loader.closeSlow();
              this.id = value.id;
              this.aumentarPaso();
              this._unsubInd.next("");
            })
            this.prSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
              if (value == null) { return }
              this.loader.closeSlow();
              this.notificaciones.showError(value.error.message);
              this._unsub.next("");
            })
          }
        } else {
          this.notificaciones.showError("Hay errores en los campos, porfavor revisalos")
        }
        break;
    }
  }
  validEmail(email: string) {
    var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
    return String(email).search(filter) != -1;
  }
  close() {
    this.router.navigate(["/"])
  }
  changeDate(event: any) {
    this.fechaNacimiento = event.value
  }
  goToLogin(){
    if (this.manager == 0 || isNaN(this.manager)) {
      this.router.navigate(["login"])
    } else {
      this.router.navigate(["login"], {queryParams: {manager: this.manager}})
    }
  }
}
