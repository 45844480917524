import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsNewsComponent implements OnInit {

  @Input() pasos: any = [];
  @Input() activo: number = 0;
  @Input() navegacionActiva: boolean = false;
  
  @Output() activoChange = new EventEmitter<number>();

  constructor(private element:ElementRef) { }

  ngOnInit() {
  }

  clickNavegation(index: number){
    if (this.navegacionActiva) {
      this.activoChange.emit(index);
      this.element.nativeElement.dispatchEvent(new CustomEvent('change', { bubbles: true }));
    }else{
      if (index < this.activo) {
        this.activoChange.emit(index);
        this.element.nativeElement.dispatchEvent(new CustomEvent('change', { bubbles: true }));
      }
    }
   
  }

}
