import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { InvitePopupComponent } from 'src/app/popups/Invite-Popup/Invite-Popup.component';
import { ConfirmPopupComponent } from 'src/app/popups/confirmPopup/confirmPopup.component';
import { EventService } from 'src/app/services/Api/event.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { StadisticsService } from 'src/app/services/Api/stadistics.service';
import { TicketsService } from 'src/app/services/Api/tickets.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PopupService } from 'src/app/services/utils/popup.service';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-EventDetails',
  templateUrl: './EventDetails.component.html',
  styleUrls: ['./EventDetails.component.scss'],
})
export class EventDetailsComponent
  extends UnsubscribesDestroy
  implements OnInit
{
  plantaSeleccionada = 0;
  urlImages = environment.urlImages;
  zoom = 18;
  isSection1: boolean = true;
  isSection2: boolean = false;
  isSection3: boolean = false;
  isSection4: boolean = false;

  tickets: any[] = [];
  ticketsStadistics: any = null;

  id!: number;
  event: any = null;
  evento: any = null;

  tabSelected: number = 0;
  dataIsLoaded: Boolean = false;

  headerImage: string = '';

  constructor(
    private eventsSE: EventService,
    private route: ActivatedRoute,
    private loginSE: LoginService,
    private loaderSE: LoaderService,
    private notificaciones: NotificacionesService,
    private router: Router,
    private popupSE: PopupService,
    private ticketsSE: TicketsService
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      this.checkPermisos();
    }
  }
  exportexcel(arrayToConvert: Array<any>) {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(
      this.jsonExcelParse(arrayToConvert)
    );
    XLSX.utils.book_append_sheet(workBook, workSheet, 'data');
    XLSX.writeFile(
      workBook,
      'Listado_compradores_' + this.event.title + '.xlsx'
    );
  }

  private jsonExcelParse(array: Array<any>) {
    var newJsonParsedArray: Array<any> = [];
    array.forEach((element) => {
      const newJsonObj = {
        Nombre: element.name + ' ' + element.surname,
        Email: element.email,
        DNI: element.dni,
        Teléfono: element.phone,
        Género: element.gender ?? '-',
        Edad: element.age == 0 ? '-' : element.age,
        Nombre_usuario: element.uniqueName ?? '-',
        Fecha_de_alta: element.creationDate?.split('T')[0] ?? '-',
        Centros: element.centers?.join(', ') ?? '-',
        Prereserva: (element.isPreorder) ? 'Sí' : 'No',
        Intereses:
          element.categories
            ?.map((element) => {
              return element.name;
            })
            .join(', ') ?? '-',
        Acepta_mails_marketing: element.marketingMails ? 'Sí' : 'No',
        Nombre_del_evento: element.eventName ?? '-',
        Nombre_del_producto: element.productName ?? '-',
        Pago_completado: element.complete ? 'Sí' : 'No',
      };
      newJsonParsedArray.push(newJsonObj);
    });
    return newJsonParsedArray;
  }
  getTicketStadistics(event) {
    this.ticketsStadistics = event;
  }

  getEvent() {
    this.eventsSE.getEventById(this.id);

    this.eventsSE
      .getResult()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.event = value;
        this.tickets = this.event.tickets?.map((ticket) => {
          return { ...ticket, name: ticket.product.name };
        });
        let image = this.event.event_images?.find((img: any) => img.is_header);
        if (image) {
          this.headerImage = value.event_images?.find(
            (img: any) => img.is_header
          ).image.url;
        } else {
          this.headerImage = '';
        }
        this.dataIsLoaded = true;
        this._unsubInd2.next('');
      });
    this.eventsSE
      .getResultError()
      .pipe(takeUntil(this._unsub))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.loaderSE.closeSlow();
        this.notificaciones.showError(value.error.message);
        this._unsub.next('');
      });
  }

  // Navbar

  onSection1() {
    this.isSection1 = true;
    this.isSection2 = false;
    this.isSection3 = false;
    this.isSection4 = false;
  }

  onSection2() {
    this.isSection1 = false;
    this.isSection2 = true;
    this.isSection3 = false;
    this.isSection4 = false;
  }

  onSection3() {
    this.isSection1 = false;
    this.isSection2 = false;
    this.isSection3 = true;
    this.isSection4 = false;
  }

  onSection4() {
    this.isSection1 = false;
    this.isSection2 = false;
    this.isSection3 = false;
    this.isSection4 = true;
  }

  // Borra el evento si no hay tickets vendidos
  deleteEventIfNoTicketsSold() {
    this.popupSE.openPopup(ConfirmPopupComponent, {
      accion: 'eliminar',
      genero: 'masculino',
      tipo: 'evento',
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd6))
      .subscribe((res) => {
        if (!res) return;
        this.deleteEvent([this.id]);
        this._unsubInd6.next('');
      });
  }

  // Invita a un usuario a un evento

  invite() {
    this.popupSE.openPopup(InvitePopupComponent, { data: this.tickets });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd6))
      .subscribe((res) => {
        if (!res) return;
        let data = res['returnValue'];
        this.sendInvitation(data);
        this._unsubInd6.next('');
      });
  }

  // Refresh event

  refreshEventOutput(event: boolean) {}

  // Trips options

  changeTab(tabNumber: number) {
    this.tabSelected = tabNumber;
  }
  updateEventStatus() {
    if (this.event.is_active) {
      this.eventsSE.updateEventById(this.event.id, { isActive: false });
    } else {
      this.eventsSE.updateEventById(this.event.id, { isActive: true });
    }
    this.eventsSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((value) => {
        if (value == null) {
          return;
        }

        this.notificaciones.showFeedBack(value.message);
        this.loaderSE.closeSlow();
        this._unsubInd.next('');
      });
    this.eventsSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((value) => {
        if (value == null) {
          return;
        }

        this.notificaciones.showFeedBack(value.error.message);
        this.loaderSE.closeSlow();
        this._unsub.next('');
      });
  }
  editarEvento(element: any, data: any) {
    var evento = {};
    evento[element] = data;
    this.eventsSE.updateEventById(this.route.snapshot.params['id'], evento);
    this.eventsSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.notificaciones.showFeedBack(value.message);
        this.getEvent();
        this._unsubInd.next('');
      });
    this.eventsSE
      .getResultError()
      .pipe(takeUntil(this._unsub))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.notificaciones.showError(value.error.message);
        this._unsub.next('');
      });
  }

  permiso: Boolean = false;
  checkPermisos() {
    if (
      localStorage.getItem('tipoUser') == null ||
      localStorage.getItem('tipoUser') == undefined ||
      localStorage.getItem('tipoUser') == '["2"]'
    ) {
      this.loginSE.logOut();
      this.notificaciones.showError('No tienes permisos para acceder');
    } else {
      if (
        localStorage.getItem('tipoUser') == '["0"]' ||
        localStorage.getItem('tipoUser') == '["3"]'
      ) {
        if (localStorage.getItem('permisos') == 'null') {
          this.loginSE.logOut();
          this.notificaciones.showError('No tienes permisos para acceder');
        } else if (
          !localStorage.getItem('permisos')?.includes('CONSULT_COMPANY') &&
          !localStorage.getItem('permisos')?.includes('CONSULT_COMPANY_GROUP')
        ) {
          this.loginSE.logOut();
          this.notificaciones.showError('No tienes permisos para acceder');
        } else {
          if (
            localStorage
              .getItem('permisos')
              ?.includes('CONSULT_EDIT_COMPANY') &&
            localStorage
              .getItem('permisos')
              ?.includes('CONSULT_EDIT_COMPANY_GROUP')
          ) {
            this.permiso = true;
          }
          this.id = this.route.snapshot.params['id'];
          this.getEvent();
        }
      }
      if (localStorage.getItem('tipoUser') == '["1"]') {
        if (localStorage.getItem('permisos') == 'null') {
          this.loginSE.logOut();
          this.notificaciones.showError('No tienes permisos para acceder');
        } else if (
          !localStorage.getItem('permisos')?.includes('CONSULT_COMPANY')
        ) {
          this.loginSE.logOut();
          this.notificaciones.showError('No tienes permisos para acceder');
        } else {
          // this.getEvents();
          if (
            localStorage.getItem('permisos')?.includes('CONSULT_EDIT_COMPANY')
          ) {
            this.permiso = true;
          }
          this.id = this.route.snapshot.params['id'];
          this.getEvent();
        }
      }
    }
  }

  sendInvitation(data) {
    this.ticketsSE.createInvitation(data);
    this.ticketsSE
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.notificaciones.showFeedBack('Invitación enviada correctamente');
        this._unsubInd.next('');
      });
    this.ticketsSE
      .getResultError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            this.notificaciones.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            this.notificaciones.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            this.notificaciones.showError(res.error.message);
          }
        }
        this._unsub.next('');
      });
  }

  deleteEvent(ids: number[]) {
    this.eventsSE.deleteEventIfNoTickets(ids);
    this.eventsSE
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.router.navigate(['publisher/management/events/my-events']);
        this.notificaciones.showFeedBack('Evento eliminado correctamente');
        this._unsubInd2.next('');
      });
    this.eventsSE
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            this.notificaciones.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            this.notificaciones.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            this.notificaciones.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  getExcel() {
    this.loaderSE.open();
    this.eventsSE.getExcel(this.event.id);
    this.eventsSE
      .getResult()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.exportexcel(res);
        this.loaderSE.closeSlow();
        this._unsubInd2.next('');
      });
    this.eventsSE
      .getResultError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            this.notificaciones.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            this.notificaciones.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            this.notificaciones.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }
}
