<PageTemplate>
  <ng-container bodyContent>
    <header [searcherActive]="false"></header>
    <div class="pageHeader">
      <h1>Base de datos de los pagos realizados</h1>
      <searcher [placeHolder]="'Busca aquí un usuario'" (emitChange)="getFilter($event)"></searcher>
    </div>
    <div class="filtersContainer">

      <div class="containerFilters">
        <label for="">Fecha de compra</label>
        <div>
          <input matInput [matDatepicker]="picker" readOnly="true" [(ngModel)]="fechaCompra"
            (dateChange)="changeDate($event)" placeholder="Fecha de compra" class="inputData datePicker">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker class="miPerfil-movil" #picker></mat-datepicker>
        </div>
      </div>
      <div class="containerFilters" [ngStyle]="{'align-items': 'flex-end'}">

        <button class="btn-sm" (click)="clearFilters()">Limpiar filtros</button>
      </div>
    </div>
    <mat-spinner mat-spinner *ngIf="!dataIsLoaded"></mat-spinner>
    <div class="pageBody" *ngIf="dataIsLoaded">
      <p class="noData" *ngIf="pagos.length == 0">
        No hay pagos que mostrar
      </p>
      <div class="tableContainer" *ngIf="pagos.length > 0">
        <table>
          <thead>
            <tr>
              <th>Comprador</th>
              <th class="center">Fecha</th>
              <th class="center">Código de redsys</th>
              <th class="center">Productos comprados</th>
              <th class="center">Pagado</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let pago of pagos" (click)="$event.stopPropagation(); openPagoPopup(pago)">
              <td>{{pago.name}}</td>
              <td class="center">{{pago.date | date: "dd MMMM YYYY"}}</td>
              <td class="center">{{pago.redsysOrder}}</td>
              <td class="center">{{pago.details.length}}</td>
              <td class="center flex">
                <ng-container *ngIf="pago.pay">
                  Sí
                </ng-container>
                <ng-container *ngIf="!pago.pay">
                  No
                </ng-container>
                <ng-container *ngIf="pago.pay == null">
                  ??
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="paginacion" *ngIf="dataIsLoaded && pagos.length > 0">
      <span (click)="paginaAnterior()" [class.disabled]="filters.num_pagina == 1">
        << Página anterior</span>
          <span [ngStyle]="{'text-decoration': 'none'}">{{filters.num_pagina}}</span>
          <span (click)="siguientePagina()" [class.disabled]="pagos.length < 5">Siguiente página >></span>
    </div>
  </ng-container>
</PageTemplate>