<PageTemplate>
  <ng-container bodyContent>
    <header [searcherActive]="false" [buttonActive]="false" [selectActive]="true" (yearSelected)="setYear($event)">
    </header>
    <h2>Rendimiento eventos</h2>
    <div class="graphs">
      <div class="top-group">
        <div class="filtrosGrafica">
          <div>
            <span (click)="previousPage()" [class.desactivate]="numPag == 1">
              < Página anterior</span>
                <span>|</span>
                <span (click)="nextPage()"
                  [class.desactivate]="asistencia && asistenciasTotales.length == 0 || ventas && ventasTotales.length == 0">Siguiente
                  página ></span>
          </div>
          <mat-radio-group aria-label="Select an option">
            <mat-radio-button value="ventas" [checked]="true"
              (change)="ventas = true; asistencia = false; getHistoricalSales()">Histórico ventas</mat-radio-button>
            <mat-radio-button value="asistencia" (change)="ventas = false; asistencia = true;getHistoricalAssist()">
              Histórico asistencia
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <mat-spinner class="center big" *ngIf="!dataIsLoaded"></mat-spinner>
        <ng-container *ngIf="dataIsLoaded">
          <total-event-sales-chart [data]="ventasTotales" *ngIf="ventas && dataIsLoaded && ventasTotales.length != 0">
          </total-event-sales-chart>
          <p class="noData" *ngIf="ventas && dataIsLoaded && ventasTotales.length == 0">No hay datos para el año
            seleccionado</p>
          <total-event-asist-chart [data]="asistenciasTotales"
            *ngIf="asistencia && dataIsLoaded && asistenciasTotales.length != 0"></total-event-asist-chart>
          <p class="noData" *ngIf="asistencia && dataIsLoaded && asistenciasTotales.length == 0">No hay datos para el
            año seleccionado</p>
        </ng-container>

      </div>
      <div class="bottom-group">
        <section class="left-group">
          <div class="options">
            <span (click)="onSelectGenre()" (keyup.enter)="onSelectGenre()" tabindex="0"
              [ngClass]="isDemographyGenre ? 'active' : ''">Sexo</span>
            <span (click)="onSelectAge()" (keyup.enter)="onSelectAge()" tabindex="0"
              [ngClass]="isDemographyAge ? 'active' : ''">Edad</span>
            <span (click)="onSelectInterests()" (keyup.enter)="onSelectInterests()" tabindex="0"
              [ngClass]="isDemographyInterests ? 'active' : ''">Intereses</span>
          </div>
          <mat-spinner class="center big" *ngIf="!dataIsLoadedAuxiliar"></mat-spinner>
          <ng-container *ngIf="dataIsLoadedAuxiliar">
            <ng-container *ngIf="isDemographyGenre">
              <p class="noData" *ngIf="diagramaSexo!.men == 0 && diagramaSexo!.women == 0">No hay
                datos para estos parámetros</p>
              <demographicChart [type]="'sexo'" [dataSexo]="diagramaSexo"
                *ngIf="diagramaSexo!.men > 0 || diagramaSexo!.women > 0"></demographicChart>
            </ng-container>
            <ng-container *ngIf="isDemographyAge  && dataIsLoadedAuxiliar">
              <p class="noData"
                *ngIf="diagramaEdad.catorce_a_dieciocho == 0 && diagramaEdad.cincuenta == 0 && diagramaEdad.cuarenta == 0 && diagramaEdad.dieciocho_a_veintiuno == 0 && diagramaEdad.treinta == 0 && diagramaEdad.veintiuno == 0">
                No hay datos para estos parámetros</p>
              <demographicChart [type]="'edad'" [dataEdad]="diagramaEdad"
                *ngIf="diagramaEdad.catorce_a_dieciocho != 0 || diagramaEdad.cincuenta != 0 || diagramaEdad.cuarenta != 0 || diagramaEdad.dieciocho_a_veintiuno != 0 || diagramaEdad.treinta != 0 || diagramaEdad.veintiuno != 0">
              </demographicChart>
            </ng-container>
            <ng-container *ngIf="isDemographyInterests">
              <p class="noData" *ngIf="diagramaCategories!.length == 0">No hay datos para estos
                parámetros</p>
              <demographicChart [type]="'categorias'" [dataInterest]="diagramaCategories"
                *ngIf="diagramaCategories!.length != 0"></demographicChart>
            </ng-container>
          </ng-container>
        </section>
        <section class="right-group">
          <label for="">Top Eventos</label>
          <mat-spinner class="center big" *ngIf="!dataIsLoadedAuxiliar"></mat-spinner>
          <ng-container *ngIf="dataIsLoadedAuxiliar">
            <p class="noData" *ngIf="topEventos.length == 0">No hay eventos</p>
            <div class="topEventoContainer" *ngFor="let evento of topEventos; let i = index">
              <div [routerLink]="['/publisher/management/events/my-events/event-details/'+evento.id]" class="nombre">
                {{i+1}}.
                {{evento.title}}</div>
              <div class="asistencia">{{evento.assist}}</div>
              <div class="ingresos">{{evento.total | currency:'EUR'}}</div>
            </div>
          </ng-container>
        </section>
      </div>
    </div>
    <section class="event-history-table">
      <mat-spinner *ngIf="!dataTableLoaded" class="big center"></mat-spinner>
      <table class="table-group" id="tabla" *ngIf="dataTableLoaded">
        <tr>
          <th *ngFor="let cabecera of cabeceras">{{cabecera}}</th>
        </tr>
        <tr *ngFor="let evento of eventos">
          <td><span
              [routerLink]="['/publisher/management/events/my-events/event-details/'+$any(evento).id]">{{$any(evento).title}}</span>
          </td>
          <td>

            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="13.375" height="14.922" viewBox="0 0 13.375 14.922">
                <g id="Grupo_1776" data-name="Grupo 1776" transform="translate(0.5 0.5)">
                  <path id="Trazado_765" data-name="Trazado 765"
                    d="M13.125,6.751c0,3.841-6.187,7.921-6.187,7.921S.75,10.591.75,6.751a6.154,6.154,0,0,1,6.188-6A6.154,6.154,0,0,1,13.125,6.751Z"
                    transform="translate(-0.75 -0.75)" fill="none" stroke="#0e17b6" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="1" />
                  <circle id="Elipse_591" data-name="Elipse 591" cx="2.691" cy="2.691" r="2.691"
                    transform="translate(3.496 3.416)" fill="none" stroke="#0e17b6" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="1" />
                </g>
              </svg>
              {{$any(evento).facility}}
            </span>
          </td>
          <td>
            <span>{{$any(evento).start_date.split(" ")[0].split("-").reverse().join("/")}} -
              {{$any(evento).end_date.split(" ")[0].split("-").reverse().join("/")}}</span>
          </td>
          <td>
            <progress [max]="$any(evento).total_attendace" [value]="$any(evento).attendance"></progress>
            <span>{{$any(evento).attendance}}/{{$any(evento).total_attendace}}</span>
          </td>
          <td>
            {{$any(evento).sales | currency:'EUR'}}
          </td>
          <td>
            <ng-container *ngIf="$any(evento).isActive">
              Activo
            </ng-container>
            <ng-container *ngIf="!$any(evento).isActive">
              Archivado
            </ng-container>
          </td>
        </tr>
      </table>
      <div class="paginacion" *ngIf=" dataTableLoaded && eventos.length > 0">
        <span (click)="paginaAnterior()" [class.disabled]="numPag == 1">
          << Página anterior</span>
            <span [ngStyle]="{'text-decoration': 'none'}">{{numPag}}</span>
            <span (click)="siguientePagina()" [class.disabled]="eventos.length < 6">Siguiente página >></span>
      </div>
    </section>
  </ng-container>
</PageTemplate>
