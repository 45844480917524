import { Component, OnInit, ViewChild } from '@angular/core';
import { DuracionComponent } from '../Mailing/Holders/Duracion/Duracion.component';
import { AudienciaComponent } from '../Mailing/Holders/Audiencia/Audiencia.component';
import { EdicionMailComponent } from '../Mailing/Holders/EdicionMail/EdicionMail.component';
import { LoginService } from 'src/app/services/Api/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CampaignService } from 'src/app/services/Api/Campaign.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { takeUntil } from 'rxjs';
import { EdicionMensajeComponent } from './Holders/EdicionMensaje/EdicionMensaje.component';

@Component({
  selector: 'app-Push',
  templateUrl: './Push.component.html',
  styleUrls: ['./Push.component.scss'],
})
export class PushComponent extends UnsubscribesDestroy implements OnInit {
  @ViewChild(DuracionComponent) step1!: DuracionComponent;
  @ViewChild(AudienciaComponent) step2!: AudienciaComponent;
  @ViewChild(EdicionMensajeComponent) step3!: EdicionMensajeComponent;

  toggleElements: Array<String> = [
    'Duración',
    'Audiencias',
    'Edicion de mensaje',
  ];
  selectedToggle: number = 0;
  editar: Boolean = false;
  dataIsLoaded: Boolean = true;
  dataPaso1: any = null;
  validStep1: Boolean = false;
  dataPaso2: any = null;
  validStep2: Boolean = false;
  dataPaso3: any = null;
  validStep3: Boolean = false;
  permiso: boolean = false;

  campaign: any = null;
  campaignId: any = null;
  constructor(
    private loginSE: LoginService,
    private router: Router,
    private notificaciones: NotificacionesService,
    private campaignSE: CampaignService,
    private loader: LoaderService,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      this.checkPermisos();
      if (localStorage.getItem('campaignId')) {
        this.campaignId = localStorage.getItem('campaignId');
        this.getCampanya();
      }
    }
  }
  override ngOnDestroy(): void {
    localStorage.removeItem('campaignId');
  }
  /**
   * FUNCTIONALITY
   */
  changeToggle(action: string = 'siguiente') {
    switch (this.selectedToggle) {
      case 0:
        this.step1.validarPaso1();
        if (this.validStep1) {
          this.selectedToggle++;
        }
        if (this.editar) {
          // this.getEvent();
        }
        break;
      case 1:
        if (action != 'atras') {
          this.step2.validarPaso2();
          if (this.validStep2) {
            this.selectedToggle++;
          }
          if (this.editar) {
            // this.getEvent();
          }
        }

        break;
      case 2:
        if (action != 'atras') {
          this.step3.validarPaso3();
          if (this.validStep3) {
            this.crearCampanya();
          }
          if (this.editar) {
            // this.getEvent();
          }
        }
        break;
    }
  }

  //GETTERS & SETTERS
  getDataFromStep1(event) {
    this.dataPaso1 = event;
  }
  getDataFromStep2(event) {
    this.dataPaso2 = event;
  }
  getDataFromStep3(event) {
    this.dataPaso3 = event;
  }

  setDataPaso1() {
    this.dataPaso1 = {
      name: this.campaign.name,
      startDate: new Date(this.campaign.start_date),
      startTime: this.campaign.start_time.split('T')[1].split('+')[0],
      lanzarCampanyaGuardar: this.campaign.is_active,
    };
  }
  setDataPaso2() {
    this.dataPaso2 = {
      id: this.campaign.audiences[0].id,
      name: this.campaign.audiences[0].name,
      from_age: this.campaign.audiences[0].from_age,
      until_age: this.campaign.audiences[0].until_age,
      locality: this.campaign.audiences[0].locality,
      gender: this.campaign.audiences[0].gender,
      alcance: this.campaign.audiences[0].alcance,
    };
  }
  setDataPaso3() {
    this.dataPaso3 = {
      asunto: this.campaign.asunto,
      cuerpo: this.campaign.cuerpo,
      url: this.campaign.url,
    };
  }
  //VALIDACIONES

  isValidStep1(valid: Boolean) {
    this.validStep1 = valid;
  }
  isValidStep2(valid: Boolean) {
    this.validStep2 = valid;
  }
  isValidStep3(valid: Boolean) {
    this.validStep3 = valid;
  }

  /**
   * CALL TO API
   */
  editarCampanya(event) {
    var campaign = {};
    campaign[event.element] = event.value;

    this.campaignSE.update(this.route.snapshot.params['id'], campaign);
    this.campaignSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd4))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.notificaciones.showFeedBack(value.message);
        this.getCampanya(false);
        this._unsubInd4.next('');
      });
    this.campaignSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.notificaciones.showError(value.error.message);
        this._unsub.next('');
      });
  }
  crearCampanya() {
    let campaign = {
      company: Number(sessionStorage.getItem('dcmpny')),
      name: this.dataPaso1.name,
      type: 'Push',
      startDate: this.dataPaso1.startDate,
      startTime: this.dataPaso1.startTime,
      audience: this.dataPaso2.id,
      asunto: this.dataPaso3.asunto,
      cuerpo: this.dataPaso3.cuerpo,
      url: this.dataPaso3.url,
    };
    if (this.dataPaso1.lanzarCampanyaGuardar) {
      campaign['isActive'] = true;
    } else {
      campaign['isActive'] = false;
    }
    if (campaign.startDate == null) {
      campaign.startDate = new Date();
    }
    if (campaign.startTime == '') {
      campaign.startDate =
        new Date().getHours() - 5 + ':' + new Date().getMinutes();
    }
    this.campaignSE.create(campaign);
    this.loader.open();
    this.campaignSE
      .getResultIndividual()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (res == null) {
          return;
        }

        if (this.dataPaso1.lanzarCampanyaGuardar) {
          this.lanzarCampaign();
        } else {
          this.loader.closeSlow();
          this.notificaciones.showFeedBack(res.message);
          setTimeout(() => {
            this.router.navigate([
              '/publisher/communities-promotions/promotions',
            ]);
          }, 500);
        }
        this._unsubInd.next('');
      });
    this.campaignSE
      .getResultIndividualError()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (res == null) {
          return;
        }
        this.loader.closeSlow();
        this.notificaciones.showError(res.error.message);
        this._unsubInd.next('');
      });
  }
  lanzarCampaign() {
    this.campaignSE.lanzar();
    this.campaignSE
      .getResult()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: any) => {
        if (res == null) {
          return;
        }
        this.loader.closeSlow();
        this._unsubInd2.next('');
        setTimeout(() => {
          this.router.navigate([
            '/publisher/communities-promotions/promotions',
          ]);
        }, 500);
      });
    this.campaignSE
      .getResultError()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: any) => {
        if (res == null) {
          return;
        }
        this.loader.closeSlow();
        setTimeout(() => {
          this.router.navigate([
            '/publisher/communities-promotions/promotions',
          ]);
        }, 500);
        this._unsubInd2.next('');
      });
  }
  getCampanya(loaderActive: boolean = true) {
    this.campaignSE.getSingle(this.campaignId);
    if (loaderActive) {
      this.dataIsLoaded = false;
    }
    this.campaignSE
      .getResultIndividual()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (res == null) {
          return;
        }
        this.campaign = res;
        this.setDataPaso1();
        this.setDataPaso2();
        this.setDataPaso3();
        this._unsubInd.next('');
        this.dataIsLoaded = true;
      });
  }
  /**
   * PERMISOS
   */
  checkPermisos() {
    if (
      localStorage.getItem('tipoUser') == null ||
      localStorage.getItem('tipoUser') == undefined ||
      localStorage.getItem('tipoUser') == '["2"]'
    ) {
      this.loginSE.logOut();
      this.notificaciones.showError('No tienes permisos para acceder');
    } else {
      if (
        localStorage.getItem('tipoUser') == '["0"]' ||
        localStorage.getItem('tipoUser') == '["3"]'
      ) {
        if (localStorage.getItem('permisos') == 'null') {
          this.loginSE.logOut();
          this.notificaciones.showError('No tienes permisos para acceder');
        } else if (
          !localStorage.getItem('permisos')?.includes('CONSULT_COMPANY') &&
          !localStorage.getItem('permisos')?.includes('CONSULT_COMPANY_GROUP')
        ) {
          this.loginSE.logOut();
          this.notificaciones.showError('No tienes permisos para acceder');
        } else if (
          !localStorage.getItem('permisos')?.includes('CONSULT_EDIT_COMPANY') &&
          localStorage
            .getItem('permisos')
            ?.includes('CONSULT_EDIT_COMPANY_GROUP')
        ) {
          this.router.navigate(['/publisher/communities-promotions']);
          this.notificaciones.showError('No tienes permisos para acceder');
        }
      }
      if (localStorage.getItem('tipoUser') == '["1"]') {
        if (localStorage.getItem('permisos') == 'null') {
          this.loginSE.logOut();
          this.notificaciones.showError('No tienes permisos para acceder');
        } else if (
          !localStorage.getItem('permisos')?.includes('CONSULT_COMPANY')
        ) {
          this.loginSE.logOut();
          this.notificaciones.showError('No tienes permisos para acceder');
        } else if (
          !localStorage.getItem('permisos')?.includes('CONSULT_EDIT_COMPANY')
        ) {
          this.notificaciones.showError('No tienes permisos para acceder');
          this.router.navigate(['/publisher/communities-promotions']);
        }
      }
    }
  }
}
