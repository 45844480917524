import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { BusinessService } from 'src/app/services/Api/business.service';
import { validURL } from 'src/app/resources/regex';
import { Business } from 'src/app/models/Business';
import { LoginService } from 'src/app/services/Api/login.service';
import { environment } from 'src/environments/environment';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { CompanyService } from 'src/app/services/Api/company.service';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { SocialMediaService } from 'src/app/services/Api/socialMedia.service';

@Component({
  selector: 'app-EditPromotionalPage',
  templateUrl: './EditPromotionalPage.component.html',
  styleUrls: ['./EditPromotionalPage.component.scss']
})
export class EditPromotionalPageComponent extends UnsubscribesDestroy implements OnInit {
  urlImages = environment.urlImages
  id!: number;
  company: any = {}
  descriptionError: boolean = false;
  webPageError: boolean = false;

  companyProfileImage: string = ""
  companyImage1: string = "";
  companyImage2: string = "";
  companyImage3: string = ""
  companyImage4: string = ""
  companyImage5: string = ""
  companyImage6: string = ""
  companyImage7: string = ""
  companyImage8: string = ""
  companyImage9: string = ""
  bannerImage: string = ""
  normalImages: any = []
  dataLoaded: boolean = false;
  constructor(
    private router: Router,
    private loaderSE: LoaderService, private notifications: NotificacionesService, private loginSE: LoginService, private companySE: CompanyService, private socialMediaSE: SocialMediaService, private notificaciones: NotificacionesService
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      // Fill the form using the information provided by getBusiness(id)
      this.checkPermisos();
    }

  }
  setCompanyImageProfile(companyProfileImage) {
    this.updateCompany("images", [{ image: companyProfileImage[0], isPrincipal: true, isBanner: false }])
  }
  setCompanyBannerProfile(companyBannerImage) {
    this.updateCompany("images", [{ image: companyBannerImage[0], isBanner: true, isPrincipal: false }])
  }
  setCompanyImage(companyImage) {
    this.updateCompany("images", [{ image: companyImage[0], isPrincipal: false }])
  }
  setClick(element) {
    (document.querySelector(element) as HTMLElement).click();
  }
  getCompany() {
    this.companySE.getCompanyGroupById(Number(sessionStorage.getItem("groupId")));
    this.companySE.getResultIndividual().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return }
      this.loaderSE.closeSlow();
      this.company = value;
      this.normalImages = []
      this.company.company_group_images.forEach(element => {
        if (element.is_banner) {
          this.bannerImage = element.image.url
        }
        if (element.isPrincipal) {
          this.companyProfileImage = element.image.url
        }
        if (!element.isBanner && !element.isPrincipal) {
          this.normalImages.push(element);
        }
      });
      if (this.normalImages.length != 0) {
        if (this.normalImages[0] != undefined || this.normalImages[0] != null) {
          this.companyImage1 = this.normalImages[0].image.url
          setTimeout(() => {
            (document.querySelector(".photo.one") as HTMLElement).style.backgroundImage = "url(" + this.companyImage1 + ")"
          }, 500);
        }
        if (this.normalImages[1] != undefined || this.normalImages[1] != null) {
          this.companyImage2 = this.normalImages[1].image.url
          setTimeout(() => {
            (document.querySelector(".photo-detail.two") as HTMLElement).style.backgroundImage = "url(" + this.companyImage2 + ")"
          }, 500);
        }
        if (this.normalImages[2] != undefined || this.normalImages[2] != null) {
          this.companyImage3 = this.normalImages[2].image.url
          setTimeout(() => {
            (document.querySelector(".photo-detail.three") as HTMLElement).style.backgroundImage = "url(" + this.companyImage3 + ")"
          }, 500);
        }
        if (this.normalImages[3] != undefined || this.normalImages[3] != null) {
          this.companyImage4 = this.normalImages[3].image.url
          setTimeout(() => {
            (document.querySelector(".photo-detail.four") as HTMLElement).style.backgroundImage = "url(" + this.companyImage4 + ")"
          }, 500);
        }
        if (this.normalImages[4] != undefined || this.normalImages[4] != null) {
          this.companyImage5 = this.normalImages[4].image.url
          setTimeout(() => {
            (document.querySelector(".photo-detail.five") as HTMLElement).style.backgroundImage = "url(" + this.companyImage5 + ")"
          }, 500);
        }
        if (this.normalImages[5] != undefined || this.normalImages[5] != null) {
          this.companyImage6 = this.normalImages[5].image.url
          setTimeout(() => {
            (document.querySelector(".photo-detail.six") as HTMLElement).style.backgroundImage = "url(" + this.companyImage6 + ")"
          }, 500);
        }
        if (this.normalImages[6] != undefined || this.normalImages[6] != null) {
          this.companyImage7 = this.normalImages[6].image.url
          setTimeout(() => {
            (document.querySelector(".photo-detail.seven") as HTMLElement).style.backgroundImage = "url(" + this.companyImage7 + ")"
          }, 500);
        }
        if (this.normalImages[7] != undefined || this.normalImages[7] != null) {
          this.companyImage8 = this.normalImages[7].image.url
          setTimeout(() => {
            (document.querySelector(".photo-detail.eight") as HTMLElement).style.backgroundImage = "url(" + this.companyImage8 + ")"
          }, 500);
        }
        if (this.normalImages[8] != undefined || this.normalImages[8] != null) {
          this.companyImage9 = this.normalImages[8].image.url
          setTimeout(() => {
            (document.querySelector(".photo-detail.nine") as HTMLElement).style.backgroundImage = "url(" + this.companyImage9 + ")"
          }, 500);
        }
      }
      this._unsubInd.next("");
      this.dataLoaded = true;
    })

  }
  updateCompany(element: any, data: any) {
    var company = {};
    if (element == "description") {
      if (data == "") {
        this.descriptionError = true;
        this.notifications.showError("No pueden haber campos vacíos")
      } else {
        this.descriptionError = false;
        company[element] = data;
        this.companySE.updateCompanyGroup(Number(sessionStorage.getItem("groupId")), company);
      }
    }
    if (element == "webpage") {
      if (data == "") {
        this.webPageError = true;
        this.notifications.showError("No pueden haber campos vacíos")
      } else {
        this.webPageError = false;
        company[element] = data;
        this.companySE.updateCompanyGroup(Number(sessionStorage.getItem("groupId")), company);
      }
    }
    if (element == "images") {
      company[element] = data;
      this.companySE.updateCompanyGroup(Number(sessionStorage.getItem("groupId")), company);
    }
    this.companySE.getResultUpdate().pipe(takeUntil(this._unsubInd2)).subscribe(value => {
      if (value == null) { return };

      this.notifications.showFeedBack(value.message)
      this.getCompany();
      this._unsubInd2.next("");
    })
    this.companySE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return };
      this.notifications.showFeedBack(value.message)
      this._unsub.next("");
    })
  }
  updateSocialMedia(id: any, element: any, data: any) {
    var socialMedia = {};
    socialMedia[element] = data;
    socialMedia["company"] = Number(sessionStorage.getItem("dcmpny"));
    this.socialMediaSE.updateSocialMediaById(id, socialMedia);
    this.socialMediaSE.getResultUpdate().pipe(takeUntil(this._unsubInd3)).subscribe(value => {
      if (value == null) { return };
      this.notifications.showFeedBack(value.message)

      this._unsubInd3.next("");
    })
    this.socialMediaSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return };
      this.notifications.showFeedBack(value.message)
      this._unsub.next("");
    })
  }
  checkPermisos() {
    if (localStorage.getItem("tipoUser") == null || localStorage.getItem("tipoUser") == undefined || localStorage.getItem("tipoUser") == '["2"]') {
      this.loginSE.logOut();
      this.notificaciones.showError("No tienes permisos para acceder")
    } else {
      if (localStorage.getItem("tipoUser") == '["0"]' || localStorage.getItem("tipoUser") == '["3"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY") && !localStorage.getItem("permisos")?.includes("CONSULT_COMPANY_GROUP")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY") && !localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY_GROUP")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else {
          this.getCompany();
        }
      }
    }

  }
}
