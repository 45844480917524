<headerMovil></headerMovil>

<div class="newTicketGeneralContainer">
  <div class="headerContainer">
    <p>Equipo de
      <ng-container *ngIf="isDataLoaded">{{pr.user.name}} {{pr.user.surname}}</ng-container>
      <ng-container *ngIf="!isDataLoaded">...</ng-container>
    </p>
  </div>
  <GeneralButton [buttonText]="'Añadir nuevo rrpp al equipo'" (click)="navigateTo()"></GeneralButton>
  <div class="bodyContainer">
    <p class="noData" *ngIf="levels.length == 0">No tienes equipo todavía</p>
    <div class="level" *ngFor="let level of levels">
      <div class="levelHeader">
        <span>Nivel {{level.nivel}}</span>
      </div>
      <div class="levelBody">
        <ng-container *ngIf="isDataLoaded">
          <div class="equipo" *ngFor="let equipo of level.equipo">
            <img *ngIf="equipo.user.profile_image != null"  onerror="this.src='/assets/images/placeholder.jpg'" [src]="equipo.user.profile_image" alt="">
            <img *ngIf="equipo.user.profile_image == null" src="/assets/images/defaultImagePerfil.png" alt="">
            <span>{{equipo.user.name}} {{equipo.user.surname}}</span>
          </div>
        </ng-container>

      </div>
      <!-- <div>
        {{level.nivel}}
        <span *ngFor="let equipo of level.equipo">
          {{equipo.user.name}}
        </span>
      </div>
      <br> -->
    </div>
  </div>
</div>
<botonFlotante [texto]="'Copiar código'" [tipo]="4"></botonFlotante>
