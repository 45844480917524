import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { TicketsService } from 'src/app/services/Api/tickets.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PopupService } from 'src/app/services/utils/popup.service';

@Component({
  selector: 'app-LiberarEntradaPopup',
  templateUrl: './LiberarEntradaPopup.component.html',
  styleUrls: ['./LiberarEntradaPopup.component.scss']
})
export class LiberarEntradaPopupComponent extends UnsubscribesDestroy implements OnInit {


  constructor(public dialogRef: MatDialogRef<LiberarEntradaPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private notificaciones: NotificacionesService, private popupSE: PopupService, private ticketsSE: TicketsService, private notificacionesSE: NotificacionesService, private loaderSE: LoaderService, private ticketSE: TicketsService, private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
  }

  //FUNCIONALIDAD
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  //CALLS TO API
  liberarEntrada(){
    this.ticketSE.liberarEntrada(this.modalData.entrada.idEntrada);
    this.ticketSE.getResultIndividual().pipe(takeUntil(this._unsubInd)).subscribe((res:any)=>{
      if(res==null){return}
      this.notificaciones.showFeedBack(res.message)
      this._unsubInd.next("")
      this.closePopup();
    })
    this.ticketSE.getResultIndividualError().pipe(takeUntil(this._unsub)).subscribe((res:any)=>{
      if(res==null){return}
      this.notificaciones.showError(res.error.message)
      this._unsub.next("")
      this.closePopup();
    })
  }
}
