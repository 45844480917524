<div class="generalContainer">
  <div class="subContainer">
    <div>
      <sidebar></sidebar>
    </div>
    <div class="master-container">
      <div class="support-chat-page">
        <header class="nav-section">
          <nav class="navbar">
            <div class="breadcrumbs">
              <ul>
                <li>
                  <a routerLink="/home" aria-label="Inicio"
                    ><img src="/assets/icons/house-solid.svg" alt="" />
                  </a>
                </li>
                <li><a routerLink="/publisher/support/chat">Mensajería de soporte a usuarios</a></li>
              </ul>
            </div>
            <div class="nav-options">
              <div class="icons">
                <a routerLink="." aria-label="Ayuda" class="nav-icon"
                  ><img src="/assets/icons/questionMark.svg" alt=""
                /></a>
                <a routerLink="." aria-label="Mi usuario" class="nav-icon">
                  <img src="/assets/icons/user.svg" alt=""
                /></a>
              </div>
            </div>
          </nav>
          <section class="options">
            <div class="left-group">
              <span>Mensajería de soporte a usuarios</span>
            </div>
          </section>
        </header>
        <main>
          <div class="usuariosContainer">
            <div *ngFor="let usuario of usuarios" (click)="userSelect = usuario" [class.active]="userSelect.id == usuario.id" title="{{usuario.nombre}}">
              <span>{{usuario.nombre}}</span>
            </div>
          </div>
          <div class="chatContainer">
            <div class="chatHeader">
              <div>
                <img alt="" [src]="userSelect.urlImg">
                <span>{{userSelect.nombre}}</span>
              </div>
              <div>
                <button type="button">
                  <img src="/assets/icons/trash.svg" alt="">
                </button>
              </div>
            </div>
            <div class="messagesContainer">
              <p *ngIf="userSelect.id == null">No hay ningún chat seleccionado</p>
            </div>
            <div class="inputContainer">
              <div>
                <input id="inputMessage" [(ngModel)]="mensaje" type="text" placeholder="Escribe algo...">
              </div>
              <div>
                <button type="button">Enviar</button>
              </div>
            </div>
          </div>
        </main>
      </div>
      
    </div>
  </div>
</div>