<div class="comunityAuthorizationGeneralContainer">
  <searcher [size]="'fullWidth'" [toFilter]="empleados_backUp" (filtered)="filteredUsers($event)"
    [placeHolder]="'Buscar un usuario'" [type]="'user'"></searcher>
  <mat-spinner *ngIf="!isDataLoaded" class="big center"></mat-spinner>
  <div class="tableContainer">
    <p class="noData" *ngIf="empleados.length == 0 && isDataLoaded">No hay administradores disponibles</p>
    <ng-container *ngIf="isDataLoaded && empleados.length != 0">
      <table>
        <tr>
          <th>USUARIO</th>
          <th>ESTADO</th>
        </tr>
        <tr *ngFor="let empleado of empleados">
          <td>
            <img *ngIf="empleado.profileImage != null" [src]="empleado.profileImage"onerror="this.src='/assets/images/defaultImagePerfil.png'" alt="">
            <img *ngIf="empleado.profileImage == null" src="/assets/images/defaultImagePerfil.png" alt="">

            {{empleado.name}}
          </td>
          <td>
            <label class="switch" [class.noPermision]="!permiso">
              <input name="active" type="checkbox" (change)="authorizeAdmin(empleado.isAdmin,empleado)"
                [checked]="empleado.isAdmin == 1" />
              <span class="slider round"></span>
            </label>
            <span *ngIf="empleado.isAdmin != 1">No autorizado</span>
            <span *ngIf="empleado.isAdmin == 1">Autorizado</span>
          </td>
        </tr>
      </table>
    </ng-container>
  </div>
</div>
