import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, catchError } from 'rxjs';
import { Pr } from 'src/app/models/Pr';
import { environment } from 'src/environments/environment';
import { BaseService } from './Base.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class PrService extends BaseService {
  private urlApi: string = environment.urlApi;

  constructor(private http: HttpClient) {
    super();
  }


  getAllPRByCompany(antiguedad,eventId = null) {
    let url = '/api/public/relation/manager/' + Number(sessionStorage.getItem("dcmpny")) + "/" + antiguedad + "/ASC";
    if(eventId != null){
      url = url + "?eventId=" + eventId;
    }
    this.http.get(this.urlApi + url, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getAllPRByCompany, args: [antiguedad,eventId] })
        this.checkStatusError(error);
      },
    });
  }
  addNewPR(pr: any) {
    this.http.post(this.urlApi + '/public/relation', pr).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.addNewPR, args: [pr] })
        this.checkStatusError(error);
      },
    });
  }
  getCommutiesByPr(id) {
    this.http.get(this.urlApi + '/api/public/relation/info/' + id, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultIndividual', data)
      },
      error: (error) => {
        this.sendNextResult('resultIndividualError', error, { method: this.getCommutiesByPr, args: [id] })
        this.checkStatusError(error);
      },
    });
  }
  getStadistics(id) {
    this.http.get(this.urlApi + '/api/public/relation/home/' + id, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getStadistics, args: [id] })
        this.checkStatusError(error);
      },
    });
  }
  removePR(id) {
    var publicRelations_ids: any = []
    let send = { publicRelations_ids: publicRelations_ids };
    if (!(id instanceof Array)) { send.publicRelations_ids.push(id); } else { send.publicRelations_ids = id; }
    this.http.delete(this.urlApi + '/api/public/relation', this.sendBodyOptions(send)).subscribe({
      next: (data) => {
        this.sendNextResult('resultDelete', data)
      },
      error: (error) => {
        this.sendNextResult('resultDeleteError', error, { method: this.removePR, args: [id] })
        this.checkStatusError(error);
      },
    });
  }
  getHeaderStadisticsPR() {
    this.http.get(this.urlApi + '/api/public/relation/header/' + Number(sessionStorage.getItem("dcmpny")), this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getHeaderStadisticsPR, args: [] })
        this.checkStatusError(error);
      },
    });
  }
  getPRById(id) {
    this.http.get(this.urlApi + '/api/public/relation/' + id, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getPRById, args: [id] })
        this.checkStatusError(error);
      },
    });
  }
  setPauseRP(pr) {
    this.http.post(this.urlApi + '/api/public/relation/pause', pr, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultUpdate', data)
      },
      error: (error) => {
        this.sendNextResult('resultUpdateError', error, { method: this.setPauseRP, args: [pr] })
        this.checkStatusError(error);
      },
    });
  }
  searchRP(name) {
    this.http.get(this.urlApi + '/api/public/relation/find/' + name, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.searchRP, args: [name] })
        this.checkStatusError(error);
      },
    });
  }
  getHeaderDetails(id) {
    this.http.get(this.urlApi + '/api/public/relation/detail/header/' + id, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getHeaderDetails, args: [id] })
        this.checkStatusError(error);
      },
    });
  }
  addSubordinate(pr) {
    this.http.post(this.urlApi + '/api/public/relation/subordinate', pr, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.addSubordinate, args: [pr] })
        this.checkStatusError(error);
      },
    });
  }

  getEstadisticasTablaPR(fechaInicio, fechaFin, idPr, event = 0, anual = false) {
    this.http.get(this.urlApi + '/api/public/relation/table/' + fechaInicio + "/" + fechaFin + "/ventas/" + idPr + "/" + event + "/" + anual, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getEstadisticasTablaPR, args: [fechaInicio, fechaFin, idPr, event, anual] })
        this.checkStatusError(error);
      },
    });
  }
  getPREventEstadistics(idPr) {
    this.http.get(this.urlApi + '/api/public/relation/detail/events/' + idPr, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getPREventEstadistics, args: [idPr] })
        this.checkStatusError(error);
      },
    });
  }

  getVentasByPr(idRp, idEvento) {
    this.http.get(this.urlApi + '/api/public/relation/detail/' + idRp + "/event/" + idEvento, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getVentasByPr, args: [idRp, idEvento] })
        this.checkStatusError(error);
      },
    });
  }
  getVentasByEvent(idEvento) {
    this.http.get(this.urlApi + '/api/public/relation/event/' + idEvento, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getVentasByEvent, args: [idEvento] })
        this.checkStatusError(error);
      },
    });
  }

}
