import { Component, OnInit, Input } from '@angular/core';
import { Facility } from 'src/app/models/Facility';

@Component({
  selector: 'app-facility-item',
  templateUrl: './facility-item.component.html',
  styleUrls: ['./facility-item.component.scss'],
})
export class FacilityItemComponent implements OnInit {
  isFocus: boolean = false;

  @Input() facility:Facility | any = null;

  constructor() {}

  ngOnInit(): void {
  }

  // Added for accessibility purposes
  onFocus(): void {
    this.isFocus = true;
  }

  onBlur(): void {
    this.isFocus = false;
  }
}
