import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'ReenviarMail-Popup',
  templateUrl: './ReenviarMail-Popup.component.html',
  styleUrls: ['./ReenviarMail-Popup.component.scss'],
})
export class ReenviarMailPopupComponent implements OnInit {
  // Booleans
  isComplete: boolean = false;
  isEmailFine: boolean = false;

  // Regex
  emailRegex = new RegExp('\\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}\\b');

  // dataToPass
  dataToPass: any = {
    name: null,
    mail: null,
    id: null,
  };

  constructor(
    public dialogRef: MatDialogRef<ReenviarMailPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    public notificationSE:NotificacionesService
  ) {}

  ngOnInit() {
    this.setterFromModalData();
  }

  /**
   * FUNCTIONALITIES
   */

  closePopup() {
    let p = { returnValue: this.dataToPass };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  checkObjectFull() {
    if (this.dataToPass.name && this.dataToPass.mail && this.dataToPass.id && this.isEmailFine) {
      this.isComplete = true
    }else {
      this.isComplete = false
    }
  }

  ifEmail(key,value){
    this.isEmailFine = this.emailRegex.test(value);
    if(!this.isEmailFine){
      this.notificationSE.showWarning('El formato del mail no es correcto');
    }
  }

  /**
   * GETTERS & SETTERS
   */

  checkData(key, value) {
    if(key == 'mail') {
      this.ifEmail(key,value);
    }
    this.dataToPass[key] = value;
    this.checkObjectFull();
  }

  setterFromModalData(){
    this.dataToPass.id = this.modalData.entrada.cart
  }
}
