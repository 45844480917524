<div class="addNewPrContainer">
  <div class="header">
    <p>Añadir nuevo RRPP</p>
    <svg (click)="forceClosePopup()" xmlns="http://www.w3.org/2000/svg" width="20.242" height="20.242"
      viewBox="0 0 20.242 20.242">
      <g id="Grupo_1201" data-name="Grupo 1201" transform="translate(-5674.379 -12806.379)">
        <line id="Línea_323" data-name="Línea 323" x2="16" y2="16" transform="translate(5676.5 12808.5)" fill="none"
          stroke="#0e17b6" stroke-linecap="round" stroke-width="3" />
        <line id="Línea_324" data-name="Línea 324" x1="16" y2="16" transform="translate(5676.5 12808.5)" fill="none"
          stroke="#0e17b6" stroke-linecap="round" stroke-width="3" />
      </g>
    </svg>
  </div>
  <div class="body">
    <ng-container *ngIf="usuarioRegistrado">
      <div class="buscadorContainer">
        <div>
          <button class="btn-xs botonBuscador" (change)="filterUser(filtroCorreo)"><mat-icon>search</mat-icon></button>
          <input type="url" placeholder="Buscar un usuario por email" (change)="filterUser(filtroCorreo)" [(ngModel)]="filtroCorreo">
        </div>
        <div class="listaContainer" *ngIf="openList">
          <span>{{usuarioFiltrado.email}}</span>
          <div>
            <button (click)="invitado = true;sendInvitation()"*ngIf="!invitado">Invitar</button>
            <button class="invitado" *ngIf="invitado">Invitado</button>
          </div>
        </div>
      </div>
      <button class="btn-sm usuarioNoRegistradoBoton" (click)="usuarioRegistrado=false">Añadir un usuario no registrado</button>
    </ng-container>

    <ng-container *ngIf="!usuarioRegistrado">
      <form action="">
        <div class="doubleColumn">
          <FormElement [receiveData]="nombreRRPP" [error]="nombreRRPPError" [type]="'input'" [placeholder]="'Nombre'" [label]="'Nombre'" (sendData)="getNombreRRPP($event)"></FormElement>
          <FormElement [receiveData]="apeliidosRRPP" [error]="apeliidosRRPPError" [type]="'input'" [placeholder]="'Apellidos'" [label]="'Apellidos'" (sendData)="getApellidosRRPP($event)"></FormElement>
        </div>
        <div class="doubleColumn">
          <FormElement [receiveData]="emailRRPP" [error]="emailRRPPError" [type]="'input'" [placeholder]="'Correo electrónico'" [label]="'Correo electrónico'" (sendData)="getEmailRRPP($event)"></FormElement>
          <FormElement [receiveData]="telefonoRRPP" [error]="telefonoRRPPError" [type]="'number'" [placeholder]="'Número de teléfono'" [label]="'Número de teléfono'" (sendData)="getTelefonoRRPP($event)"></FormElement>
        </div>
        <div class="doubleColumn">
          <div class="rango">
            <FormElement [error]="fechaNacimientoRRPPError"[label]="'Fecha de nacimiento'" ></FormElement>
            <input matInput [matDatepicker]="picker" readOnly="true" (dateChange)="getNaciminetoRRPP($event)" placeholder="Fecha de nacimiento"  class="datePicker">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
          <FormElement [receiveData]="dniRRPP" [error]="dniRRPPError" [type]="'input'" [placeholder]="'DNI'" [label]="'DNI'" (sendData)="getDniRRPP($event)"></FormElement>
        </div>
          <FormElement [receiveData]="localidadRRPP" [error]="localidadRRPPError" [type]="'input'" [placeholder]="'Localidad'" [label]="'Localidad'" (sendData)="getLocalidadRRPP($event)"></FormElement>
        <div class="buttonContainer">
          <GeneralButton [buttonText]="'Agregar'" (click)="validarRRPP()"></GeneralButton>
        </div>
      </form>
      <div>
        <label for="">A través de enlace</label>
        <div>
          <button class="btn-xs" (click)="copyToClipBoard()">Copiar</button>
          <input type="url" [title]="actualURL" [value]="actualURL">
        </div>
      </div>
    </ng-container>
  </div>
</div>