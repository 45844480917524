<div class="demographicChartGeneralContainer">
  <div class="canvasContainer">
    <canvas *ngIf="type=='edad'" baseChart [data]="doughnutChartDataEdad" [type]="doughnutChartType" [options]="doughnutChartOptions"></canvas>
    <canvas *ngIf="type=='sexo'" baseChart [data]="doughnutChartDataSexo" [type]="doughnutChartType" [options]="doughnutChartOptions"></canvas>
    <canvas *ngIf="type=='platform'" baseChart [data]="doughnutChartDataPlatform" [type]="doughnutChartType" [options]="doughnutChartOptions"></canvas>
    <canvas *ngIf="type=='categorias'" baseChart [data]="doughnutChartDataInterest" [type]="doughnutChartType" [options]="doughnutChartOptions"></canvas>
  </div>
  <div class="legend" *ngIf="type=='edad'">
    <div class="legend-item">
      <span class="legend-item-icon icon-1" [ngStyle]="{'backgroundColor': '#CCCDDF'}"></span>
      <span class="legend-item-title">Menos de 18 años</span>
    </div>
    <div class="legend-item">
      <span class="legend-item-icon icon-2" [ngStyle]="{'backgroundColor': '#7A7FE6'}"></span>
      <span class="legend-item-title">Entre 18 y 21 años</span>
    </div>
    <div class="legend-item">
      <span class="legend-item-icon icon-3" [ngStyle]="{'backgroundColor': '#5960E1'}"></span>
      <span class="legend-item-title">Entre 21 y 30 años</span>
    </div>
    <div class="legend-item">
      <span class="legend-item-icon icon-4" [ngStyle]="{'backgroundColor': '#1017AF'}"></span>
      <span class="legend-item-title">Entre 30 y 40 años</span>
    </div>
    <div class="legend-item">
      <span class="legend-item-icon icon-4" [ngStyle]="{'backgroundColor': '#686BB0'}"></span>
      <span class="legend-item-title">Entre 40 y 50 años</span>
    </div>
    <div class="legend-item">
      <span class="legend-item-icon icon-6" [ngStyle]="{'backgroundColor': '#74A6D8'}"></span>
      <span class="legend-item-title">Más de 50 años</span>
    </div>
  </div>
  <div class="legend" *ngIf="type=='sexo'">
    <div class="legend-item">
      <span class="legend-item-icon icon-1" [ngStyle]="{'backgroundColor': '#CCCDDF'}"></span>
      <span class="legend-item-title" >Hombres</span>
    </div>
    <div class="legend-item">
      <span class="legend-item-icon icon-2" [ngStyle]="{'backgroundColor': '#7A7FE6'}"></span>
      <span class="legend-item-title">Mujeres</span>
    </div>
  </div>
  
  <div class="legend" *ngIf="type=='platform'">
    <div class="legend-item">
      <span class="legend-item-icon icon-1" [ngStyle]="{'backgroundColor': '#CCCDDF'}"></span>
      <span class="legend-item-title" >Aplicación</span>
    </div>
    <div class="legend-item">
      <span class="legend-item-icon icon-2" [ngStyle]="{'backgroundColor': '#7A7FE6'}"></span>
      <span class="legend-item-title">Web</span>
    </div>
  </div>

  
  <div class="legend" *ngIf="type=='categorias'">
    <div class="legend-item" *ngFor="let key of keys;let i = index">
      <span class="legend-item-icon icon-1" [ngStyle]="{'backgroundColor': $any(doughnutChartDataInterest).datasets[0].backgroundColor[i]}"></span>
      <span class="legend-item-title">{{key}}</span>
    </div>
  </div>
</div>