import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs';
import { Facility } from 'src/app/models/Facility';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { NewFacilityPopupComponent } from 'src/app/popups/NewFacilityPopup/NewFacilityPopup.component';
import { FacilityService } from 'src/app/services/Api/facility.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PopupService } from 'src/app/services/utils/popup.service';

@Component({
  selector: 'CreateEditEvent-Step4',
  templateUrl: './CreateEditEvent-Step4.component.html',
  styleUrls: ['./CreateEditEvent-Step4.component.scss']
})
export class CreateEditEventStep4Component extends UnsubscribesDestroy implements OnInit {

  //OTRAS VARIABLES
  recintos: Array<any> = []

  //VARIABLES DE CONTROL
  recintoSeleccionado: any | null = null
  informacionRecintoDesplegado: Boolean = true;
  editar: Boolean = false;
  loadedData: Boolean = false;
  //INPUTS Y OUTPUTS
  @Input() receiveData: any = null;
  @Output() data = new EventEmitter<any>()
  @Output() validStep = new EventEmitter<Boolean>()
  @Output() dataToUpdate = new EventEmitter<any>()

  constructor(private facilitiesSE: FacilityService, private popupSE: PopupService, private loader: LoaderService, private notificaciones: NotificacionesService, private route: ActivatedRoute) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.receiveData != null) {
      this.receiveData = changes['receiveData'].currentValue;
      this.setData();
    }
  }
  ngOnInit() {
    this.getFacilities()
    if (this.route.snapshot.params['id'] != null || this.route.snapshot.params['id'] != undefined) {
      this.editar = true;
    }
  }

  //FUNCIONALIDADES
  desplegarInformacionRecinto(){
    if (this.informacionRecintoDesplegado) {
      this.informacionRecintoDesplegado = false;
    }else{
      this.informacionRecintoDesplegado = true;
    }
  }
  //GETTERS Y SETTERS
  addNuevoRecinto(){
    this.popupSE.openPopup(NewFacilityPopupComponent);
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(value=>{
      if(value==null){return}
     
      this.recintoSeleccionado = value.returnValue
      if (this.editar) {
        this.recintos.push(value.returnValue)
        this.cambiarFacilityEvento();
        setTimeout(() => {
          this.getFacilities();
        }, 500);
        
       
      }else{
        this.recintos.push(value.returnValue)
      }
      this._unsubInd2.next("");
    })
  }
  setData(){
    this.recintoSeleccionado = this.receiveData
  }
  //LLAMADAS A LA API
  getFacilities() {
    this.facilitiesSE.getFacilities();
    this.facilitiesSE.getResult().pipe(takeUntil(this._unsubInd4)).subscribe(value => {
      if (value == null) { return; }
      this.recintos = value;
      this.loadedData = true;
      this._unsubInd4.next("")
    })
  }
  cambiarFacilityEvento(){
    if (this.editar) {this.dataToUpdate.emit({ element: "facility", value:this.recintoSeleccionado})}
  }
  //VALIDACIONES
  validarPaso4(){
    if (this.recintoSeleccionado == null) {
      this.notificaciones.showError("Debes seleccionar o crear un recinto")
      this.validStep.emit(false)
    }else{
      this.validStep.emit(true);
      this.data.emit(this.recintoSeleccionado)
    }
  }
}
