import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take, takeUntil } from 'rxjs';
import { StaticUtilities } from 'src/app/models/StaticUtilities';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { ConfirmPopupComponent } from 'src/app/popups/confirmPopup/confirmPopup.component';
import { PreviewDocumentsPopupComponent } from 'src/app/popups/previewDocumentsPopup/previewDocumentsPopup.component';
import { CommunitiesService } from 'src/app/services/Api/communities.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PopupService } from 'src/app/services/utils/popup.service';
import { environment } from 'src/environments/environment';


import { CompanyService } from 'src/app/services/Api/company.service';

@Component({
  selector: 'app-CommunityDetails',
  templateUrl: './CommunityDetails.component.html',
  styleUrls: ['./CommunityDetails.component.scss']
})
export class CommunityDetailsComponent extends UnsubscribesDestroy implements OnInit {
  comunidad: any = {}
  //VARIABLES DE CONTROL
  option: Number = 0
  constructor(private popupSE: PopupService, private router: Router, public activatedRoute: ActivatedRoute, private notificaciones: NotificacionesService, private loginSE: LoginService, private communititesSE: CommunitiesService, private loader: LoaderService, private companySE: CompanyService) {
    super();
  }

  ngOnInit(): void {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      this.checkPermisos();
    }
  }
  optionSelected(event) {
    this.option = event;
  }

  getCommunityById() {
    this.communititesSE.getCommunityById(this.activatedRoute.snapshot.params["id"]);
    this.communititesSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return };
      this.loader.closeSlow();
      this.comunidad = value;
      // this.getValuesOnEdit(value)
      this._unsubInd.next("");
    })
    this.communititesSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return };
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    })
  }
  permiso: Boolean = false;
  checkPermisos() {
    if (localStorage.getItem("tipoUser") == null || localStorage.getItem("tipoUser") == undefined || localStorage.getItem("tipoUser") == '["2"]') {
      this.loginSE.logOut();
      this.notificaciones.showError("No tienes permisos para acceder")
    } else {
      if (localStorage.getItem("tipoUser") == '["0"]' || localStorage.getItem("tipoUser") == '["3"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY") && !localStorage.getItem("permisos")?.includes("CONSULT_COMPANY_GROUP")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else {
          if (localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY") && localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY_GROUP")) {
            this.permiso = true;
          }
          this.getCommunityById();

        }
      }
      if (localStorage.getItem("tipoUser") == '["1"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else {
          if (localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY")) {
            this.permiso = true;
          }
          this.getCommunityById();

        }
      }
    }


  }
}
