<div class="confirmGenralContainer prEventsInfoPopup">
  <div class="header">
    <p>Venta evento por relaciones publicas</p>
    <svg (click)="forceClosePopup()" xmlns="http://www.w3.org/2000/svg" width="20.242" height="20.242"
      viewBox="0 0 20.242 20.242">
      <g id="Grupo_1201" data-name="Grupo 1201" transform="translate(-5674.379 -12806.379)">
        <line id="Línea_323" data-name="Línea 323" x2="16" y2="16" transform="translate(5676.5 12808.5)" fill="none"
          stroke="#0e17b6" stroke-linecap="round" stroke-width="3" />
        <line id="Línea_324" data-name="Línea 324" x1="16" y2="16" transform="translate(5676.5 12808.5)" fill="none"
          stroke="#0e17b6" stroke-linecap="round" stroke-width="3" />
      </g>
    </svg>
  </div>
  <div class="body">
    <div class="nombre">
      <span class="titulo">RRPP</span>
      <span class="element" *ngFor="let rp of relacionesPublicas">
        {{rp.name}}
      </span>
    </div>
    <div class="ventasTotales">
      <span class="titulo" title="Ventas totales">Ventas totales</span>
      <span class="element" *ngFor="let rp of relacionesPublicas">
        {{rp.entradas_vendidas}}
        <ng-container *ngIf="rp.entradas_vendidas > 1 || rp.entradas_vendidas == 0">
          entradas
        </ng-container>
        <ng-container *ngIf="rp.entradas_vendidas == 1 ">
          entrada
        </ng-container>
      </span>
    </div>
    <div class="facturacionTotal">
      <span class="titulo" title="Facturación total">Fact. Total</span>
      <span class="element" *ngFor="let rp of relacionesPublicas">
        {{rp.facturacion_total | currency:'EUR'}}
      </span>
    </div>
    <div class="sinGastoGestion">
      <span class="titulo" title="Facturación sin GD">Fact. sin GD</span>
      <span class="element" *ngFor="let rp of relacionesPublicas">
        {{rp.facturacion_sin_gd | currency:'EUR'}}
      </span>
    </div>
    <div class="sinGastoGestionSinIva">
      <span class="titulo" title="Facturación  sin IVA y sin GD">Fact. sin IVA y sin GD</span>
      <span class="element" *ngFor="let rp of relacionesPublicas">
        {{rp.facturacion_sin_gd_sin_iva | currency:'EUR'}}
      </span>
    </div>
    <div class="comision">
      <span class="titulo" title="Comisión sin IVA">Comisión sin IVA</span>
      <span class="element" *ngFor="let rp of relacionesPublicas">
        {{rp.comision_sin_iva | currency:'EUR'}}
      </span>
    </div>
  </div>
</div>
