<PageTemplate>
  <ng-container bodyContent>
    <header [searcherActive]="false" [idEvent]="id" [buttonActive]="true" [sharedActive]="true"
      [buttonAction]="'Editar evento'" [permiso]="permiso"></header>
    <ng-container>
      <div class="event-details-navbar">
        <div class="banner">
          <div class="left-group">
            <mat-spinner class="medium adminSpinner" *ngIf="!dataIsLoaded"></mat-spinner>
            <ng-container *ngIf="dataIsLoaded">
              <label class="switch" (click)="updateEventStatus()" [class.noPermision]="!permiso">
                <input name="active" type="checkbox" [(ngModel)]="event.is_active" [checked]="event.is_active" />
                <span class="slider round"></span>
              </label>
              <div class="active" *ngIf="event.is_active">Activo</div>
              <div class="active" *ngIf="!event.is_active">Inactivo</div>
            </ng-container>
            <div class="date">
              <mat-spinner class="small" *ngIf="!dataIsLoaded"></mat-spinner>
              <p *ngIf="dataIsLoaded">
                {{ event.start_date | date: 'd MMM' | uppercase }}
              </p>
            </div>
            <div class="title">
              <mat-spinner class="medium adminSpinner" *ngIf="!dataIsLoaded"></mat-spinner>
              <p *ngIf="dataIsLoaded">
                {{ event.title }}
              </p>
            </div>
            <div class="separador"></div>
            <div class="time">
              <mat-spinner class="medium" *ngIf="!dataIsLoaded"></mat-spinner>

              <ng-container *ngIf="dataIsLoaded">
                {{ event.start_time?.split("T")[1].split("+")[0].split(":")[0]}}:{{
                event.start_time?.split("T")[1].split("+")[0].split(":")[1]}}
                -
                {{ event.finish_time?.split("T")[1].split("+")[0].split(":")[0]}}:{{
                event.finish_time?.split("T")[1].split("+")[0].split(":")[1]}}
              </ng-container>
            </div>
          </div>
          <div class="right-group"></div>


          <div class="buttonContainer">
            <span class="invite" [class.active]="true" (click)="invite()"> <mat-icon>person_add</mat-icon> Invitar
            </span>
            <span *ngIf="!this.event?.tickets " (click)="deleteEventIfNoTicketsSold()">
              <mat-icon>delete</mat-icon> Eliminar evento</span>
            <span class="download" (click)="getExcel()" *ngIf="this.event?.tickets ">
              <mat-icon>download</mat-icon></span>
          </div>

        </div>
        <nav class="navbar-join">
          <ul>
            <li class="navbar-join-item" [ngClass]="isSection1 ? 'active' : ''">
              <a routerLink="." (click)="onSection1()">Análisis evento</a>
            </li>
            <li class="navbar-join-item" [ngClass]="isSection2 ? 'active' : ''">
              <a routerLink="." (click)="onSection2()">Links y galería</a>
            </li>
            <li class="navbar-join-item" [ngClass]="isSection3 ? 'active' : ''">
              <a routerLink="." (click)="onSection3()">Entradas, listas y reservas</a>
            </li>
            <!-- <li class="navbar-join-item" [ngClass]="isSection4 ? 'active' : ''">
              <a routerLink="." (click)="onSection4()">Logística y transporte</a>
            </li> -->
          </ul>
        </nav>
      </div>
      <EventDetails-Step1 *ngIf="isSection1" (ticketStadistics)="getTicketStadistics($event)"></EventDetails-Step1>
      <EventDetails-Step2 *ngIf="isSection2" (refreshEvent)="refreshEventOutput($event)" [headerImage]="headerImage"
        [event]="event"></EventDetails-Step2>
      <EventDetails-Step3 *ngIf="isSection3" [event]="event"></EventDetails-Step3>
      <EventDetails-Step4 *ngIf="isSection4" [event]="event"></EventDetails-Step4>
    </ng-container>
  </ng-container>
</PageTemplate>
