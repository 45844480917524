<section class="event-sales-global-biweekly-chart">
  <div style="display: block">
    <canvas
      baseChart
      [data]="barChartData"
      [options]="barChartOptions"
      [plugins]="barChartPlugins"
      [type]="barChartType"
      height="420px"
      width="866px"
    >
    </canvas>
  </div>
</section>
