import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { CommunitiesService } from 'src/app/services/Api/communities.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'app-Communities',
  templateUrl: './Communities.component.html',
  styleUrls: ['./Communities.component.scss']
})
export class CommunitiesComponent extends UnsubscribesDestroy implements OnInit {

  communities: Array<any> = []
  communitiesBackup: Array<any> = []
  estadisticas: any = {
    alcance: 0, edad_media: 0, media_mensajes: 0, num_communities: 0
  }
  isDataLoaded: Boolean = false;
  constructor(private loginSE: LoginService, private communitiesSE: CommunitiesService, private loader: LoaderService, private notificaciones: NotificacionesService, private router: Router) {
    super();
  }

  ngOnInit() {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      this.checkPermisos();
    }
  }
  filteredCommunities(event) {
    this.communities = event;
  }
  getCommunitiesStadistics() {
    this.communitiesSE.getCommunitiesStadistics();
    this.communitiesSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return }
      this.loader.closeSlow();

      this.estadisticas = value;
      this._unsubInd.next("");
      setTimeout(() => {
        this.getCommunitiesByGroup();
      }, 500);
    })
    this.communitiesSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }

      this.loader.closeSlow();
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    })
  }
  getCommunitiesByGroup() {
    this.communitiesSE.getAllCommunitiesOfGroup();
    this.communitiesSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe(value => {
      if (value == null) { return }
      this.loader.closeSlow();

      this.communities = value;
      this.communitiesBackup = value;
      this.isDataLoaded = true;
      this._unsubInd2.next("");
    })
    this.communitiesSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }

      this.loader.closeSlow();
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    })
  }
  checkPermisos() {
    if (localStorage.getItem("tipoUser") == null || localStorage.getItem("tipoUser") == undefined || localStorage.getItem("tipoUser") == '["2"]') {
      this.loginSE.logOut();
      this.notificaciones.showError("No tienes permisos para acceder")
    } else {
      if (localStorage.getItem("tipoUser") == '["0"]' || localStorage.getItem("tipoUser") == '["3"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY") && !localStorage.getItem("permisos")?.includes("CONSULT_COMPANY_GROUP")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else {
          this.getCommunitiesStadistics();
        }
      }
    }

  }
  setCompany(community) {
    sessionStorage.setItem("dcmpny", community.company);
    setTimeout(() => {
      this.router.navigate(["/publisher/communities-promotions/my-communities/community-details/" + community.id])

    }, 500);
  }
}
