import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, catchError } from 'rxjs';
import { Facility } from 'src/app/models/Facility';
import { environment } from 'src/environments/environment';
import { BaseService } from './Base.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class TransportService extends BaseService {

  private urlApi = environment.urlApi;

  constructor(private http: HttpClient) {
    super();
  }
  newTransport(transport: any) {
    this.http.post(this.urlApi + '/api/transport', transport, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.newTransport, args: [transport] })
        this.checkStatusError(error);
      },
    });
  }
  updateTransportById(id:number,transport: any) {
    this.http.put(this.urlApi + '/api/transport/'+id, transport, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultUpdate', data)
      },
      error: (error) => {
        this.sendNextResult('resultUpdateError', error, { method: this.updateTransportById, args: [id, transport] })
        this.checkStatusError(error);
      },
    });
  }
  deleteTransport(id: any) {
    var transports_ids:any = []
    let send={transports_ids:transports_ids};
    if(!(id instanceof Array)){send.transports_ids.push(id);}else{send.transports_ids=id;}
    this.http.delete(this.urlApi + '/api/transport',this.sendBodyOptions(send)).subscribe({
      next: (data) => {
        this.sendNextResult('resultDelete', data)
      },
      error: (error) => {
        this.sendNextResult('resultDeleteError', error, { method: this.deleteTransport, args: [id] })
        this.checkStatusError(error);
      },
    });
  }
}
