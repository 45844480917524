<PageTemplate>
  <ng-container bodyContent>
    <header [searcherActive]="false" [buttonActive]="false"></header>
    <div class="managementCommunitiesGeneralContainer">
      <ManagementInfoCard [label]="'Comunidades'" [title]="'Mi comunidad MMB'"
        [description]="'Accede a tus comunidades.'"
        [link]="'/publisher/communities-promotions/my-communities'" [icon]="'/assets/icons/eventos_sec.svg'">
      </ManagementInfoCard>
    </div>
    <div class="managementMarketingGeneralContainer">
      <ManagementInfoCard [label]="'Marketing'" [title]="'CRM'" [description]="'Accede tus bases de datos de cliente.'"
        [link]="'/publisher/communities-promotions/customer-database'" [icon]="'/assets/icons/taquilla.svg'">
      </ManagementInfoCard>
      <ManagementInfoCard [label]="''" [title]="'Campañas'" [description]="'Crea campañas promocionales.'"
      [link]="'/publisher/communities-promotions/promotions'" [icon]="'/assets/icons/campañas.svg'" style="margin-top: 1.1em;">
    </ManagementInfoCard>
    </div>
  </ng-container>
</PageTemplate>