<form class="form-control">
  <div class="left-group">
    <h3>Tipo de entrada</h3>
    <div class="radio-section-card">
      <mat-spinner class="center big" *ngIf="!isDataAvailable"></mat-spinner>
      <ng-container *ngIf="isDataAvailable">
        <div class="radio-group-card" *ngFor="let ticket of event.tickets; let i = index"
          (click)="ticketSeleccionado = i; ticketAux = ticket">
          <input [id]="ticket.id" type="radio" name="ticket" [value]="ticket.id" />
          <label tabindex="0" [for]="ticket.name" class="radio-group-label">{{ ticket.product.description
            }}<small>{{
              ticket.product.fee.toFixed(2) | currency: 'EUR'
              }}</small></label>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="right-group">
    <div class="price">
      <div class="form-group-sm">
        <label for="first-name">Cantidad</label>
        <input id="first-name" type="number" min="0" name="cantidadEntradas" placeholder="0"
          [(ngModel)]="cantidadEntradas" />
      </div>
      <div class="checkout">
        <h3>Precio total</h3>
        <p>
          <span *ngIf="ticketSeleccionado != -1">{{
            event.tickets[ticketSeleccionado].product.fee *
            cantidadEntradas | currency: 'EUR'
            }}
            (I.V.A. incluido)</span>
          <span *ngIf="ticketSeleccionado == -1">0€
            (I.V.A. incluido)</span>
        </p>
      </div>
    </div>
    <div class="checkbox-group" *ngIf="event.age_range != 'NO_RANGE' && event.age_range != 'RANGE_14_17'">
      <label for="adult">Confirmo que el cliente es mayor de edad</label>
      <input id="adult" type="checkbox" name="adult" [(ngModel)]="mayorEdad" />
    </div>

    <button type="button" class="btn-lg-alt" (click)="comprarEntradas(ticketAux)">Comprar</button>
  </div>
</form>