import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-demography-genre-chart',
  templateUrl: './demography-genre-chart.component.html',
  styleUrls: ['./demography-genre-chart.component.scss'],
})
export class DemographyGenreChartComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  @Input() data = {men: 0, women: 0};
  // Include 'any' type in options to avoid library error
  public doughnutChartOptions: any['options'] = {
    responsive: true,
    cutout: '85%',
    // Generic options
    font: { family: 'Rubik' },

    // Specific options
    layout: {
      padding: 20,
    },
    scales: {
      x: {
        ticks: {
          display: false,
        },
        grid: {
          lineWidth: 0,
          drawBorder: false,
        },
      },
      y: {
        ticks: {
          display: false,
        },
        grid: {
          lineWidth: 0,
          drawBorder: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        caretSize: 0,
        enabled: true,
        backgroundColor: '#0e17b6',
        padding: 14,
        displayColors: false,
        callbacks: {},
      },
    },
  };
  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartPlugins = [DataLabelsPlugin];

  public doughnutChartData: ChartData<'doughnut'> = {
    labels: ['Hombres', 'Mujeres'],
    datasets: [
      {
        data: [this.data.men, this.data.women],
        backgroundColor: ['#CCCDDF', '#7A7FE6'],
        borderWidth: 2,
        borderColor: ['#f5f6ff', '#f5f6ff', '#f5f6ff', '#f5f6ff', '#f5f6ff'],
        hoverBackgroundColor: ['#CCCDDF', '#7A7FE6'],
        hoverBorderColor: ['#000', '#000'],
        hoverOffset: 4,
        borderRadius: 50,
        spacing: 2,
      },
    ],
  };

  constructor() {}

  ngOnInit(): void {
    this.doughnutChartData.datasets[0].data = [this.data.men, this.data.women]
  }
}
