import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/Api/login.service';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent implements OnInit {

  loged: boolean = false;
  admin: boolean = false;
  constructor(private loginSE: LoginService) { }

  ngOnInit(): void {
   this.loged = this.loginSE.isLogin();
   this.admin = localStorage.getItem("tipoUser") == '["1"]'
  }

}
