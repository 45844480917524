<div class="section-2">

  <div class="chat-container" [class.mensajeFijadoActivo]="mensajeFijado != null"
    [class.mensajeFijadoActivoAbierto]="mensajeFijado != null && mensajeFijadoIsOpen" id="chat">

    <div class="mensajeFijado" [class.open]="mensajeFijadoIsOpen" id="fijado" *ngIf="mensajeFijado != null">
      <div>
        <img src="/assets/icons/chincheta.svg" alt="">
        <span (click)="goToMessage(mensajeFijado.messageId)">Mensaje fijado</span>
      </div>
      <div class="mensajeCortado">
        <mat-icon
          *ngIf="mensajeFijadoIsOpen && mensajeFijado.imagen != null || mensajeFijadoIsOpen && mensajeFijado.video != null || mensajeFijadoIsOpen && mensajeFijado.documento != null">
          attach_file</mat-icon>
        <span (click)="goToMessage(mensajeFijado.messageId)"
          *ngIf="mensajeFijadoIsOpen">{{mensajeFijado.message}}</span>
      </div>
      <div>
        <img class="desanclar" (click)="unfixMessage()" *ngIf="mensajeFijadoIsOpen"
          src="/assets/icons/desanclar.svg" alt="">

        <img (click)="openMensajeFijado()" src="/assets/icons/abrirMensaje.svg" alt="">
      </div>

    </div>
    <div class="auxiliarContainer">
      <p class="emptyText" *ngIf="mensajes.length == 0">No hay mensajes en este chat</p>
      <div [class.mensajesPropiosContainer]="idUsuario == mensaje.userId"
        [class.mensajesExternosContainer]="idUsuario != mensaje.userId" *ngFor="let mensaje of mensajes">
        <img *ngIf="idUsuario != mensaje.userId" [src]="mensaje.profileImage" onerror="this.src='/assets/images/defaultImagePerfil.png'" alt="">
        <div class="mensajeContainer" [id]="mensaje.messageId">
          <p class="reenviadoTexto" *ngIf="mensaje.reenviado">Reenviado</p>
          <p class="reenviadoTexto" *ngIf="idUsuario != mensaje.userId">{{mensaje.uniqueName}}</p>
          <div class="adjunto" *ngIf="mensaje.imagen != null">
            <img [src]="mensaje.imagen" alt="">
          </div>
          <div class="adjunto" *ngIf="mensaje.video != null">
            <video controls>
              <source [src]="mensaje.video">
            </video>
          </div>
          <div class="adjuntodocumento" *ngIf="mensaje.documento != null">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="14.5" height="16.5" viewBox="0 0 14.5 16.5">
                <g id="Grupo_1757" data-name="Grupo 1757" transform="translate(2478.25 -2771.75)">
                  <path id="Trazado_974" data-name="Trazado 974"
                    d="M2.75,15.25h8.5a2,2,0,0,0,2-2V5L9,.75H2.75a2,2,0,0,0-2,2v10.5A2,2,0,0,0,2.75,15.25Z"
                    transform="translate(-2478 2772)" fill="none" stroke="#1017af" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2" />
                  <path id="Trazado_975" data-name="Trazado 975" d="M13,5.25H8.75V1"
                    transform="translate(-2478 2772)" fill="none" stroke="#1017af" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2" />
                </g>
              </svg>
            </div>
            <div>
              <a [href]="mensaje.urlDocumento | safe" download="file.pdf"> Documento PDF</a>
              <mat-icon> publish</mat-icon>
            </div>
          </div>
          <div class="texto">
            <span>{{mensaje.message}}</span>
            <span (click)="idSelected = mensaje.messageId ;openMenu()"
              [class.menuOpened]="idSelected == mensaje.messageId">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="4" viewBox="0 0 16 4">
                <g id="Grupo_1141" data-name="Grupo 1141" transform="translate(-1741 -412)">
                  <circle id="Elipse_780" data-name="Elipse 780" cx="2" cy="2" r="2"
                    transform="translate(1741 412)" fill="#0e17b6" />
                  <circle id="Elipse_781" data-name="Elipse 781" cx="2" cy="2" r="2"
                    transform="translate(1747 412)" fill="#0e17b6" />
                  <circle id="Elipse_782" data-name="Elipse 782" cx="2" cy="2" r="2"
                    transform="translate(1753 412)" fill="#0e17b6" />
                </g>
              </svg>
            </span>
          </div>
          <div class="menuDesplegableMensaje" *ngIf="isOpen && idSelected == mensaje.messageId">
            <button title="Responder mensaje" *ngIf="false">
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
                <path id="reply"
                  d="M4.75,9.06,9.233,4.75v3.1c5.517,0,5.517,3.966,5.517,6.9a6.51,6.51,0,0,0-5.517-4.483v3.1Z"
                  transform="translate(-4.25 -4.25)" fill="none" stroke="#0e17b6" stroke-linecap="round"
                  stroke-linejoin="round" stroke-width="1" />
              </svg>
            </button>
            <button type="button" (click)="copyMessageText(mensaje)" title="Copiar mensaje">
              <svg xmlns="http://www.w3.org/2000/svg" width="11.592" height="11.592"
                viewBox="0 0 11.592 11.592">
                <g id="Grupo_11527" data-name="Grupo 11527" transform="translate(-739.25 -669.25)">
                  <path id="Trazado_10143" data-name="Trazado 10143"
                    d="M2.028,8.42h0A1.278,1.278,0,0,1,.75,7.142V2.211A1.461,1.461,0,0,1,2.211.75H7.142A1.278,1.278,0,0,1,8.42,2.028"
                    transform="translate(739 669)" fill="none" stroke="#0e17b6" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="1" />
                  <rect id="Rectángulo_7180" data-name="Rectángulo 7180" width="7.67" height="7.67" rx="2"
                    transform="translate(742.672 672.672)" fill="none" stroke="#0e17b6" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="1" />
                </g>
              </svg>
            </button>
            <button *ngIf="false" (click)="fixMessage(mensaje)" title="Fijar mensaje">
              <svg xmlns="http://www.w3.org/2000/svg" width="9.595" height="10.97" viewBox="0 0 9.595 10.97">
                <g id="Grupo_11528" data-name="Grupo 11528" transform="translate(-774.25 -902.25)">
                  <path id="Trazado_10144" data-name="Trazado 10144"
                    d="M2.813,2.813,2.125.75H7.97L7.282,2.813V4.36A3.173,3.173,0,0,1,9.345,7.282H.75A3.173,3.173,0,0,1,2.813,4.36Z"
                    transform="translate(774 902)" fill="none" stroke="#0e17b6" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="1" />
                  <line id="Línea_735" data-name="Línea 735" y2="3.266" transform="translate(779.047 909.454)"
                    fill="none" stroke="#0e17b6" stroke-linecap="round" stroke-linejoin="round"
                    stroke-width="1" />
                </g>
              </svg>
            </button>
            <button title="Reportar mensaje" (click)="confirmarReportar(mensaje)">
              <svg xmlns="http://www.w3.org/2000/svg" width="10.874" height="10.824"
                viewBox="0 0 10.874 10.824">
                <g id="Grupo_11525" data-name="Grupo 11525" transform="translate(-16436.235 -2181.25)">
                  <g id="Grupo_11526" data-name="Grupo 11526" transform="translate(16436.75 2181.75)">
                    <path id="Trazado_10140" data-name="Trazado 10140"
                      d="M.9,8.612,4.462,1.5a1.355,1.355,0,0,1,2.423,0l3.563,7.114a1.355,1.355,0,0,1-1.212,1.962H2.107A1.355,1.355,0,0,1,.9,8.612Z"
                      transform="translate(-0.75 -0.75)" fill="none" stroke="#0e17b6" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="1" />
                    <line id="Línea_734" data-name="Línea 734" y2="2.374" transform="translate(4.922 2.878)"
                      fill="none" stroke="#0e17b6" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="1" />
                    <path id="Trazado_10141" data-name="Trazado 10141"
                      d="M7.458,11a.446.446,0,1,1-.446.446A.447.447,0,0,1,7.458,11"
                      transform="translate(-2.536 -4.053)" fill="#0e17b6" />
                  </g>
                </g>
              </svg>
            </button>
            <button (click)="resendMessage(mensaje)" title="Reenviar mensaje">
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
                <path id="reply"
                  d="M14.75,9.06,10.267,4.75v3.1c-5.517,0-5.517,3.966-5.517,6.9a6.51,6.51,0,0,1,5.517-4.483v3.1Z"
                  transform="translate(-4.25 -4.25)" fill="none" stroke="#0e17b6" stroke-linecap="round"
                  stroke-linejoin="round" stroke-width="1" />
              </svg>
            </button>
            <button (click)="removeMessage(mensaje, 'externo')" title="Eliminar mensaje">
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11.357" viewBox="0 0 11 11.357">
                <g id="Grupo_11529" data-name="Grupo 11529" transform="translate(-16456.25 -2291.25)">
                  <path id="Trazado_10145" data-name="Trazado 10145"
                    d="M2.5,3.75l.6,6.909a1.429,1.429,0,0,0,1.424,1.305H7.976A1.429,1.429,0,0,0,9.4,10.659L10,3.75"
                    transform="translate(16455.5 2290.143)" fill="none" stroke="#0e17b6"
                    stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                  <path id="Trazado_10146" data-name="Trazado 10146"
                    d="M5.5,2.714V2.179A1.428,1.428,0,0,1,6.929.75h.357A1.428,1.428,0,0,1,8.714,2.179v.536"
                    transform="translate(16454.643 2291)" fill="none" stroke="#0e17b6" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="1" />
                  <line id="Línea_736" data-name="Línea 736" x2="10" transform="translate(16456.75 2293.893)"
                    fill="none" stroke="#0e17b6" stroke-linecap="round" stroke-linejoin="round"
                    stroke-width="1" />
                </g>
              </svg>
            </button>
          </div>
        </div>

      </div>
    </div>

  </div>
  <div class="inputChatContainer">
    <div class="accionesContainer" *ngIf="false">
      <button type="button" class="imagenes" (click)="setClickImagen()" fileInput
        [accept]="['image/jpeg', 'image/png']" [(fichero)]="imagen" (ficheroChange)="viewImage()">
        <svg xmlns="http://www.w3.org/2000/svg" width="17.064" height="16.5" viewBox="0 0 17.064 16.5">
          <path id="Trazado_677" data-name="Trazado 677"
            d="M.75,12,3.5,8.507a2,2,0,0,1,3.09-.068L9,11.25M6.915,8.823C7.952,7.5,9.4,5.635,9.491,5.513L9.5,5.5a2,2,0,0,1,3.085-.061L15,8.25m-12.25,7h10.5a2,2,0,0,0,2-2V2.75a2,2,0,0,0-2-2H2.75a2,2,0,0,0-2,2v10.5A2,2,0,0,0,2.75,15.25Z"
            transform="translate(0.654 0.25)" fill="none" stroke="#0e17b6" stroke-linecap="round"
            stroke-linejoin="round" stroke-width="2" />
        </svg>
      </button>
      <button type="button" class="videos" (click)="setClickVideo()" fileInput
        [accept]="['video/mp4', 'video/x-msvideo']" [(fichero)]="video" (ficheroChange)="viewVideo()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16.5" height="16.5" viewBox="0 0 16.5 16.5">
          <g id="Grupo_1756" data-name="Grupo 1756" transform="translate(-364.75 -962.75)">
            <rect id="Rectángulo_6886" data-name="Rectángulo 6886" width="14.5" height="14.5" rx="2"
              transform="translate(365.75 963.75)" fill="none" stroke="#1017af" stroke-linecap="round"
              stroke-linejoin="round" stroke-width="2" />
            <path id="Trazado_973" data-name="Trazado 973" d="M11.25,8,5.75,4.75v6.5Z"
              transform="translate(365 963)" fill="none" stroke="#1017af" stroke-linecap="round"
              stroke-linejoin="round" stroke-width="2" />
          </g>
        </svg>
      </button>
      <button type="button" class="documentos" (click)="setClickDocument()" fileInput
        [accept]="['application/pdf']" [(fichero)]="documento" (ficheroChange)="viewDocument()">
        <svg xmlns="http://www.w3.org/2000/svg" width="14.5" height="16.5" viewBox="0 0 14.5 16.5">
          <g id="Grupo_1757" data-name="Grupo 1757" transform="translate(2478.25 -2771.75)">
            <path id="Trazado_974" data-name="Trazado 974"
              d="M2.75,15.25h8.5a2,2,0,0,0,2-2V5L9,.75H2.75a2,2,0,0,0-2,2v10.5A2,2,0,0,0,2.75,15.25Z"
              transform="translate(-2478 2772)" fill="none" stroke="#1017af" stroke-linecap="round"
              stroke-linejoin="round" stroke-width="2" />
            <path id="Trazado_975" data-name="Trazado 975" d="M13,5.25H8.75V1"
              transform="translate(-2478 2772)" fill="none" stroke="#1017af" stroke-linecap="round"
              stroke-linejoin="round" stroke-width="2" />
          </g>
        </svg>
      </button>
    </div>
    <form action="" (submit)="sendMessage()">
      <input type="text" class="inputData" [(ngModel)]="mensaje" placeholder="Type something" name="texto">
      <button type="submit" class="btn-sm">Enviar</button>
    </form>
  </div>
</div>
