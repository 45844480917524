import { Component, Input, OnInit } from '@angular/core';
import { Event } from 'src/app/models/Event';

@Component({
  selector: 'app-event-item-ticket-store',
  templateUrl: './event-item-ticket-store.component.html',
  styleUrls: ['./event-item-ticket-store.component.scss'],
})
export class EventItemTicketStoreComponent implements OnInit {
  isFocus: boolean = false;

  @Input() event: Event = {
    name: '',
    description: '',
    facility: '',
    location: '',
    capacity: 0,
    partnership: '',
    minAge: 0,
    dresscode: false,
    dresscodeDesc: '',
    schedule: {
      startDate: '',
      endDate: '',
    },
    ticketList: [],
    createdAt: '',
    isActive: false,
    isFavorite: false,
    owner: '',
  };
  constructor() {}

  ngOnInit(): void {
    // Convert JSON Date to UTC Date
    this.event.schedule.startDate = new Date(
      this.event.schedule.startDate
    ).toUTCString();
  }

  // Added for accessibility purposes
  onFocus(): void {
    this.isFocus = true;
  }

  onBlur(): void {
    this.isFocus = false;
  }
}
