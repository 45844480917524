<div class="formElement">
  <label for="" *ngIf="labelActive" [class.vacio]="error">{{label}}</label>
  <!-- INPUT -->
  <ng-container *ngIf="type == 'input'">
    <input type="text" (input)="emitData()" (change)="emitUpdateData()" [(ngModel)]="data" [placeholder]="placeholder">
  </ng-container>
  <ng-container *ngIf="type == 'number'">
    <span class="icono" *ngIf="iconActive">%</span>
    <input type="number" (input)="emitData()" (change)="emitUpdateData()" min="0" [(ngModel)]="data"
      [placeholder]="placeholder" [readOnly]="soloLectura" [class.disabled]="soloLectura">
  </ng-container>
  <!-- FIN INPUT -->
  <!-- TEXT AREA -->
  <ng-container *ngIf="type == 'textArea'">
    <textarea cols="6" [rows]="textAreaRows" (input)="emitData()" (change)="emitUpdateData()" [(ngModel)]="data"
      [placeholder]="placeholder"></textarea>
  </ng-container>
  <!-- FIN TEXT AREA -->
  <!-- PILLS -->
  <ng-container *ngIf="type == 'pills'">
    <mat-spinner class="medium center" *ngIf="!loaded"></mat-spinner>
    <ng-container *ngIf="loaded">
      <div class="pillContainer">
        <span *ngFor="let pill of pills" (click)="addToSelected(pill.name)"
          [class.selected]="pillsSelected.includes(pill.name)">{{pill.name}}</span>
      </div>
    </ng-container>
  </ng-container>
  <!-- FIN PILLS -->
  <!-- SELECTORES -->
  <ng-container *ngIf="type == 'selector'">
    <ng-container *ngIf="selectorType == 'edadesMinimas'">
      <ng-select [items]="options" [(ngModel)]="optionSelected" (change)="emitSelector()" bindLabel="name"
        bindValue="value" [searchable]="false" [clearable]="false" [placeholder]="placeholder">
        <ng-template ng-label-tmp let-item="item">
          <span *ngIf="item.name == 'Variable'"
            matTooltip="La comisión se genera en base a porcentaje del importe de la entrada">{{item.name}}</span>
          <span *ngIf="item.name == 'Fijo'"
            matTooltip="La comisión es una cantidad fija por entrada">{{item.name}}</span>
          <span *ngIf="item.name != 'Fijo' && item.name != 'Variable'">{{item.name}}</span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
          <span *ngIf="item.name == 'Variable'"
            matTooltip="La comisión se genera en base a porcentaje del importe de la entrada">{{item.name}}</span>
          <span *ngIf="item.name == 'Fijo'"
            matTooltip="La comisión es una cantidad fija por entrada">{{item.name}}</span>
          <span *ngIf="item.name != 'Fijo' && item.name != 'Variable'">{{item.name}}</span>
        </ng-template>
      </ng-select>
    </ng-container>
    <ng-container *ngIf="selectorType == 'vestimenta'">
      <ng-select [items]="options" [(ngModel)]="optionSelected" (change)="emitSelector()" [placeholder]="placeholder"
        [searchable]="false" [clearable]="false"></ng-select>
    </ng-container>
  </ng-container>
  <!-- FINS SELECTORES -->
  <!-- TIME -->
  <ng-container *ngIf="type == 'time'">
    <input type="time" (input)="emitData()" (change)="emitUpdateData()" [(ngModel)]="data" [placeholder]="placeholder">
  </ng-container>
  <!-- FIN TIME -->
  <!-- RADIO -->
  <ng-container *ngIf="type == 'radio'">
    <mat-radio-group aria-label="Selecciona una opción" (change)="emitUpdateData();emitData()" [(ngModel)]="data">
      <mat-radio-button [value]="true">{{radioOptions.trueOption}}</mat-radio-button>
      <mat-radio-button [value]="false">{{radioOptions.falseOption}}</mat-radio-button>
    </mat-radio-group>
    <!-- <input type="time" (input)="emitData()" (change)="emitUpdateData()" [(ngModel)]="data" [placeholder]="placeholder"> -->
  </ng-container>
  <!-- FIN RADIO -->
</div>