<PageTemplate>
  <ng-container bodyContent>
    <header [searcherActive]="false"></header>
    <div class="master-container">
      <div class="business-page">
        <section class="options">
          <div class="float-logo">
            <mat-spinner class="medium" *ngIf="!dataLoaded"></mat-spinner>
            <ng-container *ngIf="dataLoaded">
              <img *ngIf="companyProfileImage==''" [ngStyle]="{'background': 'white'}"
              src="/assets/images/imagenDefaultNegocio.svg"  alt="" />
            <img *ngIf="companyProfileImage!=''" [src]="companyProfileImage" onerror="this.src='/assets/images/placeholder.jpg'" alt="" />
            </ng-container>
       
          </div>
          <div class="top-group">
            <mat-spinner class="medium" *ngIf="!dataLoaded"></mat-spinner>
            <ng-container *ngIf="dataLoaded">
              <img *ngIf="bannerImage != ''" onerror="this.src='/assets/images/placeholder.jpg'" [src]="bannerImage" alt="" />
              <img *ngIf="bannerImage == ''" src="/assets/pedro-lastra-Nyvq2juw4_o-unsplash.jpg" alt="" />
            </ng-container>
         
          </div>
          <div class="bottom-group">
            <mat-spinner class="medium" *ngIf="!dataLoaded"></mat-spinner>
            <ng-container *ngIf="dataLoaded">
              <div class="title">
                <h2>{{ company.name }}</h2>
                <a class="btn-sm" [routerLink]="'/admin/social/promotional-page/edit-promotional-page'" [class.noPermision]="!editar">Editar</a>
              </div>
            </ng-container>
           
          </div>
        </section>
        <main>
          <div class="info">
            <div class="left-group">
              <section>
                <h3>Descripción</h3>
                <div class="description">
                  <mat-spinner class="medium" *ngIf="!dataLoaded"></mat-spinner>

                  <ng-container *ngIf="dataLoaded">
                    <p [class.truncate]="!viewMore">{{ company.description }}</p>
                    <span class="verMas" *ngIf="!viewMore" (click)="viewMore = true">+ Ver más</span>
                    <span class="verMas" *ngIf="viewMore" (click)="viewMore = false">- Ver menos</span>
                  </ng-container>
                 
                </div>
              </section>

              <section>
                <h3>Próximos eventos</h3>
                <div class="events">
                  <mat-spinner class="big" *ngIf="!dataLoaded"></mat-spinner>
                  <ng-container  *ngIf="dataLoaded">
                    <div class="card-sm" (click)="setCompany(proximoEvento)" [routerLink]="['/publisher/management/events/my-events/event-details/'+proximoEvento.id]"
                    *ngFor="let proximoEvento of proximosEventos">
                    <div class="card-fecha">
                      {{proximoEvento.startDate | date: 'd' | uppercase }}
                      {{proximoEvento.startDate | date: 'MMM' | uppercase }}
                    </div>
                    <img [src]="proximoEvento.url" onerror="this.src='/assets/images/placeholder.jpg'" alt="" class="card-img" />
                    <div class="card-info">
                      <div class="card-title">
                        <h5>{{proximoEvento.title}}</h5>
                      </div>
                    </div>
                  </div>
                  </ng-container>

                </div>
              </section>
            </div>

            <section class="right-group">
              <h3>Galería</h3>
              <div class="photo one">
                <img src="" alt="" onerror="this.src='/assets/images/placeholder.jpg'">
              </div>
              <div class="photos">
                <ul>
                  <li>
                    <div class="photo-detail two">
                      <img src="" alt=""onerror="this.src='/assets/images/placeholder.jpg'">
                    </div>
                  </li>
                  <li>
                    <div class="photo-detail three">
                      <img src="" alt="" onerror="this.src='/assets/images/placeholder.jpg'">
                    </div>
                  </li>
                  <li>
                    <div class="photo-detail four">
                      <img src="" alt="" onerror="this.src='/assets/images/placeholder.jpg'">
                    </div>
                  </li>
                  <li>
                    <div class="photo-detail five">
                      <img src="" alt="" onerror="this.src='/assets/images/placeholder.jpg'">
                    </div>
                  </li>
                  <li>
                    <div class="photo-detail six">
                      <img src="" alt="" onerror="this.src='/assets/images/placeholder.jpg'">
                    </div>
                  </li>
                  <li>
                    <div class="photo-detail seven">
                      <img src="" alt="" onerror="this.src='/assets/images/placeholder.jpg'">
                    </div>
                  </li>
                  <li>
                    <div class="photo-detail eight">
                      <img src="" alt="" onerror="this.src='/assets/images/placeholder.jpg'">
                    </div>
                  </li>
                  <li>
                    <div class="photo-detail nine">
                      <img src="" alt="" onerror="this.src='/assets/images/placeholder.jpg'">
                    </div>
                  </li>
                </ul>
              </div>
            </section>
          </div>
        </main>
      </div>
      <!-- <footer></footer> -->
    </div>
  </ng-container>
</PageTemplate>