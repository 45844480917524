<headerMovil></headerMovil>
<div class="bodyGeneralContainer">
  <backButtonTitle [titulo]="'Mi perfil'"></backButtonTitle>
  <div class="formGeneralContainer">
    <div class="fotoPerfilContainer"  (click)="setClick('.fotoPerfilContainer button .fileInput')">
      <button fileInput [accept]="['image/jpeg']" [(fichero)]="image"
        (ficheroChange)="setProfileImage(image)"></button>
      <img *ngIf="fotoPerfil==null" src="/assets/images/defaultImagePerfil.png" alt="" />
      <img *ngIf="fotoPerfil!=null" [src]="fotoPerfil" alt="" />
    </div>
    <div class="dataContainer">
      <label for="" [class.vacio]="nombreVacio">Nombre</label>
      <input type="text" [(ngModel)]="nombre" (change)="checkData('name', nombre)" class="inputData" placeholder="Nombre">
    </div>
    <div class="dataContainer">
      <label for="" [class.vacio]="apellidosVacio">Apellidos</label>
      <input type="text" [(ngModel)]="apellidos" (change)="checkData('surname', apellidos)" class="inputData" placeholder="Apellidos">
    </div>
    <div class="dataContainer">
      <label for="" [class.vacio]="telefonoVacio">Teléfono</label>
      <input type="number" [(ngModel)]="telefono" (change)="checkData('phone', telefono)" class="inputData" placeholder="Teléfono">
    </div>
    <div class="dataContainer">
      <label for="" [class.vacio]="fechaNacimientoVacio">Fecha de nacimiento</label>
      <div>
        <input matInput [matDatepicker]="picker" readOnly="true" [value]="fechaNacimiento" (dateChange)="changeDate($event)"
          placeholder="Fecha de nacimiento" class="inputData datePicker">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker class="miPerfil-movil" #picker></mat-datepicker>
      </div>
    </div>
    <div class="dataContainer">
      <label for="" [class.vacio]="mailContactoVacio">Mail de contacto</label>
      <input type="email" disabled [(ngModel)]="mailContacto" class="inputData" placeholder="Mail de contacto">
    </div>
    <div class="dataContainer">
      <a (click)="openChangePassword()">Cambiar contraseña</a>
    </div>
    <div class="dataContainer">
      <button type="button" class="btn-sm" [routerLink]="['/rrpp/home']" >Guardar información</button>
    </div>

  </div>
</div>
<botonFlotante [texto]="'Nueva lista'" *ngIf="false"></botonFlotante>
<botonFlotante [texto]="'Copiar código'" [tipo]="4"></botonFlotante>
