<div class="newTicketGeneralContainer">
  <div class="headerContainer">
    <p>Añadir nuevo recinto</p>
    <svg (click)="forceClosePopup()" xmlns="http://www.w3.org/2000/svg" width="18.243" height="18.243"
      viewBox="0 0 18.243 18.243">
      <g id="Grupo_11743" data-name="Grupo 11743" transform="translate(1.734 2.121)">
        <line id="Línea_323" data-name="Línea 323" x2="14" y2="14" transform="translate(0.387)" fill="none"
          stroke="#070c5b" stroke-linecap="round" stroke-width="3" />
        <line id="Línea_324" data-name="Línea 324" x1="14" y2="14" transform="translate(0.387)" fill="none"
          stroke="#070c5b" stroke-linecap="round" stroke-width="3" />
      </g>
    </svg>
  </div>
  <div class="bodyContainer">
    <form action="">
      <div class="doubleColumn">
        <FormElement [receiveData]="nombreRecinto" [error]="nombreRecintoError" [type]="'input'"
          [placeholder]="'Título del recinto'" [label]="'Título del recinto'" (sendData)="getTituloRecinto($event)">
        </FormElement>
        <FormElement [receiveData]="aforoLocal" class="small" [error]="aforoLocalError" [type]="'number'"
          [placeholder]="'Aforo local'" [label]="'Aforo local'" (sendData)="getAforoLocal($event)">
        </FormElement>
      </div>
      <div class="doubleColumn">
        <FormElement [receiveData]="direccion" [error]="direccionError" [type]="'input'"
          [placeholder]="'Dirección del recinto'" [label]="'Dirección del recinto'" (sendData)="getDireccion($event)">
        </FormElement>
        <FormElement [receiveData]="localidadRecinto" [error]="localidadRecintoError" [type]="'input'"
          [placeholder]="'Localidad del recinto'" [label]="'Localidad del recinto'" (sendData)="getLocalidad($event)">
        </FormElement>
      </div>
    </form>
  </div>
  <div class="footerContainer">
    <GeneralButton (click)="forceClosePopup()" [buttonText]="'Cancelar'" [type]="'secondary'"></GeneralButton>
    <GeneralButton [buttonText]="'Añadir recinto'" (click)="validarRecinto()"></GeneralButton>
  </div>
</div>