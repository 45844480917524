import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, catchError } from 'rxjs';
import { Facility } from 'src/app/models/Facility';
import { environment } from 'src/environments/environment';
import { BaseService } from './Base.service';

@Injectable({
  providedIn: 'root'
})
export class InvoicesService extends BaseService {

  private urlApi = environment.urlApi;

  constructor(private http: HttpClient) {
    super();
  }
  getInvoices(num_devoluciones, num_pagina, companyId, year){
    this.http.get(this.urlApi + '/api/invoice/year/?num_devoluciones='+num_devoluciones+"&num_pagina="+num_pagina+"&companyGroup="+companyId+"&year="+year, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getInvoices, args: [num_devoluciones, num_pagina, companyId, year] })
        this.checkStatusError(error);
      },
    });
  }
}
