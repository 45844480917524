import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { ConfirmPopupComponent } from 'src/app/popups/confirmPopup/confirmPopup.component';
import { FacilityService } from 'src/app/services/Api/facility.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PopupService } from 'src/app/services/utils/popup.service';

@Component({
  selector: 'app-facilities-page',
  templateUrl: './facilities-page.component.html',
  styleUrls: ['./facilities-page.component.scss'],
})
export class FacilitiesPageComponent extends UnsubscribesDestroy implements OnInit {
  facilities: any = [];
  facilitites_backup: any = []
  filtro = ""
  dataIsLoaded: Boolean = false;
  constructor(private loginSE: LoginService, private facilitiesSE: FacilityService, private loaderSE: LoaderService, private notificacionesSE: NotificacionesService, private router: Router, private notificaciones: NotificacionesService, private popupSE: PopupService) {
    super();
  }
  isOpen = false;
  showSearchBar() {
    if (!this.isOpen) {
      this.isOpen = true;
    } else {
      this.isOpen = false
    }
  }
  ngOnInit(): void {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      this.checkPermisos();
    }
  }
  filteredFacilities(event) {
    this.facilities = event;
  }
  openViewFacility(id: number) {
    this.router.navigate(["/publisher/places/facilities/" + id])
  }
  permiso: Boolean = false;
  checkPermisos() {
    if (localStorage.getItem("tipoUser") == null || localStorage.getItem("tipoUser") == undefined || localStorage.getItem("tipoUser") == '["2"]') {
      this.loginSE.logOut();
      this.notificaciones.showError("No tienes permisos para acceder")
    } else {
      if (localStorage.getItem("tipoUser") == '["0"]' || localStorage.getItem("tipoUser") == '["3"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY") && !localStorage.getItem("permisos")?.includes("CONSULT_COMPANY_GROUP")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else {
          if (localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY") && localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY_GROUP")) {
            this.permiso = true;
          }
          this.getFacilities();

        }
      }
      if (localStorage.getItem("tipoUser") == '["1"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else {
          if (localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY")) {
            this.permiso = true;
          }
          this.getFacilities();

        }
      }
    }


  }
  getFacilities() {
    this.facilitiesSE.getFacilities();

    this.facilitiesSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return; }
      this.facilities = value;
      this.facilitites_backup = value;
      this.dataIsLoaded = true;
      this._unsubInd.next("");
    })
    this.facilitiesSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return; }
      this.notificacionesSE.showError(value.error.message)
      this._unsub.next("");
    })
  }
  deleteFacility(event, id) {
    this.popupSE.openPopup(ConfirmPopupComponent, { accion: "Eliminar", tipo: "recinto" })
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd5)).subscribe(value => {
      if (value == null) { return }

      this.facilitiesSE.updateFacilityById(id, { isActive: false });
      this.facilitiesSE.getResultUpdate().pipe(takeUntil(this._unsubInd4)).subscribe(res => {
        if (res == null) { return; }
        this.notificaciones.showFeedBack("Se ha eliminado el recinto correctamente")
        this._unsubInd4.next("")
        this.getFacilities();
      })
      this.facilitiesSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(res => {
        if (res == null) { return; }
        this.notificaciones.showError(res.error.message)
        this._unsub.next("")
      })
      this._unsubInd5.next("");
    })
  }
}
