<div class="stepGeneralContainer">
  <form action="">
    <div class="dataContainer" *ngIf="editar">
      <label for="">Comparte el enlace de invitación a tu comunidad para que los usuarios puedan
        registrarse:</label>
      <input type="url" class="inputData" name="url" readonly="true" [(ngModel)]="urlInvitacion">
    </div>
    <div class="dataContainer">
      <label for="">O invita a usuarios tu mismo:</label>
      <div class="buscadorContainer">
        <div>
          <button class="btn-xs botonBuscador" (click)="filterUser(filtroCorreo)">
            <mat-icon>search</mat-icon>
          </button>
          <input type="url" class="inputData" name="Filtro" placeholder="Buscar un usuario por email" (change)="filterUser(filtroCorreo)"
            [(ngModel)]="filtroCorreo">
        </div>
        <div class="listaContainer" *ngIf="openList">
          <span>{{usuarioFiltrado.email}}</span>
          <div>
            <button (click)="invitarUsuario(usuarioFiltrado.id)" *ngIf="!usuariosInvitados.includes(usuarioFiltrado.id)">Invitar</button>
            <button class="invitado" (click)="invitarUsuario(usuarioFiltrado.id)" *ngIf="usuariosInvitados.includes(usuarioFiltrado.id)">Invitado</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

