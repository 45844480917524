<div class="comunityInformationGeneralContainer">
  <div class="information">
    <div class="cards">
      <Info [value]="estadisticas.num_messages" [loaded]="isDataLoaded" [type]="'Mensajes'"></Info>
      <Info [value]="estadisticas.num_members" [loaded]="isDataLoaded" [type]="'Miembros'"></Info>
    </div>
    <div class="imagenComunidadGeneralContainer">
      <p class="title">Flyer/Portada comunidad</p>
      <div class="imagenComunidad" [ngStyle]="{'position': 'relative'}">
        <mat-spinner class="big center" *ngIf="!isDataLoaded"></mat-spinner>
        <ng-container *ngIf="isDataLoaded">
          <button fileInput [accept]="['image/jpeg']" [(fichero)]="communityProfileImage"
            (ficheroChange)="setCommunityImageProfile(communityProfileImage)"></button>
          <img *ngIf="community.image == null" src="/assets/pedro-lastra-Nyvq2juw4_o-unsplash.jpg" alt="" />
          <img *ngIf="community.image != null" [src]="community.image" onerror="this.src='/assets/pedro-lastra-Nyvq2juw4_o-unsplash.jpg'" alt="" />
          <div class="editImageOverlay" (click)="setClick('.information > .imagenComunidad button .fileInput')">
            <mat-icon>edit</mat-icon>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="descripcion" *ngIf="false">
      <p class="title">Descripción</p>
      <p [class.truncate]="!viewMore">
        {{community.description}}
      </p>
      <span class="verMas" *ngIf="!viewMore" (click)="viewMore = true">+ Ver más</span>
      <span class="verMas" *ngIf="viewMore" (click)="viewMore = false">- Ver menos</span>
    </div>
  </div>
  <div class="members">
    <p class="title">Miembros</p>
    <div class="miembrosContainer">
      <mat-spinner class="big center" *ngIf="!isDataLoaded"></mat-spinner>
      <ng-container *ngIf="isDataLoaded">
        <div *ngFor="let member of community.members">
          <span>
            <img *ngIf="member.user.profile_image != null" [src]="member.user.profile_image" onerror="this.src='/assets/images/defaultImagePerfil.png'" alt="">
            <img *ngIf="member.user.profile_image == null" src="/assets/images/defaultImagePerfil.png" alt="">
          </span>
          <span>{{member.user.name}} {{member.user.surname}}</span>
        </div>
      </ng-container>
    </div>
  </div>
</div>
