<headerMovil></headerMovil>
<div class="bodyGeneralContainer">
  <ng-container *ngIf="idEvento == -1">
    <backButtonTitle [titulo]="'Nueva Lista'"></backButtonTitle>
    <div class="search-bar-nav">
      <img class="search-bar-icon" src="../assets/icons/magnifying-glass-solid.svg" alt="" />
      <input type="text" placeholder="Busca un evento..."  [(ngModel)]="filtro" (input)="filtrarEventos(filtro)"/>
    </div>
    <app-steps class="misEventosMovilPasos"  [pasos]="pasos" [activo]="activo" [navegacionActiva]="true"
      (cambio)="cambiado($event)"></app-steps>
    <ng-container *ngIf="activo == 0">
      <div class="eventosGeneralContainer">
        <div class="carrouselContainer">
          <ng-container *ngFor="let evento of eventosActivosPorSemana">
            <div class="card-vert" (click)="goToSelectedEvent(evento)">
              <img [src]="evento.url" alt="" class="card-img">
              <div class="float-time">
                <span>{{evento.startTime.split("T")[1].split("+")[0].substr(0,
                  evento.startTime.split("T")[1].split("+")[0].length - 3)}} -
                  {{evento.finishTime.split("T")[1].split("+")[0].substr(0,
                  evento.finishTime.split("T")[1].split("+")[0].length - 3)}}</span>
              </div>
              <div class="card-info">
                <div class="card-title">
                  <h5 [title]="evento.nombre">{{evento.title}}</h5>
                </div>
                <div class="card-details">
                  <small>{{evento.facilityName}}</small>
                </div>
              </div>

            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="activo == 1">
      <app-events-calendar class="homeCalendar movilCalendar" [eventos]="eventosCalendario"
        (fechaSeleccionada)="clickFecha($event)" (cambioDeMes)="cambiarMes($event)" [ngStyle]="{'margin-bottom': '2em'}">
      </app-events-calendar>
      <mat-spinner *ngIf="!eventsLoaded" class="big"></mat-spinner>
      <div class="eventosGeneralContainer">
        <div class="carrouselContainer">
          <ng-container *ngFor="let evento of eventosSeleccionados">
            <div class="card-vert" (click)="goToSelectedEvent(evento)">
              <img [src]="evento.url" alt="" class="card-img">
              <div class="float-time">
                <span>{{evento.startTime.split("T")[1].split("+")[0].substr(0,
                  evento.startTime.split("T")[1].split("+")[0].length - 3)}} -
                  {{evento.finishTime.split("T")[1].split("+")[0].substr(0,
                  evento.finishTime.split("T")[1].split("+")[0].length - 3)}}</span>
              </div>
              <div class="card-info">
                <div class="card-title">
                  <h5 [title]="evento.title">{{evento.title}}</h5>
                </div>
                <div class="card-details">
                  <small>{{evento.facilityName}}</small>
                </div>
              </div>

            </div>
          </ng-container>
          <ng-container *ngIf="eventosSeleccionados.length == 0">
            <p class="empty">No hay eventos para esta fecha</p>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="idEvento != -1">
    <backButtonTitle class="nuevaListaEventoSeleccionado"></backButtonTitle>
    <ng-container *ngIf="listaSeleccionada == -1 && isDataLoaded">
      <div *ngFor="let lista of eventoSeleccionado.eventList" (click)="listaSeleccionada = lista.eventListId" class="lista" >
        <div>
          <span>{{lista.name}}</span>
          <span>{{lista.description}}</span>
        </div>
        <div>
          <progress [max]="lista.total" [value]="lista.participantes"></progress>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="listaSeleccionada != -1">
      <p class="blockTitle">Lista - Reservado</p>
      <div class="dataContainer">
        <label for="" [class.vacio]="nombreListaVacio">A nombre de...</label>
        <input type="text" [(ngModel)]="nombreLista" class="inputData" placeholder="Nombre">
      </div>
      <div class="dataContainer especial">
        <label for="" [class.vacio]="numeroPersonasVacio">Número de personas</label>
        <button class="btn-xs less" (click)="less()">-</button>
        <input type="number" [(ngModel)]="numeroPersonas" class="inputData" placeholder="Personas">
        <button class="btn-xs more" (click)="more()">+</button>
      </div>
      <div class="dataContainer">
        <button class="btn-lg" (click)="checkData()">Confirmar reserva</button>
      </div>
      <p class="blockTitle" [class.vacio]="reservadoSelectVacio" *ngIf="false">Zonas de reservas</p>
      <div class="dataContainer" *ngIf="false">
        <ng-select [items]="eventoSeleccionado.facility.floor_plans" bindLabel="name" bindValue="id" [(ngModel)]="reservadoSelect"
          [searchable]="false" [clearable]="false" [placeholder]="'-- Selecciona una reserva --'"></ng-select>
      </div>
      <div class="imageContainer"*ngIf="false">
        <img src="/assets/images/planoEjemplo.png" alt="">
      </div>
    </ng-container>
  </ng-container>
</div>
<botonFlotante [texto]="'Cancelar'" [tipo]="tipoBoton"></botonFlotante>
