<div class="nuevoNegocioGeneralContainer">
  <div class="logoContainer">
    <img src="/assets/icons/logoLinkPr.png" alt="">
  </div>
  <div class="headerContainer">
    <h1>Bienvenido a Memorable</h1>
    <h2>Conecta con miles de usuarios y clientes del mundo del entretenimiento y
      promociona tus mejores eventos</h2>
  </div>
  <div class="dataGeneralContainer">
    <div class="steps">
      <div [class.activo]="pasoSeleccionado == 0">
        <svg xmlns="http://www.w3.org/2000/svg" width="16.5" height="16.5" viewBox="0 0 16.5 16.5">
          <g id="Grupo_1656" data-name="Grupo 1656" transform="translate(-275.75 -513.75)">
            <path id="Trazado_734" data-name="Trazado 734" d="M14.25,7.5v7.75m-12.5,0V7.5m4,7.75V7.5m4.5,7.75V7.5"
              transform="translate(276 514)" fill="none" stroke="#080c57" stroke-linecap="round" stroke-linejoin="round"
              stroke-width="2" />
            <path id="Trazado_735" data-name="Trazado 735" d="M8,.75l7.25,6.5H.75Z" transform="translate(276 514)"
              fill="none" stroke="#080c57" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            <line id="Línea_636" data-name="Línea 636" x2="14.5" transform="translate(276.75 529.25)" fill="none"
              stroke="#080c57" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
          </g>
        </svg>
        <span>Información fiscal</span>
      </div>
      <div [class.activo]="pasoSeleccionado == 1">
        <svg xmlns="http://www.w3.org/2000/svg" width="16.5" height="16.5" viewBox="0 0 16.5 16.5">
          <g id="Grupo_1655" data-name="Grupo 1655" transform="translate(-280.75 -505.75)">
            <circle id="Elipse_970" data-name="Elipse 970" cx="3.25" cy="3.25" r="3.25"
              transform="translate(285.75 510.75)" fill="none" stroke="#080c57" stroke-linecap="round"
              stroke-linejoin="round" stroke-width="2" />
            <path id="Trazado_733" data-name="Trazado 733"
              d="M8,15.25A7.25,7.25,0,0,1,8,.75c6.812,0,7.25,4.375,7.25,7.25V9.25a2,2,0,0,1-4,0V4.75"
              transform="translate(281 506)" fill="none" stroke="#080c57" stroke-linecap="round" stroke-linejoin="round"
              stroke-width="2" />
          </g>
        </svg>

        <span>Información del administrador</span>
      </div>
      <div [class.activo]="pasoSeleccionado == 2">
        <svg xmlns="http://www.w3.org/2000/svg" width="16.5" height="16.5" viewBox="0 0 16.5 16.5">
          <g id="flag-priority-2" transform="translate(-3.75 -3.75)">
            <path id="Trazado_736" data-name="Trazado 736" d="M4.75,5.75v13.5" fill="none" stroke="#080c57"
              stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            <path id="Trazado_737" data-name="Trazado 737"
              d="M4.75,15.25V5.75S6,4.75,9,4.75s4.5,1.5,7,1.5,3.25-.5,3.25-.5l-3.5,4.75,3.5,4.75s-.75,1-3.25,1-4.5-2-7-2A9.31,9.31,0,0,0,4.75,15.25Z"
              fill="none" stroke="#080c57" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
          </g>
        </svg>
        <span>Descripción empresa</span>
      </div>
      <div [class.activo]="pasoSeleccionado == 3">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <g id="Grupo_1657" data-name="Grupo 1657" transform="translate(-291 -775)">
            <circle id="Elipse_974" data-name="Elipse 974" cx="7.25" cy="7.25" r="7.25"
              transform="translate(291.75 775.75)" fill="none" stroke="#080c57" stroke-linecap="round"
              stroke-linejoin="round" stroke-width="1.5" />
            <path id="Trazado_738" data-name="Trazado 738" d="M5.75,8.75l.434.924a1,1,0,0,0,1.772.073l2.294-4"
              transform="translate(291 775)" fill="none" stroke="#080c57" stroke-linecap="round" stroke-linejoin="round"
              stroke-width="1.5" />
          </g>
        </svg>

        <span>Revisar</span>
      </div>
    </div>
    <div class="paso0Container" *ngIf="pasoSeleccionado == 0">
      <div class="dobleColumna">
        <div class="dataContainer">
          <label for="" [class.vacio]="razonSocialNegocioError">Razón social</label>
          <input type="text" class="inputData" placeholder="Razón social" [(ngModel)]="razonSocialNegocio">
        </div>
        <div class="dataContainer">
          <label for="" [class.vacio]="cifNegocioError">CIF</label>
          <input type="text" class="inputData" placeholder="CIF" [(ngModel)]="cifNegocio">
        </div>
      </div>
      <div class="dobleColumna">
        <div class="dataContainer">
          <label for="" [class.vacio]="domicilioFiscalNegocioError">Dirección fiscal</label>
          <input type="text" class="inputData" placeholder="Dirección fiscal" [(ngModel)]="domicilioFiscalNegocio">
        </div>
        <div class="dataContainer">
          <label for="" [class.vacio]="codigoPostalError">Código postal</label>
          <input type="text" class="inputData" placeholder="Código postal" [(ngModel)]="codigoPostal">
        </div>

      </div>
      <div class="dataContainer">
        <label for="" [class.vacio]="nombreComercialNegocioError">Nombre comercial</label>
        <input type="text" class="inputData" placeholder="Nombre comercial" [(ngModel)]="nombreComercialNegocio">
      </div>
    </div>
    <div class="paso1Container" *ngIf="pasoSeleccionado == 1">
      <div class="dobleColumna">
        <div class="dataContainer">
          <label for="" [class.vacio]="nombreAdministradorNegocioError">Nombre del administrador</label>
          <input type="url" class="inputData" placeholder="Nombre del usuario" [(ngModel)]="nombreAdministradorNegocio">
        </div>
        <div class="dataContainer">
          <label for="" [class.vacio]="nombreAdministradorNegocioError">Apellidos del administrador</label>
          <input type="url" class="inputData" placeholder="Apellidos del administrador"
            [(ngModel)]="apellidosAdministradorNegocio">
        </div>
      </div>
      <div class="dobleColumna">
        <div class="dataContainer">
          <label for="" [class.vacio]="dniError">DNI</label>
          <input type="email" class="inputData" placeholder="DNI" [(ngModel)]="dni">
        </div>

        <div class="dataContainer">
          <label for="" [class.vacio]="telefonoAdministradorNegocioError">Teléfono</label>
          <input type="number" class="inputData" placeholder="Número de teléfono del administrador"
            [(ngModel)]="telefonoAdministradorNegocio">
        </div>
      </div>
      <div class="dobleColumna">
        <div class="dataContainer">
          <label for="" [class.vacio]="emailAdministracionNegocioError">E-mail usuario</label>
          <input type="email" class="inputData" placeholder="Email del administrador"
            [(ngModel)]="emailAdministracionNegocio">
        </div>
        <div class="dataContainer">
          <label for="" [class.vacio]="repetirEmailAdminsitradorError">Repetir email</label>
          <input type="email" class="inputData" placeholder="Repetir email del administrador"
            [(ngModel)]="repetirEmailAdminsitrador">
        </div>
      </div>
      <div class="dobleColumna">
        <div class="dataContainer">
          <label for="">Género:</label>
          <ng-select [items]="generos" [(ngModel)]="genero" [searchable]="false" [placeholder]="'- A rellenar -'"
            [clearable]="false"></ng-select>
        </div>
        <div class="dataContainer">
          <label [class.vacio]="fechaNacimientoAdministradorError">Fecha de nacimiento</label>
          <div>
            <input matInput [matDatepicker]="picker"  readOnly="true" [value]="fechaNacimientoAdministrador" (dateChange)="changeDate($event)"
              placeholder="Fecha de nacimiento" class="inputData datePicker">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker class="miPerfil-movil" #picker></mat-datepicker>
          </div>
        </div>
      
      </div>
      <div class="dobleColumna">
        <div class="dataContainer">
          <label [class.vacio]="newPassError" for="">Introduce una nueva contraseña</label>
          <div class="especial" *ngIf="!visible">
            <mat-icon (click)="visible = true">visibility</mat-icon>
            <input type="password" [(ngModel)]="newPass" placeholder="Nueva contraseña" class="inputData">
          </div>
          <div class="especial" *ngIf="visible">
            <mat-icon (click)="visible = false">visibility_off</mat-icon>
            <input type="text" [(ngModel)]="newPass" placeholder="Nueva contraseña" class="inputData">
          </div>
        </div>
        <div class="dataContainer">
          <label for=""  [class.vacio]="repeatNewPassError">Repetir contraseña</label>
          <div class="especial" *ngIf="!visibleRepeat">
            <mat-icon (click)="visibleRepeat = true">visibility</mat-icon>
            <input type="password" [(ngModel)]="repeatNewPass" placeholder="Repetir nueva contraseña" class="inputData">
          </div>
          <div class="especial" *ngIf="visibleRepeat">
            <mat-icon (click)="visibleRepeat = false">visibility_off</mat-icon>
            <input type="text" [(ngModel)]="repeatNewPass" placeholder="Repetir nueva contraseña" class="inputData">
          </div>
        </div>
      </div>
    </div>
    <div class="paso2Container" *ngIf="pasoSeleccionado == 2">
      <div class="dataContainer">
        <label for="" [class.vacio]="categoriasSeleccionadasError">¿Qué actividad realiza tu negocio? (Puedes
          seleccionar varias)</label>
        <div class="pillContainer">
          <span class="pill" (click)="seleccionarCategoria(categoria)"
            [class.active]="categoriasSeleccionadas.includes(categoria)"
            *ngFor="let categoria of categorias">{{categoria}}</span>
        </div>
      </div>
      <div class="dataContainer">
        <label for="" [class.vacio]="paginaWebNegocioError">Página web del negocio:</label>
        <input type="url" class="inputData" placeholder="Página web" [(ngModel)]="paginaWebNegocio">
      </div>
    </div>
    <div class="paso3Container" *ngIf="pasoSeleccionado == 3">
      <div class="condicionesTerminos">
        <div>
          <p><b>Nombre comercial: </b>{{nombreComercialNegocio}}</p>
          <p><b>Razón social: </b>{{razonSocialNegocio}}</p>
          <p><b>CIF: </b>{{cifNegocio}}</p>
          <p><b>Domicilio fiscal: </b>{{domicilioFiscalNegocio}}</p>
        </div>
        <div class="separador"></div>
        <div>
          <p><b>Nombre administrador: </b>{{nombreAdministradorNegocio}}</p>
          <p><b>Apellidos administrador: </b>{{apellidosAdministradorNegocio}}</p>
          <p><b>Email: </b>{{emailAdministracionNegocio}}</p>
          <p><b>Teléfono: </b>{{telefonoAdministradorNegocio}}</p>
        </div>
      </div>
      <div>
        <mat-checkbox [(ngModel)]="terminosConcicionesAceptados"></mat-checkbox>
        <label for="">Acepto los <a
            href="https://admin.grupomemorable.com/documents/INFORMACION%20GENERAL%20Y%20AVISO%20LEGAL..pdf"
            target="_blank">términos y condiciones</a> de Memorable S.L.</label>
      </div>
      <div>
        <mat-checkbox [(ngModel)]="cockiesPrivacidadAceptados"></mat-checkbox>
        <label for="">Acepto las <a
            href="https://admin.grupomemorable.com/documents/CONDICIONES%20COOKIES.%20Particular.pdf"
            target="_blank">politicas de cookies</a> y <a
            href="https://admin.grupomemorable.com/documents/CONDICIONES%20DE%20LA%20PROTECCI%C3%93N%20DE%20DATOS..pdf"
            target="_blank">protección de datos</a> de Memorable S.L.</label>
      </div>
      <div>
        <mat-checkbox [(ngModel)]="marketingMail"></mat-checkbox>
        <label for="">Deseo recibir información promocional de Memorable.</label>
      </div>
    </div>
  </div>
  <div class="navegation">
    <button (click)="volverPaso()" [disabled]="pasoSeleccionado < 1">
      Atrás
    </button>
    <button (click)="validatePaso0()" *ngIf="pasoSeleccionado == 0">
      Siguiente
    </button>
    <button (click)="validatePaso1()" *ngIf="pasoSeleccionado == 1">
      Siguiente
    </button>
    <button (click)="validatePaso2()" *ngIf="pasoSeleccionado == 2">
      Siguiente
    </button>
    <button *ngIf="pasoSeleccionado == 3" (click)="sendData()"
      [disabled]="!terminosConcicionesAceptados || !cockiesPrivacidadAceptados">
      Crear nuevo negocio
    </button>
  </div>
</div>