<div class="changePasswordGeneralContainer">
  <div class="header">
    <svg (click)="forceClosePopup()" xmlns="http://www.w3.org/2000/svg" width="20.242" height="20.242"
      viewBox="0 0 20.242 20.242">
      <g id="Grupo_1201" data-name="Grupo 1201" transform="translate(-5674.379 -12806.379)">
        <line id="Línea_323" data-name="Línea 323" x2="16" y2="16" transform="translate(5676.5 12808.5)" fill="none"
          stroke="#0e17b6" stroke-linecap="round" stroke-width="3" />
        <line id="Línea_324" data-name="Línea 324" x1="16" y2="16" transform="translate(5676.5 12808.5)" fill="none"
          stroke="#0e17b6" stroke-linecap="round" stroke-width="3" />
      </g>
    </svg>
  </div>
  <div class="body">
    <div class="dataContainer">
      <label for="">Introduce la vieja contraseña</label>
      <div class="especial" *ngIf="!visibleOld">
        <mat-icon (click)="visibleOld = true">visibility</mat-icon>
        <input type="password" [(ngModel)]="oldPass" placeholder="Vieja contraseña" class="inputData">
      </div>
      <div class="especial" *ngIf="visibleOld">
        <mat-icon (click)="visibleOld = false">visibility_off</mat-icon>
        <input type="text" [(ngModel)]="oldPass" placeholder="Vieja contraseña" class="inputData">
      </div>
    </div>
    <div class="dataContainer">
      <label for="">Introduce una nueva contraseña</label>
      <div class="especial" *ngIf="!visible">
        <mat-icon (click)="visible = true">visibility</mat-icon>
        <input type="password" [(ngModel)]="newPass" placeholder="Nueva contraseña" class="inputData">
      </div>
      <div class="especial" *ngIf="visible">
        <mat-icon (click)="visible = false">visibility_off</mat-icon>
        <input type="text" [(ngModel)]="newPass" placeholder="Nueva contraseña" class="inputData">
      </div>
    </div>
    <div class="dataContainer">
      <label for="">Repetir contraseña</label>
      <div class="especial" *ngIf="!visibleRepeat">
        <mat-icon (click)="visibleRepeat = true">visibility</mat-icon>
        <input type="password" [(ngModel)]="repeatNewPass" placeholder="Repetir nueva contraseña" class="inputData">
      </div>
      <div class="especial" *ngIf="visibleRepeat">
        <mat-icon (click)="visibleRepeat = false">visibility_off</mat-icon>
        <input type="text" [(ngModel)]="repeatNewPass" placeholder="Repetir nueva contraseña" class="inputData">
      </div>
    </div>
  </div>
  <div class="footer">
    <button class="btn-sm" (click)="validateData()" type="button">Confirmar</button>
    <button class="btn-sm cancel" type="button" (click)="forceClosePopup()">Cancelar</button>
  </div>
</div>