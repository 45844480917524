<div class="headerGeneralContainer">
  <div class="breadcrumsContainer">
    <ng-container *ngFor="let breadCrum of breadCrums">
      <span [routerLink]="[breadCrum.link]" >
        {{breadCrum.name}}
      </span>
      <span class="separator">></span>
    </ng-container>
  </div>
  <searcher *ngIf="searcherActive"></searcher>
  <div class="buttonContainer">
    <GeneralButton *ngIf="buttonActive" [buttonText]="buttonAction" (click)="redirectTo()" [class.noPermision]="!permiso"></GeneralButton>
  </div>
    <ng-select [items]="years" *ngIf="selectActive" [(ngModel)]="year" (change)="setYear(year)" [searchable]="false"
  [clearable]="false"></ng-select>
</div>
