<PageTemplate>
  <ng-container bodyContent>
    <header [searcherActive]="false"></header>
    <div class="pageHeader">
      <h1>Base de datos de las Comunidades de Memorable</h1>
      <div class="reportes">
        <div class="message">
          <mat-icon>warning</mat-icon>
          <span>Avisos de reportes</span>
        </div>
        <div class="numberReports">
          <div class="circle"></div>
          <span>12</span>
        </div>
      </div>
    </div>
    <searcher [size]="'fullWidth'" [placeHolder]="'Busca aquí un reporte'"></searcher>
    <div class="table">
      <div class="tableHeader">
        <div class="comunnityName">
          <mat-icon (click)="changeOrder('name')">swap_vert</mat-icon>
          <span>Nombre comunidad</span>
        </div>
        <div class="state">
          <mat-icon>swap_vert</mat-icon>
          <span>Estado</span>
        </div>
        <div class="totalMembers">
          <mat-icon>swap_vert</mat-icon>
          <span>Miembros</span>
        </div>
        <div class="bussines">
          <mat-icon>swap_vert</mat-icon>
          <span>Creación</span>
        </div>
        <div class="intereses">
          <span>Intereses</span>
        </div>
        <div class="deleteAll">
          <mat-icon (click)="multipleDelete()">delete</mat-icon>
        </div>
      </div>
      <ng-container *ngIf="!isDataLoaded">
        <mat-spinner class="big center"></mat-spinner>

      </ng-container>
      <ng-container *ngIf="isDataLoaded">
        <p class="noData" *ngIf="reportes.length == 0">No hay reportes disponibles</p>
        <ng-container *ngIf="reportes.length != 0">
          <div class="tableRows" *ngFor="let reporte of reportes" [routerLink]="['/superadmin/social/report-detail/'+reporte.communityId]" routerLinkActive="router-link-active" >
            <div class="comunnityName">
              <span>{{reporte.communityName}}</span>
            </div>
            <div class="state">
              <span *ngIf="reporte.state" class="revisada">Revisada</span>
              <span *ngIf="!reporte.state" class="noRevisada">No revisada</span>
            </div>
            <div class="totalMembers">
              <span>{{reporte.members}} miembros</span>
            </div>
            <div class="bussines">
              <span>{{reporte.reportDate | date: 'd'}} {{reporte.reportDate | date: 'MMM'}} {{reporte.reportDate | date: 'YYYY'}}</span>
            </div>
            <div class="intereses">
              <span>{{reporte.category}}</span>
            </div>
            <div class="deleteAll">
              <mat-icon (click)="singleDelete(reporte.communityId); $event.stopPropagation()">delete</mat-icon>
            </div>
          </div>
        </ng-container>

      </ng-container>
    </div>
  </ng-container>
</PageTemplate>
