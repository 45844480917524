import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EventService } from 'src/app/services/Api/event.service';
import { Event } from 'src/app/models/Event';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { LoaderService } from 'src/app/services/utils/loader.service';

@Component({
  selector: 'app-events-ticket-store',
  templateUrl: './events-ticket-store.component.html',
  styleUrls: ['./events-ticket-store.component.scss'],
})
export class EventsTicketStoreComponent extends UnsubscribesDestroy implements OnInit, OnChanges {
  isDataAvailable: boolean = false;
  events: any = [];
  eventsAuxiliar: any = []
  enlace = false;
  @Input() filtro:string = ""
  constructor(private eventService: EventService, private notificaciones:NotificacionesService, private loader: LoaderService) {
    super();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.events = this.eventsAuxiliar.filter(element=>element["title"].toLowerCase().includes(changes['filtro'].currentValue.toLowerCase()) || element["name"].toLowerCase().includes(changes['filtro'].currentValue.toLowerCase()));
  }

  ngOnInit(): void {
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    this.eventService.getEvents(100,1, date);
    this.loader.open();
    this.eventService.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value=>{
      if(value==null){return};
      this.events = value;
      this.eventsAuxiliar = value;
      this.events.reverse();
      this.isDataAvailable = true; 
      this.loader.closeSlow();
      this._unsubInd.next("");
    })
  }
}
