import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-demography-age-chart',
  templateUrl: './demography-age-chart.component.html',
  styleUrls: ['./demography-age-chart.component.scss'],
})
export class DemographyAgeChartComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  @Input() data = {catorce_a_dieciocho: 0, dieciocho_a_veintiuno: 0, veintiuno: 0, treinta: 0, cuarenta: 0, cincuenta: 0};
  // Include 'any' type in options to avoid library error
  public doughnutChartOptions: any['options'] = {
    responsive: true,
    cutout: '85%',
    // Generic options
    font: { family: 'Rubik' },

    // Specific options
    layout: {
      padding: 20,
    },
    scales: {
      x: {
        ticks: {
          display: false,
        },
        grid: {
          lineWidth: 0,
          drawBorder: false,
        },
      },
      y: {
        ticks: {
          display: false,
        },
        grid: {
          lineWidth: 0,
          drawBorder: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        caretSize: 0,
        enabled: true,
        backgroundColor: '#0e17b6',
        padding: 14,
        displayColors: false,
        callbacks: {},
      },
    },
  };
  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartPlugins = [DataLabelsPlugin];

  public doughnutChartData: ChartData<'doughnut'> = {
    labels: [
      'Menores de 18 años',
      'Entre 18 y 21 años',
      'Entre 21 y 30',
      'Entre 30 y 40 años',
      'Entre 40 y 50 años',
      'Más de 50',
    ],
    datasets: [
      {
        data: [this.data[1418], this.data[1821], this.data[21], this.data[30], this.data[40], this.data[50]],
        backgroundColor: [
          '#CCCDDF',
          '#7A7FE6',
          '#5960E1',
          '#1017AF',
          '#686BB0',
          '#74A6D8'
        ],
        hoverBackgroundColor: [
          '#CCCDDF',
          '#7A7FE6',
          '#5960E1',
          '#1017AF',
          '#686BB0',
          '#74A6D8'
        ],
        borderWidth: 2,
        borderColor: ['#f5f6ff', '#f5f6ff', '#f5f6ff', '#f5f6ff', '#f5f6ff'],
        hoverBorderColor: ['#000', '#000', '#000', '#000', '#000'],
        hoverOffset: 4,
        borderRadius: 50,
        spacing: 2,
      },
    ],
  };

  constructor() {}

  ngOnInit(): void {
    this.doughnutChartData.datasets[0].data = [this.data.catorce_a_dieciocho, this.data.dieciocho_a_veintiuno, this.data.veintiuno, this.data.treinta, this.data.cuarenta, this.data.cincuenta]
  }
}
