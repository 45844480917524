import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { CustomDatesPopupComponent } from 'src/app/popups/CustomDatesPopup/CustomDatesPopup.component';
import { EventService } from 'src/app/services/Api/event.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { StadisticsService } from 'src/app/services/Api/stadistics.service';
import { UserService } from 'src/app/services/Api/user.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PopupService } from 'src/app/services/utils/popup.service';

@Component({
  selector: 'app-HomeSA',
  templateUrl: './HomeSA.component.html',
  styleUrls: ['./HomeSA.component.scss']
})
export class HomeSAComponent extends UnsubscribesDestroy implements OnInit {
  dateSteps: Array<String> = ["7 días", "14 días", "Mensual", "Anual", "Personalizado"]
  estadisticas: any = { entradas_vendidas: 0, event_week: 0, new_pr: 0, travel_pack_sold: 0, ventas: 0 }
  isDataLoaded: Boolean = false;
  dataIsLoadedAuxiliar: Boolean = false;
  toggleElements: Array<String> = ["Ventas euros", "Ventas unidades"]
  selectedStep: Number = 0
  selectedToggle: Number = 0
  events: Array<any> = [];
  eventSelected: Number | null = null
  fecha = new Date();
  eventosSeleccionados: any = [];
  tipo: String = "ventas";
  datosGrafica: any = []
  inicio: any = null
  fin: any = null;
  constructor(private loginSE: LoginService, private estadisticasSE: StadisticsService, private notificaciones: NotificacionesService, private popupSE: PopupService, private eventsSE: EventService, private loader: LoaderService, private userSE: UserService) {
    super();
  }

  ngOnInit() {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      this.checkPermisos();
    }
  }
  getCustomDays(startDate, endDate, steps = 1) {
    const dateArray: any = [];
    let currentDate = new Date(startDate);

    while (currentDate <= new Date(endDate)) {
      dateArray.push(new Date(currentDate).getDate());
      // Use UTC date to prevent problems with time zones and DST
      currentDate.setUTCDate(currentDate.getUTCDate() + steps);
    }
    if (dateArray.length > 31) {
      return true;
    } else {
      return false;
    }
  }
  getEvents() {
    this.events = []
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    this.eventsSE.getEventByCompanyGroup(50000, 1)
    // this.loader.open();
    this.eventsSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return; }
      this.events = value;
      this.loader.closeSlow();
      this._unsubInd.next("")
    })
    this.eventsSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return; }
      this.loader.closeSlow();
      this._unsub.next("")
    })
  }
  getHomeStadistics(fechaInicio?: any, fechaFin?: any) {

    switch (this.selectedStep) {
      case 0:
        this.estadisticasSE.getStadisticsSuperAdmin(this.getWeeks().firstDay, this.getWeeks().lastDay);
        break;
      case 1:
        this.estadisticasSE.getStadisticsSuperAdmin(this.getWeeks(14).firstDay, this.getWeeks(14).lastDay);
        break;
      case 2:
        this.estadisticasSE.getStadisticsSuperAdmin(this.getDaysInMonth((new Date().getMonth() + 1), new Date().getFullYear()).firstDay, this.getDaysInMonth((new Date().getMonth() + 1), new Date().getFullYear()).lastDay);
        break;
      case 3:
        this.estadisticasSE.getStadisticsSuperAdmin(this.getDaysInYear().firstDay, this.getDaysInYear().lastDay);
        break;
      case 4:
        this.estadisticasSE.getStadisticsSuperAdmin(fechaInicio, fechaFin);
        break;
    }

    this.estadisticasSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe(value => {
      if (value == null) { return }

      this.estadisticas = value;
      this.isDataLoaded = true;
      this._unsubInd2.next("");
    })
    this.estadisticasSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    })
  }
  getTableStadistics(fechaInicio?: any, fechaFin?: any) {
    switch (this.selectedStep) {
      case 0:
        this.estadisticasSE.getStadisticsSuperTable(this.getWeeks().firstDay, this.getWeeks().lastDay, this.tipo);
        break;
      case 1:
        this.estadisticasSE.getStadisticsSuperTable(this.getWeeks(14).firstDay, this.getWeeks(14).lastDay, this.tipo);
        break;
      case 2:
        this.estadisticasSE.getStadisticsSuperTable(this.getDaysInMonth((new Date().getMonth() + 1), new Date().getFullYear()).firstDay, this.getDaysInMonth((new Date().getMonth() + 1), new Date().getFullYear()).lastDay, this.tipo);
        break;
      case 3:
        this.estadisticasSE.getStadisticsSuperTable(this.getDaysInYear().firstDay, this.getDaysInYear().lastDay, this.tipo, true);
        break;
      case 4:
        this.estadisticasSE.getStadisticsSuperTable(fechaInicio, fechaFin, this.tipo, this.getCustomDays(fechaInicio, fechaFin));
        break;
    }
    this.estadisticasSE.getResultIndividual().pipe(takeUntil(this._unsubInd4)).subscribe(value => {
      if (value == null) { return }
      this.datosGrafica = []
      this.datosGrafica = value;
      this.dataIsLoadedAuxiliar = true;
      this._unsubInd4.next("");
    })
    this.estadisticasSE.getResultIndividual().pipe(takeUntil(this._unsubInd4)).subscribe(value => {
      if (value == null) { return }
      this.notificaciones.showError(value.error.message)
      this._unsubInd4.next("");
    })
  }


  changeToggle(event: Number) {
    this.selectedToggle = event
    if (this.selectedToggle == 0) {
      this.tipo = "ventas"
      this.dataIsLoadedAuxiliar = false;
      if (this.eventSelected == null) {
        this.getTableStadistics(this.inicio, this.fin)
      }
    } else {
      this.dataIsLoadedAuxiliar = false;
      this.tipo = "unidades"
      if (this.eventSelected == null) {
        this.getTableStadistics(this.inicio, this.fin)
      }
    }
  }
  changeEvent() {
    this.datosGrafica = []
    this.dataIsLoadedAuxiliar = false;

    if (this.eventSelected == null) {
      this.getTableStadistics(this.inicio, this.fin)
    }
  }

  changeStep(event: Number) {
    this.selectedStep = event
    if (event == 4) {
      this.dataIsLoadedAuxiliar = false;
      this.popupSE.openPopup(CustomDatesPopupComponent);
      this.popupSE.returnData().pipe(takeUntil(this._unsubInd6)).subscribe(value => {
        if (value == null) { return; }
        this.inicio = new Date(value.returnValue.fechaInicio).getFullYear() + "-" + (new Date(value.returnValue.fechaInicio).getMonth() + 1) + "-" + new Date(value.returnValue.fechaInicio).getDate()
        this.fin = new Date(value.returnValue.fechaFin).getFullYear() + "-" + (new Date(value.returnValue.fechaFin).getMonth() + 1) + "-" + new Date(value.returnValue.fechaFin).getDate()

        this.getHomeStadistics(this.inicio, this.fin);
        this.getTableStadistics(this.inicio, this.fin)
        this._unsubInd6.next("")
      })
    } else {

      if (this.eventSelected == null) {
        this.getHomeStadistics();
        this.getTableStadistics(this.inicio, this.fin)
      }

    }
  }

  getWeeks(cantidadDias: number = 7) {
    const date = (() => {
      const now = new Date();
      return new Date(now.setDate(now.getDate() - now.getDay() - -1));
    })();
    const week = Array(cantidadDias).fill(date).map((date, i) => {
      if (i !== 0) {
        date.setDate(date.getDate() + 1);
      }
      return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    });
    var dates = { firstDay: week[0], lastDay: week[week.length - 1] }
    return dates;
  }
  getDaysInMonth(month, year) {
    var date = new Date(year, month - 1, 1);
    var days: any = [];
    for (let i = 1; i <= new Date(year, month, 0).getDate(); i++) {
      days.push(date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + i);

    }
    return { firstDay: days[0], lastDay: days[days.length - 1] }

  }
  getDaysInYear() {
    var firstDay = new Date().getFullYear() + "-01-" + new Date(new Date().getFullYear(), 0, 1).getDate()
    var lastDay = new Date().getFullYear() + "-12-" + new Date(new Date().getFullYear(), 11, 31).getDate()
    return { firstDay: firstDay, lastDay: lastDay }
  }

  checkPermisos() {
    if (localStorage.getItem("tipoUser") == null || localStorage.getItem("tipoUser") == undefined || localStorage.getItem("tipoUser") != '["3"]') {
      this.loginSE.logOut();
      this.notificaciones.showError("No tienes permisos para acceder")
    } else {
      if (localStorage.getItem("tipoUser") == '["3"]') {
        this.getHomeStadistics();
        this.getTableStadistics();
        setTimeout(() => {
          this.getEvents();
        }, 1000);
      }
    }

  }
}
