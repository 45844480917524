import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, catchError, BehaviorSubject } from 'rxjs';
import { Event } from 'src/app/models/Event';
import { environment } from 'src/environments/environment';
import { BaseService } from './Base.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class EventService extends BaseService {
  private apiUrl: string = environment.urlAPI;
  private urlApi = environment.urlApi;
  constructor(private http: HttpClient) {
    super();
  }

  // BehaviourSubject For things

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      console.error('An error occurred:', error.error);
    } else {
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }
    return throwError(
      () => new Error('Something bad happened; please try again later.')
    );
  }

  getEvents_old(): Observable<Event[]> {
    return this.http
      .get<Event[]>(`${this.apiUrl}/events`)
      .pipe(catchError(this.handleError));
  }

  getEvent_old(id: number): Observable<Event> {
    return this.http
      .get<Event>(`${this.apiUrl}/events/${id}`)
      .pipe(catchError(this.handleError));
  }

  addEvent(event: Event): Observable<Event> {
    return this.http
      .post<Event>(`${this.apiUrl}/events`, event, httpOptions)
      .pipe(catchError(this.handleError));
  }

  deleteEvent(id: number): Observable<Event> {
    return this.http
      .delete<Event>(`${this.apiUrl}/events/${id}`)
      .pipe(catchError(this.handleError));
  }

  updateEvent(event: Event, id: number): Observable<Event> {
    return this.http
      .put<Event>(`${this.apiUrl}/events/${id}`, event, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getEvents(num_devoluciones: number, num_pag: number, todayDate: string) {
    this.http
      .get(
        this.urlApi +
          '/api/event/next?num_devoluciones=' +
          num_devoluciones +
          '&num_pagina=' +
          num_pag +
          '&date=' +
          todayDate +
          '&company=' +
          sessionStorage.getItem('dcmpny'),
        this.getHeader()
      )
      .subscribe({
        next: (data) => {
          this.sendNextResult('result', data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, {
            method: this.getEvents,
            args: [num_devoluciones, num_pag, todayDate],
          });
          this.checkStatusError(error);
        },
      });
  }
  getEventById(id) {
    this.http.get(this.urlApi + '/event/' + id).subscribe({
      next: (data) => {
        this.sendNextResult('result', data);
      },
      error: (error) => {
        this.sendNextResult('resultError', error, {
          method: this.getEventById,
          args: [id],
        });
        this.checkStatusError(error);
      },
    });
  }
  getEventByFacility(id) {
    this.http
      .get(
        this.urlApi + '/api/event/last_for_facilities/' + id,
        this.getHeader()
      )
      .subscribe({
        next: (data) => {
          this.sendNextResult('result', data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, {
            method: this.getEventByFacility,
            args: [id],
          });
          this.checkStatusError(error);
        },
      });
  }
  getEventByDate(date) {
    this.http
      .get(this.urlApi + '/api/event/search/' + date, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult('result', data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, {
            method: this.getEventByDate,
            args: [date],
          });
          this.checkStatusError(error);
        },
      });
  }
  updateEventById(id, event) {
    this.http
      .put(this.urlApi + '/api/event/' + id, event, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult('resultUpdate', data);
        },
        error: (error) => {
          this.sendNextResult('resultUpdateError', error, {
            method: this.updateEventById,
            args: [id, event],
          });
          this.checkStatusError(error);
        },
      });
  }
  addNewEvent(event) {
    this.http
      .post(this.urlApi + '/api/event', event, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult('result', data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, {
            method: this.addNewEvent,
            args: [event],
          });
          this.checkStatusError(error);
        },
      });
  }
  getTicketsListByEventId(id) {
    this.http
      .get(this.urlApi + '/api/event/sales/' + id, this.getHeader())
      .subscribe({
        next: (data) => {
          this.customSendNextResult('ticket', data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, {
            method: this.getTicketsListByEventId,
            args: [id],
          });
          this.checkStatusError(error);
        },
      });
  }
  getEventsByWeek() {
    this.http
      .get(this.urlApi + '/api/event/week/', this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult('result', data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, {
            method: this.getEventsByWeek,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }
  getEventHistoryByCompany(companyId, year, numPag) {
    this.http
      .get(
        this.urlApi +
          '/api/event/historical/' +
          companyId +
          '/' +
          year +
          '?num_devoluciones=6&num_pagina=' +
          numPag,
        this.getHeader()
      )
      .subscribe({
        next: (data) => {
          this.sendNextResult('result', data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, {
            method: this.getEventHistoryByCompany,
            args: [companyId, year],
          });
          this.checkStatusError(error);
        },
      });
  }
  filteredEvents(url) {
    this.http.get(this.urlApi + url, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data);
      },
      error: (error) => {
        this.sendNextResult('resultError', error, {
          method: this.filteredEvents,
          args: [url],
        });
        this.checkStatusError(error);
      },
    });
  }
  getEventByCompanyGroup(devoluciones, paginas) {
    this.http
      .get(
        this.urlApi +
          '/api/group/next/' +
          sessionStorage.getItem('groupId') +
          '?num_devoluciones=' +
          devoluciones +
          '&num_pagina=' +
          paginas,
        this.getHeader()
      )
      .subscribe({
        next: (data) => {
          this.sendNextResult('result', data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, {
            method: this.getEventByCompanyGroup,
            args: [devoluciones, paginas],
          });
          this.checkStatusError(error);
        },
      });
  }
  getEventByCompany() {
    this.http
      .get(
        this.urlApi + '/event/company/' + sessionStorage.getItem('dcmpny'),
        this.getHeader()
      )
      .subscribe({
        next: (data) => {
          this.sendNextResult('result', data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, {
            method: this.getEventByCompany,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }
  getEventSelect() {
    this.http
      .get(this.urlApi + '/api/event/select', this.getHeader())
      .subscribe({
        next: (data) => {
          this.customSendNextResult('resultEvent', data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, {
            method: this.getEventByCompany,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }
  deleteEventIfNoTickets(ids) {
    let send = { events_ids: ids };
    this.http
      .delete(this.urlApi + '/api/event', this.sendBodyOptions(send))
      .subscribe({
        next: (data) => {
          this.sendNextResult('resultDelete', data);
        },
        error: (error) => {
          this.sendNextResult('resultDeleteError', error, {
            method: this.deleteEventIfNoTickets,
            args: [ids],
          });
          this.checkStatusError(error);
        },
      });
  }
  getExcel(id) {
    this.http
      .get(this.urlApi + '/api/sold/ticket/event/' + id, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult('result', data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, {
            method: this.getExcel,
            args: [id],
          });
          this.checkStatusError(error);
        },
      });
  }
  customSendNextResult(
    type: 'resultEvent' | 'ticket' | 'ticketError',
    data: any
  ) {
    let clearFuntion: Function;

    if (type === 'resultEvent') {
      this._resultEvent.next(data);
      clearFuntion = this.clearResultEvent;
    }

    if (type === 'ticket') {
      this._resultTicket.next(data);
      clearFuntion = this.clearResultTicket;
    }

    if (type === 'ticketError') {
      this._resultTicketError.next(data);
      clearFuntion = this.clearResultTicketError;
    }

    setTimeout(() => {
      this[clearFuntion.name]();
    }, 150);
  }

  _resultEvent = new BehaviorSubject<any>(null);
  getResultEvent() {
    return this._resultEvent;
  }
  clearResultEvent() {
    this._resultEvent.next(null);
  }

  _resultTicket = new BehaviorSubject<any>(null);
  getResultTicket() {
    return this._resultTicket;
  }
  clearResultTicket() {
    this._resultTicket.next(null);
  }
  _resultTicketError = new BehaviorSubject<any>(null);
  getResultTicketError() {
    return this._resultTicketError;
  }
  clearResultTicketError() {
    this._resultTicketError.next(null);
  }
}
