import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/Api/login.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'app-ManagementEvents',
  templateUrl: './ManagementEvents.component.html',
  styleUrls: ['./ManagementEvents.component.scss']
})
export class ManagementEventsComponent implements OnInit {

  constructor(private loginSE: LoginService, private notificaciones: NotificacionesService) { }

  ngOnInit() {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      this.checkPermisos();
    }
  }
  permiso: Boolean = false;
  checkPermisos() {
    if (localStorage.getItem("tipoUser") == null || localStorage.getItem("tipoUser") == undefined || localStorage.getItem("tipoUser") == '["2"]') {
      this.loginSE.logOut();
      this.notificaciones.showError("No tienes permisos para acceder")
    } else {
      if (localStorage.getItem("tipoUser") == '["0"]' || localStorage.getItem("tipoUser") == '["3"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY") && !localStorage.getItem("permisos")?.includes("CONSULT_COMPANY_GROUP")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else {
          if (localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY") && localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY_GROUP")) {
            this.permiso = true;
          }
        }
      }
      if (localStorage.getItem("tipoUser") == '["1"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else {
          if (localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY")) {
            this.permiso = true;
          }
        }
      }
    }


  }
}
