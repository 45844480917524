import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { initializeApp } from 'firebase/app';
import { getDatabase, onChildAdded, onValue, ref } from 'firebase/database';
import { Subscription, fromEvent, map, merge, of, takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { ValidationTicketPopupComponent } from 'src/app/popups/validationTicketPopup/validationTicketPopup.component';
import { EventService } from 'src/app/services/Api/event.service';
import { TicketsService } from 'src/app/services/Api/tickets.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PopupService } from 'src/app/services/utils/popup.service';
import { environment } from 'src/environments/environment';
const firebaseConfig = environment.firebaseConfig;
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
@Component({
  selector: 'app-ExternalValidation',
  templateUrl: './ExternalValidation.component.html',
  styleUrls: ['./ExternalValidation.component.scss'],
})
export class ExternalValidationComponent
  extends UnsubscribesDestroy
  implements OnInit
{
  networkStatus: boolean = false;
  networkStatus$: Subscription = Subscription.EMPTY;

  eventId: number = -1;
  dataIsLoaded: boolean = false;
  event: any = null;
  output: string = '';
  stopFlag: boolean = true;
  listOpen: boolean = false;
  activeScan: boolean = true;
  filter: string = '';
  num_dev: number = 9999999999;
  num_pag: number = 1;
  entradas: Array<any> = [];
  bufferValidados: Array<any> = [];
  bufferInvalidados: Array<any> = [];
  detallesEntradaOpen: boolean = false;
  entradaSelected: any = null;
  count: number = 0;
  entradasAux: Array<any> = [];
  @Input() ticketsYaValidados: Array<number> = [];
  constructor(
    private activeRoute: ActivatedRoute,
    private eventSE: EventService,
    private notificaciones: NotificacionesService,
    private loader: LoaderService,
    private popupSE: PopupService,
    private router: Router,
    private ticketSE: TicketsService
  ) {
    super();
  }

  ngOnInit() {
    this.eventId = Number(this.activeRoute.snapshot.params['eventId']);
    this.getEventById();
    this.getCount();
    setTimeout(() => {
      (document.querySelector('.btn-sm')! as HTMLElement).click();
    }, 500);
    this.checkNetworkStatus();
  }

  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe((status) => {
        this.networkStatus = status;
        if (status) {
          this.validarEntrada();
        } else {
          if (this.bufferValidados.length > 0) {
            this.bufferValidados = [];
          }
          this.bufferValidados = this.ticketsYaValidados;
        }
      });
  }

  getCount() {
    let starCountRef = ref(database, 'assistant/' + this.eventId + '/count');
    onValue(starCountRef, (snapshot: any) => {
      if (snapshot.exists()) {
        this.count = snapshot.val();
      }
    });
  }
  getFilter() {
    this.loader.open();
    this.entradas = this.entradasAux.filter((entrada: any) => {
      return entrada.dni?.toLowerCase().includes(this.filter.toLowerCase());
    });
    // this.getAllEntradas();
  }
  /** FUNCIONALIDADES*/

  onError(e: any): void {
    alert(e);
  }
  setOutput(event) {
    if (!this.listOpen) {
      this.output = event;
      var qrInfo = {
        soldTicketId: Number(
          event.split(',')[0].split(':')[1].replace(/\s/g, '')
        ),
        producto: event.split(',')[3].split(':')[1].replace(/\s/g, ''),
        imagen: event.split(',')[6].split(' ')[1].replace(/\s/g, ''),
        dni: event.split(',')[2].split(' ')[1].replace(/\s/g, ''),
        start_date: event.split(',')[4].split(' ')[1].replace(/\s/g, ''),
        finish_date: event.split(',')[5].split(' ')[1].replace(/\s/g, ''),
        name: event.split(',')[1].split(' ')[1].replace(/\s/g, ''),
      };

      if (this.activeScan) {
        if (this.networkStatus) {
          if (!this.ticketsYaValidados.includes(qrInfo.soldTicketId)) {
            this.stopFlag = false;
            this.activeScan = false;
            this.popupSE.openPopup(ValidationTicketPopupComponent, {
              qrInfo: qrInfo,
              eventId: this.eventId,
              redActiva: this.networkStatus,
            });

            this.popupSE
              .returnData()
              .pipe(takeUntil(this._unsubInd2))
              .subscribe((res) => {
                if (res == null) {
                  return;
                }
                this.ticketsYaValidados.push(res.id);
                this.activeScan = true;
                this._unsubInd2.next('');
              });
          } else {
            this.notificaciones.showError('Ticket ya validado');
            this.activeScan = false;
            setTimeout(() => {
              this.activeScan = true;
            }, 2000);
          }
        } else {
          if (this.bufferValidados.includes(qrInfo.soldTicketId)) {
            this.notificaciones.showError('Ticket ya validado');
            this.activeScan = false;
            setTimeout(() => {
              this.activeScan = true;
            }, 2000);
          } else {
            this.bufferValidados.push(qrInfo.soldTicketId);
            this.notificaciones.showFeedBack('Entrada validada correctamente');
            this.activeScan = true;
          }
        }
      }
    }
  }

  /** LLAMADAS A LA API */
  getEventById() {
    this.eventSE.getEventById(this.eventId);
    this.loader.open();
    this.eventSE
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.loader.closeSlow();
        this.event = value;
        this.dataIsLoaded = true;
        this._unsubInd.next('');
        this.getAllEntradas();
      });
    this.eventSE
      .getResultError()
      .pipe(takeUntil(this._unsub))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.loader.closeSlow();
        this.notificaciones.showError(value.error.message);
        this._unsub.next('');
      });
  }

  validarEntrada(entrada?) {
    if (this.networkStatus) {
      if (!entrada) {
        if (this.bufferValidados.length > 0) {
          this.ticketSE.validateTicket(
            { soldTicket: this.bufferValidados },
            this.eventId
          );
        }
        if (this.bufferInvalidados.length > 0) {
          this.ticketSE.invalidarTicket(
            { soldTicket: this.bufferInvalidados },
            this.eventId
          );
        }
      }
      if (entrada) {
        let soldTicket = entrada['soldTicketId'];
        if (!entrada.isUsed) {
          this.ticketSE.validateTicket(
            { soldTicket: [soldTicket] },
            this.eventId
          );
        } else {
          this.ticketSE.invalidarTicket(
            { soldTicket: [soldTicket] },
            this.eventId
          );
        }
      }
      this.ticketSE
        .getResultUpdate()
        .pipe(takeUntil(this._unsubInd))
        .subscribe((value) => {
          if (value == null) {
            return;
          }
          this.loader.closeSlow();
          if (
            this.bufferInvalidados.length == 0 &&
            this.bufferValidados.length == 0
          ) {
            if (entrada.isUsed) {
              this.notificaciones.showFeedBack(
                'Entrada invalidada correctamente'
              );
            } else {
              this.notificaciones.showFeedBack(
                'Entrada validada correctamente'
              );
            }
          }
          this.bufferValidados = [];
          this.bufferInvalidados = [];

          this._unsubInd.next('');
          this.getAllEntradas();
        });
      this.ticketSE
        .getResultUpdateError()
        .pipe(takeUntil(this._unsub))
        .subscribe((value) => {
          if (value == null) {
            return;
          }
          this.loader.closeSlow();
          this._unsub.next('');
          if (
            this.bufferInvalidados.length == 0 &&
            this.bufferValidados.length == 0
          ) {
            this.notificaciones.showError(value.error.message);
          }
        });
    } else {
      if (this.bufferValidados.includes(entrada.soldTicketId)) {
        this.notificaciones.showFeedBack('Entrada desvalidada correctamente');
        this.bufferValidados.splice(
          this.bufferValidados.indexOf(entrada.soldTicketId),
          1
        );
        this.bufferInvalidados.push(entrada.soldTicketId);
      } else {
        this.notificaciones.showFeedBack('Entrada validada correctamente');
        this.bufferValidados.push(entrada.soldTicketId);
        this.bufferInvalidados.splice(
          this.bufferInvalidados.indexOf(entrada.soldTicketId),
          1
        );
      }
    }
  }
  getAllEntradas() {
    this.ticketsYaValidados = [];
    this.ticketSE.getAllEntradasEvento(
      this.eventId,
      this.num_dev,
      this.num_pag,
      this.filter
    );
    this.ticketSE
      .getResult()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (res == null) {
          return;
        }
        this.entradas = [];
        this.entradas = res;
        this.entradasAux = res;

        this.entradas.forEach((element) => {
          if (element.isUsed) {
            this.ticketsYaValidados.push(element.soldTicketId);
          }
        });
        this.loader.closeSlow();
        this._unsubInd2.next('');
      });
    this.ticketSE
      .getResultError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (res == null) {
          return;
        }
        this.notificaciones.showError(res.error.message);
        this.loader.closeSlow();
        this._unsub.next('');
      });
  }

  previous() {
    if (this.num_pag > 1) {
      this.num_pag--;
      this.loader.open();
      this.getAllEntradas();
    }
  }
  next() {
    this.num_pag++;
    this.loader.open();
    this.getAllEntradas();
  }
  controlOpenList() {
    this.listOpen ? (this.listOpen = false) : (this.listOpen = true);
    // setTimeout(() => {
    //   (document.querySelector(".btn-sm")! as HTMLElement).click();

    // }, 500);
  }
}
