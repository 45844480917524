<section class="event-sales-global-weekly-chart">
  <div style="display: block">
    <canvas *ngIf="tipo=='semanal'"
      baseChart
      [data]="barChartDataSemanal"
      [options]="barChartOptions"
      [plugins]="barChartPlugins"
      [type]="barChartType"
      height="420px"
      width="866px"
    >
    </canvas>
    <canvas *ngIf="tipo=='biSemanal'"
      baseChart
      [data]="barChartDataBiSemanal"
      [options]="barChartOptions"
      [plugins]="barChartPlugins"
      [type]="barChartType"
      height="420px"
      width="866px"
    >
    </canvas>
    <canvas *ngIf="tipo=='mensual'"
    baseChart
    [data]="barChartDataMensual"
    [options]="barChartOptions"
    [plugins]="barChartPlugins"
    [type]="barChartType"
    height="420px"
    width="866px"
  >
  </canvas>
  </div>
  <div class="legend">
    <div class="legend-item">
      <span class="legend-item-icon icon-1"></span>
      <span class="legend-item-title">RRPP</span>
    </div>
    <div class="legend-item">
      <span class="legend-item-icon icon-3"></span>
      <span class="legend-item-title">Online</span>
    </div>
  </div>
</section>
