<div>
  <span *ngFor="let paso of pasos; let i = index"
    [ngClass]="{
      activo: i <= _activo,
      inactivo: !yaActivo.includes(i) && !navegacionActiva,
      activoSiguiente: (i+1) <= _activo
    }"
    (click)="pulsado(i);"
  >
    <i class="numberPasos">{{i + 1}}</i>
    <span>{{ paso }}</span>
    <i class="background_fix"></i>
    <i class="triangles">
      <b class="triangle_center"></b>
      <b class="triangle_white"></b>
      <b class="triangle_hidden"></b>
    </i>
  </span>
</div>
