import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { TransportService } from 'src/app/services/Api/transport.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PopupService } from 'src/app/services/utils/popup.service';

@Component({
  selector: 'CreateEditEvent-Step5',
  templateUrl: './CreateEditEvent-Step5.component.html',
  styleUrls: ['./CreateEditEvent-Step5.component.scss']
})
export class CreateEditEventStep5Component extends UnsubscribesDestroy implements OnInit {

  transportes: Array<any> = []

  //VARIABLES DE CONTROL
  nuevoTransporteAbierto: Boolean = false;
  verTransporteAbierto: Boolean = false;
  transporteSeleccionado: any = null;
  editar: Boolean = false;

  //VARIABLES FORMULARIO
  nombreTicketTransporte: String = ""
  nombreTicketTransporteError: Boolean = false;

  precioTransporte: Number = 0;
  precioTransporteError: Boolean = false;

  horaRecogida: String = ""
  horaRecogidaError: Boolean = false;

  horaLlegada: String = ""
  horaLlegadaError: Boolean = false;

  calleDireccionRecogida: String = ""
  calleDireccionRecogidaError: Boolean = false;

  numeroRecogida: Number | null = null
  numeroRecogidaError: Boolean = false;

  localidadRecogida: String = ""
  localidadRecogidaError: Boolean = false;

  provinciaRecogida: String = ""
  provinciaRecogidaError: Boolean = false;

  empresaContratacion: String = ""
  empresaContratacionError: Boolean = false;

  tipoVehiculo: String = ""
  tipoVehiculoError: Boolean = false;

  vehiculos: Number = 0
  vehiculosError: Boolean = false;

  plazas: Number = 0
  plazasError: Boolean = false;

  //INPUTS Y OUTPUTS
  @Input() receiveData: any = null;
  @Output() data = new EventEmitter<any>()
  @Output() validStep = new EventEmitter<Boolean>()
  @Output() dataToUpdate = new EventEmitter<any>()
  constructor(private notificaciones: NotificacionesService, private popupSE: PopupService, private loader: LoaderService, private route: ActivatedRoute, private transportSE: TransportService) {
    super();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.receiveData != null) {
      this.receiveData = changes['receiveData'].currentValue;
      this.setData();
    }
  }
  ngOnInit() {
    if (this.route.snapshot.params['id'] != null || this.route.snapshot.params['id'] != undefined) {
      this.editar = true;
    }
  }

  //GETTERS Y SETTERS
  getNombreTicketTransporte(event) {
    this.nombreTicketTransporte = event;
    this.updateTransporteSeleccionado("name", event);
  }
  getPrecioTransporte(event) {
    this.precioTransporte = event;
    this.updateTransporteSeleccionado("fee", event);
  }
  getHoraRecogida(event) {
    this.horaRecogida = event;
    this.updateTransporteSeleccionado("pickUpTime", event);
  }
  getHoraLLegada(event) {
    this.horaLlegada = event;
    this.updateTransporteSeleccionado("srrivalTime", event);
  }
  getCalleRecogida(event) {
    this.calleDireccionRecogida = event;
    this.updateTransporteSeleccionado("street", event);
  }
  getNumeroRecogida(event) {
    this.numeroRecogida = event;
    this.updateTransporteSeleccionado("number", event);
  }
  getLocalidadRecogida(event) {
    this.localidadRecogida = event;
    this.updateTransporteSeleccionado("locality", event);
  }
  getProvinciaRecogida(event) {
    this.provinciaRecogida = event;
    this.updateTransporteSeleccionado("province", event);
  }
  getEmpresaContratacion(event) {
    this.empresaContratacion = event;
    this.updateTransporteSeleccionado("company", event);
  }
  getTipoVehiculo(event) {
    this.tipoVehiculo = event;
    this.updateTransporteSeleccionado("vehicleType", event);
  }
  getVehiculos(event) {
    this.vehiculos = event;
    this.updateTransporteSeleccionado("vehiclesQuantity", event);
  }
  getPlazas(event) {
    this.plazas = event;
    this.updateTransporteSeleccionado("capacity", event);
  }
  setTransporteDataOnClick(transporte) {
    this.nombreTicketTransporte = transporte.name;
    this.precioTransporte = transporte.fee;
    this.horaRecogida = transporte.pickUpTime;
    this.horaLlegada = transporte.arrivalTime;
    this.calleDireccionRecogida = transporte.address.street;
    this.numeroRecogida = transporte.address.number;
    this.localidadRecogida = transporte.address.locality;
    this.provinciaRecogida = transporte.address.province;
    this.empresaContratacion = transporte.company;
    this.tipoVehiculo = transporte.vehicleType;
    this.vehiculos = transporte.vehiclesQuantity;
    this.plazas = transporte.capacity
  }
  updateTransporteSeleccionado(type, data) {
    switch (type) {
      case "name":
        this.transporteSeleccionado.name = data;
        break;
      case "fee":
        this.transporteSeleccionado.fee = data;
        break;
      case "pickUpTime":
        this.transporteSeleccionado.pickUpTime = data;
        break;
      case "arrivalTime":
        this.transporteSeleccionado.arrivalTime = data;
        break;
      case "street":
        this.transporteSeleccionado.address.street = data;
        break;
      case "number":
        this.transporteSeleccionado.address.number = data;
        break;
      case "locality":
        this.transporteSeleccionado.address.locality = data;
        break;
      case "province":
        this.transporteSeleccionado.address.province = data;
        break;
      case "company":
        this.transporteSeleccionado.company = data;
        break;
      case "vehicleType":
        this.transporteSeleccionado.vehicleType = data;
        break;
      case "vehiclesQuantity":
        this.transporteSeleccionado.vehiclesQuantity = data;
        break;
      case "capacity":
        this.transporteSeleccionado.capacity = data;
        break;
    }
  }
  setData(){
    this.transportes = this.receiveData
  }
  
  //FUNCIONALIDAD
  addNuevoTransporte() {
    var transporte = {
      name: "Transporte sin nombre",
      fee: 0,
      capacity: 0,
      pickUpTime: "",
      arrivalTime: "",
      pickUpDate: "",
      arrivalDate: "",
      address: {
        street: "",
        number: null,
        locality: "",
        province: ""
      },
      company: "",
      vehicleType: "",
      vehiclesQuantity: 0
    }
    this.transporteSeleccionado = transporte;
    this.setTransporteDataOnClick(transporte)
    this.transportes.push(transporte);
    this.validStep.emit(false);
  }
  
  //VALIDACIONES
  validarNombreTransporte(){
    if (this.nombreTicketTransporte == "") {
      this.nombreTicketTransporteError = true;
    }else{
      this.nombreTicketTransporteError = false;
    }
  }
  validarPrecioTransporte(){
    if (this.precioTransporte == null) {
      this.precioTransporteError = true;
    }else{
      this.precioTransporteError = false;
    }
  }
  validarCapacidad(){
    if (this.plazas == null) {
      this.plazasError = true;
    }else{
      this.plazasError = false;
    }
  }
  validarHoraRecogida(){
    if (this.horaRecogida == "") {
      this.horaRecogidaError = true;
    }else{
      this.horaRecogidaError = false;
    }
  }
  validarHoraLlegada(){
    if (this.horaLlegada == "") {
      this.horaLlegadaError = true;
    }else{
      this.horaLlegadaError = false;
    }
  }
  validarDireccion(){
    if (this.calleDireccionRecogida == "") {
      this.calleDireccionRecogidaError = true;
    }else{
      this.calleDireccionRecogidaError = false;
    }
  }
  validarNumero(){
    if (this.numeroRecogida == null) {
      this.numeroRecogidaError = true;
    }else{
      this.numeroRecogidaError = false;
    }
  }
  validarLocalidad(){
    if (this.localidadRecogida == "") {
      this.localidadRecogidaError = true;
    }else{
      this.localidadRecogidaError = false;
    }
  }
  validarProvincia(){
    if (this.provinciaRecogida == "") {
      this.provinciaRecogidaError = true;
    }else{
      this.provinciaRecogidaError = false;
    }
  }
  validarCompañia(){
    if (this.empresaContratacion == "") {
      this.empresaContratacionError = true;
    }else{
      this.empresaContratacionError = false;
    }
  }
  validarTipoVehiculo(){
    if (this.tipoVehiculo == "") {
      this.tipoVehiculoError = true;
    }else{
      this.tipoVehiculoError = false;
    }
  }
  validarCantidadVehiculos(){
    if (this.vehiculos == null) {
      this.vehiculosError = true;
    }else{
      this.vehiculosError = false;
    }
  }
  validarFormularioTransporte(){
    this.validarNombreTransporte();
    this.validarPrecioTransporte();
    this.validarCapacidad();
    this.validarHoraRecogida();
    this.validarHoraLlegada();
    this.validarDireccion();
    this.validarNumero();
    this.validarLocalidad();
    this.validarProvincia();
    this.validarCompañia()
    this.validarTipoVehiculo();
    this.validarCantidadVehiculos();
    if(!this.nombreTicketTransporteError && !this.precioTransporteError && !this.horaRecogidaError && !this.horaLlegadaError && !this.calleDireccionRecogidaError && 
      !this.numeroRecogidaError && !this.localidadRecogidaError && !this.provinciaRecogidaError && !this.empresaContratacionError && !this.tipoVehiculoError && !this.vehiculosError && !this.plazasError){
        this.updateTransporteEvento();
        this.transporteSeleccionado = null;
        this.verTransporteAbierto = false;
        this.nuevoTransporteAbierto  = false;
        this.data.emit(this.transportes)
        this.validStep.emit(true)
    }else{
      this.notificaciones.showError("Algunos campos contienen errores. Porfavor revísalos");
    }
  }

  //LLAMADAS A LA API
  updateTransport(event){
    var transporte = {}
    if (event.element == "street") {
      transporte["address"] = {street: event.value};
    }else if(event.element == "number"){
      transporte["address"] = {number: event.value};
    }else if(event.element == "locality"){
      transporte["address"] = {locality: event.value};
    }else if(event.element == "province"){
      transporte["address"] = {province: event.value};
    }else if(event.element == "pickUpTime"){
      transporte["pickUpTime"] = event.value;
      transporte["pickUpDate"] = this.transporteSeleccionado.pickUpDate.split("T")[0]
    }else if(event.element == "arrivalTime"){
      transporte["arrivalTime"] = event.value;
      transporte["arrivalDate"] = this.transporteSeleccionado.arrivalDate.split("T")[0]
    }else{
      transporte[event.element] = event.value;
    }
    this.transportSE.updateTransportById(this.transporteSeleccionado.id, transporte);
    this.transportSE.getResultUpdate().pipe(takeUntil(this._unsubInd2)).subscribe(value=>{
      if(value==null){return}
      this.notificaciones.showFeedBack(value.message);
      this._unsubInd2.next("");
    })
    this.transportSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(value=>{
      if(value==null){return}
      this.notificaciones.showError(value.error.message);
      this._unsub.next("");
    })
  }
  updateTransporteEvento(){
    if (this.editar && this.nuevoTransporteAbierto) {this.dataToUpdate.emit({ element: "transport", value:[this.transporteSeleccionado]})}
  }
  updateNombreTransporte(event){
    if (this.editar && this.verTransporteAbierto) {this.updateTransport({element:"name", value: event})}
  }
  updatePrecioTransporte(event){
    if (this.editar && this.verTransporteAbierto) {this.updateTransport({element:"fee", value: event})}
  }
  updateHoraRecogidaTransporte(event){
    if (this.editar && this.verTransporteAbierto) {this.updateTransport({element:"pickUpTime", value: event})}
  }
  updateHoraLlegadaTransporte(event){
    if (this.editar && this.verTransporteAbierto) {this.updateTransport({element:"arrivalTime", value: event})}
  }
  updateDireccionTransporte(event){
    if (this.editar && this.verTransporteAbierto) {this.updateTransport({element:"street", value: event})}
  }
  updateNumeroTransporte(event){
    if (this.editar && this.verTransporteAbierto) {this.updateTransport({element:"number", value: event})}
  }
  updateLocalidadTransporte(event){
    if (this.editar && this.verTransporteAbierto) {this.updateTransport({element:"locality", value: event})}
  }
  updateProvinciaTransporte(event){
    if (this.editar && this.verTransporteAbierto) {this.updateTransport({element:"province", value: event})}
  }
  updateEmpresaTransporte(event){
    if (this.editar && this.verTransporteAbierto) {this.updateTransport({element:"company", value: event})}
  }
  updateVehiculoTransporte(event){
    if (this.editar && this.verTransporteAbierto) {this.updateTransport({element:"vehicleType", value: event})}
  }
  updateCantidadVehiculosTransporte(event){
    if (this.editar && this.verTransporteAbierto) {this.updateTransport({element:"vehiclesQuantity", value: event})}
  }
  updatePlazasTransporte(event){
    if (this.editar && this.verTransporteAbierto) {this.updateTransport({element:"capacity", value: event})}
  }
}
