import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { EventService } from 'src/app/services/Api/event.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'app-ValidateBuyTickets',
  templateUrl: './ValidateBuyTickets.component.html',
  styleUrls: ['./ValidateBuyTickets.component.scss']
})
export class ValidateBuyTicketsComponent extends UnsubscribesDestroy implements OnInit {

  id!: number;
  event: any = null
  dataIsLoaded:Boolean = false;
  optionSelected:Number = 0
  
  constructor(private loginSE: LoginService, private route: ActivatedRoute, private eventsSE: EventService, private loaderSE: LoaderService, private notificaciones: NotificacionesService, private router: Router) {
    super();
  }

  ngOnInit() {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      this.id = this.route.snapshot.params['id'];
      this.getEvent()
    }
  }
  getEvent() {
    this.eventsSE.getEventById(this.id);
 
    this.eventsSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return }
      this.event = value;
      this.dataIsLoaded = true;

      this._unsubInd.next("");
    })
    this.eventsSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.loaderSE.closeSlow();
      this.notificaciones.showError(value.error.message);
      this._unsub.next("");
    })
  }
  compartirEscaner(){
    if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard api method'
      this.notificaciones.showFeedBack("Se ha copiado el enlace de validación")
      return navigator.clipboard.writeText("https://"+location.hostname +"/validar-entrada/"+this.route.snapshot.params['id']);
    } else {
      // text area method
      let textArea = document.createElement("textarea");
      textArea.value = location.href +"/validar-entrada/"+this.route.snapshot.params['id']
      // make the textarea out of viewport
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise<void>((res, rej) => {
        // here the magic happens
        document.execCommand('copy') ? res() : rej();
        textArea.remove();
        this.notificaciones.showFeedBack("Se ha copiado el enlace de validación")
      });
    }
  }
}
