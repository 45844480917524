import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'CreateEditEvent-Step6',
  templateUrl: './CreateEditEvent-Step6.component.html',
  styleUrls: ['./CreateEditEvent-Step6.component.scss']
})
export class CreateEditEventStep6Component implements OnInit {

  //VARIABLES DE CONTROL
  masInfo: boolean = false;
  urlImages = environment.urlImages;
  imagenFlyer:String = ""
  imagenFyerError: Boolean = false;
  editar: Boolean = false;
  url: string = "";
  errorImage: boolean = false;
  //INPUTS Y OUTPUTS
  @Input() receiveData: any = null;
  @Output() data = new EventEmitter<any>()
  @Output() validStep = new EventEmitter<Boolean>()
  @Output() dataToUpdate = new EventEmitter<any>()

  constructor(private notificaciones: NotificacionesService, private route: ActivatedRoute) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.receiveData != null) {
      this.receiveData = changes['receiveData'].currentValue;
      this.setData();
    }
  }
  ngOnInit() {
    if (this.route.snapshot.params['id'] != null || this.route.snapshot.params['id'] != undefined) {
      this.editar = true;
    }
  }

  checkImage(url) {
    const img = new Image();
    img.src = url;

    if (img.complete) {
      this.imagenFlyer = url
    } else {
      img.onload = () => {
        this.imagenFlyer = url
      };

      img.onerror = () => {
        this.errorImage = true;
        this.imagenFlyer = "/assets/images/placeholder.jpg"
      };
    }
  }

  //GETTERS Y SETTERS
  getImagenFlyer(imagenFlyer){
    this.imagenFlyer = imagenFlyer;
    this.validarPaso6();
  }

  validarPaso6(){
    if(this.imagenFlyer == ""){
      this.imagenFyerError = true;
    }else{
      this.imagenFyerError = false;
    }
    if (!this.imagenFyerError) {
      this.updateImagenFlyer();
      this.validStep.emit(true);
      this.data.emit(this.imagenFlyer);
    }else{
      this.validStep.emit(false);
      this.notificaciones.showError("Debes subir una imagen de flyer")
    }
  }
  setData(){
    if (this.route.snapshot.params['id'] != null || this.route.snapshot.params['id'] != undefined) {
      this.checkImage(this.receiveData)
    }else{
      this.imagenFlyer = this.receiveData;
    }

  }
  //LLAMADAS A LA API
  updateImagenFlyer(){
    if (this.editar) {this.dataToUpdate.emit({ element: "images", value:[{image: this.imagenFlyer[0], isPrincipal: true}]})}
  }
}
