import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'Info',
  templateUrl: './Info.component.html',
  styleUrls: ['./Info.component.scss']
})
export class InfoComponent implements OnInit {
  @Input() style:String = "simple";
  @Input() currency:boolean = false;
  @Input() value:any = null;
  @Input() type:any = "";
  @Input() icon:string = "";
  @Input() typeCard: String = "normal";
  @Input() loaded: Boolean = true;
  constructor() { }

  ngOnInit() {
  }

}
