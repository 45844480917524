<div class="newTicketGeneralContainer">
  <div class="headerContainer">
    <p *ngIf="modalData.accion=='Nueva entrada'">Crear nueva entrada anticipada</p>
    <p *ngIf="modalData.accion == 'Editar entrada'">Editar entrada anticipada</p>
    <svg (click)="forceClosePopup()" xmlns="http://www.w3.org/2000/svg" width="18.243" height="18.243"
      *ngIf="(this.modalData.accion == 'Editar entrada' && this.modalData.entrada.idProducto == null) || modalData.accion == 'Nueva entrada'"
      viewBox="0 0 18.243 18.243">
      <g id="Grupo_11743" data-name="Grupo 11743" transform="translate(1.734 2.121)">
        <line id="Línea_323" data-name="Línea 323" x2="14" y2="14" transform="translate(0.387)" fill="none"
          stroke="#070c5b" stroke-linecap="round" stroke-width="3" />
        <line id="Línea_324" data-name="Línea 324" x1="14" y2="14" transform="translate(0.387)" fill="none"
          stroke="#070c5b" stroke-linecap="round" stroke-width="3" />
      </g>
    </svg>
    <svg (click)="validarEntrada()" xmlns="http://www.w3.org/2000/svg" width="18.243" height="18.243"
      *ngIf="(this.modalData.accion == 'Editar entrada' && this.modalData.entrada.idProducto != null)"
      viewBox="0 0 18.243 18.243">
      <g id="Grupo_11743" data-name="Grupo 11743" transform="translate(1.734 2.121)">
        <line id="Línea_323" data-name="Línea 323" x2="14" y2="14" transform="translate(0.387)" fill="none"
          stroke="#070c5b" stroke-linecap="round" stroke-width="3" />
        <line id="Línea_324" data-name="Línea 324" x1="14" y2="14" transform="translate(0.387)" fill="none"
          stroke="#070c5b" stroke-linecap="round" stroke-width="3" />
      </g>
    </svg>
  </div>
  <div class="bodyContainer">
    <form action="">
      <FormElement [receiveData]="nombreTicket" [error]="nombreTicketError" [type]="'input'"
        [placeholder]="'Nombre de la entrada'" (updateData)="updateNombreEntrada($event)"
        [label]="'Nombre de la entrada'" (sendData)="getNombreEntrada($event)">
      </FormElement>
      <div *ngIf="false">
        <FormElement [error]="fechasError" [label]="'Limitación en horarios y fechas'"></FormElement>
        <div class="fechasContainer">
          <div class="rango" [class.disabled]="editar">
            <mat-date-range-input [rangePicker]="picker" [min]="modalData.startDate" [max]="modalData.endDate"
              class="datePicker">
              <input *ngIf="modalData.accion!='Editar entrada'" (dateInput)="getFechaInicio($event.value)" matStartDate
                placeholder="Inicio">
              <input *ngIf="modalData.accion!='Editar entrada'" (dateInput)="getFechaFin($event.value)" matEndDate
                placeholder="Fin">
              <input *ngIf="editar" (dateInput)="getFechaInicio($event.value)" [value]="fechaInicio" matStartDate
                placeholder="Inicio">
              <input *ngIf="editar" (dateInput)="getFechaFin($event.value)" [value]="fechaFin" matEndDate
                placeholder="Fin">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </div>
          <FormElement [type]="'time'" [class.disabled]="editar" [receiveData]="horaInicio" [label]="''"
            [placeholder]="'Hora inicio'" (sendData)="getHoraInicio($event)"></FormElement>
          <FormElement [type]="'time'" [class.disabled]="editar" [receiveData]="horaFin" [label]="''"
            [placeholder]="'Hora fin'" (sendData)="getHoraFin($event)"></FormElement>
        </div>
      </div>
      <FormElement [receiveData]="incluido" [class.disabled]="editar" [error]="incluidoError" [type]="'textArea'"
        [textAreaRows]="'5'" [placeholder]="'Incluye'" [label]="'Incluye'" (sendData)="getIncluido($event)"
        (updateData)="updateDescripcion($event)"></FormElement>
      <div class="tripleColumn">
        <FormElement [receiveData]="ticketConIva" [class.disabled]="editar" [error]="ticketConIvaError"
          [type]="'number'" [placeholder]="'P.V.P'" [label]="'P.V.P'" (sendData)="getPrecioEntradaIva($event)"
          (updateData)="updatePVP($event)"></FormElement>
        <FormElement [receiveData]="ticketSinIva" [class.disabled]="editar" [error]="ticketSinIvaError"
          [type]="'number'" [placeholder]="'Base imponible'" [label]="'Base imponible'"
          (sendData)="getPrecioEntradaSinIva($event)" [soloLectura]="true"></FormElement>
        <FormElement [receiveData]="iva" class="disabled" [error]="ivaError" [type]="'number'" [placeholder]="'IVA'"
          [label]="'IVA'" (sendData)="getIva($event)"></FormElement>
      </div>
      <div class="tripleColumn">
        <FormElement [receiveData]="gastosGestion" [class.disabled]="editar" [error]="gastosGestionError"
          [type]="'number'" [placeholder]="'Gastos de gestión'" [label]="'Gastos de gestión'"
          (sendData)="getGastosGestion($event)" (updateData)="updateGastosGestion($event)">
        </FormElement>
        <FormElement [receiveData]="stockTicket" [error]="stockTicketError" [type]="'number'"
          (updateData)="updateStockEntrada($event)" [placeholder]="'Entradas restantes'" [label]="'Entradas restantes'"
          (sendData)="getStockEntradas($event)"></FormElement>
          <FormElement [receiveData]="stockTicket! + sales!" [type]="'number'" [class.disabled]="true"
          [placeholder]="'Stock total'" [label]="'Stock total'"></FormElement>
      </div>
      <div class="doubleColumn">
        <FormElement [receiveData]="comisionRRPP" [class.disabled]="editar" [error]="comisionRRPPError"
          [type]="'number'" [placeholder]="'Comisión RRPP'" [label]="'Comisión RRPP'"
          (sendData)="getComisionRRPP($event)" (updateData)="updateComision($event)"></FormElement>
        <FormElement [receiveData]="tipoComision" [class.disabled]="editar" [type]="'selector'"
          [label]="'Tipo comisión'" [options]="tiposGestion" [placeholder]="'Tipo de comision'"
          [selectorType]="'edadesMinimas'" (sendData)="getTipoGestion($event)"
          (updateData)="updateTipoComision($event)">
        </FormElement>
      </div>
      <div class="preventa" [class.disabled]="editar">
        <p>
          <img src="/assets/icons/arrowGrey.svg" alt="">
          ¿Esta entrada es una PRERRESERVA?
        </p>
        <div class="options">
          <span>No</span>
          <mat-slide-toggle (change)="changeValue()" [checked]="preorder"></mat-slide-toggle>
          <span>Sí</span>
        </div>
      </div>
      <div class="preventa" [class.disabled]="editar">
        <p>
          <img src="/assets/icons/arrowGrey.svg" alt="">
          ¿Mostrar el stock en la APP?
        </p>
        <div class="options">
          <span>No</span>
          <mat-slide-toggle (change)="getMostrarStock()" [checked]="mostrarStock"></mat-slide-toggle>
          <span>Sí</span>
        </div>
      </div>
      <div class="tablaGastos">
        <div>
          <p>Cliente paga</p>
          <p>{{(ticketConIva! + gastosGestion!) | currency:'EUR'}}</p>
        </div>
        <div>
          <p>Gastos de gestión</p>
          <p>{{gastosGestion! | currency:'EUR'}}</p>
        </div>
        <div>
          <p>Recibes</p>
          <p>{{(ticketConIva! + gastosGestion!) | currency:'EUR'}}</p>
        </div>
      </div>
    </form>


  </div>
  <div class="footerContainer">
    <GeneralButton (click)="forceClosePopup()" [buttonText]="'Cancelar'" [type]="'secondary'"
      *ngIf="(this.modalData.accion == 'Editar entrada' && this.modalData.entrada.idProducto == null) || modalData.accion == 'Nueva entrada'">
    </GeneralButton>
    <GeneralButton (click)="validarEntrada()" [buttonText]="'Cancelar'" [type]="'secondary'"
      *ngIf="(this.modalData.accion == 'Editar entrada' && this.modalData.entrada.idProducto != null)"></GeneralButton>
    <GeneralButton *ngIf="modalData.accion!='Editar entrada'" (click)="validarEntrada()"
      [buttonText]="'Agregar entrada'"></GeneralButton>
    <GeneralButton *ngIf="modalData.accion == 'Editar entrada'" (click)="validarEntrada()"
      [buttonText]="'Finalizar edicion'"></GeneralButton>
  </div>
</div>