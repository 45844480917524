<div class="stepGeneralContainer">
  <div class="stepLeft">
    <div class="precioCambioNombreContainer">
      <div class="switchContainer">
        <p [class.vacio]="precioError">¿El cliente podrá modificar la titularidad de las entradas adquiridas?</p>
        <div class="options">
          <span>Sí</span>
          <mat-slide-toggle (change)="changeValue()" [checked]="active"></mat-slide-toggle>
          <span>No</span>
        </div>
      </div>
      <div class="elementContainer" *ngIf="active">
        <span>
          <img src="/assets/icons/arrowGrey.svg" alt="">
          ¿Cuál es el precio que vas a fijar tu cliente para la gestión de cambio de titularidad?
        </span>
        <span class="symbol">€</span>
        <FormElement [labelActive]="false" [receiveData]="precio" [error]="precioError" [type]="'number'"
          [placeholder]="'10'" (sendData)="getPrecio($event)" (updateData)="updatePrecio($event)"></FormElement>
      </div>

    </div>
    <div class="precioCambioNombreContainer">
      <div class="switchContainer">
        <p [class.vacio]="precioError">¿Hay código de descuento de RRPP?</p>
        <div class="options">
          <span>Sí</span>
          <mat-slide-toggle (change)="changeValueDiscountRRPP()" [checked]="discount_rrppActive"></mat-slide-toggle>
          <span>No</span>
        </div>
      </div>
      <div class="elementContainer" *ngIf="discount_rrppActive">
        <span>
          <img src="/assets/icons/arrowGrey.svg" alt="">
          ¿Cuál es el descuento por código RRPP?
        </span>
        <span class="symbol">€</span>
        <FormElement [labelActive]="false" [receiveData]="discount_rrpp" [error]="discount_rrppError" [type]="'number'"
          [placeholder]="'10'" (sendData)="getDiscountRRPP($event)" (updateData)="updateDiscountRRPP($event)">
        </FormElement>
      </div>

    </div>
    <div class="tipoIvaContainer">
      <p>¿Qué tipo de I.V.A. vas a aplicar al evento?</p>
      <FormElement [labelActive]="false" [receiveData]="iva" [error]="ivaError" [type]="'number'" [placeholder]="'Iva'"
        [iconActive]="true" (sendData)="getIva($event)" (updateData)="updateIva($event)"></FormElement>
    </div>
    <div class="entradasContainer">
      <div class="entradasDesplegable" (click)="desplegarEntradas()" [class.opened]="entradasDesplegadas">
        <span [class.vacio]="entradasError">ENTRADAS ANTICIPADAS</span>
        <img src="/assets/icons/dropDownArrow.svg" alt="">
      </div>
      <div class="entradasLista" *ngIf="entradasDesplegadas">
        <div class="entradaCard" *ngFor="let entrada of entradas" (click)="editTicket(entrada)"
          [class.preorder]="entrada.isPreorder">
          <div class="titulos">
            <span class="titulo" *ngIf="entrada.isPreorder" [title]="entrada.name">PRERRESERVA {{entrada.name}}</span>
            <span class="titulo" *ngIf="!entrada.isPreorder" [title]="entrada.name"> {{entrada.name}}</span>
            <span class="descripcion" *ngIf="!entrada.isPreorder">{{entrada.description}}</span>
          </div>
          <div class="liberarEntrada" *ngIf="entrada.isPreorder" [class.disabled]="!editar">
            <button class="liberar" (click)="liberarEntrada(entrada);$event.stopPropagation()">Liberar entrada</button>
          </div>
          <div class="footer">
            <span>{{(entrada.fee+entrada.administrativeExpenses) | currency:'EUR'}}</span>
            <div>
              <mat-slide-toggle [checked]="entrada.isActive" (toggleChange)="changeActiveTicket(entrada)"
                (click)="$event.stopPropagation()"></mat-slide-toggle>
              <span *ngIf="!entrada.isActive">Inactiva</span>
              <span *ngIf="entrada.isActive">Activa</span>
            </div>
          </div>
        </div>

        <div class="addNuevaEntrada" (click)="addNewTicket()">
          <svg xmlns="http://www.w3.org/2000/svg" width="24.922" height="24.815" viewBox="0 0 24.922 24.815">
            <g id="Grupo_503" data-name="Grupo 503" transform="translate(2 2)">
              <line id="Línea_195" data-name="Línea 195" y2="20.815" transform="translate(10.617 0)" fill="none"
                stroke="#fff" stroke-linecap="round" stroke-width="4" />
              <line id="Línea_196" data-name="Línea 196" y2="20.922" transform="translate(20.922 10.627) rotate(90)"
                fill="none" stroke="#fff" stroke-linecap="round" stroke-width="4" />
            </g>
          </svg>
        </div>
      </div>
    </div>
    <div class="listasContainer">
      <div class="listasDesplegable" (click)="desplegarListas()" [class.opened]="listasDesplegadas">
        <span>LISTAS</span>
        <img src="/assets/icons/dropDownArrow.svg" alt="">
      </div>
    </div>
    <GeneralButton [buttonText]="'Añadir zona de reserva'"></GeneralButton>
  </div>
</div>
