<div class="limitWidth">
  <div class="dataContainer lineaMode">
    <label>¿Deseas crear tu comunidad?</label>
    <mat-radio-group aria-label="Selecciona una opción" [(ngModel)]="communityForm.create">
      <mat-radio-button [value]="true">Sí</mat-radio-button>
      <mat-radio-button [value]="false">No</mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="dataContainer" [class.disabled]="!communityForm.create">
    <label [class.vacio]="nombreComunidadError">¿Cómo se llama?</label>
    <input class="inputData" placeholder="Comunidad de lo que sea" [(ngModel)]="nombreComunidad">
  </div>
  <div class="dataContainer lineaMode" [class.disabled]="!communityForm.create">
    <label>¿Deseas que sea privada o pública?</label>
    <mat-radio-group aria-label="Selecciona una opción" [(ngModel)]="publica">
      <mat-radio-button [value]="true">Privada</mat-radio-button>
      <mat-radio-button [value]="false">Pública</mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="dataContainer" [class.disabled]="true">
    <label  [class.vacio]="edadMinimaError">¿Deseas restringir el acceso a la comunidad a un rango de edad?</label>
    <mat-radio-group aria-label="Selecciona una opción" [(ngModel)]="restriccionEdad" class="radioFlex">
      <mat-radio-button [value]="true">No</mat-radio-button>
      <mat-radio-button [value]="false" class="radioFullWidth">
        <div class="edadSelect">
          <span>Sí, ¿cual?</span>
          <ng-select [items]="edadesMinima" [(ngModel)]="edadMinima" [searchable]="false" bindLabel="name"
          bindValue="value" [placeholder]="'- A rellenar -'" [clearable]="true"></ng-select>
        </div>
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="dataContainer" [class.disabled]="!communityForm.create">
    <label [class.vacio]="selectedInteresesError">¿Cuáles son los intereses de la comunidad?</label>
    <mat-spinner *ngIf="!dataIsLoaded" class="medium center"></mat-spinner>
    <div class="flexContainer" *ngIf="dataIsLoaded">
      <button class="pill" *ngFor="let item of listIntereses" (click)="toggleSelectedInteres(item)" [class.active]="checkSelectedInteres(item)">{{item}}</button>
    </div>
  </div>
</div>
