<PageTemplate>
  <ng-container bodyContent>
    <header [searcherActive]="false"></header>
    <div class="master-container">
      <div class="business-page">
        <section class="options">
          <div class="float-logo">
            <mat-spinner class="medium" *ngIf="!dataLoaded"></mat-spinner>

            <ng-container *ngIf="dataLoaded">
              <button fileInput [accept]="['image/jpeg']" [(fichero)]="companyProfileImage"
                (ficheroChange)="setCompanyImageProfile(companyProfileImage)"></button>
              <div class="editImageOverlay" (click)="setClick('.float-logo button .fileInput')">
                <mat-icon>edit</mat-icon>
              </div>
              <img *ngIf="companyProfileImage==''" src="/assets/images/imagenDefaultNegocio.svg" alt="" />
              <img *ngIf="companyProfileImage!=''" [src]="companyProfileImage" alt="" />
            </ng-container>

          </div>
          <div class="top-group">
            <mat-spinner class="medium" *ngIf="!dataLoaded"></mat-spinner>

            <ng-container *ngIf="dataLoaded">
              <button fileInput class="fichero" [accept]="['image/jpeg']" [(fichero)]="bannerImage"
                (ficheroChange)="setCompanyBannerProfile(bannerImage)"></button>
              <div class="editImageOverlay" (click)="setClick('.top-group button .fileInput')">
                <mat-icon>edit</mat-icon>
              </div>
              <img *ngIf="bannerImage != ''" [src]="bannerImage" alt="" />
              <img *ngIf="bannerImage == ''" src="/assets/pedro-lastra-Nyvq2juw4_o-unsplash.jpg" alt="" />
            </ng-container>
          </div>
          <div class="bottom-group">
            <div class="title">
              <mat-spinner class="medium" *ngIf="!dataLoaded"></mat-spinner>
              <ng-container *ngIf="dataLoaded">
                <h2>{{ company.name }}</h2>
              </ng-container>
            </div>
            <button type="button" [routerLink]="['/admin/social/promotional-page/']">Finalizar edicion</button>
          </div>
        </section>

        <main>
          <div class="info">
            <div class="left-group">
              <section>
                <form class="form-control">
                  <div class="form-group-md">
                    <label for="description" [class.vacio]="descriptionError">Descripción</label>
                    <mat-spinner class="big" *ngIf="!dataLoaded"></mat-spinner>
                    <ng-container *ngIf="dataLoaded">
                      <textarea id="description" type="textarea"
                        (change)="updateCompany('description', company.description)" name="descripcion"
                        [(ngModel)]="company.description" required
                        placeholder="Introduzca una descripción..."></textarea>
                    </ng-container>

                  </div>

                  <div class="form-group-md" *ngFor="let socialMedia of company.social_medias; let i = index">
                    <div class="content">
                      <label for="socialLink1">URL Social Media {{i+1}}</label>
                      <mat-spinner class="big" *ngIf="!dataLoaded"></mat-spinner>
                      <ng-container *ngIf="dataLoaded">
                         <input id="socialLink1" name="redes"
                        (change)="updateSocialMedia(socialMedia.id, 'url', socialMedia.url)"
                        [(ngModel)]="socialMedia.url" type="url" placeholder="Introduzca una red social..." />
                      </ng-container>
                     
                    </div>
                  </div>

                  <div class="form-group-md">
                    <div class="content">
                      <label for="website" [class.vacio]="webPageError">URL Website</label>
                      <mat-spinner class="big" *ngIf="!dataLoaded"></mat-spinner>
                      <ng-container *ngIf="dataLoaded">
                        <input id="website" type="url" name="web" (change)="updateCompany('webpage', company.webpage)"
                        [(ngModel)]="company.webpage" placeholder="Introduzca una página web..." />
                      </ng-container>
                      
                    </div>
                  </div>

                  <button type="submit" class="btn-lg-alt">Finalizar edición</button>
                </form>
              </section>
            </div>

            <section class="right-group">
              <h3>Galería</h3>
              <div class="photo one" (click)="setClick('.photo.one button .fileInput')">
                <button fileInput [accept]="['image/jpeg']" [(fichero)]="companyImage1"
                  (ficheroChange)="setCompanyImage(companyImage1)"></button>
                <div class="editImageOverlay">
                  <mat-icon>edit</mat-icon>
                </div>
              </div>
              <div class="photos">
                <ul>
                  <li>
                    <div class="photo-detail two" (click)="setClick('.photo-detail.two button .fileInput')">
                      <button fileInput [accept]="['image/jpeg']" [(fichero)]="companyImage2"
                        (ficheroChange)="setCompanyImage(companyImage2)"></button>
                      <div class="editImageOverlay">
                        <mat-icon>edit</mat-icon>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="photo-detail three" (click)="setClick('.photo-detail.three button .fileInput')">
                      <button fileInput [accept]="['image/jpeg']" [(fichero)]="companyImage3"
                        (ficheroChange)="setCompanyImage(companyImage3)"></button>
                      <div class="editImageOverlay">
                        <mat-icon>edit</mat-icon>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="photo-detail four" (click)="setClick('.photo-detail.four button .fileInput')">
                      <button fileInput [accept]="['image/jpeg']" [(fichero)]="companyImage4"
                        (ficheroChange)="setCompanyImage(companyImage4)"></button>
                      <div class="editImageOverlay">
                        <mat-icon>edit</mat-icon>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="photo-detail five" (click)="setClick('.photo-detail.five button .fileInput')">
                      <button fileInput [accept]="['image/jpeg']" [(fichero)]="companyImage5"
                        (ficheroChange)="setCompanyImage(companyImage5)"></button>
                      <div class="editImageOverlay">
                        <mat-icon>edit</mat-icon>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="photo-detail six" (click)="setClick('.photo-detail.six button .fileInput')">
                      <button fileInput [accept]="['image/jpeg']" [(fichero)]="companyImage6"
                        (ficheroChange)="setCompanyImage(companyImage6)"></button>
                      <div class="editImageOverlay">
                        <mat-icon>edit</mat-icon>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="photo-detail seven" (click)="setClick('.photo-detail.seven button .fileInput')">
                      <button fileInput [accept]="['image/jpeg']" [(fichero)]="companyImage7"
                        (ficheroChange)="setCompanyImage(companyImage7)"></button>
                      <div class="editImageOverlay">
                        <mat-icon>edit</mat-icon>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="photo-detail eight" (click)="setClick('.photo-detail.eight button .fileInput')">
                      <button fileInput [accept]="['image/jpeg']" [(fichero)]="companyImage8"
                        (ficheroChange)="setCompanyImage(companyImage8)"></button>
                      <div class="editImageOverlay">
                        <mat-icon>edit</mat-icon>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="photo-detail nine" (click)="setClick('.photo-detail.nine button .fileInput')">
                      <button fileInput [accept]="['image/jpeg']" [(fichero)]="companyImage9"
                        (ficheroChange)="setCompanyImage(companyImage9)"></button>
                      <div class="editImageOverlay">
                        <mat-icon>edit</mat-icon>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </section>
          </div>
        </main>
      </div>
    </div>
  </ng-container>
</PageTemplate>