import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { CommunitiesService } from 'src/app/services/Api/communities.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'CommunityInformation',
  templateUrl: './CommunityDetails-CommunityInformation.component.html',
  styleUrls: ['./CommunityDetails-CommunityInformation.component.scss']
})
export class CommunityDetailsCommunityInformationComponent extends UnsubscribesDestroy implements OnInit {

  //VARIABLES DE CONTROL
  estadisticas: any = {num_members: 0,num_admins: 0,num_files: 0,num_messages: 0}
  isDataLoaded = false;
  communityProfileImage = ""
  urlImages = environment.urlImages
  viewMore = false;


  @Input() community: any = null

  constructor(private communititesSE: CommunitiesService, private notificaciones: NotificacionesService, private activatedRoute: ActivatedRoute, private loader: LoaderService) {
    super();
  }

  ngOnInit() {
    setTimeout(() => {
      this.getStadistics();
    }, 500);
  }

  //FUNCIONALIDAD
  setClick(element) {
    (document.querySelector(element) as HTMLElement).click();
  }
  setCommunityImageProfile(communityProfileImage) {
    this.editCommunity("images", [{ image: communityProfileImage[0], isPrincipal: true }])
  }

  //LLAMADAS A LA API
  getStadistics() {
    this.communititesSE.getStadistics(this.activatedRoute.snapshot.params["id"]);
    this.communititesSE.getResultIndividual().pipe(takeUntil(this._unsubInd2)).subscribe(value => {
      if (value == null) { return };
      this.estadisticas = value;
      this.isDataLoaded = true;
      this._unsubInd2.next("");
    })
    this.communititesSE.getResultIndividualError().pipe(takeUntil(this._unsubInd2)).subscribe(value => {
      if (value == null) { return };
      this.notificaciones.showError(value.error.message)
      this._unsubInd2.next("");
    })
  }

  getCommunityById() {
    this.communititesSE.getCommunityById(this.activatedRoute.snapshot.params["id"]);
    this.communititesSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return };
      this.community = value;
      // this.getValuesOnEdit(value)
      this._unsubInd.next("");
    })
    this.communititesSE.getResultError().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return };
      this.notificaciones.showError(value.error.message)
      this._unsubInd.next("");
    })
  }
  editCommunity(element: any, data: any) {
    var comunidad = {};
    comunidad[element] = data;
    this.communititesSE.updateCommunity(this.activatedRoute.snapshot.params["id"], comunidad);
    this.communititesSE.getResultUpdate().pipe(takeUntil(this._unsubInd3)).subscribe(value => {
      if (value == null) { return }
      this.notificaciones.showFeedBack(value.message);
      this.getCommunityById();
      this._unsubInd3.next("");
    })
    this.communititesSE.getResultUpdateError().pipe(takeUntil(this._unsubInd3)).subscribe(value => {
      if (value == null) { return }
      this.notificaciones.showFeedBack(value.error.message);
      this._unsubInd3.next("");
    })
  }
}
