<div class="audienciaGeneralContainer">
  <div class="header">
    <span class="informacion">
      Previo a editar tu correo tipo nos gustaría saber <b> quién es el objetivo al que vas a enviar esta campaña:</b>
    </span>
    <ng-select [items]="audiencias" [(ngModel)]="audienciaSeleccionada" bindLabel="name"
      placeholder="-- Seleccionada una opción --" (change)="checkDeleteSelector($event)" ></ng-select>
  </div>
  <div class="body">
    <div class="left">
      <div class="element">
        <span>
          <mat-icon>calendar_month</mat-icon>
          Elige la edad de tu nuevo target.
        </span>
        <div class="dobleColumna">
          <div>
            <span>Desde:</span>
            <FormElement [receiveData]="edadInicio" [error]="edadInicioError" [type]="'number'" [placeholder]="'0'"
              [labelActive]="false" (sendData)="getEdadInicio($event)" (updateData)="updateEdadInicio()">
            </FormElement>
          </div>
          <div>
            <span>Hasta:</span>

            <FormElement [receiveData]="edadFin" [error]="edadFinError" [type]="'number'" [placeholder]="'50'"
              [labelActive]="false" (sendData)="getEdadFin($event)" (updateData)="updateEdadFin()"></FormElement>

          </div>

        </div>
      </div>
      <div class="element">
        <span>
          <mat-icon class="material-symbols-outlined">
            celebration
          </mat-icon>
          Elige el evento:
        </span>
        <ng-select [items]="eventos" [bindValue]="'id'" [(ngModel)]="eventoSeleccionado" bindLabel="label"
        placeholder="Elige el evento" (change)="updateEvent(eventoSeleccionado)">
        <ng-template ng-label-tmp let-item="item">
          <span><b>{{ item.title  }}</b> -> {{ item.startDate | date: 'dd/MM/yyyy' }}</span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
          <span><b>{{ item.title   }}</b> -> {{ item.startDate | date: 'dd/MM/yyyy' }}</span>
        </ng-template>

      </ng-select>
        </div>
      <div class="element">
        <span>
          <mat-icon class="material-symbols-outlined">
            location_on
          </mat-icon>
          Elige el centro de tu objetivo:
        </span>
        <ng-select [items]="centros" [bindValue]="'id'" [(ngModel)]="centroSeleccionado" bindLabel="name"
        placeholder="Elige el centro" (change)="updateCenter(centroSeleccionado)">

      </ng-select>
        </div>
      <div class="element">
        <span>
          <mat-icon>person</mat-icon>
          Elige el género del objetivo:
        </span>
        <div class="generoContainer">
          <mat-radio-button name="genero" value="Masculino" [checked]="generoActivo == 'Masculino'"
            (change)="getGenero($event.value)">Hombre</mat-radio-button>
          <mat-radio-button name="genero" value="Femenino" [checked]="generoActivo == 'Femenino'"
            (change)="getGenero($event.value)">Mujer</mat-radio-button>
          <mat-radio-button name="genero" value="Desconocido" [checked]="generoActivo == 'Desconocido'"
            (change)="getGenero($event.value)">Indiferente</mat-radio-button>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="element">
        <span [class.vacio]="nombreAudienciaError">
          <mat-icon>info</mat-icon>
          Dale un nombre a tu audiencia.
        </span>
        <FormElement [receiveData]="nombreAudiencia" [error]="nombreAudienciaError" [type]="'input'"
          [placeholder]="'Nombre de la audiencia'" [labelActive]="false" (sendData)="getNombre($event)"></FormElement>
        <div class="audienciaContainer">
          <p>Audiencia total</p>
          <p>{{audienciaTotal}}</p>
        </div>
        <button type="button" (click)="saveAudience()">Guardar audiencia</button>
      </div>
    </div>
  </div>
</div>