import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { ConfirmPopupComponent } from 'src/app/popups/confirmPopup/confirmPopup.component';
import { PrEventsInfoPopupComponent } from 'src/app/popups/prEventsInfoPopup/prEventsInfoPopup.component';
import { TeamPrPopupComponent } from 'src/app/popups/TeamPrPopup/TeamPrPopup.component';
import { EventService } from 'src/app/services/Api/event.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { PrService } from 'src/app/services/Api/pr.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PopupService } from 'src/app/services/utils/popup.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-PublicRelation-Details',
  templateUrl: './PublicRelation-Details.component.html',
  styleUrls: ['./PublicRelation-Details.component.scss']
})
export class PublicRelationDetailsComponent extends UnsubscribesDestroy implements OnInit {

  //VARIABLES
  idRRPP: number = this.route.snapshot.params['id'];
  pr: any = null;
  dataIsLoaded: Boolean = false;
  urlImages = environment.urlImages;
  estadisticas: any = null

  fechaInicio: string = ""
  auxInicio: string = ""
  fechaFin: string = ""
  auxFin: string = ""
  eventos: Array<any> = []
  eventoSeleccionado = null
  estadisticaTabla: Array<any> = []
  estadisticaTablaBackup: Array<any> = []
  dataIsLoadedAuxiliar: Boolean = false;
  constructor(private loader: LoaderService, private notificaciones: NotificacionesService, private popupSE: PopupService, private loginSE: LoginService, private prSE: PrService, private route: ActivatedRoute, private router: Router, private eventSE: EventService) {
    super();
  }

  ngOnInit() {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      this.checkPermisos();
    }
  }

  //FUNCIONALIDADES
  verEquipo(subordinados) {
    this.popupSE.openPopup(TeamPrPopupComponent, { subordinados: subordinados, nombrePR: this.pr.user.name + " " + this.pr.user.surname });
  }
  filteredEvents(event) {
    this.estadisticaTabla = event;
  }
  openInfoVentasPR(idEvento) {
    this.popupSE.openPopup(PrEventsInfoPopupComponent, { idEvento: idEvento, idPr: this.pr.id });

  }
  //LLAMADAS A LA API

  getPRById() {
    this.prSE.getPRById(this.idRRPP);
    this.prSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return }

      this.pr = value;
      this._unsubInd.next("");
      setTimeout(() => {
        this.getHeaderData();
      }, 500);
    })
    this.prSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.notificaciones.showFeedBack(value.message)
      this._unsub.next("");
    })
  }
  eliminarRRPP() {
    this.popupSE.openPopup(ConfirmPopupComponent, { accion: "Eliminar", tipo: "relación pública" })
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd6)).subscribe(value => {
      if (value == null) { return }
      this.prSE.removePR(Number(this.idRRPP));
      this.prSE.getResultDelete().pipe(takeUntil(this._unsubInd3)).subscribe(value => {
        if (value == null) { return }
        this.notificaciones.showFeedBack(value.message)
        this._unsubInd3.next("");
        setTimeout(() => {
          this.router.navigate(["/publisher/management/public-relation/"]);
        }, 500);

      })
      this.prSE.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe(value => {
        if (value == null) { return }
        this.notificaciones.showError(value.error.message)
        this._unsub.next("");
      })
      this._unsubInd6.next("");
    })
  }
  setPauseRRPP() {
    if (this.pr.pause) {
      this.prSE.setPauseRP({ publicRelation: Number(this.idRRPP), pause: false });
    } else if (!this.pr.pause) {
      this.prSE.setPauseRP({ publicRelation: Number(this.idRRPP), pause: true });
    }
    this.prSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return }
      this.notificaciones.showFeedBack(value.message)
      setTimeout(() => {
        this.getPRById()

      }, 500);
      this._unsubInd.next("");
    })
    this.prSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    })
  }
  getHeaderData() {
    this.prSE.getHeaderDetails(this.pr.id);
    this.prSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return }

      this.estadisticas = value;
      this._unsubInd.next("");
      setTimeout(() => {
        this.getPREventEstadistics()
      }, 500);

    })
    this.prSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.notificaciones.showError(value.message)
      this._unsub.next("");
    })
  }

  getPREventEstadistics() {
    this.prSE.getPREventEstadistics(this.pr.id);
    this.prSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return }
      this.estadisticaTabla = value;
      this.estadisticaTablaBackup = value;
      this.dataIsLoaded = true;
      this.dataIsLoadedAuxiliar = true;
      this._unsubInd.next("");

    })
    this.prSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.notificaciones.showError(value.message)
      this._unsub.next("");
    })
  }
  permiso: Boolean = false;
  checkPermisos() {
    if (localStorage.getItem("tipoUser") == null || localStorage.getItem("tipoUser") == undefined || localStorage.getItem("tipoUser") == '["2"]') {
      this.loginSE.logOut();
      this.notificaciones.showError("No tienes permisos para acceder")
    } else {
      if (localStorage.getItem("tipoUser") == '["0"]' || localStorage.getItem("tipoUser") == '["3"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY") && !localStorage.getItem("permisos")?.includes("CONSULT_COMPANY_GROUP")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else {
          if (localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY") && localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY_GROUP")) {
            this.permiso = true;
          }
          this.idRRPP = this.route.snapshot.params['id'];
          this.getPRById();
        }
      }
      if (localStorage.getItem("tipoUser") == '["1"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else {
          if (localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY")) {
            this.permiso = true;
          }
          this.idRRPP = this.route.snapshot.params['id'];
          this.getPRById();
        }
      }
    }


  }
}
