<li>
  <div
    class="card-md-alt-det"
    [class.seleccionado]="seleccionado"
    [ngStyle]="{
      backgroundColor: this.isFocus ? '#d3d5ef' : ''
    }"
    (click)="pulsado()"
    (focus)="onFocus()"
    (blur)="onBlur()"
  >
    <img
      [src]="event.url"
      alt=""
      class="card-img"
    />
    <div class="float-date">
      
      <span>{{ event.startDate | date: 'd' }}</span
      ><span>{{ event.startDate | date: 'MMM' | uppercase }}</span>
    </div>
    <div class="float-info">
      <div class="float-item">
        <div class="float-icon">
          <img src="/assets/icons/ticket-solid.svg" alt="" />
        </div>
        <div class="float-details">
          <span class="float-title">Entradas</span>
          <span class="float-description">{{event.ticket_vendidos}} / {{event.num_ticket}}</span>
        </div>
      </div>
      <div class="float-item">
        <div class="float-icon">
          <img src="/assets/icons/person-solid.svg" alt="" />
        </div>
        <div class="float-details">
          <span class="float-title">Listas</span>
          <span class="float-description" *ngIf="event.eventList[0].participantes != null">{{event.eventList[0].participantes}} / {{event.eventList[0].total}}</span>
          <span class="float-description" *ngIf="event.eventList[0].participantes == null">0 / {{event.eventList[0].total}}</span>
        </div>
      </div>
      <div class="float-item">
        <div class="float-icon">
          <img src="/assets/icons/star-solid.svg" alt="" />
        </div>
        <div class="float-details">
          <span class="float-title">Reservas</span>
          <span class="float-description">150/ 310</span>
        </div>
      </div>
    </div>
    <div class="card-info">
      <div class="card-title">
        <h5 [title]="event.name">{{ event.title }}</h5>
      </div>
      <div class="card-details">
        <small class="facility">{{event.name}}</small>
        <small class="active" *ngIf="event.isActive">Activo</small>
        <small class="inactive" *ngIf="!event.isActive">No activo</small>
      </div>
    </div>
  </div>
</li>
