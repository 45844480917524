<PageTemplate>
  <ng-container bodyContent>
    <header [searcherActive]="false" [buttonActive]="true" [buttonAction]="'Crear nuevo evento'" [permiso]="editar">
    </header>
    <div class="eventContainer">
      <div class="calendar">
        <app-events-calendar class="misEventosCalendar" [eventos]="events" (cambioDeMes)="cambiarMes($event)" (fechaSeleccionada)="clickFecha($event)">
        </app-events-calendar>
        <mat-spinner *ngIf="!eventsLoaded" class="big"></mat-spinner>
      </div>
      <div class="eventList">
        <h3>{{ fecha | date: 'longDate' }}</h3>
        <div class="noData"  *ngIf="!dataLoaded">
          <mat-spinner class="big"></mat-spinner>
        </div>
        <ng-container *ngIf="dataLoaded">
          <p class="noData" *ngIf="eventosSeleccionados.length == 0">
            No hay eventos en esta fecha
          </p>
          <div class="events" [class.hidden]="eventosSeleccionados.length == 0 && dataLoaded">
            <ul>
              <li *ngFor="let evento of eventosSeleccionados">
                <card [type]="'carrouselCard'" [data]="evento"></card>
              </li>
            </ul>
          </div>
        </ng-container>
      </div>
    </div>
    <searcher [size]="'fullWidth'" [placeHolder]="'Busca aquí un evento'" [toFilter]="events" [type]="'event'"
      (filtered)="filteredEvents($event)"></searcher>
    <div class="eventsContainer">
      <mat-spinner class="big" *ngIf="!dataLoaded"></mat-spinner>
      <ng-container *ngIf="dataLoaded">
        <p class="noData" *ngIf="eventsList.length == 0">No hay eventos disponibles</p>
        <card [data]="event" [type]="'eventCard'" *ngFor="let event of eventsList "></card>
      </ng-container>
    </div>
  </ng-container>
</PageTemplate>