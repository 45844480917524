import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-TeamPrPopup',
  templateUrl: './TeamPrPopup.component.html',
  styleUrls: ['./TeamPrPopup.component.scss']
})
export class TeamPrPopupComponent implements OnInit {
  urlImages = environment.urlImages
  niveles: number = 0
  levels:any = []
  constructor(public dialogRef: MatDialogRef<TeamPrPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) { }

  ngOnInit() {
    document.querySelector(".mat-dialog-container")?.classList.add("rightPopup");
    if (this.modalData.subordinados.length > 0) {
      this.niveles = 1
      this.levels.push({nivel: this.niveles, equipo: this.modalData.subordinados})
    }
    this.getLevels(this.modalData.subordinados)
  }
  getLevels(subordinados) {
    subordinados.forEach(element => {
      if (element.subordinates.length != 0) {
        this.niveles ++;
        this.levels.push({nivel: this.niveles, equipo: element.subordinates})
        this.getLevels(element.subordinates)
      }
    });
  }
  //FUNCIONALIDAD
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
}
