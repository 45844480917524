<PageTemplate>
  <ng-container bodyContent>
    <div class="formHeader">
      <GeneralButton [buttonText]="'Cancelar'" (click)="cancel()"></GeneralButton>
      <div>
        <GeneralButton [buttonText]="'Atrás'" (click)="paso = paso - 1" [class.disabled]="paso == 0"></GeneralButton>
        <GeneralButton [buttonText]="'Siguiente'"  *ngIf="paso < 4" (click)="validateStep()"></GeneralButton>
        <GeneralButton [buttonText]="'Crear evento'"  *ngIf="paso == 4 && !editar" (click)="crearEvento()"></GeneralButton>
        <GeneralButton [buttonText]="'Finalizar edición'"  *ngIf="paso == 4 && editar" [routerLink]="['/publisher/management/events/my-events']" ></GeneralButton>
      </div>
    </div>
    <steps [pasos]="pasos" [activo]="paso" (activoChange)="cambiarPaso($event)"></steps>
    <CreateEditEvent-Step1 *ngIf="paso == 0" (dataToUpdate)="editarEvento($event)"(data)="getDataFromStep1($event)" (validStep)="isValidStep1($event)" [receiveData]="dataPaso1"></CreateEditEvent-Step1>
    <CreateEditEvent-Step2 *ngIf="paso == 1" (dataToUpdate)="editarEvento($event)" (data)="getDataFromStep2($event)" (validStep)="isValidStep2($event)" [receiveData]="dataPaso2"></CreateEditEvent-Step2>
    <CreateEditEvent-Step3 *ngIf="paso == 2" (dataToUpdate)="editarEvento($event)" (data)="getDataFromStep3($event)" (validStep)="isValidStep3($event)" [receiveData]="dataPaso3" [limitacionFecha]="dataPaso2" (update)="getEvent()"></CreateEditEvent-Step3>
    <CreateEditEvent-Step4 *ngIf="paso == 3" (dataToUpdate)="editarEvento($event)" (data)="getDataFromStep4($event)" (validStep)="isValidStep4($event)" [receiveData]="dataPaso4" ></CreateEditEvent-Step4>
    <!-- <CreateEditEvent-Step5 *ngIf="paso == 4" (dataToUpdate)="editarEvento($event)" (data)="getDataFromStep5($event)" (validStep)="isValidStep5($event)" [receiveData]="dataPaso5"></CreateEditEvent-Step5> -->
    <CreateEditEvent-Step6 *ngIf="paso == 4" (dataToUpdate)="editarEvento($event)" (data)="getDataFromStep6($event)" (validStep)="isValidStep6($event)" [receiveData]="dataPaso6"></CreateEditEvent-Step6>
  </ng-container>
</PageTemplate>
