<PageTemplate>
  <ng-container bodyContent>
    <header [searcherActive]="false"></header>
    <div class="pageHeader">
      <h1>Base de datos de los usuarios de Memorable</h1>
      <searcher [placeHolder]="'Busca aquí un usuario'" (emitChange)="getFilter($event)"></searcher>
    </div>
    <mat-spinner *ngIf="!dataIsLoaded"></mat-spinner>
    <div class="pageBody" *ngIf="dataIsLoaded">
      <p class="noData" *ngIf="usuarios.length == 0">No hay usuarios que mostrar</p>
      <div class="tableContainer" *ngIf="usuarios.length > 0">
        <table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th class="center">DNI</th>
              <th class="center">Email</th>
              <th class="center">Teléfono</th>
              <th class="center">Edad</th>
              <th class="center">Género</th>
              <th class="center">Centro</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of usuarios" (click)="$event.stopPropagation();openEditUser(user.id)">
              <td>{{user.name}} {{user.surname}}</td>
              <td class="center">{{user.dni}}</td>
              <td class="center">{{user.email}}</td>
              <td class="center">{{user.phone}}</td>
              <td class="center">{{user.age}}</td>
              <td class="center">{{user.gender}}</td>
              <td class="center">{{user.center}}</td>
              <td>
                <mat-icon (click)="$event.stopPropagation();openEditUser(user.id)">edit</mat-icon>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="paginacion" *ngIf="dataIsLoaded && usuarios.length > 0">
      <span (click)="paginaAnterior()" [class.disabled]="numPagina == 1">
        << Página anterior</span>
          <span [ngStyle]="{'text-decoration': 'none'}">{{numPagina}}</span>
          <span (click)="siguientePagina()" [class.disabled]="usuarios.length < 6">Siguiente página >></span>
    </div>
  </ng-container>
</PageTemplate>