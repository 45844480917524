<PageTemplate>
  <ng-container bodyContent>
    <header [searcherActive]="false"></header>
    <dateSteps [steps]="dateSteps" (stepSelected)="changeStep($event)"></dateSteps>
    <div class="infoContainer" >
      <Info [typeCard]="'admin'" [currency]="true" [loaded]="isDataLoaded" [icon]="'/assets/icons/graficaIcono.svg'" [type]="'Facturación total de la empresa'" [value]="estadisticas.ventas"></Info>
      <Info [typeCard]="'admin'" [loaded]="isDataLoaded" [icon]="'/assets/icons/graficaIcono.svg'"  [type]="'Ventas totales'" [value]="estadisticas.entradas_vendidas" *ngIf="estadisticas.entradas_vendidas != null"></Info>
      <Info [typeCard]="'admin'" [loaded]="isDataLoaded" [icon]="'/assets/icons/graficaIcono.svg'"  [type]="'Ventas totales'" [value]="0" *ngIf="estadisticas.entradas_vendidas == null"></Info>
      <Info [typeCard]="'admin'" [loaded]="isDataLoaded" [icon]="'/assets/icons/calendario.svg'"[type]="'Eventos totales'" [value]="estadisticas.event_week"></Info>
      <Info [typeCard]="'admin'" [loaded]="isDataLoaded" [icon]="'/assets/icons/entrada.svg'" [type]="'Total Relaciones Públicas'" [value]="estadisticas.new_pr"></Info>
    </div>
    <div class="graficaContainer">
      <div class="graficaHeader">
        <toggleMenu [data]="toggleElements" (toggleMenu)="changeToggle($event)"></toggleMenu>
        <ng-select [items]="events" [(ngModel)]="eventSelected" (change)="changeEvent()"bindLabel="title" bindValue="id"
        [searchable]="false" [clearable]="true" [placeholder]="'Selecciona un evento'"></ng-select>
      </div>
      <div class="graficaBody" [class.loaded]="!dataIsLoadedAuxiliar">
        <mat-spinner class="big" *ngIf="!dataIsLoadedAuxiliar"></mat-spinner>
        <SalesChart *ngIf="selectedStep == 0 && dataIsLoadedAuxiliar && tipo=='ventas'" [type]="'weekly'" [data]="datosGrafica"></SalesChart>
        <SalesChart *ngIf="selectedStep == 1 && dataIsLoadedAuxiliar  && tipo=='ventas'" [type]="'biWeekly'" [data]="datosGrafica"></SalesChart>
        <SalesChart *ngIf="selectedStep == 2 && dataIsLoadedAuxiliar  && tipo=='ventas'" [type]="'monthly'" [data]="datosGrafica"></SalesChart>
        <SalesChart *ngIf="selectedStep == 3 && dataIsLoadedAuxiliar  && tipo=='ventas'" [type]="'anual'" [data]="datosGrafica"></SalesChart>
        <SalesChart *ngIf="selectedStep == 4 && dataIsLoadedAuxiliar  && tipo=='ventas'" [dates]="{inicio:inicio, fin: fin}" [type]="'custom'" [data]="datosGrafica"></SalesChart>

        <UnitsChart *ngIf="selectedStep == 0 && dataIsLoadedAuxiliar  && tipo=='unidades'" [type]="'weekly'" [data]="datosGrafica"></UnitsChart>
        <UnitsChart *ngIf="selectedStep == 1 && dataIsLoadedAuxiliar  && tipo=='unidades'" [type]="'biWeekly'" [data]="datosGrafica"></UnitsChart>
        <UnitsChart *ngIf="selectedStep == 2 && dataIsLoadedAuxiliar  && tipo=='unidades'" [type]="'monthly'" [data]="datosGrafica"></UnitsChart>
        <UnitsChart *ngIf="selectedStep == 3 && dataIsLoadedAuxiliar  && tipo=='unidades'" [type]="'anual'" [data]="datosGrafica"></UnitsChart>
        <UnitsChart *ngIf="selectedStep == 4 && dataIsLoadedAuxiliar  && tipo=='unidades'"[dates]="{inicio:inicio, fin: fin}"  [type]="'custom'" [data]="datosGrafica"></UnitsChart>
      </div>
    </div>
  </ng-container>
</PageTemplate>
