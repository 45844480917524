import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-previewDocumentsPopup',
  templateUrl: './previewDocumentsPopup.component.html',
  styleUrls: ['./previewDocumentsPopup.component.scss']
})
export class PreviewDocumentsPopupComponent implements OnInit {

  mensaje = ""

  constructor(public dialogRef: MatDialogRef<PreviewDocumentsPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any,) { }

  ngOnInit() {
  }
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = {removeDocument: true}
    this.dialogRef.close(p);
  }
}
