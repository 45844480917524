import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigurationOptions } from 'intl-input-phone';
import { sha256 } from 'js-sha256';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { CategoriesService } from 'src/app/services/Api/categories.service';
import { CompanyService } from 'src/app/services/Api/company.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'app-register-company-form',
  templateUrl: './register-company-form.component.html',
  styleUrls: ['./register-company-form.component.scss']
})
export class RegisterCompanyFormComponent extends UnsubscribesDestroy implements OnInit {

  pasoSeleccionado = 0;

  nombreComercialNegocio: string = ""
  nombreComercialNegocioError: boolean = false;
  razonSocialNegocio: string = ""
  razonSocialNegocioError: boolean = false;
  cifNegocio: string = ""
  cifNegocioError: boolean = false;
  domicilioFiscalNegocio: string = ""
  domicilioFiscalNegocioError: boolean = false;
  codigoPostal: String = ""
  codigoPostalError: Boolean = false;
  paso0Checked = false;
  paso0Valido = false

  nombreAdministradorNegocio: string = ""
  nombreAdministradorNegocioError: boolean = false
  apellidosAdministradorNegocio: string = ""
  apellidosAdministradorNegocioError: boolean = false
  emailAdministracionNegocio: string = ""
  emailAdministracionNegocioError: boolean = false;
  telefonoAdministradorNegocio: number | null = null
  telefonoAdministradorNegocioError: boolean = false
  fechaNacimientoAdministrador:String = ""
  fechaNacimientoAdministradorError: boolean = false;
  dni: String = ""
  dniError: Boolean = false;
  repetirEmailAdminsitrador:String = ""
  repetirEmailAdminsitradorError: Boolean = false;
  generos = ["Hombre", "Mujer"]
  genero = "Hombre"
  paso1Valido = false;

  terminosConcicionesAceptados: boolean = false
  cockiesPrivacidadAceptados: boolean = false;
  marketingMail: boolean = false;

  categorias: any = [];
  categoriasSeleccionadas: any = []
  categoriasSeleccionadasError: boolean = false
  ambitoGeograficoNegocio: string = ""
  ambitoGeograficoNegocioError: boolean = false;
  paginaWebNegocio: string = ""
  paginaWebNegocioError: boolean = false;

  paso2Valido = false

  visible: Boolean = false;
  visibleRepeat: Boolean = false;

  newPass: string = ""
  newPassError: Boolean = false;
  repeatNewPass: string = ""
  repeatNewPassError: Boolean = false;
  constructor(private loader: LoaderService, private notificaciones: NotificacionesService, private categoriesSE: CategoriesService, private companySE: CompanyService, private router: Router) {
    super()
  }

  ngOnInit() {
    this.getCategories();
  }
  siguientePaso() {
    if (this.pasoSeleccionado < 3) {
      this.pasoSeleccionado++;
    }

  }
  volverPaso() {
    if (this.pasoSeleccionado != 0) {
      this.pasoSeleccionado--;
    }
  }
  changeDate(event: any) {
    this.fechaNacimientoAdministrador = event.value

  }
  getCategories() {
    this.categoriesSE.getCategoriesFromCompany();
    this.loader.open();
    this.categoriesSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return }
      value.forEach(element => {
        this.categorias.push(element.name)
      });
      this.loader.closeSlow();
      this._unsubInd.next("")
    })
    this.categoriesSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.loader.closeSlow();
      this.notificaciones.showError(value.error.message)
      this._unsub.next("")
    })
  }
  seleccionarCategoria(categoria) {
    if (!this.categoriasSeleccionadas.includes(categoria)) {
      this.categoriasSeleccionadas.push(categoria)
    } else {
      this.categoriasSeleccionadas.splice(this.categoriasSeleccionadas.indexOf(categoria), 1);
    }
  }
  validatePaso0() {

    if (this.nombreComercialNegocio == "") {
      this.nombreComercialNegocioError = true;
    } else {
      this.nombreComercialNegocioError = false;
    }
    if (this.razonSocialNegocio == "") {
      this.razonSocialNegocioError = true;
    } else {
      this.razonSocialNegocioError = false;
    }
    if (this.cifNegocio == "") {
      this.cifNegocioError = true;
    } else {
      this.cifNegocioError = false;
    }
    if (this.domicilioFiscalNegocio == "") {
      this.domicilioFiscalNegocioError = true;
    } else {
      this.domicilioFiscalNegocioError = false;
    }
    if (this.codigoPostal == "") {
      this.codigoPostalError = true;
    }else{
      this.codigoPostalError = false;
    }
    if (!this.nombreComercialNegocioError && !this.razonSocialNegocioError && !this.cifNegocioError && !this.domicilioFiscalNegocioError && !this.codigoPostalError) {
      this.paso0Valido = true;
      this.siguientePaso()
    } else {
      this.paso0Valido = false;
      this.notificaciones.showError("No pueden haber campos vacíos")
    }
  }

  validatePaso1() {
    if (this.nombreAdministradorNegocio == "") {
      this.nombreAdministradorNegocioError = true;
    } else {
      this.nombreAdministradorNegocioError = false;
    }
    if (this.apellidosAdministradorNegocio == "") {
      this.apellidosAdministradorNegocioError = true;
    } else {
      this.apellidosAdministradorNegocioError = false;
    }
    if (this.emailAdministracionNegocio == "") {
      this.emailAdministracionNegocioError = true;
    } else {
      this.emailAdministracionNegocioError = false;
    }
    if (this.telefonoAdministradorNegocio == null) {
      this.telefonoAdministradorNegocioError = true;
    } else {
      this.telefonoAdministradorNegocioError = false;
    }
  
    if (this.fechaNacimientoAdministrador == "") {
      this.fechaNacimientoAdministradorError = true;
    }else{
      this.fechaNacimientoAdministradorError = false;
    }
    if (this.dni == "") {
      this.dniError = true;
    }else{
      this.dniError = false;
    }
    if (this.repetirEmailAdminsitrador == "") {
      this.repetirEmailAdminsitradorError = true;
    }else{
      this.repetirEmailAdminsitradorError = false;
    }
    if (this.newPass == "") {
      this.newPassError = true;
    }else{
      this.newPassError = false;
    }
    if (this.repeatNewPass == "") {
      this.repeatNewPassError = true;
    }else{
      this.repeatNewPassError = false;
    }
  
    if (!this.nombreAdministradorNegocioError && !this.apellidosAdministradorNegocioError && !this.emailAdministracionNegocioError && !this.telefonoAdministradorNegocioError && !this.fechaNacimientoAdministradorError && !this.dniError && !this.repetirEmailAdminsitradorError && !this.newPassError && !this.repeatNewPassError) {
      if (this.emailAdministracionNegocio != this.repetirEmailAdminsitrador) {
        this.notificaciones.showError("Los e-mail introducidos no coinciden")
        this.emailAdministracionNegocioError = true;
        this.repetirEmailAdminsitradorError = true;
      }else{
        this.emailAdministracionNegocioError = false;
        this.repetirEmailAdminsitradorError = false;
        if (this.newPass != this.repeatNewPass) {
          this.notificaciones.showError("Las contraseñas introducidas no coinciden")
          this.repeatNewPassError = true;
          this.newPassError = true;
        }else{
          this.repeatNewPassError = true;
          this.newPassError = true;
          this.paso1Valido = true;
          this.siguientePaso()
        }
    
   
      }
    } else {
      this.paso1Valido = false;
      this.notificaciones.showError("No pueden haber campos vacíos")
    }
  }
  validatePaso2() {
    if (this.categoriasSeleccionadas.length == 0) {
      this.categoriasSeleccionadasError = true;
    } else {
      this.categoriasSeleccionadasError = false;
    }

    if (!this.categoriasSeleccionadasError) {
      this.siguientePaso()
      this.paso2Valido = true;
    } else {
      this.paso2Valido = false;
      this.notificaciones.showError("Debes seleccionar al menos una actividad")
    }
  }
  sendData() {
    var company = {
      name: this.nombreComercialNegocio,
      tradeName: this.razonSocialNegocio,
      cif: this.cifNegocio,
      geographicalArea: this.ambitoGeograficoNegocio,
      year: 2000,
      webpage: this.paginaWebNegocio,
      taxAddress: { street: this.domicilioFiscalNegocio, zipCode: this.codigoPostal,},
      categories: this.categoriasSeleccionadas,
      description: "",
      admin: {
        email: this.emailAdministracionNegocio,
        name: this.nombreAdministradorNegocio,
        surname: this.apellidosAdministradorNegocio,
        phone: this.telefonoAdministradorNegocio,
        marketingMails: this.marketingMail,
        birthDate: this.fechaNacimientoAdministrador,
        gender: this.genero,
        dni: this.dni,
        password: sha256(this.newPass),
        address: {
          country: ""
        }
      }
    }
    this.companySE.addNewCompanyGroup(company);
    this.loader.open();
    this.companySE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe(value => {
      if (value == null) { return };
      this.loader.closeSlow();
      this.notificaciones.showFeedBack(value.message)
      this._unsubInd2.next("")
      this.router.navigate(["/login"])
    })
    this.companySE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return };
      this.loader.closeSlow();
      this.notificaciones.showError(value.error.message)
      this._unsub.next("")
    })
  }
}
