import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-pr-revenue-monthly-chart',
  templateUrl: './pr-revenue-monthly-chart.component.html',
  styleUrls: ['./pr-revenue-monthly-chart.component.scss'],
})
export class PrRevenueMonthlyChartComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  @Input() data: any = {}

  public lineChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [],
        label: 'Tickets',
        backgroundColor: 'rgba(110, 116, 211, 0.2)',
        borderColor: 'rgba(110, 116, 211, 1)',
        borderWidth: 2,
        pointBackgroundColor: 'rgba(110, 116, 211 ,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        fill: 'origin',
      },
    ],
    labels: [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
      '24',
      '25',
      '26',
      '27',
      '28',
    ],
  };

  public lineChartOptions: ChartConfiguration['options'] = {
    responsive: true,

    // Generic options
    font: { family: 'Rubik' },

    // Specific options
    layout: {
      padding: 40,
    },
    elements: {
      line: {
        tension: 0.5,
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#0e17b6',
          font: {
            weight: '700',
          },
        },
        grid: {
          lineWidth: 0,
        },
      },
      y: {
        grid: {
          color: '#dfe0f5',
          drawBorder: false,
        },
        ticks: {
          callback: (value) => `${value} €`,
          color: '#0e17b6',
          font: {},
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
        align: 'end',
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        yAlign: 'bottom',
        enabled: true,
        backgroundColor: '#0e17b6',
        padding: 14,
        displayColors: false,
        callbacks: {
          title: () => ``,
          label: function (context) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat('es-ES', {
                style: 'currency',
                currency: 'EUR',
              }).format(context.parsed.y);
            }
            return label;
          },
        },
      },
    },
  };

  public lineChartType: ChartType = 'line';

  constructor() {}

  ngOnInit(): void {
    this.data.forEach(element => {
      switch (element.purchase_date.split("T")[0].split("-")[2]) {
        case "01":
          this.lineChartData.datasets[0].data[0] = element.recaudacion_ticket
          break;
        case "02":
          this.lineChartData.datasets[0].data[1] = element.recaudacion_ticket
          break;
        case "03":
          this.lineChartData.datasets[0].data[2] = element.recaudacion_ticket
          break;
        case "04":
          this.lineChartData.datasets[0].data[3] = element.recaudacion_ticket
          break;
        case "05":
          this.lineChartData.datasets[0].data[4] = element.recaudacion_ticket
          break;
        case "06":
          this.lineChartData.datasets[0].data[5] = element.recaudacion_ticket
          break;
        case "07":
          this.lineChartData.datasets[0].data[6] = element.recaudacion_ticket
          break;
        case "08":
          this.lineChartData.datasets[0].data[7] = element.recaudacion_ticket
          break;
        case "09":
          this.lineChartData.datasets[0].data[8] = element.recaudacion_ticket
          break;
        case "10":
          this.lineChartData.datasets[0].data[9] = element.recaudacion_ticket
          break;
        case "11":
          this.lineChartData.datasets[0].data[10] = element.recaudacion_ticket
          break;
        case "12":
          this.lineChartData.datasets[0].data[11] = element.recaudacion_ticket
          break;
        case "13":
          this.lineChartData.datasets[0].data[12] = element.recaudacion_ticket
          break;
        case "14":
          this.lineChartData.datasets[0].data[13] = element.recaudacion_ticket
          break;
        case "15":
          this.lineChartData.datasets[0].data[14] = element.recaudacion_ticket
          break;
        case "16":
          this.lineChartData.datasets[0].data[15] = element.recaudacion_ticket
          break;
        case "17":
          this.lineChartData.datasets[0].data[16] = element.recaudacion_ticket
          break;
        case "18":
          this.lineChartData.datasets[0].data[17] = element.recaudacion_ticket
          break;
        case "19":
          this.lineChartData.datasets[0].data[18] = element.recaudacion_ticket
          break;
        case "20":
          this.lineChartData.datasets[0].data[19] = element.recaudacion_ticket
          break;
        case "21":
          this.lineChartData.datasets[0].data[20] = element.recaudacion_ticket
          break;
        case "22":
          this.lineChartData.datasets[0].data[21] = element.recaudacion_ticket
          break;
        case "23":
          this.lineChartData.datasets[0].data[22] = element.recaudacion_ticket
          break;
        case "24":
          this.lineChartData.datasets[0].data[23] = element.recaudacion_ticket
          break;
        case "25":
          this.lineChartData.datasets[0].data[24] = element.recaudacion_ticket
          break;
        case "26":
          this.lineChartData.datasets[0].data[25] = element.recaudacion_ticket       
          break;
        case "27":
          this.lineChartData.datasets[0].data[26] = element.recaudacion_ticket
          break;
        case "28":
          this.lineChartData.datasets[0].data[27] = element.recaudacion_ticket
          break;
        case "29":
          this.lineChartData.datasets[0].data[28] = element.recaudacion_ticket
          break;
        case "30":
          this.lineChartData.datasets[0].data[29] = element.recaudacion_ticket
          break;
        case "31":
          this.lineChartData.datasets[0].data[30] = element.recaudacion_ticket
          break;
      }
    });
  }
}
