export class StaticUtilities {
    static base64ToFormData(base64){
        let formData=new FormData();
        if(base64 instanceof Array){
            for (let i = 0; i < base64.length; i++) {
                let extension=base64[i].split(";")[0].split("/")[1];
                let file=this.base64ToBlob(base64[i]);
                formData.append("upload"+i,file,"fichero"+i+"."+extension);
            }
        }else{
            let extension=base64.split(";")[0].split("/")[1];
            let file=this.base64ToBlob(base64);
            formData.append("upload",file,"fichero."+extension);
        }
        return formData;
    }
    static dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
          
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      
      return new File([u8arr], filename, {type:mime});
  }
    static base64ToBlob(base64){    
        let splitDataURI = base64.split(',')
        let byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        let mimeString = splitDataURI[0].split(':')[1].split(';')[0]

        let ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

        return new Blob([ia], { type: mimeString })
    }
    static hexToHSL(H) {
      //H=H.replace("#","");
      // Convert hex to RGB first
      let r = 0, g = 0, b = 0;
      if (H.length == 4) {
        r = +("0x" + H[1] + H[1]);
        g = +("0x" + H[2] + H[2]);
        b = +("0x" + H[3] + H[3]);
      } else if (H.length == 7) {
        r = +("0x" + H[1] + H[2]);
        g = +("0x" + H[3] + H[4]);
        b = +("0x" + H[5] + H[6]);
      }
      // Then to HSL
      r /= 255;
      g /= 255;
      b /= 255;
      let cmin = Math.min(r,g,b),
          cmax = Math.max(r,g,b),
          delta = cmax - cmin,
          h = 0,
          s = 0,
          l = 0;
    
      if (delta == 0)
        h = 0;
      else if (cmax == r)
        h = ((g - b) / delta) % 6;
      else if (cmax == g)
        h = (b - r) / delta + 2;
      else
        h = (r - g) / delta + 4;
    
      h = Math.round(h * 60);
    
      if (h < 0)
        h += 360;
    
      l = (cmax + cmin) / 2;
      s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
      s = +(s * 100).toFixed(0);
      l = +(l * 100).toFixed(0);
    
      return "hsl(" + h + "," + s + "%," + l + "%)";
    }
    static HSLToHex(h,s,l) {
      s /= 100;
      l /= 100;
    
      let c = (1 - Math.abs(2 * l - 1)) * s,
          x = c * (1 - Math.abs((h / 60) % 2 - 1)),
          m = l - c/2,
          r = 0,
          g = 0, 
          b = 0; 
    
      if (0 <= h && h < 60) {
        r = c; g = x; b = 0;
      } else if (60 <= h && h < 120) {
        r = x; g = c; b = 0;
      } else if (120 <= h && h < 180) {
        r = 0; g = c; b = x;
      } else if (180 <= h && h < 240) {
        r = 0; g = x; b = c;
      } else if (240 <= h && h < 300) {
        r = x; g = 0; b = c;
      } else if (300 <= h && h < 360) {
        r = c; g = 0; b = x;
      }
      // Having obtained RGB, convert channels to hex
      let rP = Math.round((r + m) * 255).toString(16);
      let gP = Math.round((g + m) * 255).toString(16);
      let bP = Math.round((b + m) * 255).toString(16);
  
      // Prepend 0s, if necessary
      if (rP.length == 1)
        rP = "0" + r;
      if (gP.length == 1)
        gP = "0" + g;
      if (bP.length == 1)
        bP = "0" + b;
    
      return "#" + rP + gP + bP;
    }
}
