import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { FacilityService } from 'src/app/services/Api/facility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'card',
  templateUrl: './Cards.component.html',
  styleUrls: ['./Cards.component.scss']
})
export class CardsComponent extends UnsubscribesDestroy implements OnInit {

  @Input() type:String = ""
  @Input() data:any = null
  @Input() elementActive: Number = -1 
  @Input() redirect: Boolean = true
  @Input() disabled: Boolean = false;
  urlImages = environment.urlImages
  @Input() deleteActive: Boolean = false;
  @Output() confirmDelete = new EventEmitter<any>();

  constructor(private router: Router) {
    super();
  }

  ngOnInit() {
  }
  navigateToEvent(){
    if(this.redirect){
      this.router.navigate(["/publisher/management/events/my-events/event-details/"+this.data.id])
    }
  }
  handleMissingImage(event){
  }
  emitDelete(){
    this.confirmDelete.emit(true)
  }
 
}
