<nav class="sidebar">
  <div class="sidebar-container">
    <div class="logo">
      <img (click)="goToWelcomePage()" src="/assets/icons/memorableLogoText.png" alt="">
    </div>
    <div class="companyAdmnistratorGeneralContainer">
      <div class="companySelectorContainer" (click)="controlarDespliegueEmpresas()"
        [class.selected]="desplegarEmpresas">
        <ng-container *ngIf="onlySuperAdmin">
          <div>
            <img [src]="groupImage" alt="" *ngIf="userType != '3'">
            <span *ngIf="userType == '3'">SA</span>
          </div>
        </ng-container>
        <ng-container *ngIf="!onlySuperAdmin">
          <div (click)="$event.stopPropagation();goToWelcomePage()">
            <img [src]="groupImage" alt="" *ngIf="userType != '3'">
            <span *ngIf="userType == '3'">SA</span>
          </div>
        </ng-container>

        <div *ngIf="userType != '3'">
          <span>{{groupName}}</span>
          <span>{{companyName}}</span>
        </div>
        <div *ngIf="userType == '3'">
          <span>Superadmin</span>
          <span>Sistema</span>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="12.692" height="19.931" viewBox="0 0 12.692 19.931">
          <g id="Grupo_11575" data-name="Grupo 11575" transform="translate(-266.906 -130.034)">
            <path id="Trazado_10151" data-name="Trazado 10151" d="M-16164.68,15594.229l4.932-4.932,4.933,4.932"
              transform="translate(16433 -15458.263)" fill="none" stroke="#bababa" stroke-linecap="round"
              stroke-linejoin="round" stroke-width="2" />
            <path id="Trazado_10152" data-name="Trazado 10152" d="M-16164.68,15589.3l4.932,4.932,4.933-4.932"
              transform="translate(16433 -15445.263)" fill="none" stroke="#bababa" stroke-linecap="round"
              stroke-linejoin="round" stroke-width="2" />
          </g>
        </svg>
      </div>
      <div class="companyList" [class.opened]="desplegarEmpresas">
        <div class="jerarquia">
          <div>
            <img [src]="groupImage" alt="">
          </div>
          <div>
            <span>Panel administración matriz</span>
            <span class="navigate" (click)="goToAdministration()">{{groupName}}</span>
          </div>
        </div>
        <div class="company">
          <span>Administración negocios filiales</span>
          <div *ngFor="let company of companies" [class.active]="company.id == companyId"
            (click)="reloadCompany(company.id)" (mouseenter)="updateGo(company.id)" (mouseleave)="removeGo()">
            <div><span>{{company.name.charAt(0).toUpperCase()}}{{company.name.charAt(1).toUpperCase()}}</span></div>
            <span>{{company.name}}</span>
          </div>
        </div>
        <button type="button" (click)="logOut()">
          <svg xmlns="http://www.w3.org/2000/svg" width="15.035" height="16.192" viewBox="0 0 15.035 16.192">
            <g id="Capa_2" data-name="Capa 2" transform="translate(0.507 -2.35)">
              <g id="Capa_1" data-name="Capa 1" transform="translate(0 2.85)">
                <path id="Trazado_42" data-name="Trazado 42" d="M8.634,14.58a7.009,7.009,0,1,0,4.864.03"
                  transform="translate(-4.015 -12.986)" fill="none" stroke="#1117a8" stroke-linecap="round"
                  stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1" />
                <line id="Línea_62" data-name="Línea 62" x1="0.002" y2="6.558" transform="translate(7.007)" fill="none"
                  stroke="#1117a8" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1" />
              </g>
            </g>
          </svg>
          <span>Cerrar sesión</span></button>
        <button type="button" *ngIf="userType == '0'" [routerLink]="['/admin/bussines/new-bussines']">
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
            <g id="Grupo_11910" data-name="Grupo 11910" transform="translate(-1 -496)">
              <path id="Trazado_10160" data-name="Trazado 10160"
                d="M4.75,14.75a10,10,0,0,1,10-10h0a10,10,0,0,1,10,10h0a10,10,0,0,1-10,10h0a10,10,0,0,1-10-10Z"
                transform="translate(-2.75 492.25)" fill="none" stroke="#fff" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="2" />
              <path id="Trazado_10161" data-name="Trazado 10161" d="M12,8.75v9.014" transform="translate(0 493.743)"
                fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
              <path id="Trazado_10162" data-name="Trazado 10162" d="M17.764,12H8.75" transform="translate(-1.257 495)"
                fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            </g>
          </svg>
          <span>Añadir un nuevo negocio</span></button>
      </div>
    </div>
    <ng-container *ngIf="route != 'admin' && route != 'superadmin'">
      <div class="navegationContainer">
        <div [routerLink]="['/publisher/home']" routerLinkActive="active">
          <img class="noActivo" src="/assets/icons/home.svg" alt="">
          <img class="activo" src="/assets/icons/homeActive.svg" alt="">
          <span>Inicio</span>
        </div>
        <div [routerLink]="['/publisher/management/events']" routerLinkActive="active">
          <img class="noActivo" src="/assets/icons/eventos.svg" alt="">
          <img class="activo" src="/assets/icons/EventosActivo.svg" alt="">
          <span>Mis eventos</span>
        </div>
        <div [routerLink]="['/publisher/management/public-relation']" routerLinkActive="active">
          <img class="noActivo" src="/assets/icons/comunidades.svg" alt="">
          <img class="activo" src="/assets/icons/comunidadesActivo.svg" alt="">
          <span>Relaciones públicas</span>
        </div>
        <div [routerLink]="['/publisher/communities-promotions']" routerLinkActive="active">
          <img class="noActivo" src="/assets/icons/comunidadNoActivo.svg" alt="">
          <img class="activo" src="/assets/icons/comunidadActivo.svg" alt="">
          <span>Comunidades y promociones</span>
        </div>
        <div [routerLink]="['/publisher/facilities']" routerLinkActive="active">
          <img class="noActivo" src="/assets/icons/miEspacio.svg" alt="">
          <img class="activo" src="/assets/icons/miEspacioActivo.svg" alt="">
          <span>Recintos</span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="route == 'admin'">
      <div class="navegationContainer admin">
        <div [routerLink]="['/admin/home']" routerLinkActive="active">
          <img class="noActivo" src="/assets/icons/home.svg" alt="">
          <img class="activo" src="/assets/icons/homeActive.svg" alt="">
          <span>Inicio</span>
        </div>
        <div [routerLink]="['/admin/bussines']" routerLinkActive="active">
          <img class="noActivo" src="/assets/icons/miEspacio.svg" alt="">
          <img class="activo" src="/assets/icons/miEspacioActivo.svg" alt="">
          <span>Negocios</span>
        </div>
        <div (click)="openSocial(); $event.stopPropagation()" class="social " [class.subMenuOpen]="openedSocial"
          [class.active]="openedSocial">
          <div>
            <img src="/assets/icons/comunidadNoActivo.svg" alt="">
            <span>Social</span>
          </div>
          <div class="subMenu" *ngIf="openedSocial">
            <span [routerLink]="['/admin/social/communities']" routerLinkActive="subMenuActive">
              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16">
                <path id="Polígono_6" data-name="Polígono 6"
                  d="M6.586,1.414a2,2,0,0,1,2.828,0l3.172,3.172A2,2,0,0,1,11.172,8H4.828A2,2,0,0,1,3.414,4.586Z"
                  transform="translate(8) rotate(90)" fill="#1117a8" />
              </svg>

              Todas mis comunidades</span>
            <span [routerLink]="['/admin/social/customer-database']" routerLinkActive="subMenuActive">
              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16">
                <path id="Polígono_6" data-name="Polígono 6"
                  d="M6.586,1.414a2,2,0,0,1,2.828,0l3.172,3.172A2,2,0,0,1,11.172,8H4.828A2,2,0,0,1,3.414,4.586Z"
                  transform="translate(8) rotate(90)" fill="#1117a8" />
              </svg>
              CRM General
            </span>
            <span [routerLink]="['/admin/social/promotional-page']" routerLinkActive="subMenuActive">
              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16">
                <path id="Polígono_6" data-name="Polígono 6"
                  d="M6.586,1.414a2,2,0,0,1,2.828,0l3.172,3.172A2,2,0,0,1,11.172,8H4.828A2,2,0,0,1,3.414,4.586Z"
                  transform="translate(8) rotate(90)" fill="#1117a8" />
              </svg>

              Página promocional
            </span>
          </div>
        </div>
        <div (click)="openAdministracion(); $event.stopPropagation()" class="social "
          [class.subMenuOpen]="openedAdministracion" [class.active]="openedAdministracion">
          <div>
            <img src="/assets/icons/crownNoActive.svg" alt="">
            <span>Administración</span>
          </div>
          <div class="subMenu" *ngIf="openedAdministracion">
            <span [routerLink]="['/admin/administration/invoices']" routerLinkActive="subMenuActive">
              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16">
                <path id="Polígono_6" data-name="Polígono 6"
                  d="M6.586,1.414a2,2,0,0,1,2.828,0l3.172,3.172A2,2,0,0,1,11.172,8H4.828A2,2,0,0,1,3.414,4.586Z"
                  transform="translate(8) rotate(90)" fill="#1117a8" />
              </svg>
              Liquidaciones
            </span>
            <span [routerLink]="['/admin/administration/my-profile']" routerLinkActive="subMenuActive">
              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16">
                <path id="Polígono_6" data-name="Polígono 6"
                  d="M6.586,1.414a2,2,0,0,1,2.828,0l3.172,3.172A2,2,0,0,1,11.172,8H4.828A2,2,0,0,1,3.414,4.586Z"
                  transform="translate(8) rotate(90)" fill="#1117a8" />
              </svg>
              Mi perfil
            </span>
            <span [routerLink]="['/admin/administration/my-group']" routerLinkActive="subMenuActive">
              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16">
                <path id="Polígono_6" data-name="Polígono 6"
                  d="M6.586,1.414a2,2,0,0,1,2.828,0l3.172,3.172A2,2,0,0,1,11.172,8H4.828A2,2,0,0,1,3.414,4.586Z"
                  transform="translate(8) rotate(90)" fill="#1117a8" />
              </svg>
              Datos de empresa
            </span>
            <span [routerLink]="['/admin/administration/users-permissions']" routerLinkActive="subMenuActive">
              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16">
                <path id="Polígono_6" data-name="Polígono 6"
                  d="M6.586,1.414a2,2,0,0,1,2.828,0l3.172,3.172A2,2,0,0,1,11.172,8H4.828A2,2,0,0,1,3.414,4.586Z"
                  transform="translate(8) rotate(90)" fill="#1117a8" />
              </svg>
              Usuarios y permisos
            </span>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="route == 'superadmin'">
      <div class="navegationContainer superadmin">
        <div [routerLink]="['/superadmin/home']" routerLinkActive="active">
          <img class="noActivo" src="/assets/icons/home.svg" alt="">
          <img class="activo" src="/assets/icons/homeActive.svg" alt="">
          <span>Inicio</span>
        </div>
        <div class="social ">
          <div routerLinkActive="active" [routerLink]="['/superadmin/crm']">
            <img class="crmIcon" src="/assets/icons/crm.svg" alt="">
            <span>CRM</span>
          </div>
        </div>
        <div class="social ">
          <div routerLinkActive="active" [routerLink]="['/superadmin/entradas']">
            <img class="iconoEntradas" src="/assets/icons/entradaAzul.svg" alt="">
            <span>Gestión entradas</span>
          </div>
        </div>
        <div class="social ">
          <div routerLinkActive="active" [routerLink]="['/superadmin/pagos']">
            <img class="iconoPagos" src="/assets/icons/taquilla.svg" alt="">
            <span>Gestión pagos</span>
          </div>
        </div>
        <div class="social ">
          <div routerLinkActive="active" [routerLink]="['/superadmin/social']">
            <img src="/assets/icons/comunidadNoActivo.svg" alt="">
            <span>Comunidades / social</span>
          </div>
        </div>
        <div class="logout">
          <div (click)="logOut()">
            <svg xmlns="http://www.w3.org/2000/svg" width="15.035" height="16.192" viewBox="0 0 15.035 16.192">
              <g id="Capa_2" data-name="Capa 2" transform="translate(0.507 -2.35)">
                <g id="Capa_1" data-name="Capa 1" transform="translate(0 2.85)">
                  <path id="Trazado_42" data-name="Trazado 42" d="M8.634,14.58a7.009,7.009,0,1,0,4.864.03"
                    transform="translate(-4.015 -12.986)" fill="none" stroke="#1117a8" stroke-linecap="round"
                    stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1" />
                  <line id="Línea_62" data-name="Línea 62" x1="0.002" y2="6.558" transform="translate(7.007)"
                    fill="none" stroke="#1117a8" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1" />
                </g>
              </g>
            </svg>
            <span>Cerrar sesión</span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</nav>