import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'GeneralButton',
  templateUrl: './GeneralButton.component.html',
  styleUrls: ['./GeneralButton.component.scss']
})
export class GeneralButtonComponent implements OnInit {
  @Input() type: String = "primary"
  @Input() buttonText: String = ""
  @Input() permiso: Boolean = true;
  constructor() { }

  ngOnInit() {
  }

}
