<section class="demography-interests-chart">
  <div>
    <div style="display: block">
      <canvas
        baseChart
        [data]="doughnutChartData"
        [type]="doughnutChartType"
        [options]="doughnutChartOptions"
        height="400px"
        width="400px"
      >
      </canvas>
    </div>
  </div>

  <div class="legend">
    <div class="legend-item" *ngFor="let key of keys;let i = index">
      <span class="legend-item-icon icon-1" [ngStyle]="{'backgroundColor': $any(doughnutChartData).datasets[0].backgroundColor[i]}"></span>
      <span class="legend-item-title">{{key}}</span>
    </div>
    <!-- <div class="legend-item">
      <span class="legend-item-icon icon-2"></span>
      <span class="legend-item-title">Música</span>
    </div>
    <div class="legend-item">
      <span class="legend-item-icon icon-3"></span>
      <span class="legend-item-title">Viajes</span>
    </div>
    <div class="legend-item">
      <span class="legend-item-icon icon-4"></span>
      <span class="legend-item-title">Videojuegos</span>
    </div>
    <div class="legend-item">
      <span class="legend-item-icon icon-5"></span>
      <span class="legend-item-title">Cine</span>
    </div>
    <div class="legend-item">
      <span class="legend-item-icon icon-6"></span>
      <span class="legend-item-title">Lectura</span>
    </div> -->
  </div>
</section>
