<div class="generalContainer">
  <div class="subContainer">
    <div>
      <sidebar></sidebar>
    </div>
    <div class="master-container">
      <div class="settings-page">
        <header class="nav-section">
          <nav class="navbar">
            <div class="breadcrumbs">
              <ul>
                <li>
                  <a routerLink="/publisher/home" aria-label="Inicio"
                    ><img src="/assets/icons/house-solid.svg" alt="" />
                  </a>
                </li>
                <li><a routerLink=".">Opciones</a></li>
              </ul>
            </div>
            <div class="nav-options">
              <div class="icons">
             
                <a routerLink="." aria-label="Ayuda" class="nav-icon"
                  ><img src="/assets/icons/questionMark.svg" alt=""
                /></a>
                <a routerLink="." aria-label="Mi usuario" class="nav-icon">
                  <img src="/assets/icons/user.svg" alt=""
                /></a>
              </div>
            </div>
          </nav>
        </header>

        <main>
          <section class="pill-group" *ngIf="false">
            <h3>Información de pagos</h3>
            <ul>
              <li>
                <app-menu-item
                  enlace="./events"
                  titulo="Datos de cuenta bancaria"
                ></app-menu-item>
              </li>
              <li>
                <app-menu-item
                  enlace="."
                  titulo="Métodos de pago"
                ></app-menu-item>
              </li>
            </ul>
          </section>

          <section class="pill-group">
            <h3>Configuración general</h3>
            <ul>
              <li *ngIf="false">
                <app-menu-item
                  enlace="./ticket-store"
                  titulo="Idioma y región"
                ></app-menu-item>
              </li>
              <li *ngIf="false">
                <app-menu-item
                  enlace="./account-notifications"
                  titulo="Notificaciones de la cuenta"
                ></app-menu-item>
              </li>
              <li>
                <app-menu-item
                  enlace="./change-password"
                  titulo="Contraseña y usuario"
                ></app-menu-item>
              </li>
            </ul>
          </section>
        </main>
      </div>
    </div>
  </div>
</div>
