<ng-container>
  <div class="stepContainer">
    <div class="informacionContainer">
      <div>
        <div>
          <span class="title">Facturación total</span>
          <span class="subtitle">Ventas entradas</span>
        </div>
        <mat-spinner *ngIf="!dataIsLoaded" class="medium"></mat-spinner>
        <span *ngIf="dataIsLoaded" class="valor">{{estadisticas.facturacion_total_bruta | currency:'EUR'}}</span>
      </div>
      <div>
        <div>
          <span class="title">Facturación neta</span>
          <span class="subtitle">Ventas entradas - comisiones</span>
        </div>
        <mat-spinner *ngIf="!dataIsLoaded" class="medium"></mat-spinner>
        <span *ngIf="dataIsLoaded" class="valor">{{estadisticas.facturacion_total_neta | currency:'EUR'}}</span>
      </div>
      <div>
        <div>
          <span class="title">Comisiones</span>
          <span class="subtitle">Facturación bruta - Facturación neta</span>
        </div>
        <mat-spinner *ngIf="!dataIsLoaded" class="medium"></mat-spinner>
        <span *ngIf="dataIsLoaded" class="valor">{{estadisticas.comisiones | currency:'EUR'}}</span>
      </div>
      <div *ngIf="estadisticas.reservas == 0">
        <div>
          <span class="title">Entradas</span>
        </div>
        <mat-spinner *ngIf="!dataIsLoaded" class="medium"></mat-spinner>
        <span *ngIf="dataIsLoaded" class="valor">{{estadisticas.entradas}} unidades</span>
      </div>
      <div *ngIf="estadisticas.reservas != 0">
        <div>
          <span class="title">Asistentes</span>
          <span class="subtitle">Prereserva / Confirmados</span>
        </div>
        <mat-spinner *ngIf="!dataIsLoaded" class="medium"></mat-spinner>
        <span *ngIf="dataIsLoaded" class="valor">{{estadisticas.reservas}} / {{estadisticas.entradas}}</span>
      </div>
    </div>
    <div class="graficasContainer">
      <div class="ventasUnidadesContainer">
        <div class="graficaHeader">
          <toggleMenu [data]="toggleElements" (toggleMenu)="changeToggle($event)"></toggleMenu>
          <div class="toggleDates">
            <span title="Semanal" (click)="onSelectEventSalesWeekly()" [ngClass]="isEventSalesWeekly ? 'active' : ''">
              <img src="/assets/icons/calendar-2-solid.svg" alt="" />
            </span>
            <span title="Bisemanal" (click)="onSelectEventSalesBiweekly()"
              [ngClass]="isEventSalesBiweekly ? 'active' : ''">
              <img src="/assets/icons/calendar-day-2-solid.svg" alt="" />
            </span>
            <span title="Mensual" (click)="onSelectEventSalesMonthly()" [ngClass]="isEventSalesMonthly ? 'active' : ''">
              <img src="/assets/icons/calendar-days-2-solid.svg" alt="" />
            </span>
          </div>
        </div>
        <mat-spinner *ngIf="!dataIsLoaded" class="center big"></mat-spinner>
        <ng-container *ngIf="dataIsLoaded">
          <SalesChart *ngIf="isEventSalesWeekly && selectedToggle == 0" [data]="semanales"></SalesChart>
          <SalesChart *ngIf="isEventSalesBiweekly && selectedToggle == 0" [data]="biSemanales" [type]="'biWeekly'">
          </SalesChart>
          <SalesChart *ngIf="isEventSalesMonthly && selectedToggle == 0" [data]="mensuales" [type]="'monthly'">
          </SalesChart>
          <UnitsChart *ngIf="isEventSalesWeekly && selectedToggle == 1" [data]="semanales"></UnitsChart>
          <UnitsChart *ngIf="isEventSalesBiweekly && selectedToggle == 1" [data]="biSemanales" [type]="'biWeekly'">
          </UnitsChart>
          <UnitsChart *ngIf="isEventSalesMonthly && selectedToggle == 1" [data]="mensuales" [type]="'monthly'">
          </UnitsChart>
        </ng-container>
      </div>
      <div class="sexoInteresesContainer">
        <div class="graficaHeader">
          <div>
            <span (click)="onSelectPlatform()" [class.active]="isPlatform">Plataforma</span>
            <span (click)="onSelectGenre()" [class.active]="isDemographyGenre">Sexo</span>
            <span (click)="onSelectAge()" [class.active]="isDemographyAge">Edad</span>
            <span (click)="onSelectInterests()" [class.active]="isDemographyInterests">Intereses</span>
          </div>
        </div>
        <mat-spinner *ngIf="!dataIsLoaded" class="center big"></mat-spinner>

        <ng-container *ngIf="dataIsLoaded">
          <ng-container *ngIf="isPlatform && diagramaPlatform != null">
            <p class="noData" *ngIf="diagramaPlatform!.app == 0 && diagramaPlatform!.web == 0">No hay
              datos para estos parámetros</p>
            <demographicChart [type]="'platform'" [dataSexo]="diagramaPlatform"
              *ngIf="diagramaPlatform!.app > 0 || diagramaPlatform!.web > 0"></demographicChart>
          </ng-container>
          <ng-container *ngIf="isDemographyGenre && diagramaSexo != null">
            <p class="noData" *ngIf="diagramaSexo!.men == 0 && diagramaSexo!.women == 0">No hay
              datos para estos parámetros</p>
            <demographicChart [type]="'sexo'" [dataSexo]="diagramaSexo"
              *ngIf="diagramaSexo!.men > 0 || diagramaSexo!.women > 0"></demographicChart>
          </ng-container>
          <ng-container *ngIf="isDemographyAge && diagramaEdad != null">
            <p class="noData"
              *ngIf="diagramaEdad.catorce_a_dieciocho == 0 && diagramaEdad.cincuenta == 0 && diagramaEdad.cuarenta == 0 && diagramaEdad.dieciocho_a_veintiuno == 0 && diagramaEdad.treinta == 0 && diagramaEdad.veintiuno == 0">
              No hay datos para estos parámetros</p>
            <demographicChart [type]="'edad'" [dataEdad]="diagramaEdad"
              *ngIf="diagramaEdad.catorce_a_dieciocho != 0 || diagramaEdad.cincuenta != 0 || diagramaEdad.cuarenta != 0 || diagramaEdad.dieciocho_a_veintiuno != 0 || diagramaEdad.treinta != 0 || diagramaEdad.veintiuno != 0">
            </demographicChart>
          </ng-container>
          <ng-container *ngIf="isDemographyInterests && diagramaCategories != null">
            <p class="noData" *ngIf="diagramaCategories!.length == 0">No hay datos para estos
              parámetros</p>
            <demographicChart [type]="'categorias'" [dataInterest]="diagramaCategories"
              *ngIf="diagramaCategories!.length != 0"></demographicChart>
          </ng-container>


        </ng-container>
      </div>
    </div>
    <div class="centrosEducativosContainer">
      <div class="centrosEducativosHeader">
        <p>Centros educativos</p>
        <searcher [placeHolder]="'Busca aquí un centro'" [toFilter]="centrosBackUp" [type]="'centro'"
          (filtered)="filteredCentros($event)"></searcher>
      </div>
      <div class="centrosEducativosBody">
        <div class="centrosEducativos">
          <div class="cabecera">
            <span>Centro</span>
            <span>Localidad</span>
            <span>Estudiantes</span>
          </div>
          <div class="tablaCentros" [class.loaded]="centros.length == 0">
            <mat-spinner class="big" *ngIf="centros.length == 0"></mat-spinner>
            <ng-container *ngIf="centros.length != 0">
              <div class="filaCentro" [class.active]="centroSeleccionado == centro.id" *ngFor="let centro of centros"
                (click)="centroSeleccionado = centro.id; centroSeleccionadoAuxiliar = centro;getCentersGrados()">
                <span [title]="centro.name">{{centro.name}}</span>
                <span>{{centro.locality}}</span>
                <span>{{centro.estudiantes}} personas</span>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="gradosEducativos">
          <p class="noData" *ngIf="grados.length == 0">No hay grados disponibles</p>
          <ng-container *ngIf="grados.length  != 0">
            <div class="cabecera">
              <span>{{centroSeleccionadoAuxiliar.name}}</span>
              <span>{{centroSeleccionadoAuxiliar.locality}}</span>
              <span>{{centroSeleccionadoAuxiliar.estudiantes}} estudiantes</span>
            </div>
            <div class="cabecera especial">
              <span>Curso</span>
              <span>Ciclo</span>
              <span>Usuario</span>
            </div>
            <div class="tablaCentros" [class.loaded] = "grados.length == 0">
              <ng-container *ngIf="grados.length != 0">
                <div class="filaCentro" *ngFor="let grado of grados">
                  <span>{{grado.courses}}</span>
                  <span>{{grado.type}}</span>
                  <span>{{grado.student}} personas</span>
                </div>
              </ng-container>
            </div>
          </ng-container>

        </div>
      </div>
    </div>
  </div>

</ng-container>
