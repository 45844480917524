import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'EventDetails-Step4',
  templateUrl: './EventDetails-Step4.component.html',
  styleUrls: ['./EventDetails-Step4.component.scss']
})
export class EventDetailsStep4Component implements OnInit {

  @Input() event:any = null
  transporteSeleccionado: any = { adress: [], arrival_date: "", capacity: null, company: "", fee: null, id: -1, name: "", pick_up_date: "", vehicle_type: "", vehicles_quantity: "" };
  horaRecogida: string = ""
  horaLlegada: string = ""

  constructor() { }

  ngOnInit() {
  }
  seleccionarTransporte() {
    this.horaLlegada = this.transporteSeleccionado.arrival_date.split("T")[1].split("+")[0].split(":")[0] + ":" + this.transporteSeleccionado.arrival_date.split("T")[1].split("+")[0].split(":")[1]
    this.horaRecogida = this.transporteSeleccionado.pick_up_date.split("T")[1].split("+")[0].split(":")[0] + ":" + this.transporteSeleccionado.pick_up_date.split("T")[1].split("+")[0].split(":")[1]
  }

}
