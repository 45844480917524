import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-AvisoIOSPopup',
  templateUrl: './AvisoIOSPopup.component.html',
  styleUrls: ['./AvisoIOSPopup.component.scss']
})
export class AvisoIOSPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AvisoIOSPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, ) { }

  ngOnInit() {


  }
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

}
