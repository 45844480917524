<div class="generalContainer">
  <div class="subContainer">
    <div>
      <sidebar></sidebar>
    </div>
    <div class="master-container">
      <div class="subscriptions-page">
        <header class="nav-section">
          <nav class="navbar">
            <div class="breadcrumbs">
              <ul>
                <li>
                  <a routerLink="/home" aria-label="Inicio"><img src="/assets/icons/house-solid.svg"
                      alt="" />
                  </a>
                </li>
                <li><a routerLink="../">Mi espacio</a></li>
                <li><a routerLink=".">Suscripciones</a></li>
              </ul>
            </div>
            <div class="nav-options">
              <div class="icons">
                <a routerLink="." aria-label="Ayuda" class="nav-icon"><img src="/assets/icons/questionMark.svg"
                    alt="" /></a>
                <a routerLink="." aria-label="Mi usuario" class="nav-icon">
                  <img src="/assets/icons/user.svg" alt="" /></a>
              </div>
            </div>
          </nav>
          <section class="options">
            <div class="left-group">
              <a class="btn-xs" routerLink="../">Atrás</a>
            </div>
            <div class="right-group"></div>
          </section>
        </header>

        <main>
          <section class="subscription-options">
            <div class="card" [class.active]="active == 1" (click)="active = 1">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="43.016" height="43.016" viewBox="0 0 43.016 43.016">
                  <g id="giving" transform="translate(-3.25 -3.25)">
                    <path id="Trazado_994" data-name="Trazado 994" d="M11.649,12.75H4.75V30.688h6.9Z" transform="translate(0 14.078)" fill="none" stroke="#1017af" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                    <path id="Trazado_995" data-name="Trazado 995" d="M7.5,17.959,14.432,13.5A11.038,11.038,0,0,1,20.4,11.75h2.967v6.209l-6.9,3.45" transform="translate(4.839 12.318)" fill="none" stroke="#1017af" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                    <path id="Trazado_996" data-name="Trazado 996" d="M7,29.558c17.938,0,33.806-11.729,33.806-11.729V13H24.938" transform="translate(3.959 14.518)" fill="none" stroke="#1017af" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                    <path id="Trazado_997" data-name="Trazado 997" d="M14.861,5.962a3.575,3.575,0,0,1,5.367,0l1.111,1.212L22.45,5.962a3.575,3.575,0,0,1,5.367,0,4.411,4.411,0,0,1,0,5.854l-3.795,4.14a3.575,3.575,0,0,1-5.367,0l-3.795-4.14A4.411,4.411,0,0,1,14.861,5.962Z" transform="translate(15.837 0)" fill="none" stroke="#1017af" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" fill-rule="evenodd"/>
                  </g>
                </svg>                
              </div>
              <p>BASIC</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                fugiat nulla pariatur.</p>
            </div>
            <div class="card" [class.active]="active == 2" (click)="active = 2">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="35.757" height="48.236" viewBox="0 0 35.757 48.236">
                  <g id="award" transform="translate(-5.25 -3.25)">
                    <path id="Trazado_998" data-name="Trazado 998" d="M39.507,21.128A16.378,16.378,0,1,1,23.128,4.75,16.378,16.378,0,0,1,39.507,21.128Z" transform="translate(0)" fill="none" stroke="#1017af" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                    <path id="Trazado_999" data-name="Trazado 999" d="M10.87,14.75,7.75,28.789l13.259-4.68,13.259,4.68L31.148,14.75" transform="translate(2.12 21.197)" fill="none" stroke="#1017af" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                  </g>
                </svg>                           
              </div>
              <p>MEDIUM</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                fugiat nulla pariatur.</p>
            </div>
            <div class="card" [class.active]="active == 3" (click)="active = 3">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="48.236" height="48.236" viewBox="0 0 48.236 48.236">
                  <g id="trophy" transform="translate(-3.25 -3.25)">
                    <path id="Trazado_1000" data-name="Trazado 1000" d="M7.75,4.75H34.267v19.5a13.259,13.259,0,1,1-26.517,0Z" transform="translate(6.359)" fill="none" stroke="#1017af" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                    <path id="Trazado_1001" data-name="Trazado 1001" d="M16.5,6.75h.323a8.256,8.256,0,0,1,2.821,16.015L16.5,23.908" transform="translate(24.906 4.239)" fill="none" stroke="#1017af" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                    <path id="Trazado_1002" data-name="Trazado 1002" d="M13.329,6.75h-.323a8.256,8.256,0,0,0-2.821,16.015l3.145,1.143" transform="translate(0 4.239)" fill="none" stroke="#1017af" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                    <path id="Trazado_1003" data-name="Trazado 1003" d="M12,15.5V26.419" transform="translate(15.368 22.787)" fill="none" stroke="#1017af" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                    <path id="Trazado_1004" data-name="Trazado 1004" d="M8.75,19.25H29.028" transform="translate(8.479 30.736)" fill="none" stroke="#1017af" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                  </g>
                </svg>                                    
              </div>
              <p>PREMIUM</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                fugiat nulla pariatur.</p>
            </div>
          </section>
        </main>
      </div>
    </div>
  </div>
</div>