import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'app-changePasswordPopup',
  templateUrl: './changePasswordPopup.component.html',
  styleUrls: ['./changePasswordPopup.component.scss']
})
export class ChangePasswordPopupComponent implements OnInit {

  newPass: string = "";
  repeatNewPass: string = "";
  newPassError: boolean = false;
  repeatNewPassError: boolean = false;
  oldPass: String = ""
  oldPassError: boolean = false;
  visibleOld:boolean = false;
  visibleRepeat:boolean = false;
  visible: boolean = false;
  constructor(public dialogRef: MatDialogRef<ChangePasswordPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private notificaciones: NotificacionesService) { }

  ngOnInit() {
  }
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
  validateData(){
    if (this.newPass == "") {
      this.newPassError = true;
    }else{
      this.newPassError = false;
    }
    if (this.oldPass == "") {
      this.oldPassError = true;
    }else{
      this.oldPassError = false;
    }

    if (this.repeatNewPass == "") {
      this.repeatNewPassError = true;
    }else{
      this.repeatNewPassError = false;
    }
    if (!this.repeatNewPassError && !this.newPassError && !this.oldPassError) {
      if (this.newPass != this.repeatNewPass) {
        this.newPassError = true;
        this.repeatNewPassError = true;
        this.notificaciones.showError("Las cotraseñas introducidas no coinciden")
      }else{
        var obj = {
          oldPass: this.oldPass,
          newPass: this.newPass,
        }
        this.closePopup(obj);
      }
    }else{  
      this.notificaciones.showError("No pueden haber campos vacíos")
    }
  }
}
