import { Component, OnInit, ViewChild } from '@angular/core';
import { Route, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { BaseService } from 'src/app/services/Api/Base.service';
import { CompanyService } from 'src/app/services/Api/company.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { FormBusinessBasicInformationComponent } from './holders/form-business-basic-information/form-business-basic-information.component';
import { FormBusinessCommunityComponent } from './holders/form-business-community/form-business-community.component';
import { FormBusinessEnclosuresComponent } from './holders/form-business-enclosures/form-business-enclosures.component';

@Component({
  selector: 'app-admin-form-business',
  templateUrl: './admin-form-business.component.html',
  styleUrls: ['./admin-form-business.component.scss']
})
export class AdminFormBusinessComponent extends UnsubscribesDestroy implements OnInit {

  @ViewChild(FormBusinessBasicInformationComponent) step1!: FormBusinessBasicInformationComponent;
  @ViewChild(FormBusinessEnclosuresComponent) step2!: FormBusinessEnclosuresComponent;
  @ViewChild(FormBusinessCommunityComponent) step3!: FormBusinessCommunityComponent;
  validPaso1: Boolean = true;
  validPaso3: Boolean = true;

  pasos = [
    'Información básica',
    'Recintos',
    'Comunidad'
  ];
  activoNav = 0;
  dataPaso1: any = null
  dataPaso2: any = null
  dataPaso3: any = null
  constructor(private loginSE: LoginService, private notificaciones: NotificacionesService, private companySE: CompanyService, private router: Router, private loader: LoaderService) { super() }

  ngOnInit() {
    this.checkPermisos()
  }

  nextPaso() {
    if (this.activoNav == 0) {
      this.step1.validarPaso1();
      if (this.validPaso1) {
        this.activoNav = this.activoNav + 1;
      }
    }
    if (this.activoNav == 1) {
      this.step2.validarPaso2();
      this.activoNav = this.activoNav + 1;
    }
    if (this.activoNav == 2) {
      this.step3.validarPaso3();
      if (this.validPaso3) {
        this.finish();
      }
    }
  }
  backPaso() {
    this.activoNav = this.activoNav - 1;
  }
  finish() {
    var company = {
      name: this.dataPaso1.name,
      ageRange: this.dataPaso1.ageRange,
      categories: this.dataPaso1.categories,
      facilities: this.dataPaso2.facilities,
      community: this.dataPaso3
    }
    this.companySE.addNewCompany(company)
    this.loader.open();
    this.companySE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return }
      this.loader.closeSlow();
      this.notificaciones.showFeedBack(value.message)
      setTimeout(() => {
        this.router.navigate(["/admin/bussines"])
      }, 500);
      this._unsubInd.next("")
    })
    this.companySE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.loader.closeSlow();

      this.notificaciones.showError(value.error.message)
      this._unsub.next("")
    })
  }

  paso1Valido(event) {
    this.validPaso1 = event;
  }
  paso3Valido(event) {
    this.validPaso3 = event;
  }
  getDataPaso1(event) {
    this.dataPaso1 = event;
  }
  getDataPaso2(event) {
    this.dataPaso2 = event;
  }
  getDataPaso3(event) {
    this.dataPaso3 = event;
  }
  checkPermisos() {
    if (localStorage.getItem("tipoUser") == null || localStorage.getItem("tipoUser") == undefined || localStorage.getItem("tipoUser") == '["2"]') {
      this.loginSE.logOut();
      this.notificaciones.showError("No tienes permisos para acceder")
    } else {
      if (localStorage.getItem("tipoUser") == '["0"]' || localStorage.getItem("tipoUser") == '["3"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY") && !localStorage.getItem("permisos")?.includes("CONSULT_COMPANY_GROUP")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CREATE_COMPANY")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        }
      }
    }
  }
}
