<div class="section-4">
  <section class="left-group">
    <div class="pill-group">
      <ng-container>
        <p class="noData" *ngIf="event.transports.length == 0">
          No existen transportes para este evento
        </p>
      </ng-container>
      <ng-container *ngIf="event.transports.length != 0">
        <ng-container *ngFor="let transporte of event.transports">
          <div class="pill" (click)="transporteSeleccionado = transporte;seleccionarTransporte()"
            [class.seleccionado]="transporteSeleccionado.id == transporte.id">
            <span class="pill-title">{{transporte.name}}</span>
            <span class="pill-details">{{transporte.fee | currency:'EUR'}}</span>
          </div>
        </ng-container>
      </ng-container>

    </div>
  </section>
  <section class="right-group">
    <p class="noData" *ngIf="transporteSeleccionado.id == -1">
      No se ha seleccionado ningun transporte
    </p>
    <div *ngIf="transporteSeleccionado.id != -1">
      <h3>{{transporteSeleccionado.name}}</h3>
      <div class="wrapper">
        <div class="box box-2">
          <span class="box-title">{{transporteSeleccionado.fee | currency:'EUR'}}</span>
          <span class="box-details">Precio transporte</span>
        </div>
        <div class="box box-4">
          <span class="box-title">{{horaRecogida}}</span>
          <span class="box-details">Recogida</span>
        </div>
        <div class="box box-5">
          <span class="box-title">{{horaLlegada}}</span>
          <span class="box-details">Llegada</span>
        </div>
      </div>
      <div class="infoBillete">
        <div class="box box-3">
          <span class="box-title">Dirección de recogida</span>
          <span class="box-details">{{transporteSeleccionado.address.street}}</span>
        </div>
        <div class="box box-6">
          <span class="box-title">Tipo de vehículo</span>
          <span class="box-details">{{transporteSeleccionado.vehicle_type}}</span>
        </div>
        <div class="box box-7">
          <span class="box-title">Número de vehículos</span>
          <span class="box-details">{{transporteSeleccionado.vehicles_quantity}} vehículos</span>
        </div>
        <div class="box box-8">
          <span class="box-title">Número de plazas</span>
          <span class="box-details">{{transporteSeleccionado.capacity}} plazas</span>
        </div>
        <div class="box box-9">
          <span class="box-title">Empresa de transporte</span>
          <span class="box-details">{{transporteSeleccionado.company}}</span>
        </div>
      </div>
    </div>
  </section>
</div>