import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'DatosDePagoGestionPagos-Popup',
  templateUrl: './DatosDePagoGestionPagos-Popup.component.html',
  styleUrls: ['./DatosDePagoGestionPagos-Popup.component.scss']
})
export class DatosDePagoGestionPagosPopupComponent implements OnInit {

  dataFromView: any = null;

  constructor(public dialogRef: MatDialogRef<DatosDePagoGestionPagosPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any,) { }

  ngOnInit() {
    this.dataFromView = this.modalData.data;
  }

  /**
   * FUNCTIONALITY
   */

  forceClosePopup() {
    this.dialogRef.close();
  }


}
