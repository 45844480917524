<div class="stepGeneralContainer">
  <div>
    <searcher [size]="'fullWidth'"></searcher>
    <div>
      <div class="stepLeft">
        <mat-spinner class="big center" *ngIf="!loadedData"></mat-spinner>
        <ng-container *ngIf="loadedData">
          <card (click)="recintoSeleccionado = recinto; cambiarFacilityEvento()" [type]="'facilityCard'"
            [data]="recinto" *ngFor="let recinto of recintos"></card>
        </ng-container>
      </div>
      <div class="stepRight">
        <p class="noData" *ngIf="recintoSeleccionado ==null">No hay ningún recinto seleccionado</p>
        <div class="informacionRecinto" *ngIf="recintoSeleccionado != null">
          <div class="informacionRecintoDesplegable" [class.notOpened]="!informacionRecintoDesplegado"
            (click)="$event.stopPropagation(); desplegarInformacionRecinto()">
            <span>INFORMACIÓN DEL RECINTO</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="8" viewBox="0 0 32 8">
              <path id="Polígono_146" data-name="Polígono 146"
                d="M15.106.447a2,2,0,0,1,1.789,0l7.528,3.764A2,2,0,0,1,23.528,8H8.472a2,2,0,0,1-.894-3.789Z"
                fill="#070c5b" />
            </svg>
          </div>
          <div class="informacionRecintoDesplegado" *ngIf="informacionRecintoDesplegado">
            <div>
              <div>
                <span>
                  Nombre:
                </span>
                <span>
                  <mat-spinner *ngIf="!loadedData" class="small"></mat-spinner>
                  <ng-container *ngIf="loadedData">
                    {{recintoSeleccionado.name}}
                  </ng-container>
                </span>
              </div>
            </div>
            <div>
              <div>
                <span>
                  Calle:
                </span>
                <span>
                  <mat-spinner *ngIf="!loadedData" class="small"></mat-spinner>
                  <ng-container *ngIf="loadedData">
                    {{recintoSeleccionado.address.street}}
                  </ng-container>
                </span>
              </div>
            </div>
            <div>
              <div>
                <span>
                  Localidad:
                </span>
                <span>
                  <mat-spinner *ngIf="!loadedData" class="small"></mat-spinner>
                  <ng-container *ngIf="loadedData">
                    {{recintoSeleccionado.address.locality}}
                  </ng-container>
                </span>
              </div>
            </div>
            <div>
              <div>
                <span>
                  Aforo:
                </span>
                <span>
                  <mat-spinner *ngIf="!loadedData" class="small"></mat-spinner>
                  <ng-container *ngIf="loadedData">
                    {{recintoSeleccionado.capacity}} personas
                  </ng-container>
                </span>
              </div>
            </div>
            <div>
              <div>
                <span>
                  Añadido:
                </span>
                <span *ngIf="recintoSeleccionado!.creation_date! != null">
                  <mat-spinner *ngIf="!loadedData" class="small"></mat-spinner>
                  <ng-container *ngIf="loadedData">
                    {{recintoSeleccionado!.creation_date!.split("T")[0]}}
                  </ng-container>
                </span>
                <span *ngIf="recintoSeleccionado!.creation_date! == null">
                  <mat-spinner *ngIf="!loadedData" class="small"></mat-spinner>
                  <ng-container *ngIf="loadedData">
                  -
                  </ng-container>
                </span>
              </div>
            </div>
          </div>
        </div>
        <button class="añadirNuevoRecinto" (click)="addNuevoRecinto()">
          <img src="../../../../../../../../../assets/icons/plus.svg" alt="">
          <span>Añadir un nuevo recinto</span>
        </button>
      </div>
    </div>
  </div>

</div>