import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EditorModule } from '@tinymce/tinymce-angular';
import { CarouselModule } from 'ngx-owl-carousel-o';
// import 'localeEs' and 'registerLocaleData' to correctly display dates and currency when using string interpolation
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';

import { NgChartsModule } from 'ng2-charts';

import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { MatDialogModule } from '@angular/material/dialog';
import { SidebarComponent } from './components/NOT_REFACTORING_COMPONENTS/sidebar/sidebar.component';
import { LoginPageComponent } from './pages/shared/login-page/login-page.component';
import { FacilitiesPageComponent } from './pages/publisher/Facilities/facilities-page/facilities-page.component';
import { HomePageComponent } from './pages/publisher/home-page/home-page.component';
import { NotFoundPageComponent } from './pages/shared/not-found-page/not-found-page.component';
import { EventsComponent } from './components/events/events.component';
import { EventItemComponent } from './components/event-item/event-item.component';
import { SettingsPageComponent } from './pages/publisher/settings-page/settings-page.component';
import { FacilityDetailsPageComponent } from './pages/publisher/Facilities/facility-details-page/facility-details-page.component';
import { FacilityItemComponent } from './components/facility-item/facility-item.component';
import { AddFacilityPageComponent } from './pages/publisher/Facilities/add-facility-page/add-facility-page.component';
import { SubscriptionsPageComponent } from './pages/publisher/subscriptions-page/subscriptions-page.component';
import { PromotionsPageComponent } from './pages/publisher/promotions-page/promotions-page.component';
import { InvoicesPageComponent } from './pages/publisher/invoices-page/invoices-page.component';
import { AddCampaignPageComponent } from './pages/publisher/CommunititesPromotions/ManagementCommunitiesPromotions/Holders/Promotions/add-campaign-page/add-campaign-page.component';
import { EventSalesWeeklyChartComponent } from './components/charts/event-sales-weekly-chart/event-sales-weekly-chart.component';
import { DemographyAgeChartComponent } from './components/charts/demography-age-chart/demography-age-chart.component';
import { DemographyGenreChartComponent } from './components/charts/demography-genre-chart/demography-genre-chart.component';
import { DemographyInterestsChartComponent } from './components/charts/demography-interests-chart/demography-interests-chart.component';
import { EventsTicketStoreComponent } from './components/events-ticket-store/events-ticket-store.component';
import { EventItemTicketStoreComponent } from './components/event-item-ticket-store/event-item-ticket-store.component';
import { EventSalesGlobalWeeklyChartComponent } from './components/charts/event-sales-global-weekly-chart/event-sales-global-weekly-chart.component';
import { EventSalesBiweeklyChartComponent } from './components/charts/event-sales-biweekly-chart/event-sales-biweekly-chart.component';
import { EventSalesMonthlyChartComponent } from './components/charts/event-sales-monthly-chart/event-sales-monthly-chart.component';
import { EventSalesGlobalBiweeklyChartComponent } from './components/charts/event-sales-global-biweekly-chart/event-sales-global-biweekly-chart.component';
import { EventSalesGlobalMonthlyChartComponent } from './components/charts/event-sales-global-monthly-chart/event-sales-global-monthly-chart.component';
import { PrRevenueWeeklyChartComponent } from './components/charts/pr-revenue-weekly-chart/pr-revenue-weekly-chart.component';
import { PrRevenueBiweeklyChartComponent } from './components/charts/pr-revenue-biweekly-chart/pr-revenue-biweekly-chart.component';
import { PrRevenueMonthlyChartComponent } from './components/charts/pr-revenue-monthly-chart/pr-revenue-monthly-chart.component';
import { EventsCalendarComponent } from './components/calendars/events-calendar/events-calendar.component';
import { SelectDateComponent } from './components/calendars/select-date/select-date.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { FooterComponent } from './components/footer/footer.component';
import { StepsComponent } from './components/NOT_REFACTORING_COMPONENTS/steps/steps.component';
import { LoadingComponent } from './popups/loading/loading.component';
import { SupportChatComponent } from './pages/publisher/supportChat/supportChat.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ConfirmPopupComponent } from './popups/confirmPopup/confirmPopup.component';
import { FileInputDirective } from './directives/fileInput.directive';
import { AgmCoreModule } from '@agm/core';
import { ChangePasswordComponent } from './pages/publisher/changePassword/changePassword.component';
import { NotificacionesService } from './services/utils/notificaciones.service';
import { PrLinkComponent } from './pages/publisher/pr-link/pr-link.component';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { AddNewRRPPopupComponent } from './popups/addNewRRPPopup/addNewRRPPopup.component';
import { ShowInfoPopupComponent } from './popups/showInfoPopup/showInfoPopup.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MisEventosComponent } from './pages/RRPP-Movil/mis-eventos/mis-eventos.component';
import { HomeComponent } from './pages/RRPP-Movil/home/home.component';
import { MisComunidadesComponent } from './pages/RRPP-Movil/mis-comunidades/mis-comunidades.component';
import { HamburguerMenuComponent } from './components/NOT_REFACTORING_COMPONENTS/hamburguerMenu/hamburguerMenu.component';
import { BotonFlotanteComponent } from './components/NOT_REFACTORING_COMPONENTS/botonFlotante/botonFlotante.component';
import { BackButtonTitleComponent } from './components/NOT_REFACTORING_COMPONENTS/backButtonTitle/backButtonTitle.component';
import { MiPerfilComponent } from './pages/RRPP-Movil/mi-perfil/mi-perfil.component';
import { NuevaListaComponent } from './pages/RRPP-Movil/nueva-lista/nueva-lista.component';
import { VerEventoComponent } from './pages/RRPP-Movil/ver-evento/ver-evento.component';
// import { MatFormField, MatLabel } from '@angular/material/form-field';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
import { PreviewDocumentsPopupComponent } from './popups/previewDocumentsPopup/previewDocumentsPopup.component';
import { SafePipe } from './pipes/SafePipe.pipe';
import { MatIconModule } from '@angular/material/icon';
import { ChangePasswordPopupComponent } from './popups/changePasswordPopup/changePasswordPopup.component';
import { MatRadioModule } from '@angular/material/radio';
import { NgxPanZoomModule } from 'ngx-panzoom';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AddNewAdminPopupComponent } from './popups/addNewAdminPopup/addNewAdminPopup.component';
import { RecoverPasswordComponent } from './pages/shared/recoverPassword/recoverPassword.component';
import { TotalEventSalesChartComponent } from './components/charts/total-event-sales-chart/total-event-sales-chart.component';
import { TotalEventAsistChartComponent } from './components/charts/total-event-asist-chart/total-event-asist-chart.component';
import { ValidationTicketPopupComponent } from './popups/validationTicketPopup/validationTicketPopup.component';
import { EventUnitsSalesGlobalComponent } from './components/charts/event-units-sales-global/event-units-sales-global.component';
import { RegisterCompanyFormComponent } from './pages/shared/register-company-form/register-company-form.component';
import { IntlInputPhoneModule } from 'intl-input-phone';
import { RedirectToStoreComponent } from './pages/shared/redirect-to-store/redirect-to-store.component';
import { HeaderComponent } from './components/NOT_REFACTORING_COMPONENTS/header/header.component';
import { SearcherComponent } from './components/NOT_REFACTORING_COMPONENTS/searcher/searcher.component';
import { DateStepsComponent } from './components/NOT_REFACTORING_COMPONENTS/Date-Steps/Date-Steps.component';
import { InfoComponent } from './components/NOT_REFACTORING_COMPONENTS/Info/Info.component';
import { CardsComponent } from './components/NOT_REFACTORING_COMPONENTS/Cards/Cards.component';
import { ToggleMenuComponent } from './components/NOT_REFACTORING_COMPONENTS/toggleMenu/toggleMenu.component';
import { SalesChartComponent } from './components/NOT_REFACTORING_COMPONENTS/Charts/SalesChart/SalesChart.component';
import { AdminWelcomeComponent } from './pages/admin/admin-welcome/admin-welcome.component';
import { AdminFormBusinessComponent } from './pages/admin/admin-form-business/admin-form-business.component';
import { FormBusinessBasicInformationComponent } from './pages/admin/admin-form-business/holders/form-business-basic-information/form-business-basic-information.component';
import { FormBusinessEnclosuresComponent } from './pages/admin/admin-form-business/holders/form-business-enclosures/form-business-enclosures.component';
import { FormBusinessCommunityComponent } from './pages/admin/admin-form-business/holders/form-business-community/form-business-community.component';
import { StepsNewsComponent } from './components/NOT_REFACTORING_COMPONENTS/steps-news/steps.component';
import { PageTemplateComponent } from './components/NOT_REFACTORING_COMPONENTS/PageTemplate/PageTemplate.component';
import { ManagementEventsComponent } from './pages/publisher/Events/ManagementEvents/ManagementEvents.component';
import { GeneralButtonComponent } from './components/NOT_REFACTORING_COMPONENTS/GeneralButton/GeneralButton.component';
import { ManagementInfoCardComponent } from './components/NOT_REFACTORING_COMPONENTS/ManagementInfoCard/ManagementInfoCard.component';
import { MyEventsComponent } from './pages/publisher/Events/ManagementEvents/Holders/MyEvents/MyEvents.component';
import { CreateEditEventsComponent } from './pages/publisher/Events/ManagementEvents/Holders/CreateEditEvents/CreateEditEvents.component';
import { CreateEditEventStep1Component } from './pages/publisher/Events/ManagementEvents/Holders/CreateEditEvents/Holders/CreateEditEvent-Step1/CreateEditEvent-Step1.component';
import { CreateEditEventStep2Component } from './pages/publisher/Events/ManagementEvents/Holders/CreateEditEvents/Holders/CreateEditEvent-Step2/CreateEditEvent-Step2.component';
import { CreateEditEventStep3Component } from './pages/publisher/Events/ManagementEvents/Holders/CreateEditEvents/Holders/CreateEditEvent-Step3/CreateEditEvent-Step3.component';
import { CreateEditEventStep4Component } from './pages/publisher/Events/ManagementEvents/Holders/CreateEditEvents/Holders/CreateEditEvent-Step4/CreateEditEvent-Step4.component';
import { CreateEditEventStep5Component } from './pages/publisher/Events/ManagementEvents/Holders/CreateEditEvents/Holders/CreateEditEvent-Step5/CreateEditEvent-Step5.component';
import { CreateEditEventStep6Component } from './pages/publisher/Events/ManagementEvents/Holders/CreateEditEvents/Holders/CreateEditEvent-Step6/CreateEditEvent-Step6.component';
import { FormElementComponent } from './components/NOT_REFACTORING_COMPONENTS/FormElement/FormElement.component';
import { SwitchOptionsComponent } from './components/NOT_REFACTORING_COMPONENTS/SwitchOptions/SwitchOptions.component';
import { RangeCalendarComponent } from './components/NOT_REFACTORING_COMPONENTS/RangeCalendar/RangeCalendar.component';
import { NewTicketPopupComponent } from './popups/NewTicketPopup/NewTicketPopup.component';
import { NewFacilityPopupComponent } from './popups/NewFacilityPopup/NewFacilityPopup.component';
import { EventDetailsComponent } from './pages/publisher/Events/ManagementEvents/Holders/EventDetails/EventDetails.component';
import { EventDetailsStep1Component } from './pages/publisher/Events/ManagementEvents/Holders/EventDetails/Holders/EventDetails-Step1/EventDetails-Step1.component';
import { DemographicChartComponent } from './components/NOT_REFACTORING_COMPONENTS/Charts/demographicChart/demographicChart.component';
import { EventDetailsStep2Component } from './pages/publisher/Events/ManagementEvents/Holders/EventDetails/Holders/EventDetails-Step2/EventDetails-Step2.component';
import { EventDetailsStep3Component } from './pages/publisher/Events/ManagementEvents/Holders/EventDetails/Holders/EventDetails-Step3/EventDetails-Step3.component';
import { EventDetailsStep4Component } from './pages/publisher/Events/ManagementEvents/Holders/EventDetails/Holders/EventDetails-Step4/EventDetails-Step4.component';
import { ValidateBuyTicketsSelectEventComponent } from './pages/publisher/Events/ManagementEvents/Holders/ValidateBuyTickets/ValidateBuyTickets-SelectEvent.component';
import { ValidateBuyTicketsComponent } from './pages/publisher/Events/ManagementEvents/Holders/ValidateBuyTickets/Holders/ValidateBuyTickets/ValidateBuyTickets.component';
import { BuyTicketsComponent } from './pages/publisher/Events/ManagementEvents/Holders/ValidateBuyTickets/Holders/ValidateBuyTickets/BuyTickets/BuyTickets.component';
import { ValidateTicketsComponent } from './pages/publisher/Events/ManagementEvents/Holders/ValidateBuyTickets/Holders/ValidateBuyTickets/ValidateTickets/ValidateTickets.component';
import { HistoricEventComponent } from './pages/publisher/Events/ManagementEvents/Holders/HistoricEvent/HistoricEvent.component';
import { ManagementCommunitiesPromotionsComponent } from './pages/publisher/CommunititesPromotions/ManagementCommunitiesPromotions/ManagementCommunitiesPromotions.component';
import { MyCommunitiesComponent } from './pages/publisher/CommunititesPromotions/ManagementCommunitiesPromotions/Holders/Communities/MyCommunities/MyCommunities.component';
import { CommunityDetailsComponent } from './pages/publisher/CommunititesPromotions/ManagementCommunitiesPromotions/Holders/Communities/MyCommunities/Holders/CommunityDetails/CommunityDetails.component';
import { CommunityDetailsCommunityInformationComponent } from './pages/publisher/CommunititesPromotions/ManagementCommunitiesPromotions/Holders/Communities/MyCommunities/Holders/CommunityDetails/Holders/CommunityDetails-CommunityInformation/CommunityDetails-CommunityInformation.component';
import { CommunityDetailsChatComponent } from './pages/publisher/CommunititesPromotions/ManagementCommunitiesPromotions/Holders/Communities/MyCommunities/Holders/CommunityDetails/Holders/CommunityDetails-Chat/CommunityDetails-Chat.component';
import { UnitsChartComponent } from './components/NOT_REFACTORING_COMPONENTS/Charts/UnitsChart/UnitsChart.component';
import { CommunityDetailsAuthorizationComponent } from './pages/publisher/CommunititesPromotions/ManagementCommunitiesPromotions/Holders/Communities/MyCommunities/Holders/CommunityDetails/Holders/CommunityDetails-Authorization/CommunityDetails-Authorization.component';
import { CreateEditCommunityComponent } from './pages/publisher/CommunititesPromotions/ManagementCommunitiesPromotions/Holders/Communities/MyCommunities/Holders/CreateEditCommunity/CreateEditCommunity.component';
import { CreateEditCommunityStep1Component } from './pages/publisher/CommunititesPromotions/ManagementCommunitiesPromotions/Holders/Communities/MyCommunities/Holders/CreateEditCommunity/Holders/CreateEditCommunity-Step1/CreateEditCommunity-Step1.component';
import { CreateEditCommunityStep2Component } from './pages/publisher/CommunititesPromotions/ManagementCommunitiesPromotions/Holders/Communities/MyCommunities/Holders/CreateEditCommunity/Holders/CreateEditCommunity-Step2/CreateEditCommunity-Step2.component';
import { CustomDatesPopupComponent } from './popups/CustomDatesPopup/CustomDatesPopup.component';
import { CustomerDatabaseComponent } from './pages/publisher/CommunititesPromotions/ManagementCommunitiesPromotions/Holders/Marketing/CustomerDatabase/CustomerDatabase.component';
import { PublicRelationComponent } from './pages/publisher/PublicRelation/PublicRelation.component';
import { PublicRelationDetailsComponent } from './pages/publisher/PublicRelation/Holders/PublicRelation-Details/PublicRelation-Details.component';
import { TeamPrPopupComponent } from './popups/TeamPrPopup/TeamPrPopup.component';
import { MyCompaniesComponent } from './pages/admin/MyCompanies/MyCompanies.component';
import { AdminHomeComponent } from './pages/admin/Admin-GeneralAdministration/Home/Home.component';
import { BussinesComponent } from './pages/admin/Admin-GeneralAdministration/Bussines/Bussines.component';
import { PromotionalPageComponent } from './pages/admin/Admin-GeneralAdministration/PromotionalPage/PromotionalPage.component';
import { EditPromotionalPageComponent } from './pages/admin/Admin-GeneralAdministration/EditPromotionalPage/EditPromotionalPage.component';
import { CommunitiesComponent } from './pages/admin/Admin-GeneralAdministration/Communities/Communities.component';
import { MyUserComponent } from './pages/admin/Admin-GeneralAdministration/MyUser/MyUser.component';
import { UpdateGroupDataComponent } from './pages/admin/Admin-GeneralAdministration/UpdateGroupData/UpdateGroupData.component';
import { AdminCustomerDatabaseComponent } from './pages/admin/Admin-GeneralAdministration/AdminCustomerDatabase/AdminCustomerDatabase.component';
import { UsersPermissionsComponent } from './pages/admin/Admin-GeneralAdministration/UsersPermissions/UsersPermissions.component';
import { InvoicesComponent } from './pages/admin/Admin-GeneralAdministration/Invoices/Invoices.component';
import { EnlaceInvitacionComponent } from './pages/RRPP-Movil/enlace-invitacion/enlace-invitacion.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ExternalValidationComponent } from './pages/shared/ExternalValidation/ExternalValidation.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MailingComponent } from './pages/publisher/CommunititesPromotions/ManagementCommunitiesPromotions/Holders/Promotions/add-campaign-page/Mailing/Mailing.component';
import { PushComponent } from './pages/publisher/CommunititesPromotions/ManagementCommunitiesPromotions/Holders/Promotions/add-campaign-page/Push/Push.component';
import { PrEventsInfoPopupComponent } from './popups/prEventsInfoPopup/prEventsInfoPopup.component';
import { AudienciesComponent } from './pages/publisher/CommunititesPromotions/ManagementCommunitiesPromotions/Holders/Promotions/Audiencies/Audiencies.component';
import { DuracionComponent } from './pages/publisher/CommunititesPromotions/ManagementCommunitiesPromotions/Holders/Promotions/add-campaign-page/Mailing/Holders/Duracion/Duracion.component';
import { AudienciaComponent } from './pages/publisher/CommunititesPromotions/ManagementCommunitiesPromotions/Holders/Promotions/add-campaign-page/Mailing/Holders/Audiencia/Audiencia.component';
import { EdicionMailComponent } from './pages/publisher/CommunititesPromotions/ManagementCommunitiesPromotions/Holders/Promotions/add-campaign-page/Mailing/Holders/EdicionMail/EdicionMail.component';
import { LiberarEntradaPopupComponent } from './popups/LiberarEntradaPopup/LiberarEntradaPopup.component';
import { CambiarPrecioEntradaPopupComponent } from './popups/CambiarPrecioEntradaPopup/CambiarPrecioEntradaPopup.component';
import { HomeSAComponent } from './pages/SuperAdmin/HomeSA/HomeSA.component';
import { SocialComponent } from './pages/SuperAdmin/Social/Social.component';
import { ReportesDetailsComponent } from './pages/SuperAdmin/Social/ReportesDetails/ReportesDetails.component';
import { MiEquipoComponent } from './pages/RRPP-Movil/mi-equipo/mi-equipo.component';
import { AvisoIOSPopupComponent } from './popups/AvisoIOSPopup/AvisoIOSPopup.component';
import { GestionPagosComponent } from './pages/SuperAdmin/GestionPagos/GestionPagos.component';
import { GestionEntradasComponent } from './pages/SuperAdmin/GestionEntradas/GestionEntradas.component';
import { GestionCRMComponent } from './pages/SuperAdmin/GestionCRM/GestionCRM.component';
import { EditarUsuarioPopupComponent } from './popups/EditarUsuarioPopup/EditarUsuarioPopup.component';
import { CambiarTitularidadPopupComponent } from './popups/cambiarTitularidadPopup/cambiarTitularidadPopup.component';
import { ValidarEmailComponent } from './pages/shared/ValidarEmail/ValidarEmail.component';
import { EdicionMensajeComponent } from './pages/publisher/CommunititesPromotions/ManagementCommunitiesPromotions/Holders/Promotions/add-campaign-page/Push/Holders/EdicionMensaje/EdicionMensaje.component';
import { InvitePopupComponent } from './popups/Invite-Popup/Invite-Popup.component';
import { DatosDePagoGestionPagosPopupComponent } from './popups/DatosDePagoGestionPagos-Popup/DatosDePagoGestionPagos-Popup.component';
import { ReenviarMailPopupComponent } from './popups/ReenviarMail-Popup/ReenviarMail-Popup.component';
import { SearchUserPopupComponent } from './popups/SearchUser-Popup/SearchUser-Popup.component';

registerLocaleData(localeEs, 'es-Es');

FullCalendarModule.registerPlugins([dayGridPlugin, interactionPlugin]);

@NgModule({
  declarations: [
    AppComponent,

    SidebarComponent,
    LoginPageComponent,
    FacilitiesPageComponent,
    HomePageComponent,
    NotFoundPageComponent,
    EventsComponent,
    EventItemComponent,
    SettingsPageComponent,
    FacilityDetailsPageComponent,
    FacilityItemComponent,
    AddFacilityPageComponent,
    SubscriptionsPageComponent,
    PromotionsPageComponent,
    InvoicesPageComponent,
    AddCampaignPageComponent,
    EventSalesWeeklyChartComponent,
    DemographyAgeChartComponent,
    DemographyGenreChartComponent,
    DemographyInterestsChartComponent,
    EventsTicketStoreComponent,
    EventItemTicketStoreComponent,
    EventSalesGlobalWeeklyChartComponent,
    EventSalesBiweeklyChartComponent,
    EventSalesMonthlyChartComponent,
    EventSalesGlobalBiweeklyChartComponent,
    EventSalesGlobalMonthlyChartComponent,
    PrRevenueWeeklyChartComponent,
    PrRevenueBiweeklyChartComponent,
    PrRevenueMonthlyChartComponent,
    EventsCalendarComponent,
    SelectDateComponent,
    MenuItemComponent,
    FooterComponent,
    StepsComponent,
    StepsNewsComponent,
    LoadingComponent,
    SupportChatComponent,
    ConfirmPopupComponent,
    FileInputDirective,
    ChangePasswordComponent,
    PrLinkComponent,
    AddNewRRPPopupComponent,
    ShowInfoPopupComponent,
    MisEventosComponent,
    HomeComponent,
    MisComunidadesComponent,
    HamburguerMenuComponent,
    BotonFlotanteComponent,
    BackButtonTitleComponent,
    MiPerfilComponent,
    NuevaListaComponent,
    VerEventoComponent,
    PreviewDocumentsPopupComponent,
    SafePipe,
    ChangePasswordPopupComponent,
    AddNewAdminPopupComponent,
    RecoverPasswordComponent,
    TotalEventSalesChartComponent,
    TotalEventAsistChartComponent,
    ValidationTicketPopupComponent,
    EventUnitsSalesGlobalComponent,
    RegisterCompanyFormComponent,
    RedirectToStoreComponent,
    HeaderComponent,
    SearcherComponent,
    DateStepsComponent,
    InfoComponent,
    CardsComponent,
    ToggleMenuComponent,
    SalesChartComponent,
    AdminWelcomeComponent,
    AdminFormBusinessComponent,
    FormBusinessBasicInformationComponent,
    FormBusinessEnclosuresComponent,
    FormBusinessCommunityComponent,
    PageTemplateComponent,
    ManagementEventsComponent,
    GeneralButtonComponent,
    ManagementInfoCardComponent,
    MyEventsComponent,
    CreateEditEventsComponent,
    CreateEditEventStep1Component,
    CreateEditEventStep2Component,
    CreateEditEventStep3Component,
    CreateEditEventStep4Component,
    CreateEditEventStep5Component,
    CreateEditEventStep6Component,
    FormElementComponent,
    SwitchOptionsComponent,
    RangeCalendarComponent,
    NewTicketPopupComponent,
    NewFacilityPopupComponent,
    EventDetailsComponent,
    EventDetailsStep1Component,
    EventDetailsStep2Component,
    EventDetailsStep3Component,
    EventDetailsStep4Component,
    DemographicChartComponent,
    ValidateBuyTicketsSelectEventComponent,
    ValidateBuyTicketsComponent,
    BuyTicketsComponent,
    ValidateTicketsComponent,
    HistoricEventComponent,
    ManagementCommunitiesPromotionsComponent,
    MyCommunitiesComponent,
    CommunityDetailsComponent,
    CommunityDetailsCommunityInformationComponent,
    CommunityDetailsChatComponent,
    UnitsChartComponent,
    CommunityDetailsAuthorizationComponent,
    CreateEditCommunityComponent,
    CreateEditCommunityStep1Component,
    CreateEditCommunityStep2Component,
    CustomDatesPopupComponent,
    CustomerDatabaseComponent,
    PublicRelationComponent,
    PublicRelationDetailsComponent,
    TeamPrPopupComponent,
    MyCompaniesComponent,
    AdminHomeComponent,
    BussinesComponent,
    PromotionalPageComponent,
    EditPromotionalPageComponent,
    CommunitiesComponent,
    MyUserComponent,
    UpdateGroupDataComponent,
    AdminCustomerDatabaseComponent,
    UsersPermissionsComponent,
    InvoicesComponent,
    EnlaceInvitacionComponent,
    ExternalValidationComponent,
    MailingComponent,
    PushComponent,
    PrEventsInfoPopupComponent,
    AudienciesComponent,
    DuracionComponent,
    AudienciaComponent,
    EdicionMailComponent,
    LiberarEntradaPopupComponent,
    CambiarPrecioEntradaPopupComponent,
    HomeSAComponent,
    SocialComponent,
    ReportesDetailsComponent,
    MiEquipoComponent,
    AvisoIOSPopupComponent,
    GestionPagosComponent,
    GestionEntradasComponent,
    GestionCRMComponent,
    EditarUsuarioPopupComponent,
    CambiarTitularidadPopupComponent,
    ValidarEmailComponent,
    EdicionMensajeComponent,
    InvitePopupComponent,
    DatosDePagoGestionPagosPopupComponent,
    ReenviarMailPopupComponent,
    SearchUserPopupComponent,
  ],
  imports: [
    FormsModule,
    CarouselModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgChartsModule,
    FullCalendarModule,
    MatDialogModule,
    BrowserAnimationsModule,
    NgSelectModule,
    EditorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatPasswordStrengthModule,
    MatCheckboxModule,
    MatRadioModule,
    NgxPanZoomModule,
    DragDropModule,
    NgxScannerQrcodeModule,
    MatIconModule,
    GooglePlaceModule,
    MatSlideToggleModule,
    IntlInputPhoneModule,
    MatTooltipModule,

    MatProgressSpinnerModule,
    GalleryModule.withConfig({
      // thumbView: 'contain',
    }),
    LightboxModule,
    AgmCoreModule.forRoot({
      apiKey: '',
    }),
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'es-ES' }],
  bootstrap: [AppComponent],
})
export class AppModule {}
