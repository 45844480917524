<div class="info" [class]="style" *ngIf="typeCard == 'normal'">
  <span *ngIf="loaded" class="value">
    <ng-container *ngIf="!currency">{{value}} </ng-container>
    <ng-container *ngIf="currency">{{value|currency:'EUR'}}</ng-container>
  </span>
  <mat-spinner class="medium" *ngIf="!loaded"></mat-spinner>

  <span class="label">{{type}}</span>
</div>
<div class="info admin" *ngIf="typeCard == 'admin'">
  <div>
    <img [src]="icon">
    <span class="label">{{type}}</span>
  </div>

  <span *ngIf="loaded" class="value">
    <ng-container *ngIf="!currency">{{value}} </ng-container>
    <ng-container *ngIf="currency">{{value|currency:'EUR'}}</ng-container>
  </span>
  <mat-spinner class="medium adminSpinner" *ngIf="!loaded"></mat-spinner>

</div>