<headerMovil></headerMovil>
<div class="bodyGeneralContainer">
  <backButtonTitle [titulo]="'Mis eventos'"></backButtonTitle>
  <app-steps class="misEventosMovilPasos" [pasos]="pasos" [activo]="activo" [navegacionActiva]="true"
    (cambio)="cambiado($event)"></app-steps>
  <ng-container *ngIf="activo == 0">
    <div class="eventosGeneralContainer">
      <div class="carrouselContainer">
        <p class="sinDatos" *ngIf="eventosHoy.length == 0">No hay eventos hoy</p>
        <ng-container *ngFor="let evento of eventosHoy">
          <div class="card-vert" (click)="saveEvent(evento)">
            <ng-container *ngFor="let imagen of evento.event_images">
              <img *ngIf="imagen.is_principal" [src]="imagen.image.url" alt="" class="card-img">
            </ng-container>
            <div class="float-time">
              <span>{{evento.start_time.split("T")[1].split("+")[0].split(":")[0]}}:{{evento.start_time.split("T")[1].split("+")[0].split(":")[1]}} - {{evento.finish_time.split("T")[1].split("+")[0].split(":")[0]}}:{{evento.finish_time.split("T")[1].split("+")[0].split(":")[1]}}</span>
            </div>
            <div class="card-info">
              <div class="card-title">
                <h5 [title]="evento.nombre">{{evento.title}}</h5>
              </div>
              <div class="card-details">
                <small>{{evento.facility.name}}</small>
              </div>
            </div>

          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="activo == 1">
    <app-events-calendar class="homeCalendar movilCalendar rrpCalendar" [eventos]="eventosCalendario"
      (fechaSeleccionada)="clickFecha($event)" (cambioDeMes)="cambiarMes($event)">
    </app-events-calendar>    
    <mat-spinner *ngIf="!eventsLoaded" class="big"></mat-spinner>
    <div class="calendar-info">
      <h3>{{ fecha | date: 'longDate' }}</h3>
      <div class="event-list">
        <ul>
          <li class="event-list-item" *ngFor="let evento of eventosSeleccionados" (click)="saveEvent(evento)">
            <a routerLink="."><span>{{ evento.title }}</span></a>
            <div class="horario">
              <span>{{evento.startTime.split("T")[1].split("+")[0].substr(0, evento.startTime.split("T")[1].split("+")[0].length - 3)}}</span>
              <span> - </span>
              <span>{{evento.finishTime.split("T")[1].split("+")[0].substr(0, evento.finishTime.split("T")[1].split("+")[0].length - 3)}}</span>
            </div>
          </li>
          <li class="event-list-item vacio" *ngIf="eventosSeleccionados.length == 0">
            Sin eventos para esta fecha
          </li>
        </ul>
      </div>
    </div>
  </ng-container>
</div>
<botonFlotante [texto]="'Nueva lista'" *ngIf="false"></botonFlotante>
<botonFlotante [texto]="'Copiar código'" [tipo]="4"></botonFlotante>
