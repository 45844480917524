import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { TicketsService } from 'src/app/services/Api/tickets.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PopupService } from 'src/app/services/utils/popup.service';

@Component({
  selector: 'app-CambiarPrecioEntradaPopup',
  templateUrl: './CambiarPrecioEntradaPopup.component.html',
  styleUrls: ['./CambiarPrecioEntradaPopup.component.scss']
})
export class CambiarPrecioEntradaPopupComponent extends UnsubscribesDestroy implements OnInit {

  ticketConIva: number | null = this.modalData.entrada.fee.toFixed(2);
  ticketConIvaError: Boolean = false;

  constructor(public dialogRef: MatDialogRef<CambiarPrecioEntradaPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private notificaciones: NotificacionesService, private popupSE: PopupService, private ticketSE: TicketsService, private notificacionesSE: NotificacionesService) {
    super();
  }

  ngOnInit() {
  }

  //FUNCIONALIDAD
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  getPrecioEntradaIva(event) {
    this.ticketConIva = event;
  }

  updateFee(){
    if (this.ticketConIva == null) {
      this.notificaciones.showError("No puedes dejar el campo vacío")
      this.ticketConIvaError = true;
    }else{
      this.ticketConIvaError = false;
      this.editarEntrada('fee', this.ticketConIva);
    }
  }
  editarEntrada(element: any, value: any) {
    var ticket = {}
    ticket[element] = value;
    this.ticketSE.updateTicket(this.modalData.entrada.idProducto, ticket)
    this.ticketSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return }
      this.notificaciones.showFeedBack(value.message)
      this._unsubInd2.next("");
      this.closePopup();
    })
    this.ticketSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.notificaciones.showError(value.message)
      this._unsub.next("");
      this.closePopup();
    })
  }
}
