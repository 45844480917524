<PageTemplate>
  <ng-container bodyContent>
    <header [searcherActive]="false" [buttonActive]="true" [buttonAction]="'Añadir nuevo R.R.P.P'"
      (especialData)="newRRPP($event)" [permiso]="permiso"></header>
    <div class="prGeneralContainer">
      <div class="prHeader">
        <div>
          <div>
            <img src="/assets/icons/graficaIcono.svg" alt="">
            <span>Total equipo Relaciones Públicas </span>
          </div>
          <mat-spinner class="medium adminSpinner" *ngIf="!dataIsLoaded"></mat-spinner>
          <span *ngIf="dataIsLoaded">{{estadisticas.total_public_relations}} RRPP</span>
        </div>
        <div>
          <div>
            <img src="/assets/icons/graficaIcono.svg" alt="">
            <span>Ventas esta semana</span>
          </div>
          <mat-spinner class="medium adminSpinner" *ngIf="!dataIsLoaded"></mat-spinner>
          <span *ngIf="dataIsLoaded">{{estadisticas.entradas_vendidas | currency:'EUR'}}</span>
        </div>
      </div>      
      <ng-select [items]="eventos" [(ngModel)]="evento" [searchable]="true" [clearable]="false" bindLabel="title"
      bindValue="id" [placeholder]="'Selecciona un evento'" (change)="getEvento()"></ng-select>
      <searcher *ngIf="selectedToggle == 0" [type]="'rp'" [placeHolder]="'Busca aquí tu relaciones públicas'"
        [size]="'fullWidth'"></searcher>
      <div class="filtrosContainer">
        <button type="button" (click)=contraerTodos()>
          <img src="/assets/icons/databaseIcon.svg" alt="">
          <span>Contraer todos los datos</span>
        </button>
        <toggleMenu [data]="['Vista equipos', 'Tabla ventas']" (toggleMenu)="selectedToggle = $event;"></toggleMenu>

        <div [class.noVisible]="selectedToggle == 1">
          <span>Ordenar por:</span>
          <ng-select [items]="['Antigüedad', 'Alfabéticamente']" [(ngModel)]="orderBy" (change)="obtenerRRPP()"
            [searchable]="false" [clearable]="false" [placeholder]="'Ordenar por'"></ng-select>

        </div>

      </div>
      <div class="buscadores" *ngIf="false">
        <div>
          <span>Filtrar por evento</span>
          <searcher [placeHolder]="'Busca aquí tu relaciones públicas'" [size]="'fullWidth'"></searcher>
        </div>
        <div>
          <span>Filtrar por negocio</span>
          <searcher [placeHolder]="'Busca aquí tu relaciones públicas'" [size]="'fullWidth'"></searcher>
        </div>
      </div>
      <div class="prListContainer" *ngIf="selectedToggle == 0">
        <mat-spinner class="big center" *ngIf="!dataIsLoaded"></mat-spinner>
        <ng-container *ngIf="dataIsLoaded">
          <div class="prList" *ngFor="let relacionesPublicasSuperior of relacionesPublicas">
            <div class="prManager">
              <div class="reducir" (click)="abrirGrupo(relacionesPublicasSuperior.id)"
                *ngIf="relacionesPublicasSuperior.subordinates.length != 0  && relacionesAbiertas.includes(relacionesPublicasSuperior.id)">
                -</div>
              <div class="aumentar" (click)="abrirGrupo(relacionesPublicasSuperior.id)"
                *ngIf="relacionesPublicasSuperior.subordinates.length != 0 && !relacionesAbiertas.includes(relacionesPublicasSuperior.id)">
                +</div>
              <div class="prManager_sub" [class.disabled]="relacionesPublicasSuperior.pause">
                <div class="prManager_name">
                  <span>{{relacionesPublicasSuperior.user.name.charAt(0).toUpperCase()}}{{relacionesPublicasSuperior.user.name.charAt(1).toUpperCase()}}</span>
                  <span>{{relacionesPublicasSuperior.user.name}}</span>
                </div>
                <div class="prManager_individuales">
                  <span>Ventas individuales</span>
                  <span>{{relacionesPublicasSuperior.ventas_total_pr | currency:'EUR'}}</span>
                </div>
                <div class="prManager_equipo">
                  <span>Ventas equipo</span>
                  <span>{{relacionesPublicasSuperior.total_equipo | currency:'EUR'}}</span>
                </div>
                <div class="prManager_detalles">
                  <GeneralButton [buttonText]="'Ver detalles'"
                    [routerLink]="['/publisher/management/public-relation/public-relation-details/'+relacionesPublicasSuperior.id]">
                  </GeneralButton>
                </div>

              </div>

            </div>
            <ng-container *ngIf="relacionesAbiertas.includes(relacionesPublicasSuperior.id)">
              <ng-container *ngFor="let subordinado of relacionesPublicasSuperior.subordinates">
                <div class="auxiliarSubordinate">
                  <div class="prSubordinate one">
                    <div class="reducir" (click)="abrirGrupo(subordinado.id)"
                      *ngIf="subordinado.subordinates.length != 0  && relacionesAbiertas.includes(subordinado.id)">-
                    </div>
                    <div class="aumentar" (click)="abrirGrupo(subordinado.id)"
                      *ngIf="subordinado.subordinates.length != 0 && !relacionesAbiertas.includes(subordinado.id)">+
                    </div>
                    <div class="prManager_sub" [class.disabled]="subordinado.pause">
                      <div class="prManager_name">
                        <span>{{subordinado.user.name.charAt(0).toUpperCase()}}{{subordinado.user.name.charAt(1).toUpperCase()}}</span>
                        <span>{{subordinado.user.name}}</span>
                      </div>
                      <div class="prManager_individuales">
                        <span>Ventas individuales</span>
                        <span>{{subordinado.ventas_total_pr | currency:'EUR'}}</span>
                      </div>
                      <div class="prManager_equipo">
                        <span>Ventas equipo</span>
                        <span>{{subordinado.total_equipo | currency:'EUR'}}</span>
                      </div>
                      <div class="prManager_detalles">
                        <GeneralButton [buttonText]="'Ver detalles'"
                          [routerLink]="['/publisher/management/public-relation/public-relation-details/'+subordinado.id]">
                        </GeneralButton>
                      </div>

                    </div>
                  </div>
                </div>
                <ng-container *ngIf="relacionesAbiertas.includes(subordinado.id)">
                  <ng-container *ngFor="let subordinado_two of subordinado.subordinates">
                    <div class="auxiliarSubordinate">
                      <div class="prSubordinate two">
                        <div class="reducir" (click)="abrirGrupo(subordinado_two.id)"
                          *ngIf="subordinado_two.subordinates.length != 0  && relacionesAbiertas.includes(subordinado_two.id)">
                          -</div>
                        <div class="aumentar" (click)="abrirGrupo(subordinado_two.id)"
                          *ngIf="subordinado_two.subordinates.length != 0 && !relacionesAbiertas.includes(subordinado_two.id)">
                          +</div>
                        <div class="prManager_sub " [class.disabled]="subordinado_two.pause">
                          <div class="prManager_name">
                            <span>{{subordinado_two.user.name.charAt(0).toUpperCase()}}{{subordinado_two.user.name.charAt(1).toUpperCase()}}</span>
                            <span>{{subordinado_two.user.name}}</span>
                          </div>
                          <div class="prManager_individuales">
                            <span>Ventas individuales</span>
                            <span>{{subordinado_two.ventas_total_pr | currency:'EUR'}}</span>
                          </div>
                          <div class="prManager_equipo">
                            <span>Ventas equipo</span>
                            <span>{{subordinado_two.total_equipo | currency:'EUR'}}</span>
                          </div>
                          <div class="prManager_detalles">
                            <GeneralButton [buttonText]="'Ver detalles'"
                              [routerLink]="['/publisher/management/public-relation/public-relation-details/'+subordinado_two.id]">
                            </GeneralButton>
                          </div>

                        </div>
                      </div>
                    </div>
                    <ng-container *ngIf="relacionesAbiertas.includes(subordinado_two.id)">
                      <ng-container *ngFor="let subordinado_three of subordinado_two.subordinates">
                        <div class="auxiliarSubordinate">
                          <div class="prSubordinate three">
                            <div class="reducir" (click)="abrirGrupo(subordinado_three.id)"
                              *ngIf="subordinado_three.subordinates.length != 0  && relacionesAbiertas.includes(subordinado_three.id)">
                              -</div>
                            <div class="aumentar" (click)="abrirGrupo(subordinado_three.id)"
                              *ngIf="subordinado_three.subordinates.length != 0 && !relacionesAbiertas.includes(subordinado_three.id)">
                              +</div>
                            <div class="prManager_sub" [class.disabled]="subordinado_three.pause">
                              <div class="prManager_name">
                                <span>{{subordinado_three.user.name.charAt(0).toUpperCase()}}{{subordinado_three.user.name.charAt(1).toUpperCase()}}</span>
                                <span>{{subordinado_three.user.name}}</span>
                              </div>
                              <div class="prManager_individuales">
                                <span>Ventas individuales</span>
                                <span>{{subordinado_three.ventas_total_pr | currency:'EUR'}}</span>
                              </div>
                              <div class="prManager_equipo">
                                <span>Ventas equipo</span>
                                <span>{{subordinado_three.total_equipo | currency:'EUR'}}</span>
                              </div>
                              <div class="prManager_detalles">
                                <GeneralButton [buttonText]="'Ver detalles'"
                                  [routerLink]="['/publisher/management/public-relation/public-relation-details/'+subordinado_three.id]">
                                </GeneralButton>
                              </div>

                            </div>
                          </div>
                        </div>
                        <ng-container *ngIf="relacionesAbiertas.includes(subordinado_three.id)">
                          <ng-container *ngFor="let subordinado_four of subordinado_three.subordinates">
                            <div class="auxiliarSubordinate">
                              <div class="prSubordinate four">
                                <div class="reducir" (click)="abrirGrupo(subordinado_four.id)"
                                  *ngIf="subordinado_four.subordinates.length != 0  && relacionesAbiertas.includes(subordinado_four.id)">
                                  -</div>
                                <div class="aumentar" (click)="abrirGrupo(subordinado_four.id)"
                                  *ngIf="subordinado_four.subordinates.length != 0 && !relacionesAbiertas.includes(subordinado_four.id)">
                                  +</div>
                                <div class="prManager_sub" [class.disabled]="subordinado_four.pause">
                                  <div class="prManager_name">
                                    <span>{{subordinado_four.user.name.charAt(0).toUpperCase()}}{{subordinado_four.user.name.charAt(1).toUpperCase()}}</span>
                                    <span>{{subordinado_four.user.name}}</span>
                                  </div>
                                  <div class="prManager_individuales">
                                    <span>Ventas individuales</span>
                                    <span>{{subordinado_four.ventas_total_pr | currency:'EUR'}}</span>
                                  </div>
                                  <div class="prManager_equipo">
                                    <span>Ventas equipo</span>
                                    <span>{{subordinado_four.total_equipo | currency:'EUR'}}</span>
                                  </div>
                                  <div class="prManager_detalles">
                                    <GeneralButton [buttonText]="'Ver detalles'"
                                      [routerLink]="['/publisher/management/public-relation/public-relation-details/'+subordinado_four.id]">
                                    </GeneralButton>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>

            </ng-container>

          </div>
        </ng-container>
      </div>
      <div class="tablaPRContainer" *ngIf="selectedToggle == 1">
        <div class="filter">
          <div class="searcherButton">
            <searcher [placeHolder]="'Busca aquí un RRPP'" (emitChange)="getFilter($event)"></searcher>
            <button class="btn-sm" [class.noVisible]="selectedToggle == 0" (click)="exportAsExcelFile()">Descargar
              excel</button>
          </div>
        </div>

        <mat-spinner mat-spinner *ngIf="!dataIsLoaded"></mat-spinner>
        <div class="pageBody" *ngIf="dataIsLoaded">
          <p class="noData" *ngIf="rp.length == 0">
            No hay nada que mostrar
          </p>
          <div class="tableContainer" *ngIf="rp.length > 0">
            <table>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th class="center">Entradas vendidas</th>
                  <th class="center">Comisión sin iva</th>
                  <th class="center">Facturación sin GD</th>
                  <th class="center">Facturación sin GD/IVA</th>
                  <th class="right">Facturación total</th>

                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let entrada of rp" (click)="$event.stopPropagation();">
                  <td>{{entrada.name}}</td>
                  <td class="center">{{entrada.entradas_vendidas}}</td>
                  <td class="center">{{entrada.comision_sin_iva | currency: 'EUR'}}</td>
                  <td class="center">{{entrada.facturacion_sin_gd | currency: 'EUR'}}</td>

                  <td class="center">{{entrada.facturacion_sin_gd_sin_iva | currency: 'EUR'}}</td>
                  <td class="right">{{entrada.facturacion_total | currency: 'EUR'}}</td>

                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </ng-container>
</PageTemplate>