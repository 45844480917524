import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { resultHttp } from 'src/app/models/resultHttp';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { ConfirmPopupComponent } from 'src/app/popups/confirmPopup/confirmPopup.component';
import { LoginService } from 'src/app/services/Api/login.service';
import { ReportesService } from 'src/app/services/Api/Reportes.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PopupService } from 'src/app/services/utils/popup.service';

@Component({
  selector: 'app-Social',
  templateUrl: './Social.component.html',
  styleUrls: ['./Social.component.scss']
})
export class SocialComponent extends UnsubscribesDestroy implements OnInit {

  numDev: number = 9;
  numPag: number = 1;
  orderBy: string = "NAME_DESC"
  reportes: any = []
  lastLength: number = 0
  isDataLoaded: boolean = false;
  constructor(private loginSE: LoginService, private notificaciones: NotificacionesService, private popupSE: PopupService, private reportesSE: ReportesService) {
    super();
  }

  ngOnInit() {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      this.checkPermisos();
    }
  }

  /**
   * FUNCTIONALITIEs
   */

  activatePagination() {
    let that = this;

    window.onscroll = function (ev) {
      if (that.reportes.length > 0) {
        if (((window.innerHeight + window.scrollY)) >= document.body.scrollHeight) {
          that.numPag += 1;
          // that.paginationLoaded = false
          if (that.lastLength != 0) {
            that.getAll();
          }
        }
      }
    };
  }


  singleDelete(id: number) {
    this.popupSE.openPopup(ConfirmPopupComponent, { accion: "Eliminar", tipo: "el reporte" })
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd5)).subscribe(value => {
      if (value == null) { return }
      this._unsubInd5.next("");
    })
  }
  multipleDelete() {
    this.popupSE.openPopup(ConfirmPopupComponent, { accion: "Eliminar", tipo: "todos los reportes" })
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd5)).subscribe(value => {
      if (value == null) { return }
      this._unsubInd5.next("");
    })
  }
  changeOrder(type){
    switch (type) {
      case "name":
        this.orderBy == "NAME_DESC" ? this.orderBy = "NAME_ASC" : this.orderBy = "NAME_DESC";
        this.getAll();
        break;
    }
  }
  /**
   * CALL TO API
   */

  getAll() {
    this.reportes = []
    this.isDataLoaded = false;
    this.reportesSE.getAll(this.numDev, this.numPag, this.orderBy);
    this.reportesSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if (res == null) { return }
      this.lastLength = res.length
      if (res.length != 0) {
        res.forEach(element => {
          this.reportes.push(element)
        });
      }
      this.isDataLoaded = true;
      this._unsubInd.next('')
    })
    this.reportesSE.getResultError().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if (res == null) { return }
      this.notificaciones.showError(res.error.message)
      this._unsub.next('')
    })
  }

  /**
   * PERMISSIONS
   */
  checkPermisos() {
    if (localStorage.getItem("tipoUser") == null || localStorage.getItem("tipoUser") == undefined || localStorage.getItem("tipoUser") != '["3"]') {
      this.loginSE.logOut();
      this.notificaciones.showError("No tienes permisos para acceder")
    } else {
      if (localStorage.getItem("tipoUser") == '["3"]') {
        this.getAll();
        this.activatePagination();
      }
    }

  }
}
