import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { EventService } from 'src/app/services/Api/event.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'EventDetails-Step3',
  templateUrl: './EventDetails-Step3.component.html',
  styleUrls: ['./EventDetails-Step3.component.scss']
})
export class EventDetailsStep3Component extends UnsubscribesDestroy implements OnInit {
  entradas: any = [];
  listas: any = [];
  id!: number;
  imagenEntrada: String = ""
  @Input() event:any = null
  urlImages = environment.urlImages
  dataLoaded: Boolean = false;
  ticketsOpened: Array<number> = [];
  constructor(private eventsSE: EventService, private route: ActivatedRoute, private notificaciones: NotificacionesService, private loader: LoaderService) {
    super();
  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.event.event_images.forEach(element => {
      if (element.is_principal) {
        this.imagenEntrada = element.image.url
      }
    });
    this.getProducts();
  }
ngAfterViewChecked(){  
  this.getTicketLinesDisplayed(this.entradas);
}
  calculateVentas(entradas){
    entradas.forEach(element => {
      element.sold = element.ventas.entradas_vendidas;
      /*element.salesByPrice.forEach(element2 => {
        element.sold += Number(element2.entradas_vendidas);
      });*/
    });
  }
  controlOpenTicket(ticket: any) {
    this.ticketsOpened.includes(ticket) ?  this.ticketsOpened.splice(this.ticketsOpened.indexOf(ticket),1) : this.ticketsOpened.push(ticket)

  }
  getProducts() {
    this.eventsSE.getTicketsListByEventId(this.id)
    this.eventsSE.getResultTicket().pipe(takeUntil(this._unsubInd3)).subscribe(value => {
      if (value == null) { return }

      this.entradas = value["Ticket"]
      this.listas = value["List"]
      this.calculateVentas(this.entradas)
      this.dataLoaded = true;
      this._unsubInd3.next("");
    })
    this.eventsSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.notificaciones.showError(value.error.message);
      this._unsub.next("");
    })
  }

  // TicketLines

  getTicketLinesDisplayed(entradas: any) {
    let displayedTickets = document.querySelectorAll('.pill-lg-alt')
    entradas.forEach((ticket:any, index:number)=> {
      let stock = ticket.stock;
      let appLine = (ticket.entradasApp * 100) / stock;
      let webLine = (ticket.entradasWeb * 100) / stock;
      let displayedTicket = displayedTickets[index];
      let webLineElement = displayedTicket.querySelector('.sold.web')! as HTMLElement;
      let appLineElement = displayedTicket.querySelector('.sold.app')! as HTMLElement;

      if(appLineElement != null && webLineElement != null){
        appLineElement.style.width = appLine + "%";
        webLineElement.style.left = appLine + "%";
        webLineElement.style.width = webLine + "%";
      }
      
      let ventasLineElement = displayedTicket.querySelector('.sold.ventas')! as HTMLElement;
      let reservaLineElement = displayedTicket.querySelector('.sold.reservas')! as HTMLElement;

      if(reservaLineElement != null && ventasLineElement != null){
        let ventas = ticket.ventas.entradas_vendidas;
        ventas = Number(ventas);
        let reservas = ticket.reservas.entradas_vendidas;
        reservas = Number(reservas);
        ventasLineElement.style.width = (ventas * 100) / stock+"%";
        reservaLineElement.style.width = (reservas * 100) / stock+"%";
      }


    })
  }
}
