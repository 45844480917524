import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, catchError } from 'rxjs';
import { Facility } from 'src/app/models/Facility';
import { environment } from 'src/environments/environment';
import { BaseService } from './Base.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class CommunitiesService extends BaseService{

  private urlApi = environment.urlApi;
  constructor(private http: HttpClient) {
    super();
  }

  getCommunitiesByUser(){
    this.http.get(this.urlApi + '/api/community/my',this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getCommunitiesByUser, args: [] })
        this.checkStatusError(error);
      },
    });
  }
  getMymanagedCommunities(num_dev:number, num_pagina: number){
    this.http.get(this.urlApi + '/api/community/myAdmins?num_devoluciones='+num_dev+'&num_pagina='+num_pagina,this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getMymanagedCommunities, args: [] })
        this.checkStatusError(error);
      },
    });
  }
  getCommunityById(id){
    this.http.get(this.urlApi + '/api/community/'+id,this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getCommunityById, args: [id] })
        this.checkStatusError(error);
      },
    });
  }
  getStadistics(id){
    this.http.get(this.urlApi + '/api/community/'+id+'/statistics',this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultIndividual', data)
      },
      error: (error) => {
        this.sendNextResult('resultIndividualError', error, { method: this.getStadistics, args: [id] })
        this.checkStatusError(error);
      },
    });
  }
  newCommunitie(comunidad){
    this.http.post(this.urlApi + '/api/community',comunidad,this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.newCommunitie, args: [comunidad] })
        this.checkStatusError(error);
      },
    });
  }
  updateCommunity(id, community){
    this.http.put(this.urlApi + '/api/community/'+id,community,this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultUpdate', data)
      },
      error: (error) => {
        this.sendNextResult('resultUpdateError', error, { method: this.updateCommunity, args: [id, community] })
        this.checkStatusError(error);
      },
    });
  }

  updateAdmin(admin){
    this.http.put(this.urlApi + '/api/community/admin/authorization',admin,this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultDelete', data)
      },
      error: (error) => {
        this.sendNextResult('resultUpdateError', error, { method: this.updateAdmin, args: [admin] })
        this.checkStatusError(error);
      },
    });
  }
  getCommunitiesStadistics(){
    this.http.get(this.urlApi + '/api/group/header/communities/'+sessionStorage.getItem("groupId"),this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getCommunitiesStadistics, args: [] })
        this.checkStatusError(error);
      },
    });
  }
  getAllCommunitiesOfGroup(){
    this.http.get(this.urlApi + '/api/group/communities/'+sessionStorage.getItem("groupId"),this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getAllCommunitiesOfGroup, args: [] })
        this.checkStatusError(error);
      },
    });
  }
  delteCommunity(id){
    var communities_ids:any = []
    let send={communities_ids:communities_ids};
    if(!(id instanceof Array)){send.communities_ids.push(id);}else{send.communities_ids=id;}
    this.http.delete(this.urlApi + '/api/community',this.sendBodyOptions(send)).subscribe({
      next: (data) => {
        this.sendNextResult('resultDelete', data)
      },
      error: (error) => {
        this.sendNextResult('resultDeleteError', error, { method: this.delteCommunity, args: [id] })
        this.checkStatusError(error);
      },
    });
  }
}
