import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { PrService } from 'src/app/services/Api/pr.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'app-prEventsInfoPopup',
  templateUrl: './prEventsInfoPopup.component.html',
  styleUrls: ['./prEventsInfoPopup.component.scss']
})
export class PrEventsInfoPopupComponent extends UnsubscribesDestroy implements OnInit {

  relacionesPublicas: Array<any> = []

  constructor(public dialogRef: MatDialogRef<PrEventsInfoPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private prSE: PrService, private notificaciones: NotificacionesService) {
    super();
  }

  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.getVentasByPr();
  }


  //LLAMADAS A LA API

  getVentasByPr(){
    this.prSE.getVentasByPr(this.modalData.idPr, this.modalData.idEvento);
    this.prSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return }
      this.relacionesPublicas = value;
      this._unsubInd.next("");

    })
    this.prSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.notificaciones.showError(value.message)
      this._unsub.next("");
    })
  }

}
