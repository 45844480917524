import { AfterContentChecked, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { ListsService } from 'src/app/services/Api/lists.service';
import { TicketsService } from 'src/app/services/Api/tickets.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PopupService } from 'src/app/services/utils/popup.service';

@Component({
  selector: 'app-NewTicketPopup',
  templateUrl: './NewTicketPopup.component.html',
  styleUrls: ['./NewTicketPopup.component.scss']
})
export class NewTicketPopupComponent extends UnsubscribesDestroy implements OnInit {

  //VARIABLES DE CONTROL
  tiposGestion = [{ value: "FIXED_COMISSION", name: "Fijo" }, { value: "VARIABLE_COMISSION", name: "Variable" }]
  editar: Boolean = false;

  //VARIABLES DE FORMULARIO
  nombreTicket: String = ""
  nombreTicketError: Boolean = false;

  incluido: String = ""
  incluidoError: Boolean = false;

  fechaInicio: String = ""
  fechaFin: String = ""
  fechasError: Boolean = false;

  horaInicio: String = ""
  horaFin: String = ""

  stockTicket: number | null = null
  stockTicketError: Boolean = false;

  gastosGestion: number | null = 0
  gastosGestionError: Boolean = false;

  ticketConIva: number | null = 0;
  ticketConIvaError: Boolean = false;

  ticketSinIva: number | null = 0;
  ticketSinIvaError: Boolean = false;

  iva: number | null = 10;
  ivaError: Boolean = false;

  comisionRRPP: number | null = null;
  comisionRRPPError: Boolean = false;

  sales: number = 0;

  tipoComision: any = "VARIABLE_COMISSION";
  preorder: boolean = false;
  mostrarStock: boolean = true;
  constructor(public dialogRef: MatDialogRef<NewTicketPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private notificaciones: NotificacionesService, private popupSE: PopupService, private ticketsSE: TicketsService, private notificacionesSE: NotificacionesService, private loaderSE: LoaderService, private ticketSE: TicketsService, private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    document.querySelector(".mat-dialog-container")?.classList.add("rightPopup");
    if (this.modalData.accion == "Editar entrada") {
      // this.getEntradaInfo(this.modalData.entrada)
      this.setAllData(this.modalData.entrada);
    } else {
      this.iva = this.modalData.iva
    }
    if (this.route.snapshot.params['id'] != null || this.route.snapshot.params['id'] != undefined) {
      this.editar = true;
    }
  }

  //FUNCIONALIDAD
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
  calculateIva() {
    this.ticketSinIva = Number(((this.ticketConIva! + this.gastosGestion!) / Number("1." + this.iva!)).toFixed(2));
  }
  //GETTERS Y SETTERS
  getNombreEntrada(event) {
    this.nombreTicket = event;
  }
  getIncluido(event) {
    this.incluido = event;
  }
  getFechaInicio(event) {
    this.fechaInicio = event;
  }
  getFechaFin(event) {
    this.fechaFin = event;
  }
  getHoraInicio(event) {
    this.horaInicio = event;
  }
  getHoraFin(event) {
    this.horaFin = event;
  }
  getStockEntradas(event) {
    this.stockTicket = event;
  }

  getGastosGestion(event) {
    this.gastosGestion = event;
    this.calculateIva()

  }
  getPrecioEntradaIva(event) {
    this.ticketConIva = event;
    this.calculateIva()
  }
  getPrecioEntradaSinIva(event) {
    this.ticketSinIva = event;
  }
  getIva(event) {
    this.iva = event;
    this.calculateIva()
  }
  getComisionRRPP(event) {
    this.comisionRRPP = event;
  }
  getTipoGestion(event) {
    this.tiposGestion = event;
  }
  changeValue() {
    if (this.preorder) {
      this.preorder = false;
    } else {
      this.preorder = true;
    }
    this.updatePrereserva(this.preorder);
  }
  getMostrarStock() {
    if (this.mostrarStock) {
      this.mostrarStock = false;
    } else {
      this.mostrarStock = true;
    }
    this.updateMostrarStock(this.mostrarStock);
  }
  setAllData(entrada) {
    this.nombreTicket = entrada.name;
    this.incluido = entrada.description;
    this.fechaInicio = entrada.startDate;
    this.fechaFin = entrada.finishDate;
    this.horaInicio = entrada.startTime;
    this.horaFin = entrada.finishTime;
    this.stockTicket = entrada.stock;
    this.gastosGestion = entrada.administrativeExpenses;
    this.ticketConIva = entrada.fee;
    this.calculateIva()
    this.iva = entrada.iva;
    this.comisionRRPP = entrada.comission;
    this.tipoComision = entrada.taxType;
    this.preorder = entrada.isPreorder;
    this.mostrarStock = entrada.showStock
    if (entrada.sales == null) {
      this.sales = 0

    } else {
      this.sales = entrada.sales

    }
  }
  //VALIDACIONES
  validarNombre() {
    if (this.nombreTicket == "") {
      this.nombreTicketError = true;
    } else {
      this.nombreTicketError = false;
    }
  }
  validarFechas() {
    if (this.fechaInicio == "" || this.fechaFin == "") {
      this.fechasError = true;
    } else {
      this.fechasError = false;
    }
  }
  validarIncluye() {
    if (this.incluido == "") {
      this.incluidoError = true;
    } else {
      this.incluidoError = false;
    }
  }
  validarStock() {
    if (this.stockTicket == null) {
      this.stockTicketError = true;
    } else {
      this.stockTicketError = false;
    }
  }
  validarGastosGestion() {
    if (this.gastosGestion == null) {
      this.gastosGestionError = true;
    } else {
      this.gastosGestionError = false;
    }
  }
  validarPrecioEntradaConIva() {
    if (this.ticketConIva == null) {
      this.ticketConIvaError = true;
    } else {
      this.ticketConIvaError = false;
    }
  }
  validarPrecioEntradaSinIva() {
    if (this.ticketSinIva == null) {
      this.ticketSinIvaError = true;
    } else {
      this.ticketSinIvaError = false;
    }
  }
  validarIva() {
    if (this.iva == null) {
      this.ivaError = true;
    } else {
      this.ivaError = false;
    }
  }
  validarComisionRRPP() {
    if (this.comisionRRPP == null) {
      this.comisionRRPPError = true;
    } else {
      this.comisionRRPPError = false;
    }
  }

  validarEntrada() {
    this.validarNombre();
    // this.validarFechas();
    this.validarIncluye();
    this.validarStock();
    this.validarGastosGestion();
    this.validarPrecioEntradaConIva();
    this.validarPrecioEntradaSinIva();
    this.validarIva();
    this.validarComisionRRPP();
    if (!this.nombreTicketError && !this.incluidoError && !this.stockTicketError && !this.gastosGestionError && !this.ticketConIvaError && !this.ticketSinIvaError && !this.ivaError && !this.comisionRRPPError) {
      var entrada = {
        name: this.nombreTicket,
        description: this.incluido,
        stock: this.stockTicket,
        fee: this.ticketConIva!,
        iva: this.iva,
        administrativeExpenses: this.gastosGestion,
        company: Number(sessionStorage.getItem("dcmpny")),
        taxType: this.tipoComision,
        startDate: this.fechaInicio,
        finishDate: this.fechaFin,
        startTime: this.horaInicio,
        finishTime: this.horaFin,
        isActive: true,
        comission: this.comisionRRPP,
        showStock: this.mostrarStock
      }
      if (this.modalData.accion == "Nueva entrada") {
        this.closePopup(entrada);
      } else {
        if (this.modalData.entrada.idProducto != null) {
          entrada["idProducto"] = this.modalData.entrada.idProducto
          entrada['idEntrada'] = this.modalData.entrada.idEntrada
          entrada['sales'] = this.modalData.entrada.sales
          this.closePopup({ entradaVieja: this.modalData.entrada, entradaNueva: entrada });
        } else {
          this.closePopup({ entradaVieja: this.modalData.entrada, entradaNueva: entrada });
        }
      }
      if (this.preorder) {
        entrada["isPreorder"] = true;
      } else {
        entrada["isPreorder"] = null
      }
    } else {
      this.notificaciones.showError("Hay fallos en algunos campos, porfavor revísalos");
    }
  }

  //LAMADAS A LA API
  editarEntrada(element: any, value: any) {
    var ticket = {}
    ticket[element] = value;
    this.ticketSE.updateTicket(this.modalData.entrada.idProducto, ticket)
    this.ticketSE.getResultUpdate().pipe(takeUntil(this._unsubInd6)).subscribe(value => {
      if (value == null) { return }
      this.notificaciones.showFeedBack(value.message)
      this._unsubInd6.next("");
    })
    this.ticketSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.notificaciones.showError(value.message)
      this._unsub.next("");
    })
  }
  
  // getEntradaInfo(id){}

  updateNombreEntrada(event) {
    if (this.modalData.accion == "Editar entrada" && this.modalData.entrada.idProducto != null) { this.editarEntrada('name', event) }
  }
  updateStockEntrada(event) {
    if (this.modalData.accion == "Editar entrada" && this.modalData.entrada.idProducto != null) { this.editarEntrada('stock', event ?? 0) }
  }
  updateEntradasRestantes(event) {
    // if (this.modalData.accion == "Editar entrada" && this.modalData.entrada.idProducto != null) { this.editarEntrada('description', event) }

  }
  updateDescripcion(event) {
    if (this.modalData.accion == "Editar entrada" && this.modalData.entrada.idProducto != null) { this.editarEntrada('description', event) }

  }
  updatePVP(event) {
    if (this.modalData.accion == "Editar entrada" && this.modalData.entrada.idProducto != null) { this.editarEntrada('fee', event) }

  }
  updateGastosGestion(event) {
    if (this.modalData.accion == "Editar entrada" && this.modalData.entrada.idProducto != null) { this.editarEntrada('administrativeExpenses', event) }

  }
  updateComision(event) {
    if (this.modalData.accion == "Editar entrada" && this.modalData.entrada.idProducto != null) { this.editarEntrada('comission', event) }

  }
  updateTipoComision(event) {
    if (this.modalData.accion == "Editar entrada" && this.modalData.entrada.idProducto != null) { this.editarEntrada('comissionType', event) }

  }
  updatePrereserva(event) {
    if (this.modalData.accion == "Editar entrada" && this.modalData.entrada.idProducto != null) { this.editarEntrada('isPreorder', event) }

  }
  updateMostrarStock(event) {
    if (this.modalData.accion == "Editar entrada" && this.modalData.entrada.showStock != null) { this.editarEntrada('showStock', event) }

  }
}
