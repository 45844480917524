import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { PrService } from 'src/app/services/Api/pr.service';
import { UserService } from 'src/app/services/Api/user.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'app-addNewRRPPopup',
  templateUrl: './addNewRRPPopup.component.html',
  styleUrls: ['./addNewRRPPopup.component.scss']
})
export class AddNewRRPPopupComponent extends UnsubscribesDestroy implements OnInit {

  constructor(public dialogRef: MatDialogRef<AddNewRRPPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private notificaciones: NotificacionesService, private userSE: UserService, private loader: LoaderService, private prSE: PrService, private router: Router) { super() }
  actualURL = window.location.hostname + "/pr-register-link?dcmpny=" + sessionStorage.getItem("dcmpny");
  usuarioRegistrado = true;
  filtroCorreo = ""
  usuarioFiltrado: any = {}
  openList = false;
  invitado = false;

  //VARIABLES FORMULARIOS
  nombreRRPP: String = ""
  nombreRRPPError: Boolean = false;
  apeliidosRRPP: String = ""
  apeliidosRRPPError: Boolean = false;
  emailRRPP: String = ""
  emailRRPPError: Boolean = false;
  telefonoRRPP: Number | null = null;
  telefonoRRPPError: Boolean = false;
  localidadRRPP: String = ""
  localidadRRPPError: Boolean = false;
  fechaNacimientoRRPP: String = ""
  fechaNacimientoRRPPError: Boolean = false;
  dniRRPP: String = ""
  dniRRPPError: Boolean = false;

  ngOnInit() {
    document.querySelector(".mat-dialog-container")?.classList.add("rightPopup");

  }
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  //FUNCIONALIDADES
  copyToClipBoard() {
    navigator.clipboard.writeText(this.actualURL);
    this.notificaciones.showFeedBack("Se ha copiado el enlace correctamente");
    setTimeout(() => {
      this.forceClosePopup();
    }, 1000);
  }

  //GETTERS Y SETTERS
  getNombreRRPP(event) {
    this.nombreRRPP = event;
  }
  getApellidosRRPP(event) {
    this.apeliidosRRPP = event;
  }
  getEmailRRPP(event) {
    this.emailRRPP = event;
  }
  getTelefonoRRPP(event) {
    this.telefonoRRPP = event
  }
  getLocalidadRRPP(event) {
    this.localidadRRPP = event
  }
  getNaciminetoRRPP(event) {
    this.fechaNacimientoRRPP = event;
  }
  getDniRRPP(event) {
    this.dniRRPP = event
  }


  //VALIDACIONES

  validEmail(email: String) {
    var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
    return String(email).search(filter) != -1;
  }
  validDni(dni) {
    var filter = /^[0-9]{8,8}[A-Za-z]$/g;
    return String(dni).search(filter) != -1;
  }
  validarNombreRRPP() {
    if (this.nombreRRPP == "") {
      this.nombreRRPPError = true;
    } else {
      this.nombreRRPPError = false;
    }
  }
  validarApellidosRRPP() {
    if (this.apeliidosRRPP == "") {
      this.apeliidosRRPPError = true;
    } else {
      this.apeliidosRRPPError = false;
    }
  }
  validarEmailRRPP() {
    if (this.emailRRPP == "") {
      this.emailRRPPError = true;
    } else {
      this.emailRRPPError = false;
      if (this.validEmail(this.emailRRPP)) {
        this.emailRRPPError = false;
      } else {
        this.emailRRPPError = true;
      }
    }
  }
  validarTelefonoRRPP() {
    if (this.telefonoRRPP == null) {
      this.telefonoRRPPError = true;
    } else {
      this.telefonoRRPPError = false;
    }
  }
  validarFechaNacimiento() {
    if (this.fechaNacimientoRRPP == "") {
      this.fechaNacimientoRRPPError = true;
    } else {
      this.fechaNacimientoRRPPError = false;
    }
  }
  validarLocalidad() {
    if (this.localidadRRPP == "") {
      this.localidadRRPPError = true;
    } else {
      this.localidadRRPPError = false;
    }
  }
  validarDni() {
    if (this.dniRRPP == "") {
      this.dniRRPPError = true;
    } else {
      this.dniRRPPError = false;
    }
  }

  validarRRPP() {
    this.validarNombreRRPP();
    this.validarApellidosRRPP();
    this.validarEmailRRPP();
    this.validarTelefonoRRPP();
    this.validarFechaNacimiento();
    this.validarLocalidad();
    this.validarDni();
    if (!this.nombreRRPPError && !this.apeliidosRRPPError && !this.emailRRPPError && !this.telefonoRRPPError && !this.fechaNacimientoRRPPError && !this.localidadRRPPError && !this.dniRRPPError) {
      var rrpp = {
        idCompany: Number(sessionStorage.getItem("dcmpny")),
        timetable: "",
        name: this.nombreRRPP,
        surname: this.apeliidosRRPP,
        email: this.emailRRPP,
        phone: this.telefonoRRPP,
        address: {
          locality: this.localidadRRPP
        },
        birthDate: this.fechaNacimientoRRPP["value"],
        dni: this.dniRRPP,
        bankAccount: "",
        type: "RP",
        link: "",
        password: ""
      }
      this.addNewPR(rrpp)
    } else {
      this.notificaciones.showError("Hay errores en los campos, porfavor revisalos")
    }
  }

  //LLAMADAS A LA API
  filterUser(email) {
    if (email != "") {
      this.userSE.filterUserByEmail(email);
      this.loader.open();
      this.userSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
        if (value == null) { return }
        this.loader.closeSlow();
        this.openList = true;
        this.usuarioFiltrado = value
        this._unsubInd.next("");
      })
      this.userSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
        if (value == null) { return }
        this.loader.closeSlow();
        this.notificaciones.showWarning(value.error.message)
        this._unsub.next("");
      })
    }

  }
  sendInvitation() {
    this.userSE.sendInvitationEmailRRPP({ companyId: Number(sessionStorage.getItem("dcmpny")), userId: this.usuarioFiltrado.id});
    this.loader.open();
    this.userSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe(value => {
      if (value == null) { return }
      this.loader.closeSlow();
      this.notificaciones.showFeedBack(value)
      setTimeout(() => {
        this.closePopup({accionHecha: true})
      }, 500);
      this._unsubInd2.next("");
    })
    this.userSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.loader.closeSlow();
      this.notificaciones.showError(value.error.message)
      this.forceClosePopup();
      this._unsub.next("");
    })
  }

  addNewPR(rrpp){
    this.prSE.addNewPR(rrpp);
    this.loader.open();
    this.prSE.getResult().pipe(takeUntil(this._unsubInd3)).subscribe(value => {
      if (value == null) { return }

      this.loader.closeSlow();
      this.notificaciones.showFeedBack(value.message);
      setTimeout(() => {
        this.closePopup({accionHecha: true})
      }, 500);

      this._unsubInd3.next("");
    })
    this.prSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.loader.closeSlow();
      this.notificaciones.showError(value.error.message);
      this._unsub.next("");
    })
  }
}
