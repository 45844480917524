<PageTemplate>
  <ng-container bodyContent>
    <header [searcherActive]="false" [buttonActive]="true" [idFacility]="facility.id" [buttonAction]="'Editar recinto'"
      [permiso]="permiso"></header>
    <main>
      <h2 *ngIf="isDataLoaded">{{ facility.name }}</h2>
      <section class="details">
        <div class="top-group">
          <div class="map">
            <mat-spinner *ngIf="!isDataLoaded" class="big center"></mat-spinner>
            <ng-container *ngIf="isDataLoaded">
              <agm-map [latitude]="lat" [zoom]="zoom" [longitude]="lng">
                <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
              </agm-map>
            </ng-container>
          </div>
          <div class="quick-facts">
            <ul>
              <li class="fact">
                <div class="pill-flat">
                  <div class="pill-title">
                    <h5>Aforo total</h5>
                  </div>
                  <div class="pill-details">
                    <mat-spinner *ngIf="!isDataLoaded" class="small"></mat-spinner>
                    <small *ngIf="isDataLoaded">{{ facility.capacity }} personas</small>
                  </div>
                </div>
              </li>
              <li class="fact">
                <div class="pill-flat">
                  <div class="pill-title">
                    <h5>Metros cuadrados</h5>
                  </div>
                  <div class="pill-details">
                    <mat-spinner *ngIf="!isDataLoaded" class="small"></mat-spinner>
                    <small *ngIf="isDataLoaded">{{ facility.square_meters }} m&#178;</small>
                  </div>
                </div>
              </li>
            </ul>
            <ul>
              <li class="fact">
                <div class="pill-flat">
                  <div class="pill-title">
                    <h5>Zonas</h5>
                  </div>
                  <div class="pill-details">
                    <mat-spinner *ngIf="!isDataLoaded" class="small"></mat-spinner>
                    <small *ngIf="isDataLoaded">{{ facility.floor_plans.length }} zonas</small>
                  </div>
                </div>
              </li>
              <li class="fact">
                <div class="pill-flat">
                  <div class="pill-title">
                    <h5>Reservas disponibles</h5>
                  </div>
                  <div class="pill-details">
                    <mat-spinner *ngIf="!isDataLoaded" class="small"></mat-spinner>
                    <small
                      *ngIf="isDataLoaded && facility.availableBookings == null || facility.availableBookings == undefined">0
                      reservas</small>
                    <small
                      *ngIf=" isDataLoaded && facility.availableBookings != null || facility.availableBookings != undefined">{{facility.availableBookings}}
                      reservas</small>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="bottom-group">
          <h3>Dirección del recinto</h3>
          <mat-spinner *ngIf="!isDataLoaded" class="medium"></mat-spinner>

          <p *ngIf="isDataLoaded">
            {{ facility.address.street }},{{ facility.address.number }} ,
            {{ facility.address.locality }}, {{ facility.address.zip_code }},{{facility.address.province}}
          </p>


        </div>
        <div class="etiquetasContainer">
          <span *ngFor="let etiqueta of facility.categories">{{etiqueta.name}}</span>
        </div>

      </section>
      <h3>Últimos eventos añadidos a este recinto</h3>
      <div class="eventosGeneralContainer">
        <mat-spinner *ngIf="!isDataLoaded" class="big center"></mat-spinner>

        <ng-container *ngIf="isDataLoaded">
          <card [type]="'eventSimpleCard'" [data]="evento"
            [routerLink]="['/publisher/management/events/my-events/event-details/'+evento.id]"
            *ngFor="let evento of eventos"></card>
        </ng-container>
      </div>
    </main>
  </ng-container>
</PageTemplate>