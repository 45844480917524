import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { SearchUserPopupComponent } from 'src/app/popups/SearchUser-Popup/SearchUser-Popup.component';
import { CampaignService } from 'src/app/services/Api/Campaign.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PopupService } from 'src/app/services/utils/popup.service';

@Component({
  selector: 'edicionMensaje',
  templateUrl: './EdicionMensaje.component.html',
  styleUrls: ['./EdicionMensaje.component.scss'],
})
export class EdicionMensajeComponent
  extends UnsubscribesDestroy
  implements OnInit
{
  @Output() data = new EventEmitter<any>();
  @Output() validStep = new EventEmitter<Boolean>();
  @Output() dataToUpdate = new EventEmitter<any>();
  @Input() receiveData: any = null;

  @ViewChild('editor') editor: any;

  cuerpo: string = '';
  url: string = '';
  editar: boolean = false;

  asuntoMail: string = '';
  asuntoMailError: boolean = false;
  cuerpoMailError: boolean = false;
  urlMailError: boolean = false;
  options: any = {
    selector: 'textarea',
    plugins:
      'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage tinycomments tableofcontents footnotes mergetags autocorrect',
    toolbar:
      ' undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
  };
  constructor(
    private route: ActivatedRoute,
    private notificaciones: NotificacionesService,
    private popupSE: PopupService,
    private campaignSE: CampaignService,
    private loader: LoaderService
  ) {
    super();
  }

  ngOnInit() {
    if (
      this.route.snapshot.params['id'] != null ||
      this.route.snapshot.params['id'] != undefined
    ) {
      this.editar = true;
    }
    this.setData();
  }

  /**
   * GETTERS Y SETTER
   */
  setData() {
    if (this.receiveData != null) {
      this.asuntoMail = this.receiveData.asunto;
      this.cuerpo = this.receiveData.cuerpo;
      this.url = this.receiveData.url;
    }
  }
  getAsunto(event: string) {
    this.asuntoMail = event;
  }
  getCuerpo(event: string) {
    this.cuerpo = event;
  }
  getUrl(event: string) {
    this.url = event;
  }
  /**
   * VALIDACIONES
   */

  validarAsunto() {
    if (this.asuntoMail == '') {
      this.asuntoMailError = true;
    } else {
      this.asuntoMailError = false;
    }
  }
  validarCuerpoEmail() {
    if (this.cuerpo == '') {
      this.cuerpoMailError = true;
    } else {
      this.cuerpoMailError = false;
    }
  }

  validarPaso3() {
    this.validarCuerpoEmail();
    this.validarAsunto();
    if (!this.asuntoMailError && !this.cuerpoMailError && !this.urlMailError) {
      var step3Obj = {
        asunto: this.asuntoMail,
        cuerpo: this.cuerpo,
        url: this.url,
      };
      this.data.emit(step3Obj);
      this.validStep.emit(true);
    } else {
      this.validStep.emit(false);
      this.notificaciones.showError('No pueden haber campos vacios');
    }
  }

  /**
   * FUNCTIONALITIES
   */
  sendTestCampaign() {
    this.popupSE.openPopup(SearchUserPopupComponent);
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd5))
      .subscribe((res) => {
        if (res == null) return;
        this.createAnnounce({
          usuarios: [res.returnValue.user],
          tipo: 'push',
          body: this.cuerpo,
          asunto: this.asuntoMail,
          url: this.url,
        });
        this._unsubInd5.next('');
      });
  }

  /**
   * EMITTERS
   */
  updateAsunto(event: string) {
    if (this.editar) {
      this.dataToUpdate.emit({ element: 'asunto', value: event });
    }
  }
  updateCuerpo(event: any) {
    if (this.editar) {
      this.dataToUpdate.emit({
        element: 'cuerpo',
        value: event.event.level.content,
      });
    }
  }
  updateUrl(event: any) {
    if (this.editar) {
      this.dataToUpdate.emit({
        element: 'url',
        value: event.event.level.content,
      });
    }
  }

  /**
   * CALLS TO API
   */
  createAnnounce(data) {
    this.campaignSE.createAnnounce(data);
    this.loader.open();

    this.campaignSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: any) => {
        if (res == null) {
          return;
        }
        this.notificaciones.showFeedBack(
          'Campaña de prueba lanzada correctamente'
        );
        this.loader.closeSlow();
        this._unsubInd2.next('');
      });
    this.campaignSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: any) => {
        if (res == null) {
          return;
        }
        if (res.status == 422) {
          this.notificaciones.showFeedBack(
            'Campaña de prueba lanzada correctamente'
          );
        }
        this.loader.closeSlow();
        this._unsubInd2.next('');
      });
  }
}
