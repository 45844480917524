import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Shared
import { LoginPageComponent } from './pages/shared/login-page/login-page.component';
import { NotFoundPageComponent } from './pages/shared/not-found-page/not-found-page.component';

// Publisher
import { FacilitiesPageComponent } from './pages/publisher/Facilities/facilities-page/facilities-page.component';
import { HomePageComponent } from './pages/publisher/home-page/home-page.component';
import { SettingsPageComponent } from './pages/publisher/settings-page/settings-page.component';
import { FacilityDetailsPageComponent } from './pages/publisher/Facilities/facility-details-page/facility-details-page.component';
import { AddFacilityPageComponent } from './pages/publisher/Facilities/add-facility-page/add-facility-page.component';
import { SubscriptionsPageComponent } from './pages/publisher/subscriptions-page/subscriptions-page.component';
import { InvoicesPageComponent } from './pages/publisher/invoices-page/invoices-page.component';
import { AddCampaignPageComponent } from './pages/publisher/CommunititesPromotions/ManagementCommunitiesPromotions/Holders/Promotions/add-campaign-page/add-campaign-page.component';

// Admin
import { ChangePasswordComponent } from './pages/publisher/changePassword/changePassword.component';
import { PrLinkComponent } from './pages/publisher/pr-link/pr-link.component';
import { MisEventosComponent } from './pages/RRPP-Movil/mis-eventos/mis-eventos.component';
import { HomeComponent } from './pages/RRPP-Movil/home/home.component';
import { MisComunidadesComponent } from './pages/RRPP-Movil/mis-comunidades/mis-comunidades.component';
import { MiPerfilComponent } from './pages/RRPP-Movil/mi-perfil/mi-perfil.component';
import { NuevaListaComponent } from './pages/RRPP-Movil/nueva-lista/nueva-lista.component';
import { VerEventoComponent } from './pages/RRPP-Movil/ver-evento/ver-evento.component';
import { RecoverPasswordComponent } from './pages/shared/recoverPassword/recoverPassword.component';
import { RegisterCompanyFormComponent } from './pages/shared/register-company-form/register-company-form.component';
import { RedirectToStoreComponent } from './pages/shared/redirect-to-store/redirect-to-store.component';
import { AdminWelcomeComponent } from './pages/admin/admin-welcome/admin-welcome.component';
import { AdminFormBusinessComponent } from './pages/admin/admin-form-business/admin-form-business.component';
import { ManagementEventsComponent } from './pages/publisher/Events/ManagementEvents/ManagementEvents.component';
import { MyEventsComponent } from './pages/publisher/Events/ManagementEvents/Holders/MyEvents/MyEvents.component';
import { CreateEditEventsComponent } from './pages/publisher/Events/ManagementEvents/Holders/CreateEditEvents/CreateEditEvents.component';
import { EventDetailsComponent } from './pages/publisher/Events/ManagementEvents/Holders/EventDetails/EventDetails.component';
import { ValidateBuyTicketsSelectEventComponent } from './pages/publisher/Events/ManagementEvents/Holders/ValidateBuyTickets/ValidateBuyTickets-SelectEvent.component';
import { ValidateBuyTicketsComponent } from './pages/publisher/Events/ManagementEvents/Holders/ValidateBuyTickets/Holders/ValidateBuyTickets/ValidateBuyTickets.component';
import { HistoricEventComponent } from './pages/publisher/Events/ManagementEvents/Holders/HistoricEvent/HistoricEvent.component';
import { ManagementCommunitiesPromotionsComponent } from './pages/publisher/CommunititesPromotions/ManagementCommunitiesPromotions/ManagementCommunitiesPromotions.component';
import { MyCommunitiesComponent } from './pages/publisher/CommunititesPromotions/ManagementCommunitiesPromotions/Holders/Communities/MyCommunities/MyCommunities.component';
import { CommunityDetailsComponent } from './pages/publisher/CommunititesPromotions/ManagementCommunitiesPromotions/Holders/Communities/MyCommunities/Holders/CommunityDetails/CommunityDetails.component';
import { CreateEditCommunityComponent } from './pages/publisher/CommunititesPromotions/ManagementCommunitiesPromotions/Holders/Communities/MyCommunities/Holders/CreateEditCommunity/CreateEditCommunity.component';
import { CustomerDatabaseComponent } from './pages/publisher/CommunititesPromotions/ManagementCommunitiesPromotions/Holders/Marketing/CustomerDatabase/CustomerDatabase.component';
import { PublicRelationComponent } from './pages/publisher/PublicRelation/PublicRelation.component';
import { PublicRelationDetailsComponent } from './pages/publisher/PublicRelation/Holders/PublicRelation-Details/PublicRelation-Details.component';
import { MyCompaniesComponent } from './pages/admin/MyCompanies/MyCompanies.component';
import { AdminHomeComponent } from './pages/admin/Admin-GeneralAdministration/Home/Home.component';
import { BussinesComponent } from './pages/admin/Admin-GeneralAdministration/Bussines/Bussines.component';
import { PromotionalPageComponent } from './pages/admin/Admin-GeneralAdministration/PromotionalPage/PromotionalPage.component';
import { EditPromotionalPageComponent } from './pages/admin/Admin-GeneralAdministration/EditPromotionalPage/EditPromotionalPage.component';
import { CommunitiesComponent } from './pages/admin/Admin-GeneralAdministration/Communities/Communities.component';
import { MyUserComponent } from './pages/admin/Admin-GeneralAdministration/MyUser/MyUser.component';
import { UpdateGroupDataComponent } from './pages/admin/Admin-GeneralAdministration/UpdateGroupData/UpdateGroupData.component';
import { AdminCustomerDatabaseComponent } from './pages/admin/Admin-GeneralAdministration/AdminCustomerDatabase/AdminCustomerDatabase.component';
import { UsersPermissionsComponent } from './pages/admin/Admin-GeneralAdministration/UsersPermissions/UsersPermissions.component';
import { InvoicesComponent } from './pages/admin/Admin-GeneralAdministration/Invoices/Invoices.component';
import { EnlaceInvitacionComponent } from './pages/RRPP-Movil/enlace-invitacion/enlace-invitacion.component';
import { ExternalValidationComponent } from './pages/shared/ExternalValidation/ExternalValidation.component';
import { MailingComponent } from './pages/publisher/CommunititesPromotions/ManagementCommunitiesPromotions/Holders/Promotions/add-campaign-page/Mailing/Mailing.component';
import { PushComponent } from './pages/publisher/CommunititesPromotions/ManagementCommunitiesPromotions/Holders/Promotions/add-campaign-page/Push/Push.component';
import { AudienciesComponent } from './pages/publisher/CommunititesPromotions/ManagementCommunitiesPromotions/Holders/Promotions/Audiencies/Audiencies.component';
import { SupportChatComponent } from './pages/publisher/supportChat/supportChat.component';
import { HomeSAComponent } from './pages/SuperAdmin/HomeSA/HomeSA.component';
import { SocialComponent } from './pages/SuperAdmin/Social/Social.component';
import { ReportesDetailsComponent } from './pages/SuperAdmin/Social/ReportesDetails/ReportesDetails.component';
import { MiEquipoComponent } from './pages/RRPP-Movil/mi-equipo/mi-equipo.component';
import { GestionPagosComponent } from './pages/SuperAdmin/GestionPagos/GestionPagos.component';
import { GestionEntradasComponent } from './pages/SuperAdmin/GestionEntradas/GestionEntradas.component';
import { GestionCRMComponent } from './pages/SuperAdmin/GestionCRM/GestionCRM.component';
import { ValidarEmailComponent } from './pages/shared/ValidarEmail/ValidarEmail.component';

const routes: Routes = [
  { path: '', component: LoginPageComponent },
  {
    path: 'superadmin/home',
    component: HomeSAComponent,
    data: { title: 'Panel de administración del Superadmin', breadcrumb: [{ name: "Home", link: "./" }] },

  },
  {
    path: 'superadmin/social',
    component: SocialComponent,
    data: { title: 'Avisos y reportes', breadcrumb: [{ name: "Avisos y reportes", link: "./" }] },
  },
  {
    path: 'superadmin/pagos',
    component: GestionPagosComponent,
    data: { title: 'Gestión de pagos', breadcrumb: [{ name: "Gestión de pagos", link: "./" }] },
  },
  {
    path: 'superadmin/entradas',
    component: GestionEntradasComponent,
    data: { title: 'Gestión de entradas', breadcrumb: [{ name: "Gestión de entradas", link: "./" }] },
  },
  {
    path: 'superadmin/crm',
    component: GestionCRMComponent,
    data: { title: 'CRM', breadcrumb: [{ name: "Gestión de los usuarios", link: "./" }] },
  },
  {
    path: 'superadmin/social/report-detail/:id',
    component: ReportesDetailsComponent,
    data: { title: 'Detalles del reporte', breadcrumb: [{ name: "Avisos y reportes", link: "/superadmin/social" }, { name: "Detalles del reporte", link: "/." }] },
  },
  {
    path: 'admin/newDirection',
    component: AdminWelcomeComponent
  },
  {
    path: 'admin/my-companies',
    component: MyCompaniesComponent
  },
  {
    path: 'admin/home',
    component: AdminHomeComponent,
    data: { title: 'Home', breadcrumb: [{ name: "Home", link: "/admin/home" }] },
  },
  {
    path: 'admin/bussines',
    component: BussinesComponent,
    data: { title: 'Negocios filiales', breadcrumb: [{ name: "Negocios filiales", link: "/admin/bussines" }] },
  },
  {
    path: 'admin/bussines/new-bussines',
    component: AdminFormBusinessComponent
  },
  {
    path: 'admin/social/promotional-page',
    component: PromotionalPageComponent,
    data: { title: 'Página de mi negocio', breadcrumb: [{ name: "Social", link: "./" }, { name: "Página de mi negocio", link: "./" }] },
  },

  {
    path: 'admin/social/promotional-page/edit-promotional-page',
    component: EditPromotionalPageComponent,
    data: { title: 'Página de mi negocio', breadcrumb: [{ name: "Social", link: "./" }, { name: "Página de mi negocio", link: "/admin/social/promotional-page/" }, { name: "Eduitar página de mi negocio", link: "./" }] },
  },
  {
    path: 'admin/social/communities',
    component: CommunitiesComponent,
    data: { title: 'Comunidades', breadcrumb: [{ name: "Social", link: "./" }, { name: "Comunidades", link: "./" }] },
  },
  {
    path: 'admin/social/customer-database',
    component: AdminCustomerDatabaseComponent,
    data: { title: 'CRM', breadcrumb: [{ name: "Social", link: "./" }, { name: "Base de datos de clientes", link: "./" }] },
  },
  {
    path: 'admin/administration/my-profile',
    component: MyUserComponent,
    data: { title: 'Mi perfil', breadcrumb: [{ name: "Administración", link: "./" }, { name: "Mi perfil", link: "./" }] },
  },
  {
    path: 'admin/administration/my-group',
    component: UpdateGroupDataComponent,
    data: { title: 'Mi grupo', breadcrumb: [{ name: "Administración", link: "./" }, { name: "Mi grupo", link: "./" }] },
  },
  {
    path: 'admin/administration/users-permissions',
    component: UsersPermissionsComponent,
    data: { title: 'Mi grupo', breadcrumb: [{ name: "Administración", link: "./" }, { name: "Usuarios y permisos", link: "./" }] },
  },
  {
    path: 'admin/administration/invoices',
    component: InvoicesComponent,
    data: { title: 'Liquidaciones', breadcrumb: [{ name: "Administración", link: "./" }, { name: "Liquidaciones", link: "./" }] },
  },
  {
    path: 'redirect-app',
    component: RedirectToStoreComponent,
    data: { title: 'Detectando sistema operativo' },
  },

  {
    path: 'change-password',
    component: RecoverPasswordComponent,
    data: { title: 'Cambiar contraseña' },
  },
  {
    path: 'login',
    component: LoginPageComponent,
    data: { title: 'Login' },
  },
  {
    path: 'nuevo-negocio',
    component: RegisterCompanyFormComponent,
    data: { title: 'Registrar nuevo negocio' },
  },
  {
    path: 'publisher/home',
    component: HomePageComponent,
    data: { title: 'Home', breadcrumb: [{ name: "Home", link: "/publisher/home" }] },
  },
  {
    path: 'publisher/support/chat',
    component: SupportChatComponent,
    data: { title: 'Mensajería de soporte a usuarios', breadcrumb: ["Soporte", "Chat de soporte"] },
  },
  {
    path: 'publisher/management/events',
    component: ManagementEventsComponent,
    data: { title: 'Eventos', breadcrumb: [{ name: "Eventos", link: "/publisher/management/events" }] },
  },
  {
    path: 'publisher/management/events/my-events',
    component: MyEventsComponent,
    data: { title: 'Mis eventos', breadcrumb: [{ name: "Eventos", link: "/publisher/management/events" }, { name: "Mis eventos", link: "/publisher/management/events/my-events" }] },
  },
  {
    path: 'publisher/management/events/my-events/event-details/:id',
    component: EventDetailsComponent,
    data: { title: 'Detalles de evento', breadcrumb: [{ name: "Eventos", link: "/publisher/management/events" }, { name: "Mis eventos", link: "/publisher/management/events/my-events" }, { name: "Detalles de eventos", link: "." }] },
  },
  {
    path: 'publisher/management/events/my-events/add-event',
    component: CreateEditEventsComponent,
    data: { title: 'Crear nuevo evento', breadcrumb: [{ name: "Eventos", link: "/publisher/management/events" }, { name: "Añadir nuevo evento", link: "/publisher/management/events/my-events/add-event" }] },
  },
  {
    path: 'publisher/management/events/my-events/edit-event/:id',
    component: CreateEditEventsComponent,
    data: { title: 'Editar evento', breadcrumb: [{ name: "Eventos", link: "/publisher/management/events" }, { name: "Mis eventos", link: "/publisher/management/events/my-events" }, { name: "Editar evento", link: "." }] },
  },
  {
    path: 'publisher/management/events/today-events',
    component: ValidateBuyTicketsSelectEventComponent,
    data: { title: 'Eventos de hoy', breadcrumb: [{ name: "Eventos", link: "/publisher/management/events" }, { name: "Eventos de hoy", link: "/publisher/management/events/today-events" }] },
  },
  {
    path: 'publisher/management/events/today-events/buy-validate/:id',
    component: ValidateBuyTicketsComponent,
    data: { title: 'Venta y validación de entradas', breadcrumb: [{ name: "Eventos", link: "/publisher/management/events" }, { name: "Eventos de hoy", link: "/publisher/management/events/today-events" }, { name: "Comprar y validar entrada", link: "." }] },
  },
  {
    path: 'publisher/management/events/event-history',
    component: HistoricEventComponent,
    data: { title: 'Histórico de eventos', breadcrumb: [{ name: "Eventos", link: "/publisher/management/events" }, { name: "Histórico de eventos", link: "/publisher/management/events/event-history" }] },
  },
  {
    path: 'publisher/management/public-relation',
    component: PublicRelationComponent,
    data: { title: 'Relaciones públicas', breadcrumb: [{ name: "Relaciones públicas", link: "/publisher/management/public-relation" },] },
  },
  {
    path: 'publisher/management/public-relation/public-relation-details/:id',
    component: PublicRelationDetailsComponent,
    data: { title: 'Detalles de relación pública', breadcrumb: [{ name: "Relaciones públicas", link: "/publisher/management/public-relation" }, { name: "Detalles de relacion pública", link: "." }] },
  },
  {
    path: 'pr-register-link',
    component: PrLinkComponent,
    data: { title: 'Link de registro de RRPP' }
  },
  {
    path: 'publisher/facilities',
    component: FacilitiesPageComponent,
    data: { title: 'Mis recintos', breadcrumb: [{ name: "Mis recintos", link: "/publisher/facilities" }] },
  },
  {
    path: 'publisher/facilities/add-facility',
    component: AddFacilityPageComponent,
    data: { title: 'Añadir recinto', breadcrumb: ["Administración", "Mis sitios", "Mis recintos", "Añadir recinto"] },
  },
  {
    path: 'publisher/facilities/edit-facility/:id',
    component: AddFacilityPageComponent,
    data: { title: 'Editar recinto', breadcrumb: ["Administración", "Mis sitios", "Mis recintos", "Detalles de recinto", "Editar recinto"] },
  },
  {
    path: 'publisher/facilities/facility-details/:id',
    component: FacilityDetailsPageComponent,
    data: { title: 'Detalles de recinto', breadcrumb: [{ name: "Mis recintos", link: "/publisher/facilities" }, { name: "Detalles de recinto", link: "." }] },
  },

  {
    path: 'publisher/settings',
    component: SettingsPageComponent,
    data: { title: 'Opciones', breadcrumb: ["Administración", "Opciones"] },
  },
  {
    path: 'publisher/settings/change-password',
    component: ChangePasswordComponent,
    data: { title: 'Mi perfiñ', breadcrumb: ["Administración", "Opciones", "Mi perfil"] }
  },
  {
    path: 'publisher/places/subscriptions',
    component: SubscriptionsPageComponent,
    data: { title: 'Suscripciones' },
  },
  {
    path: 'publisher/communities-promotions',
    component: ManagementCommunitiesPromotionsComponent,
    data: { title: 'Comunidades y promociónes', breadcrumb: [{ name: "Comunidades y promociones", link: "/publisher/communities-promotions" }] },
  },
  {
    path: 'publisher/communities-promotions/my-communities',
    component: MyCommunitiesComponent,
    data: { title: 'Mis comunidades', breadcrumb: [{ name: "Comunidades y promociones", link: "/publisher/communities-promotions" }, { name: "Mis comunidades", link: "/publisher/communities-promotions/my-communities" }] },
  },
  {
    path: 'publisher/communities-promotions/my-communities/add-community',
    component: CreateEditCommunityComponent,
    data: { title: 'Nueva comunidad' },
  },

  {
    path: 'publisher/communities-promotions/my-communities/edit-community/:id',
    component: CreateEditCommunityComponent,
    data: { title: 'Editar comunidad' },
  },
  {
    path: 'publisher/communities-promotions/my-communities/community-details/:id',
    component: CommunityDetailsComponent,
    data: { title: 'Detalles de comunidad', breadcrumb: [{ name: "Comunidades y promociones", link: "/publisher/communities-promotions" }, { name: "Mis comunidades", link: "/publisher/communities-promotions/my-communities" }, { name: "Detalles de comunidad", link: "." }] },
  },
  {
    path: 'publisher/communities-promotions/customer-database',
    component: CustomerDatabaseComponent,
    data: { title: 'Base de datos de clientes', breadcrumb: [{ name: "Comunidades y promociones", link: "/publisher/communities-promotions" }, { name: "Base de datos de clientes", link: "/publisher/communities-promotions/customer-database" }] },
  },
  {
    path: 'publisher/communities-promotions/promotions',
    component: AudienciesComponent,
    data: { title: 'Campañas promocionales', breadcrumb: [{ name: "Comunidades y promociones", link: "/publisher/communities-promotions" }, { name: "Campañas promocionales", link: "/publisher/communities-promotions/promotions" }] },
  },
  {
    path: 'publisher/communities-promotions/promotions/add-campaign',
    component: AddCampaignPageComponent,
    data: { title: 'Añadir una campaña promocional', breadcrumb: [{ name: "Comunidades y promociones", link: "/publisher/communities-promotions" }, { name: "Campañas promocionales", link: "/publisher/communities-promotions/promotions" }, { name: "Añadir campaña promocional", link: "./" }] },
  },
  {
    path: 'publisher/communities-promotions/promotions/add-campaign/mailing',
    component: MailingComponent,
    data: { title: 'Mailing', breadcrumb: [{ name: "Comunidades y promociones", link: "/publisher/communities-promotions" }, { name: "Campañas", link: "/publisher/communities-promotions/promotions" }, { name: "Añadir campaña", link: "/publisher/communities-promotions/promotions/add-campaign" }, { name: "Mailing", link: "./" }] },
  },
  {
    path: 'publisher/communities-promotions/promotions/add-campaign/mailing/:id',
    component: MailingComponent,
    data: { title: 'Mailing', breadcrumb: [{ name: "Comunidades y promociones", link: "/publisher/communities-promotions" }, { name: "Campañas", link: "/publisher/communities-promotions/promotions" }, { name: "Añadir campaña", link: "/publisher/communities-promotions/promotions/add-campaign" }, { name: "Mailing", link: "./" }] },
  },
  {
    path: 'publisher/communities-promotions/promotions/add-campaign/push',
    component: PushComponent,
    data: { title: 'Push', breadcrumb: [{ name: "Comunidades y promociones", link: "/publisher/communities-promotions" }, { name: "Campañas promocionales", link: "/publisher/communities-promotions/promotions" }, { name: "Añadir campaña promocional", link: "/publisher/communities-promotions/promotions/add-campaign" }, { name: "Push", link: "./" }] },
  },
  {
    path: 'publisher/places/invoices',
    component: InvoicesPageComponent,
    data: { title: 'Facturas', breadcrumb: ["Administración", "Mi espacio", "Facturas"] },
  },

  {
    path: 'publisher/promotions/campaigns/add-campaign',
    component: AddCampaignPageComponent,
    data: { title: 'Crear campaña', breadcrumb: ["Administración", "Comunidades y promociones", "Promociones", "Campañas", "Añadir campaña"] },
  },

  {
    path: 'rrpp/home',
    component: HomeComponent,
    data: { title: 'Home' },
  },
  {
    path: 'rrpp/mis-eventos',
    component: MisEventosComponent,
    data: { title: 'Mis eventos' },
  },
  {
    path: 'rrpp/mis-comunidades',
    component: MisComunidadesComponent,
    data: { title: 'Mis comunidades' },
  },
  {
    path: 'rrpp/mi-perfil',
    component: MiPerfilComponent,
    data: { title: 'Mi perfil' },
  },
  {
    path: 'rrpp/mi-equipo',
    component: MiEquipoComponent,
    data: { title: 'Mi equipo' },
  },
  {
    path: 'rrpp/nueva-lista',
    component: NuevaListaComponent,
    data: { title: 'Nueva lista' },
  },
  {
    path: 'rrpp/nueva-lista/:id',
    component: NuevaListaComponent,
    data: { title: 'Nueva lista' },
  },
  {
    path: 'rrpp/ver-evento/:id',
    component: VerEventoComponent,
    data: { title: 'Ver evento' },
  },
  {
    path: 'rrpp/compartir-perfil',
    component: EnlaceInvitacionComponent,
    data: { title: 'Compartir perfil' },
  },
  {
    path: 'rrpp/compartir-perfil/:id',
    component: EnlaceInvitacionComponent,
    data: { title: 'Aceptar invitación' },
  },
  {
    path: 'validar-entrada/:eventId',
    component: ExternalValidationComponent,
    data: { title: 'Validar entradas' },
  },
  { path: 'publisher', redirectTo: 'publisher/home', pathMatch: 'full' },
  { path: 'validate-email', component: ValidarEmailComponent },
  { path: 'admin', redirectTo: 'admin/home', pathMatch: 'full' },
  {
    path: '**',
    component: NotFoundPageComponent,
    data: { title: '404 Not Found' },
  },

];

@NgModule({
  // use "scrollPositionRestoration: 'enabled'" to always go to the top of the page when moving between links
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }

/* --- ROUTE MAP ---

.
├── ./login
├── ./welcome
├── ./publisher/*
│   ├── ./publisher/home
│   ├── ./publisher/management/
│   │   ├── ./publisher/management/add-events
│   │   ├── ./publisher/management/edit-events
│   │   ├── ./publisher/management/events/
│   │   │   └── ./publisher/management/events/:id
│   │   ├── ./publisher/management/add-pr
│   │   ├── ./publisher/management/edit-pr
│   │   ├── ./publisher/management/pr/
│   │   ├── ./publisher/management/ticket-store/
│   │   │   └── ./publisher/management/ticket-store/:id
│   │   ├── ./publisher/management/qr-access
│   │   └── ./publisher/management/event-history
│   ├── ./publisher/promotions/
│   │   ├── ./publisher/promotions/communities
│   │   │   └── ./publisher/promotions/communities/:id
│   │   ├── ./publisher/promotions/campaigns
│   │   └── ./publisher/promotions/customer-database/
│   ├── ./publisher/places/
│   │   ├── ./publisher/places/business
│   │   ├── ./publisher/places/edit-business
│   │   ├── ./publisher/places/facilities/
│   │   │   └── ./publisher/places/facilities/:id
│   │   ├── ./publisher/places/edit-business-confidential
│   │   ├── ./publisher/places/roles
│   │   ├── ./publisher/places/invoices
│   │   └── ./publisher/places/subscriptions
│   └── ./publisher/settings
├── ./admin/*
│   ├── ./admin/home
│   ├── ./admin/events
│   ├── ./admin/my-events
│   ├── ./admin/fav-events
│   ├── ./admin/business
│   ├── ./admin/business-approval
│   ├── ./admin/communities
│   ├── ./admin/community-analysis
│   ├── ./admin/database
│   └── ./admin/settings
└── ./not-found

Routes ending with * do not go to a unique page, instead they go to a 404 page or
are rerouted to another existing page (usually a home-like one)

 */
