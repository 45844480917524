<PageTemplate>
  <ng-container bodyContent>
    <header [searcherActive]="false" [buttonActive]="true" [buttonAction]="'Crear nueva comunidad'" [permiso]="permiso">
    </header>
    <div class="comunidadesAdminsitradasGeneralContainer">
      <p>Comunidades administradas</p>
      <div class="comunidadesContainer">
        <mat-spinner *ngIf="!dataIsLoaded" class="big center"></mat-spinner>
        <ng-container *ngIf="dataIsLoaded">
          <p class="noData" *ngIf="comunidadesAdministradas.length == 0">No hay comunidades disponibles</p>
          <card [data]="community" [type]="'communityCard'" *ngFor="let community of comunidadesAdministradas "
            (confirmDelete)="deleteCommunity($event, community.id)"
            [routerLink]="['/publisher/communities-promotions/my-communities/community-details/'+community.id]"></card>
        </ng-container>
      </div>
    </div>
  </ng-container>
</PageTemplate>