<PageTemplate>
  <ng-container bodyContent>
    <div class="formHeader">
      <GeneralButton [buttonText]="'Cancelar'" (click)="cancel()"></GeneralButton>
      <div>
        <GeneralButton [buttonText]="'Atrás'" (click)="paso = paso - 1" [class.disabled]="paso == 0"></GeneralButton>
        <GeneralButton [buttonText]="'Siguiente'"  *ngIf="paso < 1" (click)="validateStep()"></GeneralButton>
        <GeneralButton [buttonText]="'Crear comunidad'"  *ngIf="paso == 1" (click)="validateStep();crearComunidad()"></GeneralButton>
      </div>
    </div>
    <steps [pasos]="pasos" [activo]="paso" (activoChange)="cambiarPaso($event)"></steps>
    <CreateEditCommunity-Step1 *ngIf="paso == 0" (dataToUpdate)="editarComunidad($event)"(data)="getDataFromStep1($event)" (validStep)="isValidStep1($event)" [receiveData]="dataPaso1"></CreateEditCommunity-Step1>
    <CreateEditCommunity-Step2 *ngIf="paso == 1" (dataToUpdate)="editarComunidad($event)"(data)="getDataFromStep2($event)" (validStep)="isValidStep2($event)" [receiveData]="dataPaso2"></CreateEditCommunity-Step2>
  </ng-container>
</PageTemplate>