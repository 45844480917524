import { Component, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { filter, map, takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from './models/unsubscribesDestroy';
import { LoginService } from './services/Api/login.service';
import { NotificacionesService } from './services/utils/notificaciones.service';
import { environment } from "../environments/environment";
import { initializeApp } from "firebase/app";
initializeApp(environment.firebaseConfig);
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { UserService } from './services/Api/user.service';
import { TokenManagmentService } from './services/utils/TokenManagment.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends UnsubscribesDestroy {
  listenerStart = false;
  title = 'af-notification';
  message: any = null;
  name: string = "";
  menu: Array<any> = [];
  breadcrumbList: Array<any> = [];
  currentRoute: string = ""
  constructor(private router: Router, private titleService: Title, private loginSE: LoginService, private notificaciones: NotificacionesService, private userSE: UserService) {
    super()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.currentRoute != "/redirect-app") {
      if (localStorage.getItem("tipoUser") == '["1"]' && this.mobileCheck()) {
        this.router.navigate(["/login"])
        this.notificaciones.showWarning("Esta página solo es accesible desde dispositivos de escritorio")
        this.loginSE.logOut();
      }
      if (localStorage.getItem("tipoUser") == '["0"]' || localStorage.getItem("tipoUser") == '["3"]' && this.mobileCheck()) {
        this.router.navigate(["/login"])
        this.notificaciones.showWarning("Esta página solo es accesible desde dispositivos de escritorio")
        this.loginSE.logOut();
      }
      if (localStorage.getItem("tipoUser") == '["2"]' && !this.mobileCheck()) {
        this.router.navigate(["/login"])
        this.notificaciones.showWarning("Esta página solo es accesible desde dispositivos móviles")
        this.loginSE.logOut();
      }
    }

  }
  mobileCheck() {
    let check = false;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      check = true
    }
    return check;
  };

  ngOnInit() {

    this.currentRoute = "";
    this.router.events.pipe(filter(event => event instanceof NavigationStart), map(event => event as NavigationStart), takeUntil(this._unsubInd6)).subscribe(res => {
      if (res.url.includes("deep")) {
        // location.reload();
        // alert(location.href)
        this.currentRoute = res.url;
        localStorage.setItem("deepRoute", location.href)
        this.router.navigate(["/redirect-app"])
      }
      this._unsubInd6.next("");
    });


    TokenManagmentService.listenerTokenLost().pipe(takeUntil(this._unsub)).subscribe(res => {
      this.notificaciones.showError("Lost security token, please log in again.")
      this.loginSE.logOut();
    })

    TokenManagmentService.listenerStartRefreshToken().pipe(takeUntil(this._unsub)).subscribe(res => {
      this.loginSE.refreshToken()
    })

    // Function to dynamically get the title of each route and output it to the browser near the favicon. This is essential for a good browser history experience
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;
          let routeTitle = '';
          while (route!.firstChild) {
            route = route.firstChild;
          }
          if (route.snapshot.data['title']) {
            routeTitle = route!.snapshot.data['title'];
          }
          return routeTitle;
        })
      )
      .subscribe((title: string) => {
        if (title) {
          this.titleService.setTitle(`Memorable - ${title}`);
        }
      });
    this.requestPermission();
    this.listen();
  }
  requestPermission() {
    const messaging = getMessaging();
    getToken(messaging,
      { vapidKey: environment.firebaseConfig.vapidKey }).then(
        (currentToken) => {
          if (currentToken) {
            localStorage.setItem("tokenPush", currentToken)
          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
  }
  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      this.message = payload;
      if (this.message["notification"]["title"] != undefined && this.message["notification"]["body"] == undefined) {
        this.notificaciones.showFeedBack(this.message["notification"]["title"])
        this.userSE.getMyUser();
        this.userSE.getResult().pipe(takeUntil(this._unsubInd3)).subscribe(value => {
          if (value == null) { return }
          localStorage.setItem("permisos", value.permisos)
          this._unsubInd3.next("");
          this.checkPermisos();
        })
      }
      if (this.message["notification"]["title"] != undefined && this.message["notification"]["body"] != undefined) {
        this.notificaciones.showFeedBack(this.message["notification"]["title"])
      }

    });
  }
  checkPermisos() {
    if (localStorage.getItem("tipoUser") == null || localStorage.getItem("tipoUser") == undefined || localStorage.getItem("tipoUser") == '["2"]') {
      this.loginSE.logOut();
      this.notificaciones.showError("No tienes permisos para acceder")
    } else {
      if (localStorage.getItem("tipoUser") == '["0"]' || localStorage.getItem("tipoUser") == '["3"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY") && !localStorage.getItem("permisos")?.includes("CONSULT_COMPANY_GROUP")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else {
          location.reload();
        }
      }
      if (localStorage.getItem("tipoUser") == '["1"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else {
          location.reload();
        }
      }
    }


  }
}
