import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { child } from 'firebase/database';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { TicketsService } from 'src/app/services/Api/tickets.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'app-validationTicketPopup',
  templateUrl: './validationTicketPopup.component.html',
  styleUrls: ['./validationTicketPopup.component.scss']
})
export class ValidationTicketPopupComponent extends UnsubscribesDestroy implements OnInit {

  constructor(public dialogRef: MatDialogRef<ValidationTicketPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private ticketSE: TicketsService, private notificaciones: NotificacionesService, private loader: LoaderService, private route: ActivatedRoute) { super() }
  data: any = null;
  dataIsLoaded = false
  eventoValido: Boolean = true;
  forceClosePopup(data: any = null) {
    this.dialogRef.close(data);
  }
  ngOnInit() {
    (document.querySelector(".mat-dialog-container") as HTMLElement).style.display = "none"
    if (this.modalData.redActiva) {
      this.ticketSE.validateTicket({ soldTicket: [this.modalData.qrInfo.soldTicketId] }, this.modalData.eventId);
      this.loader.open()
      this.ticketSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe(value => {
        if (value == null) { return }
        (document.querySelector(".mat-dialog-container") as HTMLElement).style.display = "initial"

        this.loader.closeSlow()
        if (value.message == "El Evento del ticket no coincide con el evento indicado.") {
          this.eventoValido = false;
        } else {
          this.data = value.data;
        }
        this.dataIsLoaded = true;
        this._unsubInd.next("");

      })
      this.ticketSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(value => {
        if (value == null) { return }
        this.loader.closeSlow()
        this.notificaciones.showError(value.error.message)
        this._unsub.next("");
        this.forceClosePopup({ stopFlag: false, id: this.modalData.qrInfo.soldTicketId });
      })
    }
  }

}
