<div class="calendarContainer">
  <div class="fechaInicio">
    <div class="calendar">
      <div class="month">
        <span *ngFor="let month of months; let i = index;" (click)="mesActual = i+1; goToDate()"
          [class.active]="mesActual == i+1">{{month}}</span>
      </div>
      <full-calendar #calendar [deepChangeDetection]="true" [options]="calendarOptionsInicio"></full-calendar>
    </div>
  </div>
</div>