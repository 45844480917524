import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { EventService } from 'src/app/services/Api/event.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { PrService } from 'src/app/services/Api/pr.service';
import { UserService } from 'src/app/services/Api/user.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'app-enlace-invitacion',
  templateUrl: './enlace-invitacion.component.html',
  styleUrls: ['./enlace-invitacion.component.scss']
})
export class EnlaceInvitacionComponent extends UnsubscribesDestroy implements OnInit {

  invitacion: Boolean = false;
  pr: any = null
  dataLoaded: Boolean = false;
  manager: number | null = null;
  constructor(private activatedRoute: ActivatedRoute, private notificaciones: NotificacionesService,  private route: ActivatedRoute,private router: Router, private loginSE: LoginService,  private loaderSE: LoaderService, private prSE: PrService, private userSE: UserService) {
    super();
  }

  ngOnInit() {
    if (!this.loginSE.isLogin()) {
      this.router.navigate(["/pr-register-link"], { queryParams: {manager: Number(this.route.snapshot.params['id'] ), dcmpny: sessionStorage.getItem("dcmpny")}})
    } else {
      sessionStorage.removeItem("savedRoute")
      if (this.route.snapshot.params['id'] != null || this.route.snapshot.params['id'] != undefined) {
        this.invitacion = true;
        this.prSE.getPRById(this.route.snapshot.params['id'])
        this.prSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value=>{
          if(value == null){return}
          this.pr = value;
          this.dataLoaded = true;
          this._unsubInd.next("")
          setTimeout(() => {
            this.getMyData();

          }, 500);
        })
        this.prSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value=>{
          if(value == null){return}
          this.notificaciones.showError(value.error.message)

          this._unsub.next("")
        })
      }
    }
  }
  getMyData() {
    this.userSE.getMyUser();

    this.userSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return }

      sessionStorage.setItem("nmsr", value.id)
      sessionStorage.setItem("dcmpny", value.companyId)
      sessionStorage.setItem("prId", value.prId)
      this._unsubInd.next("");
    })
  }
  shareLink(){
    this.notificaciones.showFeedBack("Enlace de invitación copiado correctamente")
    if (location.host.includes("localhost")) {
      return navigator.clipboard.writeText("http://"+location.host +"/rrpp/compartir-perfil/"+sessionStorage.getItem("prId"));
    }else{
      return navigator.clipboard.writeText("https://"+location.host +"/rrpp/compartir-perfil/"+sessionStorage.getItem("prId"));
    }
  }
  rechazar(){
    this.notificaciones.showFeedBack("Has rechazado la petición")
    setTimeout(() => {
      this.router.navigate(["/rrpp/home"])
    }, 500);
  }
  aceptar(manager:any = null){
    if(this.manager == null){
      this.prSE.addSubordinate({manager: Number(this.route.snapshot.params['id'] ), publicRelation: Number(sessionStorage.getItem("prId"))})
    }else{
      this.prSE.addSubordinate({manager: manager, publicRelation: Number(sessionStorage.getItem("prId"))})

    }
    this.prSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe(value=>{
      if(value == null){return}
      this.notificaciones.showFeedBack("Te has unido al equipo de"+ this.pr.user.name+" "+this.pr.user.surname)
      setTimeout(() => {
        this.router.navigate(["/rrpp/home"])
      }, 500);
      this._unsubInd2.next("")
    })
    this.prSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value=>{
      if(value == null){return}
      this.notificaciones.showError(value.message.error)
      this._unsub.next("")
    })
  }
}
