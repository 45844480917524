<div class="newTicketGeneralContainer">
  <div class="headerContainer">
    <p>Equipo de {{modalData.nombrePR}}</p>
    <svg (click)="forceClosePopup()" xmlns="http://www.w3.org/2000/svg" width="18.243" height="18.243"
      viewBox="0 0 18.243 18.243">
      <g id="Grupo_11743" data-name="Grupo 11743" transform="translate(1.734 2.121)">
        <line id="Línea_323" data-name="Línea 323" x2="14" y2="14" transform="translate(0.387)" fill="none"
          stroke="#070c5b" stroke-linecap="round" stroke-width="3" />
        <line id="Línea_324" data-name="Línea 324" x1="14" y2="14" transform="translate(0.387)" fill="none"
          stroke="#070c5b" stroke-linecap="round" stroke-width="3" />
      </g>
    </svg>
  </div>
  <div class="bodyContainer">
    <div class="level" *ngFor="let level of levels">
      <div class="levelHeader">
        <span>Nivel {{level.nivel}}</span>
      </div>
      <div class="levelBody">
        <div class="equipo" *ngFor="let equipo of level.equipo">
          <img *ngIf="equipo.user.profile_image != null"  onerror="this.src='/assets/images/placeholder.jpg'" [src]="equipo.user.profile_image" alt="">
          <img *ngIf="equipo.user.profile_image == null" src="/assets/images/defaultImagePerfil.png" alt="">
          <span>{{equipo.user.name}} {{equipo.user.surname}}</span>
        </div>
      </div>
      <!-- <div>
        {{level.nivel}}
        <span *ngFor="let equipo of level.equipo">
          {{equipo.user.name}}
        </span>
      </div>
      <br> -->
    </div>
  </div> 
</div>