import { Component, OnInit } from '@angular/core';
import { sha256 } from 'js-sha256';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { ChangePasswordPopupComponent } from 'src/app/popups/changePasswordPopup/changePasswordPopup.component';
import { UserService } from 'src/app/services/Api/user.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PopupService } from 'src/app/services/utils/popup.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-changePassword',
  templateUrl: './changePassword.component.html',
  styleUrls: ['./changePassword.component.scss']
})
export class ChangePasswordComponent extends UnsubscribesDestroy implements OnInit {
  urlImages = environment.urlImages
  constructor(private notificaciones: NotificacionesService, private loader: LoaderService, private userSE: UserService, private popupSE: PopupService) {
    super();
  }
  image = ""
  nombre = ""
  apellidos = ""
  genero = ""
  fechaNacimiento = ""
  telefono = ""
  email = "";
  password = "";
  repeatPassword = "";
  fotoPerfil = ""
  nombreVacio: boolean = false
  apellidosVacio: boolean = false
  generoVacio: boolean = false
  fechaNacimientoVacio: boolean = false
  telefonoVacio: boolean = false
  emailVacio: boolean = false;
  passwordVacio: boolean = false;
  repeatPasswordVacio: boolean = false;

  ngOnInit() {
    this.getMyData()
  }
  getMyData(loaderActivo: boolean = true) {
    this.userSE.getMyUser();
    if (loaderActivo) {
      this.loader.open()
    }
    this.userSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return }
      this.nombre = value.name
      this.apellidos = value.surname
      this.genero = value.gender
      this.telefono = value.phone
      this.fechaNacimiento = value.birthDate
      this.email = value.email
      this.fotoPerfil = value.profileImage
      sessionStorage.setItem("profileImage",value.profileImage)
      if (loaderActivo) {
        this.loader.closeSlow();
      }
      this._unsubInd.next("");
    })
  }
  updateUser(element: any, data: any) {
    var usuario = {};
    usuario[element] = data;

    if (this.validateData(element, data)) {
      this.userSE.updateUser(sessionStorage.getItem("nmsr"), usuario);
    }
    this.userSE.getResultUpdate().pipe(takeUntil(this._unsubInd2)).subscribe(value => {
      if (value == null) { return }
      this.notificaciones.showFeedBack(value.message)
      this.getMyData(false);
      this._unsubInd2.next("");
    })
    this.userSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    })
  }
  validateData(element, data) {
    var valido = true;
    if (element == "name") {
      if (data == "") {
        this.notificaciones.showError("No pueden haber campos vacios")
        this.nombreVacio = true;
        valido = false;
        return false;
      } else {
        this.nombreVacio = false;
        valido = true;

      }
    }
    if (element == "surname") {
      if (data == "") {
        this.notificaciones.showError("No pueden haber campos vacios")
        this.apellidosVacio = true;
        valido = false;
      } else {
        this.apellidosVacio = false;
        valido = true;

      }
    }
    if (element == "gender") {
      if (data == "") {
        this.notificaciones.showError("No pueden haber campos vacios")
        this.generoVacio = true;
        valido = false;
      } else {
        this.generoVacio = false;
        valido = true;

      }
    }
    if (element == "birthDate") {
      if (data == "") {
        this.notificaciones.showError("No pueden haber campos vacios")
        this.fechaNacimientoVacio = true;
        valido = false;
      } else {
        this.fechaNacimientoVacio = false;
        valido = true;

      }
    }
    if (element == "phone") {
      if (data == "" || data == null) {
        this.notificaciones.showError("No pueden haber campos vacios")
        this.telefonoVacio = true;
        valido = false;
      } else {
        this.telefonoVacio = false;
        valido = true;

      }
    }
    return valido;
  }
  changeDate(event: any) {
    this.fechaNacimiento = event.value
    this.updateUser('birthDate', this.fechaNacimiento)
  }
  validEmail(email: string) {
    var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
    return String(email).search(filter) != -1;
  }
  openChangePassword() {
    this.popupSE.openPopup(ChangePasswordPopupComponent)
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd3)).subscribe(value => {
      if (value == null) { return }
      this.updateUser("password", sha256(value.returnValue.newPass))
      this._unsubInd3.next("")
      this.popupSE.clearData();
    })
  }
  setClick(element) {
    (document.querySelector(element) as HTMLElement).click();
  }
  setProfileImage(imagenPerfil){
    this.updateUser("profileImage", [{image: imagenPerfil[0], isPrincipal: true}])
  }
}
