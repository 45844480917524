import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { UserService } from 'src/app/services/Api/user.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'app-ValidarEmail',
  templateUrl: './ValidarEmail.component.html',
  styleUrls: ['./ValidarEmail.component.scss']
})
export class ValidarEmailComponent extends UnsubscribesDestroy implements OnInit {

  urlParams: any = null;
  myParam: any = null;
  mensaje: string = "Validando email..."

  constructor(private userSE: UserService, private loader: LoaderService, private notificaciones: NotificacionesService) {
    super();
  }

  ngOnInit() {
    this.urlParams = new URLSearchParams(window.location.search);
    this.myParam = this.urlParams.get('code');
    this.loader.open();
    this.validateEmail();
  }

  validateEmail() {
    this.userSE.validateEmail({ code: this.myParam });
    this.userSE.getResultIndividual().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (res == null) { return }
      this.mensaje = "Email validado correctamente"
      this.loader.closeSlow();

      this._unsubInd.next('')
    })
    this.userSE.getResultIndividualError().pipe(takeUntil(this._unsub)).subscribe((res: any) => {
      if (res == null) { return }
      this.notificaciones.showError(res.error.message)
      this.mensaje = "Error al validar el email"
      this.loader.closeSlow();
      this._unsub.next('')
    })
  }

}
