import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take, takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { InvoicesService } from 'src/app/services/Api/invoices.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { CompanyService } from 'src/app/services/Api/company.service';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
@Component({
  selector: 'app-invoices-page',
  templateUrl: './invoices-page.component.html',
  styleUrls: ['./invoices-page.component.scss']
})
export class InvoicesPageComponent extends UnsubscribesDestroy implements OnInit {

  date = new Date();
  actualYear = this.date.getFullYear();
  total = 0;
  totalImponible = 0
  totalIva = 0
  cabeceras = ["Número de la factura", "Fecha", "Base imponible", "IVA", "Total", ""]
  facturas: any = []
  iva = 0.21;
  company:any = null;
  facturaSeleccionada:any  = null
  dataLoaded = false;
  constructor(private loginSE: LoginService, private router: Router, private notificaciones: NotificacionesService, private invoicesSE: InvoicesService, private loader: LoaderService, private companySE: CompanyService) {
    super();
  }

  ngOnInit(): void {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      if (!localStorage.getItem("permisos")?.split(",").includes("4")|| localStorage.getItem("permisos") == null) {
        this.router.navigate(["/publisher/places"])
        this.notificaciones.showError("No tienes acceso a este recurso")
      }else{
        this.getInvoices();
        this.companySE.getCompanyById(Number(sessionStorage.getItem("dcmpny")));
        this.companySE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe(value => {
          if (value == null) { return }
          this.dataLoaded = true;
          this.company = value;
          this._unsubInd2.next("");
        })
      }

    
    }

  }

  addYear() {
    this.clearTotal();
    this.actualYear++;
    this.getTotal()
    this.getInvoices();
  }
  subctractYear() {
    this.clearTotal();
    this.actualYear--;
    this.getTotal()
    this.getInvoices();
  }
  openDocument(factura) {
    this.loader.open();
    setTimeout(() => {
      let data:any = document.getElementById("factura");
     html2canvas(data).then(canvas => {
       const contentDataURL = canvas.toDataURL('image/png', 1.0)
       let pdf = new jspdf('p', 'em', 'a4');
       pdf.addImage(contentDataURL, 'PNG', 0, 0, 0, 0);  
       pdf.save(factura.number);   
       this.loader.closeSlow();
     }); 
    }, 1000);
     
  
   
  }
  getTotal() {
    this.total = 0
    this.totalImponible = 0
    this.totalIva = 0
    this.facturas.map(element => {
      this.total += element.amount
      this.totalImponible += element.amount - (element.amount * this.iva)
      this.totalIva += (element.amount * this.iva)
    })
  }
  clearTotal() {
    this.total = 0;
    this.totalImponible = 0;
    this.totalIva = 0;
  }
  getInvoices() {
    this.invoicesSE.getInvoices(5000, 1, sessionStorage.getItem("dcmpny"), this.actualYear)
    this.loader.open();
    this.invoicesSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return; }
      this.loader.closeSlow()
      this.facturas = value;
      this.getTotal()
      this._unsubInd.next("")
    })
    this.invoicesSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return; }
      this.loader.closeSlow()
      this.notificaciones.showError(value.error.message)
      this._unsub.next("")
    })
  }
}
