import { Injectable } from '@angular/core';
import { BaseService } from './Base.service';
import { environment } from 'src/environments/environment';
import { initializeApp } from "firebase/app";
import { get, getDatabase, off, onChildAdded, onChildChanged, ref } from 'firebase/database';
import { BehaviorSubject, take } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { resultHttp } from 'src/app/models/resultHttp';
const firebaseConfig = environment.firebaseConfig
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
@Injectable({
  providedIn: 'root'
})
export class ChatService extends BaseService {
  private starCountRef: any;
  private urlFirebase = environment.urlFirebase
  constructor(protected http: HttpClient) {
    super();
  }
  sendMessage(idComunidad, message) {
    this.http
      .post(this.urlFirebase + '/community/' + idComunidad + '/messages', message)
      .subscribe({
        next: (data) => {
          this.sendNextResult('result', data)
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.sendMessage, args: [idComunidad, message] })
          this.checkStatusError(error);
        },
      });
  }

  removeMessages(idMessage, idComunidad) {
    this.http
      .delete(this.urlFirebase + '/community/' + idComunidad + "/", {body: {messageId: idMessage}})
      .subscribe({
        next: (data) => {
          this.sendNextResult('resultDelete', data)
        },
        error: (error) => {
          this.sendNextResult('resultDeleteError', error, { method: this.removeMessages, args: [idMessage, idComunidad] })
          this.checkStatusError(error);
        },
      });
  }
}
