import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-showInfoPopup',
  templateUrl: './showInfoPopup.component.html',
  styleUrls: ['./showInfoPopup.component.scss']
})
export class ShowInfoPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ShowInfoPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) { }
  data = [];
  tipo =""
  forceClosePopup() {
    this.dialogRef.close();
  }
  ngOnInit() {
    this.tipo = this.modalData.tipo 
  }

}
