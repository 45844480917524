import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/Api/login.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'app-add-campaign-page',
  templateUrl: './add-campaign-page.component.html',
  styleUrls: ['./add-campaign-page.component.scss']
})
export class AddCampaignPageComponent implements OnInit {

  nombreCampanya: String = ""
  nombreCampanyaError: Boolean = false;

  constructor(private loginSE: LoginService, private router: Router, private notificaciones: NotificacionesService) { }

  ngOnInit(): void {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      this.checkPermisos();
    }
  }

  getNombreCampnya(event) {
    this.nombreCampanya = event;
  }

  validateNombreCampanya() {
    if (this.nombreCampanya == "") {
      this.nombreCampanyaError = true;
    } else {
      this.nombreCampanyaError = false
    }
  }
  redirect() {
    this.validateNombreCampanya();
    if (!this.nombreCampanyaError) {
      this.router.navigate(['/publisher/communities-promotions/promotions/add-mailing-campaign'])
    } else {
      this.notificaciones.showError("Hay errores en los campos, porfavor revisalos")
    }
  }
  /**
  * PERMISOS
  */
  checkPermisos() {
    if (localStorage.getItem("tipoUser") == null || localStorage.getItem("tipoUser") == undefined || localStorage.getItem("tipoUser") == '["2"]') {
      this.loginSE.logOut();
      this.notificaciones.showError("No tienes permisos para acceder")
    } else {
      if (localStorage.getItem("tipoUser") == '["0"]' || localStorage.getItem("tipoUser") == '["3"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY") && !localStorage.getItem("permisos")?.includes("CONSULT_COMPANY_GROUP")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY") && localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY_GROUP")) {
          this.router.navigate(["/publisher/communities-promotions"])
          this.notificaciones.showError("No tienes permisos para acceder")
        }
      }
      if (localStorage.getItem("tipoUser") == '["1"]') {

        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY")) {
          this.notificaciones.showError("No tienes permisos para acceder")
          this.router.navigate(["/publisher/communities-promotions"])
        }
      }
    }


  }
}
