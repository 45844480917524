<PageTemplate>
  <ng-container bodyContent>
    <header [searcherActive]="false"></header>
    <main>
      <section class="info">
        <div class="disclaimer">
          <p>
            Estos datos se utilizan exclusivamente con fines fiscales o de
            contacto. No mostraremos estos datos a tus clientes.
          </p>
        </div>
        <ng-container *ngIf="activo == 0">
          <form>
            <div>
              <div class="dataContainer" [class.noPermision]="!editar">
                <label for="" [class.vacio]="razonSocialVacio">Razón social</label>
                <mat-spinner class="medium" *ngIf="!dataLoaded"></mat-spinner>
                <input *ngIf="dataLoaded" type="text" class="inputData" (change)="saveChanges('name', razonSocial)" [(ngModel)]="razonSocial" name="razonSocial"
                  placeholder="Razón social">
              </div>
              <div class="dataContainer" [class.noPermision]="!editar">
                <label for="" [class.vacio]="cifVacio">CIF del negocio</label>
                <mat-spinner class="medium" *ngIf="!dataLoaded"></mat-spinner>
                <input *ngIf="dataLoaded" type="text" class="inputData" (change)="saveChanges('cif', cif)" [(ngModel)]="cif" name="cif" placeholder="CIF del negocio">
              </div>
              <div class="dataContainer" [class.noPermision]="!editar">
                <label for="" [class.vacio]="cpVacio">Código postal</label>
                <mat-spinner class="medium" *ngIf="!dataLoaded"></mat-spinner>
                <input *ngIf="dataLoaded" type="text" class="inputData" (change)="saveChanges('cp', cp)" [(ngModel)]="cp" name="cp" placeholder="Codigo postal">
              </div>
              
            </div>
            <div>
              <div class="dataContainer" [class.noPermision]="!editar">
                <label for="" [class.vacio]="denominacionComercialVacio">Denominación comercial</label>
                <mat-spinner class="medium" *ngIf="!dataLoaded"></mat-spinner>
                <input *ngIf="dataLoaded" type="text" class="inputData" (change)="saveChanges('tradeName', denominacionComercial)"[(ngModel)]="denominacionComercial"
                  name="denominacionComercial" placeholder="Denominación comercial">
              </div>
              <div class="dataContainer" [class.noPermision]="!editar">
                <label for="" [class.vacio]="direccionFiscalVacio">Dirección fiscal del negocio</label>
                <mat-spinner class="medium" *ngIf="!dataLoaded"></mat-spinner>
                <input *ngIf="dataLoaded" type="text" class="inputData" (change)="saveChanges('taxAddress', direccionFiscal)" [(ngModel)]="direccionFiscal" name="direccionFiscal"
                  placeholder="Dirección fiscal del negocio">
              </div>
            </div>
          </form>
        </ng-container>
        
      </section>
    </main>
  </ng-container>
</PageTemplate>