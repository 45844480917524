import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-event-sales-weekly-chart',
  templateUrl: './event-sales-weekly-chart.component.html',
  styleUrls: ['./event-sales-weekly-chart.component.scss'],
})
export class EventSalesWeeklyChartComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  @Input() data: any = {}
  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    // Generic options
    font: { family: 'Rubik' },

    // Specific options
    layout: {
      padding: 20,
    },
    scales: {
      x: {
        ticks: {
          color: '#0e17b6',
          font: {
            weight: '700',
          },
        },
        stacked: true,
        grid: {
          lineWidth: 0,
        },
      },
      y: {
        ticks: {
          callback: (value) => `${value} €`,
          color: '#0e17b6',
          font: {},
        },
        stacked: true,
        grid: {
          color: '#dfe0f5',
          drawBorder: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        align: 'end',
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        yAlign: 'bottom',
        enabled: true,
        backgroundColor: '#0e17b6',
        padding: 14,
        displayColors: false,
        callbacks: {
          title: () => ``,
          label: function (context) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat('es-ES', {
                style: 'currency',
                currency: 'EUR',
              }).format(context.parsed.y);
            }
            return label;
          },
        },
      },
    },
  };
  public barChartType: ChartType = 'bar';
  public barChartPlugins = [DataLabelsPlugin];

  public barChartData: ChartData<'bar'> = {
    labels: [],
    datasets: [
      {
        data: [],
        barThickness: 40,
        backgroundColor: '#9FA2E2',
        hoverBackgroundColor: '#9FA2E2',
        borderWidth: 2,
        borderColor: '#9FA2E2',
        hoverBorderColor: '#000',
        borderSkipped: false,
      }
    ],
  };

  constructor() {
  }

  ngOnInit(): void {
    const date = (() => {
      const now = new Date();
      return new Date(now.setDate(now.getDate() - now.getDay() - -1));
    })();
    const week = Array(7).fill(date).map((date, i) => {
      if (i !== 0) {
        date.setDate(date.getDate() + 1);
      }
      return String(date.getDate());
    });
    this.barChartData.labels = week;
    for (let i = 0; i <  this.barChartData.labels.length; i++) {
      this.data.forEach(element => {
        if(element.date.split("-")[2] ==  this.barChartData.labels![i]){
          this.barChartData.datasets[0].data[i] = element.total
        }
      })
      
    }
  }
}
