<PageTemplate>
  <ng-container bodyContent>
    <div class="headerGeneral">
      <header [searcherActive]="false" [buttonActive]="false"></header>
      <ng-select [items]="years.reverse()" [(ngModel)]="yearSelected" [searchable]="true"
      [clearable]="false" [placeholder]="'Selecciona un año'" (change)="setYear(yearSelected)"></ng-select>
    </div>
    <div class="crmHeader">
      <div class="crmHeader-Top">
        <div class="crmHeader-TopLeft">
          <span>Nuevos clientes último mes</span>
          <mat-spinner class="small" *ngIf="!headerIsLoaded"></mat-spinner>
          <span *ngIf="headerIsLoaded">{{estadistica.lastMonth}}</span>
        </div>
        <div class="crmHeader-TopRight">
          <span>Nuevos clientes último año</span>
          <mat-spinner class="small" *ngIf="!headerIsLoaded"></mat-spinner>
          <span *ngIf="headerIsLoaded">{{estadistica.lastYear}}</span>
        </div>
      </div>
      <div class="crmHeader-Bottom">
        <div class="crmHeader-BottomLeft">
          <span>Total de clientes</span>
          <mat-spinner class="small" *ngIf="!headerIsLoaded"></mat-spinner>
          <span *ngIf="headerIsLoaded">{{estadistica.totalClientes}}</span>
        </div>
        <div class="crmHeader-BottomRight">
          <span>Total de clientes que reciben publicidad</span>
          <mat-spinner class="small" *ngIf="!headerIsLoaded"></mat-spinner>
          <span *ngIf="headerIsLoaded">{{estadistica.marketing}}</span>
        </div>
      </div>
    </div>
    <p class="title">Base de datos de clientes</p>
    <div class="graficasContainer">
      <div class="grafica">
        <div class="graficaHeader">
          <div>
            <span (click)="onSelectGenre()" [class.active]="isDemographyGenre">Sexo</span>
            <span (click)="onSelectAge()" [class.active]="isDemographyAge">Edad</span>
            <span (click)="onSelectInterests()" [class.active]="isDemographyInterests">Intereses</span>
          </div>
        </div>
        <div class="noData" *ngIf="!chartDataLoaded">
          <mat-spinner class="big center"></mat-spinner>
        </div>
        <ng-container *ngIf="chartDataLoaded">
          <ng-container *ngIf="isDemographyGenre">
            <p class="noData" *ngIf="diagramaSexo!.men == 0 && diagramaSexo!.women == 0">No hay
              datos para estos parámetros</p>
            <demographicChart [type]="'sexo'" [dataSexo]="diagramaSexo"
              *ngIf="diagramaSexo!.men > 0 || diagramaSexo!.women > 0"></demographicChart>
          </ng-container>
          <ng-container *ngIf="isDemographyAge">
            <p class="noData"
              *ngIf="diagramaEdad.catorce_a_dieciocho == 0 && diagramaEdad.cincuenta == 0 && diagramaEdad.cuarenta == 0 && diagramaEdad.dieciocho_a_veintiuno == 0 && diagramaEdad.treinta == 0 && diagramaEdad.veintiuno == 0">
              No hay datos para estos parámetros</p>
            <demographicChart [type]="'edad'" [dataEdad]="diagramaEdad"
              *ngIf="diagramaEdad.catorce_a_dieciocho != 0 || diagramaEdad.cincuenta != 0 || diagramaEdad.cuarenta != 0 || diagramaEdad.dieciocho_a_veintiuno != 0 || diagramaEdad.treinta != 0 || diagramaEdad.veintiuno != 0">
            </demographicChart>
          </ng-container>
          <ng-container *ngIf="isDemographyInterests">
            <p class="noData" *ngIf="diagramaCategories!.length == 0">No hay datos para estos
              parámetros</p>
            <demographicChart [type]="'categorias'" [dataInterest]="diagramaCategories"
              *ngIf="diagramaCategories!.length != 0"></demographicChart>
          </ng-container>
        </ng-container>
      </div>
      <div class="tabla">
        <p class="subtitle">Detalles del análisis</p>
        <div class="edadContainer">
          <div class="usuario">
            <div>
              <div></div>
              <span>De 14 a 18</span>
            </div>
            <mat-spinner class="small" *ngIf="!chartDataLoaded"></mat-spinner>
            <span *ngIf="chartDataLoaded">{{diagramaEdad.catorce_a_dieciocho}}</span>
          </div>
          <div class="usuario">
            <div>
              <div></div>
              <span>De 18 a 21</span>
            </div>
            <mat-spinner class="small" *ngIf="!chartDataLoaded"></mat-spinner>
            <span *ngIf="chartDataLoaded">{{diagramaEdad.dieciocho_a_veintiuno}}</span>
          </div>
          <div class="usuario">
            <div>
              <div></div>
              <span>Mayores de 21</span>
            </div>
            <mat-spinner class="small" *ngIf="!chartDataLoaded"></mat-spinner>
            <span *ngIf="chartDataLoaded">{{diagramaEdad.veintiuno}}</span>
          </div>
          <div class="usuario">
            <div>
              <div></div>
              <span>Mayores de 30</span>
            </div>
            <mat-spinner class="small" *ngIf="!chartDataLoaded"></mat-spinner>
            <span *ngIf="chartDataLoaded">{{diagramaEdad.treinta}}</span>
          </div>
          <div class="usuario">
            <div>
              <div></div>
              <span>Mayores de 40</span>
            </div>
            <mat-spinner class="small" *ngIf="!chartDataLoaded"></mat-spinner>
            <span *ngIf="chartDataLoaded">{{diagramaEdad.cuarenta}}</span>
          </div>
          <div class="usuario">
            <div>
              <div></div>
              <span>Mayores de 50</span>
            </div>
            <mat-spinner class="small" *ngIf="!chartDataLoaded"></mat-spinner>
            <span *ngIf="chartDataLoaded">{{diagramaEdad.cincuenta}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="centrosEducativosContainer">
      <div class="centrosEducativosHeader">
        <p>Centros educativos</p>
        <searcher [placeHolder]="'Busca aquí un centro'" [toFilter]="centrosBackUp" [type]="'centro'"
          (filtered)="filteredCentros($event)"></searcher>
      </div>
      <div class="centrosEducativosBody">
        <div class="centrosEducativos">
          <div class="cabecera">
            <span>Centro</span>
            <span>Localidad</span>
            <span>Estudiantes</span>
          </div>
          <div class="tablaCentros">
            <div class="noData" *ngIf="!dataIsLoaded">
              <mat-spinner  class="medium center"></mat-spinner>
            </div>
            <ng-container *ngIf="dataIsLoaded">
              <div class="filaCentro" [class.active]="centroSeleccionado == centro.id" *ngFor="let centro of centros"
                (click)="centroSeleccionado = centro.id; centroSeleccionadoAuxiliar = centro;getCentersGrados()">
                <span [title]="centro.name">{{centro.name}}</span>
                <span>{{centro.locality}}</span>
                <span>{{centro.estudiantes}} personas</span>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="gradosEducativos">
          <p class="noData" *ngIf="centroSeleccionadoAuxiliar == null">No hay grados disponibles</p>
          <ng-container *ngIf="centroSeleccionadoAuxiliar != null">
            <div class="cabecera">
              <span>{{centroSeleccionadoAuxiliar.name}}</span>
              <span>{{centroSeleccionadoAuxiliar.locality}}</span>
              <span>{{centroSeleccionadoAuxiliar.estudiantes}} estudiantes</span>
            </div>
            <div class="cabecera especial">
              <span>Curso</span>
              <span>Ciclo</span>
              <span>Usuario</span>
            </div>
            <div class="tablaCentros" [class.loaded] = "grados.length == 0">
              <mat-spinner class="big" *ngIf="grados.length == 0"></mat-spinner>
              <ng-container *ngIf="grados.length != 0">
                <div class="filaCentro" *ngFor="let grado of grados">
                  <span>{{grado.courses}}</span>
                  <span>{{grado.type}}</span>
                  <span>{{grado.student}} personas</span>
                </div>
              </ng-container>
            </div>
          </ng-container>

        </div>
      </div>
    </div>
  </ng-container>
</PageTemplate>
