<div class="pill-lg-soft">
  <div class="pill-title">
    <h5>{{ titulo }}</h5>
    <p>{{ descripcion }}</p>
  </div>
  <div class="pill-details">
    <small><a [routerLink]="enlace">Acceder</a></small>
  </div>
  <span class="icono" *ngIf="icono != ''"><img [src]="icono" /></span>
</div>
