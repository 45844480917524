<div class="confirmGenralContainer">
  <div class="header">
    <p>Invita a un usuario</p>
    <svg (click)="forceClosePopup()" xmlns="http://www.w3.org/2000/svg" width="20.242" height="20.242"
      viewBox="0 0 20.242 20.242">
      <g id="Grupo_1201" data-name="Grupo 1201" transform="translate(-5674.379 -12806.379)">
        <line id="Línea_323" data-name="Línea 323" x2="16" y2="16" transform="translate(5676.5 12808.5)" fill="none"
          stroke="#0e17b6" stroke-linecap="round" stroke-width="3" />
        <line id="Línea_324" data-name="Línea 324" x1="16" y2="16" transform="translate(5676.5 12808.5)" fill="none"
          stroke="#0e17b6" stroke-linecap="round" stroke-width="3" />
      </g>
    </svg>

  </div>
  <div class="body">

    <div class="selectorModule">
      <label for="name">Selecciona un ticket</label>
      <ng-select [items]="tickets" style="width:100%" [bindLabel]="'name'" [bindValue]="'id'" [(ngModel)]="ticketSelected" (change)="isComplete()" [placeholder]="'Selecciona un ticket'"
      [searchable]="true" [clearable]="false"></ng-select>
    </div>
    
    <div  class="selectorModule">
      <label for="name">Introduce el número de teléfono del usuario</label>
      <input type="text" placeholder="Introduce el número de teléfono del usuario" (change)="debounceCheckPhone()" [(ngModel)]="phone" class="inputData">
    </div>
    <mat-spinner class="small" *ngIf="isLoading"></mat-spinner>
    <div class="usuarioSeleccionado" *ngIf="isUserChecked">
      <p><b>Usuario seleccionado:</b></p>
      <span><b>{{ newUser.name }}</b></span>
      <span>{{ newUser.email }}</span>

    </div>





  </div>
  <div class="footer">
    <button type="button" (click)="forceClosePopup()">Cancelar</button>
    <button type="button" [class.disabled]="!isActive"  (click)="continueInviting()">Invitar</button>
  </div>
</div>