import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { CommunitiesService } from 'src/app/services/Api/communities.service';
import { UserService } from 'src/app/services/Api/user.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'CreateEditCommunity-Step2',
  templateUrl: './CreateEditCommunity-Step2.component.html',
  styleUrls: ['./CreateEditCommunity-Step2.component.scss']
})
export class CreateEditCommunityStep2Component extends UnsubscribesDestroy implements OnInit {

  urlInvitacion: string = ""
  filtro: string = "";
  isOpenLista: boolean = false;
  usuarios:any = []
  usuariosFiltrados: any = []
  usuariosInvitados: any = []
  editar = false
  usuarioFiltrado: any = {}
  openList = false;
  filtroCorreo = ""
  invitado = false;

  //ENVIO Y RECEPCION DE DATOS
  @Output() data = new EventEmitter<any>()
  @Output() validStep = new EventEmitter<Boolean>()
  @Output() dataToUpdate = new EventEmitter<any>()
  @Input() receiveData: any = null

  constructor(private notificaciones: NotificacionesService, private router: Router, public activatedRoute: ActivatedRoute,private loader: LoaderService, private userSE: UserService, private communititesSE: CommunitiesService) {
    super();
  }

  ngOnInit() {
    if (this.activatedRoute.snapshot.params["id"] != null || this.activatedRoute.snapshot.params["id"] != undefined) {
      this.editar = true;
      if (this.receiveData.code != null) {
       this.urlInvitacion = window.location.host+"/deep/comunity/"+this.receiveData.code;
      }else{
        this.urlInvitacion = window.location.host+"/deep/comunity/"+this.receiveData.id;

      }
    }
  }

  filtrarUsuarios(filtro?) {
    this.usuariosFiltrados = this.usuarios.filter(usuario => usuario.nombre.toLowerCase().includes(filtro.toLowerCase()))
  }
  openLista() {
    if (this.isOpenLista) {
      this.isOpenLista = false;
    } else {
      this.isOpenLista = true;
    }
  }
  invitarUsuario(usuario: any) {
    if (this.usuariosInvitados.length == 0) {
      this.usuariosInvitados.push(usuario)
    } else {
      if (!this.usuariosInvitados.includes(usuario)) {
        this.usuariosInvitados.push(usuario)
      } else {
        this.usuariosInvitados.splice(this.usuariosInvitados.indexOf(usuario), 1)
      }
    }
    // this.editCommunity('members', this.usuariosInvitados)
  }
  filterUser(email) {
    if (email != "") {
      this.userSE.filterUserByEmail(email);
      this.userSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe(value => {
        if (value == null) { return }
        this.openList = true;
        this.usuarioFiltrado = value
        this._unsubInd2.next("");
      })
      this.userSE.getResultError().pipe(takeUntil(this._unsubInd2)).subscribe(value => {
        if (value == null) { return }
        this.notificaciones.showWarning(value.error.message)
        this._unsubInd2.next("");
      })
    }

  }
  validarPaso2(){
    var step2Obj = {
      members: this.usuariosInvitados
    }
    this.data.emit(step2Obj)
    this.validStep.emit(true);
  }
}
