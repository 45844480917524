<section class="event-sales-global-monthly-chart">
  <div style="display: block">
    <canvas
      baseChart
      [data]="barChartData"
      [options]="barChartOptions"
      [plugins]="barChartPlugins"
      [type]="barChartType"
      height="420px"
      width="866px"
    >
    </canvas>
  </div>
  <div class="legend">
    <div class="legend-item">
      <span class="legend-item-icon icon-1"></span>
      <span class="legend-item-title">RRPP</span>
    </div>
    <div class="legend-item">
      <span class="legend-item-icon icon-2"></span>
      <span class="legend-item-title">Taquilla</span>
    </div>
    <div class="legend-item">
      <span class="legend-item-icon icon-3"></span>
      <span class="legend-item-title">Online</span>
    </div>
    <div class="legend-item">
      <span class="legend-item-icon icon-4"></span>
      <span class="legend-item-title">Invitaciones</span>
    </div>
  </div>
</section>
