<section class="demography-age-chart">
  <div>
    <div style="display: block">
      <canvas
        baseChart
        [data]="doughnutChartData"
        [type]="doughnutChartType"
        [options]="doughnutChartOptions"
        height="400px"
        width="400px"
      >
      </canvas>
    </div>
  </div>

  <div class="legend">
    <div class="legend-item">
      <span class="legend-item-icon icon-1"></span>
      <span class="legend-item-title">Menos de 18 años</span>
    </div>
    <div class="legend-item">
      <span class="legend-item-icon icon-2"></span>
      <span class="legend-item-title">Entre 18 y 21 años</span>
    </div>
    <div class="legend-item">
      <span class="legend-item-icon icon-3"></span>
      <span class="legend-item-title">Entre 21 y 30 años</span>
    </div>
    <div class="legend-item">
      <span class="legend-item-icon icon-4"></span>
      <span class="legend-item-title">Entre 30 y 40 años</span>
    </div>
    <div class="legend-item">
      <span class="legend-item-icon icon-4"></span>
      <span class="legend-item-title">Entre 40 y 50 años</span>
    </div>
    <div class="legend-item">
      <span class="legend-item-icon icon-6"></span>
      <span class="legend-item-title">Más de 50 años</span>
    </div>
  </div>
</section>
