import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'dateSteps',
  templateUrl: './Date-Steps.component.html',
  styleUrls: ['./Date-Steps.component.scss']
})
export class DateStepsComponent implements OnInit {

  @Input() steps:Array<String> = []
  @Output() stepSelected = new EventEmitter<Number>();
  selectedStep: Number = 0;

  constructor() { }

  ngOnInit() {
  }

  setSelectedStep(stepNumber:Number){
    this.selectedStep = stepNumber;
    this.stepSelected.emit(this.selectedStep)
  }
}
