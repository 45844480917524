import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { EventService } from 'src/app/services/Api/event.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'EventDetails-Step2',
  templateUrl: './EventDetails-Step2.component.html',
  styleUrls: ['./EventDetails-Step2.component.scss']
})
export class EventDetailsStep2Component extends UnsubscribesDestroy implements OnInit {
  @Input() event:any = null
  @Input() headerImage:string = ''

  @Output() refreshEvent:EventEmitter<boolean> = new EventEmitter<boolean>()
  
  urlImages = environment.urlImages
  longitud = 255;
  fileImagen: string = ""
  headerImageModel: string = ""

  constructor(private notificaciones:NotificacionesService, private eventsSE: EventService, private router: Router) {
    super();
  }

  ngOnInit() {
  }

  checkIsToSend(file: any) {
    this.editarEvento("images", [{ image: file[0], isPrincipal: false }])
  }

  sendLink() {
    this.notificaciones.showFeedBack("Enlace de evento copiado correctamente")
    if (location.host.includes("localhost")) {
      return navigator.clipboard.writeText(environment.ticketera + this.event.id);
    }else{
      return navigator.clipboard.writeText(environment.ticketera + this.event.id);
    }
  }

  // Get the header Image

  sendHeaderImage(img: any) {
    this.editarEvento("images", [{image:img[0], isPrincipal: false, isHeader: true}])
  }

  editarEvento(element: any, data: any) {
    var evento = {};
    evento[element] = data;
    this.eventsSE.updateEventById(this.event.id, evento);
    this.eventsSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return; }
      this.notificaciones.showFeedBack(value.message);
      this.refreshEvent.emit(true);
      this._unsubInd.next("")
    })
    this.eventsSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return; }
      this.notificaciones.showError(value.error.message);
      this._unsub.next("")
    })
  }
  // getEvent() {
  //   this.eventsSE.getEventById(this.event.id);
  //   this.eventsSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe(value => {
  //     if (value == null) { return }
  //     this.event = value;
  //     this._unsubInd2.next("");
  //   })
  //   this.eventsSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
  //     if (value == null) { return }
  //     this.notificaciones.showError(value.error.message);
  //     this._unsub.next("");
  //   })
  // }
}
