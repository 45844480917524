import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { Fecha } from 'src/app/models/Fecha';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { EventService } from 'src/app/services/Api/event.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { UserService } from 'src/app/services/Api/user.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'app-MyEvents',
  templateUrl: './MyEvents.component.html',
  styleUrls: ['./MyEvents.component.scss']
})
export class MyEventsComponent extends UnsubscribesDestroy implements OnInit {
  events: Array<any> = [];
  eventSelected: Number | null = null
  fecha = new Date();
  eventosSeleccionados: any = [];
  eventsList: Array<any> = []
  editar: Boolean = false;
  dataLoaded: Boolean = false;
  constructor(private loginSE: LoginService, private userSE: UserService, private loader: LoaderService, private eventsSE: EventService, private notificaciones: NotificacionesService,) {
    super();
  }

  ngOnInit() {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      this.checkPermisos();
    }
  }
  checkPermisos() {
    if (localStorage.getItem("tipoUser") == null || localStorage.getItem("tipoUser") == undefined || localStorage.getItem("tipoUser") == '["2"]') {
      this.loginSE.logOut();
      this.notificaciones.showError("No tienes permisos para acceder")
    } else {
      if (localStorage.getItem("tipoUser") == '["0"]' || localStorage.getItem("tipoUser") == '["3"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY") && !localStorage.getItem("permisos")?.includes("CONSULT_COMPANY_GROUP")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else {
          if (localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY") && localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY_GROUP")) {
            this.editar = true;
          }
          this.getEvents();

        }
      }
      if (localStorage.getItem("tipoUser") == '["1"]') {

        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else {
          // this.getEvents();
          if (localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY")) {
            this.editar = true;
          }
          this.getEvents();
        }
      }
    }


  }
  cambiarMes(fecha: Date){
    this.getEvents(fecha);
  }
  clickFecha(fecha: Date) {
    this.fecha = fecha;
    this.eventosSeleccionados = []
    this.events.map(element => {
      var d2Aux = new Date(element.startDate)
      var d2: Fecha = new Fecha();
      d2.dia = d2Aux.getDate();
      d2.mes = d2Aux.getMonth() + 1
      d2.year = d2Aux.getFullYear()

      if (d2.dia == fecha.getDate() && d2.mes == (fecha.getMonth() + 1) && d2.year == fecha.getFullYear()) {
        this.eventosSeleccionados.push(element)
      }

    })
  }
  lateralScrollingWithWheel() {
    (document.querySelector(".events ul") as HTMLElement).addEventListener("wheel", (evt) => {
      evt.preventDefault();
      (document.querySelector(".events ul") as HTMLElement).scrollLeft += evt.deltaY;

    })
  }
  touchScroll() {
    var slider = document.querySelector(".events ul") as HTMLElement;
    let isDown = false;
    let startX: any;
    let scrollLeft: any;
    slider.addEventListener('mousedown', (e) => {
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
      cancelMomentumTracking();
    });

    slider.addEventListener('mouseleave', () => {
      isDown = false;
      slider.classList.remove('active');
    });

    slider.addEventListener('mouseup', () => {
      isDown = false;
      slider.classList.remove('active');
      beginMomentumTracking();
    });

    slider.addEventListener('mousemove', (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      var prevScrollLeft = slider.scrollLeft;
      slider.scrollLeft = scrollLeft - walk;
      velX = slider.scrollLeft - prevScrollLeft;
    });

    slider.addEventListener('wheel', (e) => {
      cancelMomentumTracking();
    });

    var velX = 0;
    var momentumID: any;

    function beginMomentumTracking() {
      cancelMomentumTracking();
      momentumID = requestAnimationFrame(momentumLoop);
    }
    function cancelMomentumTracking() {
      cancelAnimationFrame(momentumID);
    }
    function momentumLoop() {
      slider.scrollLeft += velX;
      velX *= 0.95;
      if (Math.abs(velX) > 0.5) {
        momentumID = requestAnimationFrame(momentumLoop);
      }
    }
  }
  filteredEvents(event) {
    this.eventsList = event;
  }
  eventsLoaded = false;
  fechaAnt:Date|null = null;
  getEvents(fecha?: Date) {
    if(fecha != undefined){
      var today = fecha;
    }else{
      var today = new Date();
    }
    if(this.fechaAnt != null){
      if(this.fechaAnt > today){
        this.fechaAnt = today;
      }else{
        return;
      }
    }else{
      this.fechaAnt = today;
    }
    this.eventsLoaded = false;
    this.events = []
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    this.eventsSE.getEvents(50000, 1, date)
    this.eventsSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return; }
      this.events = value;
      this.eventsList = value;
      this.dataLoaded = true;
      this.eventsLoaded = true;
      this._unsubInd.next("")
      setTimeout(() => {
        this.clickFecha(new Date(today.toISOString()))
        this.touchScroll();
        this.lateralScrollingWithWheel()
      }, 500);


    })
    this.eventsSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return; }
      this.notificaciones.showError(value.error.message)
      this._unsub.next("")
    })

  }


}
