import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { CompanyService } from 'src/app/services/Api/company.service';
import { CRMService } from 'src/app/services/Api/CRM.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
@Component({
  selector: 'app-AdminCustomerDatabase',
  templateUrl: './AdminCustomerDatabase.component.html',
  styleUrls: ['./AdminCustomerDatabase.component.scss']
})
export class AdminCustomerDatabaseComponent extends UnsubscribesDestroy implements OnInit {

  //VARIABLES
  nombreCompanyia: String = ""
  diagramaSexo: any = null;
  diagramaEdad: any = {
    catorce_a_dieciocho: 0,
    dieciocho_a_veintiuno: 0,
    veintiuno: 0,
    treinta: 0,
    cuarenta: 0,
    cincuenta: 0
  };
  diagramaCategories: any = null
  estadistica: any = null;
  centros: any = []
  centrosBackUp: any = []
  centroSeleccionado: any = -1;
  centroSeleccionadoAuxiliar: any = null
  grados: any = []
  optionSelected: any = null;
  companies: Array<any> = []
  //VARIABLES DE CONTROL
  isDemographyGenre: boolean = false;
  isDemographyAge: boolean = true;
  isDemographyInterests: boolean = false;
  chartDataLoaded: Boolean = false;
  headerIsLoaded: Boolean = false;
  dataIsLoaded: Boolean = false;

  yearSelected: any = new Date().getFullYear();
  years: any = []
  constructor(private companySE: CompanyService, private loader: LoaderService, private notificaciones: NotificacionesService, private loginSE: LoginService, private crmSE: CRMService, private companiesSE: CompanyService) {
    super();
  }

  ngOnInit() {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {

      this.checkPermisos();
    }
  }

  //FUNCIONALIDADES
  onSelectGenre() {
    this.isDemographyGenre = true;
    this.isDemographyAge = false;
    this.isDemographyInterests = false;
  }

  onSelectAge() {
    this.isDemographyGenre = false;
    this.isDemographyAge = true;
    this.isDemographyInterests = false;
  }

  onSelectInterests() {
    this.isDemographyGenre = false;
    this.isDemographyAge = false;
    this.isDemographyInterests = true;
  }

  filteredCentros(event) {
    this.centros = event;
  }
  setCompany() {
    if (this.optionSelected != null) {
      this.getCRMHeaderCompany()
    } else {
      this.getCRMHeader()
    }
  }

  //LLAMADAS A LA API
  getAllCompanies() {
    this.companySE.getAllCompaniesOfGroup();
    this.companySE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return }

      this.companies = value;
      this._unsubInd.next("");
    })
    this.companySE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.loader.closeSlow();
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    })
  }
  getCRMHeader() {
    this.crmSE.getHeaderCRMGroup();
    this.crmSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe(value => {
      if (value == null) { return }

      this.estadistica = value;
      this.headerIsLoaded = true;
      this._unsubInd2.next("");
      setTimeout(() => {
        this.getCompanyGroupDiagram()

      }, 500);
    })
    this.crmSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.loader.closeSlow();
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    })
  }
  getCRMHeaderCompany() {
    this.crmSE.getHeaderCRMCompany(this.optionSelected);
    this.crmSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe(value => {
      if (value == null) { return }

      this.estadistica = value;
      this.headerIsLoaded = true;
      this._unsubInd2.next("");
      setTimeout(() => {
        this.getCRMDiagram()
      }, 500);
    })
    this.crmSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.loader.closeSlow();
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    })
  }
  getCompanyGroupDiagram() {
    this.crmSE.getCompanyGroupDiagram();
    this.crmSE.getResult().pipe(takeUntil(this._unsubInd6)).subscribe(value => {
      if (value == null) { return }
      this.diagramaSexo = value.sex
      this.diagramaEdad = value.age
      this.diagramaCategories = value.categories
      this.chartDataLoaded = true;
      setTimeout(() => {
        this.getCenters()

      }, 500);
      this._unsubInd6.next("");
    })
    this.crmSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.loader.closeSlow();
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    })
  }
  getCRMDiagram() {
    this.crmSE.getCompanyDiagram(this.optionSelected);
    this.crmSE.getResult().pipe(takeUntil(this._unsubInd3)).subscribe(value => {
      if (value == null) { return }
      this.diagramaSexo = value.sex
      this.diagramaEdad = value.age
      this.diagramaCategories = value.categories
      this.chartDataLoaded = true;
      setTimeout(() => {
        this.getCenters()

      }, 500);
      this._unsubInd3.next("");
    })
    this.crmSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.loader.closeSlow();
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    })
  }
  getCenters() {
    this.crmSE.getCenters();
    this.crmSE.getResult().pipe(takeUntil(this._unsubInd4)).subscribe(value => {
      if (value == null) { return }

      this.loader.closeSlow();
      this.centros = value;
      this.centrosBackUp = value;
      this._unsubInd4.next("");
      this.dataIsLoaded = true;
    })
    this.crmSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.loader.closeSlow();
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    })
  }
  getCentersGrados() {
    this.crmSE.getCentersGrados(this.centroSeleccionado);
    this.crmSE.getResult().pipe(takeUntil(this._unsubInd5)).subscribe(value => {
      if (value == null) { return }
      this.loader.closeSlow();
      this.grados = value;
      this._unsubInd5.next("");
    })
    this.crmSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      this.loader.closeSlow();
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    })
  }
  checkPermisos() {
    if (localStorage.getItem("tipoUser") == null || localStorage.getItem("tipoUser") == undefined || localStorage.getItem("tipoUser") == '["2"]') {
      this.loginSE.logOut();
      this.notificaciones.showError("No tienes permisos para acceder")
    } else {
      if (localStorage.getItem("tipoUser") == '["0"]' || localStorage.getItem("tipoUser") == '["3"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY") && !localStorage.getItem("permisos")?.includes("CONSULT_COMPANY_GROUP")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else {
          this.getAllCompanies();
          this.getCRMHeader();
        }
      }
    }
  }
}
