import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-demography-interests-chart',
  templateUrl: './demography-interests-chart.component.html',
  styleUrls: ['./demography-interests-chart.component.scss'],
})
export class DemographyInterestsChartComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  @Input() data = {};
  keys:any = []
  // Include 'any' type in options to avoid library error
  public doughnutChartOptions: any['options'] = {
    responsive: true,
    cutout: '85%',
    // Generic options
    font: { family: 'Rubik' },

    // Specific options
    layout: {
      padding: 20,
    },
    scales: {
      x: {
        ticks: {
          display: false,
        },
        grid: {
          lineWidth: 0,
          drawBorder: false,
        },
      },
      y: {
        ticks: {
          display: false,
        },
        grid: {
          lineWidth: 0,
          drawBorder: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        caretSize: 0,
        enabled: true,
        backgroundColor: '#0e17b6',
        padding: 14,
        displayColors: false,
        callbacks: {},
      },
    },
  };
  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartPlugins = [DataLabelsPlugin];

  public doughnutChartData: ChartData<'doughnut'> = {
    labels: this.keys,
    datasets: [
      {
        data: [],
        backgroundColor: [
          '#CCCDDF',
          '#7A7FE6',
          '#5960E1',
          '#1017AF',
          '#686BB0',
          '#000669',
        ],
        hoverBackgroundColor: [
          '#CCCDDF',
          '#7A7FE6',
          '#5960E1',
          '#1017AF',
          '#686BB0',
          '#000669',
        ],
        borderWidth: 2,
        borderColor: ['#f5f6ff', '#f5f6ff', '#f5f6ff', '#f5f6ff', '#f5f6ff'],
        hoverBorderColor: ['#000', '#000', '#000', '#000', '#000', '#000'],
        hoverOffset: 4,
        borderRadius: 50,
        spacing: 2,
      },
    ],
  };

  constructor() {}

  ngOnInit(): void {
    this.keys = Object.keys(this.data)
    this.doughnutChartData.labels = this.keys
    this.keys.forEach(element => {
      this.doughnutChartData.datasets[0].data.push(this.data[element])
    });
    
    
  }
}
