import { ifStmt } from '@angular/compiler/src/output/output_ast';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs';
import { edadType } from 'src/app/models/edadType.enum';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { CategoriesService } from 'src/app/services/Api/categories.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'form-business-basic-information',
  templateUrl: './form-business-basic-information.component.html',
  styleUrls: ['./form-business-basic-information.component.scss', '../../admin-form-business.component.scss']
})
export class FormBusinessBasicInformationComponent extends UnsubscribesDestroy implements OnInit {

  //Initial values
  listIntereses: any[] = [];
  edadesMinima = [{ name: "Todos los públicos", value: "NO_RANGE" }, { name: "De 14 a 17", value: "RANGE_14_17" }, { name: "Más de 16", value: "RANGE_16" }, { name: "Más de 18", value: "RANGE_18" }, { name: "Más de 21", value: "RANGE_21" }, { name: "Más de 30", value: "RANGE_30" }, { name: "Más de 40", value: "RANGE_40" }, { name: "Más de 50", value: "RANGE_50" }];

  //Selected items
  selectedAge: any[] = [];
  selectedIntereses: any[] = [];
  interesesError: Boolean = false;
  //VARIABLES
  razonSocial: String = ""
  razonSocialError: Boolean = false;
  edadSeleccionada: String = ""
  edadSeleccionadaError: Boolean = false;
  @Output() dataPaso1 = new EventEmitter<any>();
  @Output() valido = new EventEmitter<boolean>();
  @Input() data:any = null 
  dataIsLoaded: boolean = false;
  constructor(private loginSE: LoginService, private categoriesSE: CategoriesService, private loader: LoaderService, private notificaciones: NotificacionesService) {
    super();
  }

  ngOnInit() {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      this.getCategoriesOfCompany();
      if (this.data != null) {
        this.setData();
      }
    }
  }

  toggleSelectedInteres(interes: any) {
    if (this.checkSelectedInteres(interes)) {
      //forced update
      this.selectedIntereses = [...this.selectedIntereses.filter(e => e != interes)];
    } else {
      this.selectedIntereses.push(interes);
    }
  }
  checkSelectedInteres(interes: any) {
    //TO DO filter by id when connect api
    return this.selectedIntereses.filter(e => e == interes).length != 0;
  }

  validarRazonSocial() {
    if (this.razonSocial == "") {
      this.razonSocialError = true;
    } else {
      this.razonSocialError = false;
    }
  }
  validarIntereses(){
    if (this.selectedIntereses.length == 0) {
      this.interesesError = true;
    }else{
      this.interesesError = false;
    }
  }
  validarEdades(){
    if (this.edadSeleccionada == "") {
      this.edadSeleccionadaError = true;
    }else{
      this.edadSeleccionadaError = false;
    }
  }
  validarPaso1() {
    this.validarRazonSocial();
    this.validarIntereses();
    this.validarEdades();
    if (!this.interesesError && !this.razonSocialError && !this.edadSeleccionadaError) {
      var paso1 = {
        name: this.razonSocial,
        categories: this.selectedIntereses,
        ageRange: this.edadSeleccionada
      }
      this.dataPaso1.emit(paso1)
      this.valido.emit(true);
    }else{
      this.valido.emit(false);
      this.notificaciones.showError("Se han detectado errores en los campos, porfavor revísalos")
    }
  }
  setData(){
    this.razonSocial = this.data.name;
    this.selectedIntereses = this.data.categories;
    this.edadSeleccionada = this.data.ageRange
  }
  //LLAMADAS A LA API
  getCategoriesOfCompany() {
    this.categoriesSE.getCategoriesFromCompany();
    this.categoriesSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return };
      this.listIntereses = value;
      this.dataIsLoaded = true;
      this._unsubInd.next("");
    })
    this.categoriesSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return };
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    })
  }
}
