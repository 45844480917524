import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';

@Component({
  selector: 'CreateEditEvent-Step2',
  templateUrl: './CreateEditEvent-Step2.component.html',
  styleUrls: ['./CreateEditEvent-Step2.component.scss']
})
export class CreateEditEventStep2Component implements OnInit {

  //VARIABLES DE CONTROL
  click: number = 0;
  today = new Date();
  editar: Boolean = false;
  //VARIABLES FORMULARIOS
  fechaInicio: Date | null= null
  fechaInicioError: Boolean = false;
  fechaFin: Date | null = null
  fechaFinError: Boolean = false;

  horaInicio: String = ""
  horaInicioError: Boolean = false;
  horaFin: String = ""
  horaFinError: Boolean = false;

  recurrencia: Number = 1

  rango: boolean = false;
  calendarioCargado: boolean = true;
  yearSelected: number = new Date().getFullYear();
  years: Array<number> = []
  //ENVIO Y RECEPCION DE DATOS
  @Output() data = new EventEmitter<any>()
  @Output() validStep = new EventEmitter<Boolean>()
  @Output() dataToUpdate = new EventEmitter<any>()
  @Input() receiveData: any = null;


  ngOnChanges(changes: SimpleChanges): void {
    if (this.receiveData != null) {
      this.receiveData = changes['receiveData'].currentValue;
      this.setData();
    }
  }
  ngOnInit() {
    this.generateArrayOfYears();
    if (this.route.snapshot.params['id'] != null || this.route.snapshot.params['id'] != undefined) {
      this.editar = true;
    }
  }
  constructor(private notificaciones: NotificacionesService, private route: ActivatedRoute) { }

  generateArrayOfYears() {
    var max = new Date().getFullYear()
    var min = max + 10

    for (var i = max; i <= min; i++) {
      this.years.push(i)
    }
  }

  //OBTENER DATOS Y SETEAR DATOS
  seleccionarFechaEvento(event) {
    this.click = this.click + 1;
    if (this.rango) {
      if (this.click % 2 != 0) {
        this.fechaInicio = event;
        this.validarFechas();
      }
      if (this.click % 2 == 0) {
        this.fechaFin = event;
        this.updateFechas();
      }
    }else{
      this.fechaInicio = event;
      this.fechaFin = event;
      this.validarFechas();
      if (!this.fechaInicioError) {
        this.updateFechas();
      }
    }


  }
  getHoraInicio(event) {
    this.horaInicio = event;
  }
  getHoraFin(event) {
    this.horaFin = event;
  }

  getRecurrencia(event) {
    this.calendarioCargado = false;
    this.recurrencia = event;
    if (event == 1) {
      this.rango = false
      this.fechaInicio = null
      this.fechaFin = null
      setTimeout(() => {
        this.calendarioCargado = true;
      }, 10);
    }else{
      this.rango = true;
      this.fechaInicio = null
      this.fechaFin = null
      setTimeout(() => {
        this.calendarioCargado = true;
      }, 10);
    }
    this.updateisMultipleDays();
  }
  setData(){
    this.yearSelected = new Date(this.receiveData.startDate).getFullYear()
    // let auxInicio = this.yearSelected+"-"+new Date(String(this.fechaInicio)).getMonth()+"-"+new Date(String(this.fechaInicio)).getDate()
    // let auxFin = this.yearSelected+"-"+new Date(String(this.fechaInicio)).getMonth()+"-"+new Date(String(this.fechaFin)).getDate()
    this.fechaInicio = new Date(this.receiveData.startDate)
    this.fechaFin = new Date(this.receiveData.endDate)
    this.horaInicio = this.receiveData.startTime
    this.horaFin = this.receiveData.finishTime
    if (this.receiveData.isMultipleDays) {
      this.recurrencia = 0;
    }else{
      this.recurrencia = 1;
    }
    this.rango = this.receiveData.isMultipleDays
    this.click = 0;

  }

  //VALIDACIONES
  validarFechas() {
    var d2 = new Date(this.yearSelected+"-"+(new Date(String(this.fechaInicio)).getMonth()+1)+"-"+new Date(String(this.fechaInicio)).getDate());
    var auxToday = this.today.getFullYear()+"-"+(this.today.getMonth()+1)+"-"+this.today.getDate()
    if (this.today.getFullYear() == this.yearSelected) {
      if (d2.getTime() < new Date(auxToday).getTime()) {
        this.notificaciones.showError("La fecha de inicio no puede ser inferior a la fecha actual")
        this.fechaInicioError = true;
        this.validStep.emit(false)
      } else {
        this.fechaInicioError = false;

      }
    }else{
      this.fechaInicioError = false;
    }

  }
  validarFechaInicio(){
    if (this.fechaInicio == null) {
      this.fechaInicioError = true;
    } else {
      this.fechaInicioError = false;
    }
  }
  validarFechaFin(){
    if (this.fechaFin == null) {
      this.fechaFinError = true;
    } else {
      this.fechaFinError = false;
    }
  }

  validarPaso2(){
    this.validarFechaInicio();
    this.validarFechaFin();
    this.validarFechas();
    if (!this.fechaInicioError && !this.fechaFinError) {
      let auxInicio = this.yearSelected+"-"+(new Date(String(this.fechaInicio)).getMonth()+1)+"-"+new Date(String(this.fechaInicio)).getDate()
      let auxFin = this.yearSelected+"-"+(new Date(String(this.fechaInicio)).getMonth()+1)+"-"+new Date(String(this.fechaFin)).getDate()
      var step2Obj = {
        startDate: auxInicio,
        endDate: auxFin,
        startTime: this.horaInicio,
        finishTime: this.horaFin,
      }
      if (this.recurrencia == 0) {
        step2Obj["isMultipleDays"] = true
      }else{
        step2Obj["isMultipleDays"] = false
      }
      this.data.emit(step2Obj)
      this.validStep.emit(true);
    }else{
      this.validStep.emit(false)
      this.notificaciones.showError("No pueden haber campos vacios")
    }
  }

  //LLAMADAS A LA API
  updateHoraInicio(event){
    if(this.editar){this.dataToUpdate.emit({ element: "startTime", value: event }) }
  }
  updateHoraFin(event){
    if(this.editar){this.dataToUpdate.emit({ element: "finishTime", value: event }) }
  }
  updateisMultipleDays(){
    if (this.recurrencia == 0) {
      if (this.editar) { this.dataToUpdate.emit({ element: "isMultipleDays", value: true }) }
    } else if (this.recurrencia == 1) {
      if (this.editar) { this.dataToUpdate.emit({ element: "isMultipleDays", value: false }) }
    }
  }
  updateFechas(){
    if (this.editar) {
      let auxInicio = this.yearSelected+"-"+(new Date(String(this.fechaInicio)).getMonth()+1)+"-"+new Date(String(this.fechaInicio)).getDate()
      let auxFin = this.yearSelected+"-"+(new Date(String(this.fechaFin)).getMonth()+1)+"-"+new Date(String(this.fechaFin)).getDate()
      this.validarFechas()
      if (!this.fechaInicioError) {
        this.dataToUpdate.emit([{ element: "startDate", value: auxInicio }, { element: "endDate", value: auxFin }])
      }

    }
  }
  // updateFechaFin(){
  //   if (this.editar) { ) }
  // }
}
