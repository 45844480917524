import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { ConfirmPopupComponent } from 'src/app/popups/confirmPopup/confirmPopup.component';
import { CampaignService } from 'src/app/services/Api/Campaign.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PopupService } from 'src/app/services/utils/popup.service';

@Component({
  selector: 'app-Audiencies',
  templateUrl: './Audiencies.component.html',
  styleUrls: ['./Audiencies.component.scss'],
})
export class AudienciesComponent extends UnsubscribesDestroy implements OnInit {
  permiso: boolean = false;
  num_dev: number = 9;
  num_pag: number = 1;
  isDataLoaded: boolean = false;
  paginationLoaded: boolean = false;
  audiencias: Array<any> = [];
  openeds: Array<number> = [];
  isLocalDataLoaded: boolean = true;
  updatingCampaign: number = -1;
  filtro: string = '';
  paginationActive: boolean = true;
  constructor(
    private loginSE: LoginService,
    private notificaciones: NotificacionesService,
    private router: Router,
    private route: ActivatedRoute,
    private campaignSE: CampaignService,
    private popupSE: PopupService
  ) {
    super();
  }

  ngOnInit() {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      this.checkPermisos();
      this.activatePagination();
    }
  }

  /**
   * GETTERS & SETTERS
   */

  getFilter(event: string) {
    this.filtro = event;
    if (this.filtro == '') {
      this.num_pag = 1;
      this.num_dev = 9;
      this.paginationActive = true;
    }
    this.getAudiences();
  }
  /**
   * FUNCIONALIDAD
   */

  controlOpened(id) {
    if (!this.openeds.includes(id)) {
      this.openeds.push(id);
    } else {
      this.openeds.splice(this.openeds.indexOf(id, 1));
    }
  }

  activatePagination() {
    let that = this;

    window.onscroll = function (ev) {
      if (that.audiencias.length > 0) {
        if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
          if (that.paginationActive) {
            that.num_pag++;
            that.getAudiences(true);
          }
        }
      }
    };
  }
  copyCampaign(campaign) {
    localStorage.setItem('campaignId', campaign.id);
    switch (campaign.type) {
      case 'Email':
        this.router.navigate([
          '/publisher/communities-promotions/promotions/add-campaign/mailing',
        ]);
        break;
      case 'Push':
        this.router.navigate([
          '/publisher/communities-promotions/promotions/add-campaign/push',
        ]);
        break;
    }
  }
  /**
   * CALL TO API
   */
  getAudiences(pagination: boolean = false) {
    if (this.filtro != '') {
      this.campaignSE.getAll(9000, 1, this.filtro);
    } else {
      this.campaignSE.getAll(this.num_dev, this.num_pag);
    }
    this.campaignSE
      .getResult()
      .pipe(takeUntil(this._unsub))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        if (!pagination) {
          this.audiencias = value;
        } else {
          if (value.length > 0) {
            value.forEach((element) => {
              if (!this.audiencias.includes(element)) {
                this.audiencias.push(element);
              }
            });
          } else {
            this.paginationActive = false;
          }
        }
        this.isDataLoaded = true;
        this._unsub.next('');
      });
  }
  updateCampaign(id: number, isChecked: boolean, campaign: any) {
    if (isChecked) {
      this.popupSE.openPopup(ConfirmPopupComponent, {
        accion: 'Desactivar',
        tipo: 'campaña',
      });
    } else {
      this.popupSE.openPopup(ConfirmPopupComponent, {
        accion: 'Activar',
        tipo: 'campaña',
      });
    }
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd5))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        let checked = isChecked ? false : true;
        this.isLocalDataLoaded = false;
        this.updatingCampaign = id;
        this.campaignSE.update(id, { isActive: checked });
        this.campaignSE
          .getResultUpdate()
          .pipe(takeUntil(this._unsubInd))
          .subscribe((res: any) => {
            if (res == null) {
              return;
            }
            this.notificaciones.showFeedBack(res.message);
            let indexOf = this.audiencias.indexOf(campaign);
            this.audiencias[indexOf] = undefined;
            this.audiencias[indexOf] = campaign;
            this._unsubInd.next('');
            campaign.is_active = checked;
            this.isLocalDataLoaded = true;
            this.updatingCampaign = -1;
          });
        this.campaignSE
          .getResultUpdateError()
          .pipe(takeUntil(this._unsubInd))
          .subscribe((res: any) => {
            if (res == null) {
              return;
            }
            this.notificaciones.showError(res.error.message);
            this._unsubInd.next('');
          });
        this._unsubInd5.next('');
      });
  }
  /**
   * PERMISOS
   */
  checkPermisos() {
    if (
      localStorage.getItem('tipoUser') == null ||
      localStorage.getItem('tipoUser') == undefined ||
      localStorage.getItem('tipoUser') == '["2"]'
    ) {
      this.loginSE.logOut();
      this.notificaciones.showError('No tienes permisos para acceder');
    } else {
      if (
        localStorage.getItem('tipoUser') == '["0"]' ||
        localStorage.getItem('tipoUser') == '["3"]'
      ) {
        if (localStorage.getItem('permisos') == 'null') {
          this.loginSE.logOut();
          this.notificaciones.showError('No tienes permisos para acceder');
        } else if (
          !localStorage.getItem('permisos')?.includes('CONSULT_COMPANY') &&
          !localStorage.getItem('permisos')?.includes('CONSULT_COMPANY_GROUP')
        ) {
          this.loginSE.logOut();
          this.notificaciones.showError('No tienes permisos para acceder');
        } else if (
          !localStorage.getItem('permisos')?.includes('CONSULT_EDIT_COMPANY') &&
          localStorage
            .getItem('permisos')
            ?.includes('CONSULT_EDIT_COMPANY_GROUP')
        ) {
          this.router.navigate(['/publisher/communities-promotions']);
          this.notificaciones.showError('No tienes permisos para acceder');
        } else {
          this.permiso = true;
          this.getAudiences();
        }
      }
      if (localStorage.getItem('tipoUser') == '["1"]') {
        if (localStorage.getItem('permisos') == 'null') {
          this.loginSE.logOut();
          this.notificaciones.showError('No tienes permisos para acceder');
        } else if (
          !localStorage.getItem('permisos')?.includes('CONSULT_COMPANY')
        ) {
          this.loginSE.logOut();
          this.notificaciones.showError('No tienes permisos para acceder');
        } else if (
          !localStorage.getItem('permisos')?.includes('CONSULT_EDIT_COMPANY')
        ) {
          this.notificaciones.showError('No tienes permisos para acceder');
          this.router.navigate(['/publisher/communities-promotions']);
        } else {
          this.permiso = true;
          this.getAudiences();
        }
      }
    }
  }
}
