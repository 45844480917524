import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Event } from 'src/app/models/Event';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit {
  @Output() salida = new EventEmitter<number>();

  constructor() {}
  @Input() events: Event[] = [];
  @Input() seleccoinado: number = -1;
  @Input() enlace: boolean = true;
  @Input() limite: boolean = false;
  ngOnInit(): void {
  }

  devolucion(id: number) {
    this.salida.emit(id);
  }
}
