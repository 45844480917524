<PageTemplate>
  <ng-container bodyContent>
    <header [searcherActive]="false"></header>
    <div class="master-container">
      <div class="invoices-page">
        <main>
          <div class="invoice-year">
            <a class="left-chevron" (click)="subctractYear()">
              <svg xmlns="http://www.w3.org/2000/svg" width="10.589" height="26.773" viewBox="0 0 10.589 26.773">
                <path id="Trazado_404" data-name="Trazado 404" d="M3967.894,9858.88l12-8,12,8"
                  transform="translate(-9849.678 3993.281) rotate(-90)" fill="none" stroke="#070c5b"
                  stroke-linecap="round" stroke-width="2" />
              </svg>
            </a>
            <h3>{{actualYear}}</h3>
            <a class="right-chevron" (click)="addYear()">
              <svg xmlns="http://www.w3.org/2000/svg" width="10.589" height="26.773" viewBox="0 0 10.589 26.773">
                <path id="Trazado_403" data-name="Trazado 403" d="M3967.894,9858.88l12-8,12,8"
                  transform="translate(9860.267 -3966.507) rotate(90)" fill="none" stroke="#070c5b"
                  stroke-linecap="round" stroke-width="2" />
              </svg>
            </a>
          </div>
          <section class="invoice-table">
            <table class="table-group-soft">
              <tr>
                <th *ngFor="let cabecera of cabeceras">{{cabecera}}</th>
              </tr>
              <tr *ngIf="!dataLoaded">
                <td></td>
                <td></td>
                <td class="totalIva"><mat-spinner class="big"></mat-spinner></td>
                <td></td>
                <td></td>
                <td>
                </td>
              </tr>
              <ng-container *ngIf="dataLoaded">
                <ng-container *ngFor="let factura of facturas">
                  <tr>
                    <td>{{factura.event}}</td>
                    <td> {{factura.creation_date | date: 'd' }} {{factura.creation_date | date: 'MMMM' }}</td>
                    <td class="totalIva">{{(factura.amount - (factura.amount*iva)) | currency:'EUR'}}</td>
                    <td>{{factura.amount*iva | currency:'EUR'}}</td>
                    <td>{{factura.amount | currency:'EUR'}}</td>
                    <td>
                      <button (click)="facturaSeleccionada = factura;openDocument(factura);">Ver factura</button>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
              
              <tr>
                <td class="totales">TOTALES</td>
                <td></td>
                <td class="totales">{{totalImponible | currency:'EUR'}}</td>
                <td class="totales">{{totalIva | currency:'EUR'}}</td>
                <td class="totales">{{total | currency:'EUR'}}</td>
                <td></td>
              </tr>
            </table>
          </section>
        </main>
      </div>
    </div>

    <div id="factura" *ngIf="dataLoaded && facturaSeleccionada != null" style="display: flex;flex-direction:column;">
      <div class="headerFacturaGeneralContainer" style="width: 60em;padding:4em;display:flex;align-items: center;">
        <div style="width: 50%;display:flex;justify-content:flex-start;flex-direction: column;gap:16px">
          <p style="font-size: 24px; font-weight: 600;">{{company.name}}</p>
          <p style="font-size: 16px; font-weight: 400; width: 70%;">{{company.description}}</p>
        </div>
        <div style="width: 50%;display:flex;align-items:center">
          <img src="/assets/icons/logoLinkPr.png" alt="">
        </div>
      </div>
      <div class="subHeader" style="padding-left: 4em;padding-right:4em;width: 30em;margin-bottom: 4em;">
        <div style="display: flex; flex-direction:column; gap: 16px;">
          <p><b style="font-weight: 500;">Número factura:</b> {{facturaSeleccionada.number}}</p>
          <p><b style="font-weight: 500;">Fecha:</b> {{facturaSeleccionada.creation_date.split(" ")[0]}}</p>
        </div>
      </div>
      <div class="subHeader" style="padding-left: 4em;padding-right:4em;width: 30em;margin-bottom: 4em;">
        <div style="display: flex; flex-direction:column; gap: 16px;">
          <p style="font-size: 20px; font-weight: 600; border-bottom: 1px solid; ">Cliente</p>
          <p><b style="font-weight: 500;">Nombre comercial:</b> {{company.trade_name}}</p>
          <p><b style="font-weight: 500;">Dirección:</b> {{company.address.street}}</p>
          <p><b style="font-weight: 500;">CIF:</b> {{company.cif}}</p>
        </div>
      </div>
      <div class="subHeader" style="padding-left: 4em;padding-right:4em; display: flex; justify-content: flex-start;">
        <table style="width: 42em;">
          <tr style="background-color: #e6e7f0; height: 4em;">
            <th style="text-align: left; padding-left: 2em; font-weight: 600; color: black;">Base imponible</th>
            <th style="text-align: left; font-weight: 600; color: black;">IVA (21%)</th>
            <th style="text-align: left; font-weight: 600; color: black;">Total</th>
          </tr>
          <tr style=" height: 4em;">
            <th style="text-align: left; padding-left: 2em; font-weight: 400; color: black;">
              {{(facturaSeleccionada.amount-(facturaSeleccionada.amount*0.21)) | currency:'EUR'}}</th>
            <th style="text-align: left; font-weight: 400; color: black;">
              {{(facturaSeleccionada.amount*0.21) | currency:'EUR'}}</th>
            <th style="text-align: left; font-weight: 400; color: black;">{{(facturaSeleccionada.amount) | currency:'EUR'}}
            </th>
          </tr>
        </table>
      </div>
    </div>
  </ng-container>
</PageTemplate>