import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, catchError } from 'rxjs';
import { Facility } from 'src/app/models/Facility';
import { environment } from 'src/environments/environment';
import { BaseService } from './Base.service';


@Injectable({
  providedIn: 'root',
})
export class StadisticsService extends BaseService {

  private urlApi = environment.urlApi;

  constructor(private http: HttpClient) {
    super();
  }

  getHomeStadistics(firstDate, secondDate) {
    this.http.get(this.urlApi + '/api/event/statistics/home/' + Number(sessionStorage.getItem("dcmpny")) + "/" + secondDate + "/" + firstDate, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getHomeStadistics, args: [firstDate, secondDate] })
        this.checkStatusError(error);
      },
    });
  }
  getEventStadisticsById(id) {
    this.http.get(this.urlApi + '/api/event/statistics/' + id, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getEventStadisticsById, args: [id] })
        this.checkStatusError(error);
      },
    });
  }
  getEventStadisticsDiagramHistorical(companyId, year) {
    this.http.get(this.urlApi + '/api/event/statistics/company/diagram/' + companyId + "/" + year, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultIndividual', data)
      },
      error: (error) => {
        this.sendNextResult('resultIndividualError', error, { method: this.getEventStadisticsDiagramHistorical, args: [companyId, year] })
        this.checkStatusError(error);
      },
    });
  }
  getEventStadisticsDiagram(eventId) {
    this.http.get(this.urlApi + '/api/event/statistics/diagram/' + eventId, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getEventStadisticsDiagram, args: [eventId] })
        this.checkStatusError(error);
      },
    });
  }
  getTableHomeStadistics(fechaInicio, fechaFin, tipo, anual = false) {
    this.http.get(this.urlApi + '/api/event/statistics/table/' + fechaFin + '/' + fechaInicio + "/" + tipo + "/" + sessionStorage.getItem("dcmpny") + "/" + anual, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultIndividual', data)
      },
      error: (error) => {
        this.sendNextResult('resultIndividualError', error, { method: this.getTableHomeStadistics, args: [fechaInicio, fechaFin, tipo, anual] })
        this.checkStatusError(error);
      },
    });
  }
  getTopEvents(eventId, year) {
    this.http.get(this.urlApi + '/event/top/' + Number(sessionStorage.getItem("dcmpny")) + '/' + year + "/5", this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getTopEvents, args: [Number(sessionStorage.getItem("dcmpny")), year] })
        this.checkStatusError(error);
      },
    });
  }
  getHistoricalSales(year, numPag) {
    this.http.get(this.urlApi + '/api/event/statistics/historical/table/sales/' + year + "/" + sessionStorage.getItem("dcmpny") + "?num_devoluciones=12&num_pagina=" + numPag, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getHistoricalSales, args: [year, numPag] })
        this.checkStatusError(error);
      },
    });
  }
  getHistoricalAssist(year, numPag) {
    this.http.get(this.urlApi + '/api/event/statistics/historical/table/assist/' + year + "/" + sessionStorage.getItem("dcmpny") + "?num_devoluciones=5000&num_pagina=" + numPag, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getHistoricalAssist, args: [year, numPag] })
        this.checkStatusError(error);
      },
    });
  }
  getTopRRPP(idCompany) {
    this.http.get(this.urlApi + '/api/public/relation/company/' + idCompany, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getTopRRPP, args: [idCompany] })
        this.checkStatusError(error);
      },
    });
  }
  getPerformanceByRRPP(semana, ventas, idRRPP) {
    this.http.get(this.urlApi + '/api/public/relation/table/' + semana + '/' + ventas + "/" + idRRPP, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getPerformanceByRRPP, args: [semana, ventas, idRRPP] })
        this.checkStatusError(error);
      },
    });
  }

  getTableEventStadistics(idEvento, firstDate, lastDate, tipo, anual = false) {
    this.http.get(this.urlApi + '/api/statistics/table/' + idEvento + '/' + lastDate + '/' + firstDate + '/' + tipo + '/' + anual, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultIndividual', data)
      },
      error: (error) => {
        this.sendNextResult('resultIndividualError', error, { method: this.getTableEventStadistics, args: [idEvento, firstDate, lastDate, tipo, anual] })
        this.checkStatusError(error);
      },
    });
  }
  getRRPPStadistics(idRRPP, event: any = null) {
    let url = ""
    event == null ? url = '/api/public/relation/home/app/' + idRRPP : url = '/api/public/relation/home/app/' + idRRPP + "?event=" + event;
    this.http.get(this.urlApi + url, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultIndividual', data)
      },
      error: (error) => {
        this.sendNextResult('resultIndividualError', error, { method: this.getRRPPStadistics, args: [idRRPP] })
        this.checkStatusError(error);
      },
    });
  }

  getHomeGroupStadistics(firstDate, secondDate) {
    this.http.get(this.urlApi + '/api/company/group/statistics/home/' + secondDate + "/" + firstDate + "/" + Number(sessionStorage.getItem("groupId")), this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getHomeGroupStadistics, args: [firstDate, secondDate] })
        this.checkStatusError(error);
      },
    });
  }
  getTableGroupStadistics(firstDate, secondDate, tipo, anual = false) {
    this.http.get(this.urlApi + '/api/event/statistics/table/group/' + secondDate + "/" + firstDate + "/" + tipo + "/" + Number(sessionStorage.getItem("groupId")) + "/" + anual, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultIndividual', data)
      },
      error: (error) => {
        this.sendNextResult('resultIndividualError', error, { method: this.getTableGroupStadistics, args: [firstDate, secondDate, tipo, anual] })
        this.checkStatusError(error);
      },
    });
  }

  getStadisticsSuperAdmin(firstDate, secondDate) {
    this.http.get(this.urlApi + '/api/super/home/' + firstDate + "/" + secondDate, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('result', data)
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getStadisticsSuperAdmin, args: [firstDate, secondDate] })
        this.checkStatusError(error);
      },
    });
  }

  getStadisticsSuperTable(firstDate, secondDate, tipo, anual = false) {
    this.http.get(this.urlApi + '/api/user/super/statistics/table/' + firstDate + "/" + secondDate + "/" + tipo + "/" + anual, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultIndividual', data)
      },
      error: (error) => {
        this.sendNextResult('resultIndividualError', error, { method: this.getTableGroupStadistics, args: [firstDate, secondDate, tipo, anual] })
        this.checkStatusError(error);
      },
    });
  }
}
