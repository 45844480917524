import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenManagmentService } from '../utils/TokenManagment.service';
@Injectable({
  providedIn: 'root'
})
export class BaseService {

  private _unsub = new Subject<any>();
  private methodsRefreshToken: Array<{ method: Function, args: Array<any> }> = [];
  private alreadyListener = false;

  protected urlHost = environment.urlApi;
  protected _result = new BehaviorSubject<any>(null);
  protected _resultError = new BehaviorSubject<any>(null);
  protected _resultIndividual = new BehaviorSubject<any>(null);
  protected _resultIndividualError = new BehaviorSubject<any>(null);
  protected _resultUpdate = new BehaviorSubject<any>(null);
  protected _resultUpdateError = new BehaviorSubject<any>(null);
  protected _resultDelete = new BehaviorSubject<any>(null);
  protected _resultDeleteError = new BehaviorSubject<any>(null);
  getResult() { return this._result; }
  getResultIndividual() { return this._resultIndividual; }
  getResultIndividualError() { return this._resultIndividualError; }
  getResultError() { return this._resultError; }
  getResultUpdate() { return this._resultUpdate; }
  getResultUpdateError() { return this._resultUpdateError; }
  getResultDelete() { return this._resultDelete; }
  getResultDeleteError() { return this._resultDeleteError; }

  clearResult() { this._result.next(null); }
  clearResultError() { this._resultError.next(null); }
  clearResultIndividual() { this._resultIndividual.next(null); }
  clearResultIndividualError() { this._resultIndividualError.next(null); }
  clearResultUpdate() { this._resultUpdate.next(null); }
  clearResultUpdateError() { this._resultUpdateError.next(null); }
  clearResultDelete() { this._resultDelete.next(null); }
  clearResultDeleteError() { this._resultDeleteError.next(null); }

  protected sendNextResult(type: 'result' | 'resultError' | 'resultIndividual' | 'resultIndividualError' | 'resultUpdate' | 'resultUpdateError' | 'resultDelete' | 'resultDeleteError', data, methodCall?: { method: Function, args: Array<any> }) {
    let clearFuntion: Function;
    if (type == 'result') {
      this._result.next(data);
      clearFuntion = this.clearResult;
    } else if (type == 'resultError') {
      this._resultError.next(data);
      clearFuntion = this.clearResultError;
      this.checkStatusError(data, methodCall);
    } else if (type == 'resultIndividual') {
      this._resultIndividual.next(data);
      clearFuntion = this.clearResultIndividual;
    } else if (type == 'resultIndividualError') {
      this._resultIndividualError.next(data);
      clearFuntion = this.clearResultIndividualError;
      this.checkStatusError(data, methodCall);
    } else if (type == 'resultUpdate') {
      this._resultUpdate.next(data);
      clearFuntion = this.clearResultUpdate;
    } else if (type == 'resultUpdateError') {
      this._resultUpdateError.next(data);
      clearFuntion = this.clearResultUpdateError;
      this.checkStatusError(data, methodCall);
    } else if (type == 'resultDelete') {
      this._resultDelete.next(data);
      clearFuntion = this.clearResultDelete;
    } else if (type == 'resultDeleteError') {
      this._resultDeleteError.next(data);
      clearFuntion = this.clearResultDeleteError;
      this.checkStatusError(data, methodCall);
    }
    setTimeout(() => {
      this[clearFuntion.name]();
    }, 150);
  }

  private listenerRefreshToken(methodCall?: { method: Function, args: Array<any> }) {
    if (methodCall != null) {
      this.methodsRefreshToken.push(methodCall);
    } else {
      console.error("Exist one method without error methodCall");
    }
    if (!this.alreadyListener) {
      this.alreadyListener = true;
      TokenManagmentService.listenerRefreshToken().pipe(takeUntil(this._unsub)).subscribe(value => {
        setTimeout(() => {
          this._unsub.next("");
          this.alreadyListener = false;
          this.reloadMethodRefreshToken();
        }, 100);
      })
      TokenManagmentService.startTokenRefresh();
    }
  }

  protected reloadMethodRefreshToken() {
    setTimeout(() => {
      this.methodsRefreshToken.forEach(element => {
        this[element.method.name](...element.args);
      });
      this.methodsRefreshToken = [];
    }, 1000);
  }

  protected checkStatusError(data: any, methodCall?: { method: Function, args: Array<any> }) {
    if (data.status == 401) {
      if (methodCall?.method.name != "checkLogin" && methodCall != undefined) {
        this.listenerRefreshToken(methodCall)
      }
    }
    if (data.status == 403) { TokenManagmentService.tokenLost(); }
  }

  protected setToken(token: string) { localStorage.setItem('token', token) }
  protected setRefreshToken(refreshToken) { localStorage.setItem('refreshToken', refreshToken) }
  protected getToken() { return localStorage.getItem('token'); }
  protected getHeader() {
    return {
      headers: new HttpHeaders({
        Authorization: "Bearer " + localStorage.getItem("token")
      })
    };
  }

  protected getNotificacionesHeader() {
    return {
      headers: new HttpHeaders({
        Authorization: "key=AAAAUiJ8OX0:APA91bHLw2Xh9SLWR_jUEj5WQ1URNzPN59MqPmQc0rgqmth53b6NVEbGLqscLQwVOjZ9fIeX4mQMHlyLbksFuw3p5_SawBHpDmzY5dnjfrBO4PeLJoxuOFA1CbI99fuZ8NkH2FrELxbU"
      })
    };
  }
  protected convertArrayObject(arraySend, objectIndex) {
    let send;
    send = {};
    send[objectIndex] = [];
    if (!(arraySend instanceof Array)) {
      send[objectIndex].push(arraySend);
    } else {
      send[objectIndex] = [...arraySend];
    }
    return send;
  }
  protected getUserTypeToken(type?) {
    let tokp;
    tokp = localStorage.getItem("token");
    let p: any = [];
    if (type != null) {
      tokp = type;
    }

    if (tokp != null) {
      let tok = tokp.split(".")[1];
      let roles: Array<any> = this.getRoles(JSON.parse(atob(tok)));
      if (roles.includes("ROLE_SUPER_ADMIN")) {
        p.push('3');
      } else if (roles.includes("ROLE_COMPANY_GROUP_ADMIN")) {
        p.push('0');
      } else if (roles.includes("ROLE_COMPANY_ADMIN")) {
        p.push('1');
      } else if (roles.includes("ROLE_RRPP")) {
        p.push('2');
      } else if (roles.includes("ROLE_USER")) {
        p.push('4');
      }
      return { rolePrincipal: JSON.stringify(p), roles: roles };

    }
    return null;
  }
  private getRoles(token: any) {
    var roles: any = []
    Object.keys(token.roles).forEach(element => {
      roles.push(token.roles[element]);
    });
    return roles;
  }
  protected sendBodyOptions(bodyOptions) {
    if (bodyOptions != null) {
      return { headers: this.getHeader().headers, body: bodyOptions };
    }
    return this.getHeader();
  }
  protected optionsGet(options) {
    if (options == null) { return ''; }
    let ret = "?";
    let objKey = Object.keys(options);
    for (let i = 0; i < Object.keys(options).length; i++) {
      let element = objKey[i];
      if (options[element] instanceof Array) {
        let j = options[element].join(",");
        ret += element + "=" + j;
      } else {
        if (i > 0) { ret += "&"; }
        ret += element + "=" + options[element];
      }
    }
    return ret;
  }

  constructor() { }

}
