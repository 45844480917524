import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/models/unsubscribesDestroy';
import { ConfirmPopupComponent } from 'src/app/popups/confirmPopup/confirmPopup.component';
import { CommunitiesService } from 'src/app/services/Api/communities.service';
import { LoginService } from 'src/app/services/Api/login.service';
import { LoaderService } from 'src/app/services/utils/loader.service';
import { NotificacionesService } from 'src/app/services/utils/notificaciones.service';
import { PopupService } from 'src/app/services/utils/popup.service';
import { CreateEditCommunityStep1Component } from './Holders/CreateEditCommunity-Step1/CreateEditCommunity-Step1.component';
import { CreateEditCommunityStep2Component } from './Holders/CreateEditCommunity-Step2/CreateEditCommunity-Step2.component';

@Component({
  selector: 'app-CreateEditCommunity',
  templateUrl: './CreateEditCommunity.component.html',
  styleUrls: ['./CreateEditCommunity.component.scss']
})
export class CreateEditCommunityComponent extends UnsubscribesDestroy implements OnInit {

  @ViewChild(CreateEditCommunityStep1Component) step1!: CreateEditCommunityStep1Component;
  @ViewChild(CreateEditCommunityStep2Component) step2!: CreateEditCommunityStep2Component;

  //VARIABLES DE CONTROL
  comunidad: any = null
  pasos: Array<String> = ["Información comunidad", "Invitar usuarios"]
  paso: number = 0
  dataPaso1: any = null;
  validStep1: Boolean = false;
  dataPaso2: any = null;
  validStep2: Boolean = false;

  constructor(private loginSE: LoginService, private popupSE: PopupService, private router: Router, private communititesSE: CommunitiesService, private loader: LoaderService, private notificaciones: NotificacionesService, private activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logOut(true);
    } else {
      this.checkPermisos();

    }
  }
  //FUNCIONALIDADES
  cambiarPaso(event) {
  }
  cancel() {
    if (this.dataPaso1 != null) {
      this.popupSE.openPopup(ConfirmPopupComponent, { accion: "Descartar", tipo: "cambios" })
      this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe(value => {
        if (value == null) { return }
        this.router.navigate(["/publisher/communities-promotions/my-communities"])
        this._unsubInd.next("");
      })
    } else {
      this.router.navigate(["/publisher/communities-promotions/my-communities"])
    }
  }
  validateStep() {
    switch (this.paso) {
      case 0:
        this.step1.validarPaso1();
        if (this.validStep1) {
          this.paso = this.paso + 1;
        }
        break;
      case 1:
        this.step2.validarPaso2();
        break;
    }
  }

  //LLAMADAS A LA API
  crearComunidad() {
    var comunidad = {
      name: this.dataPaso1.name,
      isPrivate: this.dataPaso1.isPrivate,
      isActive: true,
      description: "",
      members: this.dataPaso2.members,
      ageRange: this.dataPaso1.ageRange,
      categories: this.dataPaso1.categories,
      company: Number(sessionStorage.getItem("dcmpny"))
    }
    this.communititesSE.newCommunitie(comunidad);
    this.loader.open();
    this.communititesSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return };
      this.loader.closeSlow();
      this.notificaciones.showFeedBack(value.message)
      setTimeout(() => {
        this.router.navigate(["/publisher/communities-promotions/my-communities/"])
      }, 500);
      this._unsubInd.next("");
    })
    this.communititesSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return };
      this.loader.closeSlow();
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    })
  }
  getCommunityById() {
    this.communititesSE.getCommunityById(this.activatedRoute.snapshot.params["id"]);
    this.communititesSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return };
      this.comunidad = value;
      this.loader.closeSlow();
      this.setDataToAllSteps();
      this._unsubInd.next("");
    })
    this.communititesSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return };
      this.loader.closeSlow();
      this.notificaciones.showError(value.error.message)
      this._unsub.next("");
    })
  }

  editarComunidad(event) {
    var evento = {}
    evento[event.element] = event.value
    this.communititesSE.updateCommunity(this.activatedRoute.snapshot.params['id'], evento);
    this.communititesSE.getResultUpdate().pipe(takeUntil(this._unsubInd4)).subscribe(value => {
      if (value == null) { return; }
      this.notificaciones.showFeedBack(value.message);
      this.getCommunityById();
      this._unsubInd4.next("")
    })
    this.communititesSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return; }
      this.notificaciones.showError(value.error.message);
      this._unsub.next("")
    })
  }


  //VALIDACIONES
  isValidStep1(valid: Boolean) {
    this.validStep1 = valid
  }
  isValidStep2(valid: Boolean) {
    this.validStep2 = valid
  }

  //GETTERS & SETTERS
  getDataFromStep1(event) {
    this.dataPaso1 = event;
  }
  getDataFromStep2(event) {
    this.dataPaso2 = event;
  }
  setDataToStep1() {
    this.dataPaso1 = {
      name: this.comunidad.name,
      isPrivate: this.comunidad.is_private,
      ageRange: this.comunidad.age_range,
      categories: this.comunidad.categories
    }
  }
  setDataToStep2() {
    this.dataPaso2 = {
      id: this.comunidad.id,
      code: this.comunidad.code
    }
  }
  setDataToAllSteps() {
    this.setDataToStep1();
    this.setDataToStep2();
  }
  permiso: Boolean = false;
  checkPermisos() {
    if (localStorage.getItem("tipoUser") == null || localStorage.getItem("tipoUser") == undefined || localStorage.getItem("tipoUser") == '["2"]') {
      this.loginSE.logOut();
      this.notificaciones.showError("No tienes permisos para acceder")
    } else {
      if (localStorage.getItem("tipoUser") == '["0"]' || localStorage.getItem("tipoUser") == '["3"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY") && !localStorage.getItem("permisos")?.includes("CONSULT_COMPANY_GROUP")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else {
          if (localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY") && localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY_GROUP")) {
            if (this.activatedRoute.snapshot.params["id"] != null || this.activatedRoute.snapshot.params["id"] != undefined) {
              this.getCommunityById();
            }
          } else {
            this.router.navigate(["/publisher/communities-promotions/my-communities/"])
            this.notificaciones.showError("No tienes permiso para acceder")
          }
        }
      }
      if (localStorage.getItem("tipoUser") == '["1"]') {
        if (localStorage.getItem("permisos") == "null") {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else if (!localStorage.getItem("permisos")?.includes("CONSULT_COMPANY")) {
          this.loginSE.logOut();
          this.notificaciones.showError("No tienes permisos para acceder")
        } else {
          if (localStorage.getItem("permisos")?.includes("CONSULT_EDIT_COMPANY")) {
            if (this.activatedRoute.snapshot.params["id"] != null || this.activatedRoute.snapshot.params["id"] != undefined) {
              this.getCommunityById();
            }
          } else {
            this.router.navigate(["/publisher/communities-promotions/my-communities/"])
            this.notificaciones.showError("No tienes permiso para acceder")
          }
        }
      }
    }


  }
}
