import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core';
import esLocale from '@fullcalendar/core/locales/es';

@Component({
  selector: 'app-select-date',
  templateUrl: './select-date.component.html',
  styleUrls: ['./select-date.component.scss'],
})
export class SelectDateComponent implements OnInit {
  constructor() {}

  @Input() rango: boolean = false;
  @Input() ini: any = null;
  @Input() fin: any = null;

  @Output() fechaSeleccionada = new EventEmitter<any>();
  ngOnInit() {
    if (this.ini != null) {
      if (this.fin != null) {
        this.pintarRango(this.ini, this.fin);
      } else {
        this.pintarFecha(this.ini);
      }
    }
  }

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    headerToolbar: {
      left: 'prev',
      center: 'title',
      right: 'next',
    },
    locale: esLocale,
    events: [],
    dateClick: this.handleDateClick.bind(this),
  };
  marcarInicio = true;
  calcularIntervalo() {}
  rellenarDias() {}
  handleDateClick(arg: any) {
    if (this.calendarOptions.events != undefined) {
      if (!this.rango) {
        this.pintarFecha(arg.date);
        this.fechaSeleccionada.emit(arg.date);
      } else {
        if (this.marcarInicio) {
          this.fin = null;
          this.ini = arg.date;
          this.pintarFecha(arg.date);
        } else {
          let res: any = new Date(arg.date.getTime() + 6000);
          this.fin = res;
          this.pintarRango(this.ini, this.fin);
        }
        this.fechaSeleccionada.emit([this.ini, this.fin]);
        this.marcarInicio = !this.marcarInicio;
      }
    }
    // this.fechaSeleccionada.emit(arg.date);
  }
  pintarFecha(date: any) {
    this.calendarOptions.events = [
      {
        date: date,
        display: 'block',
      },
    ];
  }

  pintarRango(date1: any, date2: any) {
    this.calendarOptions.events = [
      {
        start: date1,
        end: date2,
        display: 'block',
      },
    ];
  }
}
