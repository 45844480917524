<div class="previewPopupGeneralContainer">
  <div class="popupHeader">
    <svg (click)="forceClosePopup()" xmlns="http://www.w3.org/2000/svg" width="20.242" height="20.242"
      viewBox="0 0 20.242 20.242">
      <g id="Grupo_1201" data-name="Grupo 1201" transform="translate(-5674.379 -12806.379)">
        <line id="Línea_323" data-name="Línea 323" x2="16" y2="16" transform="translate(5676.5 12808.5)" fill="none"
          stroke="#0e17b6" stroke-linecap="round" stroke-width="3" />
        <line id="Línea_324" data-name="Línea 324" x1="16" y2="16" transform="translate(5676.5 12808.5)" fill="none"
          stroke="#0e17b6" stroke-linecap="round" stroke-width="3" />
      </g>
    </svg>
  </div>
  <ng-container *ngIf="modalData.type == 'image'">
    <img [src]="modalData.document" alt="">
  </ng-container>
  <ng-container *ngIf="modalData.type == 'video'">
    <video controls>
      <source [src]="modalData.document">
    </video>
  </ng-container>
  <ng-container *ngIf="modalData.type == 'document'">
    <iframe [src]="modalData.document | safe"></iframe>
  </ng-container>
  <input type="text" class="inputData" placeholder="Escribe un mensaje..." [(ngModel)]="mensaje">
  <div class="buttonContainer">
    <button class="btn-sm cerrar" (click)="forceClosePopup()">Cancelar</button>
    <button class="btn-sm" (click)="closePopup({mensaje:mensaje})">Enviar</button>
  </div>
  
</div>